import {
  customerContacts,
  CustomerStyles,
  Stack,
} from '@trueconnect/tp-components';
import { ContactForRenderType } from 'src/pages/App/Customers/DataView/Tabs';
import classnames from 'classnames';

export const ContactPhoneNumber = (item: ContactForRenderType) => {
  const { phone, disabled } = item;
  return (
    <Stack
      alignItems="start"
      justifyContent="center"
      className="w-full h-full overflow-hidden"
    >
      <a
        href={`tel: ${phone}`}
        className={classnames(
          customerContacts,
          '!font-medium font-[inter] truncate w-full ',
          CustomerStyles.greyedOut(disabled)
        )}
      >
        {phone}
      </a>
    </Stack>
  );
};
