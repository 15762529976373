import {
  Button,
  Stack,
  TextInput,
  branchType,
  defaultFontType,
  useIsSelectedView,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import BranchFilter from '@trueconnect/tp-components/src/pages/Policy/BranchFilter';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  CreateCustomerNoteDto,
  useApiInvalidateQueries,
  useApiMutation,
} from 'src/api';

export const titleClassname = classnames(
  'text-sm font-medium font-inter leading-normal',
  defaultFontType
);

export const NewNoteModal = ({
  isVisible,
  availableBranches,
  onClose,
  defaultBranch = '',
}: {
  isVisible?: boolean;
  availableBranches: branchType[];
  onClose: () => void;
  defaultBranch?: string;
}) => {
  const [title, setTitle] = useState('');
  const [selectedBranch, setSelectedBranch] = useState<string>(defaultBranch);
  const { addNotification } = useNotificationSystem();
  const isMobileView = useIsSelectedView('sm');
  const { state } = useStateProvider();
  const { companyData } = state.customer;

  useEffect(() => {
    setSelectedBranch(defaultBranch);
  }, [defaultBranch, isVisible]);

  const setBranches = (branchId: string) => {
    if (selectedBranch === branchId) {
      setSelectedBranch('');
    } else {
      setSelectedBranch(branchId);
    }
  };

  const close = () => {
    setTitle('');
    setSelectedBranch('');
    onClose();
  };

  const invalidateQueries = useApiInvalidateQueries();

  const { mutate, isLoading } = useApiMutation('create_Note', {
    onSuccess: () => {
      close();
      invalidateQueries('search_Notes');
    },
    onError: () => {
      addNotification({
        color: 'failure',
        title: t('customer.notes.addNoteModal.cantCreateNoteError'),
      });
    },
  });

  const onClick = () => {
    mutate([
      companyData?.id,
      {
        branchId: selectedBranch ? selectedBranch : null,
        title: title,
        text: '',
      } as CreateCustomerNoteDto,
    ]);
  };

  return (
    <Modal
      show={!!isVisible}
      title={t('customer.notes.addNoteModal.modalTitle')}
      onClose={close}
      className={classnames(
        '[&>div>div]:overflow-visible [&>div>div>div]:overflow-visible',
        '[&>div>div>div:nth-child(3)]:border-none'
      )}
      footer={
        <Stack direction="row" className="w-full gap-4">
          <Button className="w-1/2" onClick={close}>
            {t('common.cancel')}
          </Button>
          <Button
            isLoading={isLoading}
            dataTestid="addNoteAccept"
            disabled={!title}
            className="w-1/2"
            variant="primary"
            onClick={onClick}
          >
            {t('common.save')}
          </Button>
        </Stack>
      }
    >
      <Stack
        dataTestid="addNoteModal"
        className="w-full h-full gap-6"
        direction="col"
      >
        <div className="w-full">
          <p className={classnames(titleClassname, 'pb-1')}>
            {t('customer.notes.addNoteModal.titleLabel')}
          </p>
          <TextInput
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            data-testid="createNoteNameInput"
          />
        </div>
        <div className="w-full [&>div>div]:!w-full [&>div>div]:bg-gray-50 [&>div>div]:!border-[1px] [&>div>div]:border-gray-300">
          <p className={classnames(titleClassname, 'pb-1')}>
            {t('customer.notes.addNoteModal.branchLabel')}
          </p>
          <BranchFilter
            {...{
              setBranches,
              branchIds: [selectedBranch],
              availableBranches,
              isMobileView,
              mode: 'secondary',
              placeholder: t('customer.notes.selectBranch'),
              closeAfterSelect: true,
            }}
          />
        </div>
      </Stack>
    </Modal>
  );
};

export default NewNoteModal;
