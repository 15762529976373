import {
  ToggleSwitch as ToggleSwitchFlowbite,
  ToggleSwitchProps,
} from 'flowbite-react';
import classnames from 'classnames';

export const ToggleSwitch = (props: ToggleSwitchProps) => {
  return (
    <ToggleSwitchFlowbite
      {...props}
      className={classnames(
        props.className,
        '[&>div]:!ring-gray-100',
        props.checked && '[&>div]:bg-blue-700'
      )}
    />
  );
};

export default ToggleSwitch;
