import {
  ChevronDownIcon,
  ChevronUpIcon,
  ConversationListItem,
  ConversationListItemProps,
  IconButton,
  InformationNote,
  Stack,
  TopicType,
  VirtualizedList,
  branchType,
  getBranches,
  hoverClassname,
  uniqueStrings,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import { TopicsItem } from '@trueconnect/tp-components/src/pages/Topics';
import classnames from 'classnames';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useApiInfiniteQuery } from 'src/api';

export const CollapsableTopicItem = ({
  item,
  isOpen,
  onClick,
  onLoaded,
}: {
  item: TopicType;
  isOpen: boolean;
  onClick: (id: string) => void;
  onLoaded: (id: string) => void;
}) => {
  const { state, actions } = useStateProvider();
  const { availableBranches } = state.workManagement;
  const { setSelectedTopic } = actions;

  const { addNotification } = useNotificationSystem();

  const queryConversations = useApiInfiniteQuery(
    'searchCustomerConversations',
    [
      item.customerId || 0,
      {
        state: undefined,
        search: '',
        topicIds: item.id ? [item.id] : [],
      },
    ],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map(
            (subitem) =>
              ({
                title: subitem.title,
                id: subitem.id,
                participants: subitem.participants,
                lastActive: subitem.lastActivityUtcTimestamp,
                branches: getBranches(availableBranches, subitem.branchIds),
                ...subitem,
              } as ConversationListItemProps)
          )
        ),
      enabled: !!item.id && isOpen,
      onSuccess: () => {
        !!item.id && onLoaded(item.id);
      },
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('Topics.Details.cantLoadConversations'),
        });
      },
    }
  );

  const foundConversations = queryConversations.data?.flat(1).length;

  useEffect(() => {
    !!item.id && isOpen && onLoaded(item.id);
  }, [isOpen, item.id, onLoaded]);

  return (
    <Stack direction="col" justifyContent="center" className="border-b">
      <Stack
        className={classnames(
          'w-full h-[80px]',
          isOpen ? 'bg-gray-100' : hoverClassname
        )}
        onClick={() => {
          item.id && onClick(item.id);
        }}
        justifyContent="center"
      >
        <TopicsItem
          {...{
            item,
            secondaryText: 'creatorUserName',
            branches: getBranches(
              availableBranches,
              uniqueStrings([
                ...(item.branchIds || []),
                ...(item.childrenBranchIds || []),
              ])
            ) as branchType[],
            extraItems: () => (
              <IconButton className="absolute right-5">
                {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </IconButton>
            ),
          }}
        />
      </Stack>
      {isOpen && (
        <Stack
          direction="col"
          className={classnames(
            'w-full !pr-2',
            foundConversations == 0 || queryConversations.isLoading
              ? 'min-h-[110px]'
              : 'min-h-[350px] max-h-[350px]'
          )}
        >
          <Stack className="w-full h-full relative flex-1">
            <VirtualizedList
              columns={[
                {
                  dataPropName: 'recentConversation',
                  render: (itm) => {
                    return (
                      <div
                        className="w-full h-full border-b"
                        onClick={() => {
                          setSelectedTopic(item);
                        }}
                      >
                        <ConversationListItem key={itm.id} {...{ ...itm }} />
                      </div>
                    );
                  },
                },
              ]}
              infinityQuery={queryConversations}
              classNames={{
                content: 'absolute',
              }}
              headerHeight={0}
              rowHeight={120}
              nothingFound={
                <InformationNote
                  title={t('conversation.nothingIsFound.text')}
                />
              }
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default CollapsableTopicItem;
