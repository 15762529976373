import {
  CheckBox,
  SearchInput,
  Stack,
  VirtualizedList,
  commonText,
  useStateProvider,
} from '@trueconnect/tp-components';
import FilterCell from '@trueconnect/tp-components/src/components/ExtendedSearch/FilterCell';
import { useState } from 'react';
import { useApiInfiniteQuery } from 'src/api';
import { contactFilterType } from './typing';
import { t } from 'i18next';
import classnames from 'classnames';

export const ContactSearch = ({
  active,
  selectedCustomerId,
}: {
  active?: boolean;
  selectedCustomerId?: number;
}) => {
  const { extendedSearchParams } = useStateProvider().state.conversation;
  const { selectedContacts = [], selectedCustomer } = extendedSearchParams;
  const setExtendedSearchParams =
    useStateProvider().actions.setExtendedSearchParams;
  const [searchParam, setSearchParam] = useState('');
  const [enabled, setEnabled] = useState(false);

  const queryContacts = useApiInfiniteQuery(
    'searchContacts',
    [searchParam, selectedCustomer || selectedCustomerId, undefined, 0],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item
            .filter((item) => !!item.userId)
            .map(
              (subitem) =>
                ({
                  ...subitem,
                  name: classnames(subitem.firstName, subitem.lastName),
                } as contactFilterType)
            )
        ),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled,
    }
  );

  const handleCheckBoxClick = (userId: string) => {
    if (selectedContacts.includes(userId)) {
      setExtendedSearchParams({
        ...extendedSearchParams,
        selectedContacts: selectedContacts.filter((item) => item != userId),
      });
    } else {
      setExtendedSearchParams({
        ...extendedSearchParams,
        selectedContacts: [...selectedContacts, userId],
      });
    }
  };

  return (
    <FilterCell
      title={t('tab.contacts.text')}
      setEnabled={setEnabled}
      active={active}
      dataTestId="extendedSearchContactFilter"
    >
      <div className="px-4 w-full">
        <SearchInput
          isMobileView={true}
          classNames={{
            wrapper: 'bg-white',
            input: 'bg-white text-sm',
            icon: 'mx-0 px-0 [&>div]:m-0 !w-7',
          }}
          setSearchParam={setSearchParam}
          placeholder={t(
            'conversation.extendedSearch.contactSearch.inputPlaceholder'
          )}
          dataTestid="extendedSearchContactInput"
        />
      </div>
      <Stack
        className={`w-full h-full pt-3 px-3`}
        isResizable={true}
        resizeDirection="ver"
        resizeProps={{
          width: -1,
          height: 180,
          resizeHandles: ['s'],
          minConstraints: [-1, 140],
          maxConstraints: [-1, 380],
        }}
      >
        <VirtualizedList
          infinityQuery={queryContacts}
          headerHeight={0}
          rowHeight={30}
          classNames={{
            row: 'border-none',
          }}
          columns={[
            {
              dataPropName: 'item',
              render: (item) => (
                <Stack
                  key={item.userId}
                  direction="row"
                  className="cursor-pointer overflow-hidden"
                  alignItems="center"
                  onClick={() => {
                    handleCheckBoxClick(item.userId);
                  }}
                  dataTestid={`extendedContactSearch_${item.email}`}
                >
                  <CheckBox
                    containerClassname="!w-7 !px-1"
                    checked={selectedContacts.includes(item.userId)}
                    onChange={() => ({})}
                  />
                  <p
                    className={classnames(
                      commonText,
                      'text-sm !text-[#4B5563] leading-normal'
                    )}
                  >
                    {item.name}
                  </p>
                </Stack>
              ),
            },
          ]}
        />
      </Stack>
    </FilterCell>
  );
};

export default ContactSearch;
