import { CheckIcon } from '@heroicons/react/24/solid';
import IconButton from './IconButton';
import classnames from 'classnames';

export const CircleCheckBox = ({
  className,
  checked,
}: {
  className: string;
  checked: boolean;
}) => {
  return (
    <IconButton
      hoverable={false}
      className={classnames(
        '!w-6 !h-6 !rounded-full radioBtn',
        checked && 'bg-blue-500 !border-none',
        !checked && 'border border-[#D1D5DB]',
        className
      )}
    >
      <CheckIcon color="white" />
    </IconButton>
  );
};

export default CircleCheckBox;
