import { useStateProvider } from './../../providers';
import { Stack } from './../../controls';
import classnames from 'classnames';
import { conversationPageStyles } from './../../styles';
import { ConversationTypes } from '../../providers/Conversation/reducers';
import { useTranslation } from 'react-i18next';

export const ConversationFilterSelector = () => {
  const { t } = useTranslation();
  const { conversationFilter } = useStateProvider().state.conversation;
  const { setConversationType } = useStateProvider().actions;

  return (
    <Stack
      direction="row"
      className="w-full pt-3 pl-4 pb-2 gap-4"
      alignItems="center"
    >
      {ConversationTypes.map((filter) => (
        <button
          key={filter}
          onClick={() => {
            setConversationType(filter);
          }}
          data-testid={`conversationFilter_${filter}`}
          className={classnames(
            conversationPageStyles.sidebar.filter.tab,
            conversationFilter === filter
              ? conversationPageStyles.sidebar.filter.selected
              : conversationPageStyles.sidebar.filter.default
          )}
        >
          {filter === 'All' && t(`conversationTab.conversation.All`)}
          {filter === 'Open' && t(`conversationTab.conversation.Open`)}
          {filter === 'Closed' && t(`conversationTab.conversation.Closed`)}
        </button>
      ))}
    </Stack>
  );
};

export default ConversationFilterSelector;
