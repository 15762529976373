import {
  BreakPoints,
  useIsSelectedRange,
  useStateProvider,
  OrgFrom,
  Branch,
  Insurer,
  ContractType,
  ExpireDate,
  Price,
  Description,
  MobileViewItem,
  VirtualizedGridCellRendererType,
  PolicyType,
  Number,
  IconButton,
  NavBurgerIcon,
  Stack,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import { Badge } from './columns';

export const useGetColumns = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const isMidSizeView =
    (width <= BreakPoints['lg'] || width <= BreakPoints['xl']) &&
    width >= BreakPoints['md'];
  const isTabletView = useIsSelectedRange(['xl']);
  const isMobileView = useIsSelectedRange(['sm', 'md']);
  const { state } = useStateProvider();
  const { availableBranches } = state.workManagement;

  const shortBranch = width <= 1400;

  const columns: VirtualizedGridCellRendererType<PolicyType>[] = [
    {
      dataPropName: 'orgFrom',
      title: isMidSizeView ? <></> : t('policies.orgFrom'),
      className: classnames(isMidSizeView ? 'w-[25px]' : 'w-[150px] pl-6 pr-5'),
      widthRatio: isMidSizeView ? 0.8 : 1.8,
      render: (data) => OrgFrom({ ...data, isBroker: true }, isMidSizeView),
    },
    {
      dataPropName: 'branch',
      title: t('policies.branch'),
      widthRatio: shortBranch ? 1.3 : 3,
      className: classnames(shortBranch && 'pr-2'),
      render: (data) => {
        const item = availableBranches.find((item) => item.id == data.branchId);
        return Branch(data, item, shortBranch);
      },
    },
    {
      dataPropName: 'insurer',
      title: t('policies.insurer'),
      widthRatio: isTabletView ? 5 : 3,
      render: (data) => Insurer(data),
    },
    {
      dataPropName: 'contractType',
      title: t('policies.contractType'),
      widthRatio: isTabletView ? 4 : 3,
      render: (data) => ContractType(data),
    },
    {
      dataPropName: 'number',
      title: t('policies.number'),
      widthRatio: 2,
      render: (data) => Number(data),
    },
    {
      dataPropName: 'expireDate',
      title: t('policies.expireDate'),
      widthRatio: 2,
      render: (data) => ExpireDate(data),
    },
    {
      dataPropName: 'price',
      title: t('policies.bruttoPremium'),
      className: classnames('text-end pr-7'),
      widthRatio: 3,
      render: (data) => Price(data.bruttoPremium),
    },
    {
      dataPropName: 'description',
      title: t('policies.description'),
      widthRatio: 3,
      render: (data) => Description(data),
    },
    {
      dataPropName: 'badgeColumn',
      title: (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          className="w-full h-full"
        >
          <IconButton hoverable={false}>
            <NavBurgerIcon />
          </IconButton>
        </Stack>
      ),
      className: 'w-[120px] pr-8',
      widthRatio: 2,
      render: (data) => {
        return <Badge data={data} />;
      },
    },
  ];

  if (isMidSizeView) {
    return columns.filter(
      (item) => !['description'].includes(item.dataPropName)
    );
  }

  if (isMobileView) {
    return [
      {
        dataPropName: 'mobileViewItem',
        render: (data) => {
          const item = availableBranches.find(
            (item) => item.id == data.branchId
          );
          return MobileViewItem(data, item);
        },
      },
    ] as VirtualizedGridCellRendererType<PolicyType>[];
  }

  return columns;
};
