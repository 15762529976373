import { invoiceType } from 'src/components';
import { payloadType } from '../StateProvider/actions';
import {
  CustomerData,
  brokerType,
  companyType,
  documentType,
  policySearchParams,
} from './types';

type actionType =
  | 'CUSTOMER_DATA'
  | 'BROKER_DATA'
  | 'TOGGLE_MODAL'
  | 'CLEAR_CUSTOMER_DATA'
  | 'TOGGLE_INVITED_MODAL'
  | 'TOGGLE_PERMISSION_MODAL'
  | 'TOGGLE_SHARE_MODAL'
  | 'SET_DOCUMENT_TO_SHARE'
  | 'SET_COMPANY_DATA'
  | 'SET_SELECTED_INVOICE'
  | 'SET_INVOICE_MODAL_TYPE'
  | 'SET_POLICY_SEARCH_PARAMS'
  | 'SET_POLICY_BRANCH_FILTER';

interface action {
  type: actionType;
  payload?: payloadType;
}

interface actions {
  [key: string]: (payload?: payloadType) => action;
}

export const stateActionsCustomer: actions = {
  setCustomerData: (value: CustomerData) => ({
    type: 'CUSTOMER_DATA',
    payload: value,
  }),
  setBrokerData: (value: brokerType) => ({
    type: 'BROKER_DATA',
    payload: value,
  }),
  setClearCustomerData: () => ({
    type: 'CLEAR_CUSTOMER_DATA',
  }),
  setToggleCustomerModal: (value: boolean) => ({
    type: 'TOGGLE_MODAL',
    payload: value,
  }),
  setToggleInvitedListModal: (value: boolean) => ({
    type: 'TOGGLE_INVITED_MODAL',
    payload: value,
  }),
  setTogglePermissionModal: (value: boolean) => ({
    type: 'TOGGLE_PERMISSION_MODAL',
    payload: value,
  }),
  setToggleShareModal: (value: boolean) => ({
    type: 'TOGGLE_SHARE_MODAL',
    payload: value,
  }),
  setCompanyData: (value: companyType) => ({
    type: 'SET_COMPANY_DATA',
    payload: value,
  }),
  setDocumentToShare: (value: documentType) => ({
    type: 'SET_DOCUMENT_TO_SHARE',
    payload: value,
  }),
  setSelectedInvoice: (value: invoiceType) => ({
    type: 'SET_SELECTED_INVOICE',
    payload: value,
  }),
  setInvoiceModalType: (value: string) => ({
    type: 'SET_INVOICE_MODAL_TYPE',
    payload: value,
  }),
  setPolicySearchParams: (value: policySearchParams) => ({
    type: 'SET_POLICY_SEARCH_PARAMS',
    payload: value,
  }),
  setPolicyBranchFilter: (value: string | undefined) => ({
    type: 'SET_POLICY_BRANCH_FILTER',
    payload: value,
  }),
};

export type actionsCustomers = {
  setCustomerData: (value: CustomerData) => void;
  setBrokerData: (value: brokerType) => void;
  setClearCustomerData: () => void;
  setToggleCustomerModal: (value: boolean) => void;
  setToggleInvitedListModal: (value: boolean) => void;
  setTogglePermissionModal: (value: boolean) => void;
  setToggleShareModal: (value: boolean) => void;
  setCompanyData: (value: companyType) => void;
  setDocumentToShare: (value: documentType) => void;
  setSelectedInvoice: (value: invoiceType) => void;
  setInvoiceModalType: (value: string) => void;
  setPolicySearchParams: (value: policySearchParams) => void;
  setPolicyBranchFilter: (value: string | undefined) => void;
};

export default stateActionsCustomer;
