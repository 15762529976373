import { createContext, useContext } from 'react';
import { SmartSpinnerLocal } from './Spinners';

export enum ResourceType {
  PolicyDirectoryBranch = 'PolicyDirectoryBranch',
}

export interface ContactAllowedResourcesDto {
  resourceType?: ResourceType;
  allowedAccessToAll?: boolean;
  resourceIds?: string[] | undefined;
}

export interface ContactPermissionDto {
  allowConversations?: boolean;
  allowSharedDocuments?: boolean;
  allowPolicyDirectory?: boolean;
  allowedResources?: ContactAllowedResourcesDto[];
}

export type UserProfile = {
  image?: string;
  givenName: string;
  displayName?: string;
  surname: string;
  mail: string;
  userId?: string;
  customerName?: string;
  permissions?: ContactPermissionDto;
  userType?: 'Broker' | 'CustomerContact' | 'Unset';
  roles?: string[];
};

const defaultUserProfile: UserProfile = {
  givenName: '',
  surname: '',
  mail: '',
};

export const UserProfileContext = createContext(defaultUserProfile);

export const useUserProfile = () => useContext(UserProfileContext);

export type UserProfileProviderProps = {
  children: React.ReactNode;
  userProfile: UserProfile;
};

export const UserProfileProvider: React.FC<UserProfileProviderProps> = ({
  children,
  userProfile,
}) => {
  return (
    <UserProfileContext.Provider value={userProfile}>
      <SmartSpinnerLocal
        name="UserProfileProvider"
        condition={!userProfile.userId}
        renderAsGlobal={true}
      >
        {children}
      </SmartSpinnerLocal>
    </UserProfileContext.Provider>
  );
};
