export const VirtualizedComponentHeader =
  'bg-customColor_2 py-2 !border-none flex normal-case text-customColor_20 font-medium px-2 first:indent-3';

export const virtualizedRowClassname = 'bg-customColor_17 border-b';

export const virtualizedColumnClassname =
  'mx-0 first:mx-0 h-full flex items-center';

export const DocumentCellStyle = 'h-fit w-full';

export const cellStyle = 'flex items-center h-fit w-full';
