import { useRef, useState } from 'react';
import { Stack } from './../controls';
import { useClickAway } from 'react-use';
import classnames from 'classnames';
import { defaultScrollbar, hoverClassname } from './../styles';
import { ChevronDownIcon, ChevronUpIcon } from './../images';
import IconButton from './IconButton';

export const SelectInput = ({
  selectedChoice = '',
  options,
  onSelect,
  isEditable = true,
  className,
}: {
  selectedChoice?: string;
  options: { displayName: string; id: string }[];
  className?: string;
  onSelect?: (item: string) => void;
  isEditable?: boolean;
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const ref = useRef(null);

  useClickAway(ref, () => {
    setShowDropdown(false);
  });

  return (
    <div
      ref={ref}
      className={classnames('w-full border-b-2 relative', className)}
    >
      <Stack
        direction="row"
        justifyContent="between"
        alignItems="center"
        dataTestid="selectPremiumType"
        className={classnames('w-full h-9', isEditable && hoverClassname)}
        onClick={() => {
          isEditable && setShowDropdown(true);
        }}
      >
        <p className="text-base font-medium text-[#1C64F2]">{selectedChoice}</p>
        {isEditable && (
          <IconButton className="cursor-pointer">
            {showDropdown ? (
              <ChevronUpIcon color="#1C64F2" />
            ) : (
              <ChevronDownIcon color="#1C64F2" />
            )}
          </IconButton>
        )}
      </Stack>
      {showDropdown && (
        <Stack
          className={classnames(
            'absolute z-[2] top-9 max-h-[180px] w-full border-2 shadow-sm bg-white',
            'overflow-auto',
            defaultScrollbar
          )}
        >
          {options.map((item) => (
            <Stack
              key={item.id}
              dataTestid={`rewardTypeOption_${item.id}`}
              direction="row"
              alignItems="center"
              className={classnames(
                hoverClassname,
                'w-full min-h-[40px] px-1 text-base font-medium text-[#6B7280]'
              )}
              onClick={() => {
                onSelect?.(item.id);
                setShowDropdown(false);
              }}
            >
              <p>{item.displayName}</p>
            </Stack>
          ))}
        </Stack>
      )}
    </div>
  );
};

export default SelectInput;
