import DatePicker from 'react-multi-date-picker';
import { Stack } from './../../controls';
import classnames from 'classnames';
import { IconButton } from './../../components';
import { CalendarIcon } from '@heroicons/react/24/solid';
import { CloseIcon, clear_filter } from './../../images';
import { useStateProvider } from './../../providers';
import BranchFilter from './BranchFilter';
import { useEffect, useState } from 'react';
import { useIsSelectedView } from './../../utils';
import { t } from 'i18next';

export const PolicyToolkit = ({ showBranches }: { showBranches?: boolean }) => {
  const minYear = new Date().getFullYear().toString();
  const { state, actions } = useStateProvider();
  const { searchPolicyParams } = state.customer;
  const { availableBranches } = state.workManagement;
  const { setPolicySearchParams } = actions;
  const [stateYear, setStateYear] = useState<any>(undefined);
  const isMobileView = useIsSelectedView('sm');

  const setYear = (year: any) => {
    setStateYear(year);
    setPolicySearchParams({
      ...searchPolicyParams,
      year: parseInt(year?.toString()),
    });
  };

  const setBranches = (branchId: string) => {
    if (!branchId) {
      setPolicySearchParams({
        ...searchPolicyParams,
        branchIds: [],
      });
      return;
    }
    if (searchPolicyParams?.branchIds?.includes(branchId)) {
      setPolicySearchParams({
        ...searchPolicyParams,
        branchIds: searchPolicyParams?.branchIds.filter(
          (item) => item !== branchId
        ),
      });
    } else {
      setPolicySearchParams({
        ...searchPolicyParams,
        branchIds: [...(searchPolicyParams?.branchIds || []), branchId],
      });
    }
  };

  const clearFilters = () => {
    setStateYear(undefined);
    setPolicySearchParams({
      year: undefined,
      branchIds: [],
    });
  };

  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, []);

  return (
    <Stack
      direction="row"
      alignItems="center"
      className={classnames(isMobileView ? 'gap-1' : 'gap-3')}
    >
      <Stack
        direction="row"
        alignItems="center"
        className={classnames(
          'px-3 w-[186px] h-[44px] border border-2',
          !isMobileView && 'rounded-lg'
        )}
      >
        <IconButton customSize="!w-4 !h-4" hoverable={false}>
          <CalendarIcon />
        </IconButton>
        <DatePicker
          onlyYearPicker={true}
          minDate={minYear}
          value={stateYear}
          onChange={(date) => {
            date && setYear(date);
          }}
          placeholder={t('policies.selectDate')}
          inputMode="none"
          editable={false}
          className="[&>div>div>div>div:nth-child(2)]:h-[160px] [&>div>div>div>div:nth-child(2)]:w-[180px]"
          inputClass={classnames(
            'w-full border-none focus:border-none ring-0 focus:ring-0',
            'text-sm font-normal leading-normal text-customColor_4 cursor-pointer'
          )}
        />
        {stateYear && (
          <IconButton
            customSize="!w-4 !h-4"
            className="cursor-pointer"
            onClick={() => {
              setYear(undefined);
            }}
          >
            <CloseIcon color="#1C64F2" />
          </IconButton>
        )}
      </Stack>
      {showBranches && (
        <BranchFilter
          {...{
            setBranches,
            branchIds: searchPolicyParams.branchIds || [],
            availableBranches,
            isMobileView,
          }}
        />
      )}
      {(!!searchPolicyParams.year ||
        !!searchPolicyParams.branchIds?.length) && (
        <>
          <IconButton className="cursor-pointer" onClick={clearFilters}>
            <img src={clear_filter} />
          </IconButton>
        </>
      )}
    </Stack>
  );
};
