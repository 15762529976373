import {
  HubListenerNotificationType,
  InvoiceConfirmedType,
} from './../../providers/HubListener/typing';
import { ReceiveMessage } from './types/ReceiveMessage';
import { ShareDocument } from './types/ShareDocument';
import {
  ReceiveMessageType,
  ShareDocumentType,
} from './../../providers/HubListener/typing';
import { InvoiceConfirmed } from './types/InvoiceConfirmed';

export const NotificationRendered = ({
  item,
}: {
  item: HubListenerNotificationType;
}) => {
  switch (item.target) {
    case 'ReceiveMessage':
      return (
        <ReceiveMessage
          {...{
            ...item,
            notification: item.notification as ReceiveMessageType,
          }}
        />
      );
    case 'ShareDocument':
      return (
        <ShareDocument
          {...{ ...item, notification: item.notification as ShareDocumentType }}
        />
      );
    case 'InvoiceConfirmed':
      return (
        <InvoiceConfirmed
          {...{
            ...item,
            notification: item.notification as InvoiceConfirmedType,
          }}
        />
      );
    default:
      return <></>;
  }
};
