import { useWindowSize } from 'react-use';

export const BreakPoints = {
  sm: 640,
  // => @media (min-width: 640px) { ... }

  md: 768,
  // => @media (min-width: 768px) { ... }

  lg: 1024,
  // => @media (min-width: 1024px) { ... }

  xl: 1280,
  // => @media (min-width: 1280px) { ... }
  xxl: 1500,
};

export type BreakPointNames = keyof typeof BreakPoints;

export function useBreakPoint() {
  const { width } = useWindowSize();
  let key: BreakPointNames;
  for (key in BreakPoints) {
    if (width <= BreakPoints[key]) {
      return key;
    }
  }
  return 'xxl';
}

export const useIsSelectedView = (value: string) => {
  const breakpoint = useBreakPoint();
  return breakpoint === value;
};

export const useIsSelectedRange = (values: string[]) => {
  const breakpoint = useBreakPoint();
  return breakpoint && values.includes(breakpoint);
};
