import {
  ArrowDownIcon,
  DocumentCheckIcon,
  IDocumentType,
  TabProps,
  Tabs,
  VirtualizedGridCellRendererType,
  useIsSelectedRange,
} from '@trueconnect/tp-components';
import DropzoneTab from './DropzoneTab';
import SelectExistingTab from './SelectExistingTab';
import { useParams } from 'react-router-dom';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

type UploadModalTabsProps = documentsTabProps;

export type documentsTabProps = {
  FilesTableProps: {
    isDragActive: boolean;
    columns: VirtualizedGridCellRendererType<IDocumentType>[];
    queryDocuments: UseInfiniteQueryResult<IDocumentType[]>;
  };
};

export const UploadModalTabs: React.FC<UploadModalTabsProps> = (props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const isMobileView = useIsSelectedRange(['sm', 'md', 'lg']);

  const tabs: TabProps[] = [
    {
      title: t(
        isMobileView
          ? 'uploadPanel.upload.selectFrom.computerShort'
          : 'uploadPanel.upload.selectFrom.computer'
      ),
      path: id,
      component: <DropzoneTab />,
      icon: <ArrowDownIcon />,
    },
    {
      title: t(
        isMobileView
          ? 'uploadPanel.upload.selectFrom.conversationShort'
          : 'uploadPanel.upload.selectFrom.conversation'
      ),
      path: id,
      component: <SelectExistingTab {...props} />,
      icon: <DocumentCheckIcon />,
    },
  ];

  return <Tabs tabs={tabs} className="!mx-0" />;
};

export default UploadModalTabs;
