import { Bars4Icon } from '@heroicons/react/24/solid';
import IconButton from './../../../components/IconButton';
import { Stack } from './../../../controls';
import { hoverClassname } from './../../../styles';
import { OrgFrom } from '../columns';
import { PolicyType } from '../typing';
import { t } from 'i18next';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useIsSelectedRange, useIsSelectedView } from './../../../utils';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

export const DetailsHeader = ({
  policyData,
  returnUrl = '/policies',
  classNames,
}: {
  policyData: PolicyType & { isBroker?: boolean };
  returnUrl?: string;
  classNames?: string;
}) => {
  const { contractTypeName, policyNumber } = policyData;
  const navigate = useNavigate();
  const isMobileView = useIsSelectedView('sm');
  const isTabletView = useIsSelectedRange(['md', 'lg']);

  if (isMobileView) {
    return (
      <Stack
        className={classnames('w-full h-[70px] pl-2 pt-2 relative', classNames)}
        dataTestid={`policy_${policyNumber}`}
        direction="row"
        alignItems="center"
        justifyContent="between"
      >
        <Stack
          direction="row"
          className="w-full pl-[50px]"
          alignItems="center"
          justifyContent="between"
        >
          <IconButton
            className="absolute top-6 left-4"
            hoverable={false}
            onClick={() => {
              navigate(returnUrl);
            }}
          >
            <ChevronLeftIcon className="text-customColor_5" />
          </IconButton>
          <Stack direction="col" className="h-[50px] w-full">
            <div className="w-full">
              <Stack direction="row" className="w-full [&>div]:pl-0">
                {OrgFrom(policyData, false)}
                <p className="ml-1">{contractTypeName}</p>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                className="w-full text-base gap-1"
              >
                <p className="font-semibold">
                  {t('policyDetails.policyNumber')}
                </p>
                <p>{policyNumber}</p>
              </Stack>
            </div>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      dataTestid={`policy_${policyNumber}`}
      className={classnames('w-full pb-4', classNames)}
    >
      <Stack direction="row" alignItems="center" className="w-full">
        <div className="[&>div]:pl-0">{OrgFrom(policyData, false)}</div>
        <p className="text-2xl font-semibold pl-2 pr-1">
          {t('policyDetails.title', { contractTypeName })}
        </p>
        <p className="text-2xl">{policyNumber}</p>
      </Stack>
      {!isTabletView && (
        <Stack
          direction="row"
          alignItems="center"
          className={classnames(
            'text-[#1C64F2] p-1 rounded-lg',
            hoverClassname
          )}
          onClick={() => {
            navigate(returnUrl);
          }}
        >
          <IconButton hoverable={false} className="!m-0 !p-0">
            <Bars4Icon color="#1C64F2" />
          </IconButton>
          <p className="text-nowrap">{t('policyDetails.backToOverView')}</p>
        </Stack>
      )}
    </Stack>
  );
};
