import { Stack } from './../../controls';
import {
  ConversationListItem,
  ConversationListItemProps,
} from './../../pages/Conversations';
import { CommonStyles } from './../../styles';
import { classNamesType } from '../VirtualizedList';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import classnames from 'classnames';
import { useIsSelectedView } from './../../utils';
import VirtualizedList from '../Virtualized/VirtualizedList';
import InformationNote from '../InformationNote';
import { t } from 'i18next';

export const ConversationTable = ({
  queryConversations,
  classNames,
  nothingFound,
  selected,
}: {
  queryConversations: UseInfiniteQueryResult<ConversationListItemProps[]>;
  classNames?: classNamesType;
  nothingFound?: string | JSX.Element | undefined;
  selected?: string;
}) => {
  const isMobileView = useIsSelectedView('sm');

  return (
    <Stack
      className={classnames(
        CommonStyles.full,
        !isMobileView && 'border rounded-lg px-2 pb-5',
        classNames?.container
      )}
    >
      <VirtualizedList
        {...{
          infinityQuery: queryConversations,
          classNames,
          nothingFound: nothingFound || (
            <InformationNote title={t('conversation.nothingIsFound.text')} />
          ),
          rowHeight: 120,
          columns: [
            {
              dataPropName: 'rowItem',
              render: (item: ConversationListItemProps) => (
                <ConversationListItem
                  {...item}
                  selected={selected === item.id}
                />
              ),
            },
          ],
        }}
      />
    </Stack>
  );
};

export default ConversationTable;
