import Modal from '@trueconnect/tp-components/src/controls/Modal';
import classnames from 'classnames';
import InvoiceDocument from './InvoiceDocument';
import {
  Button,
  InformationNote,
  SelectInput,
  SmartSpinnerLocal,
  Stack,
  TextArea,
  defaultFontType,
  defaultScrollbar,
  invoiceType,
  useApiUpload,
  useIsSelectedView,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import { Progress } from 'flowbite-react';
import { t } from 'i18next';
import dayjs from 'dayjs';
import {
  GetPolicyByIdDto,
  InvoiceNotificationReceiverDto,
  UpdateInvoiceDto,
  useApiInvalidateQueries,
  useApiMutation,
  useApiQuery,
} from 'src/api';
import InvoicePrice from './InvoicePrice';
import { useCallback, useEffect, useState } from 'react';
import { Query, useQueryClient } from '@tanstack/react-query';
import AlreadyReadInvoiceConfirmation from './AlreadyReadInvoiceConfirmation';
import RecipientSearch from './RecipientSearch';

export const titleClassname = classnames(
  'text-base font-semibold font-inter',
  defaultFontType
);
export const subTitle = 'text-[#6B7280]';

const formatDate = (date?: Date) => {
  return dayjs(date).format('DD.MM.YYYY');
};

export const InvoiceEdit = ({
  show,
  onClose,
  policyData,
}: {
  show?: boolean;
  onClose?: () => void;
  policyData: GetPolicyByIdDto;
}) => {
  const { state, actions } = useStateProvider();
  const selectedInvoice = state.customer.selectedInvoice as invoiceType;
  const isMobileView = useIsSelectedView('sm');
  const { data = [] } = useApiQuery('getAvailablePolicyRewardTypes', [], {
    enabled: !!show,
  });
  const { addNotification } = useNotificationSystem();

  const [selectedDocument, setSelectedDocument] = useState<File | undefined>();
  const [selectedRewardType, setSelectedRewardType] = useState<
    string | undefined
  >();
  const [brutto, setBrutto] = useState<number | undefined>(
    selectedInvoice?.nettoPremium
  );
  const [notificationMessage, setNotificationMessage] = useState('');
  const [selectedRecipients, setSelectRecipients] = useState<
    InvoiceNotificationReceiverDto[]
  >([]);

  const selectRecipients = (item: InvoiceNotificationReceiverDto) => {
    if (item.email) {
      if (selectedRecipients.map((itm) => itm.email).includes(item.email)) {
        setSelectRecipients(
          selectedRecipients.filter((itm) => itm.email !== item.email)
        );
      } else {
        setSelectRecipients([...selectedRecipients, item]);
      }
    }
  };

  useEffect(() => {
    if (selectedInvoice?.id) {
      setBrutto(
        selectedInvoice?.amount
          ? selectedInvoice?.amount
          : selectedInvoice?.nettoPremium || 0
      );
      setSelectedRewardType(selectedInvoice?.policyRewardTypeId);
      setSelectRecipients(selectedInvoice?.notificationRecipients || []);
      setNotificationMessage(selectedInvoice.notificationText || '');
    }
  }, [selectedInvoice?.id]);

  useEffect(() => {
    return () => {
      setSelectedDocument(undefined);
      setSelectedRewardType(undefined);
      setNotificationMessage('');
      setSelectRecipients([]);
      setBrutto(0);
    };
  }, [show]);

  const invalidateQueries = useApiInvalidateQueries();
  const queryClient = useQueryClient();
  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('getInvoicesByPolicyId');
  }, [invalidateQueries]);

  const { currentUploadFile, isUploading } = state.uploads;
  const { setCurrentUploadFileData, setIsUploading } = actions;

  const { mutate, isLoading } = useApiMutation('updateInvoice');

  const isFull =
    !!brutto &&
    (!!selectedDocument?.name || selectedInvoice.documentId) &&
    !!selectedInvoice?.id &&
    !!selectedRewardType;

  const { mutate: deleteDocument } = useApiMutation('delete_Invoice_Document', {
    onSuccess: () => {
      if (selectedDocument?.name)
        setCurrentUploadFileData({
          file: selectedDocument,
          cancel: () => ({}),
          progress: 0,
        });
    },
  });

  const updateInvoice = () => {
    if (selectedDocument?.name) {
      if (selectedInvoice.documentId) {
        deleteDocument([selectedInvoice.id]);
      } else {
        setCurrentUploadFileData({
          file: selectedDocument,
          cancel: () => ({}),
          progress: 0,
        });
      }
    } else {
      mutate(
        [
          {
            amount: brutto,
            documentId: selectedInvoice?.documentId,
            id: selectedInvoice?.id,
            policyRewardTypeId: selectedRewardType,
            isConfirmed: !!selectedInvoice?.documentId,
            notificationRecipients: selectedRecipients,
            notificationText: notificationMessage,
          } as UpdateInvoiceDto,
        ],
        {
          onSuccess: () => {
            onClose?.();
            invalidateQuery();
          },
          onError: () => {
            addNotification({
              title: t('policyDetails.editInvoice.saveError'),
              color: 'failure',
            });
          },
        }
      );
    }
  };

  const isInvoiceRead = !!selectedInvoice?.firstTimeDownloadedUtc;
  const [showModalType, setShowModalType] = useState('');

  const onAccept = () => {
    if (isFull) {
      if (isInvoiceRead) {
        setShowModalType('confirm');
      } else {
        updateInvoice();
      }
    } else {
      setShowModalType('validation');
    }
  };

  const { dateFrom } = selectedInvoice;
  const { policyNumber, contractTypeName, insuranceCompanyName, branchId } =
    policyData;
  const extension = selectedDocument?.name.split('.').reverse()[0];
  const selectedRewardTypeName =
    data.find((item) => item.id == selectedRewardType)?.displayName || '';

  const createSessionQuery = useApiQuery(
    'creates_Upload_Session_For_Invoice_Documents',
    [
      selectedInvoice?.id || '',
      {
        uploadFileName: `${dateFrom}-${policyNumber}-${contractTypeName}-${insuranceCompanyName}-${selectedRewardTypeName}.${extension}`,
      },
    ],
    { enabled: false }
  );

  const removeSession = useCallback(() => {
    queryClient.removeQueries({
      predicate: (query: Query) =>
        query.queryKey[0] === 'creates_Upload_Session_For_Invoice_Documents',
    });
  }, [queryClient]);

  const { mutate: closeUploadSession } = useApiMutation(
    `close_Upload_Session_For_Invoice`,
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (_, [_policyId, documentId]) => {
        removeSession();
        setCurrentUploadFileData({ progress: 0, cancel: () => ({}) });
        setIsUploading(false);
        mutate(
          [
            {
              amount: brutto,
              documentId,
              id: selectedInvoice?.id,
              policyRewardTypeId: selectedRewardType,
              isConfirmed: !!documentId && isFull,
              notificationRecipients: selectedRecipients,
              notificationText: notificationMessage,
            } as UpdateInvoiceDto,
          ],
          {
            onSuccess: () => {
              invalidateQuery();
              onClose?.();
            },
            onError: () => {
              addNotification({
                title: t('policyDetails.editInvoice.saveError'),
                color: 'failure',
              });
            },
          }
        );
      },
    }
  );

  const { progress } = useApiUpload<string>(
    selectedInvoice?.id || '',
    createSessionQuery,
    (id: string, documentId: string) => {
      closeUploadSession([id, documentId]);
    },
    setIsUploading,
    (error) => {
      addNotification({
        title: error,
        color: 'failure',
      });
    },
    currentUploadFile.file,
    isUploading
  );

  if (!selectedInvoice?.id) {
    return <></>;
  }

  return (
    <>
      <Modal
        {...{
          show,
          onClose,
          title: <p>{t('policyDetails.editInvoice.title')}</p>,
          className: classnames(
            '[&>div]:p-0 [&>div]:max-w-[520px] [&>div>div]:sm:min-w-[520px] [&>div]:h-full [&>div>div>div:nth-child(1)]:p-4 [&>div>div>div:nth-child(1)]:pb-1',
            '[&>div>div]:h-full [&>div>div]:rounded-none [&>div>div]:max-h-full [&>div]:ml-auto [&>div>div>div]:border-none [&>div>div>div:nth-child(2)]:px-4',
            '[&>div>div>div:nth-child(2)]:py-0 [&>div>div>div:nth-child(2)]:flex [&>div>div>div:nth-child(2)]:flex-col',
            isMobileView && '[&>div>div>div:nth-child(2)]:px-1'
          ),
          footer: (
            <Stack direction="row" className="w-full gap-4">
              <Button
                className="w-1/2"
                variant="secondary"
                onClick={onClose}
                dataTestid={`cancelEditInvoice`}
              >
                {t('common.cancel')}
              </Button>
              <Button
                variant="primary"
                className="w-1/2"
                onClick={onAccept}
                isLoading={isUploading || isLoading}
                dataTestid={`acceptEditInvoice`}
              >
                {t('policyDetails.editInvoice.onAccept')}
              </Button>
            </Stack>
          ),
        }}
      >
        {isUploading && (
          <Stack
            direction="col"
            className="w-full h-full"
            alignItems="center"
            justifyContent="center"
          >
            {t('policyDetails.editInvoice.uploadingFile')}
            <div className="w-full h-6">
              <Progress progress={progress} size="lg" color="blue" />
            </div>
          </Stack>
        )}

        {!isUploading && (
          <SmartSpinnerLocal
            condition={isLoading || !selectedInvoice?.id}
            name="updateInvoice"
          >
            <div className="w-full h-full flex flex-col">
              <Stack
                className={classnames('px-4 pt-6', isMobileView && '!px-2')}
              >
                <InvoiceDocument
                  {...{
                    selectedDocument,
                    documentId: selectedInvoice.documentId,
                    setSelectedDocument,
                  }}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  className="pt-8 pb-6 gap-8"
                >
                  <Stack>
                    <p className={titleClassname}>
                      {t('policyDetails.editInvoice.dateFrom')}
                    </p>
                    <p className={classnames(subTitle, 'font-semibold')}>
                      {formatDate(selectedInvoice?.dateFrom)}
                    </p>
                  </Stack>
                  <Stack>
                    <p className={titleClassname}>
                      {t('policyDetails.editInvoice.dateTo')}
                    </p>
                    <p className={classnames(subTitle, 'font-semibold')}>
                      {formatDate(selectedInvoice?.dateTo)}
                    </p>
                  </Stack>
                </Stack>
                <p className={titleClassname}>
                  {t('policyDetails.editInvoice.rewardType')}
                </p>
                <SelectInput
                  {...{
                    selectedChoice:
                      selectedInvoice.policyRewardTypeName ||
                      data.find((item) => item.id == selectedRewardType)
                        ?.displayName,
                    options: data?.map((item) => ({
                      displayName: item.displayName || '',
                      id: item.id || '',
                    })),
                    className: 'mb-5',
                    onSelect: (item) => {
                      if (item) {
                        setSelectedRewardType(item);
                      }
                    },
                  }}
                />
                <InvoicePrice
                  {...{
                    brutto,
                    setBrutto,
                  }}
                />
              </Stack>

              {!selectedInvoice.isConfirmed && (
                <p
                  className={classnames(
                    defaultFontType,
                    'leading-normal font-medium text-lg pt-8'
                  )}
                >
                  {t('policyDetails.editInvoice.notificationSection.title')}
                </p>
              )}
              {!selectedInvoice.isConfirmed && (
                <div
                  className={classnames(
                    'w-full px-4 pt-4 pb-8',
                    isMobileView && '!px-2'
                  )}
                >
                  <div className="w-full relative">
                    <RecipientSearch
                      {...{
                        selectedRecipients,
                        selectRecipients,
                        branchId,
                        enabled: !selectedInvoice.isConfirmed,
                      }}
                    />
                  </div>
                  <p
                    className={classnames(
                      titleClassname,
                      '!font-medium',
                      'mb-3 mt-4'
                    )}
                  >
                    {t('policyDetails.editInvoice.notificationTitle')}
                  </p>
                  <div className="w-full h-full max-h-[100px]">
                    <TextArea
                      value={
                        selectedInvoice.isConfirmed ? '' : notificationMessage
                      }
                      data-testid="notificationMessage"
                      placeholder={
                        selectedInvoice.isConfirmed
                          ? selectedInvoice?.notificationText
                          : t('policyDetails.editInvoice.notificationMessage')
                      }
                      onChange={(e) => {
                        !selectedInvoice.isConfirmed &&
                          setNotificationMessage(e.target.value);
                      }}
                      className={classnames(
                        'focus:border-gray-300 focus:ring-0'
                      )}
                    />
                  </div>
                </div>
              )}
              {selectedInvoice.isConfirmed && (
                <div
                  className={classnames(
                    'w-full px-4 pt-4 pb-8',
                    isMobileView && '!px-2'
                  )}
                >
                  <p
                    className={classnames(
                      titleClassname,
                      '!font-medium',
                      'mt-4'
                    )}
                  >
                    {t(
                      'policyDetails.editInvoice.notificationSection.alreadyNotifiedListHeader'
                    )}
                  </p>
                  <div
                    className={classnames(
                      'w-full max-h-[80px] overflow-y-auto flex flex-wrap gap-1 mb-8',
                      defaultScrollbar
                    )}
                  >
                    {(selectedInvoice?.notificationRecipients || []).map(
                      (item: InvoiceNotificationReceiverDto) => (
                        <p
                          key={item.email}
                          className={classnames(
                            defaultFontType,
                            'text-sm font-normal leading-[21px]'
                          )}
                        >
                          {item.displayName} {item.email}
                          {';'}
                        </p>
                      )
                    )}
                  </div>

                  <p
                    className={classnames(
                      titleClassname,
                      '!font-medium',
                      'mt-4'
                    )}
                  >
                    {t(
                      'policyDetails.editInvoice.notificationSection.notificationMessage'
                    )}
                  </p>
                  <p
                    className={classnames(
                      defaultFontType,
                      'text-sm font-normal leading-[21px]'
                    )}
                  >
                    {selectedInvoice.notificationText}
                  </p>
                </div>
              )}
              {!selectedInvoice.isConfirmed && (
                <div className="w-full h-full px-4 mt-3 flex flex-col justify-end [&>div]:mt-0 [&>div>div]:text-xs [&>div>div]:font-normal">
                  <InformationNote
                    title={t('policyDetails.editInvoice.beforeSaveInfo')}
                  />
                </div>
              )}
            </div>
          </SmartSpinnerLocal>
        )}
      </Modal>

      <Modal
        {...{
          show: showModalType == 'validation',
          showFooter: false,
          title: t('policyDetails.notFilledEditInvoiceForm.header'),
          onClose: () => {
            setShowModalType('');
          },
        }}
      >
        <Stack
          direction="col"
          className={classnames(
            'w-full h-full font-inter text-sm',
            defaultFontType
          )}
        >
          <p className="pb-5">
            {t('policyDetails.notFilledEditInvoiceForm.context')}
          </p>
          {[
            ...(selectedDocument?.name || selectedInvoice.documentId
              ? []
              : [t('policyDetails.notFilledEditInvoiceForm.pdf')]),
            ...(selectedRewardType
              ? []
              : [t('policyDetails.notFilledEditInvoiceForm.rewardType')]),
            ...(brutto
              ? []
              : [t('policyDetails.notFilledEditInvoiceForm.amount')]),
          ]
            .filter((item) => !!item)
            .map((item) => (
              <li key={item}>{item}</li>
            ))}

          <Button
            className="w-1/2 mt-12 ml-auto"
            dataTestid="closeInformationMissingModal"
            variant="primary"
            onClick={() => {
              setShowModalType('');
            }}
          >
            {t('common.close')}
          </Button>
        </Stack>
      </Modal>

      <AlreadyReadInvoiceConfirmation
        {...{
          show: showModalType == 'confirm',
          isLoading,
          onClose: () => {
            setShowModalType('');
          },
          onAccept: () => {
            setShowModalType('');
            updateInvoice();
          },
        }}
      />
    </>
  );
};
