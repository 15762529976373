import { defaultScrollbar } from '@trueconnect/tp-components';
import { Dispatch, SetStateAction } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  InlineEditor,
  Paragraph,
  Essentials,
  Bold,
  Italic,
  Mention,
  Undo,
  FontColor,
  FontBackgroundColor,
  EditorConfig,
} from 'ckeditor5';
import { t } from 'i18next';
import classnames from 'classnames';

import 'ckeditor5/ckeditor5.css';
import './module.scss';

const editorConfig = {
  toolbar: {
    items: [
      'undo',
      'redo',
      'bold',
      'italic',
      'fontColor',
      'fontBackgroundColor',
    ],
  },
  plugins: [
    Paragraph,
    Essentials,
    Bold,
    Italic,
    Undo,
    Mention,
    FontColor,
    FontBackgroundColor,
  ],
  placeholder: t('customer.notes.notePlaceholder'),
} as EditorConfig;

export const RichTextEditor = ({
  value,
  setValue,
  isEditable,
  config = {},
}: {
  value?: string;
  setValue?: Dispatch<SetStateAction<string | undefined>>;
  isEditable?: boolean;
  config?: EditorConfig;
}) => {
  const handleEditorChange = (event: any, editor: any) => {
    const data = editor.getData();
    setValue?.(data);
  };

  return (
    <div
      data-testid="RichTextEditor"
      className={classnames(
        'w-full h-full relative border-[1px] [&>div]:!p-0 [&>div>p]:!m-0 font-normal leading-normal text-customColor_4',
        !isEditable && 'border-white'
      )}
    >
      {isEditable ? (
        <CKEditor
          editor={InlineEditor}
          config={{
            ...editorConfig,
            ...config,
            language: 'de',
          }}
          data={value}
          onChange={handleEditorChange}
        />
      ) : (
        <div
          className={classnames(
            'w-full h-full absolute top-0 bottom-0 left-0 right-0 overflow-y-auto border-white border-[1px] font-normal leading-normal text-customColor_4',
            defaultScrollbar
          )}
          dangerouslySetInnerHTML={{ __html: value || '' }}
        />
      )}
    </div>
  );
};

export default RichTextEditor;
