import React, { ReactNode } from 'react';
import { Modal as GlobalModal } from 'flowbite-react';
import classnames from 'classnames';
import { useIsSelectedView } from './../utils';

export interface ModalProps {
  show?: boolean;
  onClose?: (e?: Event) => void;
  title?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'xl' | '2xl' | '5xl' | '7xl';
  showTitle?: boolean;
  showBody?: boolean;
  showFooter?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  show = true,
  onClose,
  title,
  children,
  footer,
  className,
  size = 'md',
  showTitle = true,
  showBody = true,
  showFooter = true,
}: ModalProps) => {
  const isMobileView = useIsSelectedView('sm');

  return (
    <GlobalModal
      show={show}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      size={size}
      dismissible={false}
      className={classnames(
        isMobileView &&
          'h-screen [&>div]:flex [&>div]:flex-col [&>div]:overflow-hidden [&>div]:align-center [&>div]:justify-center [&>div]:p-0 [&>div>div]:rounded-none',
        'z-[5] !h-full [&>div]:flex [&>div]:flex-col [&>div]:auto [&>div]:align-center [&>div]:justify-center',
        '[&>div>div>div:nth-child(1)>h3]:font-[inter] [&>div>div>div:nth-child(1)>h3]:pt-[2px]',
        '[&>div>div]:overflow-y-auto',
        className
      )}
    >
      {showTitle && <GlobalModal.Header>{title}</GlobalModal.Header>}
      {showBody && <GlobalModal.Body>{children}</GlobalModal.Body>}
      {showFooter && <GlobalModal.Footer>{footer}</GlobalModal.Footer>}
    </GlobalModal>
  );
};

export default Modal;
