import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ContactStatus,
  Stack,
  modalStyles,
  useStateProvider,
} from '@trueconnect/tp-components';
import { useCallback, useState } from 'react';
import { DialogProps } from '../StatusBadge/states';
import {
  useApiInfiniteQuery,
  useApiInvalidateQueries,
  useApiMutation,
} from 'src/api';
import classnames from 'classnames';

export const InvitationConfirmation = ({
  contactId,
  close,
  visible,
  className,
}: DialogProps) => {
  const { t } = useTranslation();
  const invalidateQueries = useApiInvalidateQueries();
  const { customerData } = useStateProvider().state.customer;
  const { setCustomerData } = useStateProvider().actions;

  const { mutate: changeContactStatus } = useApiMutation('changeContactStatus');

  const [showDuplicatedEmailAlert, setShowDuplicatedEmailAlert] =
    useState(false);
  const { data, isLoading } = useApiInfiniteQuery(
    'searchCustomers',
    [customerData.email, undefined, 0],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map((subitem) => ({
            ...subitem,
          }))
        ),
      refetchOnWindowFocus: false,
      errorMessage: t('customers.loading.error'),
    }
  );

  const confirmCustomerInvitation = useCallback(async () => {
    if (data && data?.length >= 2) {
      setShowDuplicatedEmailAlert(true);
    } else {
      await changeContactStatus([contactId, ContactStatus.Invited], {
        onSuccess: () => {
          invalidateQueries('searchContacts');
          invalidateQueries('getConversationById');
          setCustomerData({ ...customerData, status: ContactStatus.Invited });
        },
      });
    }
  }, [
    changeContactStatus,
    contactId,
    customerData,
    invalidateQueries,
    setCustomerData,
    data,
  ]);

  return (
    <>
      <Modal
        show={!!visible}
        className={classnames(className, modalStyles)}
        title={t('customer.inviteConverConfirmation.title')}
        footer={
          <>
            <Button
              className="w-1/2"
              color="info"
              isLoading={isLoading}
              onClick={confirmCustomerInvitation}
            >
              {t('customer.inviteConverConfirmation.buttonOnAccept')}
            </Button>
            <Button className="w-1/2" color="gray" onClick={close}>
              {t('customer.inviteConverConfirmation.buttonOnClose')}
            </Button>
          </>
        }
        onClose={close}
      >
        <span className="font-light">
          {t('customer.inviteConverConfirmation.description')}
        </span>
      </Modal>
      <Modal
        show={!!showDuplicatedEmailAlert}
        className={classnames(className, modalStyles)}
        title={t('customer.duplicatedEmailAlert.title')}
        footer={
          <Stack className="w-full" direction="row" justifyContent="end">
            <Button
              className="w-1/2"
              color="gray"
              onClick={() => {
                close();
                setShowDuplicatedEmailAlert(false);
              }}
            >
              {t('common.cancel')}
            </Button>
          </Stack>
        }
        onClose={() => {
          close();
          setShowDuplicatedEmailAlert(false);
        }}
      >
        <span className="font-light">
          {t('customer.duplicatedEmailAlert.description', {
            customers: data?.map((item) => item.name).join(', '),
          })}
        </span>
      </Modal>
    </>
  );
};

export default InvitationConfirmation;
