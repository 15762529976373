import { useCallback, useEffect } from 'react';
import { useApiInvalidateQueries, useApiMutation, useApiQuery } from 'src/api';
import { ConversationDetails } from './ConversationDetails';
import { useNavigate } from 'react-router-dom';
import {
  CommonStyles,
  ConversationParticipantType,
  NullConvoView,
  SmartSpinnerLocal,
  Stack,
  conversationDataType,
  getBranches,
  initialConversationDataState,
  useIsSelectedRange,
  useStateProvider,
  useUserProfile,
} from '@trueconnect/tp-components';
import { useSearchParams } from 'react-router-dom';
import classnames from 'classnames';
import { useAsync } from 'react-use';
import { getContactImage } from 'src/utils';

type ConversationContainerType = {
  isSearching: boolean;
  hasConversations: boolean | undefined;
  conversationId?: string;
  isLoading?: boolean;
};

export const ConversationContainer: React.FC<ConversationContainerType> = ({
  isSearching,
  hasConversations,
  conversationId = '',
  isLoading: isDataLoading,
}) => {
  const invalidateQueries = useApiInvalidateQueries();
  const navigate = useNavigate();
  const { userId } = useUserProfile();
  const { actions, state } = useStateProvider();
  const { setConversationData, setConversationType } = actions;
  const { conversationFilter } = state.conversation;
  const { availableBranches } = state.workManagement;

  const {
    data: conversationData,
    isLoading,
    fetchStatus,
  } = useApiQuery('getConversationById', [conversationId], {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    retry(failureCount, _) {
      if (failureCount >= 1) navigate('/conversations?err=not_found');
      return true;
    },
    enabled: !!conversationId,
  });

  const isConversationMember = !!conversationData?.participants?.find(
    (item) => item.userId === userId
  );

  useAsync(async () => {
    const participants = await Promise.all(
      conversationData?.participants?.map(async (item) => {
        return {
          ...item,
          image: await getContactImage(item.imageId),
          customerId: conversationData.customerId,
        } as ConversationParticipantType;
      }) || []
    );

    setConversationData({
      ...conversationData,
      conversationId,
      isConversationMember,
      notes: conversationData?.notes || '',
      readOnly: !(conversationData?.state === 'Open' && isConversationMember),
      disableUpload: !(
        conversationData?.state === 'Open' && isConversationMember
      ),
      participants,
      lastActive: conversationData?.lastMessageUtcTimestamp,
      lastReadMessagesTimestamp: conversationData?.lastReadMessagesUtcTimestamp,
      branches: getBranches(availableBranches, conversationData?.branchIds),
    } as conversationDataType);

    if (
      conversationData?.state &&
      conversationFilter !== conversationData?.state
    ) {
      setConversationType(conversationData?.state);
    }

    if (conversationData?.id && fetchStatus == 'idle' && isConversationMember) {
      setLastReadMessages(true);
    }
  }, [conversationId, fetchStatus]);

  useEffect(() => {
    return () => {
      setConversationData(initialConversationDataState);
      if (conversationData?.id && isConversationMember) {
        setLastReadMessages(false);
      }
    };
  }, [conversationId]);

  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('searchConversations');
  }, [invalidateQueries]);

  const { mutate } = useApiMutation(
    'set_Participant_Conversation_Messages_Last_Read_Time'
  );

  const setLastReadMessages = (clear: boolean) => {
    conversationData?.id && mutate([conversationData?.id]);
    clear && invalidateQuery();
  };

  const [searchParams] = useSearchParams();
  const err = searchParams.get('err');

  const isMobileView = useIsSelectedRange(['sm']);

  return (
    <>
      {err ? (
        <NullConvoView type="notFound" />
      ) : (
        <>
          {conversationId ? (
            <SmartSpinnerLocal
              name="ConversationDetails"
              condition={
                !state.conversation.conversationData?.title || isLoading
              }
            >
              <ConversationDetails />
            </SmartSpinnerLocal>
          ) : (
            <Stack
              className={classnames(
                CommonStyles.full,
                isMobileView && 'hidden'
              )}
            >
              <SmartSpinnerLocal condition={isDataLoading} name="tester">
                {hasConversations || isSearching ? (
                  <NullConvoView type="nothingIsSelected" />
                ) : (
                  <NullConvoView type="nothingIsCreated" />
                )}
              </SmartSpinnerLocal>
            </Stack>
          )}
        </>
      )}
    </>
  );
};
