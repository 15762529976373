import {
  Avatar,
  IconButton,
  Stack,
  XMarkIcon,
  defaultScrollbar,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { selectedUserType } from 'src/components/permissions/PermissionDialog';

export const SelectedUserList = ({
  selectedUsers,
  manageSelectedParticipants,
}: {
  selectedUsers: selectedUserType[];
  manageSelectedParticipants: (newItem: selectedUserType) => void;
}) => {
  return (
    <Stack
      direction="row"
      className={classnames(
        'overflow-auto py-3 flex-wrap max-h-[120px] w-full',
        defaultScrollbar
      )}
    >
      {selectedUsers.map((item) => (
        <div key={item.contactId} className="px-2">
          <Stack
            direction="row"
            alignItems="center"
            onClick={() => {
              manageSelectedParticipants(item);
            }}
          >
            <Avatar imgSrc={item.logo} initials={item.displayName} />
            <span className="whitespace-nowrap font-semibold">
              {item.displayName}
            </span>
            <IconButton
              onClick={() => {
                manageSelectedParticipants(item);
              }}
            >
              <XMarkIcon />
            </IconButton>
          </Stack>
        </div>
      ))}
    </Stack>
  );
};

export default SelectedUserList;
