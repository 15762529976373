import { Button } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { DialogProps } from '../StatusBadge/states';
import {
  ContactStatus,
  useApiInvalidateQueries,
  useApiMutation,
} from 'src/api';
import { useCallback } from 'react';
import { modalStyles, useStateProvider } from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';

export const Deactivate: React.FC<DialogProps> = ({
  contactId,
  close,
  visible,
}) => {
  const { t } = useTranslation();
  const invalidateQueries = useApiInvalidateQueries();
  const { customerData } = useStateProvider().state.customer;
  const { setCustomerData } = useStateProvider().actions;

  const { mutate: changeContactStatus } = useApiMutation('changeContactStatus');

  const deactivateUser = useCallback(async () => {
    await changeContactStatus([contactId, ContactStatus.Deactivated], {
      onSuccess: () => {
        invalidateQueries('searchContacts');
        invalidateQueries('getCustomerContactByUserId');
      },
    });
    close();
    setCustomerData({ ...customerData, status: ContactStatus.Deactivated });
  }, [
    changeContactStatus,
    close,
    contactId,
    customerData,
    invalidateQueries,
    setCustomerData,
  ]);

  return (
    <Modal
      size="md"
      onClose={close}
      show={!!visible}
      className={modalStyles}
      showFooter={false}
      showTitle={false}
    >
      <h3 className="mb-5 text-xl font-semibold pt-4">
        {t('customer.deactivate.modal.title')}
      </h3>
      <span className="font-light">
        {t('customer.deactivate.modal.description')}
      </span>
      <div className="flex flex-col justify-center items-center gap-4 pt-10">
        <Button className="w-80" color="failure" onClick={deactivateUser}>
          {t('customer.deactivate.modal.buttonOnAccept')}
        </Button>
        <Button className="w-80" color="gray" onClick={close}>
          {t('customer.deactivate.modal.buttonOnClose')}
        </Button>
      </div>
    </Modal>
  );
};

export default Deactivate;
