import {
  ConversationParticipantType,
  useStateProvider,
  DropDownItemType,
  Dropdown,
  IconButton,
  EllipsisHorizontalIcon,
  useIsSelectedView,
  ContactStatus,
  XMarkIcon,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import { indicatorStates } from 'src/components/StatusBadge/states';

export const Actions = ({
  item,
  setMemberIds,
}: {
  item: ConversationParticipantType;
  setMemberIds: (ids: string[]) => void;
}) => {
  const { t } = useTranslation();
  const { readOnly } = useStateProvider().state.conversation.conversationData;

  const isMobileView = useIsSelectedView('sm');
  const { contactId, email, status, customerId, displayName } = item;
  const { color, icon, border } =
    indicatorStates[status || ContactStatus.Active];
  const { setCustomerData, setToggleCustomerModal } =
    useStateProvider().actions;

  const handleClick = () => {
    contactId &&
      customerId &&
      displayName &&
      setCustomerData({ contactId, email, status, customerId, displayName });
    setToggleCustomerModal(true);
  };

  const manageMember: DropDownItemType = {
    icon: icon,
    color: color,
    classNames: `text-${border} border-${border}`,
    text: status && t(`statusBadge.${status}`),
    onClick: handleClick,
    type: 'button',
  };

  const items: DropDownItemType[] = [
    {
      icon: <XMarkIcon data-testid="removeFromConversation" />,
      text: t('conversation.participant.remove.title'),
      classNames: 'text-[#E53935] border-[#E53935]',
      type: 'button',
      color: '#E53935',
      onClick: () => {
        if (item.userId) {
          setMemberIds([item.userId]);
        }
      },
    },
  ];

  if (
    isMobileView &&
    !readOnly &&
    status &&
    item.status !== ContactStatus.Active
  ) {
    items.unshift(manageMember);
  }

  return (
    <>
      {!item.isCreator && !readOnly && (
        <Dropdown
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="pr-2"
          label={
            <IconButton>
              <EllipsisHorizontalIcon data-testid={`${item.email}_action`} />
            </IconButton>
          }
          mobileView={isMobileView}
          items={items}
        />
      )}
    </>
  );
};

export default Actions;
