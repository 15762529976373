import {
  SidebarHeader,
  InformationNote,
  VirtualizedList,
} from './../../components';
import { CreateConversationIcon, Stack } from './../../controls';
import { conversationPageStyles, CommonStyles } from './../../styles';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { useStateProvider } from './../../providers';
import { ConversationListItemProps } from './ConversationListItemProps';
import { useTranslation } from 'react-i18next';
import { useIsSelectedRange, useIsSelectedView } from './../../utils';
import { UseInfiniteQueryResult } from '@tanstack/react-query';

export const ConversationSidebar = ({
  queryConversations,
  isSearching,
  rowRenderer,
}: {
  isSearching: boolean;
  queryConversations: UseInfiniteQueryResult<ConversationListItemProps[]>;
  rowRenderer: (item: ConversationListItemProps) => JSX.Element;
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { state } = useStateProvider();
  const { conversationData, conversationFilter } = state.conversation;
  const { selectedTopic } = state.workManagement;

  const isMobileView = useIsSelectedView('sm');
  const isTabletView = useIsSelectedRange(['sm', 'md', 'lg']);

  const notOwnedConversationOpen =
    !!id &&
    (conversationData.topicId == selectedTopic.id || !selectedTopic.id) &&
    (conversationData.state == conversationFilter ||
      conversationFilter == 'All') &&
    !queryConversations.data
      ?.flat(1)
      .map((item) => item.id)
      .includes(id);

  const finalQueryConversations = (
    notOwnedConversationOpen
      ? {
          ...queryConversations,
          data: [
            {
              ...conversationData,
              id: conversationData.conversationId,
            },
            ...(queryConversations.data || []),
          ],
        }
      : queryConversations
  ) as UseInfiniteQueryResult<ConversationListItemProps[]>;

  return (
    <Stack
      direction="col"
      className={classnames(
        conversationPageStyles.sidebar.wrapper(
          isTabletView,
          !isMobileView,
          !!id
        ),
        'relative w-full',
        isTabletView &&
          !isMobileView &&
          '!min-w-[340px] !max-w-[340px] w-[340px]'
      )}
      isResizable={!isTabletView}
      resizeProps={{
        width: 325,
        height: 0,
        resizeHandles: ['e'],
        minConstraints: [325, -1],
        maxConstraints: [500, -1],
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        className="w-full h-14 px-4 border-b"
      >
        <h1 className={classnames('leading-tight text-lg font-semibold')}>
          {t('sidebar.conversations')}
        </h1>
      </Stack>
      <SidebarHeader />
      <div className={classnames(CommonStyles.full, 'flex-1')}>
        <VirtualizedList
          {...{
            infinityQuery: finalQueryConversations,
            nothingFound: (
              <InformationNote
                wrapperClassnames="!mt-1"
                title={t(
                  isSearching || conversationFilter != 'All'
                    ? 'conversation.nothingIsFound.text'
                    : 'conversation.nothingIsCreated.text'
                )}
              />
            ),
            rowHeight: 120,
            columns: [
              {
                dataPropName: 'rowItem',
                render: rowRenderer,
              },
            ],
          }}
        />
      </div>
      {isMobileView && (
        <CreateConversationIcon className="absolute right-8 bottom-8" />
      )}
    </Stack>
  );
};

export default ConversationSidebar;
