import { LoginStyles, StackItem } from '@trueconnect/tp-components';
import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import classnames from 'classnames';

/** Default slide interval in milliseconds */
const DefaultSlideIntervalMs = 10000;

export interface ImageCarouselProps {
  className: string;
  images: string[];
  /** Set the time between change of images in milliseconds. Default is 10 seconds.*/
  intervalMs?: number;
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({
  images,
  className,
  intervalMs = DefaultSlideIntervalMs,
}) => {
  const [imageIdx, setImageIdx] = useState(0);
  const [fadeIn, setFadeIn] = useState(false);

  useInterval(() => {
    setFadeIn(false);
    setImageIdx((prevState) => (prevState + 1) % images.length);
  }, intervalMs);

  useEffect(() => {
    setTimeout(() => setFadeIn(true), 100);
  }, [imageIdx]);

  const lastImageIdx = (imageIdx || images.length) - 1;
  return (
    <StackItem
      className={classnames(LoginStyles.WelcomeImagePage.root, className)}
    >
      <div className={LoginStyles.WelcomeImagePage.imageContainer}>
        <img
          key={lastImageIdx}
          src={images[lastImageIdx]}
          alt=""
          className={LoginStyles.WelcomeImagePage.lastImage}
        />
        <img
          key={imageIdx}
          src={images[imageIdx]}
          alt=""
          className={LoginStyles.WelcomeImagePage.curImage(fadeIn)}
        />
      </div>
    </StackItem>
  );
};

export default ImageCarousel;
