type truncateStringProps = {
  str: string;
  maxLen?: number;
};

export const truncateString = ({ str, maxLen = 50 }: truncateStringProps) => {
  if (str.length > maxLen) {
    str = str.substring(0, maxLen - 2) + '..';
  }
  return str;
};

export default truncateString;
