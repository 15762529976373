import {
  XCircleIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/solid';
import { useRef, Dispatch, SetStateAction } from 'react';
import { useClickAway } from 'react-use';
import { IconButton } from './../components';
import Stack from './Stack';
import classnames from 'classnames';

export const inputStyle =
  '!border !border-gray-200 rounded-lg p-2 !h-10 !w-full focus:outline-none focus:border-gray-400';

export const secondaryInputStyle = '!border-b-2 !border-gray-200';

export const savedData =
  '!border-green-500 !placeholder-green-500 !text-green-500';

export function InfiniteScrollDropdownItem({
  clickAwayCondition,
  classNames,
  placeholder,
  table,
  setShowDropdown,
  showDropdown,
  isSavedCondition,
  setSearchParam,
  searchParam,
  tableContainerClassname,
  tableClassname,
  type = 'primary',
  icon,
}: {
  clickAwayCondition: boolean;
  classNames?: string;
  placeholder?: string;
  table: JSX.Element;
  isSavedCondition?: boolean;
  setShowDropdown: Dispatch<SetStateAction<boolean>>;
  showDropdown: boolean;
  setSearchParam: Dispatch<SetStateAction<string>>;
  searchParam: string;
  tableContainerClassname?: string;
  tableClassname?: string;
  type?: 'primary' | 'secondary';
  icon?: JSX.Element;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useClickAway(ref, () => {
    setShowDropdown(false);
    clickAwayCondition && setSearchParam('');
  });

  return (
    <div ref={ref}>
      <Stack
        className={classnames(
          inputStyle,
          isSavedCondition && !searchParam && savedData,
          classNames,
          'w-full h-[42px]'
        )}
        direction="row"
        alignItems="center"
        justifyContent="between"
        onClick={() => {
          setShowDropdown(true);
        }}
      >
        {icon && <IconButton hoverable={false}>{icon}</IconButton>}
        <input
          value={searchParam}
          className={classnames(
            'w-full focus:outline-none text-sm',
            isSavedCondition && !searchParam && savedData,
            classNames
          )}
          placeholder={placeholder}
          onChange={(e) => setSearchParam(e.target.value)}
        />
        <Stack direction="row" alignItems="center">
          {searchParam && (
            <IconButton
              onClick={() => {
                setSearchParam('');
              }}
            >
              <XCircleIcon />
            </IconButton>
          )}
          {type == 'primary' && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowDropdown(!showDropdown);
              }}
            >
              <IconButton>
                {showDropdown ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </IconButton>
            </div>
          )}
        </Stack>
      </Stack>
      {showDropdown && (
        <div
          className={classnames(
            '!absolute flex flex-col z-10 bg-white !left-0 w-full p-1 border',
            tableContainerClassname ? tableContainerClassname : '!top-[80px]'
          )}
        >
          <Stack className={classnames('w-full', tableClassname)}>
            {table}
          </Stack>
        </div>
      )}
    </div>
  );
}

export default InfiniteScrollDropdownItem;
