import {
  SmartSpinnerLocal,
  Stack,
  CustomerStyles,
  defaultScrollbar,
  useIsSelectedRange,
  useIsSelectedView,
  useStateProvider,
  CheckIcon,
  XCircleIcon,
  IconButton,
  XMarkIcon,
  Button,
  PlusSmallIcon,
  ToggleSwitch,
  RedirectButton,
  PlusIcon,
} from '@trueconnect/tp-components';
import { useParams } from 'react-router-dom';

import classnames from 'classnames';
import { useEffect, useState } from 'react';
import useGetCustomerData from '../useGetCustomerData';
import NotesPageBreadCrumb from './NotesPageBreadCrumb';
import { t } from 'i18next';
import { renderBadge } from '../columns';
import BranchFilter from '@trueconnect/tp-components/src/pages/Policy/BranchFilter';
import NotesList, { getGeneralNote } from './NotesList';
import NewNoteModal from './NewNoteModal';
import SyncStatus from '../SyncStatus';

export const NotesPage = () => {
  const { id = '' } = useParams();
  const parsedId = parseInt(id || '');
  const data = useGetCustomerData(parsedId);
  const { actions, state } = useStateProvider();
  const isMobileView = useIsSelectedView('sm');
  const isSelectedView = useIsSelectedRange(['sm', 'md']);
  const { setShowNavbar, setShowBottombar, setCompanyData } = actions;
  const { companyData } = state.customer;
  const generalNotesBranch = getGeneralNote();
  const availableBranches = [
    generalNotesBranch,
    ...state.workManagement.availableBranches,
  ];

  useEffect(() => {
    isMobileView && setShowNavbar(!id);
    isMobileView && setShowBottombar(!id);
    return () => {
      setShowNavbar(true);
      setShowBottombar(true);
    };
  }, [id, isMobileView, setShowBottombar, setShowNavbar]);

  useEffect(() => {
    data && setCompanyData(data);
    return () => {
      setCompanyData({});
    };
  }, [id, data, setCompanyData]);

  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedBranches, setSelectedBranches] = useState<string[]>([]);
  const [showCreateNoteModal, setShowCreateNoteModal] = useState(false);
  const [showSelectBranchNotification, setShowSelectBranchNotification] =
    useState(true);

  const setBranches = (branchId: string) => {
    if (selectedBranches.includes(branchId)) {
      setSelectedBranches(selectedBranches.filter((item) => item != branchId));
    } else {
      setSelectedBranches([...selectedBranches, branchId]);
    }
  };

  const [branchToAddNote, setBranchToAddNote] = useState<string>('');

  return (
    <SmartSpinnerLocal name="DataViewIsDataLoaded" condition={!data?.id}>
      <Stack direction="row" className={classnames('w-full h-full relative')}>
        <div
          className={classnames(
            CustomerStyles.wrapper,
            'absolute top-0 left-0'
          )}
        >
          {showSelectBranchNotification && !isMobileView && (
            <Stack
              direction="row"
              className="w-full h-[42px] px-5 text-white bg-customColor_5"
              alignItems="center"
              justifyContent="between"
            >
              <p>{t('customer.notes.selectBranchNotification')}</p>
              <IconButton
                hoverable={false}
                className="!m-0"
                onClick={() => {
                  setShowSelectBranchNotification(false);
                }}
              >
                <XMarkIcon color="white" />
              </IconButton>
            </Stack>
          )}
          <Stack
            direction="col"
            className={classnames(
              'w-full',
              isMobileView ? '' : 'pl-8 pr-5 pt-5 [&>div>div]:mb-0'
            )}
          >
            {!isMobileView && (
              <Stack>
                <NotesPageBreadCrumb id={parsedId} customerData={data} />
              </Stack>
            )}
            <Stack direction="col" className="w-full">
              <Stack
                direction={isMobileView ? 'col' : 'row'}
                className={classnames(
                  'mb-2 w-full mt-2',
                  isMobileView && 'gap-3'
                )}
                alignItems={isMobileView ? 'start' : 'center'}
                justifyContent="between"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="between"
                  className={classnames(
                    'h-[50px] w-full',
                    isMobileView && 'ml-2 mt-2 pl-[50px]'
                  )}
                >
                  {isMobileView && (
                    <RedirectButton
                      className="absolute top-6 left-4"
                      path={`/customers/${id}`}
                    />
                  )}
                  <Stack direction="col" className="w-full">
                    <Stack
                      direction="row"
                      className="w-full pн-1"
                      alignItems="center"
                      justifyContent="between"
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="between"
                      >
                        <p
                          className={classnames(
                            CustomerStyles.header,
                            'overflow-hidden whitespace-nowrap',
                            isMobileView && 'text-base'
                          )}
                        >
                          {data?.name}
                        </p>
                        <div className="mx-4">
                          {data?.isCurrentTrueDataCustomer &&
                            renderBadge(CheckIcon, 'success')}
                          {data?.isFormerTrueDataCustomer &&
                            renderBadge(XCircleIcon, 'failure')}
                        </div>
                      </Stack>
                    </Stack>
                    {isMobileView && (
                      <SyncStatus
                        active={true}
                        className="[&>div>div]:m-0 [&>div]:w-6 px-0"
                      />
                    )}
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  className={classnames(
                    '[&>div]:!h-[44px] [&>div>div:first]:!h-[44px] [&>button]:!h-[44px] gap-[18px]',
                    isMobileView &&
                      'w-full [&>div]:w-full [&>div>div]:w-full !px-2'
                  )}
                >
                  <BranchFilter
                    {...{
                      setBranches,
                      branchIds: selectedBranches,
                      availableBranches,
                      isMobileView,
                      mode: 'secondary',
                      placeholder: t('customer.notes.selectBranch'),
                    }}
                  />
                  {!isSelectedView && (
                    <Button
                      className={'text-nowrap'}
                      dataTestid="addNote"
                      icon={<PlusSmallIcon />}
                      variant="primary"
                      onClick={() => {
                        setShowCreateNoteModal(true);
                      }}
                    >
                      {t('customer.notes.addNoteBtn')}
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <div
            className={classnames(
              'w-full h-full overflow-y-auto overflow-x-hidden',
              defaultScrollbar,
              !isMobileView && 'pl-8 pr-1 pb-3',
              isMobileView && '!pl-2'
            )}
          >
            <NotesList
              {...{
                customerId: companyData?.id,
                selectedBranches,
                availableBranches,
                isEditMode,
                onAdd: (id) => {
                  setBranchToAddNote(id);
                  setShowCreateNoteModal(true);
                },
              }}
            />
          </div>
          <Stack
            direction="row"
            alignItems="center"
            className="w-full h-[80px] px-8 border-t"
          >
            <Stack direction="row" alignItems="center" className="gap-4">
              <p>
                {isEditMode
                  ? t('customer.notes.editModeOn')
                  : t('customer.notes.editModeOff')}
              </p>
              <ToggleSwitch
                data-testid="EditModeSwitch"
                {...{
                  checked: isEditMode,
                  onChange: (val) => {
                    setIsEditMode(val);
                  },
                }}
              />
            </Stack>
          </Stack>
        </div>
        {isSelectedView && (
          <Button
            variant="primary"
            dataTestid="addNote"
            className={classnames(
              'h-14 w-14 text-[#0170df] rounded-full absolute bottom-8 right-8'
            )}
            onClick={() => {
              setShowCreateNoteModal(true);
            }}
          >
            <IconButton hoverable={false}>
              <PlusIcon className="text-white" />
            </IconButton>
          </Button>
        )}
      </Stack>
      <NewNoteModal
        {...{
          isVisible: showCreateNoteModal,
          defaultBranch: branchToAddNote,
          availableBranches,
          onClose: () => {
            setShowCreateNoteModal(false);
            setBranchToAddNote('');
          },
        }}
      />
    </SmartSpinnerLocal>
  );
};

export default NotesPage;
