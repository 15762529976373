declare global {
  interface Window {
    _tpConfig: {
      TC_REACT_ENV_CLIENTID: string;
      TC_REACT_ENV_AUTHORITY: string;
      TC_REACT_ENV_SCOPES: string;
      TC_REACT_ENV_PUBLIC_BLOB_STORAGE_ACCOUNT_NAME: string;
      TC_REACT_ENV_PUBLIC_BLOB_STORAGE_MAINTENANCE_FOLDER_NAME: string;
    };
  }
}

const overrideConfig = window._tpConfig || {};

export const msalConfig = {
  auth: {
    clientId:
      process.env.REACT_APP_CLIENTID || overrideConfig['TC_REACT_ENV_CLIENTID'],
    authority:
      process.env.REACT_APP_AUTHORITY ||
      overrideConfig['TC_REACT_ENV_AUTHORITY'],
    redirectUri:
      process.env.REACT_APP_REDIRECT_TO_SELF_BASEURL ||
      `${window.location.protocol}//${window.location.host}`,
    postLogoutRedirectUri:
      process.env.REACT_APP_REDIRECT_TO_SELF_BASEURL ||
      `${window.location.protocol}//${window.location.host}`,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    tokenRenewalOffsetSeconds: 60 * 5,
  },
};

// Add scopes here for ID token to be used at web api endpoint.
export const loginRequest = {
  scopes: [
    process.env.REACT_APP_SCOPES || overrideConfig['TC_REACT_ENV_SCOPES'],
  ],
};

export const blobStorageAccountName =
  process.env.REACT_APP_PUBLIC_BLOB_STORAGE_ACCOUNT_NAME ||
  overrideConfig['TC_REACT_ENV_PUBLIC_BLOB_STORAGE_ACCOUNT_NAME'];

export const maintenanseFolder =
  process.env.REACT_APP_PUBLIC_BLOB_STORAGE_MAINTENANCE_FOLDER_NAME ||
  overrideConfig['TC_REACT_ENV_PUBLIC_BLOB_STORAGE_MAINTENANCE_FOLDER_NAME'];
