export function arraysEqual<T>(a: T[], b: T[]): boolean {
  if (Array.isArray(a) && Array.isArray(b) && a.length === b.length) {
    // Sort the arrays before comparing
    const sortedA = a.slice().sort();
    const sortedB = b.slice().sort();
    return sortedA.every((val, index) => val === sortedB[index]);
  }
  return false;
}

export default arraysEqual;
