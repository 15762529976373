type ExtraAction =
  | { type: 'SHOW_NAVBAR'; payload: boolean }
  | { type: 'SHOW_BOTTOMBAR'; payload: boolean };

type ExtraState = {
  showNavbar: boolean;
  showBottomBar: boolean;
};

export const initialExtraState: ExtraState = {
  showNavbar: true,
  showBottomBar: true,
};

const extraReducer = (
  state: ExtraState = initialExtraState,
  action: ExtraAction
): ExtraState => {
  switch (action.type) {
    case 'SHOW_NAVBAR':
      return { ...state, showNavbar: action.payload };
    case 'SHOW_BOTTOMBAR':
      return { ...state, showBottomBar: action.payload };
    default:
      return state;
  }
};

export { extraReducer };
export type { ExtraAction };
export type { ExtraState };
