import { useApiQuery } from 'src/api';

export const GetFileUrl = (_: string, documentId: string) => {
  const { data, refetch } = useApiQuery('getDocumentById', [documentId], {
    enabled: false,
  });

  return {
    getUrl: () => {
      refetch();
    },
    url: data?.preViewUrl,
  };
};

export default GetFileUrl;
