/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BranchBadge,
  IconButton,
  PlusIcon,
  Stack,
  branchType,
  customScrollbar,
  Button,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { noteType } from './typing';
import classnames from 'classnames';
import NoteListItem from './NoteListItem';
import { Dispatch, SetStateAction } from 'react';
import { t } from 'i18next';

export type NotesCarouselsType = {
  branch: branchType;
  notes: noteType[];
};

export const NotesCarousels = ({
  data,
  onAdd,
  isEditMode,
  customerId,
  setNoteIdToDelete,
  setNoteToCopy,
}: {
  data: NotesCarouselsType;
  isEditMode?: boolean;
  onAdd: (id: string) => void;
  customerId: number | undefined;
  setNoteIdToDelete: Dispatch<SetStateAction<string>>;
  setNoteToCopy: Dispatch<SetStateAction<noteType | undefined>>;
}) => {
  const isMobileView = useIsSelectedView('sm');

  return (
    <Stack className="w-full min-h-[380px] max-h-[380px] pr-2 pt-4">
      <Stack direction="row" alignItems="center" className="gap-2">
        <BranchBadge item={data.branch} />
        {isMobileView && (
          <Button
            size="xs"
            className="h-[22px]"
            onClick={() => {
              onAdd(data.branch.id || '');
            }}
          >
            {t('customer.notes.addNoteBtn')}
          </Button>
        )}
      </Stack>
      <Stack
        direction="row"
        className={classnames(
          'w-full h-full pt-4 pb-2 gap-4 overflow-x-scroll overflow-y-hidden',
          customScrollbar
        )}
      >
        {data.notes.map((item) => (
          <NoteListItem
            key={item.id}
            {...{
              item,
              isEditMode,
              customerId,
              setNoteIdToDelete,
              setNoteToCopy,
            }}
          />
        ))}
        {!isMobileView && (
          <Stack
            className={classnames(
              'min-w-[254px] p-6 max-w-[254px] h-full border rounded-lg shadow-sm',
              'bg-customColor_2 hover:bg-[#E5E7EB] cursor-pointer'
            )}
            alignItems="center"
            justifyContent="center"
            onClick={() => {
              onAdd(data.branch.id || '');
            }}
          >
            <IconButton hoverable={false}>
              <PlusIcon color="#0170DF" />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default NotesCarousels;
