import {
  Stack,
  useStateProvider,
  hoverClassname,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { t } from 'i18next';

export const AllConversationsItem = ({ isFull }: { isFull?: boolean }) => {
  const { state, actions } = useStateProvider();
  const { selectedTopic } = state.workManagement;
  const { extendedSearchParams } = state.conversation;
  const { setSelectedTopic, setExtendedSearchParams } = actions;

  const id = selectedTopic.id;
  const { selectedTopics } = extendedSearchParams;

  const isNoTopicSelected = !id;
  return (
    <Stack
      direction="row"
      className={classnames(
        'justify-center py-2 cursor-pointer !min-h-14 border-b w-full',
        hoverClassname,
        isNoTopicSelected && 'bg-customColor_2'
      )}
      alignItems="center"
      onClick={() => {
        if (id && selectedTopics?.includes(id)) {
          setExtendedSearchParams({
            ...extendedSearchParams,
            selectedTopics: selectedTopics.filter((item) => item !== id),
          });
        }
        setSelectedTopic({});
      }}
    >
      <p
        className={classnames(
          'text-sm font-semibold leading-normal',
          isNoTopicSelected && 'text-customColor_5'
        )}
      >
        {t(`Topics.Sidebar.allConversations.${isFull ? 'long' : 'short'}`)}
      </p>
    </Stack>
  );
};
