import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { SignInButton } from 'src/components/SignInButton';
import {
  LoginStyles,
  MaintenanceInfo,
  SmartSpinnerLocal,
  Stack,
  textStyle_2,
  true_connect,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { isLoading, redirectUrlParameter } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

import {
  loginImage1,
  loginImage2,
  loginImage3,
  truePartnersLogo,
} from '@trueconnect/tp-components';
import ImageCarousel from '@trueconnect/tp-components/src/components/ImageCarousel';
import { blobStorageAccountName, maintenanseFolder } from 'src/authConfig';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { accounts, inProgress, instance } = useMsal();

  const accessTokenExists = accounts.length > 0 && !instance.getActiveAccount();

  useEffect(() => {
    if (isAuthenticated) {
      const redUrlParam = new URL(
        new URLSearchParams(window.location.search).get(redirectUrlParameter) ||
          `${window.location.protocol}//${window.location.host}`
      );
      const redirectUrl = `${redUrlParam.pathname}${redUrlParam.search}${redUrlParam.hash}`;
      navigate(redirectUrl);
    }
  }, [navigate, isAuthenticated, inProgress]);

  const imgProps = {
    width: '76',
    height: '24',
    className: classnames(LoginStyles.image, 'mx-4'),
  };

  return (
    <SmartSpinnerLocal
      name="Login"
      condition={isLoading(inProgress) || isAuthenticated || accessTokenExists}
      renderAsGlobal={true}
    >
      <Stack direction="col" className={'h-screen w-screen overflow-hidden'}>
        <MaintenanceInfo
          mode="banner"
          blobStorageAccountName={blobStorageAccountName}
          maintenanseFolder={maintenanseFolder}
        />
        <Stack alignItems="center" direction="row" className="w-full h-full">
          <div
            className={
              'lg:px-16 lg:py-16 flex-[0_0_40%] sm:min-w-full lg:min-w-[600px] h-full'
            }
          >
            <Stack
              direction="col"
              alignItems="stretch"
              className={LoginStyles.container}
            >
              <div className="h-12 px-16 py-4 sm:mb-6 lg:p-0">
                <div className="hidden sm:block">
                  <img
                    src={truePartnersLogo}
                    alt="True Partners"
                    width="156"
                    height="49"
                    className={LoginStyles.image}
                  />
                </div>
                <Stack
                  className="h-4 w-full sm:hidden block"
                  direction="row"
                  justifyContent="center"
                >
                  <img
                    src={truePartnersLogo}
                    alt="True Partners"
                    {...imgProps}
                  />
                  <div className="border-r-2 h-6 border-gray-400" />
                  <img src={true_connect} alt="True Connect" {...imgProps} />
                </Stack>
              </div>

              <Stack direction="col" className="h-full !w-full">
                <ImageCarousel
                  className={'w-full flex-1 h-[40%] lg:hidden block'}
                  images={[loginImage1, loginImage2, loginImage3]}
                />
                <Stack
                  className="mt-5 w-full flex-1 max-w-[500px] max-h-[320px] min-h-[320px] mx-auto px-8 lg:p-0 items-center lg:items-start"
                  direction="col"
                  justifyContent="center"
                >
                  <h1 data-testid="loginHeader" className={textStyle_2}>
                    {t('login.welcome.main')}
                  </h1>
                  <p className={LoginStyles.secondaryText}>
                    {t('login.welcome.secondary')}
                  </p>
                  <SignInButton />
                </Stack>
              </Stack>
            </Stack>
          </div>
          <ImageCarousel
            className={'h-screen min-w-[60%] hidden lg:block'}
            images={[loginImage1, loginImage2, loginImage3]}
          />
        </Stack>
      </Stack>
    </SmartSpinnerLocal>
  );
};

export default Login;
