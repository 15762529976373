import { useCallback, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import raw from 'rehype-raw';
import rehypeHighlight from 'rehype-highlight';
import { SearchInput, Stack } from './../../controls';
import { useTranslation } from 'react-i18next';
import {
  Highlighted,
  InformationNote,
  textStyle_2,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import HelpCenterBreadCrumb from './HelpCenterBreadCrumb';
import classnames from 'classnames';

import styles from './Help.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { AccordionCell } from './CustomAccordion';

export type HelpType = {
  title: string;
  content: string;
  contextURL: string;
  children: HelpType[];
};

const searchHelpTypes = ({
  data,
  searchParam = '',
}: {
  data: HelpType[];
  searchParam?: string;
}) => {
  if (!searchParam || searchParam.length < 2) {
    return data;
  }

  const results: HelpType[] = [];

  function searchRecursive({
    items = [],
    parent,
  }: {
    items?: HelpType[];
    parent?: HelpType;
  }): HelpType[] {
    const filteredChildren: HelpType[] = [];

    for (const item of items) {
      const filteredItem: HelpType = {
        ...item,
        children: searchRecursive({ items: item.children, parent: item }),
      };

      const isMatch = (value: string, search: string) => {
        return value.toLowerCase().includes(search.toLowerCase());
      };

      if (
        isMatch(filteredItem.title, searchParam) ||
        isMatch(filteredItem.content, searchParam) ||
        filteredItem.children.length > 0
      ) {
        const existingParent = parent
          ? results.find((result) => result.title === parent.title)
          : null;

        if (existingParent) {
          const existingChild = existingParent.children.find(
            (child) => child.title === filteredItem.title
          );

          if (!existingChild) {
            existingParent.children.push(filteredItem);
          }
        } else {
          const existingItem = results.find(
            (result) => result.title === filteredItem.title
          );

          if (!existingItem) {
            if (parent) {
              results.push({ ...parent, children: [filteredItem] });
            } else {
              results.push(filteredItem);
            }
          }
        }
      }
    }

    return filteredChildren;
  }

  searchRecursive({ items: data });

  return results;
};

export const Help: React.FC<{
  data: HelpType[];
  headerComponent?: React.ReactNode;
}> = ({ data, headerComponent }) => {
  const { t } = useTranslation();
  const isMobileView = useIsSelectedView('sm');
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const [searchParam, setSearchParam] = useState<string>('');
  const [filtedData, setFiltedData] = useState<HelpType[]>([]);
  const { value } = useParams();

  const scrollAndClick = useCallback(async () => {
    if (value) {
      const element = containerRef.current?.querySelector(
        `[data-testid="${value}"]`
      ) as HTMLElement | null;

      if (element) {
        await element.click();
        await element.scrollIntoView({ behavior: 'smooth' });
        navigate('/help');
      }
    }
  }, [navigate, value]);

  useEffect(() => {
    scrollAndClick();
  }, [value, filtedData.length, scrollAndClick]);

  useEffect(() => {
    setFiltedData(searchHelpTypes({ data, searchParam }));
  }, [data, searchParam]);

  const customHighlight = (str: string) => {
    return Highlighted(str, searchParam, {
      common: '',
      highlight: 'bg-[#0170df] text-white',
    });
  };

  return (
    <div
      className={classnames(
        'h-full w-full relative overflow-auto',
        styles.wrapper
      )}
    >
      <div
        ref={containerRef}
        className={classnames(
          'px-8 py-6 absolute w-full',
          isMobileView && '!py-1 !px-3'
        )}
      >
        <HelpCenterBreadCrumb />
        {isMobileView && (
          <p className="text-2xl font-bold">{t('sidebar.help')}</p>
        )}
        <Stack
          className={classnames(
            'w-full h-fit',
            isMobileView && 'flex-col-reverse'
          )}
          direction={isMobileView ? 'col' : 'row'}
          alignItems="start"
        >
          <div className="w-full">
            {!isMobileView && (
              <p className="text-2xl font-bold">{t('sidebar.help')}</p>
            )}
            <SearchInput
              setSearchParam={setSearchParam}
              classNames={{
                wrapper: classnames(
                  'w-[80%] bg-customColor_7 mt-8 h-12',
                  isMobileView && 'w-full'
                ),
                input: 'bg-customColor_7',
              }}
              placeholder={t('settings.inputPlaceholder') as string}
            />
          </div>
          {headerComponent}
        </Stack>
        {!filtedData.length && (
          <InformationNote title={t('HelpPage.nothingFound')} />
        )}
        {filtedData.map((item) => (
          <Stack key={item.title} className="w-full">
            <p
              className={classnames(
                'text-xl leading-normal',
                isMobileView ? 'pt-5 pb-2' : 'pt-8 pb-4',
                textStyle_2
              )}
            >
              {customHighlight(item.title)}
            </p>
            <div
              className={classnames(
                'w-full mb-4 border rounded-lg [&>div:not(:last-child)]:border-b'
              )}
            >
              {item.children.map((child) => (
                <AccordionCell
                  key={child.title}
                  searchParam={searchParam}
                  title={customHighlight(child.title)}
                  data-testid={child.title.replaceAll(' ', '_')}
                >
                  <ReactMarkdown
                    transformImageUri={(uri: string) =>
                      uri.startsWith('http') || uri.startsWith('https')
                        ? uri
                        : `/helpAttachments${uri}`
                    }
                    remarkPlugins={[gfm]}
                    rehypePlugins={[raw, rehypeHighlight]}
                    className={styles.markdown}
                  >
                    {searchParam
                      ? child.content.replace(
                          new RegExp(searchParam, 'gi'),
                          `<span className="bg-[#0170df] text-white">$&</span>`
                        )
                      : child.content}
                  </ReactMarkdown>
                </AccordionCell>
              ))}
            </div>
          </Stack>
        ))}
      </div>
    </div>
  );
};

export default Help;
