import { KeyboardEvent, useEffect } from 'react';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { MessageType } from './Message';
import ChatBottom from './ChatBottom';
import MessagesComponent from './MessagesComponent';
import { useGetFileUrlFull } from './../../../types';
import { useStateProvider } from './../../../providers';
import { getUnsentMessage } from './../../../utils';

export interface DownloadDocumentInfoDto {
  documentId?: string | undefined;
  signedUrl?: string | undefined;
}

export type ChatProps = {
  conversationId: string;
  user: string;
  queryMessages: UseInfiniteQueryResult<MessageType[]>;
  sendMessage: (message: string) => void;
  visible?: boolean;
  messages: MessageType[];
  lastReadMessagesTimestamp?: Date;
} & useGetFileUrlFull;

export const Chat: React.FC<ChatProps> = ({
  conversationId,
  user,
  queryMessages,
  sendMessage,
  visible,
  useGetFileUrl,
  messages,
  lastReadMessagesTimestamp,
}) => {
  const { state, actions } = useStateProvider();

  const { canSendMessage, message } = state.chat;
  const {
    setToggleShouldScrollToBottom,
    setMessage,
    setSelectedFiles,
    setIsUploadPanelVisible,
  } = actions;

  useEffect(() => {
    const { text = '', attachments = [] } = getUnsentMessage(conversationId);
    setMessage(text);
    setSelectedFiles(attachments);
    setIsUploadPanelVisible(attachments.length !== 0);

    return () => {
      setMessage('');
    };
  }, [conversationId, setIsUploadPanelVisible, setMessage, setSelectedFiles]);

  const handleSend = () => {
    if (message.trim()) {
      sendMessage(message.trim());
      setToggleShouldScrollToBottom(true);
      setMessage('');
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && canSendMessage) {
      if (e.ctrlKey) {
        handleSend();
        e.preventDefault();
      }
    }
  };

  return (
    <>
      <MessagesComponent
        {...{
          messages,
          queryMessages,
          user,
          className: visible ? 'invisible' : '',
          useGetFileUrl: (documentId) =>
            useGetFileUrl(conversationId, documentId),
          lastReadMessagesTimestamp,
        }}
      />

      <ChatBottom
        {...{
          className: visible ? 'invisible' : '',
          onKeyDown,
          handleSend,
          canSendMessage,
        }}
      />
    </>
  );
};

export default Chat;
