import {
  Stack,
  Button,
  useStateProvider,
  CheckBox,
  TaskType,
  SearchInput,
  searchInputStyles,
} from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';

import { t } from 'i18next';
import DeleteTaskModal from './DeleteTaskModal';
import { useGetColumns } from 'src/pages/App/Tasks/Tabs/Backlog/columns';
import { Dispatch, SetStateAction, useState } from 'react';
import { useApiInfiniteQuery } from 'src/api';
import { BacklogTable } from 'src/pages/App/Tasks/Tabs/Backlog/BacklogTable';

export type batchUpdateTask = {
  id: string | undefined;
  topicId?: string | null;
  conversationId?: string | null;
};

export const AddTaskModal = ({
  onAccept,
  isLoading,
  customerId,
  parentId,
  editedTasks,
  setEditedTasks,
}: {
  onAccept: () => void;
  isLoading?: boolean;
  editedTasks: batchUpdateTask[];
  setEditedTasks: Dispatch<SetStateAction<batchUpdateTask[]>>;
  customerId?: number;
  parentId?: string;
}) => {
  const { state, actions } = useStateProvider();
  const { showAddTask } = state.workManagement;
  const { setShowAddTask } = actions;
  const columns = useGetColumns({});

  const [searchParam, setSearchParam] = useState('');
  const tasksQuery = useApiInfiniteQuery(
    'searchTasks',
    [
      {
        title: searchParam,
        orderBy: undefined,
        customerIds: customerId ? [customerId] : [],
        page: 0,
      },
    ],
    {
      select: (data) =>
        [
          ...data.pages.flatMap((item) =>
            item.map((subitem) => subitem as TaskType)
          ),
        ].filter(
          (item) => item.conversationId == parentId || !item.conversationId
        ),
      enabled: showAddTask,
    }
  );

  const alreadyAdded = tasksQuery.data
    ?.flatMap((itm) => itm)
    .filter((itm) => !!itm.conversationId)
    .map((itm) => itm.id);

  const onClick = (task: TaskType) => {
    if (alreadyAdded?.includes(task.id)) {
      const foundTask = editedTasks.find((tsk) => tsk.id === task.id);
      if (!foundTask) {
        setEditedTasks([
          ...editedTasks,
          {
            id: task.id,
            topicId: task.topicId,
            conversationId: null,
          },
        ]);
      } else {
        setEditedTasks(editedTasks.filter((itm) => itm.id !== task.id));
      }
    } else {
      const foundTask = editedTasks.find((tsk) => tsk.id === task.id);
      if (foundTask) {
        setEditedTasks(editedTasks.filter((itm) => itm.id !== task.id));
      } else {
        setEditedTasks([
          ...editedTasks,
          {
            id: task.id,
            topicId: task.topicId,
            conversationId: parentId,
          },
        ]);
      }
    }
  };

  const isSelected = (item: TaskType) => {
    const editedItem = editedTasks.find((itm) => itm.id == item.id);
    if (editedItem?.conversationId) {
      return true;
    }
    if (!editedItem) {
      return !!alreadyAdded?.includes(item.id);
    }
    return false;
  };

  const onClose = () => {
    setShowAddTask(false);
  };

  const finalColumns = [
    ...columns.filter(
      (item) => !['actions', 'updated'].includes(item.dataPropName)
    ),
    {
      dataPropName: 'checkbox',
      render: (item: TaskType) => (
        <>
          <Stack
            direction="row"
            className="w-full"
            justifyContent="center"
            onClick={() => {
              onClick(item);
            }}
          >
            <CheckBox
              {...{
                checked: isSelected(item),
                onChange: () => {},
              }}
            />
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Modal
        size="7xl"
        title={t('Tasks.modals.addTask.action')}
        show={showAddTask}
        onClose={onClose}
        footer={
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            className="w-full gap-5"
          >
            <Button onClick={onClose}>{t('common.cancel')}</Button>
            <Button
              color="blue"
              isLoading={isLoading}
              onClick={onAccept}
              dataTestid="acceptAddTask"
            >
              {t('common.save')}
            </Button>
          </Stack>
        }
      >
        <Stack
          direction="row"
          className="w-full px-3 pb-4"
          justifyContent="end"
          alignItems="center"
        >
          <SearchInput
            setSearchParam={setSearchParam}
            classNames={{
              wrapper: searchInputStyles,
              input: 'text-sm',
            }}
            placeholder={t('Tasks.searchPlaceholder')}
            saveHistory={true}
            inputName="TaskSearch"
            dataTestid="TaskSearch"
          />
        </Stack>
        <Stack className="h-[400px]">
          <BacklogTable
            {...{
              columns: finalColumns,
              tasksQuery,
            }}
          />
        </Stack>
      </Modal>
      <DeleteTaskModal />
    </>
  );
};

export default AddTaskModal;
