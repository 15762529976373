import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { t } from 'i18next';
import { Stack, useIsSelectedView } from '@trueconnect/tp-components';
import classnames from 'classnames';
import {
  ContactPersonListItem,
  ContactPersonType,
} from './ContactPersonListItem';

export const ContactPersonsModal = ({
  visible,
  onClose,
  contacts,
}: {
  visible?: boolean;
  onClose?: () => void;
  contacts: ContactPersonType[];
}) => {
  const isMobileView = useIsSelectedView('sm');

  if (!visible) {
    return <></>;
  }

  return (
    <Modal
      show={visible}
      size="xl"
      onClose={onClose}
      showFooter={false}
      title={t('customer.informationPage.contactPersons')}
      className={classnames(
        '[&>div>div]:h-full [&>div>div]:max-h-[100vh]',
        isMobileView && '[&>div]:max-w-full',
        '[&>div>div>div:nth-child(2)]:px-2 [&>div>div>div:nth-child(2)]:py-3'
      )}
    >
      <Stack className="w-full h-full gap-3 pt-2">
        {contacts.map((item) => (
          <div
            key={item.id}
            className="w-full flex flex-row items-center [&>div]:!w-full"
          >
            <ContactPersonListItem {...{ item }} />
          </div>
        ))}
      </Stack>
    </Modal>
  );
};

export default ContactPersonsModal;
