type ConfigActionType =
  | 'TOGGLE_SHOW_FULL_SIDEBAR'
  | 'SET_LANGUAGE'
  | 'ClOSE_SIDEBAR';

export interface ConfigState {
  FullSidebar: boolean;
  language?: string;
}
export type payloadType = any; // todo find a way yo avoid any

export interface ConfigActions {
  [key: string]: (payload?: payloadType) => ConfigAction;
}

interface ConfigAction {
  type: ConfigActionType;
  payload?: payloadType;
}

export const configActions: ConfigActions = {
  toggleSidebar: (isFullMode: boolean) => ({
    type: 'TOGGLE_SHOW_FULL_SIDEBAR',
    payload: isFullMode,
  }),
  setLanguage: (language: string) => ({
    type: 'SET_LANGUAGE',
    payload: language,
  }),
  closeSidebar: () => ({
    type: 'ClOSE_SIDEBAR',
  }),
};

export function configReducer(
  state: ConfigState,
  action: ConfigAction
): ConfigState {
  switch (action.type) {
    case 'TOGGLE_SHOW_FULL_SIDEBAR':
      return {
        ...state,
        FullSidebar: !state.FullSidebar,
      };
    case 'ClOSE_SIDEBAR':
      return {
        ...state,
        FullSidebar: false,
      };
    case 'SET_LANGUAGE':
      return {
        ...state,
        language:
          action.payload !== undefined ? action.payload : state.language,
      };
    default:
      return state;
  }
}

export interface ConfigContextValue {
  configState: ConfigState;
  configActions: ConfigActions;
}
