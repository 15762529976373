import { UseInfiniteQueryResult } from '@tanstack/react-query';
import {
  Stack,
  CommonStyles,
  VirtualizedList,
  ConversationListItem,
  ConversationListItemProps,
  useStateProvider,
  Button,
} from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { initialCustomerState } from '@trueconnect/tp-components/src/providers/Customers/reducers';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

export const ExistingConversationsModal = ({
  setPermissions,
  onClose,
  userConversations,
  showContactConversations,
  setShowContactConversations,
}: {
  setPermissions: () => void;
  onClose: () => void;
  userConversations: UseInfiniteQueryResult<ConversationListItemProps[]>;
  showContactConversations: boolean;
  setShowContactConversations: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const { state, actions } = useStateProvider();
  const { customerData } = state.customer;
  const { setCustomerData, setToggleCustomerModal } = actions;

  const rowRenderer: React.FC<ConversationListItemProps> = (
    item: ConversationListItemProps
  ) => {
    return (
      <Stack
        className="w-full h-full"
        onClick={() => {
          setShowContactConversations(false);
          onClose();
          setToggleCustomerModal(false);
          setCustomerData(initialCustomerState.customerData);
        }}
      >
        <ConversationListItem {...item} />
      </Stack>
    );
  };

  return (
    <Modal
      size="2xl"
      title={t('permissions.existingConversations.header', {
        displayName: customerData.displayName,
      })}
      show={showContactConversations}
      onClose={() => {
        setShowContactConversations(false);
      }}
      className="!z-50"
      footer={
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          className="w-full gap-5"
        >
          <Button
            onClick={() => {
              setShowContactConversations(false);
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            color="blue"
            onClick={setPermissions}
            dataTestid="withdrawExistingConversations"
          >
            {t('common.save')}
          </Button>
        </Stack>
      }
    >
      <Stack className={'h-[400px]'}>
        <p className="pb-5">
          {t('permissions.existingConversations.body', {
            displayName: customerData.displayName,
          })}
        </p>
        <Stack className={CommonStyles.full}>
          <VirtualizedList
            columns={[
              {
                dataPropName: 'recentConversation',
                render: rowRenderer,
              },
            ]}
            infinityQuery={userConversations}
            rowHeight={120}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ExistingConversationsModal;
