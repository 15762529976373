import { Button as FlowbiteButton, ButtonColors } from 'flowbite-react';
import { MouseEventHandler, useCallback, useState } from 'react';
import { useIsSelectedView } from '../utils/breakpoints';
import { Spinner } from './Spinner';
import classnames from 'classnames';

export type ButtonProps = {
  variant?: 'primary' | 'secondary';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  children?: React.ReactNode;
  className?: string;
  color?: keyof ButtonColors;
  icon?: React.ReactNode;
  mobileOverrides?: Omit<ButtonProps, 'mobileProps' | 'onClick'>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  outline?: boolean;
  isLoading?: boolean;
  dataTestid?: string;
};

export const Button: React.FC<ButtonProps> = (props) => {
  const { mobileOverrides, ...defaultProps } = props;
  const isMobileView = useIsSelectedView('sm');
  const {
    variant,
    children,
    icon,
    color,
    size = 'sm',
    onClick,
    className,
    disabled,
    isLoading,
    dataTestid,
    ...otherProps
  } = isMobileView ? { ...defaultProps, ...mobileOverrides } : defaultProps;

  const [loading, setLoading] = useState(false);

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (event) => {
      try {
        setLoading(true);
        await onClick?.(event);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    },
    [onClick]
  );

  const showSpinner = loading || isLoading;

  return (
    <FlowbiteButton
      {...otherProps}
      size={size}
      data-testid={dataTestid}
      onClick={handleClick}
      disabled={disabled || showSpinner}
      className={classnames(
        className,
        'focus:ring-gray-100',
        'overflow-hidden'
      )}
      color={color || variant === 'primary' ? 'blue' : 'light'}
    >
      {showSpinner ? (
        <Spinner className="h-[15px] p-0 m-0" />
      ) : (
        <>
          {icon && <span className="mr-2 h-5 w-5">{icon}</span>}
          {children}
        </>
      )}
    </FlowbiteButton>
  );
};

export default Button;
