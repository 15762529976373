import { t } from 'i18next';
import { Tooltip } from 'flowbite-react';
import { IconButton, InfoIconSolid } from '@trueconnect/tp-components';
import classnames from 'classnames';

export const NotShowToCustomerIcon = ({
  classNames,
}: {
  classNames?: string;
}) => {
  return (
    <div className={classnames('h-5', classNames)}>
      <Tooltip
        content={t('notShownToCustomer')}
        style="light"
        placement="top"
        className="!fixed"
        arrow={false}
      >
        <IconButton
          hoverable={false}
          customSize="!w-4 !h-4"
          className="[&>div]:w-4 [&>div]:h-4"
        >
          <InfoIconSolid color="black" />
        </IconButton>
      </Tooltip>
    </div>
  );
};
