import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { SearchInput, Stack } from './../../controls';
import { useStateProvider } from './../../providers';
import { conversationPageStyles } from './../../styles';
import { useDebounce } from 'react-use';

export const Search = () => {
  const { searchValue } = useStateProvider().state.conversation;
  const [inputValue, setInputValue] = useState<string>(searchValue);
  const { setSearchValue } = useStateProvider().actions;

  useEffect(() => {
    return () => {
      setSearchValue('');
    };
  }, [setSearchValue]);

  useDebounce(
    () => {
      setSearchValue(inputValue);
    },
    500,
    [inputValue]
  );

  return (
    <Stack className="w-full" direction="row" alignItems="center">
      <SearchInput
        classNames={{
          wrapper: conversationPageStyles.sidebar.searchInput,
          icon: '!m-0',
        }}
        dataTestid="conversationSearchInput"
        setSearchParam={setInputValue}
        isMobileView={true}
        searchIcon={<MagnifyingGlassIcon color="#1C64F2" />}
        iconHoverable={false}
        showClearIcon={false}
        autoFocus={true}
      />
    </Stack>
  );
};

export default Search;
