import {
  Spinner as SpinnerFlowbite,
  SpinnerProps as SpinnerFlowbiteProps,
} from 'flowbite-react';
import classnames from 'classnames';

export type SpinnerProps = SpinnerFlowbiteProps & {
  className?: string;
};

export const Spinner: React.FC<SpinnerProps> = (props) => {
  return (
    <SpinnerFlowbite
      {...props}
      className={classnames('fill-customColor_5', props.className)}
    />
  );
};

export default Spinner;
