import { TextInput as TextInputFlowbite, TextInputProps } from 'flowbite-react';
import classnames from 'classnames';

export const TextInput = (props: TextInputProps) => {
  return (
    <TextInputFlowbite
      {...props}
      className={classnames(
        props.className,
        '[&>div>input:focus]:border-blue-700 [&>div>input:focus]:ring-blue-700'
      )}
    />
  );
};

export default TextInput;
