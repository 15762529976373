export enum HttpStatusCode {
  BadRequest = 400,
  Conflict = 409,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
  ServiceUnavailable = 503,
  ConnectionTimeout = 504,
  else = 'else',
  ERR_NETWORK = 'ERR_NETWORK',
}

export const translatedErrors: HttpStatusCode[] = [
  HttpStatusCode.BadRequest,
  HttpStatusCode.Unauthorized,
  HttpStatusCode.Conflict,
  HttpStatusCode.Forbidden,
  HttpStatusCode.NotFound,
  HttpStatusCode.InternalServerError,
  HttpStatusCode.ServiceUnavailable,
  HttpStatusCode.ERR_NETWORK,
  HttpStatusCode.else,
];
