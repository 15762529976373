import { useTranslation } from 'react-i18next';
import { Progress } from 'flowbite-react';
import Button from '../../../../components/Button';
import FileUploadComponent from '../FileUploadComponent';
import { useStateProvider } from '../../../../providers';
import { Stack } from '../../..';
import FilesToUploadTable from '../FilesToUploadTable';
import classnames from 'classnames';
import { useIsSelectedView } from '../../../../utils';

export const DropzoneTab = () => {
  const { progress, cancel, file } =
    useStateProvider().state.uploads.currentUploadFile;
  const { isUploading, filesToUpload, currentUploadFile } =
    useStateProvider().state.uploads;
  const { t } = useTranslation();
  const isMobileView = useIsSelectedView('sm');

  const condition =
    !!filesToUpload.length || currentUploadFile.file || isUploading;

  if (!condition) return <FileUploadComponent />;

  return (
    <>
      {condition && (
        <>
          <div className={classnames(isMobileView ? 'flex-1' : 'h-40')}>
            <FilesToUploadTable />
          </div>
          <Stack direction="col" className="w-full h-fit">
            {file && (
              <div className="h-6 overflow-hidden">
                <div className="truncate">
                  {t('uploadPanel.uploading', { fileName: file.name })}
                </div>
              </div>
            )}
            <div className="w-full h-4">
              <Progress
                color="blue"
                progress={progress}
                size="lg"
                className="w-full"
              />
            </div>
            <Button className="mt-2 mx-auto" onClick={cancel}>
              {t('common.cancel')}
            </Button>
          </Stack>
        </>
      )}
    </>
  );
};

export default DropzoneTab;
