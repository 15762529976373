import {
  Stack,
  CommonStyles,
  SearchInput,
  VirtualizedList,
  CreateConversationPanel,
  useStateProvider,
  truncateString,
  useNotificationSystem,
  IconButton,
  XCircleIcon,
  ContactType,
  useUserProfile,
  ContactStatus,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateConversationDto,
  useApiInfiniteQuery,
  useApiInvalidateQueries,
  useApiMutation,
} from 'src/api';
import { useNavigate } from 'react-router-dom';
import Contact from './conversation/Contact';
import { CustomerData } from '@trueconnect/tp-components/src/providers/Customers/types';
import PermissionDialog from './permissions/PermissionDialog';
import TopicComponent from './TopicComponent';
import BranchesComponent from './BranchesComponent';
import ManageComponent from './ManageComponent';

export function CreateConversationPanelBroker() {
  const invalidateQueries = useApiInvalidateQueries();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { addNotification } = useNotificationSystem();
  const [searchParam, setSearchParam] = useState('');
  const { actions, state } = useStateProvider();
  const [
    showConfirmAllowConversationModal,
    setShowConfirmAllowConversationModal,
  ] = useState(false);

  const queryContacts = useApiInfiniteQuery(
    'searchContacts',
    [searchParam, undefined, undefined, 0],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map((subitem) => ({
            name: truncateString({
              str: `${subitem.firstName} ${subitem.lastName}`,
            }),
            company: subitem.customerName || '',
            ...subitem,
          }))
        ),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: false,
    }
  );

  const { refetch } = queryContacts;
  const { showCreateConversation, contactToCreateConversation } =
    state.conversation;

  const {
    setCustomerData,
    setToggleInvitedListModal,
    setShowCreateConversation,
    setContactToCreateConversation,
    setSelectedBranches,
    setSelectedTopic,
  } = actions;

  useEffect(() => {
    showCreateConversation && refetch();
    showCreateConversation && setSelectedBranches([]);
  }, [refetch, showCreateConversation, searchParam, setSelectedBranches]);

  const { mutate, isLoading: isCreatingConversation } = useApiMutation(
    'create_Conversation'
  );

  const { customerData } = state.customer;
  const { selectedBranches } = state.workManagement;
  const { displayName } = useUserProfile();
  const BrokerFirstName = displayName?.split(' ')[0];
  const [attachedTopic, setAttachedTopic] = useState<string>('');

  const createConversation = () => {
    mutate(
      [
        {
          contactId: contactToCreateConversation.id,
          title: `${contactToCreateConversation.displayName}, ${BrokerFirstName}`,
          branchIds: selectedBranches
            .filter((item) => !!item.id)
            .map((item) => item.id) as string[],
          topicId: attachedTopic ? attachedTopic : undefined,
        } as CreateConversationDto,
      ],
      {
        onSuccess: (conversationId) => {
          invalidateQueries('searchConversations');
          setToggleInvitedListModal(false);
          setSelectedTopic({});
          onClose();
          navigate(`/conversations/${conversationId}`);
        },
        onError: () => {
          setSelectedBranches([]);
          addNotification({
            title: t('apiCalls.error.createConversation'),
            color: 'failure',
          });
        },
      }
    );
  };

  const openContact = (contact: ContactType) => {
    const { activeInTrueData, email, status, name } = contact;

    if (!activeInTrueData || status == ContactStatus.Deactivated) {
      addNotification({
        title: 'Cant create conversation',
        subTitle: t('createConversation.contactNotActiveInTrueData', {
          name: name,
        }),
        color: 'info',
      });
    } else if (!email) {
      addNotification({
        title: 'Cant create conversation',
        subTitle: t('createConversation.contactHasNoEmail', {
          name: name,
        }),
        color: 'info',
      });
    } else if (!contact.permissions?.allowConversations) {
      setCustomerData({
        contactId: contact.id,
        customerId: contact.customerId,
        displayName: classnames(contact.firstName, contact.lastName),
        status: contact.status,
        permissions: contact.permissions,
        ...contact,
      } as CustomerData);
      setShowConfirmAllowConversationModal(true);
      return;
    } else {
      setContactToCreateConversation({
        ...contact,
        displayName: classnames(contact.firstName, contact.lastName),
        permissions: { ...contact.permissions, allowConversations: true },
      });
    }
  };

  const onClose = () => {
    setShowCreateConversation(false);
    setShowConfirmAllowConversationModal(false);
    setContactToCreateConversation({});
    setSelectedBranches([]);
    setSearchParam('');
  };

  const isContactSelected = !!contactToCreateConversation.displayName;

  return (
    <>
      <CreateConversationPanel
        {...{
          isCreatingConversation,
          close: onClose,
        }}
        header={
          <>
            {isContactSelected && (
              <Stack
                className="h-[71px] mt-8 w-full border-b"
                direction="row"
                alignItems="center"
              >
                <Contact
                  {...{
                    data: contactToCreateConversation as ContactType,
                    searchParam: '',
                  }}
                />
                <IconButton
                  onClick={() => {
                    setContactToCreateConversation({});
                  }}
                >
                  <XCircleIcon className="h-5" />
                </IconButton>
              </Stack>
            )}
            {!isContactSelected && (
              <>
                <SearchInput
                  classNames={{ wrapper: 'h-[52px] my-6' }}
                  setSearchParam={setSearchParam}
                  saveHistory={true}
                  inputName="CreateConversationSearch"
                  placeholder={
                    t(
                      'navbar.createConversationPanel.inputPlaceholder'
                    ) as string
                  }
                  dataTestid="createConversationSearchInput"
                />
                <h1
                  className={classnames(
                    'font-light text-sm	text-customColor_4 font-normal leading-normal mb-0'
                  )}
                >
                  {t('navbar.createConversationPanel.startQuestion')}
                </h1>
              </>
            )}
          </>
        }
      >
        <>
          {!isContactSelected && (
            <Stack
              direction="col"
              alignItems="stretch"
              className={CommonStyles.full}
            >
              <VirtualizedList
                columns={[
                  {
                    dataPropName: 'contact',
                    render: (contact) => (
                      <Contact
                        {...{
                          data: contact,
                          searchParam,
                        }}
                      />
                    ),
                  },
                ]}
                onRowClick={(item) => {
                  openContact(item);
                }}
                rowHeight={71}
                infinityQuery={queryContacts}
                headerHeight={0}
              />
            </Stack>
          )}
          {isContactSelected && (
            <>
              <BranchesComponent />
              <TopicComponent {...{ attachedTopic, setAttachedTopic }} />
              <ManageComponent
                {...{
                  onCancel: onClose,
                  onAccept: () => {
                    createConversation();
                  },
                }}
              />
            </>
          )}
        </>
      </CreateConversationPanel>
      <PermissionDialog
        {...{
          isVisible: showConfirmAllowConversationModal,
          permission: 'allowConversations',
          onClose: () => {
            setShowConfirmAllowConversationModal(false);
          },
          onOk: () => {
            setContactToCreateConversation({
              ...customerData,
              displayName: customerData.displayName,
              permissions: {
                ...customerData.permissions,
                allowConversations: true,
              },
            });
          },
          isLoading: isCreatingConversation,
          usersToAllow: [
            { ...customerData, permissions: customerData.permissions },
          ],
        }}
      />
    </>
  );
}

export default CreateConversationPanelBroker;
