import { UseInfiniteQueryResult } from '@tanstack/react-query';
import {
  ArrowUpTrayIcon,
  IDocumentType,
  RowClickHandlerType,
  Stack,
  VirtualizedGridCellRendererType,
  VirtualizedList,
  classNamesType,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

type FilesTableProps = {
  isDragActive: boolean;
  columns: VirtualizedGridCellRendererType<IDocumentType>[];
  queryDocuments: UseInfiniteQueryResult<IDocumentType[]>;
  openFile?: RowClickHandlerType<IDocumentType>;
  isMobileView?: boolean;
  classNames?: classNamesType;
};

export const DragIcon = () => {
  return (
    <Stack
      direction="col"
      justifyContent="center"
      className="w-full h-full mb-5"
      alignItems="center"
    >
      <ArrowUpTrayIcon className="w-6 h-6" />
      <p className="s-24">{t('file.dropzone')}</p>
    </Stack>
  );
};

export const FilesTable: React.FC<FilesTableProps> = ({
  isDragActive,
  columns,
  queryDocuments,
  openFile,
  isMobileView,
  classNames,
}) => {
  const { t } = useTranslation();

  return (
    <div className="h-full flex-1">
      {isDragActive ? (
        <DragIcon />
      ) : (
        <VirtualizedList
          columns={columns}
          overscan={600}
          headerHeight={isMobileView ? 0 : 40}
          rowHeight={64}
          infinityQuery={queryDocuments}
          onRowClick={openFile}
          nothingFound={<p className="pl-6">{t('fileToolbar.empty.folder')}</p>}
          classNames={{
            ...classNames,
            header: classnames(
              '!bg-customColor_17 font-normal hover:bg-customColor_2',
              classNames?.header
            ),
            row: classnames(
              'hover:bg-customColor_2 cursor-pointer',
              isMobileView ? 'first:!border-b-none' : '',
              classNames?.row
            ),
          }}
        />
      )}
    </div>
  );
};

export default FilesTable;
