import {
  ArrowPathRoundedSquareIcon,
  BellIcon,
  EnvelopeIcon,
  UserCircleIcon,
} from '@trueconnect/tp-components';
import {
  Deactivate,
  InvitationConfirmation,
  InvitationDetail,
  Reactivate,
} from '../CustomerManagementModals';

export interface DialogProps {
  contactId: number;
  customerId?: number;
  close: () => void;
  email?: string;
  visible?: boolean;
  status?: string;
  className?: string;
}

export const states: {
  [key: string]: {
    color?: string;
    icon: JSX.Element;
    modal?: React.FC<DialogProps>;
    hover?: string;
    close?: () => void;
  };
} = {
  NotInvited: {
    icon: <EnvelopeIcon color="#0170df" />,
    color: 'customColor_5',
    hover: 'customColor_2',
    modal: InvitationConfirmation,
  },
  Invited: {
    icon: <BellIcon color="yellow-500" />,
    color: 'yellow-500', // customColor_18 is not available, weird behaviour
    hover: 'customColor_2',
    modal: InvitationDetail,
  },
  Active: {
    icon: <UserCircleIcon color="black" />,
    color: '#111928',
    hover: '',
    modal: Deactivate,
  },
  Deactivated: {
    icon: <ArrowPathRoundedSquareIcon color="#03543F" />,
    color: 'green-600',
    hover: 'customColor_2',
    modal: Reactivate,
  },
};

export const indicatorStates: {
  [key: string]: {
    className?: string;
    icon: JSX.Element;
    color: string;
    border: string;
  };
} = {
  NotInvited: {
    icon: <EnvelopeIcon className="p-[3px]" />,
    color: '#0170df',
    border: 'blue-500',
    className: 'bg-blue-500 text-white',
  },
  Invited: {
    icon: <BellIcon className="p-[2px]" />,
    color: '#C27803',
    border: 'yellow-500',
    className: 'bg-yellow-500 text-white',
  },
  Active: {
    icon: <UserCircleIcon color="black" />,
    color: '#111928',
    border: '',
  },
  Deactivated: {
    icon: <ArrowPathRoundedSquareIcon color="white" className="p-[2px]" />,
    color: '#03543F',
    border: 'green-500',
    className: 'bg-green-500 text-white',
  },
};
