import {
  Stack,
  BranchesSelectionComponent,
  useStateProvider,
} from '@trueconnect/tp-components';

export function BranchesComponent() {
  const { state, actions } = useStateProvider();
  const { selectedBranches } = state.workManagement;
  const { setSelectedBranches } = actions;

  return (
    <Stack className="w-full pr-[2px]" direction="col">
      <BranchesSelectionComponent
        {...{ type: 'conversation', selectedBranches, setSelectedBranches }}
      />
    </Stack>
  );
}

export default BranchesComponent;
