import { ConversationListItemProps } from 'src/pages/Conversations';
import { payloadType } from '../StateProvider/actions';
import { branchType } from '../WorkManagement/branches';
import {
  ContactBrokerType,
  FetchTaskStateDto,
  TaskType,
  TopicType,
  addConversationToTopicType,
  extendedTopicParams,
} from './typing';
import { PolicyType } from 'src/components';

type actionType =
  | 'SET_AVAILABLE_BRANCHES'
  | 'SET_SELECTED_TOPIC'
  | 'SHOW_CHANGE_BRANCHES'
  | 'SET_SELECTED_BRANCHES'
  | 'SET_NOT_EDITABLE_BRANCHES'
  | 'SHOW_ATTACH_TO_TOPIC'
  | 'SET_ADD_CONVERSATION_TO_TOPIC'
  | 'SET_SHOW_TOPICS_SIDEBAR'
  | 'SET_FORM_TYPE'
  | 'SET_SELECTED_TASK'
  | 'SET_OPEN_TASK'
  | 'SET_SHOW_DELETE_TASK'
  | 'SET_DRAGGED_CONVERSATION'
  | 'SET_SHOW_ADD_TASK'
  | 'SET_AVAILABLE_TASKTYPES'
  | 'SET_SHOW_EXTENDED_TOPIC_SEARCH'
  | 'SET_EXTENDED_TOPIC_SEARCH_PARAMS'
  | 'SET_AVAILABLE_BROKERS'
  | 'SET_SELECTED_POLICY';

interface action {
  type: actionType;
  payload?: payloadType;
}

interface actions {
  [key: string]: (payload?: payloadType) => action;
}

export const stateActionsWorkManagement: actions = {
  setAvailableBranches: (value: branchType[]) => ({
    type: 'SET_AVAILABLE_BRANCHES',
    payload: value,
  }),
  setAvailableBrokers: (value: ContactBrokerType[]) => ({
    type: 'SET_AVAILABLE_BROKERS',
    payload: value,
  }),
  setSelectedTopic: (value: TopicType) => ({
    type: 'SET_SELECTED_TOPIC',
    payload: value,
  }),
  setShowChangeBranchesDialog: (value: boolean) => ({
    type: 'SHOW_CHANGE_BRANCHES',
    payload: value,
  }),
  setSelectedBranches: (value: branchType[]) => ({
    type: 'SET_SELECTED_BRANCHES',
    payload: value,
  }),
  setNotEditableBranches: (value: branchType[]) => ({
    type: 'SET_NOT_EDITABLE_BRANCHES',
    payload: value,
  }),
  setShowAttachToTopicDialog: (value: boolean) => ({
    type: 'SHOW_ATTACH_TO_TOPIC',
    payload: value,
  }),
  setAddConversationToTopic: (value: addConversationToTopicType) => ({
    type: 'SET_ADD_CONVERSATION_TO_TOPIC',
    payload: value,
  }),
  setShowTopicsSidebar: (value: boolean) => ({
    type: 'SET_SHOW_TOPICS_SIDEBAR',
    payload: value,
  }),
  setShowTaskForm: (value: boolean) => ({
    type: 'SET_FORM_TYPE',
    payload: value,
  }),
  setSelectedTask: (value: TaskType) => ({
    type: 'SET_SELECTED_TASK',
    payload: value,
  }),
  setOpenTask: (value: string) => ({
    type: 'SET_OPEN_TASK',
    payload: value,
  }),
  setShowDeleteTask: (value: boolean) => ({
    type: 'SET_SHOW_DELETE_TASK',
    payload: value,
  }),
  setDraggedConversation: (value: ConversationListItemProps) => ({
    type: 'SET_DRAGGED_CONVERSATION',
    payload: value,
  }),
  setShowAddTask: (value: boolean) => ({
    type: 'SET_SHOW_ADD_TASK',
    payload: value,
  }),
  setAvailableTaskTypes: (value: FetchTaskStateDto[]) => ({
    type: 'SET_AVAILABLE_TASKTYPES',
    payload: value,
  }),
  setShowExtendedTopicSearch: (value: boolean) => ({
    type: 'SET_SHOW_EXTENDED_TOPIC_SEARCH',
    payload: value,
  }),
  setExtendedTopicSearchParams: (value: extendedTopicParams) => ({
    type: 'SET_EXTENDED_TOPIC_SEARCH_PARAMS',
    payload: value,
  }),
  setSelectedPolicy: (value: PolicyType) => ({
    type: 'SET_SELECTED_POLICY',
    payload: value,
  }),
};

export type actionsWorkManagement = {
  setAvailableBranches: (value: branchType[]) => void;
  setAvailableBrokers: (value: ContactBrokerType[]) => void;
  setSelectedTopic: (value: TopicType) => void;
  setShowChangeBranchesDialog: (value: boolean) => void;
  setSelectedBranches: (value: branchType[]) => void;
  setNotEditableBranches: (value: branchType[]) => void;
  setShowAttachToTopicDialog: (value: boolean) => void;
  setAddConversationToTopic: (value: addConversationToTopicType) => void;
  setShowTopicsSidebar: (value: boolean) => void;
  setShowTaskForm: (value: boolean) => void;
  setSelectedTask: (value: TaskType) => void;
  setOpenTask: (value: string) => void;
  setShowDeleteTask: (value: boolean) => void;
  setDraggedConversation: (value: ConversationListItemProps) => void;
  setShowAddTask: (value: boolean) => void;
  setAvailableTaskTypes: (value: FetchTaskStateDto[]) => void;
  setShowExtendedTopicSearch: (value: boolean) => void;
  setExtendedTopicSearchParams: (value: extendedTopicParams) => void;
  setSelectedPolicy: (value: PolicyType) => void;
};

export default stateActionsWorkManagement;
