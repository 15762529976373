export type branchType = {
  id?: string;
  name?: string | undefined;
  sortOrder?: number;
  shortName?: string | undefined;
  convenientName?: string | undefined;
  backgroundColor?: string | undefined;
  color?: string | undefined;
  canBeDeleted?: boolean;
};

export const getBranches = (
  availableBranches: branchType[],
  ids: string[] | undefined
): branchType[] => {
  return ids
    ?.map((id) => availableBranches.find((branch) => branch.id === id))
    .filter((item): item is branchType => !!item) as branchType[];
};

export const sortBranches = (data: branchType[]) => {
  const sortedArray = [...data].sort((a, b) => {
    const sortOrderA = a.name || '';
    const sortOrderB = b.name || '';
    return sortOrderA.localeCompare(sortOrderB);
  });
  return sortedArray;
};

export const orderBranches = (
  availableBranches: branchType[],
  branches: branchType[]
) => {
  const branchesNames = branches.map((branch) => branch.name);
  const result = [];

  for (const branch of availableBranches) {
    if (branchesNames.includes(branch.name)) {
      result.push(branch);
    }
  }

  return result;
};

export const formatBranches = (branches: branchType[]) => {
  const branchDisplayNames: string[] = branches.map(
    (branch) => branch.shortName || branch.name || ''
  );

  if (branchDisplayNames.length <= 3) {
    return branchDisplayNames.join(', ');
  } else {
    const firstThreeDisplayNames = branchDisplayNames.slice(0, 3).join(', ');
    const remainingCount = branchDisplayNames.length - 3;
    return `${firstThreeDisplayNames} + ${remainingCount}`;
  }
};

export default getBranches;
