import {
  BranchesSelectionComponent,
  Button,
  EditableTitle,
  Stack,
  branchType,
  useNotificationSystem,
} from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { useApiInvalidateQueries, useApiMutation } from 'src/api';

interface CreateTopicDialogProps {
  visible: boolean;
  close: () => void;
  redirectOnCreateTopic?: boolean;
}

export const CreateTopicModal: React.FC<CreateTopicDialogProps> = ({
  visible,
  close,
  redirectOnCreateTopic,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isMobileView = width < 400;

  const [name, setName] = useState('');
  const invalidateQueries = useApiInvalidateQueries();
  const { addNotification } = useNotificationSystem();
  const navigate = useNavigate();
  const [selectedBranches, setSelectedBranches] = useState<branchType[]>([]);

  const onClose = () => {
    setName('');
    setSelectedBranches([]);
    close();
  };

  const { mutate, isLoading } = useApiMutation('createTopic', {
    onSuccess: (data) => {
      invalidateQueries('searchTopics');
      if (redirectOnCreateTopic) {
        data.id && navigate(data.id);
      }
      onClose();
    },
    onError: () => {
      addNotification({
        color: 'failure',
        title: t('Topics.modals.createTopic.error'),
      });
    },
  });

  return (
    <Modal
      show={visible}
      size="5xl"
      title={
        <Stack className="w-full overflow-hidden">
          <EditableTitle
            {...{
              title: name,
              isEditable: true,
              dataTestId: 'createTopicNameInput',
              shouldValidate: false,
              onChange: (e) => setName(e),
              variant: 'secondary',
              placeholder: 'New Topic',
              className: 'h-8 [&>div>span]:py-0 [&>div>input]:py-0',
            }}
          />
        </Stack>
      }
      className="!z-50 [&>div>div>div:nth-child(1)]:items-center [&>div>div>div:nth-child(1)>h3]:w-full"
      footer={
        <Stack
          direction={isMobileView ? 'col' : 'row'}
          justifyContent="end"
          className="w-full gap-2"
        >
          <Button
            variant="secondary"
            onClick={onClose}
            dataTestid="createTopicCancel"
          >
            <p className="truncate">{t('common.cancel')}</p>
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              mutate([
                {
                  displayName: name,
                  branchIds: selectedBranches.map((item) => item.id),
                },
              ]);
            }}
            disabled={!name}
            dataTestid="createTopicAccept"
            isLoading={isLoading}
          >
            <p className="truncate">{t('common.accept')}</p>
          </Button>
        </Stack>
      }
      onClose={onClose}
    >
      <div className="w-full h-full [&>h1]:pt-0">
        <BranchesSelectionComponent
          {...{
            type: 'topic',
            selectedBranches,
            setSelectedBranches: (branch) => {
              setSelectedBranches(branch);
            },
          }}
        />
      </div>
    </Modal>
  );
};

export default CreateTopicModal;
