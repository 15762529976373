import { Tooltip } from 'flowbite-react';
import { Stack } from './../controls';
import { ExclamationTriangleIcon, InfoIcon, XMarkIcon } from './../images';
import IconButton from './IconButton';
import dayjs from 'dayjs';
import { t } from 'i18next';
import classnames from 'classnames';
import { useIsSelectedRange } from './../utils';
import { useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { useEffectOnce } from 'react-use';

interface FetchMaintenanceDto {
  IsActive: boolean;
  ShowFrom: Date;
  ShowTo: Date;
  Message: string | undefined;
}

const formatDate = (date: Date) => {
  return dayjs(date).startOf('minute').toDate();
};

const displayDate = (date: DateObject) => {
  return date.format('DD.MM.YYYY HH:mm');
};

export const MaintenanceInfo = ({
  blobStorageAccountName,
  maintenanseFolder,
  mode = 'info',
}: {
  blobStorageAccountName: string;
  maintenanseFolder: string;
  mode?: 'info' | 'banner';
}) => {
  const isTabletView = useIsSelectedRange(['sm', 'md', 'lg']);
  const [showMobileMaintenanceInfo, setShowMobileMaintenanceInfo] =
    useState(true);

  const [data, setData] = useState<FetchMaintenanceDto | undefined>(undefined);
  useEffectOnce(() => {
    fetch(
      `https://${blobStorageAccountName}.blob.core.windows.net/${maintenanseFolder}/notification.json`,
      {
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      }
    )
      .then((data) => data.json())
      .then((result) => {
        setData(result as FetchMaintenanceDto | undefined);
      })
      .catch(() => {});
  });

  if (!data) {
    return <></>;
  }

  const startDate = new DateObject(formatDate(data?.ShowFrom));
  const endDate = new DateObject(formatDate(data?.ShowTo));

  const today = new DateObject();

  const isActive =
    data?.IsActive &&
    today?.toDate() >= startDate?.toDate() &&
    today?.toDate() <= endDate?.toDate();

  if (!isActive || !showMobileMaintenanceInfo) {
    return <></>;
  }

  const maintenanceInfoContent = (
    <Tooltip
      content={
        <div>
          {displayDate(startDate).toString()} -{' '}
          {displayDate(endDate).toString()}
          <p>{data?.Message}</p>
        </div>
      }
      style="light"
      trigger="hover"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        className="cursor-pointer"
      >
        <IconButton
          hoverable={false}
          customSize="!w-6 !h-6 [&>div]:!w-6 [&>div]:!h-6"
        >
          <ExclamationTriangleIcon />
        </IconButton>
        <div className={(classnames(isTabletView && 'text-sm w-min'), 'ml-2')}>
          {classnames(t('navbar.maintenance'), displayDate(endDate))}
        </div>
        <IconButton
          hoverable={false}
          customSize="!w-5 !h-5 [&>div]:!w-5 [&>div]:!h-5"
        >
          <InfoIcon />
        </IconButton>
      </Stack>
    </Tooltip>
  );

  if (mode === 'banner') {
    return (
      <Stack
        className="w-full h-[40px] border-b"
        direction="row"
        alignItems="center"
        justifyContent="between"
      >
        <div className="w-6" />
        {maintenanceInfoContent}
        <IconButton
          onClick={() => {
            setShowMobileMaintenanceInfo(false);
          }}
        >
          <XMarkIcon />
        </IconButton>
      </Stack>
    );
  }

  return maintenanceInfoContent;
};
