import { Stack } from './../../../controls';
import { useStateProvider } from './../../../providers';
import { useTranslation } from 'react-i18next';

export const UploadButton = ({ children }: { children?: React.ReactNode }) => {
  const { setFilesToUpload } = useStateProvider().actions;
  const { t } = useTranslation();
  return (
    <>
      <label
        className="h-12 cursor-pointer w-full mt-4"
        htmlFor={'file-input-button'}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          className="bg-customColor_2 h-12 rounded-md"
        >
          {children}
          <h1 className="text-center">{t('uploadPanel.selectFile')}</h1>
        </Stack>
      </label>
      <input
        id="file-input-button"
        multiple
        type="file"
        style={{ display: 'none' }}
        onChange={(e) => {
          setFilesToUpload(Array.from(e.target.files || []));
          e.target.value = '';
        }}
      />
    </>
  );
};

export default UploadButton;
