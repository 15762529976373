import classnames from 'classnames';
import { ConversationState } from 'src/api';
import { Button } from 'flowbite-react';
import { t } from 'i18next';
import { ConversationType } from '@trueconnect/tp-components';

const typesOrdered: ConversationType[] = [
  ConversationState.Open,
  ConversationState.Closed,
  'All',
];

export const ConversationStatusFilter = ({
  selectedType,
  setSelectedType,
  isMobileView,
  classNames,
  types = typesOrdered,
}: {
  selectedType: ConversationType;
  setSelectedType: (item: ConversationType) => void;
  isMobileView?: boolean;
  classNames?: string;
  types?: typeof typesOrdered;
}) => {
  return (
    <Button.Group className={classnames('w-full px-4 py-2', classNames)}>
      {types.map((item) => {
        const isSelected = selectedType === item;
        return (
          <Button
            key={item}
            color={isSelected ? 'gray' : 'white'}
            data-testid={`conversationFilter_${item}`}
            className={classnames(
              isMobileView ? 'w-full' : 'w-[100px]',
              'border outline-0 focus:ring-0',
              isSelected
                ? 'hover:!text-gray-900 focus:!text-gray-900 !bg-gray-100'
                : 'text-gray-400'
            )}
            onClick={() => {
              setSelectedType(item);
            }}
          >
            <>{t(`conversationTab.conversation.${item}`)}</>
          </Button>
        );
      })}
    </Button.Group>
  );
};

export default ConversationStatusFilter;
