import {
  BuildingOffice2Icon,
  InfiniteScrollDropdownItem,
  Stack,
  VirtualizedList,
} from '@trueconnect/tp-components';
import { Dispatch, SetStateAction, useState } from 'react';
import { FetchBrokerDeputyDto, useApiInfiniteQuery } from 'src/api';

export type customerType = {
  id?: number | undefined;
  name?: string | undefined;
};

export const CustomerSearch = ({
  data,
  selectedCustomer,
  setSelectedCustomer,
}: {
  data?: FetchBrokerDeputyDto | undefined;
  selectedCustomer: customerType;
  setSelectedCustomer: Dispatch<SetStateAction<customerType>>;
}) => {
  const [searchParam, setSearchParam] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const queryBrokers = useApiInfiniteQuery(
    'searchCustomers',
    [searchParam, undefined, 0],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item
            .map((subitem) => subitem as customerType)
            .sort((a, b) => {
              const nameA = a.name || '';
              const nameB = b.name || '';

              return nameA.localeCompare(nameB);
            })
        ),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: showDropdown,
    }
  );

  return (
    <InfiniteScrollDropdownItem
      clickAwayCondition={!!selectedCustomer.name}
      {...{
        isSavedCondition:
          data?.deputyUserId == selectedCustomer.id && !!data?.deputyUserId,
        setShowDropdown,
        showDropdown,
        placeholder: selectedCustomer.name ? selectedCustomer.name : 'Customer',
        searchParam,
        setSearchParam,
      }}
      icon={<BuildingOffice2Icon />}
      tableContainerClassname={'!top-[35px]'}
      tableClassname={'h-[200px]'}
      type="secondary"
      table={
        <VirtualizedList
          columns={[
            {
              dataPropName: 'name',
              render: (item) => (
                <Stack
                  className="w-full cursor-pointer pl-4"
                  justifyContent="center"
                  onClick={() => {
                    setSelectedCustomer(item);
                    setSearchParam('');
                    setShowDropdown(false);
                  }}
                >
                  <h1>{item.name}</h1>
                </Stack>
              ),
            },
          ]}
          headerHeight={0}
          infinityQuery={queryBrokers}
          rowHeight={50}
          onRowClick={(item) => {
            setSelectedCustomer(item);
          }}
        />
      }
    />
  );
};

export default CustomerSearch;
