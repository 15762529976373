import { useTranslation } from 'react-i18next';

export const useGetLastActiveTime = (dt: Date) => {
  const { t } = useTranslation();

  if (!dt) return '';

  const now = new Date();
  const date = new Date(dt);

  const currentDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  );
  const targetDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );

  const timeDiff = currentDate.getTime() - targetDate.getTime();
  const dayDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

  if (dayDiff === 0) {
    return t('common.today', { time: date.toLocaleTimeString() });
  } else if (dayDiff === 1) {
    return t('common.yesterday', { time: date.toLocaleTimeString() });
  } else {
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }
};
