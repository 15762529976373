import {
  VirtualizedColumnType,
  ConversationParticipantType,
  useIsSelectedRange,
  useIsSelectedView,
  useStateProvider,
} from '@trueconnect/tp-components';
import { useMemo } from 'react';
import { ContactData } from 'src/components/ListItems/ContactData';
import { ContactEmail } from 'src/components/ListItems/ContactEmail';
import { ContactPhoneNumber } from 'src/components/ListItems/ContactPhoneNumber';

import { ContactListItem } from '../../../../components/ListItems/ContactListItem';
import Status from './columns/Status';

export const useContactColumnsBase = ({
  setMemberIds,
}: {
  setMemberIds: (ids: string[]) => void;
}) => {
  const { readOnly } = useStateProvider().state.conversation.conversationData;
  const isMobileView = useIsSelectedView('sm');
  const isTabletView = useIsSelectedView('md');
  const isLargeView = useIsSelectedRange(['lg', 'xl', 'xxl']);

  const isSelectedView = isMobileView || isTabletView;

  const columns: VirtualizedColumnType<ConversationParticipantType>[] = useMemo(
    () => [
      {
        dataPropName: 'name',
        render: (item: ConversationParticipantType) => (
          <ContactData
            {...{
              name: item.displayName,
              ...item,
            }}
          />
        ),
        widthRatio: 5,
      },
      {
        dataPropName: 'email',
        render: ContactEmail,
        widthRatio: 4,
      },
      {
        dataPropName: 'phoneNumber',
        render: ContactPhoneNumber,
        widthRatio: 4,
      },
      {
        dataPropName: 'status',
        render: (item: ConversationParticipantType) => (
          <Status {...{ isSelectedView, readOnly, setMemberIds, item }} />
        ),
        widthRatio: isSelectedView ? 1 : 3,
      },
    ],
    [isSelectedView, readOnly, setMemberIds]
  );

  const mobileListItem: VirtualizedColumnType<ConversationParticipantType> = {
    dataPropName: 'item',
    render: ContactListItem,
    widthRatio: 6,
  };

  if (isMobileView || isTabletView) {
    return [
      mobileListItem,
      ...columns.filter(
        (item) => !['email', 'phoneNumber', 'name'].includes(item.dataPropName)
      ),
    ];
  }

  if (isLargeView) {
    return columns.filter(
      (item) => !['phoneNumber'].includes(item.dataPropName)
    );
  }

  return columns;
};

export const useContactColumns = ({
  setMemberIds,
  columnsToRemove = [],
}: {
  setMemberIds: (ids: string[]) => void;
  columnsToRemove?: string[];
}) => {
  const { readOnly } = useStateProvider().state.conversation.conversationData;
  const columns = useContactColumnsBase({ setMemberIds });
  return (
    readOnly ? columns.filter((item) => item.dataPropName !== 'more') : columns
  ).filter((item) => !columnsToRemove.includes(item.dataPropName));
};
