import stateActionsSpinner, { actionsSpinner } from './../Spinners/actions';
import stateActionsNotifications, {
  actionsNotification,
} from './../NotificationSystem/actions';
import stateActionsUpload, { actionsUpload } from '../Uploads/actions';
import stateActionsChat, { actionsChat } from '../Chat/actions';
import stateActionsConversation, {
  actionsConversation,
} from '../Conversation/actions';
import stateActionsCustomer, { actionsCustomers } from '../Customers/actions';
import stateActionsDocument, { actionsDocument } from '../Documents/actions';
import stateActionsHubListener, {
  actionshubListener,
} from '../HubListener/actions';
import stateActionsExtra, { actionsExtra } from '../Extra/actions';
import stateActionsWorkManagement, {
  actionsWorkManagement,
} from '../WorkManagement/actions';

export type payloadType = any; // todo find a way yo avoid any

export const stateActions = {
  ...stateActionsSpinner,
  ...stateActionsNotifications,
  ...stateActionsUpload,
  ...stateActionsChat,
  ...stateActionsConversation,
  ...stateActionsCustomer,
  ...stateActionsDocument,
  ...stateActionsHubListener,
  ...stateActionsExtra,
  ...stateActionsWorkManagement,
};

export type actionsType = actionsSpinner &
  actionsUpload &
  actionsNotification &
  actionshubListener &
  actionsExtra &
  actionsCustomers &
  actionsChat &
  actionsConversation &
  actionsDocument &
  actionsWorkManagement;

export default stateActions;
