import { useStateProvider } from './../../providers';
import classnames from 'classnames';
import { t } from 'i18next';

export const SelectedTopicSidebarItem = () => {
  const { state } = useStateProvider();
  const { selectedTopic } = state.workManagement;

  return (
    <div className="w-full h-full relative">
      <div
        className={classnames('absolute h-fit !max-h-full w-full py-4')}
        style={{
          writingMode: 'vertical-lr',
        }}
      >
        <div className="rotate-[180deg] flex items-center h-full w-full">
          <p
            className={classnames(
              'inline-block indent-[10px] font-medium text-sm text-nowrap truncate leading-normal'
            )}
          >
            {selectedTopic.id
              ? t('Topics.Sidebar.displayName', {
                  displayName: selectedTopic.displayName,
                })
              : t('Topics.Sidebar.allTopics')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SelectedTopicSidebarItem;
