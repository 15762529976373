// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FeatureFlagsProperty = ((options: any) => boolean) | boolean;

export type FeatureFlagsBaseType = {
  [featureName: string]: FeatureFlagsProperty;
};

declare global {
  interface Window {
    _tpFeatureFlags?: FeatureFlagsBaseType;
  }
}

/**
 * Gets a feature flags object.
 * @param defaultFlags The default values for the flags
 */
export function getFeatureFlags<T extends FeatureFlagsBaseType>(
  defaultFlags: T
) {
  const overrideFlags = window._tpFeatureFlags || {};

  const queryParams = new URLSearchParams(window.location.search).entries();
  let entry = queryParams.next();
  while (!entry.done) {
    if (entry.value[0].startsWith('feature-')) {
      const featureName = entry.value[0].substring('feature-'.length);
      overrideFlags[featureName] = !['false', '0'].includes(
        entry.value[1].toLowerCase()
      );
    }
    entry = queryParams.next();
  }

  return new Proxy(defaultFlags, {
    get: (target, key) => {
      if (typeof key !== 'string') {
        return target[key as keyof T];
      }
      const targetFlag = target[key];
      if (key in overrideFlags) {
        const override = overrideFlags[key];
        if (typeof override !== 'undefined') {
          const evaluateOverride = (context?: unknown): boolean => {
            try {
              return typeof override === 'function'
                ? override(context)
                : !!override;
            } catch (ex) {
              // eslint-disable-next-line no-console
              console.error(
                `Configured feature flag function for ${key} is incorrect`,
                ex
              );
              return typeof targetFlag === 'function'
                ? targetFlag(context)
                : targetFlag;
            }
          };
          if (typeof targetFlag === 'function') {
            return evaluateOverride;
          } else if (typeof targetFlag === 'boolean') {
            const result = evaluateOverride();
            return result;
          }
        }
      }
      return targetFlag;
    },
  });
}

export default getFeatureFlags;
