import { Button, Stack } from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { t } from 'i18next';

export const DeleteNoteModal = ({
  visible,
  isDeleting,
  onAccept,
  onClose,
}: {
  visible?: boolean;
  isDeleting?: boolean;
  onAccept: () => void;
  onClose: () => void;
}) => {
  return (
    <Modal
      show={visible}
      className="[&>div]:!w-[270px] [&>div>div>div]:p-4"
      showTitle={false}
      showFooter={false}
      onClose={onClose}
    >
      <Stack direction="col" className="w-full gap-2">
        <p className="text-center text-xl font-semibold mb-4">
          {t(`customer.notes.deleteNoteModal.confirmationText`)}
        </p>
        <Button
          color="red"
          className="w-full bg-red-600 enabled:hover:bg-red-700"
          isLoading={isDeleting}
          onClick={() => {
            onAccept();
            onClose();
          }}
          dataTestid={`deleteNoteAccept`}
        >
          {t('common.delete')}
        </Button>
        <Button
          className="w-full bg-gray-200 enabled:hover:bg-gray-300"
          variant="secondary"
          onClick={onClose}
        >
          {t('common.cancel')}
        </Button>
      </Stack>
    </Modal>
  );
};

export default DeleteNoteModal;
