import { ConversationParticipantType, branchType } from './../../providers';

enum ConversationState {
  Open = 'Open',
  Closed = 'Closed',
}

export type ConversationListItemProps = {
  id: string;
  title: string;
  customerId: number;
  participants: ConversationParticipantType[];
  topicId?: string | undefined;
  lastActive: Date;
  company: string;
  classNames?: string;
  hasUnReadMessages: boolean;
  selected?: boolean;
  state?: ConversationState;
  branches: branchType[];
  showLastActive?: boolean;
  redirect?: boolean;
};
