import { Checkbox } from 'flowbite-react';
import classnames from 'classnames';

export const CheckBox = ({
  checked,
  disabled = false,
  onChange,
  className = '',
  dataTestId,
  containerClassname = '',
}: {
  checked: boolean;
  disabled?: boolean;
  onChange?: () => void;
  className?: string;
  containerClassname?: string;
  dataTestId?: string;
}) => {
  return (
    <div className={classnames('h-fit px-2 mb-1', containerClassname)}>
      <Checkbox
        data-testid={dataTestId}
        color="blue"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        onClick={onChange}
        className={className}
      />
    </div>
  );
};
