import { useCallback, useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

const chunksToUpload = 1024 * 1024 * 50; // 50Mb

type UploadSession = {
  signedUrl?: string | undefined;
  expirationDateTime?: Date;
};

export function useApiUpload<T>(
  id: T,
  createSessionQuery: UseQueryResult<UploadSession>,
  closeUploadSession: (Id: T, documentId: string) => void,
  setUploading: (uploading: boolean) => void,
  onError: (error: string) => void,
  file?: File,
  isUploading?: boolean
) {
  const [progress, setProgress] = useState<number>(0);

  const { data: session, refetch: createUploadSession } = createSessionQuery;

  const cancel = () => {
    setUploading(false);
    setProgress(0);
  };

  const sendFile = useCallback(
    async (url: string, file: File) => {
      const totalChunks = Math.ceil(file.size / chunksToUpload);

      for (let index = 0; index < totalChunks; index++) {
        const startByte = index * chunksToUpload;
        const endByte = Math.min(startByte + chunksToUpload, file.size);
        const chunk = file.slice(startByte, endByte);

        await fetch(url, {
          method: 'PUT',
          body: chunk,
          headers: {
            'content-type': file.type,
            'content-length': `${chunk.size}`,
            'content-range': `bytes ${startByte}-${endByte - 1}/${file.size}`,
          },
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw new Error('Error uploading file');
          })
          .then((res) => {
            setProgress((index / totalChunks) * 100);
            if (index == totalChunks - 1) {
              closeUploadSession(id, res.id);
              setProgress(100);
            }
          })
          .catch((err) => {
            onError(err?.message);
            setUploading(false);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  useEffect(() => {
    const expired = session?.expirationDateTime
      ? session?.expirationDateTime < new Date()
      : true;

    if (file && expired && id && !isUploading) {
      setUploading(true);
      createUploadSession();
    }
  }, [file, createUploadSession, session, setUploading, id, isUploading]);

  const url = session?.signedUrl;
  useEffect(() => {
    if (url && file && id) {
      setProgress(2);
      sendFile(url, file);
    }
  }, [session, file, sendFile, url, id]);

  return { progress, cancel };
}

export default useApiUpload;
