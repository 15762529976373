import { SelectedFileType } from './../providers/Uploads/typing';

const name = 'unsentMessages';

export const setUnsentMessagesText = (conversationId: string, str: string) => {
  const existingDataString = localStorage.getItem(name);
  const existingData = existingDataString ? JSON.parse(existingDataString) : {};

  existingData[conversationId] = {
    ...existingData[conversationId],
    text: str,
  };

  const { text = '', attachments = [] } = existingData[conversationId] || {
    text: '',
    attachments: [],
  };
  if (text.trim() === '' && !attachments.length) {
    delete existingData[conversationId];
  }

  localStorage.setItem(name, JSON.stringify(existingData));
};

export const setUnsentMessagesAttachments = (
  conversationId: string,
  attachments?: SelectedFileType[]
) => {
  const existingDataString = localStorage.getItem(name);
  const existingData = existingDataString ? JSON.parse(existingDataString) : {};

  existingData[conversationId] = {
    ...existingData[conversationId],
    attachments,
  };

  const { text = '' } = existingData[conversationId] || {
    text: '',
    attachments: [],
  };
  if (text.trim() === '' && !attachments?.length) {
    delete existingData[conversationId];
  }

  localStorage.setItem(name, JSON.stringify(existingData));
};

export const getUnsentMessage = (
  conversationId: string
): { text: string; attachments: SelectedFileType[] } => {
  const existingDataString = localStorage.getItem(name);
  const existingData = existingDataString ? JSON.parse(existingDataString) : {};

  return existingData[conversationId] || { text: '', attachments: [] };
};

export const deleteUnsentMessage = (conversationId: string) => {
  const existingDataString = localStorage.getItem(name);
  const existingData = existingDataString ? JSON.parse(existingDataString) : {};

  delete existingData[conversationId];

  localStorage.setItem(name, JSON.stringify(existingData));
};
