import {
  getFeatureFlags,
  FeatureFlagsType as FeatureFlagsTpComponents,
} from '@trueconnect/tp-components';

/**
 * Feature flags for internal portal.
 */
export type FeatureFlagsType = FeatureFlagsTpComponents & {
  showFileTab: boolean;
};

/**
 * Default feature flags for internal portal.
 */
const defaultFeatureFlags: FeatureFlagsType = {
  showFileTab: true,
  enableDarkTheme: false,
  enableSignalRHubLogging: false,
  showChangeLanguage: (environment) => environment !== 'production',
  showTasks: (environment) => environment !== 'production',
};

/**
 * Actual evaluated feature flags for internal portal.
 * This loads any custom override from 'feature-flags.js' in public folder.
 */
export const FeatureFlags = getFeatureFlags(defaultFeatureFlags);

export default FeatureFlags;
