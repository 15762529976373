import { useMsal } from '@azure/msal-react';
import { Button } from '@trueconnect/tp-components';
import { loginRequest } from '../authConfig';
import { O365Icon } from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import { IPublicClientApplication } from '@azure/msal-browser';

async function handleLogin(instance: IPublicClientApplication) {
  await instance.loginRedirect(loginRequest).catch((e: unknown) => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  const isBlack = false;
  return (
    <Button
      variant="primary"
      onClick={() => handleLogin(instance)}
      size="lg"
      className="w-full"
    >
      <img
        src={O365Icon}
        alt="MS"
        style={{
          fill: isBlack ? '#262626' : '#fff',
          height: '24px',
          width: '24px',
        }}
        className="mr-3"
      />
      <span className="text-lg">{t('login.signButton')}</span>
    </Button>
  );
};
