import { IDocumentType } from '../DocumentType';
import { FolderIcon, DocumentIcon } from '@heroicons/react/24/solid';
import { IconButton } from './../../../components';
import { DocumentCellStyle } from './../../../styles';
import { Stack } from './../../../controls';

export const FileListItem: React.FC<IDocumentType> = (item: IDocumentType) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      className={DocumentCellStyle}
      dataTestid={item.fileName}
    >
      <IconButton hoverable={false} size="none" className="mr-3 ml-2 !w-5">
        {item.type === 'folder' ? (
          <FolderIcon className="w-6 h-7" />
        ) : (
          <DocumentIcon className="w-6 h-7" />
        )}
      </IconButton>
      <Stack direction="col">
        <p className="font-medium">{item.fileName}</p>
        <p className="text-xs font-normal text-gray-400">{item.modifiedAt}</p>
      </Stack>
    </Stack>
  );
};

export default FileListItem;
