import {
  CheckBox,
  CustomerIcon,
  InfiniteScrollDropdownItem,
  Stack,
  VirtualizedList,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import { useState } from 'react';
import { useApiInfiniteQuery } from 'src/api';

export type brokerType = {
  userId?: string | undefined;
  userName?: string | undefined;
};

export const BrokerSearch = ({
  selectedBrokers,
  selectBrokers,
}: {
  selectedBrokers: brokerType[];
  selectBrokers: (item: brokerType) => void;
}) => {
  const [searchParam, setSearchParam] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const queryBrokers = useApiInfiniteQuery('searchBrokers', [searchParam, 0], {
    select: (data) =>
      data.pages.flatMap((item) =>
        item
          .sort((a, b) => {
            const nameA = a.displayName || '';
            const nameB = b.displayName || '';

            return nameA.localeCompare(nameB);
          })
          .map(
            (subitem) =>
              ({
                userId: subitem.id,
                userName: subitem.displayName,
              } as brokerType)
          )
      ),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: showDropdown,
  });

  return (
    <InfiniteScrollDropdownItem
      clickAwayCondition={!!selectedBrokers.length}
      {...{
        setShowDropdown,
        showDropdown,
        placeholder: selectedBrokers.length
          ? selectedBrokers.map((item) => item.userName).join(', ')
          : t('Tasks.tabs.table.columns.assignees'),
        searchParam,
        setSearchParam,
        classNames: 'w-full refer',
      }}
      icon={<CustomerIcon />}
      tableContainerClassname={'!top-[35px]'}
      tableClassname={'h-[200px]'}
      type="secondary"
      table={
        <VirtualizedList
          columns={[
            {
              dataPropName: 'name',
              render: (item) => (
                <Stack
                  className="w-full cursor-pointer pl-4"
                  justifyContent="between"
                  direction="row"
                  alignItems="center"
                  onClick={() => {
                    selectBrokers(item);
                    setSearchParam('');
                  }}
                >
                  <h1>{item.userName}</h1>
                  <CheckBox
                    {...{
                      checked: selectedBrokers
                        .map((itm) => itm.userId)
                        .includes(item.userId),
                    }}
                  />
                </Stack>
              ),
            },
          ]}
          headerHeight={0}
          infinityQuery={queryBrokers}
          rowHeight={50}
          onRowClick={(item) => {
            selectBrokers(item);
          }}
        />
      }
    />
  );
};

export default BrokerSearch;
