import { FaceSmileIcon } from '@heroicons/react/24/solid';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { IconButton } from './../../../components';
import { useStateProvider } from './../../../providers';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

export const EmojiPicker = () => {
  const { i18n } = useTranslation();
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const ref = useRef(null);
  const { state, actions } = useStateProvider();

  const { message } = state.chat;
  const { setMessage } = actions;

  const addEmoji = (e: { unified: string }) => {
    const sym = e.unified.split('_');
    const codeArray: number[] = [];
    sym.forEach((el) => codeArray.push(parseInt('0x' + el, 16)));
    const emoji = String.fromCodePoint(...codeArray);
    setMessage(message + emoji);
  };

  useClickAway(ref, () => {
    setIsPickerOpen(false);
  });

  return (
    <div className="relative inline-block" ref={ref}>
      <IconButton
        className="h-8"
        onClick={() => {
          setIsPickerOpen(!isPickerOpen);
        }}
      >
        <FaceSmileIcon />
      </IconButton>

      {isPickerOpen && (
        <div className="absolute bottom-[65px] z-10 left-0">
          <Picker
            locale={i18n.language}
            data={data}
            emojiSize={20}
            emojiButtonSize={28}
            onEmojiSelect={addEmoji}
            maxFrequentRows={1}
          />
        </div>
      )}
    </div>
  );
};

export default EmojiPicker;
