import { useTranslation } from 'react-i18next';
import {
  Button,
  Stack,
  branchType,
  modalStyles,
  useStateProvider,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { useApiInvalidateQueries, useApiMutation } from 'src/api';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { initialAddConversationToTopic } from '@trueconnect/tp-components/src/providers/WorkManagement/reducers';
import { useCallback } from 'react';

export const AddConversationToTopicDialog = () => {
  const { t } = useTranslation();
  const { state, actions } = useStateProvider();
  const { conversation, topic } = state.workManagement.addConversationToTopic;
  const { setAddConversationToTopic } = actions;
  const visible = !!conversation.id && !!topic.id;
  const { id, title = '', notes = '', branches = [] } = conversation;

  const invalidateQueries = useApiInvalidateQueries();
  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('searchTopics');
  }, [invalidateQueries]);

  const { mutate, isLoading } = useApiMutation('update_Conversation', {
    onSuccess: () => {
      onClose();
      invalidateQuery();
    },
  });

  const handleClick = () => {
    mutate([
      {
        id,
        title,
        notes,
        branchIds: (branches as branchType[])
          .filter((item) => !!item.id)
          .map((item) => item.id as string),
        topicId: topic.id,
      },
    ]);
  };

  const onClose = () => {
    setAddConversationToTopic(initialAddConversationToTopic);
  };

  const sliceString = (str: string, limit: number = 30) => {
    return str.length > limit ? str.slice(0, limit) + '...' : str;
  };

  return (
    <Modal
      size="sm"
      onClose={onClose}
      show={!!visible}
      className={classnames(modalStyles, 'text-center')}
      showFooter={false}
      showTitle={false}
    >
      <div className="w-full h-full">
        <h3 className="mb-5 text-2xl font-semibold">
          {t('Topics.modals.confirmAddConversation.title')}
        </h3>
        <span className="font-light">
          {t('Topics.modals.confirmAddConversation.subTitle', {
            conversationTitle: sliceString(title),
            topicName: sliceString(topic.displayName || ''),
          })}
        </span>
      </div>
      <Stack direction="col" alignItems="center" className="w-full mt-5">
        <Button
          className="w-full mb-3"
          variant="primary"
          onClick={handleClick}
          isLoading={isLoading}
          dataTestid="accept"
        >
          {t('common.accept')}
        </Button>
        <Button className="w-full" onClick={onClose} dataTestid="cancel">
          {t('common.cancel')}
        </Button>
      </Stack>
    </Modal>
  );
};

export default AddConversationToTopicDialog;
