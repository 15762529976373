import {
  CustomerStyles,
  Stack,
  useIsSelectedView,
  useStateProvider,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import TaskTabs from './Tabs/TaskTabs';
import TaskForm from 'src/components/tasks/TaskForm';
import DeleteTaskModal from 'src/components/tasks/DeleteTaskModal';

export const TaskPage = () => {
  const { state } = useStateProvider();
  const { showTaskForm } = state.workManagement;

  const isMobile = useIsSelectedView('sm');
  const isTabletView = useIsSelectedView('md');
  const { t } = useTranslation();

  return (
    <>
      <div className={CustomerStyles.wrapper}>
        <Stack
          direction="col"
          className={classnames(isMobile ? '' : 'px-8 pt-5 [&>div>div]:mb-0')}
        >
          <Stack
            className={classnames(
              'mb-6 mt-2 w-full',
              isTabletView && 'h-10',
              isMobile && 'mb-0'
            )}
            direction={isMobile ? 'col' : 'row'}
            justifyContent="between"
            alignItems={isMobile ? 'start' : 'center'}
          >
            <Stack
              direction="row"
              alignItems="center"
              className={classnames('w-full', isMobile && 'pl-4 mb-2')}
              justifyContent="between"
            >
              <p className={classnames(CustomerStyles.header)}>
                {t('sidebar.tasks')}
              </p>
            </Stack>
          </Stack>
        </Stack>
        <TaskTabs />
      </div>
      {showTaskForm && <TaskForm />}
      <DeleteTaskModal />
    </>
  );
};

export default TaskPage;
