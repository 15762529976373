import {
  Dropdown,
  IconButton,
  EllipsisHorizontalIcon,
  ArrowDownTrayIcon,
  EnvelopeOpenIcon,
  MessageType,
  useStateProvider,
  Stack,
  PencilIcon,
  XMarkIcon,
  FolderOpenIcon,
  useIsSelectedRange,
  useIsSelectedView,
  UserPlusIcon,
  Spinner,
  PuzzlePieceIcon,
  DropDownItemType,
  RectangleStackIcon,
  CalendarDaysIcon,
  FeatureFlags,
  environment,
} from '@trueconnect/tp-components';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import { BrokerAppLogic } from 'src/api';
import { mapping } from 'src/components/conversation/ManageStatus/ConversationStatusButton';
import classnames from 'classnames';

type ConversationActionsProps = {
  messages?: MessageType[];
  title?: string;
};

export const ConversationActionsBase = ({
  title = '',
  messages = [],
  setIsLoading,
}: ConversationActionsProps & {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  const isSelectedView = useIsSelectedRange(['sm', 'md', 'lg']);

  const { state, actions } = useStateProvider();
  const {
    state: status,
    branches = [],
    conversationId,
    readOnly,
  } = state.conversation.conversationData;

  const {
    setShowChangeStatusDialog,
    setShowChangeTitleDialog,
    setShowAddMembersDialog,
    setShowChangeBranchesDialog,
    setSelectedBranches,
    setShowAttachToTopicDialog,
    setShowAddTask,
  } = actions;

  const fetchMessages = async (page: number): Promise<MessageType[]> => {
    const messages = await BrokerAppLogic.getMessages(conversationId, page);
    return messages.length === 0
      ? ([] as MessageType[])
      : ([messages, ...(await fetchMessages(page + 1))] as MessageType[]);
  };

  const getAllMessages = async () => {
    const allMessages = await fetchMessages(0);
    return allMessages.flat();
  };

  const formatBody = async (data: MessageType[]) => {
    return (
      data
        ?.filter((item) => !!item.messageText)
        ?.map(
          (item) =>
            `[${
              dayjs(item.createdUtcTimestamp).format('DD.MM.YYYY, HH:mm') || ''
            }][${item.senderUserName || ''}] ${item.messageText}`
        )
        .join('\n') || ''
    );
  };

  const handleDownload = async () => {
    setIsLoading(true);
    const body = await formatBody(await getAllMessages());

    const blob = new Blob([body], { type: 'text/plain;charset=utf-8' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = `${title} ${dayjs().format('YYYY-MM-DD HH:mm')}.txt`;
    a.click();
    URL.revokeObjectURL(a.href);
    setIsLoading(false);
  };

  const OpenAsEmail = async () => {
    const body = await formatBody(messages);
    const mailtoLink = `mailto:?subject=${encodeURIComponent(title)}
      &body=${encodeURIComponent(body).slice(
        0,
        2000 - encodeURIComponent(title).length - 50
      )}
    `;
    window.location.href = mailtoLink;
  };

  const openAsEmail = {
    icon: <EnvelopeOpenIcon data-testid="openAsEmail" />,
    text: t('conversationPage.OpenAsEmail'),
    onClick: OpenAsEmail,
  };
  const download = {
    icon: <ArrowDownTrayIcon data-testid="download" />,
    text: t('conversationPage.download'),
    onClick: handleDownload,
  };
  // const generate =
  // {
  //   icon: <SparklesIcon />,
  //   text: t('conversationPage.GenerateEmailAsFile'),
  // },
  const conversationStatus = {
    icon:
      status === 'Open' ? (
        <XMarkIcon data-testid="conversationStatusOpen" />
      ) : (
        <FolderOpenIcon data-testid="conversationStatusClose" />
      ),
    classNames: mapping[status].buttonStyles,
    type: 'button',
    text:
      status === 'Open'
        ? t('conversation.open.statusPanel.buttonOnAccept')
        : t('conversation.closed.statusPanel.buttonOnAccept'),
    onClick: () => {
      setShowChangeStatusDialog(true);
    },
  };

  const changeTitle = {
    icon: <PencilIcon data-testid="changeTitle" />,
    text: t('common.titleWithExpand.title'),
    onClick: () => {
      setShowChangeTitleDialog(true);
    },
  };

  const inviteMember = {
    icon: <UserPlusIcon data-testid="inviteMember" />,
    text: t('conversation.participant.add.title'),
    onClick: () => {
      setShowAddMembersDialog(true);
    },
  };

  const editBranches = {
    icon: <PuzzlePieceIcon data-testid="editBranches" />,
    text: t('conversationPage.branches'),
    onClick: () => {
      setSelectedBranches(branches);
      setShowChangeBranchesDialog(true);
    },
  };

  const attactToTopic = {
    icon: <RectangleStackIcon data-testid="attactToTopic" />,
    text: t('conversationPage.attactToTopic'),
    classNames: 'text-[#0B85FE]',
    onClick: () => {
      setShowAttachToTopicDialog(true);
    },
  } as DropDownItemType;

  const addTask = {
    icon: <CalendarDaysIcon data-testid="addTask" />,
    text: t('Tasks.modals.addTask.action'),
    onClick: () => {
      setShowAddTask(true);
    },
  } as DropDownItemType;

  const messageActions = [openAsEmail, download];
  const mobileActions = isSelectedView ? [changeTitle, inviteMember] : [];

  if (status == 'Closed') return [...messageActions, conversationStatus];

  return readOnly
    ? [...mobileActions, ...messageActions]
    : [
        editBranches,
        ...mobileActions,
        ...messageActions,
        attactToTopic,
        ...(environment && FeatureFlags.showTasks(environment)
          ? [addTask]
          : []),
        conversationStatus,
      ];
};

export const ConversationActions: React.FC<ConversationActionsProps> = ({
  title = '',
  messages = [],
}) => {
  const isMobileView = useIsSelectedView('sm');
  const [isLoading, setIsLoading] = useState(false);
  const items = ConversationActionsBase({ title, messages, setIsLoading });

  return (
    <Dropdown
      defaultItemClassnames="text-customColor_9"
      mobileView={isMobileView}
      label={
        <Stack direction="row" className="mr-1">
          <IconButton
            hoverable={false}
            dataTestId="ConversationActions"
            customSize={classnames('w-6 h-6', isMobileView && 'mr-1')}
          >
            {isLoading ? (
              <Spinner className="w-6 h-6" />
            ) : (
              <EllipsisHorizontalIcon className="w-6 h-6 text-black" />
            )}
          </IconButton>
        </Stack>
      }
      items={items}
    />
  );
};

export default ConversationActions;
