import { useStateProvider } from '@trueconnect/tp-components';
import { mapping } from './ConversationStatusButton';

export const ConversationStatusModal = () => {
  const { state, actions } = useStateProvider();

  const { state: status } = state.conversation.conversationData;
  const { showChangeStatusDialog: visible } = state.conversation;
  const { setShowChangeStatusDialog } = actions;

  const { modal: SelectedModal } = mapping[status];

  return (
    <>
      <SelectedModal
        {...{
          close: () => {
            setShowChangeStatusDialog(false);
          },
          status,
          visible,
        }}
      />
    </>
  );
};

export default ConversationStatusModal;
