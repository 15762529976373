import { ContactStatus, IconButton } from '@trueconnect/tp-components';
import { indicatorStates } from './states';
import classnames from 'classnames';

export const StatusIcon = ({
  status,
  contactId,
  classNames,
}: {
  status?: ContactStatus;
  contactId?: number;
  classNames?: string;
}) => {
  return (
    <>
      {status && (
        <IconButton
          hoverable={false}
          customSize="w-4 h-4 !p-0"
          className={classnames(
            indicatorStates[status].className,
            'p-[3px] ml-2',
            classNames
          )}
        >
          {contactId && indicatorStates[status].icon}
        </IconButton>
      )}
    </>
  );
};
