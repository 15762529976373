import { useNavigate } from 'react-router-dom';
import { IconButton } from '../../components';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

export const RedirectButton = ({
  path = '/',
  className,
}: {
  path: string;
  className?: string;
}) => {
  const navigate = useNavigate();
  return (
    <IconButton
      className={className}
      dataTestId="RedirectButton"
      onClick={() => {
        navigate(path);
      }}
    >
      <ChevronLeftIcon className="text-customColor_5" />
    </IconButton>
  );
};

export default RedirectButton;
