import React from 'react';
import { useHubListener } from './HubConnection';
import { useGetMethods } from './useGetMethods';
import { ContactPermissionDto } from '../UserProfileContext';

export type HubListenerProviderProps = {
  children: React.ReactNode;
  serverUrl: string;
  loginRequest: { scopes: string[] };
  permissions?: ContactPermissionDto;
};

export const HubListenerProvider: React.FC<HubListenerProviderProps> = ({
  children,
  serverUrl,
  loginRequest,
  permissions,
}) => {
  const methods = useGetMethods(permissions);

  useHubListener(serverUrl, loginRequest, methods);

  return <>{children}</>;
};

export default HubListenerProvider;
