import {
  ExtendedConversationSearch as ExtendedConversationSearchCommon,
  DateSearch,
  StatusSearch,
  BranchSearch,
  TopicSearch,
  useStateProvider,
  branchType,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { ResizableBoxProps } from 'react-resizable';

import { useApiInfiniteQuery } from 'src/api';
import { TopicType } from '@trueconnect/tp-components/src/providers/WorkManagement/typing';
import { useState } from 'react';
import ContactSearch from 'src/components/conversation/ExtendedSearch/ContactSearch';

export const ExtendedCustomerConversationSearchBroker = ({
  resizeProps,
  classNames,
  selectedCustomerId,
}: {
  resizeProps?: ResizableBoxProps;
  classNames?: string;
  selectedCustomerId?: number;
}) => {
  const [searchParam, setSearchParam] = useState('');
  const [enabled, setEnabled] = useState(false);

  const queryTopics = useApiInfiniteQuery(
    'searchTopics',
    [
      {
        search: searchParam,
        page: 0,
        customerIds: selectedCustomerId ? [selectedCustomerId] : [],
      },
    ],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map((subitem) => subitem as TopicType)
        ),
      enabled,
    }
  );

  const { state, actions } = useStateProvider();
  const { extendedSearchParams, showExtendedSearch } = state.conversation;
  const { selectedBranches } = extendedSearchParams;
  const { setExtendedSearchParams, setShowExtendedSearch } = actions;
  const isMobileView = useIsSelectedView('sm');

  const setSelectedBranches = (branch: branchType) => {
    if (!branch.id) return;
    if (selectedBranches?.includes(branch.id))
      setExtendedSearchParams({
        ...extendedSearchParams,
        selectedBranches: selectedBranches.filter((item) => item !== branch.id),
      });
    else {
      setExtendedSearchParams({
        ...extendedSearchParams,
        selectedBranches: [...(selectedBranches || []), branch.id],
      });
    }
  };

  return (
    <ExtendedConversationSearchCommon
      {...{
        resizeProps,
        classNames,
        showExtendedSearch,
        setShowExtendedSearch,
        clearExtendedSearchParams: () => {
          setExtendedSearchParams({});
          isMobileView && setShowExtendedSearch(false);
        },
      }}
    >
      <>
        <ContactSearch active={true} selectedCustomerId={selectedCustomerId} />
        <DateSearch
          {...{
            onAccept: (dateStart, dateEnd) => {
              setExtendedSearchParams({
                ...extendedSearchParams,
                dateStart,
                dateEnd,
              });
            },
            clear: () => {
              setExtendedSearchParams({
                ...extendedSearchParams,
                dateStart: undefined,
                dateEnd: undefined,
              });
            },
          }}
        />
        <StatusSearch />
        <BranchSearch
          {...{
            selectedBranches,
            setSelectedBranches,
          }}
        />
        <TopicSearch
          {...{ setEnabled, queryTopics, setSearchParam, searchParam }}
        />
      </>
    </ExtendedConversationSearchCommon>
  );
};

export default ExtendedCustomerConversationSearchBroker;
