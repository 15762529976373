import { payloadType } from '../StateProvider/actions';
import { SpinnerSubscriber } from './reducers';

type actionType =
  | 'SUBSCRIBE_TO_SPINNER'
  | 'UNSUBSCRIBE_TO_SPINNER'
  | 'SET_PARENT_LOADED'
  | 'SET_PARENT_ACTIVE';

interface action {
  type: actionType;
  payload?: payloadType;
}

interface actions {
  [key: string]: (payload?: payloadType) => action;
}

export const stateActionsSpinner: actions = {
  SubscribeToSpinner: (subscriber: SpinnerSubscriber) => ({
    type: 'SUBSCRIBE_TO_SPINNER',
    payload: subscriber,
  }),
  UnSubscribeToSpinner: (name: string) => ({
    type: 'UNSUBSCRIBE_TO_SPINNER',
    payload: name,
  }),
  SetSpinnerParentLoaded: (setter: boolean) => ({
    type: 'SET_PARENT_LOADED',
    payload: setter,
  }),
  SetSpinnerParentActive: (setter: boolean) => ({
    type: 'SET_PARENT_ACTIVE',
    payload: setter,
  }),
};

export type actionsSpinner = {
  SubscribeToSpinner: (subscriber: SpinnerSubscriber) => void;
  UnSubscribeToSpinner: (name: string) => void;
  SetSpinnerParentLoaded: (setter: boolean) => void;
  SetSpinnerParentActive: (setter: boolean) => void;
};

export default stateActionsSpinner;
