import { VirtualizedGridCellRendererType } from './../../../components';
import { IDocumentType } from '../DocumentType';
import FileListItem from './FileListItem';
import { t } from 'i18next';

export const FileInfo: VirtualizedGridCellRendererType<IDocumentType> = {
  dataPropName: 'fileInfo',
  title: t('file.name'),
  render: FileListItem,
  widthRatio: 8,
};

export default FileInfo;
