import {
  ChevronDownIcon,
  ChevronUpIcon,
  Stack,
} from '@trueconnect/tp-components';
import { useState } from 'react';
import classnames from 'classnames';
import useGetColumns from './useGetColumns';
import { FetchBrokerRepresentativeDto } from 'src/api';

type DataTableProps = {
  data: FetchBrokerRepresentativeDto[];
};

export const DeputyFor: React.FC<DataTableProps> = ({ data }) => {
  const [sortedData, setSortedData] =
    useState<FetchBrokerRepresentativeDto[]>(data);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | null>(
    null
  );
  const [sortKey, setSortKey] = useState<string>('representativeUserName');

  const handleSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';

    if (sortKey === key) {
      direction = sortDirection === 'asc' ? 'desc' : 'asc';
    }

    const sorted = [...sortedData].sort((a, b) => {
      const aValue = a[key as keyof FetchBrokerRepresentativeDto];
      const bValue = b[key as keyof FetchBrokerRepresentativeDto];

      if (aValue !== undefined && bValue !== undefined) {
        if (direction === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      } else {
        if (aValue === undefined && bValue === undefined) {
          return 0;
        } else if (aValue === undefined) {
          return 1;
        } else {
          return -1;
        }
      }
    });

    setSortedData(sorted);
    setSortDirection(direction);
    setSortKey(key);
  };

  const renderSortIcon = (key: string) => {
    return sortDirection === 'asc' && sortKey === key ? (
      <ChevronUpIcon className="h-4 w-4 ml-1 inline-block" />
    ) : (
      <ChevronDownIcon className="h-4 w-4 ml-1 inline-block" />
    );
  };

  const columns = useGetColumns();

  return (
    <Stack className="w-full py-3 overflow-hidden">
      <table className="w-full">
        <thead>
          <tr className="text-gray-500 border-b">
            {columns.map((column) => (
              <th
                key={column.key}
                className={classnames(
                  'cursor-pointer py-2 text-start px-2',
                  column.headerClassname
                )}
                onClick={() =>
                  column.key !== 'status' && handleSort(column.key)
                }
              >
                <div className="overflow-hidden flex items-center">
                  <p className="truncate"> {column.label}</p>
                  {column.key !== 'status' && renderSortIcon(column.key)}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item) => (
            <tr key={item.representationUserId} className="border-b">
              {columns.map((column) => (
                <td
                  key={column.key}
                  className={classnames(
                    'py-3 whitespace-nowrap text-center font-medium px-3',
                    column.className
                  )}
                >
                  {column.render(item)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Stack>
  );
};

export default DeputyFor;
