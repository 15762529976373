import { PathHeader, capitalizeFirstLetter } from '@trueconnect/tp-components';
import { useLocation } from 'react-use';
import { useTranslation } from 'react-i18next';
import { FetchCustomerInfoDto } from 'src/api';

const limit = 3;

export const CustomersBreadCrumb: React.FC<{
  id?: number;
  customerData?: FetchCustomerInfoDto;
  classNames?: string;
}> = ({ id, customerData, classNames }) => {
  const path =
    useLocation()
      .pathname?.split('/')
      .filter((i) => !!i) || [];
  const { t } = useTranslation();

  const params = path.map((item, idx) => {
    const pth = `/${path.slice(0, idx + 1).join('/')}`;
    if (id && item === id.toString())
      return {
        name: customerData?.name || '',
        path: pth,
      };
    return {
      name: capitalizeFirstLetter(t(`tab.${item}.text` as string, item)),
      path: pth,
    };
  });

  return (
    <PathHeader
      params={params.slice(0, limit ? limit : params.length)}
      classNames={classNames}
    />
  );
};

export default CustomersBreadCrumb;
