import {
  CheckBox,
  CommonStyles,
  CustomerStyles,
  SearchInput,
  Stack,
  VirtualizedList,
  hoverClassname,
  searchInputStyles,
  useIsSelectedView,
  useStateProvider,
} from '@trueconnect/tp-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerProps } from './CustomerProps';
import CustomersBreadCrumb from './CustomersBreadCrumb';
import { useApiInfiniteQuery } from 'src/api';
import useGetCustomersColumns from './useGetCustomersColumns';
import classnames from 'classnames';
import InvitedCustomersList from './InvitedCustomersList';
import StatusDialog from 'src/components/StatusBadge/StatusDialog';
import { InvitedCustomersButton } from './InvitedCustomersButton';

const mobileSearchInputStyles = 'w-full h-full mx-4';

export const DataTableCustomer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [searchParam, setSearchParam] = useState('');
  const navigate = useNavigate();
  const { invitedModalVisible } = useStateProvider().state.customer;
  const [hasInvitedContacts, setHasInvitedContacts] = useState<
    boolean | undefined
  >();

  const queryCustomers = useApiInfiniteQuery(
    'searchCustomers',
    [searchParam, hasInvitedContacts, 0],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map(
            (subitem) =>
              ({
                ...subitem,
                id: String(subitem.id),
                icon: subitem.logo || '',
                name: subitem.name || '',
                address: subitem.localAddress || '',
                manager: subitem.accountManagerName,
                isHolding: subitem.isAHolding,
              } as CustomerProps)
          )
        ),
      refetchOnWindowFocus: false,
      errorMessage: t('customers.loading.error'),
    }
  );

  const columns = useGetCustomersColumns();
  const isMobile = useIsSelectedView('sm');
  const isTabletView = useIsSelectedView('md');

  const onRowClick = (item: CustomerProps) => {
    const selection = window.getSelection();
    if (selection?.toString() === '') navigate(`${item.id}`);
  };

  return (
    <div className={CustomerStyles.wrapper}>
      <Stack
        direction="col"
        className={classnames(isMobile ? '' : 'px-8 pt-5 [&>div>div]:mb-0')}
      >
        {!isMobile && (
          <Stack className={classnames('w-full', isMobile && 'pl-4')}>
            <CustomersBreadCrumb id={id ? parseInt(id) : undefined} />
          </Stack>
        )}
        <Stack
          className={classnames(
            'mb-2 mt-2 w-full',
            isTabletView && 'h-10',
            isMobile && 'mb-0'
          )}
          direction={isMobile ? 'col' : 'row'}
          justifyContent="between"
          alignItems={'start'}
        >
          <Stack
            direction="row"
            alignItems="center"
            className={classnames('w-full', isMobile && 'pl-4 mb-2')}
            justifyContent="between"
          >
            <p className={classnames(CustomerStyles.header)}>
              {t('sidebar.customers')}
            </p>
            <InvitedCustomersButton />
          </Stack>
          <Stack
            alignItems="stretch"
            direction="col"
            className={classnames(isMobile ? 'w-full mb-3' : '')}
          >
            <SearchInput
              setSearchParam={setSearchParam}
              classNames={{
                wrapper: classnames(
                  'h-10',
                  isMobile ? mobileSearchInputStyles : searchInputStyles,
                  isTabletView && 'max-w-[220px]'
                ),
                input: 'text-sm',
              }}
              placeholder={t('navbar.createConversationPanel.inputPlaceholder')}
              saveHistory={true}
              inputName="CustomersSearch"
              dataTestid="customerSearch"
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" className="pl-6 pb-2">
        <CheckBox
          checked={!!hasInvitedContacts}
          onChange={() => {
            if (hasInvitedContacts) {
              setHasInvitedContacts(undefined);
            } else {
              setHasInvitedContacts(true);
            }
          }}
        />
        <label className="text-sm">{t('customers.hasInvitedCustomers')}</label>
      </Stack>
      <div className={classnames(CommonStyles.full, 'relative')}>
        <div className={classnames(CommonStyles.full, 'absolute')}>
          <VirtualizedList
            columns={columns}
            onRowClick={onRowClick}
            isSearching={!!searchParam}
            headerHeight={isMobile ? 0 : 46}
            infinityQuery={queryCustomers}
            rowHeight={isMobile ? 95 : 70}
            classNames={{
              row: classnames(
                hoverClassname,
                isMobile ? '!border-none' : 'border-b-2'
              ),
              column: 'pr-5',
              header: classnames('text-sm pt-[10px]'),
            }}
          />
        </div>
      </div>
      {invitedModalVisible && <InvitedCustomersList />}
      <StatusDialog />
    </div>
  );
};
