export interface HierarchyItemDto {
  documentId?: string | undefined;
  name?: string | undefined;
  child?: HierarchyItemDto;
}

export const formatHierarchy = (
  item: HierarchyItemDto | undefined
): Array<{ id?: string; name?: string }> => {
  const result: Array<{ id?: string; name?: string }> = [];

  if (item) {
    if (item.documentId !== undefined && item.name !== undefined) {
      result.push({ id: item.documentId, name: item.name });
    }

    if (item.child) {
      result.push(...formatHierarchy(item.child));
    }
  }

  return result;
};

export default formatHierarchy;
