import { useTranslation } from 'react-i18next';
import {
  error_page,
  error_page_face,
  error_page_forbidden,
  error_page_globe,
  error_page_white,
} from '../../images';
import { Stack } from './../../controls';
import { SmartSpinnerLocal } from './../../providers';
import {
  CustomHeightWrapper,
  ErrorWrapper,
  textStyle_1,
  textStyle_3,
} from './../../styles';
import { useIsSelectedView } from './../../utils';
import { Button } from './../../components';
import { HttpStatusCode } from './../../components/ErrorBoundary/typing';
import { useNavigate } from 'react-router-dom';

type ErrorProps = {
  height?: 'full' | 'screen';
  title?: string | null;
  subTitle?: string | null;
  statusCode?: HttpStatusCode;
  buttonType?: 'refresh' | 'dashboard' | '';
  children?: React.ReactNode;
};

const typing = {
  [HttpStatusCode.ConnectionTimeout]: error_page_white,
  [HttpStatusCode.ERR_NETWORK]: error_page_globe,
  [HttpStatusCode.InternalServerError]: error_page_face,
  [HttpStatusCode.Forbidden]: error_page_forbidden,
} as Record<HttpStatusCode, string>;

export const Error: React.FC<ErrorProps> = ({
  height = 'screen',
  title,
  subTitle,
  statusCode = HttpStatusCode.else,
  buttonType = '',
  children = <></>,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobileView = useIsSelectedView('sm');

  const handleRefresh = () => {
    window.location.reload();
  };

  const dashboardRedirect = () => {
    navigate('/');
  };

  const imgSrc = typing[statusCode as HttpStatusCode] || error_page;

  return (
    <>
      <SmartSpinnerLocal name="Image" condition={false} />
      <Stack
        className={CustomHeightWrapper(height)}
        justifyContent="center"
        dataTestid={statusCode.toString()}
      >
        <Stack className={ErrorWrapper(isMobileView)} alignItems="center">
          <img alt="" className="mb-4 w-52 h-52" src={imgSrc} />
          <h1 className={textStyle_3}>
            {title ? title : t('error.else.title')}
          </h1>
          <h1 className={textStyle_1}>
            {subTitle ? subTitle : t('error.else.subtitle')}
          </h1>
          {children}
          {buttonType === 'refresh' && (
            <Button className="mt-2" onClick={handleRefresh}>
              {t('common.clickToRefresh')}
            </Button>
          )}
          {buttonType === 'dashboard' && (
            <Button className="mt-2" onClick={dashboardRedirect}>
              {t('common.dashboardNavigate')}
            </Button>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default Error;
