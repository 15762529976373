import classnames from 'classnames';
import { CommonStyles, defaultScrollbar } from './commonStyles';
import { textStyle_2, textStyle_1 } from './TextStyles';

export const DashboardStyles = {
  wrapper: (isMobileView: boolean) =>
    classnames(
      'flex-col bg-customColor_17 relative flex',
      defaultScrollbar,
      CommonStyles.full,
      !isMobileView && 'px-8 pb-6 overflow-auto'
    ),
  panel: (isMobileView: boolean) =>
    classnames(
      'bg-customColor_17 overflow-hidden',
      !isMobileView && 'pb-5',
      CommonStyles.full
    ),

  greeting: (isMobileView: boolean) =>
    classnames(
      textStyle_2,
      'min-h-10 leading-normal testDashboardHeader',
      isMobileView ? 'mt-2 mb-6 mx-4' : 'mt-8'
    ),
  header: (isMobileView: boolean) =>
    classnames(
      textStyle_1,
      'leading-normal font-semibold select-none',
      isMobileView ? 'px-4 !text-lg' : 'py-2 pb-4'
    ),
};
