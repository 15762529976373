import { useTranslation } from 'react-i18next';
import {
  ConversationListItemProps,
  ConversationTable,
  FunnelIcon,
  IconButton,
  InformationNote,
  SearchInput,
  Stack,
  TopicType,
  getBranches,
  relativeFull,
  searchInputStyles,
  useIsSelectedRange,
  useIsSelectedView,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import { ConversationState, useApiInfiniteQuery } from 'src/api';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ConversationStatusFilter from '../../../../../../components/conversation/ConversationStatusFilter';
import classnames from 'classnames';
import ExtendedCustomerConversationSearchBroker from '../../ExtendedConversationSearch';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import ConversationTopicsSidebar from 'src/components/conversation/ConversationTopicsSidebar';

type ContactsTabProps = {
  id: number;
  searchParam?: string;
  setSearchParam: Dispatch<SetStateAction<string>>;
};

const ConversationTab: React.FC<ContactsTabProps> = ({
  id,
  searchParam,
  setSearchParam,
}) => {
  const { t } = useTranslation();
  const { state, actions } = useStateProvider();
  const { addNotification } = useNotificationSystem();
  const {
    showExtendedSearch,
    extendedSearchParams,
    conversationFilter: selectedType,
  } = state.conversation;
  const { availableBranches, selectedTopic, showTopicsSidebar } =
    state.workManagement;

  const {
    setShowExtendedSearch,
    setConversationType: setSelectedType,
    setExtendedSearchParams,
    setSelectedTopic,
    setShowTopicsSidebar,
  } = actions;

  useEffect(() => {
    setSelectedTopic({});
  }, [setSelectedTopic]);

  const {
    selectedContacts,
    dateStart,
    dateEnd,
    selectedBranches,
    selectedTopics,
    withoutTopic,
  } = extendedSearchParams;

  useEffect(() => {
    setShowExtendedSearch(false);
    setExtendedSearchParams({});
    return () => {
      setShowExtendedSearch(false);
      setExtendedSearchParams({});
    };
  }, [setShowExtendedSearch, setExtendedSearchParams]);

  const [topicSearchParam, setTopicSearchParam] = useState('');
  const queryTopics = useApiInfiniteQuery(
    'searchTopics',
    [
      {
        search: topicSearchParam,
        page: 0,
        customerIds: [id],
      },
    ],
    {
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('Topics.Sidebar.error'),
        });
      },
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map((subitem) => subitem as TopicType)
        ),
    }
  ) as UseInfiniteQueryResult<TopicType[], Error>;

  const queryConversations = useApiInfiniteQuery(
    'searchCustomerConversations',
    [
      id,
      {
        search: searchParam,
        page: 0,
        state:
          selectedType === 'All'
            ? undefined
            : (selectedType as ConversationState),
        userIds: selectedContacts,
        dateCreatedFrom: dateStart,
        dateCreatedTo: dateEnd,
        branchIds: selectedBranches,
        topicIds: [
          ...(selectedTopic.id ? [selectedTopic.id] : []),
          ...(selectedTopics || []),
        ],
        hasTopic: withoutTopic,
      },
    ],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map(
            (subitem) =>
              ({
                title: subitem.title,
                id: subitem.id,
                participants: subitem.participants,
                lastActive: subitem.lastActivityUtcTimestamp,
                hasUnReadMessages: subitem.hasUnReadMessages,
                branches: getBranches(availableBranches, subitem?.branchIds),
                ...subitem,
              } as ConversationListItemProps)
          )
        ),
    }
  );

  const isMobileView = useIsSelectedView('sm');
  const isTabletView = useIsSelectedRange(['sm', 'md']);
  const isSelectedView = useIsSelectedRange(['lg', 'xl']);

  if (isTabletView && showExtendedSearch)
    return (
      <ExtendedCustomerConversationSearchBroker
        classNames={'border-l'}
        selectedCustomerId={id}
      />
    );

  return (
    <Stack
      className={classnames(
        relativeFull,
        'overflow-hidden',
        !isTabletView && !showExtendedSearch && 'pr-4'
      )}
    >
      <Stack direction="row" className="w-full h-full">
        <ConversationTopicsSidebar
          {...{
            queryTopics,
            setSearchParam: setTopicSearchParam,
            searchParam: topicSearchParam,
            variant: 'secondary',
            setIsFull: setShowTopicsSidebar,
          }}
        />
        {!(showTopicsSidebar && isMobileView) && (
          <Stack
            className={classnames(
              'w-full h-full',
              !isTabletView && 'pl-8',
              showExtendedSearch && 'pr-4'
            )}
          >
            <Stack
              direction="row"
              className={classnames(
                'w-full',
                isMobileView ? 'pt-0' : 'py-4 pl-0',
                isMobileView && 'border-b'
              )}
              alignItems="center"
            >
              <ConversationStatusFilter
                {...{
                  selectedType,
                  setSelectedType,
                  isMobileView,
                  classNames: isMobileView ? '!pr-0 [&>button>span]:!px-1' : '',
                }}
              />
              <Stack direction="row" alignItems="center" className="mr-4">
                <IconButton
                  className="relative"
                  onClick={() => {
                    setShowExtendedSearch(!showExtendedSearch);
                  }}
                >
                  {isMobileView &&
                    (!!extendedSearchParams.selectedCustomer ||
                      !!extendedSearchParams.selectedContacts ||
                      !!extendedSearchParams.dateStart ||
                      !!extendedSearchParams.dateEnd) && (
                      <div className="absolute top-0 left-4 w-3 h-3 bg-blue-500 rounded-full" />
                    )}
                  <FunnelIcon
                    data-testid={
                      showExtendedSearch
                        ? 'closeExtendedConversationSearch'
                        : 'startExtendedConversationSearch'
                    }
                  />
                </IconButton>
                {!isMobileView && t('customer.extendedSearch.filter')}
              </Stack>
            </Stack>
            {isMobileView && (
              <SearchInput
                setSearchParam={setSearchParam}
                classNames={{
                  wrapper: classnames(searchInputStyles, 'border-none h-16'),
                }}
                dataTestid="customerPageSearch"
                placeholder={t(`tab.conversations.Searchplaceholder`)}
                isMobileView={true}
              />
            )}

            <div className={classnames('w-full h-full')}>
              <ConversationTable
                {...{
                  queryConversations,
                  nothingFound: (
                    <InformationNote
                      title={t('dashboard.panel.no.recent.conversations')}
                    />
                  ),
                }}
              />
            </div>
          </Stack>
        )}
        {isSelectedView && (
          <ExtendedCustomerConversationSearchBroker
            classNames={'border-l'}
            selectedCustomerId={id}
          />
        )}
      </Stack>
    </Stack>
  );
};

export { ConversationTab };
