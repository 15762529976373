import {
  Button,
  IconButton,
  XMarkIcon,
  FolderOpenIcon,
  useStateProvider,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import CloseConversationModal from './CloseConversationModal';
import OpenConversationModal from './OpenConversationModal';
import classnames from 'classnames';

export type conversationStatusModalProps = {
  close: () => void;
  visible: boolean;
};

export const mapping: {
  [key: string]: {
    modal: React.FC<conversationStatusModalProps>;
    buttonStyles?: string;
    icon?: React.ReactNode;
  };
} = {
  Open: {
    modal: CloseConversationModal,
    buttonStyles: '',
    icon: <XMarkIcon data-testid={`state_Open`} />,
  },
  Closed: {
    modal: OpenConversationModal,
    buttonStyles:
      '!bg-green-600 text-white hover:!bg-green-700 [&>span>div]:!mb-[2px]',
    icon: <FolderOpenIcon color="white" data-testid={`state_Closed`} />,
  },
};

export const ConversationStatusButton = ({
  isSelectedView,
}: {
  isSelectedView: boolean;
}) => {
  const { t } = useTranslation();
  const { state, actions } = useStateProvider();
  const { state: status } = state.conversation.conversationData;
  const { setShowChangeStatusDialog } = actions;

  if (!status) return <></>;

  const { icon, buttonStyles } = mapping[status];

  return (
    <>
      <Button
        className={classnames(
          'border border-2 h-[34px] !p-0 mr-[3px] cursor-pointer [&>span]:!py-2',
          isSelectedView && '[&>span]:p-1',
          buttonStyles
        )}
        variant="secondary"
        onClick={() => {
          setShowChangeStatusDialog(true);
        }}
      >
        <IconButton
          size="none"
          hoverable={false}
          className="!w-4 !h-4 mr-1"
          imgClassName="mx-0"
        >
          {icon}
        </IconButton>
        {!isSelectedView && (
          <p className="text-xs">
            {t(
              `conversation.${
                status === 'Open' ? 'open' : 'closed'
              }.statusPanel.buttonOnAccept`
            )}
          </p>
        )}
      </Button>
    </>
  );
};

export default ConversationStatusButton;
