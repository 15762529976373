import {
  useIsSelectedView,
  Dropdown,
  Stack,
  QuestionMarkCircleIcon,
  IconButton,
  ChevronRightIcon,
  hoverClassname,
} from '@trueconnect/tp-components';
import { HelpType } from '@trueconnect/tp-components/src/pages/Help';
import { getContextUrl } from './../../utils';
import { t } from 'i18next';
import classnames from 'classnames';

export const ContextHelpIcon = ({ docs }: { docs: HelpType[] }) => {
  const isMobileView = useIsSelectedView('sm');
  const path = window.location.pathname.split('/')[1];

  const contextData = docs
    .map((item) => ({
      ...item,
      contextURL: getContextUrl(item),
    }))
    .sort((a, b) => b.contextURL.length - a.contextURL.length)
    .find((item) => path == item.contextURL);

  if (!contextData) {
    return <QuestionMarkCircleIcon color="#6B7280" className="w-8 h-8 ml-4" />;
  }

  return (
    <Dropdown
      arrowIcon={false}
      placement="left-start"
      className={classnames(
        isMobileView ? '' : 'w-[340px] [&>ul>div:nth-child(2)]:hidden',
        '[&>ul>li:not(:last-child)]:border-b'
      )}
      modalClassnames="[&>div>div>div:nth-child(2)]:p-0 [&>div>div>div>h3]:w-full"
      inline={true}
      mobileView={isMobileView}
      modalTitle={
        <Stack
          direction="row"
          className="w-full pb-1"
          alignItems="center"
          justifyContent="between"
        >
          <p className="text-lg font-semibold select-none">
            {t('ContextHelp.title')}
          </p>
          <p
            className="text-base font-medium pr-2 text-customColor_5 cursor-pointer"
            onClick={() => {
              window.open('/help', '_blank');
            }}
          >
            {t('ContextHelp.helpPageLink')}
          </p>
        </Stack>
      }
      header={
        <Stack
          direction="row"
          className="w-full"
          alignItems="center"
          justifyContent="between"
        >
          <p className="text-lg font-semibold select-none">
            {t('ContextHelp.title')}
          </p>
          <p
            className="text-base font-medium text-customColor_5 cursor-pointer"
            onClick={() => {
              window.open('/help', '_blank');
            }}
          >
            {t('ContextHelp.helpPageLink')}
          </p>
        </Stack>
      }
      label={
        <QuestionMarkCircleIcon
          color="#0170DF"
          className="w-8 h-8 ml-4 cursor-pointer"
        />
      }
      items={contextData.children.map((item) => ({
        component: (
          <Stack
            direction="row"
            className="pl-2 pr-1 py-1 w-full h-full text-pretty"
            alignItems="center"
            justifyContent="between"
            onClick={() => {
              window.open(`/help/${item.title.replaceAll(' ', '_')}`, '_blank');
            }}
          >
            <p className="text-left">{item.title}</p>
            <IconButton
              hoverable={false}
              size="sm"
              className="!p-0 !m-0 !w-5 !mr-3"
            >
              <ChevronRightIcon />
            </IconButton>
          </Stack>
        ),
        classNames: classnames(
          'w-full',
          isMobileView ? 'py-4' : 'py-2',
          isMobileView && 'h-[50px] border-b',
          hoverClassname
        ),
      }))}
    />
  );
};

export default ContextHelpIcon;
