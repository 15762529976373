import {
  AuthenticationResult,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useAsync } from 'react-use';

export interface AcquireAccessTokenOptions {
  scopes: string[];
  onError?: (ex?: unknown) => void;
}

export const useGetToken = ({
  scopes = [],
  onError,
}: AcquireAccessTokenOptions) => {
  const { instance } = useMsal();

  const account = instance.getActiveAccount() || undefined;

  const goToLogin = async () => {
    if (onError) {
      onError();
    } else
      await instance.loginRedirect({
        scopes,
        account,
      });
  };

  const checkTokenLifetime = (token?: AuthenticationResult) => {
    const expiresOn = token?.expiresOn;

    if (expiresOn) {
      const expirationTime =
        (new Date(expiresOn).getTime() - new Date().getTime()) / (60 * 1000);
      if (expirationTime < 5) {
        goToLogin();
      }
    }
  };

  const refreshAccessToken = async () => {
    try {
      const token = await instance.acquireTokenSilent({
        scopes,
        account,
      });

      checkTokenLifetime(token);

      if (!token.accessToken) {
        await goToLogin();
      }
      return token;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        goToLogin();
      } else {
        console.error(error);
      }
    }
  };

  const token = useAsync(async () => {
    return await refreshAccessToken();
  });

  checkTokenLifetime(token.value);

  if (!account) {
    console.error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
    goToLogin();
    return null;
  }

  return token.value?.accessToken;
};
