import React, { ReactNode } from 'react';
import { Button } from '@trueconnect/tp-components';
import Modal from './Modal';
import { t } from 'i18next';

type ConfirmationModalProps = {
  confirmationText?: ReactNode;
  show?: boolean;
  title?: string;
  acceptText?: string;
  onAccept: () => void;
  declineText?: string;
  onClose?: () => void;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  confirmationText,
  acceptText = t('common.accept'),
  show,
  title,
  onAccept,
  declineText = t('common.cancel'),
  onClose,
}) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      title={title}
      footer={
        <>
          <Button className="w-1/2" variant="secondary" onClick={onClose}>
            {declineText}
          </Button>
          <Button className="w-1/2" variant="primary" onClick={onAccept}>
            {acceptText}
          </Button>
        </>
      }
    >
      {confirmationText}
    </Modal>
  );
};

export default ConfirmationModal;
