import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '../../images';
import IconButton from '../../components/IconButton';
import { Stack } from '../../controls';
import { hoverClassname } from '../../styles';

export const AccordionCell = ({
  className = '',
  searchParam = '',
  title = '',
  children,
}: {
  className?: string;
  searchParam?: string;
  title?: string | React.ReactNode;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(!!searchParam);
  }, [searchParam]);

  return (
    <div className={classnames('w-full', className)}>
      <Stack
        className={classnames(
          'w-full text-left font-medium h-[64px] p-5 pr-3',
          isOpen && 'bg-customColor_2 border-b',
          !isOpen && 'text-gray-500',
          hoverClassname
        )}
        direction="row"
        alignItems="center"
        justifyContent="between"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        {title}
        <IconButton>
          {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </IconButton>
      </Stack>
      {isOpen && <div className="w-full h-fit p-5">{children}</div>}
    </div>
  );
};
