import { useEffect, useRef, useState } from 'react';
import {
  CheckCircleIcon,
  PencilIcon,
  XCircleIcon,
} from '@trueconnect/tp-components/src/images';
import { t } from 'i18next';
import { IconButton } from '@trueconnect/tp-components/src/components/IconButton';
import { Spinner } from '@trueconnect/tp-components/src/components';
import Stack from '@trueconnect/tp-components/src/controls/Stack';
import { useNotificationSystem } from '@trueconnect/tp-components/src/providers';
import { conversationPageStyles } from '@trueconnect/tp-components/src/styles';
import classnames from 'classnames';
import { useClickAway } from 'react-use';

export type TitleWithEditProps = {
  title: string;
  onSave?: (value: string) => void;
  onChange?: (value: string) => void;
  isSaving?: boolean;
  isEditable?: boolean;
  notEmptyWarning?: string;
  placeholder?: string;
  inputClassName?: string;
  className?: string;
  shouldValidate?: boolean;
  dataTestId?: string;
  variant?: 'primary' | 'secondary'; //primary - on edit show accept and cancel, secondary - dont show
  iconClassnames?: string;
};

export const EditableTitle: React.FC<TitleWithEditProps> = ({
  title,
  onSave,
  isSaving,
  isEditable,
  notEmptyWarning = t(`conversationTitle.warning.isEmpty`),
  placeholder = t('common.titleWithExpand.placeholder') || '',
  shouldValidate = true,
  dataTestId = 'EditableTitle',
  inputClassName = '',
  className = '',
  variant = 'primary',
  onChange,
  iconClassnames,
}) => {
  const [value, setValue] = useState(title);
  const [editing, setEditing] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { addNotification } = useNotificationSystem();

  useEffect(() => {
    setValue(title);
  }, [title]);

  const startEditing = () => {
    inputRef.current?.focus();
    setEditing(true);
  };

  const cancelChanges = () => {
    setValue(title);
    setEditing(false);
  };

  const save = () => {
    if (shouldValidate) {
      if (value) {
        if (value !== title) {
          setEditing(false);
          onSave?.(value);
        }
      } else {
        shouldValidate &&
          addNotification({
            color: 'failure',
            title: notEmptyWarning,
          });
      }
    } else {
      setEditing(false);
      onSave?.(value);
    }
  };

  const ref = useRef<HTMLDivElement>(null);

  const span = useRef<HTMLSpanElement>(null);
  const placeholderSpan = useRef<HTMLSpanElement>(null);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    const spanWidth = span.current?.offsetWidth || 0;
    const placeholderdth = placeholderSpan.current?.offsetWidth || 110;
    setWidth(spanWidth > 40 ? spanWidth : placeholderdth);
  }, [value]);

  useClickAway(ref, save);

  return (
    <div ref={ref} className={classnames('relative h-16 w-full', className)}>
      <Stack
        direction="row"
        className={conversationPageStyles.editableTitle.wrapper}
        alignItems="center"
      >
        <span
          className={classnames(
            conversationPageStyles.editableTitle.input,
            inputClassName,
            'invisible absolute'
          )}
          ref={placeholderSpan}
        >
          {placeholder + '__'} {/*to prevent input jumping  */}
        </span>
        <span
          className={classnames(
            conversationPageStyles.editableTitle.input,
            inputClassName,
            'invisible absolute'
          )}
          ref={span}
        >
          {value.replaceAll(' ', '-') + '__'} {/*to prevent input jumping  */}
        </span>
        <input
          value={value}
          autoFocus
          data-testid={dataTestId}
          ref={inputRef}
          className={classnames(
            conversationPageStyles.editableTitle.input,
            inputClassName,
            'ease-in-out mt-[1px] !ml-0 !pl-0 leading-normal'
          )}
          style={{ width }}
          onChange={(e) => {
            setValue(e.target.value);
            onChange?.(e.target.value);
            setEditing(e.target.value !== title);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              save();
            }
            if (e.key === 'Escape') {
              cancelChanges();
            }
          }}
          placeholder={placeholder}
          readOnly={!isEditable}
        />

        <Stack direction="row" alignItems="center" className="w-fit pencil">
          {isEditable && (
            <>
              {isSaving ? (
                <IconButton hoverable={false} className="mb-2">
                  <Spinner />
                </IconButton>
              ) : !editing || variant == 'secondary' ? (
                <IconButton
                  hoverable={false}
                  size="sm"
                  className={classnames(
                    'px-0 mx-0 cursor-pointer',
                    iconClassnames
                  )}
                  imgClassName="!m-0"
                >
                  <PencilIcon
                    onClick={startEditing}
                    data-testid="editableTitle_startEdit"
                  />
                </IconButton>
              ) : (
                <Stack className="w-full" direction="row" alignItems="center">
                  <IconButton
                    hoverable={false}
                    size="sm"
                    className={classnames(
                      'px-0 mx-0 cursor-pointer',
                      iconClassnames
                    )}
                  >
                    <XCircleIcon
                      onClick={cancelChanges}
                      data-testid="editableTitle_cancelEdit"
                    />
                  </IconButton>
                  <IconButton
                    hoverable={false}
                    size="sm"
                    className={classnames(
                      'px-0 mx-0 cursor-pointer',
                      iconClassnames
                    )}
                  >
                    <CheckCircleIcon
                      className="text-customColor_6"
                      onClick={save}
                    />
                  </IconButton>
                </Stack>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default EditableTitle;
