import { useEffect, useState } from 'react';
import { Background, LoadingTransitionPage } from '../../components';
import { useStateProvider } from './../StateProvider';

type propsType = {
  children: React.ReactNode;
};

export const SmartSpinnerGlobal = ({ children }: propsType) => {
  const { actions } = useStateProvider();
  const { SetSpinnerParentLoaded, SetSpinnerParentActive } = actions;
  const [shouldRenderGlobalSpinner, setShouldRenderGlobalSpinner] =
    useState(false);

  useEffect(() => {
    SetSpinnerParentLoaded(true);
  }, [SetSpinnerParentLoaded]);

  const { state } = useStateProvider();

  const activeSpinners = state.spinners.spinners.filter((item) => item.value);
  const allSpinnersCount = state.spinners.spinners.length;
  const renderAsGlobalCount = state.spinners.spinners.filter(
    (item) => item.value && item.renderAsGlobal
  );

  const activeSpinnersCount = activeSpinners.length;

  useEffect(() => {
    const value =
      activeSpinnersCount >= 2 ||
      allSpinnersCount == 0 ||
      !!renderAsGlobalCount.length;
    setShouldRenderGlobalSpinner(value);

    SetSpinnerParentActive(value);
  }, [
    activeSpinnersCount,
    allSpinnersCount,
    renderAsGlobalCount.length,
    shouldRenderGlobalSpinner,
    SetSpinnerParentActive,
  ]);

  return (
    <div className="w-screen h-screen">
      {shouldRenderGlobalSpinner && (
        <>
          {renderAsGlobalCount[0]?.background && <Background />}
          <LoadingTransitionPage className="absolute t-0 l-0 w-full h-full" />
        </>
      )}
      <>{children}</>
    </div>
  );
};

export default SmartSpinnerGlobal;
