import { useIsSelectedRange } from '@trueconnect/tp-components';
import { t } from 'i18next';
import classnames from 'classnames';

export const HelpHeader = () => {
  const isSelectedView = useIsSelectedRange(['sm', 'md', 'lg']);

  return (
    <div className={classnames('max-w-[400px]', !isSelectedView && 'mr-10')}>
      <p className="font-bold mb-[10px]">{t('settings.anyQuestions')}</p>
      <p className="mb-2">{t('settings.subtitle.questions')}</p>
      <p>{t('settings.subtitle.difficulties')}</p>
    </div>
  );
};
