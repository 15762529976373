import { useEffect, useState } from 'react';
import { LoadingTransitionPage } from '../../components';
import { useStateProvider } from './../StateProvider';

type propsType = {
  name?: string;
  condition?: boolean;
  background?: boolean;
  renderAsGlobal?: boolean;
  children?: React.ReactNode;
};

export const SmartSpinnerLocal = ({
  name,
  condition,
  background = false,
  renderAsGlobal = false,
  children,
}: propsType) => {
  const [shouldRenderLocalSpinner, setShouldRenderLocalSpinner] =
    useState(false);

  const { actions } = useStateProvider();
  const { UnSubscribeToSpinner, SubscribeToSpinner } = actions;

  useEffect(() => {
    name &&
      SubscribeToSpinner({
        name,
        value: !!condition,
        background,
        renderAsGlobal,
      });
    return () => {
      name && UnSubscribeToSpinner(name);
    };
  }, [
    SubscribeToSpinner,
    UnSubscribeToSpinner,
    background,
    condition,
    name,
    renderAsGlobal,
  ]);

  useEffect(() => {
    return () => {
      name && UnSubscribeToSpinner(name);
    };
  }, [UnSubscribeToSpinner, name]);

  const { state } = useStateProvider();

  const { spinnerParentLoaded, globalSpinnerActive } = state.spinners;

  const activeSpinners = state.spinners.spinners.filter(
    (item) => item.value && !item.renderAsGlobal
  );
  const allSpinnersCount = state.spinners.spinners.length;

  const activeSpinnersCount = activeSpinners.length;

  useEffect(() => {
    setShouldRenderLocalSpinner(
      activeSpinnersCount < 2 &&
        activeSpinnersCount != 0 &&
        spinnerParentLoaded &&
        allSpinnersCount > 2 &&
        !globalSpinnerActive
    );
  }, [
    activeSpinnersCount,
    allSpinnersCount,
    globalSpinnerActive,
    spinnerParentLoaded,
  ]);

  if (condition)
    return shouldRenderLocalSpinner ? <LoadingTransitionPage /> : <></>;
  return <>{children}</>;
};

export default SmartSpinnerLocal;
