import { defaultScrollbar } from './../../../styles';
import {
  Accordion,
  VirtualizedGridCellRendererType,
} from './../../../components';
import { invoiceType } from './typing';
import classnames from 'classnames';
import { SmartSpinnerLocal } from './../../../providers';
import { t } from 'i18next';
import { Stack } from './../../../controls';

export const InvoiceTable = ({
  columns,
  data,
  isLoading,
  isMobileView,
  isTabletView,
}: {
  columns: VirtualizedGridCellRendererType<invoiceType>[];
  data: invoiceType[];
  isLoading?: boolean;
  isMobileView?: boolean;
  isTabletView?: boolean;
}) => {
  const totalWidthRatio = columns.reduce(
    (sum, col) => sum + (col.widthRatio || 1),
    0
  );

  if (isLoading) {
    return (
      <Stack direction="row" alignItems="center" className="w-full py-3">
        <SmartSpinnerLocal condition={isLoading} name="InvoiceTable" />
      </Stack>
    );
  }

  if (!isLoading && !data.length) {
    return (
      <Accordion
        classNames="mt-4 leading-[16px] [&>div]:!py-4"
        title={t('policyDetails.invoiceTable.title')}
      >
        <p
          className="text-[#6B7280] font-medium leading-6"
          data-testid="InvoiceTable"
        >
          {t('policyDetails.invoiceTable.noInvoice')}
        </p>
      </Accordion>
    );
  }

  if (isMobileView) {
    return (
      <Accordion
        classNames="mt-4 leading-[16px] [&>div]:!py-2 [&>div]:!pr-1"
        title={t('policyDetails.invoiceTable.title')}
      >
        <div
          data-testid="InvoiceTable"
          className={classnames(
            'w-full max-h-[400px] overflow-y-auto',
            defaultScrollbar
          )}
        >
          {data.map((row, idx) => (
            <Stack
              className={classnames(
                'w-full',
                idx !== data.length - 1 && 'border-b'
              )}
              key={row.id}
            >
              {columns.map((col) => (
                <div className="w-full" key={`${col.dataPropName}`}>
                  {col.render(row)}
                </div>
              ))}
            </Stack>
          ))}
        </div>
      </Accordion>
    );
  }

  return (
    <Accordion
      classNames={classnames(
        'mt-4 leading-[16px] [&>div]:!py-4',
        isTabletView && '[&>div]:!px-2'
      )}
      title={t('policyDetails.invoiceTable.title')}
    >
      {!isTabletView && (
        <table className={classnames('border-collapse w-full table-fixed')}>
          <thead
            className={classnames(
              'text-gray-600 bg-customColor_2 h-[46px] font-inter text-sm font-semibold sticky -top-[2px]',
              'bg-white !text-black text-sm font-semibold border-b'
            )}
          >
            <tr>
              {columns.map((col) => {
                const widthRatio = (col.widthRatio || 1) / totalWidthRatio;

                return (
                  <th
                    key={col.dataPropName}
                    style={{
                      width: `${widthRatio * 100}%`,
                      minWidth: `${widthRatio * 100}%`,
                    }}
                    className={classnames(
                      '!min-w-[150px] leading-normal select-none whitespace-nowrap text-start',
                      col.className
                    )}
                  >
                    {col.title}
                  </th>
                );
              })}
            </tr>
          </thead>
        </table>
      )}
      <div
        id="scrollableDiv"
        data-testid="InvoiceTable"
        className={classnames(
          'overflow-auto pb-[1px]',
          isTabletView ? 'max-h-[400px]' : 'max-h-[280px]',
          defaultScrollbar,
          (document.getElementById('scrollableDiv')?.scrollHeight || 0) >
            (document.getElementById('scrollableDiv')?.clientHeight || 0) &&
            '-mr-[14px]'
        )}
      >
        <SmartSpinnerLocal condition={isLoading} name="InvoiceTable">
          <table className={classnames('w-full table-fixed')}>
            <thead className="h-0">
              <tr>
                {columns.map((col) => {
                  const widthRatio = (col.widthRatio || 1) / totalWidthRatio;

                  return (
                    <th
                      key={col.dataPropName}
                      style={{
                        width: `${widthRatio * 100}%`,
                        minWidth: `${widthRatio * 100}%`,
                      }}
                      className={classnames(
                        '!min-w-[150px] leading-normal select-none whitespace-nowrap text-start',
                        col.className
                      )}
                    ></th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <>
                  <tr key={row.id} className={classnames('border-b h-[60px]')}>
                    {columns.map((col) => (
                      <td key={`${col.dataPropName}`}>{col.render(row)}</td>
                    ))}
                  </tr>
                </>
              ))}
              <tr />
            </tbody>
          </table>
        </SmartSpinnerLocal>
      </div>
    </Accordion>
  );
};

export default InvoiceTable;
