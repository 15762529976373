/**
 * Feature flags for tp-components.
 */
export type FeatureFlagsType = {
  enableDarkTheme: boolean;
  enableSignalRHubLogging: boolean;
  showChangeLanguage: (environment: string) => boolean;
  showTasks: (environment: string) => boolean;
};

/**
 * Default feature flags for tp-components.
 */
const defaultFeatureFlags: FeatureFlagsType = {
  enableDarkTheme: true,
  enableSignalRHubLogging: false,
  showChangeLanguage: (environment) => environment !== 'production',
  showTasks: (environment) => environment !== 'production',
};

/**
 * Current feature flags for tp-components.
 */
export const FeatureFlags = defaultFeatureFlags;

export const overrideFeatureFlags = (
  featureFlags: Partial<FeatureFlagsType>
) => {
  Object.assign(FeatureFlags, featureFlags);
};

export default FeatureFlags;
