import { ConversationParticipantType, useUserProfile } from '../../providers';

export const useOneParticipant = (
  participants: ConversationParticipantType[] | undefined,
  selectCustomer?: boolean
) => {
  const profileData = useUserProfile();
  return participants?.find(
    (item) =>
      item.userId !== profileData.userId &&
      (selectCustomer ? item.contactId !== null : item.contactId === null)
  );
};
