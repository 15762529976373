import {
  Button,
  ContactPermissionDto,
  ContactStatus,
  ConversationParticipantType,
  Stack,
  VirtualizedList,
} from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { t } from 'i18next';
import {
  AddConversationParticipantDto,
  SetContactPermissionsBatchItemDto,
  useApiInvalidateQueries,
  useApiMutation,
} from 'src/api';
import { useContactColumns } from 'src/pages/App/Conversations/Tabs/useContactColumns';
import classnames from 'classnames';
import { ContactData } from '../ListItems/ContactData';

export type selectedUserType = AddConversationParticipantDto & {
  displayName: string;
  permissions?: ContactPermissionDto;
  logo?: string;
  email?: string;
  customerId?: number;
  userId?: string;
  status?: ContactStatus;
};

export const PermissionDialog = ({
  isVisible,
  onClose,
  onOk,
  usersToAllow,
  permission,
}: {
  isVisible: boolean;
  onClose: () => void;
  onOk: () => void;
  usersToAllow: selectedUserType[];
  permission: keyof ContactPermissionDto;
}) => {
  const invalidateQueries = useApiInvalidateQueries();

  const { mutate, isLoading: isAddingPermissions } = useApiMutation(
    'change_Contacts_Perissions_In_Batch'
  );

  const handleOnClick = () => {
    mutate(
      [
        usersToAllow.map((item) => {
          return {
            contactId: item.contactId,
            permissions: { ...item.permissions, [permission]: true },
          } as SetContactPermissionsBatchItemDto;
        }),
      ],
      {
        onSuccess: () => {
          onOk();
          onClose();
          invalidateQueries('searchContacts');
        },
      }
    );
  };

  const preColumns = useContactColumns({
    setMemberIds: () => ({}),
    columnsToRemove: ['status', 'name'],
  });

  const columns = [
    {
      dataPropName: 'name',
      render: (item: ConversationParticipantType) => (
        <div className="w-full [&>div]:ml-0 [&>div>div>div]:pl-0">
          <ContactData
            {...{
              name: item.displayName,
              logo: '',
              ...item,
            }}
          />
        </div>
      ),
      widthRatio: 5,
    },
    ...preColumns,
  ];

  return (
    <Modal
      show={isVisible}
      onClose={onClose}
      size="2xl"
      title={t(`permissions.${permission}.header`)}
      className="[&>div>div>div:nth-child(2)]:py-2 !z-50"
      footer={
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          className="w-full gap-2"
        >
          <Button className="w-fit" onClick={onClose} variant="secondary">
            {t(`permissions.${permission}.cancelBtn`)}
          </Button>
          <Button
            className="w-fit"
            variant="primary"
            onClick={handleOnClick}
            isLoading={isAddingPermissions}
            dataTestid={`confirmBatch${permission}`}
          >
            {t(`permissions.${permission}.acceptBtn`)}
          </Button>
        </Stack>
      }
    >
      <p className="pb-3 font-normal text-sm">
        {t(`permissions.${permission}.body`)}
      </p>
      <Stack
        direction="col"
        className={classnames(
          'h-full w-full min-h-[150px] max-h-[220px] relative'
        )}
      >
        <div className={classnames('h-full w-full absolute')}>
          <VirtualizedList
            columns={columns}
            headerHeight={0}
            rowHeight={60}
            infinityQuery={{
              data: usersToAllow,
            }}
            classNames={{
              row: '!border-b',
              lastRow: 'border-none',
            }}
          />
        </div>
      </Stack>
    </Modal>
  );
};

export default PermissionDialog;
