import React, { PropsWithChildren } from 'react';
import { useIsSelectedRange } from './../../utils';
import classnames from 'classnames';

type HeaderProps = PropsWithChildren;

const Header: React.FC<HeaderProps> = ({ children }) => <>{children}</>;

type SidebarProps = PropsWithChildren;

const Sidebar: React.FC<SidebarProps> = ({ children }) => <>{children}</>;

type ContentProps = PropsWithChildren;

const Content: React.FC<ContentProps> = ({ children }) => <>{children}</>;

type BottomBarProps = PropsWithChildren;

const BottomBar: React.FC<BottomBarProps> = ({ children }) => <>{children}</>;

export type AppPageRootProps = {
  children: React.ReactNode;
};

export const AppPageRoot: React.FC<AppPageRootProps> = ({ children }) => {
  /// retrieve header, sidebar and content
  const headers: React.ReactNode[] = [];
  const sidebars: React.ReactNode[] = [];
  const content: React.ReactNode[] = [];
  const bottomBars: React.ReactNode[] = [];

  React.Children.forEach(children, (child) => {
    const isElement = React.isValidElement(child);
    if (isElement && child.type === Header) {
      headers.push(child);
    } else if (isElement && child.type === Sidebar) {
      sidebars.push(child);
    } else if (isElement && child.type === BottomBar) {
      bottomBars.push(child);
    } else {
      content.push(child);
    }
  });

  const isMobile = useIsSelectedRange(['sm', 'md', 'lg']);

  return (
    <div
      className={classnames('flex flex-col w-screen relative overflow-hidden')}
      style={{
        height: window.innerHeight,
        transition: 'height 0.3s ease',
      }}
    >
      {headers}
      <div className="flex flex-row flex-1 h-full w-full bg-customColor_17">
        {!isMobile && sidebars}
        <div className="flex-1 w-full">{content}</div>
      </div>
      {isMobile && <div className="w-full mt-auto">{bottomBars}</div>}
    </div>
  );
};

AppPageRoot.displayName = 'AppPage';
Header.displayName = 'AppPage.Header';
Content.displayName = 'AppPage.Content';
Sidebar.displayName = 'AppPage.Sidebar';
BottomBar.displayName = 'AppPage.BottomBar';

export const AppPage = Object.assign(AppPageRoot, {
  Header,
  Sidebar,
  Content,
  BottomBar,
});
