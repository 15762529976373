import {
  ConversationParticipantType,
  Stack,
  useIsSelectedRange,
} from '@trueconnect/tp-components';
import { StatusBadge } from 'src/components/StatusBadge';
import Actions from './Actions';
import classnames from 'classnames';
import { ConversationAccessWarning } from 'src/components/WarningIcon';

export const Status = ({
  isSelectedView,
  readOnly,
  setMemberIds,
  item,
}: {
  item: ConversationParticipantType;
  isSelectedView: boolean;
  readOnly: boolean;
  setMemberIds: (ids: string[]) => void;
}) => {
  const renderCondition = !isSelectedView && !readOnly;
  const isMobileView = useIsSelectedRange(['sm', 'md']);

  return (
    <Stack
      className={classnames('w-full')}
      direction="row"
      alignItems="center"
      justifyContent={item.contactId && !isMobileView ? 'between' : 'end'}
    >
      <Stack direction="row" alignItems="center" justifyContent="between">
        {item.contactId && renderCondition && (
          <StatusBadge
            email={item.email || ''}
            status={item.status}
            contactId={item.contactId}
            customerId={item.customerId}
            displayName={item.displayName}
          />
        )}
      </Stack>
      {item.accessSuspended && item.contactId && <ConversationAccessWarning />}
      <Actions item={item} setMemberIds={setMemberIds} />
    </Stack>
  );
};

export default Status;
