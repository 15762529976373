import { payloadType } from '../StateProvider/actions';
import { NotificationType } from './Notification';

interface actions {
  [key: string]: (payload?: payloadType) => any;
}

export const stateActionsNotifications: actions = {
  AddNotification: (toAdd: NotificationType) => ({
    type: 'ADD_NOTIFICATION',
    payload: toAdd,
  }),
  RemoveNotification: (id: string) => ({
    type: 'REMOVE_NOTIFICATION',
    payload: id,
  }),
};

export type actionsNotification = {
  AddNotification: (toAdd: NotificationType) => void;
  RemoveNotification: (id: string) => void;
};

export default stateActionsNotifications;
