import { StatusBadge } from 'src/components/StatusBadge';
import {
  CheckBox,
  Stack,
  VirtualizedGridCellRendererType,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { ContactForRenderType } from 'src/pages/App/Customers/DataView/Tabs';
import classnames from 'classnames';
import ContactListItem from 'src/components/ListItems/ContactListItem';
import { DocumentAccessWarning } from 'src/components/WarningIcon';
import { ContactData } from 'src/components/ListItems/ContactData';
import { ContactEmail } from 'src/components/ListItems/ContactEmail';
import { ContactPhoneNumber } from 'src/components/ListItems/ContactPhoneNumber';

export type useGetColumnsType = {
  selectedUsers: string[];
  handleToggleCheckBox: (userId: string) => void;
};

export const useGetColumnsBase = () => {
  const columns: VirtualizedGridCellRendererType<ContactForRenderType>[] = [
    {
      dataPropName: 'name',
      render: ContactData,
      widthRatio: 4,
    },
    {
      dataPropName: 'email',
      render: ContactEmail,
      widthRatio: 3,
    },
    {
      dataPropName: 'phone',
      render: ContactPhoneNumber,
      widthRatio: 2,
    },
    {
      dataPropName: 'status',
      render: (item) => (
        <>
          {item.id && (
            <StatusBadge
              email={item.email || ''}
              status={item.status}
              contactId={item.id}
              customerId={item.customerId}
              displayName={classnames(item.firstName, item.lastName)}
            />
          )}
        </>
      ),
      widthRatio: 3,
    },
  ];

  const isMobileView = useIsSelectedView('sm');

  if (isMobileView) {
    return [
      {
        dataPropName: 'item',
        render: (item) =>
          item.status &&
          ContactListItem({
            ...item,
            displayName: `${item.firstName} ${item.lastName}`,
            status: item.status,
          }),
        widthRatio: 6,
      },
    ] as VirtualizedGridCellRendererType<ContactForRenderType>[];
  }

  return columns;
};

const useGetColumns = ({
  selectedUsers,
  handleToggleCheckBox,
}: useGetColumnsType) => {
  const columns = useGetColumnsBase();
  const isMobileView = useIsSelectedView('sm');

  const CheckboxItem: VirtualizedGridCellRendererType<ContactForRenderType> = {
    dataPropName: 'checkbox',
    render: (item) => {
      return (
        <Stack direction="row" className="w-full pr-2" justifyContent="end">
          {!item.permissions?.allowSharedDocuments && <DocumentAccessWarning />}
          <CheckBox
            dataTestId={`manageDocumentAccessCheckbox_${item.email}`}
            checked={!!item.userId && selectedUsers.includes(item.userId)}
            onChange={() => {
              item.userId && handleToggleCheckBox(item.userId);
            }}
          />
        </Stack>
      );
    },
    widthRatio: isMobileView ? 2 : 1,
  };

  return [...columns, CheckboxItem];
};

export default useGetColumns;
