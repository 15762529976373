import axios from 'axios';
import { BrokerAppLogicClient } from './BrokerAppLogicClient.generated';
import {
  ApiInfiniteQueryPageMapping,
  KeysMatching,
} from '@trueconnect/tp-components';

export * from './BrokerAppLogicClient.generated';

export const serverUrl = process.env.REACT_APP_API_URL || '';

/** Singelton API Client for BrokerAppLogic  */
export const BrokerAppLogic = new BrokerAppLogicClient(serverUrl, axios);

type ApiVoidFunctions = {
  [P in keyof BrokerAppLogicClient]: Awaited<
    ReturnType<BrokerAppLogicClient[P]>
  > extends void
    ? true
    : false;
};

/**
 * All mutation methods on BrokerAppLogic.
 * NOTE: Please extend when new update methods are added.
 */
export type ApiMutationFunctions =
  | KeysMatching<ApiVoidFunctions, true>
  | 'create_Conversation'
  | 'add_Participants_To_Conversation'
  | 'set_Participant_Conversation_Messages_Last_Read_Time'
  | 'get_Document_Customer_Hierarchy_slice'
  | 'get_Contact_Perissions'
  | 'set_Deputy'
  | 'delete_Deputy'
  | 'share_Document_With_Customer'
  | 'createTopic'
  | 'updateTopic'
  | 'createTask'
  | 'updateTask'
  | 'createBranch'
  | 'updateBranch'
  | 'updateBroker'
  | 'createPolicyRewardType'
  | 'updatePolicyRewardType'
  | 'deletePolicyRewardType'
  | 'delete_Policy_Document'
  | 'updateInvoice'
  | 'delete_Invoice_Document'
  | 'getDocumentById'
  | 'changeOrderForPolicyRewardType';

/** All Query methods on BrokerAppLogic */
export type ApiQueryFunctions = Exclude<
  keyof BrokerAppLogicClient,
  ApiMutationFunctions
>;

export const queryPageMapping: ApiInfiniteQueryPageMapping<
  BrokerAppLogicClient,
  ApiQueryFunctions
> = {
  getMessages: (page, conversationId, p, cancelToken) => [
    conversationId,
    page,
    cancelToken,
  ],
  searchContacts: (page, search, customerId, statuses, p, cancelToken) => [
    search,
    customerId,
    statuses,
    page,
    cancelToken,
  ],
  searchCustomers: (page, search, hasInvitedContacts, p, cancelToken) => [
    search,
    hasInvitedContacts,
    page,
    cancelToken,
  ],
  searchConversations: (p, body, cancelToken) => {
    return [{ ...body, page: p }, cancelToken];
  },
  searchPolicies: (p, body, cancelToken) => {
    return [{ ...body, page: p }, cancelToken];
  },
  searchCustomerConversations: (p, customerId, body, cancelToken) => {
    return [customerId, { ...body, page: p }, cancelToken];
  },
  searchTopics: (p, body, cancelToken) => {
    return [{ ...body, page: p }, cancelToken];
  },
  searchTasks: (p, body, cancelToken) => {
    return [{ ...body, page: p }, cancelToken];
  },
};

export default BrokerAppLogic;
