import { UseInfiniteQueryResult } from '@tanstack/react-query';
import {
  FunnelIcon,
  IconButton,
  NotificationImage,
  SearchInput,
  SmartSpinnerLocal,
  Stack,
  TopicType,
  bigScrollbar,
  chatStyles,
  searchInputStyles,
  useIsSelectedView,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { useApiInfiniteQuery } from 'src/api';
import classnames from 'classnames';
import CollapsableTopicItem from './CollapsableTopicItem';
import ExtendedTopicSearch from '../../ExtendedTopicSearch';

export const TopicsTab = ({
  id,
  searchParam,
  setSearchParam,
}: {
  id?: number;
  searchParam?: string;
  setSearchParam: Dispatch<SetStateAction<string>>;
}) => {
  const { addNotification } = useNotificationSystem();
  const { state, actions } = useStateProvider();
  const { showExtendedTopicSearch, extendedTopicSearchParams } =
    state.workManagement;
  const {
    setSelectedTopic,
    setShowExtendedTopicSearch,
    setExtendedTopicSearchParams,
  } = actions;

  useEffect(() => {
    setSelectedTopic({});
  }, [setSelectedTopic]);

  useEffect(() => {
    setShowExtendedTopicSearch(false);
    setExtendedTopicSearchParams({});
    return () => {
      setShowExtendedTopicSearch(false);
      setExtendedTopicSearchParams({});
    };
  }, [setShowExtendedTopicSearch, setExtendedTopicSearchParams]);

  const virtuoso = useRef<VirtuosoHandle>(null);

  const queryTopics = useApiInfiniteQuery(
    'searchTopics',
    [
      {
        search: searchParam,
        page: 0,
        customerIds: id ? [id] : [],
        branchIds: extendedTopicSearchParams.branchIds,
        dateCreatedFrom: extendedTopicSearchParams.dateCreatedFrom,
        dateCreatedTo: extendedTopicSearchParams.dateCreatedTo,
      },
    ],
    {
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('Topics.Sidebar.error'),
        });
      },
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map((subitem) => subitem as TopicType)
        ),
    }
  ) as UseInfiniteQueryResult<TopicType[], Error>;

  const topics = queryTopics.data?.flat(1);
  const [collapsed, setCollapsed] = useState<string[]>([]);
  const isMobileView = useIsSelectedView('sm');
  const isTabletView = useIsSelectedView('md');

  const onLoaded = useCallback(
    (value: string) => {
      const data = queryTopics.data?.flat(1).map((itm) => itm.id) || [];
      const index = data.indexOf(value);
      if (index !== -1) {
        virtuoso.current?.scrollIntoView({ index });
      }
    },
    [queryTopics.data]
  );

  const itemContent = useCallback(
    (index: number, item: TopicType) => {
      if (!item) {
        return <></>;
      }
      const isOpen = !!item.id && collapsed.includes(item.id);

      const onClick = (value: string) => {
        if (collapsed.includes(value)) {
          setCollapsed(collapsed.filter((itm) => itm !== value));
        } else {
          setCollapsed([...collapsed, value]);
          onLoaded(value);
        }
      };

      return <CollapsableTopicItem {...{ item, isOpen, onClick, onLoaded }} />;
    },
    [collapsed, onLoaded]
  );

  if (!(topics || []).length && !queryTopics.isLoading) {
    return <NotificationImage text={t('Topics.nothingFound')} />;
  }

  return (
    <SmartSpinnerLocal
      name="searchTopics"
      condition={queryTopics.isLoading && !queryTopics.data?.flat(1).length}
    >
      <Stack
        className={classnames(
          'w-full h-full pb-5',
          !(isMobileView || isTabletView) && 'px-5'
        )}
      >
        {!(isMobileView && showExtendedTopicSearch) && (
          <>
            <Stack
              className={classnames(
                'w-full',
                !isMobileView && 'pt-[31px] mb-2'
              )}
              direction="row"
              alignItems="center"
            >
              {isMobileView && (
                <SearchInput
                  setSearchParam={setSearchParam}
                  classNames={{
                    wrapper: classnames(
                      searchInputStyles,
                      '!border-none !min-h-16'
                    ),
                  }}
                  dataTestid="customerPageSearch"
                  placeholder={t(`tab.topics.Searchplaceholder`)}
                  isMobileView={true}
                />
              )}
              <Stack
                direction="row"
                alignItems="center"
                className={classnames(
                  'mr-3 ml-auto',
                  isMobileView && 'h-full bg-customColor_7 !mr-0 pr-5'
                )}
              >
                <IconButton
                  className="relative"
                  onClick={() => {
                    setShowExtendedTopicSearch(!showExtendedTopicSearch);
                  }}
                >
                  {isMobileView &&
                    (extendedTopicSearchParams.branchIds?.length ||
                      extendedTopicSearchParams.dateCreatedFrom ||
                      extendedTopicSearchParams.dateCreatedTo) && (
                      <div className="absolute top-0 left-4 w-3 h-3 bg-blue-500 rounded-full" />
                    )}
                  <FunnelIcon
                    data-testid={
                      showExtendedTopicSearch
                        ? 'closeExtendedConversationSearch'
                        : 'startExtendedConversationSearch'
                    }
                  />
                </IconButton>
                {!isMobileView && t('customer.extendedSearch.filter')}
              </Stack>
            </Stack>
            <SmartSpinnerLocal
              name="searchTopics"
              condition={queryTopics.isLoading}
            >
              <Virtuoso
                ref={virtuoso}
                itemContent={itemContent}
                data={topics}
                className={classnames(
                  chatStyles.message.container,
                  bigScrollbar
                )}
              />
            </SmartSpinnerLocal>
          </>
        )}
        {isMobileView && <ExtendedTopicSearch />}
      </Stack>
    </SmartSpinnerLocal>
  );
};

export default TopicsTab;
