import {
  ContactType,
  Stack,
  VirtualizedGridCellRendererType,
  useIsSelectedRange,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { ContactForRenderType } from './ContactsTab';

import ContactListItem from 'src/components/ListItems/ContactListItem';
import { StatusIcon } from 'src/components/StatusBadge/StatusIcon';
import { ContactData } from 'src/components/ListItems/ContactData';
import { ContactEmail } from 'src/components/ListItems/ContactEmail';
import { ContactPhoneNumber } from 'src/components/ListItems/ContactPhoneNumber';
import { ContactActions } from './columns';

export const useContactsColumns = (
  startConversation: (contact: ContactType) => void,
  customerId?: number
) => {
  const isMobileView = useIsSelectedView('sm');
  const isTabletView = useIsSelectedRange(['md', 'lg']);

  const columns: VirtualizedGridCellRendererType<ContactForRenderType>[] = [
    {
      dataPropName: 'name',
      render: ContactData,
      widthRatio: 4,
    },
    {
      dataPropName: 'email',
      render: ContactEmail,
      widthRatio: 3,
    },
    {
      dataPropName: 'phone',
      render: ContactPhoneNumber,
      widthRatio: 2,
    },
    {
      dataPropName: 'actions',
      render: (item) =>
        ContactActions({
          item,
          startConversation,
          isMobileView,
        }),
      widthRatio: 3,
    },
  ];

  if (isMobileView) {
    return [
      {
        dataPropName: 'item',
        render: (item) =>
          item.status &&
          ContactListItem({
            ...item,
            displayName: `${item.firstName} ${item.lastName}`,
            status: item.status,
            customerId,
          }),
        widthRatio: 8,
      },
      {
        dataPropName: 'status',
        render: (item) => (
          <Stack direction="row" alignItems="center">
            <StatusIcon status={item.status} contactId={item.id} />
          </Stack>
        ),
        widthRatio: 1,
      },
      {
        dataPropName: 'actions',
        render: (item) =>
          ContactActions({
            item,
            startConversation,
            isMobileView,
            hideStatus: isMobileView,
          }),
        widthRatio: 3,
      },
    ] as VirtualizedGridCellRendererType<ContactForRenderType>[];
  }

  if (isTabletView) {
    return columns.filter((item) =>
      ['name', 'phone', 'actions'].includes(item.dataPropName)
    );
  }

  return columns;
};

export default useContactsColumns;
