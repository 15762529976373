import { Accordion as AccordionFlowbite } from 'flowbite-react';
import classnames from 'classnames';

export type AccordionProps = {
  children?: string | React.ReactNode;
  title?: string | React.ReactNode;
  collapseAll?: boolean;
  alwaysOpen?: boolean;
  classNames?: string;
};

export const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  collapseAll = false,
  alwaysOpen = true,
  classNames = '',
}) => {
  return (
    <>
      <AccordionFlowbite
        alwaysOpen={alwaysOpen}
        collapseAll={collapseAll}
        className={classnames('w-full mb-4', classNames)}
      >
        <AccordionFlowbite.Panel>
          <AccordionFlowbite.Title>{title}</AccordionFlowbite.Title>
          <AccordionFlowbite.Content>{children}</AccordionFlowbite.Content>
        </AccordionFlowbite.Panel>
      </AccordionFlowbite>
    </>
  );
};

export default Accordion;
