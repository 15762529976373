import { ArrowPathIcon, IconLabel } from '@trueconnect/tp-components';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'react-use';
import { useApiQuery } from 'src/api';
import classnames from 'classnames';

export const SyncStatus = ({
  showText = true,
  className,
  active,
}: {
  showText?: boolean;
  className?: string;
  active?: boolean;
}) => {
  const { t } = useTranslation();

  const { data } = useApiQuery('getLastSyncInfo', [], {
    refetchInterval: 30000,
  });

  const [status, setStatus] = useState(t('dataSync.loading'));
  const updateStatus = () => {
    const lastTime = data?.timeStampUtc;
    setStatus(!lastTime ? t('dataSync.loading') : dayjs(lastTime).fromNow());
  };
  useEffect(updateStatus, [data, t]);
  useInterval(updateStatus, 5000);

  return (
    <>
      {active && (
        <IconLabel
          className={classnames(className, 'font-medium')}
          hoverable={false}
          title={showText ? `${t('dataSync.lastSync')}: ${status}` : ''}
          icon={<ArrowPathIcon />}
          justifyContent="end"
        />
      )}
    </>
  );
};

export default SyncStatus;
