import { Button, Stack, useStateProvider } from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';

export const ManageComponent = ({
  onAccept,
  onCancel,
}: {
  onAccept: () => void;
  onCancel?: () => void;
}) => {
  const { actions } = useStateProvider();
  const {
    setContactToCreateConversation,
    setSelectedBranches,
    setCreateConversationModalType,
  } = actions;
  const { t } = useTranslation();

  const onClose = () => {
    setContactToCreateConversation({});
    setCreateConversationModalType('primary');
    setSelectedBranches([]);
    onCancel?.();
  };
  return (
    <>
      <Stack
        className="w-full mt-auto gap-3 px-2"
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          className="w-full"
          dataTestid="cancelCreateConversation"
          onClick={onClose}
        >
          {t('common.back')}
        </Button>
        <Button
          className="w-full"
          dataTestid="acceptCreateConversation"
          variant="primary"
          onClick={() => {
            onAccept();
          }}
        >
          {t('common.accept')}
        </Button>
      </Stack>
    </>
  );
};

export default ManageComponent;
