import { useTranslation } from 'react-i18next';
import { conversationStatusModalProps } from './ConversationStatusButton';
import {
  Button,
  Stack,
  modalStyles,
  useNotificationSystem,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { useApiInvalidateQueries, useApiMutation } from 'src/api';
import { useParams } from 'react-router-dom';
import Modal from '@trueconnect/tp-components/src/controls/Modal';

export const CloseConversationModal = ({
  close,
  visible,
}: conversationStatusModalProps) => {
  const { t } = useTranslation();
  const invalidateQueries = useApiInvalidateQueries();
  const { addNotification } = useNotificationSystem();
  const { id } = useParams();

  const { mutate: closeConversation } = useApiMutation('close_Conversation');

  const handleClick = () => {
    id &&
      closeConversation([id], {
        onSuccess: () => {
          addNotification({
            color: 'success',
            title: t('conversation.open.statusPanel.notification.success'),
          });
          invalidateQueries('searchConversations');
          invalidateQueries('getConversationById');
          close();
        },
        onError: () => {
          addNotification({
            color: 'failure',
            title: t('conversation.open.statusPanel.notification.error'),
          });
        },
      });
  };

  return (
    <Modal
      size="sm"
      onClose={close}
      show={!!visible}
      className={classnames(modalStyles, 'text-center')}
      showFooter={false}
      showTitle={false}
    >
      <h3 className="mb-5 text-2xl font-semibold">
        {t('conversation.open.statusPanel.title')}
      </h3>
      <span className="font-light">
        {t('conversation.open.statusPanel.subTitle')}
      </span>
      <Stack direction="col" alignItems="center" className="w-full mt-5">
        <Button
          className="w-full mb-3"
          variant="primary"
          onClick={handleClick}
          dataTestid="accept"
        >
          {t('conversation.open.statusPanel.buttonOnAccept')}
        </Button>
        <Button className="w-full" onClick={close} dataTestid="cancel">
          {t('conversation.open.statusPanel.buttonOnClose')}
        </Button>
      </Stack>
    </Modal>
  );
};

export default CloseConversationModal;
