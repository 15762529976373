import { overrideFeatureFlags, type FeatureFlags } from './FeatureFlags';
import './utils/dayjs'; // initializes dayjs locale and plugins

export interface InitializeOptions {
  /** Overrides the feature flags used in tp-components */
  featureFlags: Partial<typeof FeatureFlags>;
}

export function initialize({ featureFlags }: InitializeOptions) {
  if (featureFlags) {
    overrideFeatureFlags(featureFlags);
  }
}
