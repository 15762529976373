import { ComponentProps } from 'react';
import { HomeIcon, breadCrumbLink } from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'flowbite-react';
import { IconButton } from '../components';
import classnames from 'classnames';

export type PathHeaderProps = {
  params?: {
    name: string;
    path: string;
    icon?: React.FC<ComponentProps<'svg'>>;
  }[];
  showHomeIcon?: boolean;
  classNames?: string;
};

type StyledLinkProps = {
  text: string;
  navigateTo: () => void;
};

const StyledLink: React.FC<StyledLinkProps> = ({ text, navigateTo }) => {
  return (
    <div onClick={navigateTo} className={breadCrumbLink}>
      <p className="whitespace-no-wrap cursor-pointer w-max">{text}</p>
    </div>
  );
};

export const HomeButton = () => {
  return (
    <IconButton
      customSize="w-7 h-7"
      imgClassName="mx-0 my-auto"
      hoverable={false}
    >
      <HomeIcon />
    </IconButton>
  );
};

export const PathHeader: React.FC<PathHeaderProps> = ({
  params = [],
  showHomeIcon = true,
  classNames,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={classnames('container mb-5 w-auto', classNames)}>
      <Breadcrumb className="overflow-auto pb-2">
        {showHomeIcon && (
          <Breadcrumb.Item icon={HomeButton}>
            <StyledLink
              text={t('sidebar.dashboard')}
              navigateTo={() => navigate('/')}
            />
          </Breadcrumb.Item>
        )}
        {params.map((item) => (
          <Breadcrumb.Item key={item.path} icon={item.icon}>
            <StyledLink
              text={item.name}
              navigateTo={() => {
                !!item.path && navigate(item.path);
              }}
            />
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};
