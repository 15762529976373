import { documentsTabProps } from './UploadTabs';
import {
  FileInfo,
  FilesTable,
  IDocumentType,
} from './../../../../controls/Documents';
import { useStateProvider } from './../../../../providers';
import { CheckBox } from '../../../../components';
import {
  setUnsentMessagesAttachments,
  useIsSelectedView,
} from './../../../../utils';
import { toggleSelectedFiles } from './../../../../utils/toggleSelectedFiles';

export const SelectExistingTab: React.FC<documentsTabProps> = (props) => {
  const { isDragActive, queryDocuments } = props.FilesTableProps;
  const { setSelectedFiles } = useStateProvider().actions;
  const { selectedFiles } = useStateProvider().state.uploads;
  const selected = selectedFiles.map((file) => file.id);
  const { conversationId } =
    useStateProvider().state.conversation.conversationData;

  const finalColumns = [
    FileInfo,
    {
      dataPropName: 'checkBox',
      render: (item: IDocumentType) => (
        <CheckBox
          checked={selected.includes(item.id)}
          onChange={() => {
            selectFile(item);
          }}
        />
      ),
      widthRatio: 1,
    },
  ];

  const selectFile = (item: IDocumentType) => {
    const data = toggleSelectedFiles(selectedFiles, {
      id: item.id,
      title: item.fileName,
    });
    setSelectedFiles(data);
    setUnsentMessagesAttachments(conversationId, data);
  };

  const isMobileView = useIsSelectedView('sm');

  return (
    <FilesTable
      {...{
        isDragActive,
        columns: finalColumns,
        queryDocuments,
        openFile: selectFile,
        isMobileView,
      }}
    />
  );
};

export default SelectExistingTab;
