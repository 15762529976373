import {
  Button,
  EditableTitle,
  IconButton,
  SmartSpinnerLocal,
  Stack,
  TextArea,
  XMarkIcon,
  mobileModalStyles,
  useIsSelectedRange,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import {
  CreateTaskDto,
  useApiInvalidateQueries,
  useApiMutation,
} from 'src/api';
import classnames from 'classnames';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import CustomerSearch, { customerType } from './CustomerSearch';
import { DateObject } from 'react-multi-date-picker';
import Deadline from './Deadline';
import dayjs from 'dayjs';
import BrokerSearch, { brokerType } from './ContactSearch';
import TaskType from './TaskType';

export const rowStyle =
  '[&>div>button]:!w-full [&>div]:!w-full !w-full !relative [&>div:nth-child(2)]:!w-full [&>div:nth-child(3)]:!w-full';

export const TaskForm = () => {
  const { state, actions } = useStateProvider();
  const { selectedTask, showTaskForm } = state.workManagement;
  const { setShowTaskForm, setSelectedTask, setOpenTask } = actions;
  const type = selectedTask?.id ? 'Update' : 'Create';

  const [selectedBrokers, setSelectedBrokers] = useState<brokerType[]>(
    selectedTask?.assignees || []
  );
  const [selectedCustomer, setSelectedCustomer] = useState<customerType>({
    name: selectedTask?.customerName,
    id: selectedTask?.customerId,
  });

  const [stateId, setStateId] = useState<number>(1);

  const formatDate = (date: Date) => {
    return dayjs(date).startOf('day').toDate();
  };

  const [deadline, setDeadline] = useState<DateObject | undefined>(
    selectedTask?.dueDateUtc &&
      new DateObject(formatDate(selectedTask.dueDateUtc))
  );
  const [title, setTitle] = useState(selectedTask?.title || '');
  const [description, setDescription] = useState<string>(
    selectedTask?.description || ''
  );

  const selectBrokers = (item: brokerType) => {
    if (item.userId) {
      if (selectedBrokers.map((itm) => itm.userId).includes(item.userId)) {
        setSelectedBrokers(
          selectedBrokers.filter((itm) => itm.userId !== item.userId)
        );
      } else {
        setSelectedBrokers([...selectedBrokers, item]);
      }
    }
  };

  const cancel = useCallback(() => {
    setShowTaskForm(false);
    setSelectedTask({});
    setOpenTask('');
    setStateId(0);
  }, [setOpenTask, setSelectedTask, setShowTaskForm]);

  const { addNotification } = useNotificationSystem();
  const invalidateQueries = useApiInvalidateQueries();
  const close = useCallback(async () => {
    await invalidateQueries('searchTasks');
    cancel();
  }, [cancel, invalidateQueries]);

  useEffect(() => {
    if (selectedTask?.stateId) {
      setStateId(selectedTask.stateId);
    }
  }, [selectedTask]);

  const { mutate: createTask, isLoading: isCreatingTask } =
    useApiMutation('createTask');
  const { mutate: updateTask, isLoading: isUpdatingTask } =
    useApiMutation('updateTask');

  const onSave = () => {
    const params = {
      title,
      assigneesUserIds: selectedBrokers.map((item) => item.userId),
      customerId: selectedCustomer.id,
      customerName: selectedCustomer.name,
      dueDateUtc: deadline && formatDate(deadline.toDate()),
      description,
      stateId,
    } as CreateTaskDto;
    type == 'Create'
      ? createTask([params], {
          onSuccess: close,
          onError: () => {
            addNotification({
              color: 'failure',
              title: t('Tasks.modals.Create.error.title'),
              subTitle: t('Tasks.modals.Create.error.subtitle'),
            });
          },
        })
      : updateTask([{ ...params, id: selectedTask?.id }], {
          onSuccess: close,
          onError: () => {
            addNotification({
              color: 'failure',
              title: t('Tasks.modals.Update.error.title'),
              subTitle: t('Tasks.modals.Update.error.subtitle'),
            });
          },
        });
  };

  const isMobileView = useIsSelectedRange(['sm', 'md']);

  const isDataValid = !!title && !!selectedCustomer.id && !!deadline;

  return (
    <>
      <SmartSpinnerLocal
        name="CreateConversationPanel"
        condition={isCreatingTask || isUpdatingTask}
        renderAsGlobal={true}
      />
      <Modal
        show={showTaskForm}
        className={classnames(
          mobileModalStyles(isMobileView, true),
          '[&>div]:!ml-auto [&>div]:p-0 [&>div]:h-full',
          '[&>div>div>div:nth-child(1)]:py-0 [&>div>div]:!rounded-none',
          '[&>div>div>div:nth-child(1)]:text-black',
          '[&>div>div>div:nth-child(2)]:!h-auto'
        )}
        showTitle={false}
        size="xl"
        onClose={cancel}
        footer={
          <Stack direction="row" className="gap-4 w-full">
            <Button className="!w-full" variant="secondary" onClick={cancel}>
              {t('common.cancel')}
            </Button>
            <Button
              className="!w-full"
              variant="primary"
              disabled={!isDataValid}
              onClick={onSave}
            >
              {t('common.accept')}
            </Button>
          </Stack>
        }
      >
        <Stack direction="col" className="gap-3 h-full">
          <Stack direction="row" alignItems="center" className="w-full">
            <EditableTitle
              {...{
                title: title,
                onChange: (value) => {
                  setTitle(value);
                },
                onSave: (value) => {
                  setTitle(value);
                },
                isEditable: true,
                placeholder: t('common.titleWithExpand.placeholder'),
                notEmptyWarning: t('Tasks.taskTitle.warning.isEmpty'),
                shouldValidate: false,
              }}
            />
            <IconButton onClick={cancel}>
              <XMarkIcon />
            </IconButton>
          </Stack>
          <Stack className={rowStyle}>
            <BrokerSearch
              {...{
                selectedBrokers,
                selectBrokers,
              }}
            />
          </Stack>
          <Stack className={rowStyle}>
            <CustomerSearch
              {...{
                selectedCustomer,
                setSelectedCustomer,
              }}
            />
          </Stack>
          <Deadline
            {...{
              setStartDate: setDeadline,
              startDate: deadline,
            }}
          />
          {selectedTask?.id && <TaskType {...{ stateId, setStateId }} />}
          <Stack className="h-[200px] w-full">
            <TextArea
              {...{
                value: description,
                onChange: (e) => {
                  setDescription(e.target.value);
                },
              }}
            />
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

export default TaskForm;
