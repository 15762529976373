import {
  Button,
  CommonStyles,
  ConversationParticipantType,
  SearchInput,
  Stack,
  fullScreenModal,
  mobileModalStyles,
  relativeFull,
  useIsSelectedView,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiInvalidateQueries, useApiMutation } from 'src/api';
import { useGetSelectedContacts } from './useSelectedUsers';
import AddMemberTabs from './tabs/AddMemberTabs';
import classnames from 'classnames';
import SelectedUserList from './SelectedUserList';
import PermissionDialog, {
  selectedUserType,
} from 'src/components/permissions/PermissionDialog';

export const AddConversationMembersModal = ({
  customerId,
  participants,
  isVisible,
  onClose,
}: {
  customerId: number;
  participants?: ConversationParticipantType[];
  isVisible: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const invalidateQueries = useApiInvalidateQueries();

  const [activeTab, setActiveTab] = useState<number>(0);
  const [showBatchAllowConversation, setShowBatchAllowConversation] =
    useState(false);

  const [searchParam, setSearchParam] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<selectedUserType[]>([]);

  const { addNotification } = useNotificationSystem();

  const { conversationId } =
    useStateProvider().state.conversation.conversationData;

  const { mutate, isLoading } = useApiMutation(
    'add_Participants_To_Conversation'
  );

  const selectedContacts = useGetSelectedContacts(selectedUsers);

  const addParticipants = () => {
    mutate([conversationId, selectedUsers], {
      onSuccess: () => {
        invalidateQueries('getConversationById');
        invalidateQueries('getCustomerContactByUserId');
        invalidateQueries('searchConversations');
        setSelectedUsers([]);
        onClose();
        setShowBatchAllowConversation(false);
        addNotification({
          color: 'success',
          title: t(
            'conversation.manageMembers.modals.add.notification.success'
          ),
        });
      },
      onError: (error) => {
        addNotification({
          color: 'success',
          title: (error as Error)?.message,
        });
      },
    });
  };

  const handleAddParticipants = () => {
    if (
      selectedContacts.filter((item) => !item.permissions?.allowConversations)
        .length
    ) {
      setShowBatchAllowConversation(true);
    } else {
      addParticipants();
    }
  };

  const getParticipantIndex = (newItem: selectedUserType) => {
    return selectedUsers.findIndex(
      (item) =>
        (newItem.contactId && item.contactId === newItem.contactId) ||
        (newItem.brokerUserId && item.brokerUserId === newItem.brokerUserId)
    );
  };

  const manageSelectedParticipants = (newItem: selectedUserType) => {
    const existingIndex = getParticipantIndex(newItem);

    if (existingIndex === -1) {
      setSelectedUsers([...selectedUsers, newItem]);
    } else {
      setSelectedUsers(
        selectedUsers.filter((_, index) => index !== existingIndex)
      );
    }
  };

  const allowFullHistory = (item: selectedUserType, value: boolean) => {
    const index = getParticipantIndex(item);
    if (index === -1) {
      const newItem = { ...item, canReadPrevMessages: value };
      manageSelectedParticipants(newItem);
    } else {
      setSelectedUsers(() => {
        const newSelectedUsers = [...selectedUsers];
        newSelectedUsers[index] = {
          ...newSelectedUsers[index],
          canReadPrevMessages: value,
        };
        return newSelectedUsers;
      });
    }
  };

  const isMobileView = useIsSelectedView('sm');

  return (
    <>
      <Modal
        show={isVisible}
        onClose={onClose}
        size="7xl"
        className={classnames(
          isMobileView && '[&>div>div]:!rounded-t-2xl',
          mobileModalStyles(isMobileView),
          isMobileView && fullScreenModal()
        )}
        title={t('conversation.manageMembers.modals.add.title')}
        footer={
          <div className="flex flex-row justify-end w-full">
            <Button
              className="w-fit"
              color="gray"
              onClick={handleAddParticipants}
              disabled={selectedUsers.length === 0}
              isLoading={isLoading}
              dataTestid="addSelectedMembers"
            >
              {t('conversation.manageMembers.modals.add.buttonOnAccept')}
            </Button>
          </div>
        }
      >
        <Stack className={CommonStyles.full}>
          <div className={classnames('w-full', isMobileView && 'px-2 pt-2')}>
            <SearchInput
              dataTestid="searchMemberToAdd"
              classNames={{
                wrapper: classnames(
                  isMobileView ? 'w-full mb-2' : 'w-60',
                  'h-12'
                ),
              }}
              inputName="addMembersToAdd"
              setSearchParam={setSearchParam}
              placeholder={t(
                activeTab === 0
                  ? 'conversation.manageMembers.modals.add.searchCustomersInputPlaceholder'
                  : 'conversation.manageMembers.modals.add.searchBrokersInputPlaceholder'
              )}
            />
          </div>
          <SelectedUserList
            {...{ selectedUsers, manageSelectedParticipants }}
          />
          <div
            className={classnames(relativeFull, !isMobileView && '!h-[400px]')}
          >
            <AddMemberTabs
              {...{
                customerId,
                searchParam,
                activeTab,
                participants,
                selectedUsers,
                manageSelectedParticipants,
                allowFullHistory,
                setActiveTab,
              }}
            />
          </div>
        </Stack>
      </Modal>
      <PermissionDialog
        {...{
          isVisible: showBatchAllowConversation,
          permission: 'allowConversations',
          onClose: () => {
            setShowBatchAllowConversation(false);
          },
          onOk: () => {
            addParticipants();
          },
          isLoading,
          usersToAllow: selectedContacts.filter(
            (item) => !item.permissions?.allowConversations
          ),
        }}
      />
    </>
  );
};

export default AddConversationMembersModal;
