import {
  IconButton,
  RectangleStackIcon,
  Stack,
  branchType,
  hiddenScrollbar,
  nameType,
  useStateProvider,
} from '@trueconnect/tp-components';
import BranchesList from './BranchesList';
import { TopicType } from 'src/providers/WorkManagement/typing';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

export const ConversationTopicHeader = ({
  data,
  canEditBranches = false,
  classNames,
  nameTp = 'name',
  branchOnclick,
}: {
  data: TopicType;
  canEditBranches?: boolean;
  classNames?: string;
  nameTp?: nameType | undefined;
  branchOnclick?: (item: branchType) => void;
}) => {
  const { state } = useStateProvider();
  const { branches } = state.conversation.conversationData;
  const navigate = useNavigate();

  if (!data.id && !branches.length) return <></>;

  return (
    <Stack className="w-full !min-h-[56px] relative">
      <Stack
        className={classnames(
          'w-full h-full absolute border-box pt-3 pr-5 gap-4 border-b overflow-x-auto',
          hiddenScrollbar,
          classNames
        )}
        direction="row"
        alignItems="center"
      >
        {data.id && (
          <Stack
            direction="row"
            className="w-auto !max-w-[80%] h-fit"
            alignItems="center"
          >
            <Stack
              className={classnames(
                'cursor-pointer text-nowrap overflow-auto',
                hiddenScrollbar
              )}
              direction="row"
              alignItems="center"
              onClick={() => {
                if (canEditBranches) {
                  navigate(`/topics/${data.id}`);
                }
              }}
            >
              <IconButton
                hoverable={false}
                imgClassName="!m-0"
                dataTestId={`test_${data.displayName}`}
                className="!m-0 !px-0 !w-6"
              >
                <RectangleStackIcon fill="#6B7280" />
              </IconButton>
              <p className="text-sm font-semibold truncate leading-normal">
                {data.displayName || ''}
              </p>
            </Stack>
          </Stack>
        )}
        <div className="relative flex-1 w-full min-w-[20%] h-full">
          <Stack
            direction="row"
            alignItems="center"
            className="h-full w-full min-w-fit absolute pr-6"
          >
            <BranchesList
              {...{
                branchIds: branches.map((item) => item?.id as string),
                onClick: branchOnclick,
                canEditBranches: canEditBranches,
                nameTp: nameTp,
              }}
            />
          </Stack>
        </div>
      </Stack>
    </Stack>
  );
};

export default ConversationTopicHeader;
