import {
  SearchInput,
  Stack,
  CheckIcon,
  SmartSpinnerLocal,
  searchInputStyles,
  CustomerStyles,
  relativeFull,
  useStateProvider,
  useIsSelectedRange,
  useIsSelectedView,
  RedirectButton,
  initialResizeBoxProps,
  XCircleIcon,
  IconLabel,
  BookOpenIcon,
} from '@trueconnect/tp-components';
import { useEffect, useState } from 'react';
import CustomerTabs from './DataViewTabs';
import CustomersBreadCrumb from '../CustomersBreadCrumb';
import { useNavigate, useParams } from 'react-router-dom';
import useGetCustomerData from '../useGetCustomerData';
import { useTranslation } from 'react-i18next';
import StatusDialog from 'src/components/StatusBadge/StatusDialog';
import classnames from 'classnames';
import ExtendedCustomerConversationSearchBroker from './ExtendedConversationSearch';
import ExtendedTopicSearch from './ExtendedTopicSearch';
import SyncStatus from '../SyncStatus';
import { renderBadge } from '../columns';

type useParamsType = {
  id: string;
  tab: '' | 'conversations' | 'files' | 'contacts' | 'policies';
};

export const DataView = () => {
  const { id, tab = '' } = useParams<useParamsType>();
  const [activeTab, setActiveTab] = useState<number>(1);
  const navigate = useNavigate();

  const [searchParam, setSearchParam] = useState('');
  const parsedId = parseInt(id || '');
  const { state, actions } = useStateProvider();

  const data = useGetCustomerData(parsedId);
  const { t } = useTranslation();
  const {
    setSelectedFiles,
    setIsUploadPanelVisible,
    setCompanyData,
    setShowNavbar,
    setShowBottombar,
  } = actions;
  const { isUploadPanelVisible, selectedFiles, filesToUpload } = state.uploads;

  useEffect(() => {
    if (isUploadPanelVisible && selectedFiles.length && !filesToUpload.length) {
      setSelectedFiles([]);
      setIsUploadPanelVisible(false);
    }
  }, [
    filesToUpload.length,
    isUploadPanelVisible,
    selectedFiles,
    setIsUploadPanelVisible,
    setSelectedFiles,
  ]);

  useEffect(() => {
    setSearchParam('');
  }, [activeTab]);

  const isSelectedView = useIsSelectedRange(['sm', 'md']);
  const shouldHideSyncStatus = useIsSelectedRange(['sm', 'md', 'lg']);
  const isMobileView = useIsSelectedView('sm');
  const isTabletView = useIsSelectedView('md');

  useEffect(() => {
    isMobileView && setShowNavbar(!id);
    isMobileView && setShowBottombar(!id);
    return () => {
      setShowNavbar(true);
      setShowBottombar(true);
    };
  }, [id, isMobileView, setShowBottombar, setShowNavbar]);

  useEffect(() => {
    data && setCompanyData(data);
    return () => {
      setCompanyData({});
    };
  }, [id, data, setCompanyData]);

  const showExtendedConversationSearch = useIsSelectedRange([
    'sm',
    'md',
    'lg',
    'xl',
  ]);

  return (
    <SmartSpinnerLocal name="DataViewIsDataLoaded" condition={!data}>
      <Stack direction="row" className={'w-full h-full'}>
        <div className={CustomerStyles.wrapper}>
          <Stack
            direction="col"
            className={classnames(
              'w-full relative',
              isMobileView ? '' : 'pl-8 pr-5 pt-5 [&>div>div]:mb-0'
            )}
          >
            {!isMobileView && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="between"
                className="w-full"
              >
                <CustomersBreadCrumb id={parsedId} customerData={data} />
                {!shouldHideSyncStatus && (
                  <div className="[&>div]:pt-0">
                    <SyncStatus active={true} />
                  </div>
                )}
              </Stack>
            )}
            <Stack
              className={classnames(
                'mb-2 w-full mt-2',
                isTabletView && 'h-10',
                isMobileView && 'mb-0'
              )}
              direction={isMobileView ? 'col' : 'row'}
              alignItems={isMobileView ? 'start' : 'center'}
              justifyContent="between"
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="between"
                className={classnames(
                  'h-[50px] w-full',
                  isMobileView && 'ml-2 mt-2 pl-[50px]'
                )}
              >
                {isMobileView && (
                  <RedirectButton
                    className="absolute top-6 left-4"
                    path="/customers"
                  />
                )}
                <Stack direction="col" className="w-full">
                  <Stack
                    direction="row"
                    className="w-full"
                    alignItems="center"
                    justifyContent="between"
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="between"
                    >
                      <p
                        className={classnames(
                          CustomerStyles.header,
                          'overflow-hidden whitespace-nowrap',
                          isMobileView && 'text-base'
                        )}
                      >
                        {data?.name}
                      </p>
                      <div className="mx-4">
                        {data?.isCurrentTrueDataCustomer &&
                          renderBadge(CheckIcon, 'success')}
                        {data?.isFormerTrueDataCustomer &&
                          renderBadge(XCircleIcon, 'failure')}
                      </div>
                    </Stack>
                    {!isSelectedView && (
                      <IconLabel
                        dataTestid="open_notes"
                        title={t('customer.notes.redirectBtn')}
                        icon={<BookOpenIcon className="!w-4 !h-4" />}
                        onClick={() => {
                          navigate('notes');
                        }}
                        className={classnames(
                          'border-x border-y border-[#0170df] rounded-lg pr-4 h-[34px]'
                        )}
                      />
                    )}
                  </Stack>
                  {isMobileView && (
                    <SyncStatus
                      active={true}
                      className="[&>div>div]:m-0 [&>div]:w-6 px-0"
                    />
                  )}
                </Stack>
              </Stack>
              {!['policies', 'files', ''].includes(tab) && !isMobileView && (
                <Stack
                  alignItems="stretch"
                  direction="row"
                  className={classnames('h-10', isMobileView && 'w-full')}
                >
                  {tab !== 'policies' && (
                    <SearchInput
                      setSearchParam={setSearchParam}
                      classNames={{
                        wrapper: classnames(
                          searchInputStyles,
                          isSelectedView && 'w-auto',
                          isTabletView && 'h-10'
                        ),
                      }}
                      dataTestid="customerPageSearch"
                      placeholder={t(`tab.${tab}.Searchplaceholder`)}
                    />
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
          <div className={classnames(relativeFull)}>
            <CustomerTabs
              tabCallback={setActiveTab}
              searchParam={searchParam}
              setSearchParam={setSearchParam}
              customerData={data}
              className={isSelectedView ? '!mx-0' : ''}
            />
          </div>
          <StatusDialog />
        </div>
        {!showExtendedConversationSearch && (
          <ExtendedCustomerConversationSearchBroker
            resizeProps={{
              ...initialResizeBoxProps,
              resizeHandles: ['w'],
              width: 440,
              minConstraints: [360, -1],
            }}
            selectedCustomerId={parsedId}
            classNames={'border-l'}
          />
        )}
        <ExtendedTopicSearch />
      </Stack>
    </SmartSpinnerLocal>
  );
};

export default DataView;
