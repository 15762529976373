import { cloneElement } from 'react';
import { Stack } from './../controls';
import IconButton from './IconButton';
import classnames from 'classnames';
import {
  containerClassname,
  hoverableStyle,
  iconButtonClassname,
  spanClassname,
} from './../styles';

type IconLabelProps = {
  title?: string;
  icon?: JSX.Element;
  justifyContent?:
    | 'center'
    | 'end'
    | 'start'
    | 'between'
    | 'around'
    | 'evenly'
    | undefined;
  onClick?: () => void;
  className?: string;
  textClassName?: string;
  hoverable?: boolean;
  dataTestid?: string;
};

export const IconLabel: React.FC<IconLabelProps> = ({
  title,
  icon,
  justifyContent = 'start',
  onClick,
  className,
  textClassName,
  hoverable = true,
  dataTestid,
}) => {
  return (
    <Stack
      className={classnames(
        containerClassname,
        className,
        hoverableStyle(hoverable)
      )}
      direction="row"
      justifyContent={justifyContent}
      alignItems="center"
      onClick={onClick}
      dataTestid={dataTestid}
    >
      {icon && (
        <IconButton
          size="none"
          hoverable={false}
          className={iconButtonClassname}
        >
          {cloneElement(icon, { color: '#0170df' })}
        </IconButton>
      )}
      <span className={classnames(spanClassname, textClassName)}>{title}</span>
    </Stack>
  );
};

export default IconLabel;
