import {
  PolicyType,
  Stack,
  dateFormat,
  currencyFormat,
  PolicyContacts,
  useIsSelectedRange,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import classnames from 'classnames';
import { NotShowToCustomerIcon } from 'src/components/NotShowToCustomerIcon';
import { useEffect, useState } from 'react';
import { getContactImage } from 'src/utils';

const headerStyle = 'text-base font-semibold';
const textStyle = 'font-medium text-customColor_4 mb-[2px] truncate';

export const PolicyDetails = ({ policyData }: { policyData: PolicyType }) => {
  const { policyManager, accountManager } = policyData;

  const [policyManagerImage, setPolicyManagerImage] = useState('');
  const [accountManagerImage, setAccountManagerImage] = useState('');
  const isMobileView = useIsSelectedRange(['sm', 'md', 'lg']);

  useEffect(() => {
    if (policyData) {
      const fetchImages = async () => {
        const [policyImage, accountImage] = await Promise.all([
          getContactImage(policyManager?.imageId),
          getContactImage(accountManager?.imageId),
        ]);

        setPolicyManagerImage(policyImage);
        setAccountManagerImage(accountImage);
      };

      fetchImages();
    }
  }, [accountManager?.imageId, policyManager?.imageId, policyData]);

  if (!policyData?.id) {
    return <></>;
  }

  const {
    expirationDate,
    notes,
    bruttoPremium = 4000,
    nettoPremium,
    insuranceCompanyLocalAddress,
    validFromDate,
    insuranceCompanyZipCode,
    insuranceCompanyCity,
  } = policyData;

  if (isMobileView) {
    return (
      <Stack className="w-full">
        <PolicyContacts
          {...{
            policyManager: {
              ...policyManager,
              image: policyManagerImage,
            },
            accountManager: {
              ...accountManager,
              image: accountManagerImage,
            },
            isMobileView,
          }}
        />

        <Stack
          direction="col"
          alignItems="center"
          className="bg-customColor_7 w-full px-5 mt-1 py-2 gap-4"
        >
          <Stack className="w-full">
            <p className={classnames(headerStyle)}>
              {t('policyDetails.insurer')}
            </p>

            <Stack className="w-full">
              <p className={classnames(textStyle, 'w-full')}>
                {policyData.insuranceCompanyName}
              </p>
              <p className={textStyle}>{t('policyDetails.brokerServices')}</p>
              <p className={textStyle}>{insuranceCompanyLocalAddress}</p>
              <p className={textStyle}>
                {[insuranceCompanyZipCode, insuranceCompanyCity].join(', ')}
              </p>
            </Stack>
          </Stack>

          <Stack direction="row" className="h-full w-full gap-4">
            <Stack className="w-1/2">
              <p className={classnames(headerStyle)}>
                {t('policyDetails.branch')}
              </p>

              <Stack className="w-full">
                <p className={textStyle}>{policyData.branchName}</p>
              </Stack>
            </Stack>

            <Stack className="w-1/2">
              <p className={classnames(headerStyle)}>
                {t('policyDetails.contractType')}
              </p>

              <Stack className="w-full">
                <p className={classnames(textStyle, 'w-full')}>
                  {policyData.contractTypeName}
                </p>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="row" className="h-full w-full gap-4">
            <Stack className="w-1/2">
              <p className={classnames(headerStyle)}>
                {t('policyDetails.dateValidFrom')}
              </p>

              <Stack className="w-full">
                <p className={textStyle}>{dateFormat(validFromDate, true)}</p>
              </Stack>
            </Stack>

            <Stack className="w-1/2">
              <p className={classnames(headerStyle)}>
                {t('policyDetails.expireDate')}
              </p>

              <Stack className="w-full">
                <p className={textStyle}>{dateFormat(expirationDate, true)}</p>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="row" className="h-full w-full gap-4">
            <Stack className="w-1/2">
              <Stack
                direction="row"
                alignItems="center"
                className={classnames(headerStyle, 'gap-1')}
              >
                <p>{t('policyDetails.netto')}</p>
                <NotShowToCustomerIcon />
              </Stack>
              <Stack className="w-full">
                <p className={textStyle}>{currencyFormat(nettoPremium)}</p>
              </Stack>
            </Stack>

            <Stack className="w-1/2">
              <p className={classnames(headerStyle)}>
                {t('policyDetails.brutto')}
              </p>
              <Stack className="w-full">
                <p className={textStyle}>{currencyFormat(bruttoPremium)}</p>
              </Stack>
            </Stack>
          </Stack>

          {notes && (
            <Stack className="w-full overflow-hidden">
              <p className={classnames(headerStyle)}>
                {t('policyDetails.description')}
              </p>

              <Stack className="w-full max-h-11">
                <p className={classnames(textStyle, 'w-full truncate h-6')}>
                  {notes}
                </p>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack className="w-full">
      <Stack
        direction="row"
        alignItems="center"
        className="w-full h-[240px] gap-4"
      >
        <Stack
          direction="row"
          className="bg-customColor_7 w-full h-full py-[24px] px-[30px] mt-2"
          justifyContent="between"
        >
          <Stack direction="col" className="h-full" justifyContent="between">
            <Stack>
              <p className={classnames(headerStyle)}>
                {t('policyDetails.insurer')}
              </p>

              <Stack className="w-full">
                <p className={textStyle}>{policyData.insuranceCompanyName}</p>
                <p className={textStyle}>{t('policyDetails.brokerServices')}</p>
                <p className={textStyle}>{insuranceCompanyLocalAddress}</p>
                <p className={textStyle}>
                  {insuranceCompanyZipCode} {insuranceCompanyCity}
                </p>
              </Stack>
            </Stack>

            <Stack className=" max-w-[200px] overflow-hidden">
              <p className={classnames(headerStyle)}>
                {t('policyDetails.description')}
              </p>

              <Stack className="w-full max-h-11">
                <p className={classnames(textStyle, 'w-full truncate h-6')}>
                  {notes}
                </p>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="col" className="h-full" justifyContent="between">
            <Stack>
              <p className={classnames(headerStyle)}>
                {t('policyDetails.branch')}
              </p>

              <Stack className="w-full">
                <p className={textStyle}>{policyData.branchName}</p>
              </Stack>
            </Stack>

            <Stack>
              <p className={classnames(headerStyle)}>
                {t('policyDetails.contractType')}
              </p>

              <Stack className="w-full">
                <p className={textStyle}>{policyData.contractTypeName}</p>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="col" className="h-full" justifyContent="between">
            <Stack>
              <p className={classnames(headerStyle)}>
                {t('policyDetails.dateValidFrom')}
              </p>

              <Stack className="w-full">
                <p className={textStyle}>{dateFormat(validFromDate, true)}</p>
              </Stack>
            </Stack>

            <Stack>
              <p className={classnames(headerStyle)}>
                {t('policyDetails.expireDate')}
              </p>

              <Stack className="w-full">
                <p className={textStyle}>{dateFormat(expirationDate, true)}</p>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            direction="col"
            className="h-full max-w-[300px] relative"
            justifyContent="between"
          >
            <Stack>
              <Stack
                direction="row"
                alignItems="center"
                className={classnames(headerStyle, 'gap-1')}
              >
                <p>{t('policyDetails.netto')}</p>
                <NotShowToCustomerIcon />
              </Stack>
              <Stack className="w-full">
                <p className={textStyle}>{currencyFormat(nettoPremium)}</p>
              </Stack>
            </Stack>

            <Stack>
              <p className={classnames(headerStyle)}>
                {t('policyDetails.brutto')}
              </p>
              <Stack className="w-full">
                <p className={textStyle}>{currencyFormat(bruttoPremium)}</p>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <PolicyContacts
          {...{
            policyManager: {
              ...policyManager,
              image: policyManagerImage,
            },
            accountManager: {
              ...accountManager,
              image: accountManagerImage,
            },
          }}
        />
      </Stack>
    </Stack>
  );
};

export default PolicyDetails;
