import { t } from 'i18next';
import {
  CheckIcon,
  IconButton,
  Stack,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import dayjs from 'dayjs';
import { FetchBrokerRepresentativeDto } from 'src/api';

interface Column {
  key: string;
  label: string;
  headerClassname?: string;
  className?: string;
  render: (item: FetchBrokerRepresentativeDto) => React.ReactNode;
}

export const useGetColumns = () => {
  const isMobileView = useIsSelectedView('sm');

  const formatDate = (date: Date) => {
    return dayjs(date).format('DD.MM.YYYY');
  };

  const dateInRange = (start?: Date, end?: Date) => {
    if (start && end) {
      const now = new Date();
      return new Date(start) <= now && now <= new Date(end);
    }
    return false;
  };

  const columns = [
    {
      key: 'representativeUserName',
      label: t('settings.deputyFor'),
      render: (item) => (
        <Stack direction="row" alignItems="center">
          <span className="text-[#0170df]">{item.representativeUserName}</span>
          {isMobileView && dateInRange(item.startFromUtc, item.endFromUtc) && (
            <IconButton hoverable={false}>
              <CheckIcon color="green" />
            </IconButton>
          )}
        </Stack>
      ),
      headerClassname: 'w-[50%]',
      className: 'text-start',
    },
    {
      key: 'startFromUtc',
      label: t('settings.from'),
      render: (item) =>
        item.startFromUtc && <span>{formatDate(item.startFromUtc)}</span>,
      headerClassname: 'w-[20%]',
      className: 'text-start',
    },
    {
      key: 'endFromUtc',
      label: t('settings.to'),
      render: (item) =>
        item.endFromUtc && <span>{formatDate(item.endFromUtc)}</span>,
      headerClassname: 'w-[20%]',
      className: 'text-start',
    },
    {
      key: 'status',
      label: t('settings.status.label'),
      render: (item) => {
        return dateInRange(item.startFromUtc, item.endFromUtc) ? (
          <span className="text-green-500">{t('settings.status.active')}</span>
        ) : (
          <span>{t('settings.status.pending')}</span>
        );
      },
      headerClassname: 'w-[10%] text-end',
      className: 'text-end',
    },
  ] as Column[];

  return isMobileView
    ? columns.filter((item) => item.key !== 'status')
    : columns;
};

export default useGetColumns;
