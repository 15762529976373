import gregorian_de from './gregorian_de';
import gregorian_en from './gregorian_en';

export interface Locale {
  name: string;
  months: string[][];
  weekDays: string[][];
  digits: string[];
  meridiems: string[][];
}

export const locales: Record<string, Locale> = {
  de: gregorian_de,
  en: gregorian_en,
};

export default locales;
