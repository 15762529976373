import { ArrowDownIcon } from '@heroicons/react/24/solid';
import { IconButton } from './../../../components';
import { useStateProvider } from './../../../providers';
import { chatStyles } from './../../../styles';

const ScrollTracker = ({ shouldRender }: { shouldRender: boolean }) => {
  const { setToggleShouldScrollToBottom } = useStateProvider().actions;

  return (
    <>
      {shouldRender && (
        <IconButton
          customSize="w-8 h-8"
          className={chatStyles.bottom.scrollToBottomButton}
          onClick={() => {
            setToggleShouldScrollToBottom(true);
          }}
        >
          <ArrowDownIcon />
        </IconButton>
      )}
    </>
  );
};

export default ScrollTracker;
