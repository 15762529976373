import dayjs from 'dayjs';
import { invoiceType } from './typing';
import { currencyFormat } from './../../../utils';
import { Stack } from './../../../controls';
import { VirtualizedGridCellRendererType } from './../../../components/VirtualizedList';
import { t } from 'i18next';

export const Column = (data?: string | number) => {
  return (
    <Stack direction="row" alignItems="center">
      {data}
    </Stack>
  );
};

export const formatDate = (date?: Date) => {
  return dayjs(date).format('DD.MM.YYYY');
};

export const ToColumn = (item: invoiceType) => {
  return Column(formatDate(item.dateTo));
};

export const RewardTypeColumn = (item: invoiceType) => {
  return Column(item.policyRewardTypeName);
};

export const PriceColumn = (item: invoiceType) => {
  return (
    <Stack direction="row" className="w-full">
      <p className="ml-auto">{currencyFormat(item.amount)}</p>
    </Stack>
  );
};

export const useGetColumns = () => {
  return [
    {
      title: t('policyDetails.invoiceTable.to'),
      dataPropName: 'to',
      render: ToColumn,
    },
    {
      title: t('policyDetails.invoiceTable.rewardType'),
      dataPropName: 'rewardType',
      render: RewardTypeColumn,
    },
    {
      title: (
        <p className="text-end ml-auto">
          {t('policyDetails.invoiceTable.price')}
        </p>
      ),
      dataPropName: 'price',
      render: PriceColumn,
      className: '!justify-end',
    },
  ] as VirtualizedGridCellRendererType<invoiceType>[];
};
