import { useNavigate } from 'react-router-dom';
import { NavbarItemProps } from './SidebarProps';
import { useGetActivePath } from './../../utils';
import { useConfig, useStateProvider } from './../../providers';
import { SidebarStyles } from './../../styles';
import Stack from '../Stack';
import classnames from 'classnames';
import { IconButton } from './../../components';

export const Item = ({ item }: { item: NavbarItemProps }) => {
  const navigate = useNavigate();
  const activeRootPath = useGetActivePath();
  const { FullSidebar } = useConfig().configState;
  const { setSelectedTopic } = useStateProvider().actions;
  const highlight = activeRootPath === item.path ? 'text-customColor_8' : '';

  const onClick = () => {
    setSelectedTopic({});
    navigate(item.path);
  };

  const dataTestId =
    item.path == '/' ? 'dashboard' : item.path.replace('/', '');

  return (
    <Stack
      onClick={onClick}
      direction="row"
      alignItems="center"
      className={classnames(
        SidebarStyles.default(activeRootPath === item.path),
        'w-full mr-1',
        FullSidebar && 'rounded-lg'
      )}
      dataTestid={`sidebar_${dataTestId}`}
    >
      <div
        className={classnames(
          'h-full',
          !FullSidebar &&
            (activeRootPath === item.path
              ? 'border-r-[4px] mr-1 border-black rounded-r-lg'
              : 'mr-2')
        )}
      />

      <IconButton
        size="sm"
        hoverable={false}
        className="pl-0"
        imgClassName={classnames(highlight, '!h-6 !w-6')}
      >
        {item.icon}
      </IconButton>
      {FullSidebar && (
        <span
          onClick={() => {
            navigate(item.path);
          }}
          className="text-gray-900 block ml-3 font-medium"
        >
          {item.title}
        </span>
      )}
    </Stack>
  );
};
