import { useStateProvider } from './../../providers';
import Stack from '../Stack';
import Item from './Item';
import { BottomBarStyles } from './../../styles';
import { NavbarItemProps } from '../Sidebar';

export const BottomBar = ({ list }: { list: NavbarItemProps[] }) => {
  const { showBottomBar } = useStateProvider().state.extra;

  return (
    <Stack
      className={BottomBarStyles.wrapper(showBottomBar)}
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      {list.map((item) => (
        <Item key={item.path} item={item} />
      ))}
    </Stack>
  );
};

export default BottomBar;
