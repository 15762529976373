import {
  CustomerStyles,
  SearchInput,
  Stack,
  TaskType,
  VirtualizedGridCellRendererType,
  searchInputStyles,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import { useCallback, useState } from 'react';
import {
  SearchOrderBy,
  UpdateTaskDto,
  useApiInfiniteQuery,
  useApiInvalidateQueries,
  useApiMutation,
} from 'src/api';
import classnames from 'classnames';
import { CreateTaskButton } from 'src/components/tasks/CreateTaskButton';
import { BacklogTable } from './BacklogTable';
import AttachToTopicModal from 'src/components/topics/AttachToTopicModal';

export const Backlog = ({
  columns,
  orderBy,
}: {
  columns: VirtualizedGridCellRendererType<TaskType>[];
  orderBy?: SearchOrderBy | undefined;
}) => {
  const [searchParam, setSearchParam] = useState('');
  const { addNotification } = useNotificationSystem();
  const { state, actions } = useStateProvider();
  const { setSelectedTask, setShowAttachToTopicDialog, setShowTaskForm } =
    actions;

  const tasksQuery = useApiInfiniteQuery(
    'searchTasks',
    [
      {
        title: searchParam,
        orderBy,
        page: 0,
      },
    ],
    {
      select: (data) => [
        ...data.pages.flatMap((item) =>
          item.map((subitem) => subitem as TaskType)
        ),
      ],
    }
  );

  const { selectedTask } = state.workManagement;
  const { mutate, isLoading } = useApiMutation('updateTask');

  const invalidateQueries = useApiInvalidateQueries();
  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('searchTasks');
  }, [invalidateQueries]);

  const attactToTopic = (value?: string) => {
    const props = {
      ...selectedTask,
      topicId: value,
      assigneesUserIds: selectedTask?.assignees,
    } as UpdateTaskDto;

    mutate([props], {
      onSuccess: () => {
        setSelectedTask({});
        setShowAttachToTopicDialog(false);
        invalidateQuery();
      },
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('Tasks.modals.Update.error.title'),
          subTitle: t('Tasks.modals.Update.error.subtitle'),
        });
      },
    });
  };

  return (
    <Stack className="w-full h-full">
      <Stack
        direction="row"
        alignItems="center"
        className={'w-full py-4 px-5'}
        justifyContent="between"
      >
        <p className={classnames(CustomerStyles.header)}>
          {t('Tasks.tabs.backlog.title')}
        </p>
        <Stack
          direction="row"
          className="w-full"
          justifyContent="end"
          alignItems="center"
        >
          <CreateTaskButton />
          <SearchInput
            setSearchParam={setSearchParam}
            classNames={{
              wrapper: searchInputStyles,
              input: 'text-sm',
            }}
            placeholder={t('Tasks.searchPlaceholder')}
            saveHistory={true}
            inputName="TaskSearch"
            dataTestid="TaskSearch"
          />
        </Stack>
      </Stack>
      <Stack className="w-full h-full relative overflow-hidden">
        <BacklogTable
          {...{
            tasksQuery,
            orderBy,
            columns,
            onRowClick: (item) => {
              setSelectedTask(item);
              setShowTaskForm(true);
            },
          }}
        />
      </Stack>
      <AttachToTopicModal
        {...{
          attactToTopic,
          isLoading,
          initialValue: selectedTask?.topicId,
          customerId: selectedTask?.customerId,
          modalTitle: t('Tasks.modals.AttachToTopic.action'),
        }}
      />
    </Stack>
  );
};

export default Backlog;
