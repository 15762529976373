import React, { Component, ErrorInfo } from 'react';
import Error from '../../pages/Error/Error';
import axios, { AxiosError } from 'axios';
import {
  withTranslation,
  WithTranslation as WithTranslationProps,
} from 'react-i18next';
import { HttpStatusCode, translatedErrors } from './typing';
import { Stack } from '../../controls';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  catchErrors: HttpStatusCode[];
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: AxiosError | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundaryComponent extends Component<
  ErrorBoundaryProps & WithTranslationProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps & WithTranslationProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error | AxiosError) {
    return { hasError: true, error };
  }

  componentDidCatch(error: AxiosError, errorInfo: ErrorInfo) {
    this.setState({ errorInfo });
  }

  componentDidMount() {
    axios.interceptors.response.use(undefined, (error: AxiosError) => {
      if (
        this.props.catchErrors.includes(
          (error.response?.status || error.status) as HttpStatusCode
        )
      ) {
        this.setState({ hasError: true, error });
      }
      return Promise.reject(error);
    });
  }

  render() {
    const { t, catchErrors } = this.props;
    if (this.state.hasError) {
      const status = (this.state.error?.response?.status ||
        this.state.error?.status) as HttpStatusCode;
      const statusCode: HttpStatusCode = translatedErrors.includes(status)
        ? status
        : HttpStatusCode.else;

      const responseData = this.state.error?.response?.data
        ? (this.state.error?.response?.data as {
            message?: string;
            traceId?: string;
            spanId?: string;
          })
        : {};

      if (catchErrors.includes(statusCode)) {
        return (
          <Error
            height="full"
            statusCode={statusCode}
            title={t(`error.${statusCode}.title`)}
            subTitle={t(`error.${statusCode}.subtitle`)}
          >
            <Stack className="pt-5" direction="col">
              {responseData.traceId && (
                <span>Trace ID: {responseData?.traceId}</span>
              )}
              {responseData.spanId && (
                <span>Span ID: {responseData?.spanId}</span>
              )}
            </Stack>
          </Error>
        );
      }

      return this.props.children;
    }

    return this.props.children;
  }
}

export const LocalErrorBoundary = withTranslation()(ErrorBoundaryComponent);

export default LocalErrorBoundary;
