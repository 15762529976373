import { Dropdown } from 'flowbite-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfig } from './../providers';
import Stack from './Stack';
import { IconButton } from './../components';
import { FlagIcon } from './../images';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';

export const LanguageSwitcher = ({ isFull }: { isFull?: boolean }) => {
  const { i18n } = useTranslation();
  const { configActions, configState } = useConfig();
  const { setLanguage } = configActions;
  const { language = 'de' } = configState;

  const changeLanguage = (language: string) => {
    setLanguage(language);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    i18n.init({
      lng: language,
      fallbackLng: 'de',
      interpolation: {
        escapeValue: false,
      },
    });
  }, [i18n, language]);

  interface Language {
    [key: string]: string;
  }

  const languages: Language = {
    en: isFull ? 'English' : 'EN',
    de: isFull ? 'Deutsch' : 'DE',
  };

  const dropdownText = isFull
    ? Object.keys(languages).includes(i18n.language)
      ? languages[i18n.language]
      : languages['de']
    : i18n.language.toUpperCase();

  return (
    <Stack
      direction="row"
      className={classnames(
        'w-full relative [&>div>button]:border-none',
        '[&>button>span]:w-full [&>button>span]:px-1',
        '[&>button]:h-[42px]',
        isFull && '[&>button]:w-[236px] [&>div]:w-full',
        '[&>div>ul>li>button]:px-3 [&>div]:!left-[-2px]'
      )}
    >
      <Dropdown
        size="xs"
        className="fixed"
        arrowIcon={false}
        color="gray"
        label={
          <Stack
            direction="row"
            className="text-gray-500 w-full"
            justifyContent="between"
            alignItems="center"
          >
            <Stack direction="row">
              <IconButton
                hoverable={false}
                customSize="h-4 w-4"
                className="mr-1 ml-0"
              >
                <FlagIcon />
              </IconButton>
              {dropdownText}
            </Stack>
            {isFull && (
              <IconButton
                customSize="h-4 w-4"
                className={classnames('ml-[100px]')}
              >
                <ChevronDownIcon />
              </IconButton>
            )}
          </Stack>
        }
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        {Object.keys(languages).map((key) => (
          <Dropdown.Item key={key} onClick={() => changeLanguage(key)}>
            {languages[key]}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </Stack>
  );
};

export default LanguageSwitcher;
