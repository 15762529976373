import {
  Button,
  ConversationsIcon,
  useUserProfile,
  PlusSmallIcon,
  CommonStyles,
  createConversationStyles,
  useStateProvider,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

export const CreateConversationButton = () => {
  const { t } = useTranslation();
  const profileData = useUserProfile();
  const { showCreateConversation } = useStateProvider().state.conversation;
  const { setShowCreateConversation } = useStateProvider().actions;

  return (
    <Button
      size="xs"
      variant="primary"
      onClick={() => setShowCreateConversation(!showCreateConversation)}
      mobileOverrides={{
        icon: <ConversationsIcon />,
        children: t('navbar.startConversations.short'),
      }}
      icon={<PlusSmallIcon />}
      disabled={!profileData.displayName}
      className={CommonStyles.flex.allCenter}
      dataTestid="CreateConversationButton"
    >
      <p
        className={classnames(
          createConversationStyles.button,
          'leading-normal'
        )}
      >
        {t('navbar.startConversations.long')}
      </p>
    </Button>
  );
};

export default CreateConversationButton;
