import {
  PathHeader,
  capitalizeFirstLetter,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { useLocation } from 'react-use';
import { useTranslation } from 'react-i18next';

export const CustomersBreadCrumb = () => {
  const path =
    useLocation()
      .pathname?.split('/')
      .filter((i) => !!i)
      .slice(0, 1) || [];
  const { t } = useTranslation();

  const params = path.map((item, idx) => {
    return {
      name: capitalizeFirstLetter(t(`sidebar.${item}` as string, item)),
      path: `/${path.slice(0, idx + 1).join('/')}`,
    };
  });

  const isMobileView = useIsSelectedView('sm');

  return (
    <PathHeader params={params} classNames={isMobileView ? '!mb-1' : ''} />
  );
};

export default CustomersBreadCrumb;
