import { useNavigate } from 'react-router-dom';
import { LanguageSwitcher, Stack } from './../../controls';
import { useNotificationSystem, useUserProfile } from './../../providers';
import { Avatar, Button, IconButton } from './../../components';
import classnames from 'classnames';
import { ChevronRightIcon, LogoutIcon } from './../../images';
import { t } from 'i18next';
import { hoverClassname } from './../../styles';
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useIsSelectedView } from './../../utils';

export interface listItem {
  icon: JSX.Element;
  textKey: string;
  onClick?: () => void;
}

export const UserPage = ({
  sidebarItems,
  children = <></>,
}: {
  sidebarItems: listItem[];
  children?: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const profileData = useUserProfile();

  const { addNotification } = useNotificationSystem();
  const { instance } = useMsal();
  const isMobileView = useIsSelectedView('sm');

  async function handleLogout() {
    await instance.logoutRedirect().catch((e: Error) => {
      addNotification({
        title: e.message,
        color: 'failure',
      });
    });
  }

  useEffect(() => {
    if (!isMobileView) {
      navigate('/');
    }
  }, [isMobileView, navigate]);

  return (
    <Stack className="w-full h-full">
      <div className="p-4 mb-3 w-full">
        <Stack
          direction="row"
          className="pt-5 pb-8 px-2 border rounded-xl w-full overflow-hidden"
        >
          <Avatar
            classNames={
              '[&>div>img]:min-w-16 [&>div>img]:h-16 [&>div>div]:w-16 [&>div>div]:h-16'
            }
            imgSrc={profileData.image}
            initials={profileData.displayName}
            status="online"
            size={'lg'}
            bordered={true}
          />
          <p className="text-lg font-semibold truncate leading-normal text-nowrap mt-3 pl-5">
            {profileData.displayName}
          </p>
        </Stack>
      </div>

      <div className="w-full">
        {sidebarItems.map((item, index) => (
          <Stack
            key={index}
            className={classnames(
              'w-full cursor-pointer border-b h-14 pl-1',
              hoverClassname
            )}
            direction="row"
            alignItems="center"
            justifyContent="between"
            onClick={item.onClick}
          >
            <Stack direction="row" alignItems="center">
              <IconButton
                hoverable={false}
                className="[&>div]:!h-6 [&>div]:!w-6"
              >
                {item.icon}
              </IconButton>
              <p className="text-base font-medium">{t(item.textKey)}</p>
            </Stack>

            <IconButton hoverable={false} className="[&>div]:!h-6 [&>div]:!w-6">
              <ChevronRightIcon />
            </IconButton>
          </Stack>
        ))}
      </div>
      <Stack
        alignItems="center"
        className="relative p-4 h-full w-full [&>div]:w-full [&>div>button]:w-full [&>div>button]:h-12"
      >
        <LanguageSwitcher {...{ isFull: true }} />
      </Stack>
      {children}
      <Stack direction="col" className="px-4 py-3 w-full">
        <Button
          className="w-full h-[52px] py-3 bg-white border-2 rounded-lg text-red-500 border-red-500"
          onClick={handleLogout}
        >
          <Stack
            className="w-full h-full"
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton className="text-red-500" hoverable={false}>
              <LogoutIcon />
            </IconButton>
            <p className="text-base font-medium">
              {t('navbar.userDropdown.logout')}
            </p>
          </Stack>
        </Button>
      </Stack>
    </Stack>
  );
};

export default UserPage;
