import { useEffect } from 'react';
import {
  HttpTransportType,
  HubConnection as SignalRHubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { useGetToken, useNotificationSystem } from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import { methodType } from './useGetMethods';

function registerHubListener(
  serverUrl: string,
  accessToken: string,
  methods: methodType[]
) {
  const hubConnection = GetHubConnection(serverUrl, accessToken);

  if (
    !['Disconnected', 'Disconnecting'].includes(hubConnection?.state as string)
  ) {
    methods.map((method) => {
      hubConnection?.on(method.name, method.call);
    });
  }
}

function unregisterHubListener(
  serverUrl: string,
  accessToken: string,
  methods: methodType[]
) {
  const hubConnection = GetHubConnection(serverUrl, accessToken);
  methods.map((method) => {
    hubConnection?.off(method.name, method.call);
  });
}

export function useHubListener(
  serverUrl: string,
  loginRequest: { scopes: string[] },
  methods: methodType[]
) {
  const { t } = useTranslation();
  const { addNotification } = useNotificationSystem();

  const accessToken = useGetToken({
    ...loginRequest,
    onError: () => {
      addNotification({
        title: t('apiCalls.error.getAccount'),
        color: 'failure',
      });
    },
  });

  useEffect(() => {
    if (accessToken) {
      registerHubListener(serverUrl, accessToken, methods);
    }

    return () => {
      if (accessToken) {
        unregisterHubListener(serverUrl, accessToken, methods);
      }
    };
  }, [accessToken, methods, serverUrl]);
}

const hubConnections: Record<string, SignalRHubConnection | undefined> = {};

const GetHubConnection = (serverUrl: string, accessToken: string) => {
  let hubConnection = hubConnections[serverUrl];

  if (!hubConnection && accessToken) {
    hubConnection = hubConnections[serverUrl] = new HubConnectionBuilder()
      .configureLogging(LogLevel.Information)
      .withUrl(`${serverUrl}/notificationsHub`, {
        transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling,
        accessTokenFactory: () => accessToken,
      })
      .build();
    hubConnection.start();
  }
  return hubConnection;
};

export default useHubListener;
