import {
  ConversationListItemProps,
  InformationNote,
  SmartSpinnerLocal,
  Stack,
  VirtualizedList,
  getBranches,
  useIsSelectedRange,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import TopicHeader from './TopicHeader';
import ConversationStatusFilter from 'src/components/conversation/ConversationStatusFilter';
import { ConversationState, useApiInfiniteQuery } from 'src/api';
import classnames from 'classnames';
import { t } from 'i18next';
import AddConversationToTopicButton from 'src/components/topics/AddConversationToTopicButton';
import RowRenderer from '../../Conversations/RowRenderer';
import { useEffect } from 'react';

export const TopicsDetails = () => {
  const { state, actions } = useStateProvider();
  const { selectedTopic } = state.workManagement;
  const { conversationFilter: selectedType } = state.conversation;
  const { setConversationType: setSelectedType, setSelectedTopic } = actions;
  const { availableBranches } = state.workManagement;
  const { addNotification } = useNotificationSystem();
  const conversationsFound = selectedTopic.totalConversations;

  useEffect(() => {
    setSelectedType('Open');
  }, [selectedTopic.id, setSelectedType]);

  const queryConversations = useApiInfiniteQuery(
    'searchCustomerConversations',
    [
      selectedTopic.customerId || 0,
      {
        state:
          selectedType === 'All'
            ? undefined
            : (selectedType as ConversationState),
        topicIds: selectedTopic.id ? [selectedTopic.id] : [],
      },
    ],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map(
            (subitem) =>
              ({
                title: subitem.title,
                id: subitem.id,
                participants: subitem.participants,
                lastActive: subitem.lastActivityUtcTimestamp,
                branches: getBranches(availableBranches, subitem.branchIds),
                ...subitem,
              } as ConversationListItemProps)
          )
        ),
      enabled: !!selectedTopic.id,
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('Topics.Details.cantLoadConversations'),
        });
      },
    }
  );

  const isMobileView = useIsSelectedRange(['sm', 'md', 'lg']);

  return (
    <Stack className="w-full h-full">
      <TopicHeader
        {...{
          item: selectedTopic,
          conversationsFound,
          isMobileView,
        }}
      />
      <SmartSpinnerLocal
        condition={queryConversations.isLoading && selectedType === 'All'}
        name="searchConversations"
      >
        {!!conversationsFound && (
          <Stack
            direction="col"
            className={classnames(
              'w-full h-full relative',
              isMobileView ? '' : 'px-8 pt-5'
            )}
          >
            <Stack
              direction="row"
              className="w-full"
              alignItems="center"
              justifyContent="between"
            >
              <div
                className={classnames(isMobileView ? 'w-full' : 'w-[360px]')}
              >
                <ConversationStatusFilter
                  {...{
                    selectedType,
                    setSelectedType,
                    isMobileView: true,
                    classNames: isMobileView ? 'px-2' : 'pl-0',
                  }}
                />
              </div>
              <AddConversationToTopicButton
                btnType={isMobileView ? 'secondary' : 'primary'}
              />
            </Stack>
            <div className={classnames('w-full h-full')}>
              <VirtualizedList
                columns={[
                  {
                    dataPropName: 'recentConversation',
                    render: (item) => {
                      return (
                        <RowRenderer
                          key={item.id}
                          {...{
                            item,
                            onClick: () => {
                              setSelectedTopic(selectedTopic);
                            },
                          }}
                        />
                      );
                    },
                  },
                ]}
                infinityQuery={queryConversations}
                headerHeight={0}
                rowHeight={120}
                nothingFound={
                  <InformationNote
                    title={t('conversation.nothingIsFound.text')}
                  />
                }
              />
            </div>
          </Stack>
        )}
        {!conversationsFound && (
          <Stack className="w-full h-full">
            <Stack
              direction="col"
              className="pb-[180px] w-[380px] m-auto text-center"
              alignItems="center"
              justifyContent="center"
            >
              <h1 className="text-3xl text-[#111928] font-semibold leading-normal mb-4">
                {t('Topics.Details.noConversationFound.header')}
              </h1>
              <p className="text-lg leading-normal mb-8">
                {t('Topics.Details.noConversationFound.subtitle')}
              </p>
              <AddConversationToTopicButton className="!mr-0 h-[41px]" />
            </Stack>
          </Stack>
        )}
      </SmartSpinnerLocal>
    </Stack>
  );
};

export default TopicsDetails;
