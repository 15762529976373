import { DocumentCellStyle } from '../../../styles';
import { IDocumentType } from '../DocumentType';

export const FileDateTime: React.FC<IDocumentType> = (item: IDocumentType) => {
  return (
    <div className={DocumentCellStyle}>
      <p className="font-medium">{item.modifiedAt}</p>
    </div>
  );
};

export default FileDateTime;
