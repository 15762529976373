import {
  Button,
  CalendarIcon,
  IconButton,
  InformationNote,
  SmartSpinnerLocal,
  Stack,
  compareDates,
  headerStyle,
  mapDays,
  useIsSelectedRange,
  useNotificationSystem,
  useUserProfile,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { useState } from 'react';
import DeputySearch from './DeputySearch';
import { deputyType } from './typing';
import { useApiMutation, useApiQuery } from 'src/api';
import DeputyFor from './DeputyFor';
import dayjs from 'dayjs';
import ConfirmActionModal from './ConfirmActionModal';
import locales from '@trueconnect/tp-components/src/locales';

export const ItemWithTitle = ({
  children,
  title,
  classNames,
}: {
  children: React.ReactNode;
  title: string;
  classNames?: string;
}) => {
  return (
    <Stack direction="col" className={classnames('w-full h-fit', classNames)}>
      <p className={'font-medium text-sm leading-normal'}>{title}</p>
      {children}
    </Stack>
  );
};

export const inputStyle =
  '!border !border-gray-200 rounded-lg p-2 !h-10 !w-full focus:outline-none focus:border-gray-400';

export const savedData =
  '!border-green-500 !placeholder-green-500 !text-green-500';

export const DeputySettings = () => {
  const { t } = useTranslation();
  const { displayName } = useUserProfile();
  const today = new DateObject();
  const [startDate, setStartDate] = useState<DateObject | undefined>();
  const [endDate, setEndDate] = useState<DateObject | undefined>();
  const [selectedDeputy, setSelectedDeputy] = useState<deputyType>({});
  const isMobileView = useIsSelectedRange(['sm', 'md']);
  const [isGetDataLoaded, setIsGetDataLoaded] = useState(false);
  const [actionModalType, setActionModalType] = useState<
    'delete' | 'create' | ''
  >('');
  const { addNotification } = useNotificationSystem();

  const {
    data,
    isLoading: get_IsLoading,
    refetch,
  } = useApiQuery('get_Deputy', [], {
    onSuccess: async (data) => {
      const { startFromUtc, endFromUtc, deputyUserId, deputyUserName } = data;
      startFromUtc && setStartDate(new DateObject(formatDate(startFromUtc)));
      endFromUtc && setEndDate(new DateObject(formatDate(endFromUtc)));
      setSelectedDeputy({ id: deputyUserId, displayName: deputyUserName });
      await setIsGetDataLoaded(true);
    },
  });

  const { data: userRepresentatives, isLoading: representativeIsLoading } =
    useApiQuery('get_User_Representatives', []);

  const { mutate, isLoading } = useApiMutation('set_Deputy');

  const { mutate: mutateDelete, isLoading: isDeleting } =
    useApiMutation('delete_Deputy');

  const compareStartDates = () => {
    if (!(startDate && endDate && data?.startFromUtc && data?.endFromUtc))
      return false;
    const startFromUtc = startDate.toDate();
    return compareDates(new Date(data?.startFromUtc), new Date(startFromUtc));
  };

  const compareEndDates = () => {
    if (!(startDate && endDate && data?.startFromUtc && data?.endFromUtc))
      return false;
    const endFromUtc = endDate.toDate();
    return compareDates(new Date(data?.endFromUtc), new Date(endFromUtc));
  };

  const isNotChanged = compareStartDates() && compareEndDates();

  const formatDate = (date: Date) => {
    return dayjs(date).startOf('day').toDate();
  };

  const saveDeputy = () => {
    if (!(startDate && endDate)) {
      return;
    }
    mutate(
      [
        {
          startFromUtc: formatDate(startDate.toDate()),
          endFromUtc: formatDate(endDate.toDate()),
          deputyUserId: selectedDeputy.id,
        },
      ],
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  const deleteDeputy = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setSelectedDeputy({});
    mutateDelete([], {
      onSuccess: () => {
        refetch();
      },
    });
  };

  const { i18n } = useTranslation();
  const selectedLanguage = locales[i18n.language] ? i18n.language : 'de';

  return (
    <>
      <SmartSpinnerLocal
        condition={representativeIsLoading || !isGetDataLoaded || isLoading}
      >
        <div className="w-full p-3">
          <Stack
            direction="col"
            className={classnames(
              'p-6 w-full h-fit gap-2',
              isMobileView ? 'border-b border-t' : 'rounded-lg border'
            )}
          >
            {!get_IsLoading && (
              <Stack
                direction={isMobileView ? 'col' : 'row'}
                className="w-full gap-6"
              >
                <ItemWithTitle title={`(${t('settings.inputs.you')})`}>
                  <input
                    className={classnames(
                      inputStyle,
                      'text-sm',
                      data?.deputyUserId && savedData
                    )}
                    placeholder={displayName}
                    disabled
                  />
                </ItemWithTitle>
                <ItemWithTitle title={t('settings.inputs.period')}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    className="w-full gap-6 h-full"
                  >
                    <Stack
                      className="w-full relative"
                      direction="row"
                      alignItems="center"
                    >
                      <IconButton
                        className="absolute left-2"
                        customSize="!w-4 !h-4"
                        hoverable={false}
                      >
                        <CalendarIcon />
                      </IconButton>
                      <DatePicker
                        value={startDate}
                        locale={locales[selectedLanguage]}
                        onChange={(date: DateObject) => {
                          setStartDate(date);
                        }}
                        containerClassName="!w-full"
                        placeholder={t('settings.inputs.Start')}
                        format="DD.MM.YYYY"
                        weekStartDayIndex={1}
                        onOpenPickNewDate={false}
                        inputClass={classnames(
                          inputStyle,
                          'text-sm indent-5',
                          compareStartDates() && savedData
                        )}
                        minDate={today}
                        mapDays={(object) => {
                          return {
                            className: mapDays(startDate, endDate, object),
                          };
                        }}
                      />
                    </Stack>
                    <Stack
                      className="w-full relative"
                      direction="row"
                      alignItems="center"
                    >
                      <IconButton
                        className="absolute left-2"
                        customSize="!w-4 !h-4"
                        hoverable={false}
                      >
                        <CalendarIcon />
                      </IconButton>
                      <DatePicker
                        value={endDate}
                        locale={locales[selectedLanguage]}
                        onChange={(date: DateObject) => {
                          setEndDate(date);
                        }}
                        containerClassName="!w-full"
                        placeholder={t('settings.inputs.End')}
                        format="DD.MM.YYYY"
                        weekStartDayIndex={1}
                        onOpenPickNewDate={false}
                        inputClass={classnames(
                          inputStyle,
                          'text-sm indent-5',
                          compareEndDates() && savedData
                        )}
                        minDate={today}
                        mapDays={(object) => {
                          return {
                            className: mapDays(startDate, endDate, object),
                          };
                        }}
                      />
                    </Stack>
                  </Stack>
                </ItemWithTitle>
                <ItemWithTitle
                  title={t('settings.inputs.substitute')}
                  classNames={classnames(
                    `[&>div>button]:!w-full !relative [&>div:nth-child(2)]:!w-full [&>div:nth-child(3)]:!w-full`
                  )}
                >
                  <DeputySearch
                    {...{
                      selectedDeputy,
                      setSelectedDeputy,
                      savedDeputyUserId: data?.deputyUserId,
                    }}
                  />
                </ItemWithTitle>
              </Stack>
            )}
            <InformationNote
              title={t('settings.infoNote')}
              wrapperClassnames={isMobileView ? '!h-fit' : ''}
            />
            <Stack
              direction="row"
              className={classnames('gap-3 mt-4 w-full')}
              justifyContent="end"
              alignItems="end"
            >
              {data?.deputyUserName && (
                <Button
                  isLoading={isDeleting}
                  className="mt-auto !h-10 [&>span]:leading-normal"
                  onClick={() => {
                    setActionModalType('delete');
                  }}
                  size="md"
                >
                  {t('settings.delete')}
                </Button>
              )}
              {!data?.deputyUserName && (
                <Button
                  variant="primary"
                  size="md"
                  className="!h-10 [&>span]:leading-normal"
                  disabled={
                    !startDate || !endDate || isNotChanged || !selectedDeputy.id
                  }
                  onClick={() => {
                    if (startDate && endDate && startDate > endDate) {
                      addNotification({
                        color: 'info',
                        title: t(
                          'settings.Warnings.startDateMustBeEarlierThanEndDate'
                        ),
                      });
                      return;
                    }
                    setActionModalType('create');
                  }}
                  isLoading={isLoading}
                >
                  {t('common.save')}
                </Button>
              )}
            </Stack>
          </Stack>
          {!!userRepresentatives?.length && (
            <Stack direction="col" className="h-fit w-full mt-6 pb-5">
              <h1 className={classnames(headerStyle, isMobileView && 'pl-1')}>
                {t('settings.deputyFor')}:
              </h1>
              <Stack
                direction="col"
                className={classnames(
                  'w-full h-fit',
                  isMobileView
                    ? 'border-b border-t'
                    : 'rounded-lg px-6 pb-8 border'
                )}
              >
                <DeputyFor data={userRepresentatives || []} />
              </Stack>
            </Stack>
          )}
        </div>
      </SmartSpinnerLocal>

      <ConfirmActionModal
        type={actionModalType}
        visible={!!actionModalType}
        onClose={() => {
          setActionModalType('');
        }}
        onOk={actionModalType == 'create' ? saveDeputy : deleteDeputy}
      />
    </>
  );
};

export default DeputySettings;
