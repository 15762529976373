import { PathHeader, capitalizeFirstLetter } from '@trueconnect/tp-components';
import { useLocation } from 'react-use';
import { useTranslation } from 'react-i18next';
import { FetchCustomerInfoDto } from 'src/api';

export const NotesPageBreadCrumb: React.FC<{
  id?: number;
  customerData?: FetchCustomerInfoDto;
}> = ({ id, customerData }) => {
  const path =
    useLocation()
      .pathname?.split('/')
      .filter((i) => !!i)
      .slice(0, 2) || [];
  const { t } = useTranslation();

  const params = path.map((item, idx) => {
    const pth = `/${path.slice(0, idx + 1).join('/')}`;
    if (id && item === id.toString())
      return {
        name: customerData?.name || '',
        path: pth,
      };
    return {
      name: capitalizeFirstLetter(t(`tab.${item}.text` as string, item)),
      path: pth,
    };
  });

  const finalParams = [
    ...params,
    {
      name: t('customer.notes.title'),
      path: '',
    },
  ];

  return <PathHeader params={finalParams} />;
};

export default NotesPageBreadCrumb;
