import { useTranslation } from 'react-i18next';
import { useConfig } from './../providers';
import { useEffect } from 'react';

export const LanguageControl = () => {
  const { i18n } = useTranslation();
  const { language } = useConfig().configState;

  useEffect(() => {
    i18n.init({
      lng: language,
      fallbackLng: 'de',
      interpolation: {
        escapeValue: false,
      },
    });
  }, [i18n, language]);

  return <></>;
};
