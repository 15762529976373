import NotificationMessage from './NotificationMessage';
import UserMessage from './UserMessage';
import { Spinner } from './../../../components';
import Stack from './../../../controls/Stack';
import { AttachmentType } from './Attachments';
import { useGetFileUrlShort } from './../../../types';
import classnames from 'classnames';
import { useDateFormat } from '../../../utils/useDateFormat';
import { chatStyles } from './../../../styles';

export type MessageType = {
  id?: string;
  type: 'message' | 'notification';
  messageText?: string | undefined;
  senderUserId?: string;
  senderUserName: string;
  createdUtcTimestamp: Date;
  icon?: string;
  date?: string;
  loading?: boolean;
  attachments?: AttachmentType[] | undefined;
  // emotions?: string[]; //for later
};

export type MessageProps = {
  message: MessageType;
  isOwn: boolean;
  attachments: AttachmentType[];
} & useGetFileUrlShort;

const mapping = {
  message: UserMessage,
  notification: NotificationMessage,
};

export const Message: React.FC<MessageProps> = (props) => {
  const { message, isOwn } = props;
  const { type } = message;

  const SelectedType = mapping[type || 'message'];

  const timestamp = useDateFormat(message.createdUtcTimestamp);

  return (
    <Stack direction="row" alignItems="center" className={'w-full'}>
      <Stack
        direction="col"
        alignItems={isOwn ? 'end' : 'start'}
        className="w-full"
      >
        {message.senderUserName && (
          <span
            className={classnames(
              chatStyles.sender,
              message.loading ? 'my-4' : ''
            )}
          >
            {[!isOwn ? message.senderUserName : '', timestamp]
              .filter(Boolean)
              .join(', ')}
          </span>
        )}
        <Stack
          direction="row"
          className={classnames(isOwn && 'justify-end', 'w-full')}
        >
          {message.loading && <Spinner className="mx-3 ml-auto" />}
          <SelectedType {...props} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Message;
