import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useClickAway } from 'react-use';
import Button from './Button';
import { t } from 'i18next';
import classnames from 'classnames';

export const ColorPicker = ({
  value,
  setValue,
  id,
  placeholder = '',
  dataTestId = 'colorPicker',
  classNames = '',
  pickerClassname,
}: {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  id?: string;
  placeholder?: string;
  dataTestId?: string;
  classNames?: string;
  pickerClassname?: string;
}) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => {
    setShow(false);
  });

  return (
    <div
      id={id}
      className="relative flex flex-row items-center gap-2"
      ref={ref}
    >
      <div
        style={{
          background: value,
        }}
        onClick={() => {
          setShow(!show);
        }}
        className="!min-w-[42px] !min-h-[42px] rounded-full border cursor-pointer"
      />
      <input
        type="text"
        value={value}
        data-testid={`${dataTestId}_input`}
        placeholder={placeholder}
        className={classnames('border-gray-300 !h-[42px]', classNames)}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      {show && (
        <div
          className={classnames(
            '!absolute bottom-0 z-[50] bg-white p-2 !pb-1 border rounded-xl shadow',
            pickerClassname
          )}
        >
          <HexColorPicker
            className="!h-[120px] [&>div>div>div]:w-4 [&>div>div>div]:h-4"
            data-testid={`${dataTestId}_colorPicker`}
            color={value}
            onChange={(color) => {
              /^#[0-9A-F]{6}$/i.test(color) && setValue(color);
            }}
          />
          <Button
            size="xs"
            className="ml-auto mt-1"
            onClick={() => {
              setShow(!show);
            }}
          >
            {t('Branches.createForm.ok')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
