import {
  BranchesList,
  ChevronDownIcon,
  ChevronUpIcon,
  IconButton,
  Stack,
  TrashIcon,
  VirtualizedGridCellRendererType,
  cellStyle,
  dateFormat,
  TaskType,
  useStateProvider,
  EllipsisHorizontalIcon,
  DropDownItemType,
  Dropdown,
  FeatureFlags,
  environment,
  RectangleStackIcon,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { SearchOrderBy } from 'src/api';
import StatusBadge from './StatusBadge';

export const CommonColumn = (value?: string | number, classNames?: string) => {
  return (
    <div
      className={classnames(
        cellStyle,
        'font-medium pr-5 overflow-hidden',
        classNames
      )}
    >
      <span className="truncate">{value}</span>
    </div>
  );
};

export const Branches = (item: TaskType) => {
  return (
    <div className="pl-3">
      <BranchesList
        {...{
          branchIds: item.branchIds,
          canEditBranches: false,
          nameTp: 'shortName',
        }}
      />
    </div>
  );
};

export const Actions = ({ item }: { item: TaskType }) => {
  const { actions } = useStateProvider();
  const { setSelectedTask, setShowDeleteTask, setShowAttachToTopicDialog } =
    actions;

  const items: DropDownItemType[] = [
    {
      icon: <RectangleStackIcon data-testid={`attachTopic_${item.title}`} />,
      text: t('Tasks.modals.AttachToTopic.action') as string,
      onClick: () => {
        setSelectedTask(item);
        setShowAttachToTopicDialog(true);
      },
    },
  ];

  return (
    <div
      className="flex flex-row align-center w-full pr-1"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <IconButton
        onClick={() => {
          setSelectedTask(item);
          setShowDeleteTask(true);
        }}
      >
        <TrashIcon data-testid={`taskDelete_${item.title}`} />
      </IconButton>
      {environment && FeatureFlags.showTasks(environment) && (
        <Dropdown
          label={
            <IconButton>
              <EllipsisHorizontalIcon
                data-testid={`taskActions_${item.title}`}
              />
            </IconButton>
          }
          items={items}
        />
      )}
    </div>
  );
};

export const useGetColumns = ({
  setOrderBy,
  orderBy,
}: {
  setOrderBy?: Dispatch<SetStateAction<SearchOrderBy | undefined>>;
  orderBy?: SearchOrderBy | undefined;
}) => {
  const { availableTaskTypes = [] } = useStateProvider().state.workManagement;

  const OrderByBadge = ({ orderKey }: { orderKey: SearchOrderBy }) => {
    const selected = orderBy == orderKey;
    return (
      <IconButton
        onClick={() => {
          setOrderBy && setOrderBy(selected ? undefined : orderKey);
        }}
      >
        {selected ? <ChevronDownIcon /> : <ChevronUpIcon />}
      </IconButton>
    );
  };

  const columns: VirtualizedGridCellRendererType<TaskType>[] = [
    {
      dataPropName: 'title',
      title: (
        <Stack className="pl-8" direction="row" alignItems="center">
          {t('Tasks.tabs.table.columns.title')}
          {setOrderBy && <OrderByBadge orderKey={SearchOrderBy.Title} />}
        </Stack>
      ),
      render: (item) => CommonColumn(item.title, 'pl-8'),
      widthRatio: 2,
    },
    {
      dataPropName: 'customer',
      title: (
        <Stack className="pl-1" direction="row" alignItems="center">
          {t('Tasks.tabs.table.columns.customer')}
          {setOrderBy && <OrderByBadge orderKey={SearchOrderBy.CustomerName} />}
        </Stack>
      ),
      render: (item) => CommonColumn(item.customerName, 'text-[#0170df]'),
      widthRatio: 2,
    },
    {
      dataPropName: 'assignees',
      title: <p className="pl-1">{t('Tasks.tabs.table.columns.assignees')}</p>,
      render: (item) =>
        CommonColumn(item.assignees?.map((itm) => itm.userName)?.join(', ')),
      widthRatio: 3,
    },
    {
      dataPropName: 'status',
      title: (
        <Stack direction="row" alignItems="center">
          {t('Tasks.tabs.table.columns.status')}{' '}
          {setOrderBy && <OrderByBadge orderKey={SearchOrderBy.StateId} />}
        </Stack>
      ),
      render: (item) => StatusBadge({ item, availableTaskTypes }),
      widthRatio: 2,
    },
    {
      dataPropName: 'branches',
      title: <p>{t('Tasks.tabs.table.columns.branches')}</p>,
      render: Branches,
      widthRatio: 2,
    },
    {
      dataPropName: 'updated',
      title: (
        <Stack direction="row" alignItems="center">
          {t('Tasks.tabs.table.columns.lastEdit')}{' '}
          {/* <OrderByBadge orderKey={SearchOrderBy.CreatedAt} /> */}
        </Stack>
      ),
      render: (item) => CommonColumn(dateFormat(item.updatedUtc)),
      widthRatio: 2,
    },
    {
      dataPropName: 'deadline',
      title: (
        <Stack direction="row" alignItems="center">
          {t('Tasks.tabs.table.columns.deadline')}{' '}
          {setOrderBy && <OrderByBadge orderKey={SearchOrderBy.DueDate} />}
        </Stack>
      ),
      render: (item) => CommonColumn(dateFormat(item.dueDateUtc, true)),
      widthRatio: 2,
    },
    {
      dataPropName: 'actions',
      title: <p className="pl-1">{t('Tasks.tabs.table.columns.actions')}</p>,
      render: (item) => (
        <Actions
          {...{
            item,
          }}
        />
      ),
      widthRatio: 1,
    },
  ];

  return columns;
};
