import { payloadType } from '../StateProvider/actions';
import { HubListenerNotificationType } from './typing';

interface actions {
  [key: string]: (payload?: payloadType) => any;
}

export const stateActionsHubListener: actions = {
  ReceiveNotification: (toAdd: HubListenerNotificationType) => ({
    type: 'RECEIVE_NOTIFICATION',
    payload: toAdd,
  }),
  RemoveNotification: (notificationId: string) => ({
    type: 'REMOVE_NOTIFICATION',
    payload: notificationId,
  }),
  RemoveMessageNotification: (messageId: string) => ({
    type: 'REMOVE_MESSAGE_NOTIFICATION',
    payload: messageId,
  }),
};

export type actionshubListener = {
  ReceiveNotification: (toAdd: HubListenerNotificationType) => void;
  RemoveNotification: (notificationId: string) => void;
  RemoveMessageNotification: (messageId: string) => void;
};

export default stateActionsHubListener;
