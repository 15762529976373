import { payloadType } from '../StateProvider/actions';
import {
  ConversationType,
  conversationDataType,
  createConversationType,
} from './reducers';
import {
  contactToCreateConversationType,
  extendedSearchParamsType,
} from './typing';

type actionType =
  | 'SEARCH_VALUE'
  | 'CONVERSATION_TYPE'
  | 'SHOW_ADD_MEMBERS_DIALOG'
  | 'SET_CONVERSATION_DATA'
  | 'SHOW_CHANGE_STATUS_DIALOG'
  | 'SHOW_TITLE_DIALOG'
  | 'SHOW_CREATE_CONVERSATION'
  | 'SET_CREATE_CONVERSATION_TYPE'
  | 'SET_SHOW_EXTENDED_SEARCH'
  | 'SET_EXTENDED_SEARCH_PARAMS'
  | 'SET_CONTACT_TO_CREATE_CONVERSATION';

interface action {
  type: actionType;
  payload?: payloadType;
}

interface actions {
  [key: string]: (payload?: payloadType) => action;
}

export const stateActionsConversation: actions = {
  setSearchValue: (value: string) => ({
    type: 'SEARCH_VALUE',
    payload: value,
  }),
  setConversationType: (value: ConversationType) => ({
    type: 'CONVERSATION_TYPE',
    payload: value,
  }),
  setShowAddMembersDialog: (value: boolean) => ({
    type: 'SHOW_ADD_MEMBERS_DIALOG',
    payload: value,
  }),
  setShowChangeStatusDialog: (value: boolean) => ({
    type: 'SHOW_CHANGE_STATUS_DIALOG',
    payload: value,
  }),
  setShowChangeTitleDialog: (value: boolean) => ({
    type: 'SHOW_TITLE_DIALOG',
    payload: value,
  }),
  setConversationData: (value: conversationDataType) => ({
    type: 'SET_CONVERSATION_DATA',
    payload: value,
  }),
  setShowCreateConversation: (value: boolean) => ({
    type: 'SHOW_CREATE_CONVERSATION',
    payload: value,
  }),
  setCreateConversationModalType: (value: createConversationType) => ({
    type: 'SET_CREATE_CONVERSATION_TYPE',
    payload: value,
  }),
  setShowExtendedSearch: (value: boolean) => ({
    type: 'SET_SHOW_EXTENDED_SEARCH',
    payload: value,
  }),
  setExtendedSearchParams: (value: extendedSearchParamsType) => ({
    type: 'SET_EXTENDED_SEARCH_PARAMS',
    payload: value,
  }),
  setContactToCreateConversation: (value: contactToCreateConversationType) => ({
    type: 'SET_CONTACT_TO_CREATE_CONVERSATION',
    payload: value,
  }),
};

export type actionsConversation = {
  setSearchValue: (value: string) => void;
  setConversationType: (value: ConversationType) => void;
  setShowAddMembersDialog: (value: boolean) => void;
  setShowChangeStatusDialog: (value: boolean) => void;
  setShowChangeTitleDialog: (value: boolean) => void;
  setConversationData: (value: conversationDataType) => void;
  setShowCreateConversation: (value: boolean) => void;
  setCreateConversationModalType: (value: createConversationType) => void;
  setShowExtendedSearch: (value: boolean) => void;
  setExtendedSearchParams: (value: extendedSearchParamsType) => void;
  setContactToCreateConversation: (
    value: contactToCreateConversationType
  ) => void;
};

export default stateActionsConversation;
