import {
  Button,
  CheckIcon,
  CommonStyles,
  IconButton,
  Spinner,
  Stack,
  defaultScrollbar,
  relativeFull,
  useIsSelectedView,
  useStateProvider,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { useApiMutation } from 'src/api';
import { t } from 'i18next';

type NotesTabType = {
  conversationId: string;
};

export const NotesTab: React.FC<NotesTabType> = ({ conversationId }) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const { state, actions } = useStateProvider();
  const { conversationData } = state.conversation;
  const { notes, readOnly } = conversationData;
  const { setConversationData } = actions;
  const [inputValue, setInputValue] = useState(notes || '');

  const { mutate: saveNotes, isLoading } = useApiMutation(
    'update_Conversation'
  );

  const handleOnClick = useCallback(async () => {
    setConversationData({ ...conversationData, notes: inputValue });
    return await saveNotes([
      {
        id: conversationId,
        title: conversationData?.title,
        notes: inputValue,
        branchIds: conversationData.branches
          .filter((item) => !!item.id)
          .map((item) => item.id as string),
        topicId: conversationData.topicId,
      },
    ]);
  }, [
    conversationData,
    conversationId,
    inputValue,
    saveNotes,
    setConversationData,
  ]);

  // useEffect(() => {
  //   if (!readOnly) {
  //     ref.current?.focus();
  //     ref.current?.setSelectionRange(inputValue.length, inputValue.length);
  //   }
  // });

  const isMobileView = useIsSelectedView('sm');

  return (
    <Stack className={relativeFull} direction={isMobileView ? 'col' : 'row'}>
      {(!readOnly || isLoading) && isMobileView && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          className="w-full h-16 px-2 bg-customColor_7"
        >
          <Button
            variant="primary"
            size="sm"
            className={classnames(
              '!h-9 [&>span]:p-0',
              !isMobileView && 'mr-10'
            )}
            disabled={inputValue === notes}
          >
            <IconButton
              hoverable={false}
              size="none"
              className={classnames('!m-0', isLoading && 'pb-1')}
            >
              {isLoading ? (
                <Spinner size="sm" data-testid="saveChangesSpinner" />
              ) : (
                <CheckIcon
                  className={classnames('text-white')}
                  data-testid="saveChanges"
                />
              )}
            </IconButton>
            <span className="p-3">{t('conversation.changeNotes')}</span>
          </Button>
        </Stack>
      )}

      <div
        className={classnames(CommonStyles.full, !isMobileView && 'px-8 py-6')}
      >
        <textarea
          value={inputValue}
          autoFocus={true}
          data-testid="notesTextArea"
          ref={ref}
          disabled={readOnly}
          placeholder={readOnly ? '' : t('tab.notes') + '...'}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onBlur={() => {
            inputValue !== notes && !isLoading && handleOnClick();
          }}
          className={classnames(
            'w-full h-full resize-none border-none p-0',
            readOnly && 'text-customColor_23',
            defaultScrollbar
          )}
        />
      </div>
      {!isMobileView && (
        <Button
          className="h-9 mt-3 mr-2 border-none"
          disabled={inputValue === notes}
        >
          <Stack direction="row" alignItems="center">
            <IconButton
              hoverable={false}
              size="none"
              className={classnames(
                '!w-6 [&>div]:!ml-auto [&>div]:!mr-0 w-4 [&>div]:w-4 [&>div]:h-4',
                isLoading && 'pb-2'
              )}
            >
              {isLoading ? (
                <Spinner size="sm" data-testid="saveChangesSpinner" />
              ) : (
                <CheckIcon
                  className={classnames('text-customColor_6')}
                  data-testid="saveChanges"
                />
              )}
            </IconButton>
            <span className="p-3 text-customColor_6">
              {t('conversation.changeNotes')}
            </span>
          </Stack>
        </Button>
      )}
    </Stack>
  );
};

export default NotesTab;
