import classnames from 'classnames';
import { commonText } from '.';

export const companyNameClassname = commonText;

export const addressClassname = commonText;

export const InformationRowWrapper = (classNames?: string) => {
  return classnames(
    ' w-full h-16 border-b border-customColor_1',
    classNames || ''
  );
};

export const HoldingInfoHeaderClassnames = (isMobileView: boolean) =>
  classnames(
    'font-semibold text-2xl pb-2 leading-normal select-none',
    isMobileView ? 'px-4' : 'px-8'
  );

export const customerContacts =
  'font-semibold text-base leading-1.5 text-ellipsis overflow-hidden';

export const CustomerStyles = {
  wrapper: 'w-full h-full flex flex-col bg-customColor_17 box-border',
  header: 'font-semibold text-2xl leading-normal',
  informationTab: {
    wrapper: 'w-full flex p-4 flex-row items-center border-b border-gray-300',
    title: (str: string | undefined) =>
      classnames('leading-normal font-medium text-base', str),
    AccountManagerInfo: {
      wrapper: 'w-full border-b border-customColor_1 h-16',
      header: 'font-semibold text-2xl leading-normal select-none',
      name: 'font-semibold text-base leading-6 h-6',
    },
  },
  greyedOut: (disabled: boolean | undefined) =>
    disabled ? '!text-customColor_23 !cursor-default' : '',
};
