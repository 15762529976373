import {
  InformationRow,
  HomeIcon,
  EnvelopeIcon,
  MapPinIcon,
  Stack,
  InformationNote,
  SmartSpinnerLocal,
  PhoneIcon,
  InsurancesIcon,
  useIsSelectedRange,
  IconLabel,
  BookOpenIcon,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import { FetchCustomerInfoDto, useApiQuery } from 'src/api';
import { HoldingInfo } from './HoldingInfo';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import ContactPersons from './ContactPersons';
import { ContactPersonType } from './ContactPersonListItem';
import { useEffect, useState } from 'react';
import { getContactImage } from 'src/utils';

export type RowProps = {
  icon?: React.ReactNode | string;
  title: React.ReactNode;
  classNames?: {
    wrapper?: string;
    img?: string;
    title?: string;
  };
};

const InformationTab: React.FC<{ customerData?: FetchCustomerInfoDto }> = ({
  customerData,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSelectedView = useIsSelectedRange(['sm', 'md']);
  const [contacts, setContacts] = useState<ContactPersonType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { data = [], isFetched } = useApiQuery('getBrokersForCustomer', [
    customerData?.id || 0,
  ]);

  useEffect(() => {
    if (data.length) {
      const fetchImages = async () => {
        const contactsWithImages = await Promise.all(
          data.map(
            async (item) =>
              ({
                ...item,
                image: await getContactImage(item.imageId),
                phone: item.directPhoneNumber,
                isManager: item.isAccountManager,
                branchIds: item.responsibleForBranchIds,
              } as ContactPersonType)
          )
        );
        setContacts(
          contactsWithImages.sort((a, b) =>
            a.isManager === b.isManager ? 0 : a.isManager ? -1 : 1
          )
        );
        setIsLoading(false);
      };
      fetchImages();
    }
  }, [data]);

  useEffect(() => {
    isFetched && data.length == 0 && setIsLoading(false);
  }, [isFetched, data]);

  return (
    <SmartSpinnerLocal name="InformationTab" condition={!customerData}>
      <Stack direction="col">
        {isSelectedView && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            className="w-full py-2 border-b [&>div]:mr-4"
          >
            <IconLabel
              dataTestid="invited_contacts_button"
              title={t('customer.notes.redirectBtn')}
              icon={<BookOpenIcon className="!w-4 !h-4" />}
              onClick={() => {
                navigate('notes');
              }}
              className={classnames(
                'border-x border-y border-[#0170df] rounded-lg pr-4 h-[34px]'
              )}
            />
          </Stack>
        )}
        <div
          className={classnames(
            'w-full h-full',
            !isSelectedView && 'pl-8 pr-5'
          )}
        >
          <InformationRow
            icon={<HomeIcon />}
            title={customerData?.localAddress}
          />
          <InformationRow
            icon={<MapPinIcon />}
            title={`${customerData?.zipCode}, ${customerData?.city}`}
          />
          <InformationRow
            icon={<EnvelopeIcon />}
            display={!!customerData?.postOfficeMailBox}
            title={
              <a href={`mailto: ${customerData?.postOfficeMailBox}`}>
                {customerData?.postOfficeMailBox}
              </a>
            }
          />
          <InformationRow
            icon={<PhoneIcon />}
            display={!!customerData?.phone}
            title={
              <a href={`tel: ${customerData?.phone}`}>{customerData?.phone}</a>
            }
          />
          {customerData?.isAHolding === false && customerData?.holdingName && (
            <InformationRow
              icon={<InsurancesIcon />}
              title={customerData.holdingName}
              onClick={() => navigate(`/customers/${customerData.holdingId}`)}
            />
          )}
        </div>
        <div
          className={classnames(
            isSelectedView ? 'px-4' : 'pl-8 pr-5',
            'w-full h-full'
          )}
        >
          <InformationNote
            title={t('customer.informationPage.info')}
            wrapperClassnames={classnames(isSelectedView && 'pl-2')}
          />
        </div>
        <ContactPersons
          {...{
            contacts,
            isLoading,
          }}
        />
        {customerData?.children && customerData.children.length > 0 && (
          <HoldingInfo data={customerData.children} />
        )}
      </Stack>
    </SmartSpinnerLocal>
  );
};

export { InformationTab };
