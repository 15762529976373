import { Button, Stack } from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { t } from 'i18next';

export const ShareDocumentModal = ({
  visible,
  name,
  onAccept,
  onClose,
}: {
  visible: boolean;
  name: string;
  onAccept: () => void;
  onClose: () => void;
}) => {
  return (
    <Modal
      show={visible}
      onClose={onClose}
      title={t('permissions.allowSharedDocuments.finalShare.header')}
      showFooter={false}
    >
      {t('permissions.allowSharedDocuments.finalShare.body')}
      <Stack direction="row" className="w-full gap-2 mt-7">
        <Button
          className="w-1/2"
          variant="secondary"
          onClick={onClose}
          dataTestid={`cancelShare_${name.split(' ').join('_')}`}
        >
          {t('common.cancel')}
        </Button>
        <Button
          className="w-1/2"
          variant="primary"
          onClick={() => {
            onAccept();
            onClose();
          }}
          dataTestid={`confirmShare_${name.split(' ').join('_')}`}
        >
          {t('common.accept')}
        </Button>
      </Stack>
    </Modal>
  );
};

export default ShareDocumentModal;
