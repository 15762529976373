import { Spinner, Stack, StackItem } from '@trueconnect/tp-components';
import React from 'react';
import classnames from 'classnames';

export interface LoadingTransitionPageProps {
  children?: React.ReactNode;
  className?: string;
}

/**
 * Renders component with a loading spinner.
 */
export const LoadingTransitionPage: React.FC<LoadingTransitionPageProps> = ({
  children,
  className = 'h-full w-full',
}) => {
  return (
    <Stack
      alignItems="center"
      direction="col"
      justifyContent="center"
      className={classnames(className, 'z-20')}
      dataTestid="Loading"
    >
      <StackItem className="flex flex-col items-center">
        <Spinner />
        {children}
      </StackItem>
    </Stack>
  );
};

export default LoadingTransitionPage;
