import { branchType } from '../WorkManagement/branches';
import {
  ConversationParticipantType,
  contactToCreateConversationType,
  extendedSearchParamsType,
} from './typing';

export const ConversationTypes = ['Open', 'Closed', 'All', 'Mine'] as (
  | 'Open'
  | 'Closed'
  | 'All'
  | 'Mine'
)[];

export type ConversationType = (typeof ConversationTypes)[number];

type conversationState = 'Open' | 'Closed';

export type conversationDataType = {
  state: conversationState;
  conversationId: string;
  readOnly: boolean;
  disableUpload: boolean;
  topicId?: string;
  topicName?: string;
  notes: string;
  participants: ConversationParticipantType[];
  lastReadMessagesTimestamp?: Date;
  title?: string;
  customerId?: number;
  isConversationMember?: boolean;
  branches: branchType[];
};

export type createConversationType = 'primary' | 'secondary';

type ConversationState = {
  searchValue: string;
  conversationFilter: ConversationType;
  showAddMembersDialog: boolean;
  showChangeStatusDialog: boolean;
  showChangeTitleDialog: boolean;
  conversationData: conversationDataType;
  showCreateConversation: boolean;
  createConversationType: createConversationType;
  showExtendedSearch: boolean;
  extendedSearchParams: extendedSearchParamsType;
  contactToCreateConversation: contactToCreateConversationType;
};

type ConversationAction =
  | { type: 'SEARCH_VALUE'; payload: string }
  | { type: 'CONVERSATION_TYPE'; payload: ConversationType }
  | { type: 'SHOW_ADD_MEMBERS_DIALOG'; payload: boolean }
  | { type: 'SHOW_CHANGE_STATUS_DIALOG'; payload: boolean }
  | { type: 'SET_CONVERSATION_DATA'; payload: conversationDataType }
  | { type: 'SHOW_TITLE_DIALOG'; payload: boolean }
  | { type: 'SHOW_CREATE_CONVERSATION'; payload: boolean }
  | { type: 'SET_CREATE_CONVERSATION_TYPE'; payload: createConversationType }
  | { type: 'SET_SHOW_EXTENDED_SEARCH'; payload: boolean }
  | { type: 'SET_EXTENDED_SEARCH_PARAMS'; payload: extendedSearchParamsType }
  | {
      type: 'SET_CONTACT_TO_CREATE_CONVERSATION';
      payload: contactToCreateConversationType;
    };

export const initialConversationDataState = {
  conversationId: '',
  readOnly: true,
  disableUpload: true,
  participants: [],
  notes: '',
  state: 'Open' as conversationState,
  branches: [],
} as conversationDataType;

export const initialConversationState: ConversationState = {
  searchValue: '',
  conversationFilter: 'Open',
  showAddMembersDialog: false,
  conversationData: initialConversationDataState,
  showChangeStatusDialog: false,
  showChangeTitleDialog: false,
  showCreateConversation: false,
  createConversationType: 'primary',
  showExtendedSearch: false,
  extendedSearchParams: {},
  contactToCreateConversation: {},
};

const conversationReducer = (
  state: ConversationState = initialConversationState,
  action: ConversationAction
): ConversationState => {
  switch (action.type) {
    case 'SEARCH_VALUE':
      return { ...state, searchValue: action.payload };
    case 'CONVERSATION_TYPE':
      return { ...state, conversationFilter: action.payload };
    case 'SET_CONVERSATION_DATA':
      return { ...state, conversationData: action.payload };
    case 'SHOW_ADD_MEMBERS_DIALOG':
      return { ...state, showAddMembersDialog: action.payload };
    case 'SHOW_TITLE_DIALOG':
      return { ...state, showChangeTitleDialog: action.payload };
    case 'SHOW_CHANGE_STATUS_DIALOG':
      return { ...state, showChangeStatusDialog: action.payload };
    case 'SHOW_CREATE_CONVERSATION':
      return { ...state, showCreateConversation: action.payload };
    case 'SET_CREATE_CONVERSATION_TYPE':
      return { ...state, createConversationType: action.payload };
    case 'SET_SHOW_EXTENDED_SEARCH':
      return { ...state, showExtendedSearch: action.payload };
    case 'SET_EXTENDED_SEARCH_PARAMS':
      return { ...state, extendedSearchParams: action.payload };
    case 'SET_CONTACT_TO_CREATE_CONVERSATION':
      return { ...state, contactToCreateConversation: action.payload };
    default:
      return state;
  }
};

export { conversationReducer };
export type { ConversationAction };
export type { ConversationState };
