import { Badge } from 'flowbite-react';
import { IconButton } from './../../../components';
import { XMarkIcon } from './../../../images';
import { branchType } from './../../../providers';
import classnames from 'classnames';

export type nameType = 'name' | 'shortName' | 'convenientName';

type BranchBadgeType = {
  item: branchType;
  onClick?: (item: branchType) => void;
  selectedName?: nameType;
  dataTestId?: string;
  classNames?: string;
  isRemove?: boolean;
};

export const BranchBadge: React.FC<BranchBadgeType> = ({
  item,
  onClick,
  selectedName = 'convenientName',
  classNames,
  dataTestId,
  isRemove = false,
}) => {
  return (
    <Badge
      style={{ color: item.color, background: item.backgroundColor }}
      data-testid={dataTestId}
      className={classnames(
        'cursor-pointer !h-[22px] [&>span]:flex [&>span]:flex-row [&>span]:items-center',
        isRemove && 'pr-0',
        classNames
      )}
      onClick={() => {
        onClick?.(item);
      }}
    >
      <p className="block text-nowrap">{item[selectedName]}</p>
      {isRemove && (
        <IconButton
          size="none"
          hoverable={false}
          imgClassName="!w-4 !h-4 pt-[1px]"
        >
          <XMarkIcon />
        </IconButton>
      )}
    </Badge>
  );
};

export default BranchBadge;
