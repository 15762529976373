import {
  EllipsisHorizontalIcon,
  IconButton,
  Stack,
  ChevronRightIcon,
  PlusIcon,
  Dropdown,
  ContactStatus,
  DropDownItemType,
  EyeSlashIcon,
  statusBadgeStyles,
  useStateProvider,
  PencilSquareIcon,
  ContactType,
  ContactPermissionDto,
  useIsSelectedRange,
} from '@trueconnect/tp-components';
import { type ContactForRenderType } from './ContactsTab';
import StatusBadge from 'src/components/StatusBadge/StatusBadge';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { t } from 'i18next';
import { indicatorStates } from 'src/components/StatusBadge/states';
import {
  ConversationAccessWarning,
  NoEmailWarning,
  NotActiveInTrueDataWarning,
} from 'src/components/WarningIcon';

type ContactActionsProps = {
  item: ContactForRenderType;
  startConversation?: (contact: ContactType) => void;
  isMobileView?: boolean;
  hideActions?: boolean;
  hideStatus?: boolean;
  className?: string;
  permissions?: ContactPermissionDto;
};

export const ContactActions = (props: ContactActionsProps) => {
  const { status, activeInTrueData, email } = props.item;
  const hasContactEmail = !!email;
  if (!status) return <></>;
  return (
    <Stack
      className={classnames('w-full pr-2', props.className)}
      direction="row"
      alignItems="center"
      justifyContent={activeInTrueData && hasContactEmail ? 'between' : 'end'}
    >
      {activeInTrueData && hasContactEmail && <ActiveInTrueData {...props} />}

      {!hasContactEmail && <HasNoContactEmail />}
      {!activeInTrueData && <NotActiveInTrueData />}
    </Stack>
  );
};

export const ActiveInTrueData = (props: ContactActionsProps) => {
  const { id, email, status, customerId, firstName, lastName, permissions } =
    props.item;

  if (!id || !status) return <></>;

  return (
    <>
      {!props.hideStatus && (
        <StatusBadge
          showFullText={!props.isMobileView}
          email={email || ''}
          status={status}
          contactId={id}
          customerId={customerId}
          displayName={classnames(firstName, lastName)}
        />
      )}
      <Stack direction="row" className="w-full" justifyContent="end">
        {!permissions?.allowConversations && <ConversationAccessWarning />}
        {!props.hideActions && (
          <Actions
            {...{
              ...props,
              ...{ contact: { ...props.item } },
            }}
          />
        )}
      </Stack>
    </>
  );
};

export const NotActiveInTrueData = () => {
  const { t } = useTranslation();
  const isMobileView = useIsSelectedRange(['sm', 'md', 'lg']);

  if (isMobileView) {
    return <NotActiveInTrueDataWarning />;
  }

  return (
    <>
      <Stack
        className={classnames(
          statusBadgeStyles.headerText,
          'text-customColor_6'
        )}
        direction="row"
        alignItems="center"
      >
        <p>{t('activateContactInTrueData')}</p>
        <IconButton hoverable={false}>
          <ChevronRightIcon className="text-customColor_6" />
        </IconButton>
      </Stack>
    </>
  );
};

export const HasNoContactEmail = () => {
  const { t } = useTranslation();

  const isMobileView = useIsSelectedRange(['sm', 'md', 'lg']);

  if (isMobileView) {
    return <NoEmailWarning />;
  }

  return (
    <>
      <p
        className={classnames(
          statusBadgeStyles.headerText,
          'text-customColor_6'
        )}
      >
        {t('addEmailToTrueData')}
      </p>
      <IconButton hoverable={false}>
        <ChevronRightIcon className="text-customColor_6" />
      </IconButton>
    </>
  );
};

export const Actions = (props: {
  item: ContactForRenderType;
  startConversation?: (contact: ContactType) => void;
  isMobileView?: boolean;
}) => {
  const { isMobileView } = props;
  const { id, email, status, firstName, lastName, customerId, userId } =
    props.item;
  const { setCustomerData, setToggleCustomerModal, setTogglePermissionModal } =
    useStateProvider().actions;

  const items: DropDownItemType[] = [];

  if (status != ContactStatus.Deactivated) {
    items.push({
      icon: <PlusIcon data-testid="createConversation" />,
      text: t('navbar.createConversationPanel.title'),
      classNames: 'text-[#0170DF] border-[#0170DF]',
      type: 'button',
      color: '#0170DF',
      onClick: () => {
        props.startConversation?.({
          ...props.item,
        });
      },
    });
  }

  if (
    status &&
    userId &&
    [ContactStatus.Invited, ContactStatus.Active].includes(status)
  ) {
    items.push({
      icon: <PencilSquareIcon data-testid="setContactPermissions" />,
      text: t('permissions.button'),
      type: 'button',
      onClick: () => {
        id &&
          customerId &&
          setCustomerData({
            contactId: id,
            email,
            status,
            customerId,
            displayName: classnames(firstName, lastName),
          });
        setTogglePermissionModal(true);
      },
    });
  }

  const handleStatusClick = () => {
    id &&
      customerId &&
      setCustomerData({
        contactId: id,
        email,
        status,
        customerId,
        displayName: classnames(firstName, lastName),
      });
    setToggleCustomerModal(true);
  };

  if (status == ContactStatus.Active) {
    items.push({
      icon: <EyeSlashIcon data-testid="deactivateCustomer" />,
      text: t('contactsTab.deactivateCustomer'),
      classNames: 'text-[#E53935] border-[#E53935]',
      type: 'button',
      color: '#E53935',
      onClick: handleStatusClick,
    });
  }

  if (status && ![ContactStatus.Active].includes(status) && isMobileView) {
    const { color, icon, border } =
      indicatorStates[status || ContactStatus.Active];

    items.push({
      icon: icon,
      color: color,
      classNames: `text-${border} border-${border}`,
      text: t(`statusBadge.${status}`),
      onClick: handleStatusClick,
      type: 'button',
    });
  }

  if (!id) return <></>;

  return (
    <Dropdown
      onClick={(e) => {
        e.stopPropagation();
      }}
      label={
        <IconButton>
          <EllipsisHorizontalIcon
            data-testid={`${props.item.email?.replace('@', '_')}_actions`}
          />
        </IconButton>
      }
      mobileView={isMobileView}
      items={items}
    />
  );
};
