import {
  IconButton,
  PencilIcon,
  Stack,
  TrashIcon,
} from '@trueconnect/tp-components';
import { premiumType } from './typing';
import { Dispatch, SetStateAction } from 'react';
import { Tooltip } from 'flowbite-react';
import { t } from 'i18next';

export const Actions = ({
  item,
  setIsFormVisible,
  setShowDeleteModal,
  setSelectedPremiumType,
}: {
  item: premiumType;
  setIsFormVisible: Dispatch<SetStateAction<boolean>>;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  setSelectedPremiumType: Dispatch<SetStateAction<premiumType | undefined>>;
}) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="end">
      {item.canBeDeleted && (
        <IconButton
          className="!m-0 !p-0"
          onClick={() => {
            setSelectedPremiumType(item);
            setIsFormVisible(true);
          }}
        >
          <PencilIcon />
        </IconButton>
      )}
      {item.canBeDeleted ? (
        <IconButton
          className="!m-0 !p-0"
          onClick={() => {
            setSelectedPremiumType(item);
            setShowDeleteModal(true);
          }}
        >
          <TrashIcon />
        </IconButton>
      ) : (
        <div className="[&>div]:flex">
          <Tooltip
            content={t('premiumTypes.alreadyInUse')}
            placement="left"
            style="light"
            trigger="hover"
          >
            <IconButton hoverable={false} className="!m-0 !p-0">
              <TrashIcon color={'#D1D5DB'} />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </Stack>
  );
};
