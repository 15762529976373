import { ConversationListItemProps } from './ConversationListItemProps';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  UserCircleIcon,
  circle,
  IconButton,
  Stack,
  ConversationReasonClassname,
  IconButtonIconClassname,
  userNameClassname,
  conversationPageStyles,
  useOneParticipant,
  useStateProvider,
  Highlighted,
  hiddenScrollbar,
  BranchBadge,
} from '@trueconnect/tp-components';
import { useGetLastActiveTime } from './getLastActiveTime';
import classnames from 'classnames';
import { ReceiveMessageType } from './../../providers/HubListener/typing';
import { Badge } from 'flowbite-react';

export const ConversationListItem: React.FC<ConversationListItemProps> = (
  props
) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLastActive = true, redirect = true } = props;

  const participant = useOneParticipant(props.participants, true);

  const time = useGetLastActiveTime(props.lastActive);

  const { notifications } = useStateProvider().state.hubListener;
  const unreadMessagesForConversation = notifications.filter(
    (item) =>
      item.target == 'ReceiveMessage' &&
      (item.notification as ReceiveMessageType).conversationId == props.id
  ).length;

  const { searchValue } = useStateProvider().state.conversation;

  return (
    <Stack
      className={classnames(
        conversationPageStyles.listItem(
          !!props.selected,
          props.classNames || ''
        ),
        `test_${props.title}`
      )}
      dataTestid={props.title}
      onClick={() => {
        redirect && navigate(`/conversations/${props.id}`);
      }}
    >
      <Stack
        justifyContent="between"
        alignItems="center"
        className="w-full"
        direction="row"
      >
        <Stack
          justifyContent="start"
          alignItems="center"
          className={classnames('gap-1 overflow-auto', hiddenScrollbar)}
          direction="row"
        >
          {props.state && (
            <Badge
              className="!h-[22px] [&>span]:font-medium [&>span]:leading-normal px-[10px]"
              color={props.state == 'Open' ? 'success' : 'indigo'}
            >
              {t(`conversationTab.conversation.${props.state}`)}
            </Badge>
          )}
          {!!props.branches &&
            props.branches.map((item) =>
              item && item.id ? (
                <BranchBadge
                  key={item.id}
                  {...{
                    item,
                    selectedName: 'shortName',
                  }}
                />
              ) : (
                <></>
              )
            )}
        </Stack>
        {showLastActive && (
          <Stack className="w-fit">
            <p className={conversationPageStyles.lastActive}>{time}</p>
          </Stack>
        )}
      </Stack>

      <Stack
        justifyContent="between"
        alignItems="center"
        className="w-full my-2"
        direction="row"
      >
        <p className={ConversationReasonClassname}>
          {props.title
            ? Highlighted(props.title, searchValue)
            : `(${t('common.titleWithExpand.placeholder')})`}
        </p>
        {(props.hasUnReadMessages || !!unreadMessagesForConversation) && (
          <img src={circle} alt="" />
        )}
      </Stack>

      <Stack direction="row" alignItems="center" className="w-full h-full">
        <IconButton
          hoverable={false}
          customSize="w-4 h-4 mb-[3px]"
          imgClassName={IconButtonIconClassname}
        >
          <UserCircleIcon className={IconButtonIconClassname} />
        </IconButton>
        <span
          className={userNameClassname}
          title={`${participant?.displayName} (${participant?.email})`}
        >
          {participant?.displayName} ({participant?.email})
        </span>
      </Stack>
    </Stack>
  );
};

export default ConversationListItem;
