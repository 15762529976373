import { payloadType } from '../StateProvider/actions';

type actionType = 'SHOW_NAVBAR' | 'SHOW_BOTTOMBAR';

interface action {
  type: actionType;
  payload?: payloadType;
}

interface actions {
  [key: string]: (payload?: payloadType) => action;
}

export const stateActionsExtra: actions = {
  setShowNavbar: (value: boolean) => ({
    type: 'SHOW_NAVBAR',
    payload: value,
  }),
  setShowBottombar: (value: boolean) => ({
    type: 'SHOW_BOTTOMBAR',
    payload: value,
  }),
};

export type actionsExtra = {
  setShowNavbar: (value: boolean) => void;
  setShowBottombar: (value: boolean) => void;
};

export default stateActionsExtra;
