import {
  Avatar as AvatarFlowbite,
  AvatarProps as AvatarPropsFlowbite,
} from 'flowbite-react';
import { FlowbitePositions } from 'flowbite-react/lib/esm/components/Flowbite/FlowbiteTheme';
import { getInitialsFor } from './../utils';
import classnames from 'classnames';
import {
  avatarStyles,
  subTitleStyles as subTitleStylesBase,
} from './../styles';
import { Stack } from './../controls';

export type AvatarProps = {
  imgSrc?: string;
  classNames?: string;
  initials?: string;
  title?: string;
  subtitle?: string | React.ReactNode;
  size?: AvatarPropsFlowbite['size'];
  status?: 'away' | 'busy' | 'offline' | 'online';
  statusPosition?: keyof FlowbitePositions;
  bordered?: boolean;
  titleStyles?: string;
  subTitleStyles?: string;
  children?: React.ReactNode;
  stacked?: boolean;
  onClick?: () => void;
};

export const Avatar: React.FC<AvatarProps> = ({
  imgSrc /*= tempAvatar,*/,
  classNames,
  title,
  subtitle,
  size = 'xs',
  initials = title,
  statusPosition = 'top-right',
  bordered = false,
  titleStyles = '',
  subTitleStyles = '',
  children,
  onClick,
  ...props
}) => {
  initials = !imgSrc && initials ? getInitialsFor(initials) : ' ';

  return (
    <Stack direction="row" alignItems="center" onClick={onClick}>
      <AvatarFlowbite
        rounded={true}
        img={imgSrc}
        className={classnames(
          size == 'xs' && '[&>div>div]:w-8 [&>div>div]:h-8',
          'mx-1 p-1 select-none relative',
          !!imgSrc && '[&>div>img]:p-0',
          classNames
        )}
        placeholderInitials={initials}
        size={size}
        statusPosition={statusPosition}
        bordered={bordered}
        {...props}
      />
      {children && children}
      <div className="overflow-hidden w-full">
        {(title || subtitle) && (
          <div className={classnames(avatarStyles, 'overflow-hidden')}>
            {title && (
              <div className={classnames(titleStyles, 'inline')}>{title}</div>
            )}
            {subtitle && (
              <div
                className={classnames(
                  subTitleStylesBase,
                  'overflow-hidden',
                  subTitleStyles
                )}
              >
                {subtitle}
              </div>
            )}
          </div>
        )}
      </div>
    </Stack>
  );
};

export default Avatar;
