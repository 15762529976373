import {
  useIsSelectedRange,
  EditableTitle,
  useIsSelectedView,
  Stack,
  ConversationParticipantType,
  useStateProvider,
  MessageType,
  RedirectButton,
} from '@trueconnect/tp-components';
import ConversationActions from './ConversationActions';
import ParticipantDisplay from './ParticipantDisplay';
import AddTaskModal, {
  batchUpdateTask,
} from 'src/components/tasks/AddTaskModal';
import { useApiMutation } from 'src/api';
import { useState } from 'react';

export const ConversationHeader = ({
  saveConversation,
  isSavingConversation,
  readOnly,
  title,
  participant,
  messages,
}: {
  saveConversation: (title: string) => void;
  isSavingConversation: boolean;
  readOnly: boolean;
  title: string;
  participant?: ConversationParticipantType;
  messages: MessageType[];
}) => {
  const isMobileView = useIsSelectedView('sm');
  const isSelectedView = useIsSelectedRange(['sm', 'md', 'lg']);
  const { state, actions } = useStateProvider();
  const { conversationData } = state.conversation;
  const { showChangeBranches } = state.workManagement;
  const { participants } = conversationData;
  const { setShowAddTask } = actions;

  const { mutate, isLoading } = useApiMutation('update_Tasks_in_Batch');
  const [editedTasks, setEditedTasks] = useState<batchUpdateTask[]>([]);

  const onAccept = () => {
    mutate([editedTasks], {
      onSuccess: () => {
        setShowAddTask(false);
        setEditedTasks([]);
      },
    });
  };

  return (
    <Stack
      direction="row"
      className=" w-full"
      alignItems="center"
      justifyContent="between"
    >
      {isSelectedView && participants?.length ? (
        <>
          {isMobileView && <RedirectButton path="/conversations" />}
          <Stack direction="col" className="w-full">
            <h1
              className={
                'font-medium text-xl leading-normal truncate flex-1 w-0 min-w-full'
              }
            >
              {title}
            </h1>
            <ParticipantDisplay {...{ participants, participant }} />
          </Stack>
        </>
      ) : (
        <EditableTitle
          title={title || ''}
          onSave={(title) => saveConversation(title)}
          isSaving={isSavingConversation && !showChangeBranches}
          isEditable={!readOnly}
        />
      )}
      <ConversationActions title={title} messages={messages} />
      <AddTaskModal
        {...{
          onAccept,
          isLoading,
          customerId: conversationData.customerId,
          parentId: conversationData.conversationId,
          editedTasks,
          setEditedTasks,
        }}
      />
    </Stack>
  );
};

export default ConversationHeader;
