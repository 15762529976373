import {
  Dropdown as DropdownFlowbite,
  DropdownItemProps,
  DropdownProps as DropdownPropsFlowbite,
} from 'flowbite-react';
import classnames from 'classnames';
import { cloneElement } from 'react';
import DropdownModal from './DropdownModal';
import { Stack } from './../../controls';

export type DropDownItemType = Omit<DropdownItemProps, 'icon' | 'type'> & {
  icon?: JSX.Element;
  component?: React.ReactNode;
  classNames?: string;
  text?: string;
  color?: string;
  type?: 'text' | 'button' | 'divider';
};

type DropdownProps = DropdownPropsFlowbite & {
  items: DropDownItemType[];
  defaultItemClassnames?: string;
  header?: React.ReactNode;
  mobileView?: boolean;
  modalClassnames?: string;
  trigger?: 'hover' | 'click';
  dismissOnClick?: boolean;
  modalTitle?: React.ReactNode;
  placement?:
    | 'auto'
    | 'left-start'
    | 'left-end'
    | 'right-end'
    | 'right-start'
    | 'top'
    | 'right'
    | 'bottom'
    | 'left';
};

export const Dropdown = (props: DropdownProps) => {
  if (props.items.length == 0) return <></>;

  const {
    items,
    mobileView,
    placement = 'left-start',
    header,
    className,
  } = props;

  if (mobileView) {
    return <DropdownModal {...props} />;
  }

  return (
    <DropdownFlowbite
      arrowIcon={false}
      placement={placement}
      inline={true}
      {...props}
      className={classnames(
        '!fixed',
        '[&>div]:py-0 [&>div>ul]:py-0',
        mobileView && 'pr-2',
        className
      )}
    >
      {header && <DropdownFlowbite.Header>{header}</DropdownFlowbite.Header>}
      {items.map((item, key) => {
        const textColor = item.color?.startsWith('#')
          ? `[${item.color}]`
          : item.color || '[#374151]';

        if (item.component) {
          return (
            <DropdownFlowbite.Item
              onClick={item.onClick}
              key={key}
              className={classnames('p-2', item.classNames)}
            >
              {item.component}
            </DropdownFlowbite.Item>
          );
        }

        if (item.type == 'divider') {
          return <DropdownFlowbite.Divider key={`divider-${key}`} />;
        }

        return (
          <DropdownFlowbite.Item
            key={key}
            className={classnames(
              'h-8',
              props.defaultItemClassnames,
              item.classNames,
              'text-sm font-normal'
            )}
            onClick={item.onClick}
          >
            <Stack direction="row" alignItems="center">
              {item.icon &&
                cloneElement(item.icon, {
                  color: item.color,
                  className: 'h-6 w-6 pr-2',
                })}
              <span
                className={classnames(
                  textColor && `text-${textColor}`,
                  'pt-[1px]'
                )}
              >
                {item.text}
              </span>
            </Stack>
          </DropdownFlowbite.Item>
        );
      })}
    </DropdownFlowbite>
  );
};

export default Dropdown;
