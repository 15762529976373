import {
  Stack,
  Avatar,
  getInitialsFor,
  BranchIconList,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { t } from 'i18next';

export type ContactPersonType = {
  id?: string;
  email?: string | undefined;
  displayName?: string | undefined;
  phone?: string | undefined;
  branchIds?: string[];
  isManager?: boolean;
  image?: string;
};

export const ContactPersonListItem = ({
  item,
  isManager,
}: {
  item: ContactPersonType;
  isManager?: boolean;
}) => {
  const { branchIds = [] } = item;

  return (
    <Stack
      direction="row"
      alignItems="center"
      className={classnames(
        'h-16 !w-[360px] relative text-nowrap p-4 pl-0 border-b',
        'ContactPersonListItem',
        isManager && 'bg-customColor_7 border rounded-md'
      )}
    >
      {isManager && (
        <Stack alignItems="center" className="absolute -top-4 z-5 w-full">
          <div className="bg-customColor_7 rounded-lg border border-customColor_1 px-3">
            <span className="text-sm font-medium">
              {t('customer.informationPage.manager')}
            </span>
          </div>
        </Stack>
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="between"
        className="w-full overflow-hidden"
      >
        <div className="w-full overflow-hidden">
          <Avatar
            initials={getInitialsFor(item.displayName || '')}
            subtitle={item.phone}
            imgSrc={item.image}
            title={item.displayName}
            titleStyles="text-black"
            subTitleStyles="text-black"
            classNames="m-0 p-0 mr-2 ml-1 !w-8 !h-8 [&>div>img]:w-8 [&>div>img]:h-8 [&>div]:w-8 [&>div]:h-8"
          />
        </div>
        <BranchIconList
          {...{
            branchIds,
          }}
        />
      </Stack>
    </Stack>
  );
};
