import {
  ConversationTypes,
  Radio,
  Stack,
  commonText,
  useStateProvider,
} from '@trueconnect/tp-components';
import FilterCell from '@trueconnect/tp-components/src/components/ExtendedSearch/FilterCell';
import { t } from 'i18next';
import classnames from 'classnames';

export const StatusSearch = () => {
  const { conversationFilter } = useStateProvider().state.conversation;
  const { setConversationType } = useStateProvider().actions;

  const statusOrder = ['All', 'Open', 'Closed'] as typeof ConversationTypes;

  return (
    <FilterCell
      title={t('conversation.extendedSearch.statusSearch.title')}
      dataTestId="extendedSearchStatusFilter"
    >
      <Stack className="w-full h-fit pb-4 gap-2 px-4">
        {statusOrder.map((item) => (
          <Stack
            key={item}
            direction="row"
            alignItems="center"
            className="w-full gap-2 cursor-pointer"
            dataTestid={`extendedState_${item}`}
            onClick={() => {
              setConversationType(item);
            }}
          >
            <Radio checked={conversationFilter === item} />
            <p className={classnames(commonText, 'text-sm', 'leading-normal')}>
              {t(`conversation.extendedSearch.statusSearch.${item}`)}
            </p>
          </Stack>
        ))}
      </Stack>
    </FilterCell>
  );
};

export default StatusSearch;
