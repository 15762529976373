/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function callMsGraph(
  url: string,
  accessToken: string,
  responseType?: string
) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
    responseType,
  };

  return fetch(url, options).then((response) => {
    const contentType = response.headers.get('content-type');
    if (contentType?.includes('json')) {
      return response.json();
    } else if (contentType?.startsWith('image')) {
      return response.blob();
    }
    return response.text();
  });
}
