import { loginRequest } from '../../authConfig';
import { useGetUserProfile, CustomAuthenticatedTemplate } from 'src/utils';
import {
  ConfigProvider,
  ErrorBoundary,
  HubListenerProvider,
  RequestInterceptor,
  SmartSpinnerLocal,
  UserProfileProvider,
  sortBranches,
  useStateProvider,
} from '@trueconnect/tp-components';
import { useMsal } from '@azure/msal-react';
import { Suspense } from 'react';
import { useApiQuery } from 'src/api';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useEffectOnce } from 'react-use';

type AppProvidersType = {
  children: React.ReactNode;
};

const serverUrl = `${process.env.REACT_APP_API_URL}/api/v1/brokerapplogic`;

export const AppProviders: React.FC<AppProvidersType> = ({ children }) => {
  const { instance } = useMsal();

  return (
    <Suspense>
      <ErrorBoundary
        refreshToken={async () => {
          await instance.loginRedirect({ ...loginRequest });
        }}
      >
        <DndProvider backend={HTML5Backend}>
          <CustomAuthenticatedTemplate>
            <RequestInterceptor loginRequest={loginRequest}>
              <ConfigProvider>
                <SecurityProvider>{children}</SecurityProvider>
              </ConfigProvider>
            </RequestInterceptor>
          </CustomAuthenticatedTemplate>
        </DndProvider>
      </ErrorBoundary>
    </Suspense>
  );
};

export const SecurityProvider: React.FC<AppProvidersType> = ({ children }) => {
  const { userProfile } = useGetUserProfile();

  return (
    <UserProfileProvider userProfile={userProfile}>
      <HubListenerProvider
        serverUrl={serverUrl}
        loginRequest={loginRequest}
        permissions={userProfile.permissions}
      >
        <BranchesProvider> {children}</BranchesProvider>
      </HubListenerProvider>
    </UserProfileProvider>
  );
};

export const BranchesProvider: React.FC<AppProvidersType> = ({ children }) => {
  const { actions } = useStateProvider();
  const { userProfile } = useGetUserProfile();
  const { setAvailableBranches, setAvailableTaskTypes } = actions;

  const { isLoading } = useApiQuery('getAvailableBranches', [], {
    onSuccess: (data) => {
      setAvailableBranches(sortBranches(data));
    },
  });

  const { isLoading: isTaskStatesLoading } = useApiQuery(
    'getAllTaskStates',
    [],
    {
      onSuccess: (data) => {
        setAvailableTaskTypes(data);
      },
      enabled: !!userProfile.userId,
    }
  );

  //refresh urls of cached images
  useEffectOnce(() => {
    sessionStorage.setItem('cachedImages', '{}');
  });

  return (
    <SmartSpinnerLocal
      name="getAvailableBranches"
      condition={isLoading || isTaskStatesLoading || !userProfile.userId}
      renderAsGlobal={true}
    >
      {children}
    </SmartSpinnerLocal>
  );
};

export default AppProviders;
