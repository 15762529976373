import { SidebarProps } from './SidebarProps';
import { Sidebar as SidebarFlowbite } from 'flowbite-react';
import React from 'react';
import FeatureFlags from '../../FeatureFlags';
import { useConfig } from './../../providers';
import { Item } from './Item';
import { SidebarStyles } from './../../styles';
import LanguageSwitcher from '../LanguageSwitcher';
import { environment } from './../../config';

export const Sidebar: React.FC<SidebarProps> = ({ groups, children }) => {
  const { configState } = useConfig();
  const { FullSidebar } = configState;

  return (
    <>
      <SidebarFlowbite
        className={SidebarStyles.wrapper(FullSidebar)}
        aria-label="Sidebar"
      >
        <SidebarFlowbite.Items>
          {groups.map((group, group_key) => (
            <SidebarFlowbite.ItemGroup key={group_key}>
              {group.map((item) => (
                <Item item={item} key={item.title} />
              ))}
            </SidebarFlowbite.ItemGroup>
          ))}
        </SidebarFlowbite.Items>
      </SidebarFlowbite>

      <div className="absolute bottom-2 left-0 p-1">
        {children}
        {environment && FeatureFlags.showChangeLanguage(environment) && (
          <LanguageSwitcher {...{ isFull: FullSidebar }} />
        )}
      </div>
    </>
  );
};

export default Sidebar;
