import { ChevronRightIcon } from '@heroicons/react/24/solid';
import IconButton from './../../../components/IconButton';
import { Stack } from './../../../controls';
import { hoverClassname } from './../../../styles';
import classnames from 'classnames';
import { t } from 'i18next';
import Avatar from './../../../components/Avatar';

const headerStyle = 'text-sm font-semibold leading-[21px]';

export interface FetchPolicyBrokerDto {
  id?: string;
  displayName?: string | undefined;
  email?: string | undefined;
  businessPhone?: string | undefined;
  imageId?: string | undefined;
  image?: string;
}

const imgClassnames =
  'min-h-8 min-w-8 [&>div]:h-8 [&>div]:w-8 [&>div>img]:h-8 [&>div>img]:w-8 !p-0 !m-0';

export const PolicyContacts = ({
  policyManager,
  accountManager,
  onClick,
  isMobileView,
}: {
  policyManager?: FetchPolicyBrokerDto;
  accountManager?: FetchPolicyBrokerDto;
  onClick?: (item: FetchPolicyBrokerDto) => void;
  isMobileView?: boolean;
}) => {
  if (!policyManager?.id && !accountManager?.id) return <></>;

  if (isMobileView) {
    return (
      <Stack
        dataTestid="PolicyContacts"
        direction="row"
        alignItems="center"
        justifyContent="around"
        className="bg-customColor_7 w-full px-5 py-1 mt-1 border-b"
      >
        {accountManager?.id && (
          <Stack
            direction="col"
            className={classnames(
              'w-full p-1 h-[70px]',
              onClick && hoverClassname
            )}
            onClick={() => {
              onClick?.(accountManager);
            }}
          >
            <p className={classnames(headerStyle, 'mb-1')}>
              {t('policyDetails.accountManager')}
            </p>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="between"
              className="w-full h-full [&>div>div]:ml-0 [&>div>div]:pl-0"
            >
              <div className="relative w-full h-full overflow-hidden">
                <div className="w-full h-full absolute">
                  <Avatar
                    title={accountManager?.displayName}
                    subtitle={accountManager?.businessPhone}
                    imgSrc={accountManager?.image}
                    classNames={imgClassnames}
                    titleStyles="pl-2"
                    subTitleStyles="pl-2 !mt-0"
                  />
                </div>
              </div>
              {onClick && (
                <IconButton hoverable={false} className="!m-0 !p-0 w-[20px]">
                  <ChevronRightIcon />
                </IconButton>
              )}
            </Stack>
          </Stack>
        )}
        {policyManager?.id && (
          <Stack
            direction="col"
            className={classnames(
              'w-full p-1 h-[70px]',
              onClick && hoverClassname
            )}
            onClick={() => {
              onClick?.(policyManager);
            }}
          >
            <p className={classnames(headerStyle, 'mb-1')}>
              {t('policyDetails.policyManager')}
            </p>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="between"
              className="w-full h-full [&>div>div]:ml-0 [&>div>div]:pl-0"
            >
              <div className="relative w-full h-full overflow-hidden">
                <div className="w-full h-full absolute">
                  <Avatar
                    title={policyManager?.displayName}
                    subtitle={policyManager?.businessPhone}
                    imgSrc={policyManager?.image}
                    classNames={imgClassnames}
                    titleStyles="pl-2"
                    subTitleStyles="pl-2 !mt-0"
                  />
                </div>
              </div>
              {onClick && (
                <IconButton hoverable={false} className="!m-0 !p-0 w-[20px]">
                  <ChevronRightIcon />
                </IconButton>
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  }

  return (
    <>
      {(accountManager?.id || policyManager?.id) && (
        <Stack
          dataTestid="PolicyContacts"
          direction="col"
          className="bg-customColor_7 w-[300px] p-4 h-full p-4 mt-2"
          justifyContent="between"
        >
          {accountManager?.id && (
            <Stack
              direction="col"
              className={classnames(
                'w-full p-1 h-[70px]',
                onClick && hoverClassname
              )}
              onClick={() => {
                onClick?.(accountManager);
              }}
            >
              <p className={classnames(headerStyle, 'mb-1')}>
                {t('policyDetails.accountManager')}
              </p>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="between"
                className="w-full h-full [&>div>div]:ml-0 [&>div>div]:pl-0"
              >
                <div className="relative w-full h-full overflow-hidden">
                  <div className="w-full h-full absolute">
                    <Avatar
                      title={accountManager?.displayName}
                      subtitle={accountManager?.businessPhone}
                      imgSrc={accountManager?.image}
                      classNames={imgClassnames}
                      titleStyles="pl-2"
                      subTitleStyles="pl-2 !mt-0"
                    />
                  </div>
                </div>
                {onClick && (
                  <IconButton hoverable={false} className="!m-0 !p-0 w-[20px]">
                    <ChevronRightIcon />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          )}
          {policyManager?.id && (
            <Stack
              direction="col"
              className={classnames(
                'w-full p-1 h-[70px]',
                onClick && hoverClassname
              )}
              onClick={() => {
                onClick?.(policyManager);
              }}
            >
              <p className={classnames(headerStyle, 'mb-1')}>
                {t('policyDetails.policyManager')}
              </p>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="between"
                className="w-full h-full [&>div>div]:ml-0 [&>div>div]:pl-0"
              >
                <div className="relative w-full h-full overflow-hidden">
                  <div className="w-full h-full absolute">
                    <Avatar
                      title={policyManager?.displayName}
                      subtitle={policyManager?.businessPhone}
                      imgSrc={policyManager?.image}
                      classNames={imgClassnames}
                      titleStyles="pl-2"
                      subTitleStyles="pl-2 !mt-0"
                    />
                  </div>
                </div>
                {onClick && (
                  <IconButton hoverable={false} className="!m-0 !p-0 w-[20px]">
                    <ChevronRightIcon />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
};

export default PolicyContacts;
