import { PaperClipIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid';
import { IconButton, FitContentTextArea } from './../../../components';
import { Stack } from './../../../controls';
import classnames from 'classnames';
import styles from './Chat.module.scss';
import { KeyboardEvent } from 'react';
import { useStateProvider } from './../../../providers';
import { chatStyles } from './../../../styles';
import { t } from 'i18next';
import EmojiPicker from './EmojiPicker';
import { setUnsentMessagesText, useIsSelectedView } from './../../../utils';

type ChatBottomProps = {
  onKeyDown: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  handleSend: () => void;
  className: string;
};

export const ChatBottom = (props: ChatBottomProps) => {
  const { onKeyDown, handleSend } = props;
  const { state, actions } = useStateProvider();

  const { canSendMessage, message } = state.chat;
  const { setIsUploadPanelVisible, setMessage } = actions;

  const { readOnly, disableUpload, conversationId } =
    useStateProvider().state.conversation.conversationData;
  const isMobileView = useIsSelectedView('sm');

  return (
    <Stack
      className={classnames(
        chatStyles.bottom.style,
        isMobileView && 'px-4',
        props.className
      )}
    >
      <Stack
        className={chatStyles.bottom.wrapper}
        alignItems="center"
        justifyContent="center"
        direction="row"
      >
        <EmojiPicker />

        <IconButton
          className={classnames('h-8 ', disableUpload && '!cursor-default')}
          onClick={() => {
            !disableUpload && setIsUploadPanelVisible(true);
          }}
          disabled={disableUpload}
        >
          <PaperClipIcon data-testid="uploadIcon" />
        </IconButton>

        <div className="w-full relative h-[62px]">
          <FitContentTextArea
            limit={200}
            value={message}
            disabled={!canSendMessage || readOnly}
            className={styles.input}
            onKeyDown={onKeyDown}
            onChange={(e) => {
              if (!readOnly) {
                setMessage(e.target.value);
                setUnsentMessagesText(conversationId, e.target.value || '');
              }
            }}
            placeholder={
              readOnly ? t('conversation.chat.placeholder') || '' : ''
            }
            data-testid="messageInput"
          />
          {!message && !isMobileView && (
            <Stack
              className="absolute right-4 h-full"
              direction="row"
              alignItems="center"
            >
              <Stack
                className="bg-customColor_2 h-[22px] mb-[2px] py-[2px] rounded-md px-[10px]"
                direction="row"
                alignItems="center"
              >
                <IconButton
                  hoverable={false}
                  customSize="h-[14px] w-[14px]"
                  className="text-[#6B7280] mr-1"
                >
                  <PaperAirplaneIcon />
                </IconButton>
                <p className="text-[#6B7280] text-xs font-medium select-none leading-normal">
                  ctrl + Enter
                </p>
              </Stack>
            </Stack>
          )}
        </div>
        <IconButton
          className={classnames(readOnly && '!cursor-default')}
          disabled={!message.trim() || !canSendMessage || readOnly}
          onClick={() => {
            !readOnly && handleSend();
          }}
        >
          <PaperAirplaneIcon data-testid="sendMessageIcon" />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default ChatBottom;
