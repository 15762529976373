import {
  HelpIcon,
  SettingsIcon,
  UserPage as UserPageCommon,
  listItem,
} from '@trueconnect/tp-components';
import { useNavigate } from 'react-router-dom';

export const UserPage = () => {
  const navigate = useNavigate();
  const sidebarItems: listItem[] = [
    {
      icon: <SettingsIcon color="black" />,
      textKey: 'sidebar.settings',
      onClick: () => {
        navigate('/settings');
      },
    },
    {
      icon: <HelpIcon color="black" />,
      textKey: 'sidebar.help',
      onClick: () => {
        navigate('/help');
      },
    },
  ];

  return <UserPageCommon {...{ sidebarItems }} />;
};

export default UserPage;
