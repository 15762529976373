import React from 'react';
import { Notification, NotificationType } from './Notification';
import { useStateProvider } from './../StateProvider';

export type NotificationSystemProps = {
  children: React.ReactNode;
};

export const useNotificationSystem = () => {
  return {
    addNotification: useStateProvider().actions.AddNotification as (
      toAdd: NotificationType
    ) => void,
  };
};

export const NotificationSystem: React.FC<NotificationSystemProps> = ({
  children,
}) => {
  const { state, actions } = useStateProvider();

  const onDismiss = (id: string) => {
    actions.RemoveNotification(id);
  };

  return (
    <>
      {children}
      <div className="absolute top-[70px] right-0 z-50">
        {state.notifications.notifications.map((notification) => (
          <Notification
            key={notification.id}
            {...notification}
            onDismiss={onDismiss}
          />
        ))}
      </div>
    </>
  );
};

export default NotificationSystem;
