import { DocumentState, documentType } from './typing';

type DocumentAction =
  | { type: 'SET_IN_ACTION'; payload: string[] }
  | { type: 'ADD_TO_ACTION'; payload: string }
  | { type: 'REMOVE_FROM_ACTION'; payload: string }
  | { type: 'TOGGLE_DELETE_MODAL'; payload: boolean }
  | { type: 'SELECT_DOCUMENT'; payload: documentType };

export const initialDocumentState: DocumentState = {
  inAction: [],
  showDeleteModal: false,
  selectedDocument: {},
};

const documentReducer = (
  state: DocumentState = initialDocumentState,
  action: DocumentAction
): DocumentState => {
  switch (action.type) {
    case 'ADD_TO_ACTION':
      if (state.inAction.some((item) => item === action.payload)) return state;
      else {
        return {
          ...state,
          inAction: [...state.inAction, action.payload],
        };
      }
    case 'SET_IN_ACTION':
      return { ...state, inAction: action.payload };
    case 'TOGGLE_DELETE_MODAL':
      return { ...state, showDeleteModal: action.payload };
    case 'SELECT_DOCUMENT':
      return { ...state, selectedDocument: action.payload };
    case 'REMOVE_FROM_ACTION':
      return {
        ...state,
        inAction: state.inAction.filter((item) => item !== action.payload),
      };
    default:
      return state;
  }
};

export { documentReducer };
export type { DocumentAction };
export type { DocumentState };
