import {
  NotificationImage,
  SearchInput,
  Stack,
  TaskType,
  searchInputStyles,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import { SearchOrderBy, useApiInfiniteQuery } from 'src/api';
import { BacklogTable } from 'src/pages/App/Tasks/Tabs/Backlog/BacklogTable';
import { useGetColumns } from 'src/pages/App/Tasks/Tabs/Backlog/columns';
import classnames from 'classnames';

export const TasksTab = ({
  id,
  searchParam,
  setSearchParam,
}: {
  id?: number;
  searchParam?: string;
  setSearchParam: Dispatch<SetStateAction<string>>;
}) => {
  const [orderBy, setOrderBy] = useState<SearchOrderBy | undefined>(undefined);
  const columns = useGetColumns({ setOrderBy, orderBy });
  const tasksQuery = useApiInfiniteQuery(
    'searchTasks',
    [
      {
        title: searchParam,
        orderBy,
        page: 0,
        customerIds: id ? [id] : [],
      },
    ],
    {
      select: (data) => [
        ...data.pages.flatMap((item) =>
          item.map((subitem) => subitem as TaskType)
        ),
      ],
    }
  );

  const finalColumns = columns.filter(
    (col) => !['actions', 'customer'].includes(col.dataPropName)
  );
  const isMobileView = useIsSelectedView('sm');

  const tasks = tasksQuery.data?.flat(1);
  if (!(tasks || []).length && !tasksQuery.isLoading) {
    return <NotificationImage text={t('Tasks.nothingFound')} />;
  }

  return (
    <Stack className="w-full h-full relative overflow-hidden">
      {isMobileView && (
        <SearchInput
          setSearchParam={setSearchParam}
          classNames={{
            wrapper: classnames(searchInputStyles, '!border-none !min-h-16'),
          }}
          dataTestid="customerPageSearch"
          placeholder={t(`tab.tasks.Searchplaceholder`)}
          isMobileView={true}
        />
      )}
      <BacklogTable
        {...{
          tasksQuery,
          columns: finalColumns,
        }}
      />
    </Stack>
  );
};

export default TasksTab;
