import {
  Stack,
  textStyle_sm,
  branchType,
  useStateProvider,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import classnames from 'classnames';
import BranchBadge from './BranchBadge';

export type supportedTypes = 'conversation' | 'topic';

export const BranchesSelectionComponent = ({
  type,
  selectedBranches = [],
  setSelectedBranches,
}: {
  type: supportedTypes;
  selectedBranches?: branchType[];
  setSelectedBranches: (value: branchType[]) => void;
}) => {
  const { state } = useStateProvider();
  const { availableBranches, notEditableBranches } = state.workManagement;

  const addToSelected = (item: branchType) => {
    if (!selectedBranches.some((selectedItem) => selectedItem.id === item.id)) {
      setSelectedBranches([...selectedBranches, item]);
    }
  };

  const removeFromSelected = (item: branchType) => {
    const updatedSelectedBranches = selectedBranches.filter(
      (selectedItem) => selectedItem.id !== item.id
    );
    setSelectedBranches(updatedSelectedBranches);
  };

  return (
    <>
      {!!notEditableBranches.length && (
        <Stack
          direction="row"
          className="w-full flex-wrap mt-5 gap-2"
          dataTestid="selectedBranchesContainer"
        >
          <p className={classnames(textStyle_sm, '!mb-0')}>
            {t('branchesSelectionQuestion.childrenBranches')}
          </p>
          <Stack
            direction="row"
            className="w-full flex-wrap gap-2 pb-3 border-b"
          >
            {notEditableBranches.map((item) =>
              item && item.id ? (
                <BranchBadge
                  key={item.id}
                  dataTestId={item.id}
                  classNames="notEditableBranchItem"
                  {...{
                    item,
                    selectedName: 'convenientName',
                  }}
                />
              ) : (
                <></>
              )
            )}
          </Stack>
        </Stack>
      )}
      {!!selectedBranches.length && (
        <>
          <p className={classnames(textStyle_sm, 'mt-3 !mb-0')}>
            {t('branchesSelectionQuestion.selectedBranches')}
          </p>
          <Stack
            direction="row"
            className="w-full flex-wrap mt-2 gap-2 pb-3 border-b"
            dataTestid="selectedBranchesContainer"
          >
            {selectedBranches.map((item) =>
              item && item.id ? (
                <BranchBadge
                  key={item.id}
                  dataTestId={item.id}
                  classNames="selectedBranchItem"
                  {...{
                    item,
                    onClick: removeFromSelected,
                    selectedName: 'convenientName',
                    isRemove: true,
                  }}
                />
              ) : (
                <></>
              )
            )}
          </Stack>
        </>
      )}
      <h1 className={classnames(textStyle_sm, 'pt-6 pb-4 !mb-0')}>
        {t(`branchesSelectionQuestion.${type}`)}
      </h1>
      <Stack
        direction="row"
        className="w-full flex-wrap gap-2"
        dataTestid="availableBranchesContainer"
      >
        {availableBranches
          .filter(
            (branch) =>
              !notEditableBranches.map((item) => item.id).includes(branch.id)
          )
          .filter(
            (item) =>
              !selectedBranches.some(
                (selectedItem) => selectedItem.id === item.id
              )
          )
          .map((item) => (
            <BranchBadge
              key={item.id}
              dataTestId={item.id}
              classNames="availableBranchItem"
              {...{
                item,
                onClick: addToSelected,
                selectedName: 'convenientName',
              }}
            />
          ))}
      </Stack>
    </>
  );
};

export default BranchesSelectionComponent;
