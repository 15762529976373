const urlToEnviromentMapping = {
  test: /(.*)-test\.truepartners\.ch$/,
  production: /(.*)\.truepartners\.ch$/,
  local: /(.*)\.local$/,
  development: /^localhost/,
};

export type Environments = keyof typeof urlToEnviromentMapping;

export const environment = Object.entries(urlToEnviromentMapping).find(
  ([, url]) => url.test(window.location.host)
)?.[0] as Environments | undefined;

export const config: {
  environment: Environments | undefined;
} = {
  environment,
};

export default config;
