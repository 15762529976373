export interface FileResponse {
  data: Blob;
  status: number;
  fileName?: string;
  headers?: { [name: string]: any };
}

export const getImage = async ({
  imageId,
  getBlob,
}: {
  imageId?: string | undefined;
  getBlob: (s: string) => Promise<FileResponse>;
}) => {
  // If no image ID provided, return an empty string
  if (!imageId) return '';

  try {
    // Retrieve cached images from sessionStorage
    const cachedImagesString = sessionStorage.getItem('cachedImages');
    // Parse cached images from string to object, or initialize an empty object if no cached images found
    const cachedImages = cachedImagesString
      ? JSON.parse(cachedImagesString)
      : {};

    // If the requested image is already cached, return its URL from the cache
    if (cachedImages[imageId]) {
      return cachedImages[imageId];
    }

    if (cachedImages[imageId] === '') {
      return '';
    }

    // Fetch the image blob using the provided getBlob function
    const fileResponse = await getBlob(imageId);
    // Create a URL for the fetched blob
    const imageUrl = URL.createObjectURL(fileResponse.data);

    // Update the cached images in sessionStorage
    sessionStorage.setItem(
      'cachedImages',
      JSON.stringify({
        ...JSON.parse(sessionStorage.getItem('cachedImages') || '{}'),
        [imageId]: imageUrl, // Use imageId as the key to store imageUrl
      })
    );

    // Return the URL of the fetched image
    return imageUrl;
  } catch (error) {
    // If an error occurs during image fetching or caching, return an empty string

    sessionStorage.setItem(
      'cachedImages',
      JSON.stringify({
        ...JSON.parse(sessionStorage.getItem('cachedImages') || '{}'),
        [imageId]: '',
      })
    );

    return '';
  }
};
