import { useStateProvider } from './../../../providers';
import { hoverClassname } from './../../../styles';
import classnames from 'classnames';
import { Stack } from './../../../controls';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { IconButton, VirtualizedList } from '../../../components';
import { FileWithPath } from 'react-dropzone';

export type FilesToUploadType = {
  primaryView?: boolean;
};
//primaryView true - user can remove file from upload list, name displayed
//primaryView false - user cant remove file from upload list, path displayed
export const FilesToUploadTable = ({
  primaryView = true,
}: FilesToUploadType) => {
  const { filesToUpload } = useStateProvider().state.uploads;
  const { setFilesToUpload } = useStateProvider().actions;

  const rowRenderer = (file: FileWithPath) => {
    const removeFile = (path?: string) => {
      setFilesToUpload(filesToUpload.filter((item) => item.path !== path));
    };

    return (
      <div className={classnames(hoverClassname, 'w-full h-full pt-2')}>
        <Stack
          direction="row"
          alignItems="center"
          className="overflow-hidden"
          onClick={() => primaryView && removeFile(file.path)}
        >
          <>
            <IconButton hoverable={false}>
              <XMarkIcon />
            </IconButton>
            <div className="truncate">{file.name}</div>
          </>
        </Stack>
      </div>
    );
  };

  return (
    <>
      {!!filesToUpload.length && (
        <div className="w-full h-full pb-2">
          <VirtualizedList
            {...{
              rowHeight: 42,
              headerHeight: 0,
              infinityQuery: {
                data: filesToUpload,
                isLoading: false,
              },
              classNames: {
                row: '!border-b border-silver-200',
              },
              columns: [
                {
                  render: rowRenderer,
                  dataPropName: 'item',
                },
              ],
            }}
          />
        </div>
      )}
    </>
  );
};

export default FilesToUploadTable;
