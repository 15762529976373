import React, { useContext } from 'react';
import {
  ConfigActions,
  ConfigContextValue,
  ConfigState,
  configActions,
  configReducer,
  payloadType,
} from './reducers';
import { useLocalStorage } from 'react-use';

const defaultState = { FullSidebar: true };

const ConfigContext = React.createContext<ConfigContextValue>({
  configState: defaultState,
  configActions: configActions,
});

export const useConfig = () => useContext(ConfigContext);

export function ConfigProvider({ children }: { children: React.ReactNode }) {
  const [config, setConfig] = useLocalStorage<ConfigState>(
    'config',
    defaultState
  );
  const [configState, configDispatch] = React.useReducer(
    configReducer,
    config as ConfigState
  );

  const actions: ConfigActions = Object.entries(configActions).reduce(
    (acc, [key, actionFn]) => ({
      ...acc,
      [key]: (payload?: payloadType) => {
        const updatedState = configReducer(configState, actionFn(payload));
        configDispatch(actionFn(payload));
        setConfig(updatedState);
      },
    }),
    {}
  );

  return (
    <ConfigContext.Provider value={{ configState, configActions: actions }}>
      {children}
    </ConfigContext.Provider>
  );
}
