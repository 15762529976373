import {
  Column as _Column,
  Table as _Table,
  TableProps,
  ColumnProps,
} from 'react-virtualized';
import VirtualizedComponent from './VirtualizedComponent';
import 'react-virtualized/styles.css';
import styles from './VirtualizedComponent.module.scss';
import VirtualizedColumnType from './VirtualizedColumnType';
import classnames from 'classnames';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import {
  VirtualizedComponentHeader,
  virtualizedColumnClassname,
  virtualizedRowClassname,
} from './../../styles';

const Table = _Table as unknown as React.FC<TableProps>;
const Column = _Column as unknown as React.FC<ColumnProps>;

export type RowClickHandlerType<TData> = (item: TData) => void;

export type classNamesType = {
  header?: string;
  grid?: string;
  row?: string;
  column?: string;
  content?: string;
  lastRow?: string;
  container?: string;
};

export type VirtualizedTableProps<TData, TError> = {
  columns: VirtualizedColumnType<TData>[];
  rowHeight?: number;
  headerHeight?: number;
  infinityQuery: UseInfiniteQueryResult<TData[], TError>;
  onRowClick?: RowClickHandlerType<TData>;
  nothingFound?: string | JSX.Element;
  nothingFoundWrapper?: string;
  nothingFoundClassnames?: string;
  classNames?: classNamesType;
  isScrolling?: boolean | undefined;
  shouldHaveNextPage?: boolean;
};

export function VirtualizedTable<TData, TError>(
  props: VirtualizedTableProps<TData, TError>
) {
  const {
    rowHeight = 70,
    columns,
    infinityQuery,
    headerHeight = 40,
    onRowClick = () => ({}),
    nothingFound,
    nothingFoundWrapper,
    nothingFoundClassnames,
    classNames,
    isScrolling,
    shouldHaveNextPage = true,
  } = props;
  return (
    <VirtualizedComponent
      infinityQuery={infinityQuery}
      nothingFound={nothingFound}
      nothingFoundWrapper={nothingFoundWrapper}
      nothingFoundClassnames={nothingFoundClassnames}
      headerHeight={headerHeight}
      classNames={classNames?.content}
      shouldHaveNextPage={shouldHaveNextPage}
      createChildren={({
        width = 900,
        height = 600,
        onRowsRendered,
        registerChild,
        elements,
      }) => {
        return (
          <Table
            headerHeight={headerHeight}
            onRowsRendered={onRowsRendered}
            ref={registerChild}
            height={height - 1}
            rowCount={elements.length}
            headerClassName={classnames(
              VirtualizedComponentHeader,
              classNames?.header || '',
              '!border-none',
              `h-[${headerHeight}px]`
            )}
            rowHeight={rowHeight}
            width={width}
            rowGetter={({ index }) => elements[index]}
            gridClassName={classnames(styles.content, classNames?.grid || '')}
            rowClassName={classnames(
              virtualizedRowClassname,
              classNames?.row || ''
            )}
            onRowClick={(idx) => {
              onRowClick(elements[idx.index]);
            }}
            isScrolling={isScrolling}
          >
            {columns?.map((column) => (
              <Column
                key={column.dataPropName}
                label={column.title}
                dataKey={column.dataPropName}
                width={width * (column.widthRatio || 1)}
                cellRenderer={(cellData) =>
                  column.render(elements[cellData.rowIndex])
                }
                style={{ flexBasis: 'auto' }}
                className={classnames(
                  classNames?.column || '',
                  styles.column,
                  virtualizedColumnClassname,
                  column.className
                )}
                headerStyle={{ margin: 0 }} //to override custom styles
              />
            ))}
          </Table>
        );
      }}
    />
  );
}

export default VirtualizedTable;
