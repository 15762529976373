import { branchType, useStateProvider } from './../../../providers';
import FilterCell from '../FilterCell';
import { t } from 'i18next';
import { SearchInput, Stack } from './../../../controls';
import { CheckBox } from './../../../components/CheckBox';
import { commonText, bigScrollbar } from './../../../styles';
import classnames from 'classnames';
import { useState } from 'react';

export const BranchSearch = ({
  selectedBranches = [],
  setSelectedBranches,
}: {
  selectedBranches?: string[];
  setSelectedBranches?: (branches: branchType) => void;
}) => {
  const { state } = useStateProvider();
  const { availableBranches } = state.workManagement;
  const [searchParam, setSearchParam] = useState('');

  const filteredBranches = availableBranches.filter((branch) =>
    branch.name?.toLowerCase().includes(searchParam.toLowerCase())
  );

  return (
    <FilterCell
      title={t('conversation.extendedSearch.branchSearch.title')}
      dataTestId="extendedBranchStatusFilter"
    >
      <div className="px-4 w-full">
        <SearchInput
          isMobileView={true}
          classNames={{
            wrapper: 'bg-white',
            input: 'bg-white text-sm',
            icon: 'mx-0 px-0 [&>div]:m-0 !w-7',
          }}
          setSearchParam={setSearchParam}
          placeholder={
            t(
              'conversation.extendedSearch.contactSearch.inputPlaceholder'
            ) as string
          }
          dataTestid="extendedSearchContactInput"
        />
      </div>
      <Stack
        direction="col"
        className={classnames(
          'w-full h-full overflow-y-scroll px-3',
          bigScrollbar
        )}
        isResizable={true}
        resizeDirection="ver"
        resizeProps={{
          width: -1,
          height: 180,
          resizeHandles: ['s'],
          minConstraints: [-1, 140],
          maxConstraints: [-1, 380],
        }}
      >
        {filteredBranches.map((item) => (
          <Stack
            key={item.id}
            direction="row"
            className="cursor-pointer"
            alignItems="center"
            onClick={() => {
              setSelectedBranches?.(item);
            }}
          >
            <CheckBox
              className="BranchSearchFilter"
              dataTestId={item.id}
              containerClassname="!w-7 !px-1"
              checked={!!item.id && selectedBranches.includes(item.id)}
              onChange={() => ({})}
            />
            <p className={classnames(commonText, 'text-sm !text-[#4B5563]')}>
              {item.name}
            </p>
          </Stack>
        ))}
      </Stack>
    </FilterCell>
  );
};

export default BranchSearch;
