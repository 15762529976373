import {
  ChevronDownIcon,
  ChevronUpIcon,
  IconButton,
  Stack,
  VirtualizedList,
  XCircleIcon,
  useIsSelectedView,
  useUserProfile,
} from '@trueconnect/tp-components';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useApiInfiniteQuery } from 'src/api';
import classnames from 'classnames';
import { useClickAway } from 'react-use';
import { deputyType } from './typing';

export const inputStyle =
  '!border !border-gray-200 rounded-lg p-2 !h-10 !w-full focus:outline-none focus:border-gray-400';

export const savedData =
  '!border-green-500 !placeholder-green-500 !text-green-500';

export const DeputySearch = ({
  selectedDeputy,
  setSelectedDeputy,
  savedDeputyUserId,
}: {
  selectedDeputy: deputyType;
  setSelectedDeputy: Dispatch<SetStateAction<deputyType>>;
  savedDeputyUserId: string | undefined;
}) => {
  const [searchParam, setSearchParam] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const isMobileView = useIsSelectedView('sm');
  const ref = useRef<HTMLDivElement>(null);
  const { userId } = useUserProfile();

  useClickAway(ref, () => {
    setShowDropdown(false);
    selectedDeputy.displayName && setSearchParam('');
  });

  const queryBrokers = useApiInfiniteQuery('searchBrokers', [searchParam, 0], {
    select: (data) =>
      data.pages.flatMap((item) =>
        item
          .map((subitem) => ({
            id: subitem.id,
            displayName: subitem.displayName,
            email: subitem.email,
          }))
          .filter((item) => item.id != userId)
          .sort((a, b) => {
            const nameA = a.displayName || '';
            const nameB = b.displayName || '';

            return nameA.localeCompare(nameB);
          })
      ),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: showDropdown,
  });

  return (
    <div ref={ref}>
      <Stack
        className={classnames(
          inputStyle,
          savedDeputyUserId == selectedDeputy.id &&
            !!savedDeputyUserId &&
            !searchParam &&
            savedData,
          'w-full h-[42px]'
        )}
        direction="row"
        alignItems="center"
        justifyContent="between"
        onClick={() => {
          setShowDropdown(true);
        }}
      >
        <input
          value={searchParam}
          className={classnames(
            'w-full focus:outline-none text-sm',
            savedDeputyUserId == selectedDeputy.id &&
              !!savedDeputyUserId &&
              !searchParam &&
              savedData
          )}
          placeholder={selectedDeputy.displayName}
          onChange={(e) => setSearchParam(e.target.value)}
        />
        <Stack direction="row" alignItems="center">
          {searchParam && (
            <IconButton
              onClick={() => {
                setSearchParam('');
              }}
            >
              <XCircleIcon />
            </IconButton>
          )}
          <IconButton>
            {showDropdown ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </IconButton>
        </Stack>
      </Stack>
      {showDropdown && (
        <div className="!absolute flex flex-col z-10 !top-[80px] bg-white !left-0 w-full p-1 border">
          <Stack
            className={classnames(
              'w-full',
              isMobileView ? 'h-[200px]' : 'h-[400px]'
            )}
          >
            <VirtualizedList
              columns={[
                {
                  dataPropName: 'name',
                  render: (item) => (
                    <Stack
                      className={'w-full h-full cursor-pointer pl-4'}
                      justifyContent="center"
                      onClick={() => {
                        setSelectedDeputy(item);
                        setSearchParam('');
                        setShowDropdown(false);
                      }}
                    >
                      <h1>{item.displayName}</h1>
                    </Stack>
                  ),
                },
              ]}
              headerHeight={0}
              infinityQuery={queryBrokers}
              rowHeight={50}
              onRowClick={(item) => {
                setSelectedDeputy(item);
              }}
            />
          </Stack>
        </div>
      )}
    </div>
  );
};

export default DeputySearch;
