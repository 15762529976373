import { NavBurgerIcon } from '../../images';
import { IconButton } from '../../components';
import { Navbar } from 'flowbite-react';
import { useConfig, useStateProvider } from './../../providers';
import Stack from '../Stack';
import EnvBadge from './EnvBadge';
import { NavbarStyles } from './../../styles';
import { useIsSelectedRange, useIsSelectedView } from './../../utils';

export type NavbarProps = {
  onShowSidebar?: () => void;
  logos: React.ReactNode[];
  children?: React.ReactNode | React.ReactNode[];
};

export const NavbarLayout: React.FC<NavbarProps> = ({ logos, children }) => {
  const { configActions } = useConfig();
  const { showNavbar } = useStateProvider().state.extra;

  const isSelectedView = useIsSelectedRange(['sm', 'md', 'lg']);
  const isMobileView = useIsSelectedView('sm');

  return (
    <Navbar
      fluid={true}
      rounded={false}
      data-testid="Navbar"
      className={NavbarStyles.wrapper(isMobileView, showNavbar)}
    >
      <Stack
        direction="row"
        className="w-full"
        alignItems="center"
        justifyContent="between"
      >
        <Stack direction="row">
          {!isSelectedView && (
            <IconButton
              size="lg"
              className="-ml-1"
              imgClassName="h-full w-full"
              onClick={configActions.toggleSidebar}
            >
              <NavBurgerIcon />
            </IconButton>
          )}
          {logos.map((logo) => logo)}
          <EnvBadge />
        </Stack>
        {children}
      </Stack>
    </Navbar>
  );
};
export default Navbar;
