import { DocumentCellStyle } from '../../../styles';
import { IDocumentType } from '../DocumentType';

export const ModifiedBy: React.FC<IDocumentType> = (item: IDocumentType) => {
  return (
    <div className={DocumentCellStyle}>
      <p className="text-customColor_6 font-medium">
        {item.modifiedBy === 'SharePoint App' ? 'TrueConnect' : item.modifiedBy}
      </p>
    </div>
  );
};

export default ModifiedBy;
