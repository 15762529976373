import {
  ContactStatus,
  IconButton,
  Stack,
  statusBadgeBody,
  statusBadgeStyles,
  useStateProvider,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import { Badge } from 'flowbite-react';
import { states } from './states';
import classnames from 'classnames';

type StatusBadgeProps = {
  status?: ContactStatus;
  text?: string;
  showBackground?: boolean;
  email: string;
  contactId: number;
  displayName?: string;
  customerId?: number;
  classNames?: string;
  readOnly?: boolean;
  showFullText?: boolean;
  hoverable?: boolean;
};

export const StatusBadge: React.FC<StatusBadgeProps> = ({
  status = ContactStatus.NotInvited,
  text,
  showBackground = true,
  email,
  contactId,
  customerId,
  displayName,
  classNames = '',
  readOnly = false,
  showFullText = true,
  hoverable = true,
}) => {
  const { t } = useTranslation();
  const { setCustomerData, setToggleCustomerModal } =
    useStateProvider().actions;

  const { color, icon, hover } = states[status];

  const textColor = `text-${color}`;

  const handleClick = () => {
    if (status !== ContactStatus.Active) {
      customerId &&
        displayName &&
        setCustomerData({ contactId, email, status, customerId, displayName });
      setToggleCustomerModal(true);
    }
  };

  return (
    <Badge
      color={showBackground ? color : ''}
      onClick={(e) => {
        e.stopPropagation();
        !readOnly && handleClick();
      }}
      className={classnames(
        statusBadgeBody(showBackground, color, hoverable ? hover : ''),
        classNames
      )}
    >
      <Stack direction="row" alignItems="center" className="p-1">
        <IconButton
          customSize="w-4 h-4"
          imgClassName={classnames(textColor, 'w-4 h-4')}
          hoverable={!readOnly}
          disabled={readOnly}
        >
          {icon}
        </IconButton>
        <p
          className={classnames(
            statusBadgeStyles.statusText,
            `statusBadge.${status}`
          )}
        >
          {text || (showFullText && t(`statusBadge.${status}`))}
        </p>
      </Stack>
    </Badge>
  );
};

export default StatusBadge;
