import { Button } from '../../../components';
import Modal from '../../Modal';
import { useTranslation } from 'react-i18next';
import Stack from './../../../controls/Stack';
import { useWindowSize } from 'react-use';
import TextInput from './../../../components/TextInput';

interface CreateNewFolderDialogProps {
  create: () => void;
  name: string;
  setName: (name: string) => void;
  visible: boolean;
  close: () => void;
}

export const CreateNewFolderDialog: React.FC<CreateNewFolderDialogProps> = ({
  create,
  name,
  setName,
  visible,
  close,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isMobileView = width < 400;

  return (
    <Modal
      show={visible}
      size="md"
      title={t('file.dialog.new.folder.title')}
      footer={
        <Stack
          direction={isMobileView ? 'col' : 'row'}
          className="w-full gap-2"
        >
          <Button
            className={isMobileView ? 'w-full' : 'w-1/2'}
            color="info"
            onClick={create}
            disabled={!name}
            dataTestid="createFolderAccept"
          >
            <p className="truncate">{t('file.action.create.text')}</p>
          </Button>
          <Button
            className={isMobileView ? 'w-full' : 'w-1/2'}
            color="gray"
            onClick={close}
            dataTestid="createFolderCancel"
          >
            <p className="truncate">{t('common.cancel')}</p>
          </Button>
        </Stack>
      }
      onClose={close}
    >
      <TextInput
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        data-testid="createFolderNameInput"
      />
    </Modal>
  );
};

export default CreateNewFolderDialog;
