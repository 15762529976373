import {
  BranchBadge,
  Stack,
  branchType,
  bigScrollbar,
  useStateProvider,
  ToggleSwitch,
} from '@trueconnect/tp-components';
import classnames from 'classnames';

export const PolicyBranchSelection = ({
  selected,
  addToSelected,
  selectAll,
  setSelectAll,
}: {
  selected: string[];
  addToSelected: (item: branchType) => void;
  selectAll: boolean;
  setSelectAll: (value: boolean) => void;
}) => {
  const { availableBranches } = useStateProvider().state.workManagement;

  return (
    <Stack
      direction="col"
      className={classnames(
        'overflow-y-auto w-full pl-[22px] gap-2',
        bigScrollbar
      )}
    >
      <Stack
        direction="row"
        className="py-3 w-full border-b"
        justifyContent="between"
        alignItems="center"
        onClick={() => {
          setSelectAll(!selectAll);
        }}
      >
        <p>All</p>
        <ToggleSwitch
          data-testid={`selectAll_switch`}
          checked={selectAll}
          label={''}
          onChange={() => ({})}
        />
      </Stack>
      {availableBranches.map((item) => (
        <Stack
          direction="row"
          key={item.id}
          className="py-3 w-full border-b"
          justifyContent="between"
          alignItems="center"
          onClick={() => {
            !selectAll && addToSelected(item);
          }}
        >
          <BranchBadge
            key={item.id}
            dataTestId={item.id}
            classNames="availableBranchItem"
            {...{
              item,
              selectedName: 'convenientName',
            }}
          />
          <ToggleSwitch
            data-testid={`${item.shortName}_switch`}
            checked={(!!item.id && selected.includes(item.id)) || selectAll}
            label={''}
            disabled={!!selectAll}
            onChange={() => ({})}
            className={classnames(
              selectAll && '[&>div]:bg-gray-300 !cursor-default'
            )}
          />
        </Stack>
      ))}
    </Stack>
  );
};

export default PolicyBranchSelection;
