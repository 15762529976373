import {
  Button,
  Stack,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { t } from 'i18next';
import { useCallback, useState } from 'react';
import {
  useApiMutation,
  useApiInvalidateQueries,
  UpdateInvoiceDto,
} from 'src/api';
import AlreadyReadInvoiceConfirmation from './AlreadyReadInvoiceConfirmation';

export const ResetInvoice = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const { state, actions } = useStateProvider();
  const { invoiceModalType, selectedInvoice } = state.customer;
  const { setInvoiceModalType, setSelectedInvoice } = actions;
  const { addNotification } = useNotificationSystem();
  const invalidateQueries = useApiInvalidateQueries();
  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('getInvoicesByPolicyId');
  }, [invalidateQueries]);

  const { mutate, isLoading } = useApiMutation('updateInvoice', {
    onSuccess: () => {
      setInvoiceModalType('');
      setSelectedInvoice({});
      invalidateQuery();
    },
    onError: () => {
      addNotification({
        title: t('policyDetails.editInvoice.deleteError'),
        color: 'failure',
      });
    },
  });

  const updateInvoice = () => {
    mutate([
      {
        amount: selectedInvoice?.amount,
        documentId: selectedInvoice?.documentId,
        id: selectedInvoice?.id,
        policyRewardTypeId: selectedInvoice?.policyRewardTypeId,
        isConfirmed: false,
        notificationText: '',
      } as UpdateInvoiceDto,
    ]);
  };

  const onClick = () => {
    if (selectedInvoice?.firstTimeDownloadedUtc) {
      setShowConfirm(true);
    } else {
      updateInvoice();
    }
  };

  return (
    <>
      <Modal
        show={invoiceModalType == 'reset'}
        className="[&>div>div>div:nth-child(3)]:border-none"
        title={t('policyDetails.resetInvoice.header')}
        onClose={() => {
          setInvoiceModalType('');
          setSelectedInvoice({});
        }}
        footer={
          <>
            <Button
              className="w-1/2"
              variant="secondary"
              onClick={() => {
                setInvoiceModalType('');
                setSelectedInvoice({});
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button
              variant="primary"
              className="w-1/2"
              isLoading={isLoading}
              onClick={onClick}
              dataTestid={'resetInvoice'}
            >
              {t('policyDetails.resetInvoice.acceptBtn')}
            </Button>
          </>
        }
      >
        <Stack direction="col">
          <p className="pb-5">{t('policyDetails.resetInvoice.content')}</p>
          <p>{t('policyDetails.resetInvoice.info')}</p>
        </Stack>
      </Modal>

      <AlreadyReadInvoiceConfirmation
        {...{
          show: showConfirm,
          isLoading,
          onClose: () => {
            setShowConfirm(false);
          },
          onAccept: () => {
            setShowConfirm(false);
            updateInvoice();
          },
        }}
      />
    </>
  );
};

export default ResetInvoice;
