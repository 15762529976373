import { IconButton } from '../../../components';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid';
import { useStateProvider } from './../../../providers';
import { useIsSelectedView } from './../../../utils';
import UploadButton from './UploadButton';

export const FileUploadComponent = () => {
  const { setFilesToUpload } = useStateProvider().actions;
  const { t } = useTranslation();

  const { getRootProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFilesToUpload(acceptedFiles);
    },
    noClick: true,
    noKeyboard: true,
  });

  const isMobileView = useIsSelectedView('sm');

  return (
    <div className={classnames('w-full h-full', isMobileView && 'p-5')}>
      <div
        {...getRootProps()}
        className={`${classnames(
          'flex flex-col w-full flex-0 border-2 border-gray-300 border-dashed cursor-pointer',
          isDragActive ? 'bg-gray-100 bg-opacity-90 ' : '',
          isMobileView ? 'h-full rounded-2xl' : 'h-[270px] mb-5'
        )}`}
      >
        <label
          htmlFor={'file-input'}
          className="block justify-center items-center flex-1 flex w-full cursor-pointer"
        >
          {!isDragActive && (
            <p className="p-1 text-center">{t('uploadPanel.dropzoneText')}</p>
          )}
          {isDragActive && (
            <IconButton>
              <ArrowUpTrayIcon />
            </IconButton>
          )}
        </label>
        <input
          id="file-input"
          type="file"
          multiple
          style={{ display: 'none' }}
          onChange={(e) => {
            setFilesToUpload(Array.from(e.target.files || []));
            e.target.value = '';
          }}
        />
      </div>
      {!isMobileView && <UploadButton />}
    </div>
  );
};

export default FileUploadComponent;
