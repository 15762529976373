import {
  Button,
  IconButton,
  PlusIcon,
  Stack,
} from '@trueconnect/tp-components';
import { t } from 'i18next';

import CreateTopicModal from './CreateTopicModal';
import { useState } from 'react';
import classnames from 'classnames';

// primary - button, secondary - blue circle
export const CreateTopicButton = ({
  btnType = 'primary',
  classNames,
  dataTestId = '',
  redirectOnCreateTopic = false,
}: {
  btnType?: 'primary' | 'secondary';
  classNames?: string;
  dataTestId?: string;
  redirectOnCreateTopic?: boolean;
}) => {
  const [showCreateTopicModal, setShowCreateTopicModal] = useState(false);

  return (
    <>
      {btnType === 'primary' && (
        <Stack
          className={classnames('px-4 py-4 w-full', classNames)}
          dataTestid={`CreateTopicButton${dataTestId}`}
        >
          <Button
            variant="secondary"
            onClick={() => {
              setShowCreateTopicModal(true);
            }}
            className="border-x h-[34px] border-y border-[#0170df] text-[#0170df] w-full [&>span]:leading-normal [&>span]:text-base"
          >
            {t('Topics.Sidebar.createTopic')}
          </Button>
        </Stack>
      )}
      {btnType === 'secondary' && (
        <Button
          dataTestid={`CreateTopicButton${dataTestId}`}
          variant="primary"
          className={classnames(
            '!w-6 !h-6 text-[#0170df] !rounded-full',
            classNames
          )}
          onClick={() => {
            setShowCreateTopicModal(true);
          }}
        >
          <IconButton hoverable={false}>
            <PlusIcon color="white" />
          </IconButton>
        </Button>
      )}

      <CreateTopicModal
        {...{
          visible: showCreateTopicModal,
          redirectOnCreateTopic,
          close: () => {
            setShowCreateTopicModal(false);
          },
        }}
      />
    </>
  );
};

export default CreateTopicButton;
