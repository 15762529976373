import {
  Avatar,
  ConversationParticipantType,
  Stack,
  conversationPageStyles,
  getInitialsFor,
  statusBadgeStyles,
  useIsSelectedView,
  useStateProvider,
  useUserProfile,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import { StatusBadge } from 'src/components/StatusBadge';
import { Avatar as AvatarFlowbite } from 'flowbite-react';
import { AddConversationMembersButton } from 'src/components/conversation';
import { t } from 'i18next';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

export const ParticipantDisplay = ({
  participant,
  participants = [],
  showAddMore = true,
}: {
  participant?: ConversationParticipantType;
  participants: ConversationParticipantType[] | undefined;
  showAddMore?: boolean;
}) => {
  switch (participants?.length) {
    case undefined:
    case 1:
      return <AddConversationMembersButton classNames="-mt-4" />;
    case 2:
      return <TwoMembersLayout {...{ participant, showAddMore }} />;
    default:
      return <MultipleMembersLayout {...{ participants, showAddMore }} />;
  }
};

export default ParticipantDisplay;

const TwoMembersLayout = ({
  participant,
  showAddMore,
}: {
  participant?: ConversationParticipantType;
  showAddMore?: boolean;
}) => {
  const { t } = useTranslation();
  const { readOnly } = useStateProvider().state.conversation.conversationData;

  const renderStatusText =
    participant && participant?.status !== 'Active' && !readOnly && showAddMore;

  const isMobileView = useIsSelectedView('sm');
  const navigate = useNavigate();

  return (
    <Stack direction="row" alignItems="center">
      <Avatar
        title={participant?.displayName}
        imgSrc={participant?.image}
        subtitle={
          <p
            className="cursor-pointer"
            onClick={() => {
              navigate(`/customers/${participant?.customerId}`);
            }}
          >
            {participant?.customerName}
          </p>
        }
        initials={participant?.displayName}
        size="sm"
        classNames="!ml-0 !pl-0 [&>div>img]:h-8 [&>div>img]:w-8 [&>div]:w-8 [&>div]:h-8 [&>div>img]:p-0"
        titleStyles={classnames(
          !readOnly && showAddMore && 'pl-5',
          'text-base leading-normal text-black'
        )}
        subTitleStyles={classnames(
          !readOnly && showAddMore && 'pl-5',
          'font-normal text-sm leading-normal !text-[#6B7280]'
        )}
      >
        {showAddMore && <AddConversationMembersButton />}
      </Avatar>
      {renderStatusText && participant.contactId && (
        <Stack
          className={conversationPageStyles.userActions.header(isMobileView)}
        >
          {!isMobileView && (
            <p
              className={classnames(
                statusBadgeStyles.headerText,
                showAddMore && 'pl-5'
              )}
            >
              {t('user.statusBadgeHeader')}
            </p>
          )}
          <StatusBadge
            showBackground={isMobileView}
            email={participant.email || ''}
            contactId={participant.contactId}
            customerId={participant.customerId}
            status={participant.status}
            classNames={classnames(!isMobileView && 'pl-4')}
            readOnly={readOnly}
            showFullText={!isMobileView}
            hoverable={false}
          />
        </Stack>
      )}
    </Stack>
  );
};

const MultipleMembersLayout = ({
  participants,
  showAddMore,
}: {
  participants: ConversationParticipantType[];
  showAddMore?: boolean;
}) => {
  const participantsToDisplay = 4;
  const profileData = useUserProfile();
  const filteredParticipants = participants.filter(
    (item) => item.userId !== profileData.userId
  );
  return (
    <Stack direction="row" alignItems="center">
      <AvatarFlowbite.Group className="align-center">
        {filteredParticipants
          ?.slice(0, participantsToDisplay)
          .map((participant, i) => (
            <AvatarFlowbite
              key={participant.userId}
              placeholderInitials={getInitialsFor(
                participant?.displayName || ''
              )}
              img={participant?.image}
              stacked
              rounded
              size="sm"
              className={classnames(
                'cursor-default [&>div>div]:!h-8 [&>div>div]:!w-8 [&>div>div]:!ring-0 m-0 p-0 [&>div>div]:shadow',
                '[&>div>img]:w-8 [&>div>img]:h-8 [&>div]:w-8 [&>div]:h-8 [&>div>img]:p-0 [&>div>img]:!ring-0',
                i === 0 ? 'pl-[3px]' : 'pl-1'
              )}
            />
          ))}
      </AvatarFlowbite.Group>
      {showAddMore && <AddConversationMembersButton classNames="mt-0 -ml-3" />}
      <h1 className={showAddMore ? 'ml-5' : 'ml-2'}>
        {t('conversationPage.amount', { amount: participants.length })}
      </h1>
    </Stack>
  );
};
