import {
  DocumentCellStyle,
  DropDownItemType,
  Dropdown,
  EllipsisHorizontalIcon,
  IDocumentType,
  IconButton,
  Spinner,
  TrashIcon,
  useIsSelectedView,
  useStateProvider,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import classnames from 'classnames';

export const More: React.FC<{
  conversationId: string;
  item: IDocumentType;
}> = ({ item }) => {
  const { toggleDeleteDocumentModal, setSelectedDocument } =
    useStateProvider().actions;

  const items: DropDownItemType[] = [
    {
      icon: <TrashIcon />,
      text: t('file.action.delete'),
      onClick: () => {
        toggleDeleteDocumentModal(true);
        setSelectedDocument({ ...item });
      },
      classNames: 'text-[#E53935] border-[#E53935]',
      type: 'button',
      color: '#E53935',
    },
  ];

  const isMobileView = useIsSelectedView('sm');
  const { inAction } = useStateProvider().state.documents;

  return (
    <div
      className={classnames(DocumentCellStyle, 'pl-[7px]')}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Dropdown
        mobileView={isMobileView}
        label={
          <IconButton>
            {inAction.includes(item.id) && <Spinner />}
            <EllipsisHorizontalIcon />
          </IconButton>
        }
        items={items}
      />
    </div>
  );
};
