import { UseInfiniteQueryResult } from '@tanstack/react-query';
import {
  Stack,
  useStateProvider,
  Button,
  useNotificationSystem,
  SearchInput,
  XMarkIcon,
  Radio,
  useUserProfile,
} from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import TopicsList from '@trueconnect/tp-components/src/pages/Topics/TopicsList';
import { TopicType } from '@trueconnect/tp-components/src/providers/WorkManagement/typing';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useApiInfiniteQuery } from 'src/api';

export const AttachToTopicModal = ({
  attactToTopic,
  isLoading,
  initialValue,
  customerId,
  modalTitle = t('conversationPage.attactToTopic'),
}: {
  attactToTopic: (value?: string) => void;
  isLoading: boolean;
  initialValue?: string;
  customerId?: number;
  modalTitle?: string;
}) => {
  const { state, actions } = useStateProvider();
  const { showAttachToTopic } = state.workManagement;
  const { setShowAttachToTopicDialog, setSelectedTask } = actions;
  const [attachedTopic, setAttachedTopic] = useState(initialValue);
  const [searchParam, setSearchParam] = useState('');
  const { addNotification } = useNotificationSystem();

  const { userId } = useUserProfile();
  const queryTopics = useApiInfiniteQuery(
    'searchTopics',
    [
      {
        search: searchParam,
        page: 0,
        andAnyOf: {
          createdByUserIds: userId ? [userId] : [],
          conversationParticipantsUserIds: userId ? [userId] : [],
        },
      },
    ],
    {
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('Topics.Sidebar.error'),
        });
      },
      select: (data) =>
        data.pages
          .flatMap((item) => item.map((subitem) => subitem as TopicType))
          .filter((item) => item.customerId === customerId || !item.customerId),
      enabled: showAttachToTopic,
    }
  ) as UseInfiniteQueryResult<TopicType[], Error>;

  const onClose = () => {
    setShowAttachToTopicDialog(false);
    setSelectedTask({});
    setAttachedTopic('');
  };

  useEffect(() => {
    showAttachToTopic && setAttachedTopic(initialValue);
  }, [initialValue, showAttachToTopic]);

  return (
    <Modal
      size="5xl"
      title={modalTitle}
      show={showAttachToTopic}
      onClose={onClose}
      className="!z-50"
      footer={
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          className="w-full gap-5"
        >
          <Button onClick={onClose}>{t('common.cancel')}</Button>
          <Button
            color="blue"
            onClick={() => {
              attactToTopic(attachedTopic);
            }}
            dataTestid="acceptAttachToTopic"
            isLoading={isLoading}
          >
            {t('common.save')}
          </Button>
        </Stack>
      }
    >
      <Stack direction="col" className="w-full h-[500px]">
        <div className="pb-4">
          <SearchInput
            classNames={{
              wrapper: 'bg-white [&>.closeIcon]:mr-0',
              input: 'bg-white',
            }}
            setSearchParam={setSearchParam}
            clearIcon={<XMarkIcon />}
            placeholder={t('Topics.Sidebar.searchPlaceholder')}
          />
        </div>
        <TopicsList
          {...{
            queryTopics,
            onClick: (item) => {
              setAttachedTopic(attachedTopic === item.id ? undefined : item.id);
            },
            extraItems: (item) => (
              <Radio
                checked={item.id == attachedTopic}
                className="!w-5 !h-5 absolute right-3"
              />
            ),
          }}
        />
      </Stack>
    </Modal>
  );
};

export default AttachToTopicModal;
