import {
  useIsSelectedView,
  TabProps,
  ArrowPathIcon,
  FolderIcon,
  Tabs,
  UserIcon,
  useUserProfile,
  TagIcon,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import BranchesSettings from './BranchesSettingsTab/BranchesSettings';
import DeputySettings from './DeputySettingsTab/DeputySettings';
import BrokerUser from './BrokerUser/BrokerUser';
import { UserRole } from 'src/api';
import PremiumTypes from './PremiumTypes/PremiumTypes';
import { MaintenanceTab } from './MaintenanceTab/MaintenanceTab';

export const SettingsTabs = () => {
  const isMobile = useIsSelectedView('sm');
  const { roles } = useUserProfile();
  const { t } = useTranslation();

  const tabs: TabProps[] = [
    {
      title: t('settingPageTabs.deputy'),
      path: '/settings',
      testId: 'DeputyTab',
      icon: <ArrowPathIcon />,
      component: <DeputySettings />,
    },
    ...(roles?.includes(UserRole.SuperUsers)
      ? [
          {
            title: t('settingPageTabs.branches'),
            path: '/settings/branches',
            testId: 'BranchTab',
            icon: <FolderIcon />,
            component: <BranchesSettings />,
          },
        ]
      : []),
    ...(roles?.includes(UserRole.SuperUsers)
      ? [
          {
            title: t('settingPageTabs.brokers'),
            path: '/settings/brokers',
            testId: 'BrokerUserTab',
            icon: <UserIcon />,
            component: <BrokerUser />,
          },
        ]
      : []),
    ...(roles?.includes(UserRole.SuperUsers)
      ? [
          {
            title: t('settingPageTabs.premium_types'),
            path: '/settings/premium_types',
            testId: 'PremiumTypesTab',
            icon: <TagIcon />,
            component: <PremiumTypes />,
          },
        ]
      : []),
    ...(roles?.includes(UserRole.SuperUsers)
      ? [
          {
            title: t('settingPageTabs.maintenance'),
            path: '/settings/maintenance',
            testId: 'MaintenanceTab',
            icon: <TagIcon />,
            component: <MaintenanceTab />,
          },
        ]
      : []),
  ];
  return (
    <Tabs
      tabs={tabs}
      className={classnames(
        '!mx-0 [&>div]:gap-8 [&>div>button]:px-0',
        isMobile ? 'flex-nowrap overflow-auto' : '!overflow-hidden',
        isMobile ? '[&>*:first-child]' : '[&>*:first-child]',
        '[&>div]:w-full',
        "[&>div[role='tablist']>button]:!px-0",
        "[&>div[role='tablist']]:px-4"
      )}
    />
  );
};

export default SettingsTabs;
