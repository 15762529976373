import { useTranslation } from 'react-i18next';
import {
  ContactPermissionDto,
  ConversationListItemProps,
  CreateConversationIcon,
  DashboardStyles,
  SmartSpinnerLocal,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import RecentConversations from './RecentConversations';
import classnames from 'classnames';

type DashboardType = {
  displayName?: string;
  queryConversations: UseInfiniteQueryResult<ConversationListItemProps[]>;
  permissions?: ContactPermissionDto;
  children?: React.ReactNode;
};

export const Dashboard: React.FC<DashboardType> = ({
  displayName,
  permissions,
  queryConversations,
  children = <></>,
}) => {
  const { t } = useTranslation();
  const isMobileView = useIsSelectedView('sm');

  return (
    <SmartSpinnerLocal name="Dashboard" condition={!displayName}>
      <div className={DashboardStyles.wrapper(isMobileView)}>
        <h1 className={DashboardStyles.greeting(isMobileView)}>
          {t(`dashboard.greeting`)}
        </h1>
        <div
          className={classnames(isMobileView ? 'contents' : 'w-full h-full')}
        >
          {children}
          {permissions?.allowConversations && (
            <>
              <RecentConversations queryConversations={queryConversations} />
              {isMobileView && (
                <CreateConversationIcon className="absolute right-8 bottom-8" />
              )}
            </>
          )}
        </div>
      </div>
    </SmartSpinnerLocal>
  );
};

export default Dashboard;
