import {
  Button,
  IconButton,
  IconLabel,
  PlusIcon,
  useStateProvider,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import classnames from 'classnames';
import { useState } from 'react';
import AddConversationToTopicModal from './AddConversationToTopicModal';

export const AddConversationToTopicButton = ({
  className,
  btnType = 'primary',
}: {
  className?: string;
  btnType?: 'primary' | 'secondary';
}) => {
  const { selectedTopic } = useStateProvider().state.workManagement;
  const [showAddConversationModal, setShowAddConversationModal] =
    useState(false);

  return (
    <>
      {btnType == 'primary' && (
        <IconLabel
          className={classnames(
            '!border-customColor_5 border-[1px] !rounded-lg font-medium [&>div]:!w-8 h-[34px]',
            className
          )}
          title={t('Topics.Details.addConversation')}
          dataTestid="AddConversationToTopicButton"
          icon={<PlusIcon className="w-4 h-4 pt-[1px] my-auto" />}
          onClick={() => {
            setShowAddConversationModal(true);
          }}
        />
      )}
      {btnType == 'secondary' && (
        <Button
          variant="primary"
          dataTestid="AddConversationToTopicButton"
          className={classnames(
            '!rounded-full text-[#0170df] z-[5] absolute right-8 bottom-8 !h-14 !w-14'
          )}
          onClick={() => {
            setShowAddConversationModal(true);
          }}
        >
          <IconButton>
            <PlusIcon color="white" />
          </IconButton>
        </Button>
      )}
      <AddConversationToTopicModal
        {...{
          visible: showAddConversationModal,
          selectedTopic,
          onClose: () => {
            setShowAddConversationModal(false);
          },
        }}
      />
    </>
  );
};

export default AddConversationToTopicButton;
