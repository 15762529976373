import { useEffect, useState } from 'react';
import { DocumentIcon } from './../../../images';
import Stack from './../../../controls/Stack';
import { IconButton } from './../../../components';
import { useGetFileUrlShort } from './../../../types';
import { SmartSpinnerLocal } from './../../../providers';

export interface AttachmentType {
  id?: string | undefined;
  title?: string | undefined;
}

type AttachmentProps = {
  attachments: AttachmentType[];
} & useGetFileUrlShort;

const Attachment = ({
  item,
  useGetFileUrl,
}: {
  item: AttachmentType;
} & useGetFileUrlShort) => {
  const { getUrl, url } = useGetFileUrl(item.id ? item.id : '');
  const [isDownloading, setIsDonwloading] = useState(false);

  useEffect(() => {
    if (url && isDownloading) {
      window.open(url);
      setIsDonwloading(false);
    }
  }, [url, isDownloading]);

  return (
    <>
      <SmartSpinnerLocal
        name="fileDownload"
        condition={isDownloading}
        renderAsGlobal={true}
        background={true}
      />

      <Stack
        onClick={() => {
          setIsDonwloading(true);
          getUrl();
        }}
        direction="row"
        className="w-full py-1 mt-2 ml-2 rounded-lg bg-[#F3F4F6] text-black items-center cursor-pointer"
      >
        <IconButton hoverable={false}>
          <DocumentIcon />
        </IconButton>
        {item.title}
      </Stack>
    </>
  );
};

export const Attachments: React.FC<AttachmentProps> = (props) => {
  const { attachments, useGetFileUrl } = props;

  return (
    <>
      {attachments.map((item) => (
        <Attachment key={item.id} {...{ item, useGetFileUrl }} />
      ))}
    </>
  );
};

export default Attachments;
