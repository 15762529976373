import { useStateProvider } from '@trueconnect/tp-components';
import { t } from 'i18next';
import { useCallback } from 'react';
import { useApiInvalidateQueries, useApiMutation } from 'src/api';
import DeleteModal from '../DeleteModal';

export const DeleteTaskModal = () => {
  const invalidateQueries = useApiInvalidateQueries();
  const { state, actions } = useStateProvider();
  const { showDeleteTask, selectedTask } = state.workManagement;
  const { setShowDeleteTask, setSelectedTask } = actions;
  const close = useCallback(async () => {
    await invalidateQueries('searchTasks');
    setSelectedTask({});
  }, [invalidateQueries, setSelectedTask]);

  const { mutate } = useApiMutation('deleteTask', {
    onSuccess: close,
  });

  return (
    <DeleteModal
      {...{
        visible: !!showDeleteTask,
        displayName: selectedTask?.title,
        header: t('Tasks.modals.Delete.title'),
        deleteConfirmationText: t('Tasks.modals.Delete.confirmationText'),
        onAccept: () => {
          mutate([selectedTask?.id]);
        },
        onClose: () => {
          setShowDeleteTask(false);
          setSelectedTask({});
        },
      }}
    />
  );
};

export default DeleteTaskModal;
