import {
  FetchTaskStateDto,
  TaskType,
  taskTypes,
  taskTypesList,
} from '@trueconnect/tp-components';
import { t } from 'i18next';

export const StatusBadge = ({
  item,
  availableTaskTypes,
}: {
  item: TaskType;
  availableTaskTypes: FetchTaskStateDto[];
}) => {
  const option = availableTaskTypes.find((itm) => itm.id == item.stateId);

  if (!option) {
    return <></>;
  }

  return (
    <span className="font-medium">
      {option.systemName && taskTypesList.includes(option.systemName)
        ? t(`Tasks.types.${option.systemName as taskTypes}`)
        : option.systemName}
    </span>
  );
};

export default StatusBadge;
