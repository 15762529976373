import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { ConversationListItemProps } from '../Conversations';
import { useTranslation } from 'react-i18next';
import { ConversationTable, InformationNote } from './../../components';
import { Stack } from './../../controls';
import { DashboardStyles } from './../../styles';
import { useIsSelectedView } from './../../utils';

type RecentConversationsProps = {
  queryConversations: UseInfiniteQueryResult<ConversationListItemProps[]>;
};

export const RecentConversations: React.FC<RecentConversationsProps> = ({
  queryConversations,
}) => {
  const { t } = useTranslation();
  const isMobileView = useIsSelectedView('sm');

  return (
    <Stack
      direction="col"
      alignItems="stretch"
      className={DashboardStyles.panel(isMobileView)}
    >
      <p
        data-testid="recentConversationsPanel"
        className={DashboardStyles.header(isMobileView)}
      >
        {t('dashboard.panel.recentConversations')}
      </p>
      <ConversationTable
        {...{
          queryConversations,
          classNames: {
            content: isMobileView ? '[&>div>div]:border-none' : '',
            row: '!rounded-sm',
          },
          nothingFound: (
            <InformationNote
              title={t('dashboard.panel.no.recent.conversations')}
            />
          ),
        }}
      />
    </Stack>
  );
};

export default RecentConversations;
