import {
  ConversationsIcon,
  CustomerIcon,
  HelpIcon,
  HomeIcon,
  RectangleStackIcon,
  SettingsIcon,
  NavbarItemProps,
  CalendarDaysIcon,
  environment,
  FeatureFlags,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';

export const useSidebarConfig = () => {
  const { t } = useTranslation();
  const isMobileView = useIsSelectedView('sm');

  const mainSidebarElements: NavbarItemProps[] = [
    {
      title: t('sidebar.dashboard'),
      path: '/',
      icon: <HomeIcon />,
    },
    {
      title: t('sidebar.topics'),
      path: '/topics',
      icon: <RectangleStackIcon />,
    },
    {
      title: t('sidebar.conversations'),
      path: '/conversations',
      icon: <ConversationsIcon />,
    },
    {
      title: t('sidebar.customers'),
      path: '/customers',
      icon: <CustomerIcon />,
    },
  ];

  if (environment && FeatureFlags.showTasks(environment)) {
    mainSidebarElements.push({
      title: t('sidebar.tasks'),
      path: '/tasks',
      icon: <CalendarDaysIcon />,
    });
  }

  const settingsSidebarElement: NavbarItemProps[] = [
    {
      title: t('sidebar.settings'),
      path: '/settings',
      icon: <SettingsIcon />,
    },
    {
      title: t('sidebar.help'),
      path: '/help',
      icon: <HelpIcon />,
    },
  ];

  return [
    mainSidebarElements,
    ...(isMobileView ? [] : [settingsSidebarElement]),
  ];
};
