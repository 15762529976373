import classnames from 'classnames';

const image =
  'opacity-0 transition-opacity h-full w-full object-cover absolute inset-0 ease-in';

export const LoginStyles = {
  WelcomeImagePage: {
    root: 'h-screen w-screen overflow-hidden',
    imageContainer: 'relative h-full',
    lastImage: classnames(image, 'duration-[10s]'),
    curImage: (fadeIn: boolean) =>
      classnames(image, 'duration-1000', fadeIn ? 'opacity-100' : ''),
  },
  container: 'h-full lg:h-3/4 lg:px-16 lg:py-16 lg:max-w-2xl',
  image: 'justify-self-start',
  secondaryText: 'text-xl mt-2 mb-20',
};

export default LoginStyles;
