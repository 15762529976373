import { useApiInfiniteQuery } from 'src/api';
import useGetColumns from './useGetColumns';
import { VirtualizedList } from '@trueconnect/tp-components';
import { useCallback, useMemo } from 'react';
import { ContactForRenderType } from 'src/pages/App/Customers/DataView/Tabs';
import { addMemberType } from '../typing';

export const CustomerMembersTab = ({
  customerId,
  searchParam,
  isQueryEnabled,
  participants,
  selectedUsers,
  manageSelectedParticipants,
  allowFullHistory,
}: addMemberType) => {
  const participantIds = useMemo(() => {
    return participants?.map((participant) => participant.contactId) || [];
  }, [participants]);

  const queryContacts = useApiInfiniteQuery(
    'searchContacts',
    [searchParam, customerId, undefined, 0],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map((subitem) => ({
            name: `${subitem.firstName} ${subitem.lastName}`,
            company: subitem.customerName || '',
            permissions: subitem.permissions,
            ...subitem,
          }))
        ),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: isQueryEnabled,
    }
  );

  const shouldDisable = useCallback(
    (item: ContactForRenderType) => {
      return item.id ? participantIds.includes(item.id) : false;
    },
    [participantIds]
  );

  const columns = useGetColumns({
    selectedUsers,
    shouldDisable,
    allowFullHistory,
    participants,
  });

  const toggleAddCheckbox = (item: ContactForRenderType) => {
    if (
      item.id &&
      !shouldDisable(item) &&
      item.activeInTrueData === true &&
      item.email
    ) {
      manageSelectedParticipants({
        contactId: item.id,
        displayName: `${item.firstName} ${item.lastName}`,
        permissions: item.permissions,
        email: item.email,
      });
    }
  };

  return (
    <div className="w-full h-full">
      <VirtualizedList
        columns={columns}
        headerHeight={0}
        infinityQuery={queryContacts}
        rowHeight={70}
        onRowClick={toggleAddCheckbox}
      />
    </div>
  );
};
