import {
  BellIcon,
  NavbarStyles,
  Stack,
  useStateProvider,
  Dropdown,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import { NotificationRendered } from './NotificationRendered';

export const NotificationIcon = () => {
  const { notifications } = useStateProvider().state.hubListener;
  const { t } = useTranslation();
  const isMobileView = useIsSelectedView('sm');

  return (
    <Stack className="relative">
      <Dropdown
        arrowIcon={false}
        placement="left-start"
        className={isMobileView ? '' : 'w-[400px]'}
        modalClassnames="[&>div>div>div:nth-child(2)]:p-0"
        inline={true}
        mobileView={isMobileView}
        label={
          <Stack className="relative pr-[2px]" dataTestid="NotificationIcon">
            <BellIcon className={NavbarStyles.notificationIcon} />
            {!!notifications.length && (
              <div className="absolute -top-2 left-5 w-6 h-6 bg-blue-700 text-white rounded-full items-center text-center justify-center">
                <span>{notifications.length}</span>
              </div>
            )}
          </Stack>
        }
        header={
          <Stack direction="row" justifyContent="between">
            <h1 className="text-lg font-semibold leading-7 text-gray-900">
              {t('conversation.notificationModal.header')}
            </h1>
          </Stack>
        }
        items={
          notifications.length
            ? notifications.map((item) => {
                return {
                  component: <NotificationRendered item={item} />,
                  className: isMobileView ? 'border-b-2 py-4' : '',
                };
              })
            : [
                {
                  component: (
                    <span>
                      {t('conversation.notificationModal.nothingFound')}
                    </span>
                  ),
                },
              ]
        }
      ></Dropdown>
    </Stack>
  );
};

export default NotificationIcon;
