import { hoverClassname } from './commonStyles';
import classnames from 'classnames';

export const IconButtonClassname = 'pt-[1px]';

export const IconButtonIconClassname = 'mx-0';

export const basicButtonClassName =
  'my-0 inline-flex flex-row align-center rounded-lg';

export const getHoverAndFocusClassName = (
  disabled: boolean,
  hoverable: boolean
) =>
  !disabled &&
  hoverable &&
  'hover:text-customColor_24 hover:bg-customColor_21 focus:text-customColor_24 focus:bg-customColor_21';

export const getDisabledClassName = (
  disabled: boolean,
  canBeDisabled: boolean
) => (canBeDisabled ? (disabled ? 'text-gray-300' : 'text-gray-500') : '');
export const getImageClasses = (cls: string | undefined) =>
  classnames('w-[20px] h-[20px] m-auto', cls || '');

export const containerClassname =
  'normal-case mr-[20px] select-none cursor-pointer';
export const iconButtonClassname = 'pt-[2px]';
export const spanClassname = 'leading-5 text-customColor_5 text-sm';
export const hoverableStyle = (hoverable: boolean) =>
  classnames(hoverable ? classnames(hoverClassname, 'rounded-md') : '', 'p-2');
