import { useLocation, useNavigate } from 'react-router-dom';
import {
  Avatar,
  HelpIcon,
  SettingsIcon,
  LogoutIcon,
  useNotificationSystem,
  useUserProfile,
  Stack,
  CommonStyles,
  NavbarStyles,
  Dropdown,
  DropDownItemType,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import classnames from 'classnames';
import { useState } from 'react';

export const UserAvatarDropdown = ({
  hideSettings,
}: {
  hideSettings?: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNotification } = useNotificationSystem();
  const profileData = useUserProfile();
  const { instance } = useMsal();

  async function handleLogout() {
    await instance.logoutRedirect().catch((e: Error) => {
      addNotification({
        title: e.message,
        color: 'failure',
      });
    });
  }

  const settingsOption = {
    component: (
      <Stack direction="row" alignItems="center">
        <SettingsIcon className={NavbarStyles.avatarDropdown.icon} />
        {t('navbar.userDropdown.settings')}
      </Stack>
    ),
    onClick: () => navigate('/settings'),
  } as DropDownItemType;

  const otherOptions = [
    {
      component: (
        <Stack direction="row" alignItems="center">
          <HelpIcon
            className={NavbarStyles.avatarDropdown.icon}
            data-testid="help"
          />
          {t('navbar.userDropdown.help')}
        </Stack>
      ),
      onClick: () => navigate('/help'),
    },
    {
      type: 'divider',
    },
    {
      component: (
        <Stack direction="row" alignItems="center">
          <LogoutIcon
            className={NavbarStyles.avatarDropdown.logoutIcon}
            data-testid="logout"
          />
          <span className={NavbarStyles.avatarDropdown.logoutText}>
            {t('navbar.userDropdown.logout')}
          </span>
        </Stack>
      ),
      onClick: handleLogout,
    },
  ] as DropDownItemType[];

  const isMobileView = useIsSelectedView('sm');
  const [savedUrl, setSavedUrl] = useState('');
  const location = useLocation();

  if (isMobileView) {
    return (
      <Avatar
        classNames={classnames(
          CommonStyles.iconSize,
          'min-w-8 p-0',
          'cursor-pointer'
        )}
        imgSrc={profileData.image}
        initials={profileData.displayName}
        size="sm"
        status="online"
        bordered={true}
        data-testid="UserAvatarDropdown"
        onClick={() => {
          if (location.pathname == '/me') {
            navigate(savedUrl);
            setSavedUrl('');
          } else {
            setSavedUrl(location.pathname);
            navigate('/me');
          }
        }}
      />
    );
  }

  return (
    <Stack className="relative">
      <Dropdown
        label={
          <Avatar
            classNames={classnames(CommonStyles.iconSize, 'min-w-8 p-0')}
            imgSrc={profileData.image}
            initials={profileData.displayName}
            size="sm"
            status="online"
            bordered={true}
            data-testid="UserAvatarDropdown"
          />
        }
        arrowIcon={false}
        placement="left-start"
        inline={true}
        header={
          <>
            <span
              className={NavbarStyles.avatarDropdown.displayName}
              data-testid="displayName"
            >
              {profileData.displayName}
            </span>
            <span
              className={NavbarStyles.avatarDropdown.mail}
              data-testid="email"
            >
              {profileData.mail}
            </span>
          </>
        }
        items={hideSettings ? otherOptions : [settingsOption, ...otherOptions]}
      ></Dropdown>
    </Stack>
  );
};

export default UserAvatarDropdown;
