import {
  useStateProvider,
  Stack,
  getBranches,
  branchType,
  uniqueStrings,
  VirtualizedList,
} from '@trueconnect/tp-components';
import TopicsItem from './TopicsItem';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { TopicType } from '@trueconnect/tp-components/src/providers/WorkManagement/typing';

export const TopicsList = ({
  queryTopics,
  onClick,
  secondaryText = 'customerName',
  extraItems,
}: {
  queryTopics: UseInfiniteQueryResult<TopicType[], Error>;
  onClick?: (item: TopicType) => void;
  secondaryText?: 'creatorUserName' | 'customerName';
  extraItems?: (item: TopicType) => React.ReactNode;
}) => {
  const { state } = useStateProvider();
  const { availableBranches } = state.workManagement;

  return (
    <Stack direction="col" className="w-full h-full flex-1 overflow-hidden">
      <VirtualizedList
        infinityQuery={queryTopics}
        headerHeight={0}
        rowHeight={80}
        columns={[
          {
            dataPropName: 'item',
            render: (item: TopicType) => (
              <TopicsItem
                key={item.id}
                onClick={(item) => {
                  onClick?.(item);
                }}
                secondaryText={secondaryText}
                extraItems={extraItems}
                {...{
                  item,
                  branches: getBranches(
                    availableBranches,
                    uniqueStrings([
                      ...(item.branchIds || []),
                      ...(item.childrenBranchIds || []),
                    ])
                  ) as branchType[],
                }}
              />
            ),
          },
        ]}
        classNames={{
          row: '!w-full !px-0',
        }}
      />
    </Stack>
  );
};

export default TopicsList;
