import {
  Radio,
  SearchInput,
  Stack,
  TopicType,
  textStyle_sm,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import classnames from 'classnames';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TopicsList } from '@trueconnect/tp-components/src/pages/Topics';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { useApiInfiniteQuery } from 'src/api';
import CreateTopicButton from './topics/CreateTopicButton';

export const TopicComponent = ({
  attachedTopic,
  setAttachedTopic,
}: {
  attachedTopic: string;
  setAttachedTopic: Dispatch<SetStateAction<string>>;
}) => {
  const [searchParam, setSearchParam] = useState('');
  const { addNotification } = useNotificationSystem();
  const { state } = useStateProvider();
  const { contactToCreateConversation } = state.conversation;

  const queryTopics = useApiInfiniteQuery(
    'searchTopics',
    [{ search: searchParam, page: 0 }],
    {
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('Topics.Sidebar.error'),
        });
      },
      select: (data) =>
        data.pages
          .flatMap((item) => item.map((subitem) => subitem as TopicType))
          .filter(
            (itm) =>
              !itm.customerId ||
              itm.customerId === contactToCreateConversation.customerId
          ),
    }
  ) as UseInfiniteQueryResult<TopicType[], Error>;

  useEffect(() => {
    return () => {
      setSearchParam('');
    };
  }, []);
  return (
    <Stack direction="col" className="h-full w-full py-5">
      <p className={classnames(textStyle_sm, '!mt-6 !mb-3')}>
        {t('navbar.createConversationPanel.topicSelectionQuestion')}
      </p>
      <Stack
        direction="row"
        className="mb-3 w-full gap-2 pr-4"
        alignItems="center"
      >
        <SearchInput {...{ setSearchParam }} />
        <CreateTopicButton btnType="secondary" classNames="!w-[40px] !h-9" />
      </Stack>
      <TopicsList
        {...{
          queryTopics,
          extraItems: (item) => (
            <Radio
              checked={item.id == attachedTopic}
              onChange={() => {}}
              className="!w-5 !h-5 absolute right-3"
            />
          ),
          onClick: (item) => {
            if (attachedTopic !== item.id) item.id && setAttachedTopic(item.id);
            else {
              setAttachedTopic('');
            }
          },
        }}
      />
    </Stack>
  );
};

export default TopicComponent;
