import {
  CustomerIcon,
  ChevronRightIcon,
  IconButton,
  Stack,
  InsurancesIcon,
  CheckIcon,
  companyNameClassname,
  addressClassname,
  XCircleIcon,
  ArrowUpIcon,
  cellStyle,
  Avatar,
  textStyle_xs,
  CustomerStyles,
  spanClassname,
} from '@trueconnect/tp-components';
import { CustomerProps } from './CustomerProps';
import classnames from 'classnames';
import { Badge } from 'flowbite-react';
import { FC, SVGProps } from 'react';
import { ContactForRenderType } from './DataView/Tabs';
import { Tooltip } from 'flowbite-react';
// import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

export const CompanyName = (item: CustomerProps) => {
  return (
    <div className={classnames(cellStyle, companyNameClassname, 'pl-6')}>
      <Stack
        direction="row"
        justifyContent="start"
        dataTestid={`test_${item.name?.split(' ').join('_')}`}
      >
        <IconButton hoverable={false}>
          <CustomerIcon />
        </IconButton>
        <p className="p-1 font-semibold truncate">{item.name}</p>
      </Stack>
    </div>
  );
};

export const Address = (item: CustomerProps) => {
  return (
    <div
      className={classnames(cellStyle, addressClassname, 'font-medium w-full')}
    >
      <p className="truncate">
        {[item.address, `${item.zipCode} ${item.city}`]
          .filter(Boolean)
          .join(', ')}
      </p>
    </div>
  );
};
export const renderBadge = (
  icon: FC<SVGProps<SVGSVGElement>>,
  color?: string,
  className?: string
) => {
  return (
    <Badge
      className={classnames('w-6 h-6', className)}
      color={color}
      icon={icon}
    />
  );
};

export const Metadata = (item: CustomerProps, isMobileView?: boolean) => {
  // const { t } = useTranslation();
  return (
    <Stack
      direction="row"
      className={classnames(isMobileView && 'flex-wrap w-20', 'gap-2 mr-auto')}
      justifyContent={isMobileView ? 'end' : 'start'}
    >
      {item.isHolding && (
        <Tooltip
          content={t('customers.metadataIsHolding')}
          style="light"
          trigger="hover"
          placement="left"
        >
          {renderBadge(InsurancesIcon, 'info', 'bg-blue-200 text-blue-800')}
        </Tooltip>
      )}
      {item.holdingId && (
        <Tooltip
          content={t('customers.metadataIsSubsidiaries')}
          style="light"
          trigger="hover"
          placement="left"
        >
          {renderBadge(ArrowUpIcon, 'info', 'bg-indigo-100 text-blue-900')}
        </Tooltip>
      )}
      {item.isCurrentTrueDataCustomer && (
        <Tooltip
          content={t('customers.metadataActive')}
          style="light"
          trigger="hover"
          placement="right"
        >
          {renderBadge(CheckIcon, 'success')}
        </Tooltip>
      )}
      {item.isFormerTrueDataCustomer && (
        <Tooltip
          content={t('customers.metadataNotActive')}
          style="light"
          trigger="hover"
          placement="right"
        >
          {renderBadge(XCircleIcon, 'failure')}
        </Tooltip>
      )}
    </Stack>
  );
};

export const AccountManager = (item: CustomerProps) => {
  return (
    <div className={classnames(cellStyle)}>
      <span
        className={classnames(
          spanClassname,
          '!text-base font-medium leading-normal truncate'
        )}
      >
        {item.manager}
      </span>
    </div>
  );
};

export const FavoriteButton = (
  item: CustomerProps,
  onClick: (s: string) => void
) => {
  return (
    <div className={classnames(cellStyle)}>
      <IconButton hoverable={false} className="ml-auto mr-auto">
        <ChevronRightIcon
          onClick={() => {
            onClick?.(`${item.id}`);
          }}
        />
      </IconButton>
    </div>
  );
};

export const MobileViewListItem = (
  item: CustomerProps,
  isMobileView?: boolean
) => {
  const address = `${item.zipCode} ${item.city}`;

  return (
    <Stack className={'border-b h-full w-full px-2'} direction="row">
      <Avatar initials={item.name} />
      <Stack direction="row" justifyContent="between" className="ml-2 w-full">
        <Stack direction="col" justifyContent="evenly">
          <p
            className={
              'text-customColor_8 h-6 whitespace-normal text-ellipsis overflow-hidden font-medium text-base leading-1.5'
            }
          >
            {item.name}
          </p>

          {item.address && <p className={textStyle_xs}>{item.address}</p>}
          {address && (
            <p className={classnames(textStyle_xs, 'mb-1 leading-normal')}>
              {address}
            </p>
          )}
          <div
            className={classnames('[&>div]:!pl-0', '[&>div>span]:font-normal')}
          >
            {AccountManager(item)}
          </div>
        </Stack>
        <div className="pt-1">{Metadata(item, isMobileView)}</div>
      </Stack>
    </Stack>
  );
};

export const InvitedContactListItem = (item: ContactForRenderType) => {
  const { logo, name, activeInTrueData, email, customerName } = item;
  const enabled = !!(activeInTrueData && email);

  return (
    <Stack
      direction="row"
      className={classnames(CustomerStyles.greyedOut(enabled), 'w-full')}
      alignItems="center"
    >
      <Avatar
        imgSrc={logo || ''}
        initials={name}
        size="xs"
        classNames={classnames('mx-2')}
      />
      <Stack direction="row" className="w-full" justifyContent="between">
        <Stack
          direction="col"
          className="whitespace-normal"
          justifyContent="evenly"
        >
          <Stack direction="row" alignItems="center">
            <p className={'font-medium text-base leading-1.5'}>{name}</p>
          </Stack>
          <p className={textStyle_xs}>{email}</p>
          <p className={textStyle_xs}>{customerName}</p>
        </Stack>
      </Stack>
    </Stack>
  );
};
