import {
  branchType,
  Button,
  defaultFontType,
  Stack,
  useIsSelectedView,
  useNotificationSystem,
} from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { t } from 'i18next';
import { useState } from 'react';
import classnames from 'classnames';
import BranchFilter from '@trueconnect/tp-components/src/pages/Policy/BranchFilter';
import { noteType } from './typing';
import {
  CreateCustomerNoteDto,
  useApiInvalidateQueries,
  useApiMutation,
} from 'src/api';

export const titleClassname = classnames(
  'text-sm font-medium font-inter leading-normal',
  defaultFontType
);

export const CopyNoteModal = ({
  visible,
  customerId,
  noteToCopy,
  availableBranches,
  onClose,
}: {
  visible?: boolean;
  customerId: number | undefined;
  noteToCopy: noteType | undefined;
  availableBranches: branchType[];
  onAccept: () => void;
  onClose: () => void;
}) => {
  const [selectedBranches, setSelectedBranches] = useState<string[]>([]);
  const isMobileView = useIsSelectedView('sm');

  const setBranches = (branchId: string) => {
    if (selectedBranches.includes(branchId)) {
      setSelectedBranches(selectedBranches.filter((item) => item !== branchId));
    } else {
      setSelectedBranches([...selectedBranches, branchId]);
    }
  };

  const close = () => {
    setSelectedBranches([]);
    onClose();
  };

  const { addNotification } = useNotificationSystem();
  const invalidateQueries = useApiInvalidateQueries();
  const { mutate: copyNote, isLoading: isCopying } = useApiMutation(
    'create_Note',
    {
      onSuccess: () => {
        invalidateQueries('search_Notes');
      },
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('customer.notes.copyModal.error'),
        });
      },
    }
  );

  const onAccept = () => {
    selectedBranches.forEach((branch) => {
      copyNote([
        customerId,
        {
          branchId: branch || null,
          title: noteToCopy?.title,
          text: noteToCopy?.text,
        } as CreateCustomerNoteDto,
      ]);
    });

    close();
  };

  return (
    <Modal
      show={visible}
      className={classnames(
        '[&>div>div]:overflow-visible [&>div>div>div]:overflow-visible',
        '[&>div>div>div:nth-child(3)]:border-none'
      )}
      title={t('customer.notes.copyModal.title')}
      onClose={close}
      footer={
        <Stack direction="row" className="w-full gap-4">
          <Button className="w-1/2" onClick={close}>
            {t('common.cancel')}
          </Button>
          <Button
            isLoading={isCopying}
            disabled={isCopying}
            className="w-1/2"
            variant="primary"
            onClick={onAccept}
            dataTestid={`copyNoteAccept`}
          >
            {t('common.save')}
          </Button>
        </Stack>
      }
    >
      <Stack
        direction="col"
        className="w-full gap-2"
        dataTestid="copyNote_BranchFilterWrapper"
      >
        <div className="w-full [&>div>div]:!w-full [&>div>div]:bg-gray-50 [&>div>div]:!border-[1px] [&>div>div]:border-gray-300">
          <p className={classnames(titleClassname, 'pb-1')}>
            {t('customer.notes.addNoteModal.branchLabel')}
          </p>
          <BranchFilter
            {...{
              setBranches,
              branchIds: selectedBranches,
              availableBranches,
              isMobileView,
              mode: 'primary',
              placeholder: t('customer.notes.selectBranch'),
            }}
          />
        </div>
      </Stack>
    </Modal>
  );
};

export default CopyNoteModal;
