import { ConversationListItemProps } from 'src/pages/Conversations';

export type TopicType = {
  id?: string;
  displayName?: string | undefined;
  customerId?: number | undefined;
  customerName?: string | undefined;
  branchIds?: string[] | undefined;
  childrenBranchIds?: string[] | undefined;
  totalTasks?: number;
  totalConversations?: number;
  creatorUserId?: string;
  creatorUserName?: string;
};

export type addConversationToTopicType = {
  topic: TopicType;
  conversation: ConversationListItemProps;
};

export interface TaskAssigneeDto {
  userId?: string;
  userName?: string | undefined;
}

export interface TaskType {
  id?: string;
  title?: string | undefined;
  description?: string | undefined;
  dueDateUtc?: Date;
  updatedUtc?: Date;
  customerId?: number | undefined;
  customerName?: string | undefined;
  conversationId?: string | undefined;
  topicId?: string | undefined;
  branchIds?: string[] | undefined;
  assignees?: TaskAssigneeDto[] | undefined;
  stateId?: number;
  creatorUserId?: string;
  creatorUserName?: string | undefined;
}

export interface FetchTaskStateDto {
  id?: number;
  systemName?: string | undefined;
  order?: number;
}

export const taskTypesList = [
  'New',
  'InProgress',
  'OnHold',
  'Closed',
  'Removed',
];
export type taskTypes = 'New' | 'InProgress' | 'OnHold' | 'Closed' | 'Removed';

export type extendedTopicParams = {
  branchIds?: string[];
  dateCreatedFrom?: Date;
  dateCreatedTo?: Date;
  customerIds?: number[];
};

export type ContactBrokerType = {
  userId?: string;
  displayName?: string;
  email?: string;
  phoneNumber: string;
  branchIds: (string | undefined)[];
  image: string;
  isManager?: boolean;
};
