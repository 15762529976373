import { Dispatch, SetStateAction, useState } from 'react';
import {
  ConversationsIcon,
  DocumentIcon,
  TabProps,
  Tabs,
  MessageType,
  useStateProvider,
  UsersIcon,
  PencilIcon,
  ConversationParticipantType,
  useIsSelectedView,
  Stack,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import {
  ConversationTab,
  ConversationDocumentsTab,
  ConversationMembersTab,
} from './Tabs';
import { AttachmentDto } from 'src/api';
import NotesTab from './Tabs/NotesTab';

export type ConversationTabsProps = {
  userId?: string;
  participant: ConversationParticipantType | undefined;
  sendMessage: (message: string, attachments?: AttachmentDto[]) => void;
  messages: MessageType[];
  setMessages: Dispatch<SetStateAction<MessageType[]>>;
};

export const ConversationTabs: React.FC<ConversationTabsProps> = (props) => {
  const { t } = useTranslation();
  const { conversationData } = useStateProvider().state.conversation;
  const {
    conversationId,
    participants: members = [],
    customerId,
  } = conversationData;
  const isMobileView = useIsSelectedView('sm');
  const [showNotification, setShowNotification] = useState(true);

  const tabs: TabProps[] = [
    {
      path: `/conversations/${conversationId}`,
      title: t('tab.conversations.text'),
      icon: <ConversationsIcon />,
      testId: 'conversationTab',
      component: (
        <ConversationTab
          {...props}
          {...{
            showNotification,
            setShowNotification,
          }}
        />
      ),
    },
    {
      path: `/conversations/${conversationId}/files`,
      title: t('tab.files.text'),
      icon: <DocumentIcon />,
      testId: 'filesTab',
      component: <ConversationDocumentsTab {...props} />,
    },
    {
      path: `/conversations/${conversationId}/members`,
      title: t('tab.members'),
      icon: <UsersIcon />,
      testId: 'membersTab',
      component: (
        <ConversationMembersTab
          {...{ ...props, conversationId, members, customerId }}
        />
      ),
    },
    {
      path: `/conversations/${conversationId}/notes`,
      title: (
        <Stack direction="row" alignItems="center">
          <p>{t('tab.notes')}</p>

          {!!conversationData.notes && (
            <div className="rounded-full h-2 w-2 bg-customColor_5 ml-2" />
          )}
        </Stack>
      ),
      icon: <PencilIcon />,
      testId: 'notesTab',
      component: conversationId && (
        <NotesTab {...{ ...props, conversationId }} />
      ),
    },
  ];

  return (
    <Tabs
      tabs={tabs}
      className={classnames(
        '!mx-0 [&>div]:gap-8 [&>div>button]:px-0',
        isMobileView ? 'flex-nowrap overflow-auto' : '!overflow-hidden',
        isMobileView ? '[&>*:first-child]:!pl-4' : '[&>*:first-child]:!pl-8',
        "[&>div[role='tablist']>button]:!px-0"
      )}
    />
  );
};

export default ConversationTabs;
