import { payloadType } from '../StateProvider/actions';
import { documentType } from './typing';

type actionType =
  | 'ADD_TO_ACTION'
  | 'SET_IN_ACTION'
  | 'REMOVE_FROM_ACTION'
  | 'TOGGLE_DELETE_MODAL'
  | 'SELECT_DOCUMENT';

interface action {
  type: actionType;
  payload?: payloadType;
}

interface actions {
  [key: string]: (payload?: payloadType) => action;
}

export const stateActionsDocument: actions = {
  setAddToAction: (value: string) => ({
    type: 'ADD_TO_ACTION',
    payload: value,
  }),
  setInAction: (value: string[]) => ({
    type: 'SET_IN_ACTION',
    payload: value,
  }),
  removeItemAction: (value: string) => ({
    type: 'REMOVE_FROM_ACTION',
    payload: value,
  }),
  toggleDeleteDocumentModal: (value: boolean) => ({
    type: 'TOGGLE_DELETE_MODAL',
    payload: value,
  }),
  setSelectedDocument: (value: documentType) => ({
    type: 'SELECT_DOCUMENT',
    payload: value,
  }),
};

export type actionsDocument = {
  setAddToAction: (value: string) => void;
  setInAction: (value: string[]) => void;
  removeItemAction: (value: string) => void;
  toggleDeleteDocumentModal: (value: boolean) => void;
  setSelectedDocument: (value: documentType) => void;
};

export default stateActionsDocument;
