import { SelectedFileType } from './../providers/Uploads/typing';

export const toggleSelectedFiles = (
  selectedFiles: SelectedFileType[],
  item: SelectedFileType
) => {
  const fileExist = selectedFiles.some((file) => file.id === item.id);

  if (fileExist) {
    return selectedFiles.filter((file) => file.id != item.id);
  }
  return [...selectedFiles, item];
};
