import {
  CheckBox,
  CommonStyles,
  VirtualizedGridCellRendererType,
  useIsSelectedRange,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { BrokerData, BrokerEmail } from './columns';
import { SearchBrokerResponseDto } from 'src/api';
import { useGetSelectedBrokers } from '../../useSelectedUsers';
import { selectedUserType } from 'src/components/permissions/PermissionDialog';

export const useGetColumns = ({
  selectedUsers,
  shouldDisable,
}: {
  selectedUsers: selectedUserType[];
  shouldDisable: (item: SearchBrokerResponseDto) => boolean;
}) => {
  const selectedBrokers = useGetSelectedBrokers(selectedUsers).map(
    (item) => item.brokerUserId
  );

  const columns: VirtualizedGridCellRendererType<SearchBrokerResponseDto>[] = [
    {
      dataPropName: 'checkBox',
      render: (item: SearchBrokerResponseDto) => {
        const disabled = shouldDisable(item);
        return (
          <CheckBox
            dataTestId={`memberToAdd_${item.email}`}
            className={CommonStyles.checkbox(disabled)}
            disabled={shouldDisable(item)}
            onChange={() => ({})}
            checked={selectedBrokers.includes(item.id) || disabled}
          />
        );
      },
      widthRatio: 1,
    },
    {
      dataPropName: 'data',
      render: (item) => (
        <BrokerData item={item} disabled={shouldDisable(item)} />
      ),
      widthRatio: 4,
    },
    {
      dataPropName: 'email',
      render: (item) => (
        <BrokerEmail item={item} disabled={shouldDisable(item)} />
      ),
      widthRatio: 3,
    },
    {
      dataPropName: 'dummy',
      render: () => <></>,
      widthRatio: 6,
    },
  ];

  const isSelectedView = useIsSelectedRange(['md', 'lg']);
  const isMobileView = useIsSelectedView('sm');

  if (isMobileView) {
    return columns.filter(
      (item) => !['dummy', 'email'].includes(item.dataPropName)
    );
  }

  if (isSelectedView) {
    return columns.filter((item) => !['dummy'].includes(item.dataPropName));
  }

  return columns;
};

export default useGetColumns;
