import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { Stack, IconButton } from '@trueconnect/tp-components';
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import classnames from 'classnames';

export const FilterCell: React.FC<{
  children: React.ReactNode;
  title: React.ReactNode;
  setEnabled?: Dispatch<SetStateAction<boolean>>;
  active?: boolean;
  dataTestId?: string;
}> = ({ children, title, setEnabled, active = false, dataTestId }) => {
  const [show, setShow] = useState(active);

  useEffect(() => {
    active && setEnabled?.(true);
  }, [active, setEnabled]);

  return (
    <Stack direction="col" className="w-full max-h-[500px] border-b">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="between"
        className="w-full h-12 py-3 cursor-pointer"
        onClick={() => {
          setEnabled?.(!show);
          setShow(!show);
        }}
      >
        <p
          className={classnames(
            show ? 'text-black' : 'text-[#6B7280]',
            'font-medium px-4'
          )}
        >
          {title}
        </p>
        <IconButton dataTestId={dataTestId}>
          {show ? (
            <ChevronUpIcon color="black" />
          ) : (
            <ChevronDownIcon color="#6B7280" />
          )}
        </IconButton>
      </Stack>
      {show && <Stack className="w-full">{children}</Stack>}
    </Stack>
  );
};

export default FilterCell;
