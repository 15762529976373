import {
  IconButton,
  UserPlusIcon,
  useStateProvider,
} from '@trueconnect/tp-components';
import classnames from 'classnames';

export const AddConversationMembersButton = ({
  classNames = '-ml-6',
}: {
  classNames?: string;
}) => {
  const { setShowAddMembersDialog } = useStateProvider().actions;
  const { readOnly } = useStateProvider().state.conversation.conversationData;

  if (readOnly) return <></>;

  return (
    <>
      <IconButton
        size="none"
        onClick={() => {
          setShowAddMembersDialog(true);
        }}
        className={classnames(
          'bg-white !w-8 !h-8 !min-w-8 !min-h-8 z-[5] !rounded-full cursor-pointer shadow-slate-300 shadow-sm',
          classNames
        )}
      >
        <UserPlusIcon color="#0170df" data-testid="addMemberIcon" />
      </IconButton>
    </>
  );
};

export default AddConversationMembersButton;
