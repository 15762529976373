import { useStateProvider } from '@trueconnect/tp-components';
import { states } from './states';

export const StatusDialog = () => {
  const stateKeys = Object.keys(states);

  const { state, actions } = useStateProvider();
  const { customerData, modalVisible } = state.customer;
  const { setToggleCustomerModal, setClearCustomerData } = actions;

  const close = () => {
    setToggleCustomerModal(!modalVisible);
    setClearCustomerData();
  };

  if (!modalVisible || !customerData.status) {
    return <></>;
  }

  return (
    <>
      {stateKeys.map((state) => {
        const Component = states[state].modal;
        if (!Component) {
          return <div key={state}></div>;
        }
        return (
          <Component
            key={state}
            {...{ ...customerData, close, visible: modalVisible }}
            visible={customerData.status == state}
          />
        );
      })}
    </>
  );
};

export default StatusDialog;
