import { Radio as RadioFlowbite, RadioProps } from 'flowbite-react';
import classnames from 'classnames';

export const Radio = (props: RadioProps) => {
  return (
    <RadioFlowbite
      {...props}
      className={classnames(
        props.className,
        'text-blue-700 focus:ring-blue-700'
      )}
    />
  );
};

export default Radio;
