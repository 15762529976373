import {
  conversation_not_found,
  conversationPageStyles,
  select_conversation,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';

export const NullConvoView = ({
  type,
}: {
  type: 'nothingIsSelected' | 'nothingIsCreated' | 'notFound';
}) => {
  const { t } = useTranslation();
  return (
    <NotificationImage
      text={t(`conversation.${type}.text`)}
      tooltip={t(`conversation.${type}.tooltip`) as string}
      imgSrc={type == 'notFound' ? conversation_not_found : select_conversation}
    />
  );
};

export const NotificationImage = ({
  text,
  tooltip = '',
  imgSrc = select_conversation,
  children,
}: {
  text: string;
  tooltip?: string;
  imgSrc?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className={conversationPageStyles.selectConversation}>
      <div className={conversationPageStyles.noConversationReplacement.wrapper}>
        <img
          src={imgSrc}
          alt="select item to read"
          data-testid="notificationIcon"
          height={110}
          width={110}
        />
        <p className={conversationPageStyles.noConversationReplacement.text}>
          {text}
        </p>
        {tooltip && <p>{tooltip}</p>}
        {children}
      </div>
    </div>
  );
};

export default NullConvoView;
