import { DragIcon } from '@trueconnect/tp-components';
import classnames from 'classnames';

export const DropZoneIcon = ({ isDragActive }: { isDragActive: boolean }) => {
  return (
    <h1
      className={classnames(
        !isDragActive && 'hidden',
        'absolute inset-0 flex justify-center items-center'
      )}
    >
      <DragIcon />
    </h1>
  );
};

export default DropZoneIcon;
