import {
  DocumentToolbar,
  LocalErrorBoundary,
  FilesTable,
  ProgressDialog,
  Stack,
  dropZoneStyles,
  relativeFull,
  useIsSelectedView,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import { useDropzone } from 'react-dropzone';
import useGetUploadModalColumns from './useGetDocumentsTabColumns';
import useGetDocumentsTableProps from './useGetDocumentsTableProps';
import {
  AttachmentDto,
  useApiInvalidateQueries,
  useApiMutation,
} from './../../../../api';
import { useCallback, useEffect } from 'react';
import classnames from 'classnames';
import DeleteModal from 'src/components/DeleteModal';
import { t } from 'i18next';
import { HttpStatusCode } from '@trueconnect/tp-components/src/components/ErrorBoundary/typing';

type ConversationDocumentsTabType = {
  sendMessage: (message: string, attachments?: AttachmentDto[]) => void;
};

export const ConversationDocumentsTab: React.FC<
  ConversationDocumentsTabType
> = ({ sendMessage }) => {
  const { state, actions } = useStateProvider();
  const { isUploadPanelVisible, selectedFiles, filesToUpload } = state.uploads;
  const {
    setSelectedFiles,
    setIsUploadPanelVisible,
    toggleDeleteDocumentModal,
    setSelectedDocument,
    setAddToAction,
    setFilesToUpload,
  } = actions;
  const { conversationId, readOnly } = state.conversation.conversationData;

  const isMobileView = useIsSelectedView('sm');

  const { getRootProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFilesToUpload(acceptedFiles);
      setIsUploadPanelVisible(true);
    },
    noClick: true,
    noKeyboard: true,
    disabled: readOnly,
  });

  useEffect(() => {
    if (isUploadPanelVisible && selectedFiles.length && !filesToUpload.length) {
      sendMessage('', selectedFiles);
      setSelectedFiles([]);
      setIsUploadPanelVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploadPanelVisible, selectedFiles]);

  const columns = useGetUploadModalColumns(conversationId);
  const { isUploadPanelVisible: uploading } = useStateProvider().state.uploads;

  const FilesTableProps = useGetDocumentsTableProps({
    isDragActive,
    columns,
    conversationId,
  });

  const { addNotification } = useNotificationSystem();
  const invalidateQueries = useApiInvalidateQueries();
  const { mutate: deleteDocument } = useApiMutation(
    'deleteConversationDocument'
  );

  const onDelete = useCallback(
    (documentId: string) => {
      setAddToAction(documentId);
      deleteDocument([conversationId, documentId], {
        onSuccess: () => {
          invalidateQueries('search_Conversation_Documents');
          addNotification({
            title: t('file.action.deleted.success'),
            color: 'success',
          });
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteDocument, conversationId]
  );

  const { selectedDocument, showDeleteModal } = state.documents;

  return (
    <LocalErrorBoundary
      catchErrors={[
        HttpStatusCode.else,
        HttpStatusCode.BadRequest,
        HttpStatusCode.NotFound,
      ]}
    >
      <Stack direction="col" className={relativeFull}>
        {!readOnly && <DocumentToolbar />}
        <div
          {...getRootProps()}
          className={classnames(dropZoneStyles(isDragActive))}
        >
          <FilesTable
            {...FilesTableProps}
            {...{
              isMobileView,
              classNames: {
                header: 'first:indent-8',
                column: 'first:indent-5',
              },
            }}
          />
        </div>
        <ProgressDialog uploading={uploading} />
        <DeleteModal
          displayName={selectedDocument.name || ''}
          header={t('file.action.deleteDialogTitle')}
          deleteConfirmationText={t('file.action.deleteConfirmationText')}
          onAccept={() => selectedDocument.id && onDelete(selectedDocument.id)}
          visible={showDeleteModal}
          onClose={() => {
            toggleDeleteDocumentModal(false);
            setSelectedDocument({});
          }}
        />
      </Stack>
    </LocalErrorBoundary>
  );
};

export default ConversationDocumentsTab;
