import classnames from 'classnames';
import styles from './commonStyles.module.scss';

export const hoverClassname = 'hover:bg-customColor_2 cursor-pointer';
export const modalStyles = 'z-40';
export const defaultFontType = styles.fontInter;

export const CustomHeightWrapper = (height: string | number) =>
  classnames('bg-customColor_17', `h-${height}`);

export const CommonStyles = {
  flex: {
    allCenter: 'flex align-center justify-center',
  },
  full: 'w-full h-full',
  iconSize: 'w-8 h-8',
  checkbox: (disabled: boolean) => {
    return classnames(
      disabled ? 'bg-grey-700 text-[#6B7280]' : '',
      'cursor-pointer'
    );
  },
};

export const commonText =
  'truncate text-customColor_8 font-medium text-base leading-1.5';

export const defaultScrollbar = styles.defaultScrollbar;
export const hiddenScrollbar = styles.scrollbar_hidden;
export const bigScrollbar = styles.styledScrollbar;
export const customScrollbar = styles.customScrollbar;
export const smallCustomScrollbar = styles.smallCustomScrollbar;

export const styledScrollbar =
  'scrollbar-thin scrollbar-thumb-gray-700 scrollbar-thumb-rounded-full scrollbar-track-transparent';

export const ErrorWrapper = (isMobileView: boolean) =>
  classnames(
    'mx-auto text-center font-normal text-customColor_23',
    isMobileView ? ' w-4/5' : ' w-2/5'
  );

export const relativeFull = classnames('relative', CommonStyles.full);

export const pointerIcon = 'h-5 cursor-pointer';

export const fullScreenModal = () => {
  return '[&>div]:!fixed [&>div]:!bottom-0 [&>div]:!top-0';
};

export const mobileModalStyles = (
  isMobileView: boolean,
  rounded?: boolean,
  applyToModal: string[] = []
) => {
  const modalSelector = '[&>div>div>div:nth-child(2)]';

  return classnames(
    isMobileView && `${modalSelector}:p-0 [&>div>div]:mt-auto`,
    isMobileView && rounded && '[&>div>div]:rounded-t-3xl',
    ['h-full', ...applyToModal].map((item) => `${modalSelector}:${item}`),
    '[&>div>div]:w-full [&>div>div]:flex [&>div>div]:h-full [&>div>div]:flex-col [&>div>div]:max-h-[100vh]'
  );
};
