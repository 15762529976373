type ChatState = {
  canSendMessage: boolean;
  shouldScrollToBottom: boolean;
  message: string;
};

type ChatAction =
  | { type: 'SET_CAN_SEND'; payload: boolean }
  | { type: 'TOGGLE_SHOULD_SCROLL_TO_BOTTOM'; payload: boolean }
  | { type: 'SET_MESSAGE'; payload: string };

export const initialChatState: ChatState = {
  canSendMessage: true,
  shouldScrollToBottom: false,
  message: '',
};

const chatReducer = (
  state: ChatState = initialChatState,
  action: ChatAction
): ChatState => {
  switch (action.type) {
    case 'SET_CAN_SEND':
      return { ...state, canSendMessage: action.payload };
    case 'TOGGLE_SHOULD_SCROLL_TO_BOTTOM':
      return { ...state, shouldScrollToBottom: action.payload };
    case 'SET_MESSAGE':
      return { ...state, message: action.payload };
    default:
      return state;
  }
};

export { chatReducer };
export type { ChatAction };
export type { ChatState };
