import { useState } from 'react';
import { environment } from '../../config';
import { Badge } from 'flowbite-react';
import classNames from 'classnames';
import { useIsSelectedView } from './../../utils';

const badgeClass = (showBadge: boolean) =>
  classNames(
    'ml-4 cursor-pointer select-none z-10 bg-blue-100 text-blue-800',
    showBadge ? '' : 'hidden'
  );

export const EnvBadge = () => {
  const isMobile = useIsSelectedView('sm');
  const [showBadge, setShowBadge] = useState(!isMobile);
  const environmentMapping = {
    development: 'Development',
    local: 'Local',
    test: 'Test',
  };

  return (
    <>
      {(environment === 'development' ||
        environment === 'local' ||
        environment === 'test') &&
      showBadge ? (
        <Badge
          className={badgeClass(showBadge)}
          onClick={() => setShowBadge(false)}
          size="lg"
          data-testid="envBadge"
        >
          {environmentMapping[environment]}
        </Badge>
      ) : null}
    </>
  );
};

export default EnvBadge;
