import { useParams } from 'react-router-dom';
import { ConversationListItemProps } from './ConversationListItemProps';
import {
  CommonStyles,
  Stack,
  useIsSelectedRange,
  useIsSelectedView,
  useStateProvider,
} from '@trueconnect/tp-components';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import classnames from 'classnames';
import { useEffect } from 'react';
import ConversationSidebar from './ConversationSidebar';

export type ConversationProps = {
  children: React.ReactNode;
  ExtendedConversationSearch: React.ReactNode;
  TopicsSearch?: React.ReactNode;
  isSearching: boolean;
  queryConversations: UseInfiniteQueryResult<ConversationListItemProps[]>;
  rowRenderer: (item: ConversationListItemProps) => JSX.Element;
};

export const ConversationsPage: React.FC<ConversationProps> = ({
  children: ConversationDetails,
  ExtendedConversationSearch,
  TopicsSearch,
  isSearching,
  queryConversations,
  rowRenderer,
}) => {
  const { id } = useParams();
  const { state, actions } = useStateProvider();
  const {
    setShowNavbar,
    setShowBottombar,
    setExtendedSearchParams,
    setShowExtendedSearch,
    setSelectedTopic,
    setShowTopicsSidebar,
  } = actions;
  const { showExtendedSearch } = state.conversation;
  const { showTopicsSidebar } = state.workManagement;

  const isMobileView = useIsSelectedView('sm');
  const isTabletView = useIsSelectedRange(['sm', 'md', 'lg']);

  useEffect(() => {
    isMobileView && setShowNavbar(!id);
    isMobileView && setShowBottombar(!id);
    isTabletView && setExtendedSearchParams({});
    isTabletView && setShowExtendedSearch(false);
    return () => {
      setShowNavbar(true);
      setShowTopicsSidebar(false);
      setShowBottombar(true);
    };
  }, [
    id,
    isMobileView,
    isTabletView,
    setExtendedSearchParams,
    setShowBottombar,
    setShowExtendedSearch,
    setShowNavbar,
    setShowTopicsSidebar,
  ]);

  useEffect(() => {
    return () => {
      if (!id) {
        setExtendedSearchParams({});
        setSelectedTopic({});
        setShowExtendedSearch(false);
      }
    };
  }, [id, setExtendedSearchParams, setSelectedTopic, setShowExtendedSearch]);

  return (
    <Stack
      direction="row"
      className={classnames('bg-customColor_17', CommonStyles.full)}
    >
      {!((isTabletView && showExtendedSearch) || (isTabletView && !!id)) &&
        TopicsSearch}
      {!(isTabletView && !!id) &&
        !(isMobileView && showExtendedSearch) &&
        !(isMobileView && showTopicsSidebar) && (
          <>
            <ConversationSidebar
              {...{ queryConversations, isSearching, rowRenderer }}
            />
          </>
        )}
      {ExtendedConversationSearch}
      {!(isTabletView && showExtendedSearch) && (
        <div className="w-full h-full flex-1">{ConversationDetails}</div>
      )}
    </Stack>
  );
};

export default ConversationsPage;
