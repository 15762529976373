import { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import Message, { MessageType } from './Message';
import { SmartSpinnerLocal, useStateProvider } from './../../../providers';
import styles from './Chat.module.scss';
import { useGetFileUrlShort } from './../../../types';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import ScrollTracker from './ScrollTracker ';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';
import { chatStyles, styledScrollbar } from './../../../styles';
import dayjs from 'dayjs';
import { Virtuoso } from 'react-virtuoso';

type MessagesComponentProps = {
  messages: MessageType[];
  user: string;
  className?: string;
  queryMessages: UseInfiniteQueryResult<MessageType[]>;
  lastReadMessagesTimestamp?: Date;
} & useGetFileUrlShort;

export const MessagesComponent = (props: MessagesComponentProps) => {
  const {
    messages,
    user,
    useGetFileUrl,
    queryMessages,
    lastReadMessagesTimestamp,
  } = props;
  const { fetchNextPage, isFetching } = queryMessages;
  const { shouldScrollToBottom } = useStateProvider().state.chat;
  const { setToggleShouldScrollToBottom } = useStateProvider().actions;
  const containerRef = useRef<HTMLDivElement>(null);
  const [showButton, setShowButton] = useState(false);
  const { t } = useTranslation();

  useEffectOnce(() => {
    setToggleShouldScrollToBottom(true);
  });

  useEffect(() => {
    shouldScrollToBottom &&
      containerRef?.current?.scrollTo({
        top: containerRef?.current?.scrollHeight,
        behavior: 'smooth',
      });
    setToggleShouldScrollToBottom(false);
    setShowButton(false);
  }, [setToggleShouldScrollToBottom, shouldScrollToBottom]);

  const lastReadMessage = messages.find((item) =>
    dayjs(lastReadMessagesTimestamp).isBefore(dayjs(item.createdUtcTimestamp))
  );

  const virtuoso = useRef(null);

  const itemContent = useCallback(
    (index: number, item: MessageType) => (
      <div className="py-2 pr-2">
        {lastReadMessage &&
          lastReadMessage.id === item.id &&
          item.senderUserId !== user && (
            <div className="h-3 border-b-2 border-gray text-center my-2">
              <span className="bg-white px-3">
                {t('conversation.newMessages')}
              </span>
            </div>
          )}
        <Message
          key={item.id}
          message={item}
          isOwn={item.senderUserId === user}
          attachments={item.attachments || []}
          useGetFileUrl={useGetFileUrl}
        />
      </div>
    ),
    [lastReadMessage, t, useGetFileUrl, user]
  );

  const atTopStateChange = useCallback(
    async (atTop: boolean) => {
      if (!isFetching && atTop) {
        await fetchNextPage();
      }
    },
    [fetchNextPage, isFetching]
  );

  return (
    <div className={classnames(chatStyles.wrapper, props.className)}>
      <ScrollTracker shouldRender={showButton} />
      <SmartSpinnerLocal
        name="messages"
        condition={
          (!messages || !queryMessages.data) && !queryMessages.isFetched
        }
      >
        <div
          className={classnames(
            chatStyles.message.container,
            styledScrollbar,
            styles.messages
          )}
        >
          <Virtuoso
            ref={virtuoso}
            initialTopMostItemIndex={99999}
            itemContent={itemContent}
            data={messages}
            atTopStateChange={atTopStateChange}
            followOutput={'auto'}
            style={{
              flex: '1 1 auto',
              overscrollBehavior: 'contain',
            }}
            className={classnames(
              chatStyles.message.container,
              styledScrollbar,
              styles.messages
            )}
          />
        </div>
      </SmartSpinnerLocal>
    </div>
  );
};

export default MessagesComponent;
