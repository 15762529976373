import { useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import {
  UserProfile,
  callMsGraph,
  useGetToken,
} from '@trueconnect/tp-components';
import { useApiQuery } from 'src/api';

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  meEndpoint: 'https://graph.microsoft.com/v1.0/me',
  mePhotoEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value',
  scopes: ['User.Read'],
};

export type AppUser = {
  id?: string;
  email?: string | undefined;
  displayName?: string | undefined;
};

const defaultUserProfile: UserProfile = {
  givenName: '',
  surname: '',
  mail: '',
};

export const useGetUserProfile = () => {
  const [userProfile, setUserProfile] =
    useState<UserProfile>(defaultUserProfile);

  const { data: userData, refetch } = useApiQuery('ensureUser', [], {
    enabled: false,
  });

  // get access token
  const accessToken = useGetToken({
    scopes: graphConfig.scopes,
  });

  // get graph user profile data
  useAsync(async () => {
    if (accessToken) {
      const graphUser = await callMsGraph(graphConfig.meEndpoint, accessToken);
      setUserProfile((p) => ({
        ...p,
        ...graphUser,
      }));
    }
  }, [accessToken]);

  // call apps ensureUser
  useAsync(async () => {
    if (accessToken && !userData) {
      await refetch();
    }
  }, [accessToken]);

  useEffect(() => {
    setUserProfile((prev) => ({
      ...prev,
      ...userData,
      userId: userData?.id,
      permissions: undefined,
    }));
  }, [userData]);

  // get profile image
  useAsync(async () => {
    if (accessToken) {
      const imageBlob = await callMsGraph(
        `${graphConfig.mePhotoEndpoint}`,
        accessToken
      );
      if (imageBlob && imageBlob.error) {
        if (imageBlob.error.code === 'ImageNotFound') {
          // user does not have an image
          return;
        }
      }
      const url = window.URL || window.webkitURL;
      const imageUrl = url.createObjectURL(imageBlob);
      setUserProfile((p) => ({ ...p, image: imageUrl }));
    }
  }, [accessToken]);

  return { userProfile };
};
