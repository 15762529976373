import { t } from 'i18next';
import { useMemo } from 'react';
import { VirtualizedGridCellRendererType } from './../../../components';
import { IDocumentType } from '../DocumentType';
import CreatedBy from './CreatedBy';
import FileDateTime from './FileDateTime';
import FileName from './FileName';

export const useGetDocumentsColumns = () => {
  const columns: VirtualizedGridCellRendererType<IDocumentType>[] = useMemo(
    () => [
      {
        dataPropName: 'fileName',
        title: t('file.name'),
        render: FileName,
        widthRatio: 10,
      },
      {
        dataPropName: 'createdBy',
        title: t('file.createdBy'),
        render: CreatedBy,
        widthRatio: 7,
      },
      {
        dataPropName: 'modifiedAt',
        title: t('file.modifiedAt'),
        render: FileDateTime,
        widthRatio: 6,
      },
    ],
    []
  );

  return columns;
};

export default useGetDocumentsColumns;
