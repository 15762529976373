import {
  CheckBox,
  SearchInput,
  Stack,
  VirtualizedList,
  commonText,
  useStateProvider,
} from '@trueconnect/tp-components';
import FilterCell from '@trueconnect/tp-components/src/components/ExtendedSearch/FilterCell';
import { Dispatch, SetStateAction } from 'react';

import { t } from 'i18next';
import classnames from 'classnames';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { TopicType } from '@trueconnect/tp-components/src/providers/WorkManagement/typing';

export const TopicSearch = ({
  active,
  queryTopics,
  setEnabled,
  setSearchParam,
  searchParam,
  showWithoutTopicOption = true,
}: {
  active?: boolean;
  queryTopics: UseInfiniteQueryResult<TopicType[], Error>;
  setEnabled: Dispatch<SetStateAction<boolean>>;
  setSearchParam: Dispatch<SetStateAction<string>>;
  searchParam: string;
  showWithoutTopicOption?: boolean;
}) => {
  const { state, actions } = useStateProvider();
  const { extendedSearchParams } = state.conversation;
  const { selectedTopic } = state.workManagement;
  const { selectedTopics = [] } = extendedSearchParams;
  const { setExtendedSearchParams, setSelectedTopic } = actions;

  const handleCheckBoxClick = (id: string) => {
    if (selectedTopics.includes(id) || selectedTopic.id == id) {
      setExtendedSearchParams({
        ...extendedSearchParams,
        selectedTopics: selectedTopics.filter((item) => item != id),
      });
      if (selectedTopic.id === id) {
        setSelectedTopic({});
      }
    } else {
      setExtendedSearchParams({
        ...extendedSearchParams,
        selectedTopics: [...selectedTopics, id],
      });
    }
  };

  const topicIds = [
    ...(selectedTopic.id ? [selectedTopic.id] : []),
    ...(selectedTopics || []),
  ];

  const withoutTopicChecked =
    typeof extendedSearchParams.withoutTopic === 'boolean';

  return (
    <FilterCell
      title={t('conversation.extendedSearch.topicsSearch.title')}
      setEnabled={setEnabled}
      active={active}
      dataTestId="extendedSearchTopicFilter"
    >
      <div className="px-4 w-full">
        <SearchInput
          isMobileView={true}
          classNames={{
            wrapper: 'bg-white',
            input: 'bg-white text-sm',
            icon: 'mx-0 px-0 [&>div]:m-0 !w-7',
          }}
          setSearchParam={setSearchParam}
          placeholder={t(
            'conversation.extendedSearch.topicsSearch.inputPlaceholder'
          )}
          dataTestid="extendedSearchTopicInput"
        />
      </div>
      <Stack
        className={`w-full h-full pt-3 px-3`}
        isResizable={true}
        resizeDirection="ver"
        resizeProps={{
          width: -1,
          height: 180,
          resizeHandles: ['s'],
          minConstraints: [-1, 140],
          maxConstraints: [-1, 380],
        }}
      >
        {!searchParam && showWithoutTopicOption && (
          <Stack
            direction="row"
            className="cursor-pointer overflow-hidden pt-[1px]"
            alignItems="center"
            dataTestid={`extendedTopicSearch_withoutTopic`}
            onClick={() => {
              setExtendedSearchParams({
                ...extendedSearchParams,
                selectedTopics: [],
                withoutTopic: withoutTopicChecked ? undefined : false,
              });
              setSelectedTopic({});
            }}
          >
            <CheckBox
              containerClassname="!w-8"
              checked={withoutTopicChecked}
              onChange={() => ({})}
            />
            <p
              className={classnames(
                commonText,
                'text-sm !text-customColor_5 leading-normal'
              )}
            >
              {t('conversation.extendedSearch.topicsSearch.noTopic')}
            </p>
          </Stack>
        )}
        <Stack className="w-full h-full">
          <VirtualizedList
            infinityQuery={queryTopics}
            rowHeight={30}
            classNames={{
              row: 'border-none',
            }}
            columns={[
              {
                dataPropName: 'item',
                render: (item) => (
                  <Stack
                    key={item.id}
                    direction="row"
                    className="cursor-pointer overflow-hidden"
                    alignItems="center"
                    onClick={() => {
                      item.id &&
                        !withoutTopicChecked &&
                        handleCheckBoxClick(item.id);
                    }}
                    dataTestid={`extendedTopicSearch_${item.displayName}`}
                  >
                    <CheckBox
                      containerClassname="!w-8"
                      checked={!!item.id && topicIds.includes(item.id)}
                      onChange={() => ({})}
                      disabled={withoutTopicChecked}
                    />
                    <p
                      className={classnames(
                        commonText,
                        'text-sm !text-[#4B5563] leading-normal'
                      )}
                    >
                      {item.displayName}
                    </p>
                  </Stack>
                ),
              },
            ]}
          />
        </Stack>
      </Stack>
    </FilterCell>
  );
};

export default TopicSearch;
