import {
  VirtualizedList,
  SearchInput,
  searchInputStyles,
  relativeFull,
  Stack,
  useIsSelectedView,
  ContactStatus,
  ContactType,
  useStateProvider,
} from '@trueconnect/tp-components';
import { SearchContactResponseDto, useApiInfiniteQuery } from 'src/api';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import useContactsColumns from './useContactsColumns';
import classnames from 'classnames';
import { PermissionModal } from 'src/components/CustomerManagementModals';
import { CustomerData } from '@trueconnect/tp-components/src/providers/Customers/types';
import PermissionDialog from 'src/components/permissions/PermissionDialog';

export type ContactForRenderType = SearchContactResponseDto & {
  name?: string;
  image?: string | undefined;
  disabled?: boolean;
};

type ContactsTabProps = {
  id?: number;
  searchParam?: string;
  statuses?: ContactStatus[];
  setSearchParam: Dispatch<SetStateAction<string>>;
  isPanel?: boolean;
};

export const ContactsTab: React.FC<ContactsTabProps> = ({
  id,
  searchParam,
  statuses = [],
  setSearchParam,
  isPanel = false,
}) => {
  const [selectedContact, setSelectedContact] = useState<ContactType>();

  const { actions } = useStateProvider();
  const {
    setContactToCreateConversation,
    setShowCreateConversation,
    setCreateConversationModalType,
  } = actions;

  const queryContacts = useApiInfiniteQuery(
    'searchContacts',
    [searchParam, id, statuses, 0, undefined],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map((subitem) => ({
            ...subitem,
            name: `${subitem.firstName} ${subitem.lastName}`,
            company: subitem.customerName,
          }))
        ),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const openContact = (contact: ContactType) => {
    setContactToCreateConversation({
      ...contact,
      displayName: classnames(contact.firstName, contact.lastName),
      permissions: { ...contact.permissions, allowConversations: true },
    });
    setCreateConversationModalType('secondary');
    setShowCreateConversation(true);
  };

  const startConversation = (contact: ContactType) => {
    if (contact.permissions?.allowConversations) openContact(contact);
    else {
      setSelectedContact(contact);
    }
  };

  const columns = useContactsColumns(startConversation, id);
  const isMobileView = useIsSelectedView('sm');

  return (
    <div className="h-full overflow-hidden">
      <Stack
        className={classnames('w-full h-full', isMobileView && '[&>div]:!pl-0')}
      >
        {isMobileView && (
          <SearchInput
            setSearchParam={setSearchParam}
            classNames={{
              wrapper: classnames(searchInputStyles, '!border-none !min-h-16'),
            }}
            dataTestid="customerPageSearch"
            placeholder={t(`tab.contacts.Searchplaceholder`)}
            isMobileView={true}
          />
        )}
        <div className={classnames(relativeFull, !isPanel && 'px-6')}>
          <VirtualizedList
            columns={columns}
            headerHeight={0}
            infinityQuery={queryContacts}
            classNames={{
              column: 'first:ml-0',
              row: 'cursor-pointer',
            }}
            nothingFound={t('contactsTab.nothingIsFound')}
            rowHeight={70}
          />
        </div>
      </Stack>
      <PermissionModal />
      {selectedContact && (
        <PermissionDialog
          {...{
            isVisible: !!selectedContact,
            permission: 'allowConversations',
            onClose: () => {
              setSelectedContact(undefined);
            },
            onOk: () => {
              openContact({
                ...selectedContact,
              } as CustomerData);
            },
            usersToAllow: [
              {
                contactId: selectedContact.id,
                displayName: `${selectedContact.firstName} ${selectedContact.lastName}`,
                permissions: selectedContact.permissions,
                email: selectedContact.email,
              },
            ],
          }}
        />
      )}
    </div>
  );
};

export default ContactsTab;
