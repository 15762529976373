import {
  ConversationParticipantType,
  IconLabel,
  PlusIcon,
  Stack,
  VirtualizedList,
  defaultScrollbar,
  relativeFull,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import { useContactColumns } from './useContactColumns';
import { useApiMutation } from 'src/api';
import { useEffect, useState } from 'react';
import classnames from 'classnames';

export const ConversationMembersTab: React.FC<{
  conversationId: string;
  members: ConversationParticipantType[];
}> = ({ conversationId, members }) => {
  const { addNotification } = useNotificationSystem();
  const { setShowAddMembersDialog, setConversationData } =
    useStateProvider().actions;

  const { conversationData } = useStateProvider().state.conversation;
  const { readOnly, participants } = conversationData;

  const [memberIds, setMemberIds] = useState<string[]>([]);

  const { mutate: removeParticipants } = useApiMutation(
    'remove_Participants_From_Conversation',
    {
      onSuccess: () => {
        addNotification({
          color: 'success',
          title: t('conversation.participant.remove.success'),
        });
        // invalidateQueries('getConversationById');
        setConversationData({
          ...conversationData,
          participants: participants.filter(
            (item) => item.userId && !memberIds.includes(item.userId)
          ),
        });
      },
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('conversation.participant.remove.error'),
        });
      },
    }
  );

  useEffect(() => {
    if (memberIds.length > 0) {
      removeParticipants([conversationId, memberIds], {
        onSuccess: () => {
          addNotification({
            color: 'success',
            title: t('conversation.participant.remove.success'),
          });
          // invalidateQueries('getConversationById');
          setConversationData({
            ...conversationData,
            participants: participants.filter(
              (item) => item.userId && !memberIds.includes(item.userId)
            ),
          });
        },
        onError: () => {
          addNotification({
            color: 'failure',
            title: t('conversation.participant.remove.error'),
          });
        },
      });
    }
  }, [conversationId, memberIds, removeParticipants]);

  const columns = useContactColumns({ setMemberIds });

  return (
    <Stack direction="col" className={relativeFull}>
      {!readOnly && (
        <Stack
          direction="row"
          className="py-3 bg-customColor_7 mt-px h-[60px] border-b w-full"
        >
          <IconLabel
            title={t('conversation.participant.add.title') as string}
            icon={<PlusIcon data-testid="addMember" />}
            className="ml-[13px]"
            onClick={() => {
              setShowAddMembersDialog(true);
            }}
            hoverable={false}
          />
        </Stack>
      )}
      <div
        className={classnames(
          'h-full w-full flex-1 overflow-y-auto',
          defaultScrollbar
        )}
      >
        <VirtualizedList
          columns={columns}
          headerHeight={0}
          rowHeight={72}
          infinityQuery={{
            data: members,
          }}
          classNames={{
            column: 'first:pl-5 last:pr-8',
          }}
        />
      </div>
    </Stack>
  );
};

export default ConversationMembersTab;
