import {
  CheckBox,
  CommonStyles,
  ConversationParticipantType,
  ToggleSwitch,
  VirtualizedGridCellRendererType,
  useIsSelectedRange,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { ContactForRenderType } from 'src/pages/App/Customers/DataView/Tabs';
import { Tooltip } from 'flowbite-react';
import { useGetSelectedContacts } from '../../useSelectedUsers';
import { t } from 'i18next';
import classnames from 'classnames';
import { ContactActions } from 'src/pages/App/Customers/DataView/Tabs/ContactsTab/columns';
import { ContactData } from 'src/components/ListItems/ContactData';
import { ContactEmail } from 'src/components/ListItems/ContactEmail';
import { ContactPhoneNumber } from 'src/components/ListItems/ContactPhoneNumber';
import { selectedUserType } from 'src/components/permissions/PermissionDialog';

const FullHistoryAccess = ({
  item,
  shouldDisable,
  allowFullHistory,
  checked,
}: {
  item: ContactForRenderType;
  shouldDisable: (item: ContactForRenderType) => boolean;
  allowFullHistory: (newItem: selectedUserType, value: boolean) => void;
  checked: boolean;
}) => {
  const disabled =
    shouldDisable(item) || item.activeInTrueData === false || !item.email;

  return (
    <Tooltip
      content={
        <>{t('conversation.manageMembers.modals.add.historyCheckboxTooltip')}</>
      }
      style="light"
      className="!fixed"
    >
      <ToggleSwitch
        className={classnames('ml-1', disabled && '[&>div]:bg-gray-200')}
        disabled={disabled}
        onChange={() => ({})}
        checked={checked}
        label=""
        onClick={(e) => {
          e.stopPropagation();
          allowFullHistory(
            {
              ...item,
              contactId: item.id,
              displayName: `${item.firstName} ${item.lastName}`,
              permissions: item.permissions,
            },
            !checked
          );
        }}
      />
    </Tooltip>
  );
};

export const useGetColumns = ({
  selectedUsers,
  shouldDisable,
  allowFullHistory,
  participants,
}: {
  selectedUsers: selectedUserType[];
  shouldDisable: (item: ContactForRenderType) => boolean;
  allowFullHistory: (newItem: selectedUserType, value: boolean) => void;
  participants?: ConversationParticipantType[];
}) => {
  const selectedContacts = useGetSelectedContacts(selectedUsers).map(
    (item) => item.contactId
  );
  const isMobileView = useIsSelectedView('sm');
  const isTabletView = useIsSelectedRange(['md', 'lg']);
  const isXlView = useIsSelectedView('xl');

  const contactDeactivated = (item: ContactForRenderType) =>
    item.activeInTrueData === false || !item.email;

  const columns: VirtualizedGridCellRendererType<ContactForRenderType>[] = [
    {
      dataPropName: 'checkBox',
      render: (item: ContactForRenderType) => {
        if (!item.id) return <div></div>;
        const disabled = shouldDisable(item);
        return (
          <CheckBox
            dataTestId={`memberToAdd_${item.email}`}
            className={CommonStyles.checkbox(
              disabled || contactDeactivated(item)
            )}
            disabled={disabled || contactDeactivated(item)}
            onChange={() => ({})}
            checked={selectedContacts.includes(item.id) || disabled}
          />
        );
      },
      widthRatio: 1,
    },
    {
      dataPropName: 'name',
      render: (item) => (
        <ContactData
          {...{
            ...item,
            disabled: contactDeactivated(item) || shouldDisable(item),
          }}
        />
      ),
      widthRatio: isMobileView ? 6 : 4,
    },
    {
      dataPropName: 'email',
      render: (item) => (
        <ContactEmail
          {...{
            ...item,
            disabled: contactDeactivated(item) || shouldDisable(item),
          }}
        />
      ),
      widthRatio: isXlView ? 3 : 4,
    },
    {
      dataPropName: 'phone',
      render: (item) => (
        <ContactPhoneNumber
          {...{
            ...item,
            disabled: contactDeactivated(item) || shouldDisable(item),
          }}
        />
      ),
      widthRatio: 2,
    },
    {
      dataPropName: 'status',
      render: (item: ContactForRenderType) => (
        <div
          className="w-full"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ContactActions
            item={item}
            hideActions={true}
            hideStatus={isMobileView}
            isMobileView={isMobileView}
          />
        </div>
      ),
      widthRatio: isMobileView ? 2 : 3,
    },
    {
      dataPropName: 'fullHistoryAccess',
      render: (item: ContactForRenderType) => {
        if (!item.id) return <div></div>;

        const checked = selectedContacts.includes(item.id)
          ? !!selectedUsers?.find((user) => user.contactId == item.id)
              ?.canReadPrevMessages
          : !!participants?.find(
              (participant) => participant.contactId == item.id
            )?.canReadFullHistory;

        return (
          <FullHistoryAccess
            {...{
              item,
              shouldDisable,
              allowFullHistory,
              checked,
            }}
          />
        );
      },
      widthRatio: isMobileView ? 2 : 1,
    },
  ];

  if (isMobileView) {
    return columns.filter(
      (item) => !['phone', 'email'].includes(item.dataPropName)
    );
  }

  if (isTabletView) {
    return columns.filter(
      (item) => !['phone', 'email'].includes(item.dataPropName)
    );
  }

  return columns;
};

export default useGetColumns;
