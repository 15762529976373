import {
  Button,
  CalendarIcon,
  compareDatesWithTime,
  IconButton,
  InformationNote,
  inputStyle,
  locales,
  mapDays,
  savedData,
  SmartSpinnerLocal,
  Stack,
  useIsSelectedRange,
  useNotificationSystem,
} from '@trueconnect/tp-components';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import classnames from 'classnames';
import { t } from 'i18next';
import { useCallback, useState } from 'react';
import i18n from 'src/i18n';
import {
  SetMaintenanceDto,
  useApiInvalidateQueries,
  useApiMutation,
  useApiQuery,
} from 'src/api';
import dayjs from 'dayjs';
import { ItemWithTitle } from '../DeputySettingsTab/DeputySettings';
import ConfirmationModal from '@trueconnect/tp-components/src/controls/ConfirmationModal';

const formatDate = (date: Date) => {
  return dayjs(date).startOf('minute').toDate();
};

export const MaintenanceTab = () => {
  const today = new DateObject();
  const isMobileView = useIsSelectedRange(['sm', 'md']);

  const invalidateQueries = useApiInvalidateQueries();
  const [startDate, setStartDate] = useState<DateObject | undefined>();
  const [endDate, setEndDate] = useState<DateObject | undefined>();
  const [message, setMessage] = useState<string>('');
  const selectedLanguage = locales[i18n.language] ? i18n.language : 'de';
  const { addNotification } = useNotificationSystem();

  const { data, isLoading: isDataLoading } = useApiQuery(
    'get_Maintenance',
    [],
    {
      onSuccess: (data) => {
        const { message, showFrom, showTo } = data;
        showFrom && setStartDate(new DateObject(formatDate(showFrom)));
        showTo && setEndDate(new DateObject(formatDate(showTo)));
        message && setMessage(message);
      },
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('settings.Maintenance.getError'),
        });
      },
    }
  );

  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('get_Maintenance');
  }, [invalidateQueries]);

  const { mutate: mutateCreate, isLoading: isCreateLoading } = useApiMutation(
    'set_Maintenance',
    {
      onSuccess: () => {
        invalidateQuery();
        setSHowConfirmation(false);
      },
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('settings.Maintenance.setError'),
        });
      },
    }
  );

  const { mutate: mutateDelete, isLoading: isDeleteLoading } = useApiMutation(
    'delete_Maintenance',
    {
      onSuccess: () => {
        invalidateQuery();
        setMessage('');
        setEndDate(undefined);
        setStartDate(undefined);
      },
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('settings.Maintenance.deleteError'),
        });
      },
    }
  );

  const compareStartDates = () => {
    if (!(startDate && endDate && data?.showFrom && data?.showTo)) return false;
    const showFrom = startDate.toDate();
    return compareDatesWithTime(new Date(data?.showFrom), new Date(showFrom));
  };

  const compareEndDates = () => {
    if (!(startDate && endDate && data?.showFrom && data?.showTo)) return false;
    const showTo = endDate.toDate();
    return compareDatesWithTime(new Date(data?.showTo), new Date(showTo));
  };

  const isChanged = !(
    data?.message == message &&
    compareStartDates() &&
    compareEndDates()
  );

  const isActive =
    startDate &&
    endDate &&
    today?.toDate() >= startDate?.toDate() &&
    today?.toDate() <= endDate?.toDate();

  const [showConfirmation, setSHowConfirmation] = useState(false);

  return (
    <div className="w-full h-full p-3">
      <SmartSpinnerLocal name="get_Maintenance" condition={isDataLoading}>
        <Stack
          direction="col"
          className={classnames(
            'p-6 w-full h-fit gap-2',
            isMobileView ? 'border-b border-t' : 'rounded-lg border'
          )}
        >
          <Stack
            direction={isMobileView ? 'col' : 'row'}
            className="w-full h-full gap-6"
          >
            <ItemWithTitle
              title={t('settings.Maintenance.period')}
              classNames={classnames(!isMobileView && 'max-w-[340px]')}
            >
              <Stack
                direction="row"
                alignItems="center"
                className="w-fit h-full gap-6"
              >
                <Stack
                  className="w-full relative"
                  direction="row"
                  alignItems="center"
                >
                  <IconButton
                    className="absolute left-2"
                    customSize="!w-4 !h-4"
                    hoverable={false}
                  >
                    <CalendarIcon />
                  </IconButton>
                  <DatePicker
                    value={startDate}
                    locale={locales[selectedLanguage]}
                    onChange={(date: DateObject) => {
                      setStartDate(date);
                    }}
                    containerClassName="!w-full"
                    placeholder={t('settings.inputs.Start')}
                    format="DD.MM.YYYY HH.mm"
                    plugins={[
                      <TimePicker
                        key={'time'}
                        position="bottom"
                        hideSeconds={true}
                        className="[&>div>input]:!w-[30px]"
                      />,
                    ]}
                    weekStartDayIndex={1}
                    onOpenPickNewDate={false}
                    inputClass={classnames(
                      inputStyle,
                      'text-sm indent-5',
                      compareStartDates() && savedData
                    )}
                    minDate={today}
                    mapDays={(object) => {
                      return {
                        className: mapDays(startDate, endDate, object),
                      };
                    }}
                  />
                </Stack>
                <Stack
                  className="w-full relative"
                  direction="row"
                  alignItems="center"
                >
                  <IconButton
                    className="absolute left-2"
                    customSize="!w-4 !h-4"
                    hoverable={false}
                  >
                    <CalendarIcon />
                  </IconButton>
                  <DatePicker
                    value={endDate}
                    locale={locales[selectedLanguage]}
                    onChange={(date: DateObject) => {
                      setEndDate(date);
                    }}
                    containerClassName="!w-full"
                    placeholder={t('settings.inputs.End')}
                    format="DD.MM.YYYY HH.mm"
                    plugins={[
                      <TimePicker
                        key={'time'}
                        position="bottom"
                        hideSeconds={true}
                        className="[&>div>input]:!w-[30px]"
                      />,
                    ]}
                    weekStartDayIndex={1}
                    onOpenPickNewDate={false}
                    inputClass={classnames(
                      inputStyle,
                      'text-sm indent-5',
                      compareEndDates() && savedData
                    )}
                    minDate={today}
                    mapDays={(object) => {
                      return {
                        className: mapDays(startDate, endDate, object),
                      };
                    }}
                  />
                </Stack>
              </Stack>
            </ItemWithTitle>
            <ItemWithTitle title={t('settings.Maintenance.displayedMessage')}>
              <Stack className="w-full">
                <input
                  value={message}
                  maxLength={150}
                  className={classnames(
                    'w-full focus:outline-none text-sm',
                    inputStyle,
                    message == data?.message && savedData
                  )}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Stack>
            </ItemWithTitle>
          </Stack>
          <InformationNote title={t('settings.Maintenance.infoNote')} />

          <Stack
            direction="row"
            className="w-full gap-2"
            alignItems="center"
            justifyContent="end"
          >
            <Button
              isLoading={isCreateLoading}
              disabled={!(!!message && !!startDate && !!endDate) || !isChanged}
              variant="primary"
              className="mt-auto !h-10 [&>span]:leading-normal"
              onClick={() => {
                if (startDate && endDate && startDate > endDate) {
                  addNotification({
                    color: 'info',
                    title: t(
                      'settings.Maintenance.startDateMustBeEarlierThanEndDate'
                    ),
                  });
                  return;
                }

                setSHowConfirmation(true);
              }}
              size="md"
            >
              {t('common.save')}
            </Button>
            {(!!data?.message || !!data?.showFrom || !!data?.showTo) && (
              <Button
                isLoading={isDeleteLoading}
                className="mt-auto !h-10 [&>span]:leading-normal"
                onClick={() => {
                  mutateDelete([]);
                }}
                size="md"
              >
                {t('settings.delete')}
              </Button>
            )}
          </Stack>
        </Stack>
      </SmartSpinnerLocal>
      <ConfirmationModal
        {...{
          show: showConfirmation,
          onAccept: () => {
            if (!(startDate && endDate)) {
              return;
            }
            mutateCreate([
              {
                isActive: isActive,
                message,
                showFrom: formatDate(startDate.toDate()),
                showTo: formatDate(endDate.toDate()),
              } as SetMaintenanceDto,
            ]);
          },
          onClose: () => {
            setSHowConfirmation(false);
          },
          confirmationText: t('settings.Maintenance.confirmationText'),
        }}
      />
    </div>
  );
};
