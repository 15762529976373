import { Stack, IconButton, PencilIcon } from '@trueconnect/tp-components';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import classnames from 'classnames';
import { titleClassname, subTitle } from './InvoiceEdit';
import styles from './styles.module.scss';
import { NumericFormat } from 'react-number-format';

export const InvoicePrice = ({
  brutto,
  setBrutto,
  isEditable = true,
}: {
  brutto: number | undefined;
  setBrutto: Dispatch<SetStateAction<number | undefined>>;
  isEditable?: boolean;
}) => {
  const separator = '’';
  return (
    <div className="w-full">
      <p className={titleClassname}>{t('policyDetails.editInvoice.brutto')}</p>
      <div className="w-full pb-2 border-b-2">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="between"
          className={classnames(subTitle, 'w-full h-[25px]')}
        >
          <NumericFormat
            disabled={!isEditable}
            maxLength={15}
            thousandSeparator={separator}
            decimalScale={2}
            fixedDecimalScale={true}
            style={{
              boxShadow: 'none',
              border: 'none',
            }}
            className={classnames(
              subTitle,
              styles.input,
              'p-0 w-full h-[25px] focus:outline-none'
            )}
            value={brutto || 0}
            onBlur={(val) => {
              const value = val.target.value.replaceAll(/[^0-9.-]/g, '');
              setBrutto(parseFloat(value));
            }}
          />
          {isEditable && (
            <IconButton className="[&>div]:w-[18px] [&>div]:h-[18px]">
              <PencilIcon color="#1C64F2" />
            </IconButton>
          )}
        </Stack>
      </div>
    </div>
  );
};

export default InvoicePrice;
