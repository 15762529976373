import {
  Background,
  BranchesSelectionComponent,
  Button,
  CloseIcon,
  CommonStyles,
  ConversationParticipantType,
  IconButton,
  Stack,
  branchType,
  createConversationStyles,
  supportedTypes,
  textStyle_4,
  useIsSelectedRange,
  useOneParticipant,
  useStateProvider,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import { useEffect } from 'react';
import classnames from 'classnames';
import ParticipantDisplay from 'src/pages/App/Conversations/Actions/ParticipantDisplay';

export const ChangeBranchesModal = ({
  type = 'conversation',
  onSave,
  isLoading,
}: {
  type?: supportedTypes;
  onSave: (branches: branchType[]) => void;
  isLoading?: boolean;
}) => {
  const { state, actions } = useStateProvider();
  const { conversationData } = state.conversation;
  const { showChangeBranches, selectedBranches } = state.workManagement;
  const { setShowChangeBranchesDialog, setSelectedBranches } = actions;
  const isMobileView = useIsSelectedRange(['sm', 'md']);

  const close = () => {
    setShowChangeBranchesDialog(false);
    setSelectedBranches([]);
  };

  useEffect(() => {
    return () => {
      showChangeBranches && setSelectedBranches([]);
    };
  }, [showChangeBranches, setSelectedBranches]);

  const { participants } = conversationData;
  const participant = useOneParticipant(
    participants as ConversationParticipantType[],
    true
  );

  return (
    <>
      {showChangeBranches && (
        <Background>
          <div className={createConversationStyles.Wrapper}>
            <Stack className={CommonStyles.full}>
              <Stack className="w-full overflow-y-auto">
                <Stack
                  className={classnames(
                    createConversationStyles.PanelTop,
                    '!mb-0'
                  )}
                  alignItems="center"
                  direction="row"
                  justifyContent="between"
                >
                  <h1
                    className={textStyle_4}
                    data-testid="createConversation_header"
                  >
                    {t('conversationPage.branches')}
                  </h1>
                  <IconButton
                    onClick={close}
                    dataTestId="createConversation_close"
                  >
                    <CloseIcon className="h-5" />
                  </IconButton>
                </Stack>
                {type == 'conversation' && (
                  <div className="pb-4 pt-10 border-b w-full">
                    <ParticipantDisplay
                      {...{ participants, participant, showAddMore: false }}
                    />
                  </div>
                )}
              </Stack>
              <BranchesSelectionComponent
                {...{ type, selectedBranches, setSelectedBranches }}
              />
              <Stack
                className={classnames(
                  'w-full mt-auto gap-4',
                  !isMobileView && 'px-7'
                )}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  className="w-full"
                  dataTestid="cancelChangeBranches"
                  onClick={close}
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  className="w-full"
                  dataTestid="acceptChangeBranches"
                  variant="primary"
                  isLoading={isLoading}
                  onClick={() => {
                    onSave(selectedBranches);
                  }}
                >
                  {t('common.accept')}
                </Button>
              </Stack>
            </Stack>
          </div>
        </Background>
      )}
    </>
  );
};

export default ChangeBranchesModal;
