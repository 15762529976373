import {
  FunnelIcon,
  IconButton,
  MagnifyingGlassIcon,
  Stack,
  XCircleIcon,
  useConfig,
  useIsSelectedView,
  useStateProvider,
} from '@trueconnect/tp-components';
import { useEffect, useState } from 'react';
import LeftPart from './LeftPart';
import classnames from 'classnames';

export const SidebarHeader = () => {
  const [showSearch, setShowSearch] = useState(false);
  const { showExtendedSearch, extendedSearchParams } =
    useStateProvider().state.conversation;

  const { closeSidebar } = useConfig().configActions;
  const { setSearchValue, setConversationType, setShowExtendedSearch } =
    useStateProvider().actions;
  const isMobileView = useIsSelectedView('sm');

  useEffect(() => {
    return () => {
      setConversationType('Open');
    };
  }, [setConversationType]);

  return (
    <Stack
      className={classnames(
        '!h-[55px] pr-4 w-full',
        isMobileView ? '' : 'bg-customColor_7'
      )}
      direction="row"
      alignItems="center"
    >
      <LeftPart {...{ showSearch }} />
      {!showSearch && (
        <IconButton
          className="relative"
          onClick={() => {
            !showExtendedSearch && closeSidebar();
            setShowExtendedSearch(!showExtendedSearch);
          }}
        >
          {isMobileView &&
            (!!extendedSearchParams.selectedCustomer ||
              !!extendedSearchParams.selectedContacts ||
              !!extendedSearchParams.dateStart ||
              !!extendedSearchParams.dateEnd) && (
              <div className="absolute top-0 left-4 w-3 h-3 bg-blue-500 rounded-full" />
            )}
          <FunnelIcon data-testid="startExtendedConversationSearch" />
        </IconButton>
      )}
      <IconButton
        onClick={() => {
          setShowSearch(!showSearch);
          setSearchValue('');
        }}
      >
        {showSearch ? (
          <XCircleIcon data-testid="closeConversationSearch" />
        ) : (
          <MagnifyingGlassIcon data-testid="startConversationSearch" />
        )}
      </IconButton>
    </Stack>
  );
};

export default SidebarHeader;
