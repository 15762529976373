import {
  FileInfo,
  IDocumentType,
  VirtualizedGridCellRendererType,
  useGetDocumentsColumns,
  useIsSelectedView,
  useStateProvider,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import { useMemo } from 'react';
import { More } from './columns/More';

export const useGetDocumentsTabColumnsBase = (conversationId: string) => {
  const isMobileView = useIsSelectedView('sm');
  const isTabletView = useIsSelectedView('md');

  const defaultColumns = useGetDocumentsColumns();

  const columns: VirtualizedGridCellRendererType<IDocumentType>[] = useMemo(
    () => [
      ...defaultColumns,
      {
        dataPropName: 'more',
        title: t('file.more'),
        render: (item: IDocumentType) => (
          <More conversationId={conversationId} item={item} />
        ),
        widthRatio: isMobileView ? 1 : 2,
      },
    ],
    [conversationId, defaultColumns, isMobileView]
  );

  if (isMobileView) {
    return [
      FileInfo,
      ...columns.filter(
        (item) =>
          !['modifiedAt', 'createdBy', 'fileName'].includes(item.dataPropName)
      ),
    ];
  }

  if (isTabletView) {
    return columns.filter(
      (item) => !['modifiedAt'].includes(item.dataPropName)
    );
  }

  return columns;
};

export const useGetDocumentsTabColumns = (conversationId: string) => {
  const { readOnly } = useStateProvider().state.conversation.conversationData;
  const columns = useGetDocumentsTabColumnsBase(conversationId);
  return readOnly
    ? columns.filter((item) => item.dataPropName !== 'more')
    : columns;
};

export default useGetDocumentsTabColumns;
