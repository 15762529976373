import { FC } from 'react';
import { Flowbite } from 'flowbite-react';

export type ThemeProviderProps = {
  children: React.ReactNode;
};

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  return (
    <Flowbite
      theme={{
        dark: false,
        usePreferences: false,
      }}
    >
      {children}
    </Flowbite>
  );
};

export default ThemeProvider;
