import classnames from 'classnames';

interface BackgroundProps {
  children?: React.ReactNode;
  className?: string;
  color?: boolean;
}

const backgroundStyles = (showColor: boolean) =>
  classnames(
    'absolute z-20 top-0 left-0 w-full h-full',
    showColor ? 'bg-customColor_16 bg-opacity-60' : ''
  );

export const Background: React.FC<BackgroundProps> = ({
  children,
  className,
  color = true,
}) => {
  return (
    <div className={classnames(backgroundStyles(color), className)}>
      {children}
    </div>
  );
};

export default Background;
