import {
  Stack,
  hoverClassname,
  IconButton,
  PolicyType,
  PlusIcon,
  ArrowDownTrayIcon,
  DocumentIcon,
  TrashIcon,
  useStateProvider,
  ArrowUpTrayIcon,
  useNotificationSystem,
  useApiUpload,
  SmartSpinnerLocal,
  Spinner,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';
import {
  CustomerDocumentDto,
  useApiInvalidateQueries,
  useApiMutation,
  useApiQuery,
} from 'src/api';
import { Query, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import DeleteModal from 'src/components/DeleteModal';

export const PolicyDocument = ({ policyData }: { policyData: PolicyType }) => {
  const { state, actions } = useStateProvider();
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationSystem();
  const invalidateQueries = useApiInvalidateQueries();
  const { currentUploadFile, isUploading } = state.uploads;
  const { invoiceModalType } = state.customer;
  const { setCurrentUploadFileData, setIsUploading } = actions;
  const { id = '' } = policyData;

  const { getRootProps, isDragActive } = useDropzone({
    maxFiles: 1,
    accept: { 'application/pdf': ['.pdf'] },
    onDrop: (acceptedFiles) => {
      setCurrentUploadFileData({
        file: acceptedFiles[0],
        cancel: () => ({}),
        progress: 0,
      });
    },
    noClick: true,
    noKeyboard: true,
  });

  const {
    policyNumber,
    contractTypeName,
    validFromDate: dateFrom,
    insuranceCompanyName: insuranceCompany,
  } = policyData;
  const insuranceCompanyName = insuranceCompany?.replaceAll(' ', '_');
  const extension = currentUploadFile.file?.name.split('.').reverse()[0];

  const createSessionQuery = useApiQuery(
    'creates_Upload_Session_For_Policy_Documents',
    [
      id,
      {
        uploadFileName: `${dateFrom}-${policyNumber}-${contractTypeName}-${insuranceCompanyName}-Police.${extension}`,
      },
    ],
    { enabled: false }
  );

  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('getPolicyById');
  }, [invalidateQueries]);

  const removeSession = useCallback(() => {
    queryClient.removeQueries({
      predicate: (query: Query) =>
        query.queryKey[0] === 'creates_Upload_Session_For_Policy_Documents',
    });
  }, [queryClient]);

  const { mutate: closeUploadSession } = useApiMutation(
    `close_Upload_Session_For_Policy`,
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (_, [_policyId, _documentId]) => {
        removeSession();
        setCurrentUploadFileData({ progress: 0, cancel: () => ({}) });
        setIsUploading(false);
        invalidateQuery();
      },
    }
  );

  const { progress, cancel } = useApiUpload<string>(
    id,
    createSessionQuery,
    (id: string, documentId: string) => {
      closeUploadSession([id, documentId]);
    },
    setIsUploading,
    (error) => {
      addNotification({
        title: error,
        color: 'failure',
      });
    },
    invoiceModalType ? undefined : currentUploadFile.file,
    isUploading
  );

  useEffect(() => {
    id &&
      isUploading &&
      setCurrentUploadFileData({
        ...currentUploadFile,
        cancel: () => {
          setIsUploading(false);
          cancel();
          setCurrentUploadFileData({ progress: 0, cancel: () => ({}) });
        },
        progress,
      });
  }, [progress]);

  const { mutate, isLoading } = useApiMutation('delete_Policy_Document');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    mutate: getPolicyDocumentUrl,
    isLoading: isLoadingPolicyDocumentUrl,
  } = useApiMutation('getDocumentById');

  const deletePolicyDocument = () => {
    mutate([policyData.id], {
      onSuccess: () => {
        invalidateQuery();
      },
      onError: () => {
        addNotification({
          title: t('policyDetails.deleteModal.error'),
          color: 'failure',
        });
      },
    });
  };

  if (!policyData.documentId)
    return (
      <div className={classnames('w-full h-[56px]')}>
        <SmartSpinnerLocal
          condition={isUploading && !invoiceModalType}
          name="policyDocumentUpload"
        >
          <div
            data-testid="policyDocumentUpload"
            {...getRootProps()}
            className={`${classnames(
              'w-full cursor-pointer h-full bg-customColor_7 border-b',
              hoverClassname,
              isDragActive ? 'bg-gray-100 bg-opacity-90 ' : ''
            )}`}
          >
            <label
              htmlFor={'file-input'}
              className="block flex-1 flex align-center w-full h-full cursor-pointer"
            >
              {!isDragActive && (
                <Stack
                  alignItems="center"
                  direction="row"
                  className="text-base font-medium text-[#1C64F2] px-[30px]"
                >
                  <IconButton
                    hoverable={false}
                    className="!m-0 !p-0 !w-5 !mr-2"
                  >
                    <PlusIcon color="#1C64F2" />
                  </IconButton>
                  <p>{t('policyDetails.uploadPolicyDocument.title')}</p>
                </Stack>
              )}
              {isDragActive && (
                <Stack
                  direction="row"
                  className="w-full border-2 border-gray-300 border-dashed"
                  alignItems="center"
                  justifyContent="center"
                >
                  <IconButton>
                    <ArrowUpTrayIcon />
                  </IconButton>
                </Stack>
              )}
            </label>
            <input
              id="file-input"
              type="file"
              style={{ display: 'none' }}
              accept="application/pdf"
              onChange={(e) => {
                if (e.target.files && e.target.files.length) {
                  const file = e.target.files[0];
                  if (file.name.split('.').reverse()[0] !== 'pdf') {
                    addNotification({
                      title: t('policyDetails.uploadPolicyDocument.errorTitle'),
                      subTitle: t(
                        'policyDetails.uploadPolicyDocument.errorSubTitle'
                      ),
                      color: 'info',
                    });
                    return;
                  }

                  setCurrentUploadFileData({
                    file: file,
                    cancel: () => ({}),
                    progress: 0,
                  });
                }
              }}
            />
          </div>
        </SmartSpinnerLocal>
      </div>
    );

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="between"
        className={classnames(
          'w-full h-[56px] px-[30px] bg-customColor_7 border-b'
        )}
      >
        <Stack
          alignItems="center"
          direction="row"
          className="text-base font-medium"
        >
          <IconButton hoverable={false} className="!m-0 !p-0 !w-5 !mr-2">
            <DocumentIcon />
          </IconButton>
          <p>{t('policyDetails.policyIcon')}</p>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          className={classnames('p-1 rounded-lg')}
        >
          {isLoadingPolicyDocumentUrl ? (
            <Spinner />
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              className={classnames(hoverClassname, 'p-1 rounded-lg')}
              onClick={() => {
                getPolicyDocumentUrl([policyData.documentId], {
                  onSuccess: (data) => {
                    const result = data as CustomerDocumentDto;
                    result.preViewUrl &&
                      window.open(result.preViewUrl, '_blank');
                  },
                });
              }}
            >
              <p className="text-nowrap">{t('policyDetails.download')}</p>
              <IconButton
                dataTestId="downloadPolicyDocument"
                className="!m-0 !p-0 w-[20px] !ml-2"
                hoverable={false}
              >
                <ArrowDownTrayIcon color="black" />
              </IconButton>
            </Stack>
          )}

          <div className="h-6 mx-6 w-[1px] bg-customColor_1" />

          {isLoading ? (
            <Spinner />
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              className={classnames(hoverClassname, 'p-1 rounded-lg')}
            >
              <IconButton
                dataTestId="deletePolicyDocument"
                hoverable={false}
                className="!m-0 !p-0 w-[20px]"
                onClick={() => {
                  setShowDeleteModal(true);
                }}
              >
                <TrashIcon color="#0170DF" />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Stack>
      <DeleteModal
        {...{
          visible: showDeleteModal,
          displayName: 'policyDocument',
          header: t('policyDetails.deleteModal.header'),
          deleteConfirmationText: t(
            'policyDetails.deleteModal.confirmationText'
          ),
          onAccept: deletePolicyDocument,
          acceptBtnText: t('common.delete'),
          onClose: () => {
            setShowDeleteModal(false);
          },
        }}
      />
    </>
  );
};

export default PolicyDocument;
