import { HubListenerNotificationType, ReceiveMessageType } from './typing';

type HubListenerState = {
  notifications: HubListenerNotificationType[];
};

type HubListenerAction =
  | { type: 'RECEIVE_NOTIFICATION'; payload: HubListenerNotificationType }
  | { type: 'REMOVE_NOTIFICATION'; payload: string }
  | { type: 'REMOVE_MESSAGE_NOTIFICATION'; payload: string };

export const initialHubListenerState: HubListenerState = {
  notifications: [],
};

const hubListenerReducer = (
  state: HubListenerState = initialHubListenerState,
  action: HubListenerAction
): HubListenerState => {
  switch (action.type) {
    case 'RECEIVE_NOTIFICATION':
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    case 'REMOVE_NOTIFICATION':
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload
        ),
      };
    case 'REMOVE_MESSAGE_NOTIFICATION':
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) =>
            notification.target === 'ReceiveMessage' &&
            (notification.notification as ReceiveMessageType).messageId !==
              action.payload
        ),
      };
    default:
      return state;
  }
};

export { hubListenerReducer };
export type { HubListenerAction };
export type { HubListenerState };
