import {
  RectangleStackIcon,
  ChatBubbleLeftIcon,
  ClipboardIcon,
  DocumentIcon,
  InformationCircleIcon,
  LoadingTransitionPage,
  TabProps,
  Tabs,
  UserCircleIcon,
  useIsSelectedView,
  environment,
  FeatureFlags,
  CalendarDaysIcon,
} from '@trueconnect/tp-components';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ContactsTab,
  ConversationTab,
  DocumentTab,
  InformationTab,
  PolicyTab,
} from './Tabs';
import { FetchCustomerInfoDto } from 'src/api';
import classnames from 'classnames';
import TopicsTab from './Tabs/TopicsTab/TopicsTab';
import TasksTab from './Tabs/TasksTab/TasksTab';

type CustomerTabsProps = {
  searchParam: string;
  setSearchParam: Dispatch<SetStateAction<string>>;
  customerData?: FetchCustomerInfoDto;
  tabCallback?: Dispatch<SetStateAction<number>>;
  type?: 'fullText' | 'onlyActive' | 'noText';
  className?: string;
};

export const CustomerTabs: React.FC<CustomerTabsProps> = ({
  tabCallback,
  searchParam,
  customerData,
  type,
  className,
  setSearchParam,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const isMobileView = useIsSelectedView('sm');

  if (!customerData || !id) return <LoadingTransitionPage />;

  const parsedId = parseInt(id);

  const childrenIds: number[] = [];
  customerData.children?.forEach((child) => {
    if (child.id) {
      childrenIds.push(child.id);
    }
  });

  const tabs: TabProps[] = [
    {
      path: `/customers/${parsedId}`,
      title: t('tab.information'),
      testId: 'infoTab',
      icon: <InformationCircleIcon />,
      component: parsedId && <InformationTab {...{ customerData }} />,
    },
    {
      path: `/customers/${parsedId}/contacts`,
      title: t('tab.contacts.text'),
      testId: 'contactsTab',
      icon: <UserCircleIcon />,
      component: parsedId && (
        <ContactsTab id={parsedId} {...{ searchParam, setSearchParam }} />
      ),
    },
    {
      path: `/customers/${parsedId}/policies`,
      title: t('tab.policies.text'),
      testId: 'policiesTab',
      icon: <ClipboardIcon />,
      component: (
        <PolicyTab
          ids={childrenIds ? [parsedId, ...childrenIds] : [parsedId]}
        />
      ),
    },
    {
      path: `/customers/${parsedId}/topics`,
      title: t('tab.topics.text'),
      testId: 'topicsTab',
      icon: <RectangleStackIcon />,
      component: parsedId && (
        <TopicsTab id={parsedId} {...{ searchParam, setSearchParam }} />
      ),
    },
    {
      path: `/customers/${parsedId}/conversations`,
      title: t('tab.conversations.text'),
      testId: 'conversationsTab',
      icon: <ChatBubbleLeftIcon />,
      component: (
        <ConversationTab id={parsedId} {...{ searchParam, setSearchParam }} />
      ),
    },
    ...(environment && FeatureFlags.showTasks(environment)
      ? [
          {
            path: `/customers/${parsedId}/tasks`,
            title: t('tab.tasks.text'),
            testId: 'tasksTab',
            icon: <CalendarDaysIcon />,
            component: parsedId && (
              <TasksTab id={parsedId} {...{ searchParam, setSearchParam }} />
            ),
          },
        ]
      : []),
    {
      path: `/customers/${parsedId}/files`,
      title: t('tab.files.text'),
      testId: 'filesTab',
      icon: <DocumentIcon />,
      component: parsedId && (
        <DocumentTab id={parsedId} {...{ searchParam, setSearchParam }} />
      ),
    },
  ];

  return (
    <Tabs
      tabs={tabs}
      tabCallback={tabCallback}
      type={type}
      className={classnames(
        '!mx-0 [&>div]:gap-8 [&>div>button]:px-0',
        isMobileView ? 'flex-nowrap overflow-auto' : '!overflow-hidden',
        isMobileView ? '[&>*:first-child]:!pl-4' : '[&>*:first-child]:!pl-8',
        '[&>div]:w-full',
        "[&>div[role='tablist']>button]:!px-0",
        className
      )}
    />
  );
};

export default CustomerTabs;
