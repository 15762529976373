import { error_page_not_found } from './../../images';
import { NotificationImage } from '../Conversations';
import { t } from 'i18next';

export const NotFoundPage = () => {
  return (
    <NotificationImage
      imgSrc={error_page_not_found}
      text={t('error.404.title')}
      tooltip={t('error.404.subtitle')}
    />
  );
};

export default NotFoundPage;
