export const currencyFormat = (value: number | undefined) => {
  return value
    ? value
        .toLocaleString('de-CH', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: true,
        })
        .replace(/,/g, "'")
    : value;
};
