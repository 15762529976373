import classnames from 'classnames';

export const statusBadgeBody = (
  showBackground: boolean,
  color?: string,
  hover?: string
) => {
  const borderColor = showBackground ? `border border-${color}` : '';
  return classnames(
    '!cursor-pointer bg-customColor_17 rounded-lg',
    `hover:bg-${hover}`,
    borderColor,
    `text-${color}`
  );
};

export const statusBadgeStyles = {
  headerText: 'font-normal text-sm text-customColor_23',
  body: statusBadgeBody,
  statusText: 'font-medium text-xs indent-2 leading-1.5 select-none',
};
