import {
  CalendarDaysIcon,
  IconButton,
  Stack,
  inputStyle,
  locales,
  secondaryInputStyle,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

export const Deadline = ({
  startDate,
  setStartDate,
}: {
  startDate: DateObject | undefined;
  setStartDate: Dispatch<SetStateAction<DateObject | undefined>>;
}) => {
  const { i18n } = useTranslation();
  const selectedLanguage = locales[i18n.language] ? i18n.language : 'de';
  const today = new DateObject();
  const [show, setShow] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useClickAway(ref, () => {
    isOpen && setShow(false);
    setIsOpen(false);
  });

  useEffect(() => {
    !show && setShow(true);
  }, [show]);

  const picker = (
    <DatePicker
      value={startDate}
      locale={locales[selectedLanguage]}
      onChange={(date: DateObject) => {
        setStartDate(date);
        setIsOpen(false);
        setShow(false);
      }}
      containerClassName="!w-full [&>input]:!w-full"
      placeholder={t('Tasks.tabs.table.columns.deadline')}
      format="DD.MM.YYYY"
      weekStartDayIndex={1}
      onOpenPickNewDate={false}
      inputClass={classnames(
        secondaryInputStyle,
        'text-sm !border-none !border-b text-gray-500'
      )}
      minDate={today}
    />
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      className={classnames(inputStyle)}
      onClick={() => {
        setIsOpen(true);
      }}
    >
      <IconButton hoverable={false} className="!mr-0 !pr-0 !w-7">
        <CalendarDaysIcon />
      </IconButton>
      <div ref={ref} className="w-full">
        {show && picker}
        {!show && picker}
      </div>
    </Stack>
  );
};

export default Deadline;
