import React from 'react';
import cn from 'classnames';
import { ResizableBox, ResizableBoxProps } from 'react-resizable';
import classnames from 'classnames';

import 'react-resizable/css/styles.css'; // Import the styles for react-resizable

export interface StackProps {
  children: React.ReactNode;
  className?: string;
  alignItems?: 'center' | 'stretch' | 'end' | 'start' | 'baseline';
  justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
  direction?: 'col' | 'col-reverse' | 'row' | 'row-reverse';
  onClick?: (e?: any) => void;
  dataTestid?: string;
  isResizable?: boolean;
  resizeProps?: ResizableBoxProps;
  resizeDirection?: 'hor' | 'ver';
}

export const Stack: React.FC<StackProps> = ({
  children,
  alignItems = 'start',
  justifyContent = 'start',
  direction = 'col',
  className,
  onClick = () => ({}),
  dataTestid,
  isResizable,
  resizeProps = { width: 0, height: 0 },
  resizeDirection = 'hor',
}) => {
  const classes = cn(
    className,
    'flex',
    direction && `flex-${direction}`,
    justifyContent && `justify-${justifyContent}`,
    alignItems && `items-${alignItems}`
  );

  if (isResizable) {
    const { width = 0, height = 0 } = resizeProps;
    return (
      <ResizableBox
        {...resizeProps}
        className={classnames(
          'flex relative',
          resizeDirection == 'hor'
            ? 'w-full !h-full flex-row [&>span]:!h-full [&>span]:!w-1 [&>span]:!top-0 [&>span]:!mt-0 [&>span]:!transform-none [&>span]:!bg-none'
            : '!w-full h-full flex-col [&>span]:!w-full [&>span]:!h-1 [&>span]:!left-0 [&>span]:!ml-0 [&>span]:!transform-none [&>span]:!bg-none',
          resizeProps.className
        )}
        width={width}
        height={height}
      >
        <div className={classes} onClick={onClick} data-testid={dataTestid}>
          {children}
        </div>
      </ResizableBox>
    );
  }

  return (
    <div className={classes} onClick={onClick} data-testid={dataTestid}>
      {children}
    </div>
  );
};

export default Stack;
