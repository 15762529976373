import {
  CustomerStyles,
  Stack,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import SettingsBreadCrumb from '@trueconnect/tp-components/src/pages/Settings/SettingsBreadCrumb';
import classnames from 'classnames';
import { HelpHeader } from 'src/components/HelpHeader';
import SettingsTabs from './Tabs/SettingsTabs';
import { t } from 'i18next';

export const SettingsPage = () => {
  const isMobile = useIsSelectedView('sm');
  const isTabletView = useIsSelectedView('md');

  return (
    <div className={classnames(CustomerStyles.wrapper, '!py-1')}>
      <Stack
        direction="col"
        className={classnames(
          isMobile ? '' : 'pt-5 [&>div>div]:mb-0',
          'relative',
          isMobile ? 'px-3' : 'px-8'
        )}
      >
        <SettingsBreadCrumb />
        <Stack
          className={classnames(
            'w-full',
            isMobile && 'mb-0',
            isTabletView && 'mb-2'
          )}
          direction={isMobile ? 'col' : 'row'}
          justifyContent="between"
          alignItems={isMobile || isTabletView ? 'start' : 'center'}
        >
          <Stack
            direction="row"
            className={classnames('w-full h-full')}
            justifyContent="between"
          >
            <p className={classnames(CustomerStyles.header)}>
              {t('sidebar.settings')}
            </p>
          </Stack>
          <HelpHeader />
        </Stack>
      </Stack>
      <SettingsTabs />
    </div>
  );
};

export default SettingsPage;
