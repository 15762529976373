import { getApiQueryMethods } from '@trueconnect/tp-components';
import defaultApiOptions from './defaultApiOptions';
import {
  ApiMutationFunctions,
  ApiQueryFunctions,
  BrokerAppLogic,
  BrokerAppLogicClient,
  queryPageMapping,
} from './BrokerAppLogic';

const brokerApiMethods = getApiQueryMethods<
  BrokerAppLogicClient,
  ApiMutationFunctions,
  ApiQueryFunctions
>(BrokerAppLogic, defaultApiOptions, queryPageMapping);

export const useApiQuery = brokerApiMethods.useApiQuery;
export const useApiMutation = brokerApiMethods.useApiMutation;
export const useApiInfiniteQuery = brokerApiMethods.useApiInfiniteQuery;
export const useApiInfiniteQueryWithPageToken =
  brokerApiMethods.useApiInfiniteQueryWithPageToken;
export const useApiInvalidateQueries = brokerApiMethods.useApiInvalidateQueries;
