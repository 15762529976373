import { MessageProps } from './Message';
import classnames from 'classnames';
import { Attachments } from './Attachments';
import { chatStyles } from './../../../styles';
import { useIsSelectedView } from './../../../utils';

export const UserMessage: React.FC<MessageProps> = (props) => {
  const { message, isOwn } = props;
  const isMobileView = useIsSelectedView('sm');
  return (
    <div
      className={classnames(
        chatStyles.message.default(isMobileView),
        isOwn ? chatStyles.message.my : chatStyles.message.notMy
      )}
    >
      {message.messageText && (
        <h1 className="whitespace-pre-wrap">{message.messageText}</h1>
      )}
      <Attachments {...props} />
    </div>
  );
};

export default UserMessage;
