import { useGetActivePath } from '../../utils';
import { IconButton } from '../../components';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { NavbarItemProps } from '../Sidebar';
import { useStateProvider } from './../../providers';

export const Item: React.FC<{
  item: NavbarItemProps;
  classNames?: string;
}> = ({ item, classNames }) => {
  const activeRootPath = useGetActivePath();
  const navigate = useNavigate();
  const { setSelectedTopic } = useStateProvider().actions;

  const highlight = activeRootPath === item.path ? 'text-customColor_8' : '';
  const sizing = 'w-[30px] h-[30px]';

  const imgClassName = classnames(highlight, sizing);

  const dataTestId =
    item.path == '/' ? 'dashboard' : item.path.replace('/', '');

  return (
    <IconButton
      size="none"
      className={classNames}
      hoverable={false}
      imgClassName={imgClassName}
      dataTestId={`bottombar_${dataTestId}`}
      onClick={() => {
        setSelectedTopic({});
        navigate(item.path);
      }}
    >
      {item.icon}
    </IconButton>
  );
};

export default Item;
