import {
  ArrowUpTrayIcon,
  PlusIcon,
  useIsSelectedView,
  useStateProvider,
} from '@trueconnect/tp-components';
import { IconLabel, Stack } from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

export const DocumentToolbar: React.FC<{
  showCreateNewFolderDialog?: () => void;
}> = ({ showCreateNewFolderDialog }) => {
  const { t } = useTranslation();
  const isMobileView = useIsSelectedView('sm');
  const { setFilesToUpload, setIsUploadPanelVisible } =
    useStateProvider().actions;

  return (
    <Stack
      direction="row"
      className="py-3 bg-customColor_7 mt-px h-[60px] w-full"
      alignItems="center"
    >
      <label htmlFor="file-input" className="ml-4">
        <IconLabel
          hoverable={false}
          className={classnames(
            isMobileView && '[&>span]:text-xs [&>span]:font-medium px-0'
          )}
          title={t('fileToolbar.uploadFiles') as string}
          icon={<ArrowUpTrayIcon />}
        />
      </label>
      <input
        id="file-input"
        type="file"
        multiple
        style={{ display: 'none' }}
        onChange={(e) => {
          setIsUploadPanelVisible(true);
          e.target.files && setFilesToUpload(Array.from(e.target.files));
          e.target.value = '';
        }}
      />
      {showCreateNewFolderDialog && (
        <IconLabel
          title={t('file.action.create.text') as string}
          className={classnames(
            isMobileView && '[&>span]:text-xs [&>span]:font-medium px-0'
          )}
          icon={<PlusIcon />}
          onClick={showCreateNewFolderDialog}
          dataTestid="createFolder"
        />
      )}
      {/* <IconLabel title={t('fileToolbar.share')} icon={<ShareIcon />} />
      <IconLabel title={t('fileToolbar.copyLink')} icon={<LinkIcon />} /> */}
    </Stack>
  );
};

export default DocumentToolbar;
