import {
  CheckIcon,
  CustomerIcon,
  HoldingInfoHeaderClassnames,
  IconButton,
  InsurancesIcon,
  Stack,
  VirtualizedList,
  XCircleIcon,
  cellStyle,
  ChevronRightIcon,
  CustomerStyles,
  useIsSelectedView,
  ColumnType,
  spanClassname,
  ArrowUpCircleIcon,
} from '@trueconnect/tp-components';
import { FC, SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FetchCustomerInfoDto } from 'src/api';
import { Badge } from 'flowbite-react';
import classnames from 'classnames';
import useGetHoldingColumns from './useGetHoldingColumns';
import { MobileViewListItem } from '../../../columns';

export type HoldingInfoProps = {
  data: FetchCustomerInfoDto[];
};

export const HoldingInfo = ({ data }: HoldingInfoProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columns = useGetHoldingColumns();
  const isMobileView = useIsSelectedView('sm');

  const mobileListItem: ColumnType<FetchCustomerInfoDto> = {
    render: (item: FetchCustomerInfoDto) =>
      MobileViewListItem(
        {
          ...item,
          ...{
            id: item.id?.toString() || '',
            manager: item.accountManagerName,
            address: item.localAddress,
          },
        },
        isMobileView
      ),
    dataPropName: 'MobileListItem',
  };

  const onRowClick = (item: FetchCustomerInfoDto) => {
    navigate(`/customers/${item.id}`);
  };

  return (
    <Stack className={classnames('w-full')}>
      <p className={HoldingInfoHeaderClassnames(isMobileView)}>
        {t('customer.holdingInfoHeader')}
      </p>
      <div
        className={classnames(CustomerStyles.wrapper, 'max-h-[450px]')}
        style={{
          height:
            data.length * (isMobileView ? 95 : 70) + (isMobileView ? 0 : 46),
        }}
      >
        <VirtualizedList
          columns={isMobileView ? [mobileListItem] : columns}
          rowHeight={isMobileView ? 95 : 70}
          onRowClick={onRowClick}
          infinityQuery={{
            data: data || [],
            hasNextPage: true,
          }}
          headerHeight={isMobileView ? 0 : 46}
          classNames={{
            header: isMobileView ? 'border-none' : '',
            column: 'pr-5',
          }}
        />
      </div>
    </Stack>
  );
};

export const CompanyName = (item: FetchCustomerInfoDto) => {
  return (
    <div>
      <Stack direction="row" justifyContent="start" className="pl-6">
        <IconButton hoverable={false}>
          <CustomerIcon />
        </IconButton>
        <p className="p-1 font-semibold leading-normal">{item.name}</p>
      </Stack>
    </div>
  );
};

export const Address = (item: FetchCustomerInfoDto) => {
  return (
    <div className="font-medium leading-normal w-full">
      <p className="truncate">
        {item.localAddress}, {item.zipCode} {item.city}
      </p>
    </div>
  );
};

export const Metadata = (item: FetchCustomerInfoDto) => {
  const renderBadge = (
    icon: FC<SVGProps<SVGSVGElement>>,
    color?: string,
    className?: string
  ) => {
    return (
      <Badge
        className={classnames('w-6 h-6 mr-4', className)}
        color={color}
        icon={icon}
      />
    );
  };

  return (
    <Stack direction="row">
      {item.isAHolding &&
        renderBadge(InsurancesIcon, 'info', 'bg-blue-200 text-blue-800')}
      {item.holdingId &&
        renderBadge(ArrowUpCircleIcon, 'info', 'bg-indigo-100 text-blue-900')}
      {item.isCurrentTrueDataCustomer && renderBadge(CheckIcon, 'success')}
      {item.isFormerTrueDataCustomer && renderBadge(XCircleIcon, 'failure')}
    </Stack>
  );
};

export const AccountManager = (item: FetchCustomerInfoDto) => {
  return (
    <div className={classnames(cellStyle)}>
      <span className={classnames(spanClassname, '!text-base font-medium')}>
        {item.accountManagerName}
      </span>
    </div>
  );
};

export const FavoriteButton = (
  item: FetchCustomerInfoDto,
  onClick: (s: string) => void
) => {
  return (
    <div className={classnames(cellStyle)}>
      <IconButton>
        <ChevronRightIcon
          onClick={() => {
            onClick?.(`${item.id}`);
          }}
        />
      </IconButton>
    </div>
  );
};
