import {
  IconButton,
  PlusIcon,
  SmartSpinnerLocal,
  Stack,
  VirtualizedGridCellRendererType,
  bigScrollbar,
  hoverClassname,
  useIsSelectedView,
  useNotificationSystem,
} from '@trueconnect/tp-components';
import { premiumType } from './typing';
import classnames from 'classnames';
import { t } from 'i18next';
import { Actions } from './columns';
import CreatePremiumTypeModal from './ManagePremiumType';
import { useCallback, useEffect, useState } from 'react';
import DeleteModal from 'src/components/DeleteModal';
import {
  FetchPolicyRewardTypeDto,
  useApiInvalidateQueries,
  useApiMutation,
  useApiQuery,
} from 'src/api';
import DndItem from './DndItem';
import update from 'immutability-helper';

export const PremiumTypes = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPremiumType, setSelectedPremiumType] = useState<
    premiumType | undefined
  >();
  const [savedData, setSavedData] = useState<premiumType[]>([]);

  const { data = [], isLoading } = useApiQuery(
    'getAvailablePolicyRewardTypes',
    [],
    {
      onSuccess: (data) => {
        setSavedData(data);
      },
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('premiumTypes.table.cantLoad'),
        });
      },
    }
  );

  useEffect(() => {
    setSavedData(data);
  }, [data?.length, data.map((item) => item.displayName).join('')]);

  const { addNotification } = useNotificationSystem();

  const invalidateQueries = useApiInvalidateQueries();
  const { mutate, isLoading: isDeleting } = useApiMutation(
    'deletePolicyRewardType',
    {
      onSuccess: () => {
        invalidateQueries('getAvailablePolicyRewardTypes');
        setShowDeleteModal(false);
      },
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('premiumTypes.modals.create.error'),
        });
        setShowDeleteModal(false);
      },
    }
  );

  const columns = [
    {
      dataPropName: 'name',
      title: t('premiumTypes.table.name'),
      className: 'w-1/2 text-start',
      render: (item) => <>{item.displayName}</>,
    },
    {
      dataPropName: 'actions',
      title: <p className="pr-2">{t('premiumTypes.table.actions')}</p>,
      className: 'w-1/2 text-end',
      render: (item) => {
        return (
          <Actions
            {...{
              item,
              setIsFormVisible,
              setShowDeleteModal,
              setSelectedPremiumType,
            }}
          />
        );
      },
    },
  ] as VirtualizedGridCellRendererType<premiumType>[];

  const isMobileView = useIsSelectedView('sm');
  const moveItem = (
    dragIndex: number | undefined,
    hoverIndex: number | undefined
  ) => {
    if (dragIndex == undefined || hoverIndex == undefined) return;

    const dragCard = savedData[dragIndex];

    setSavedData(
      update(savedData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      })
    );
  };

  const { mutate: changeOrder } = useApiMutation(
    'changeOrderForPolicyRewardType',
    {
      onSuccess: (dt) => {
        setSavedData(dt as FetchPolicyRewardTypeDto[]);
      },
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('premiumTypes.errorSavingChanges'),
        });
      },
    }
  );

  const onDropItem = useCallback(
    (id: string, index: number) => {
      changeOrder([{ id: id, newOrder: index + 1 }]);
    },
    [savedData, setSavedData]
  );

  return (
    <>
      <div className={classnames('w-full h-full', !isMobileView && 'p-3')}>
        <div
          className={classnames('w-full h-full overflow-hidden flex flex-col')}
        >
          <SmartSpinnerLocal
            condition={isLoading}
            name="getAvailablePolicyRewardTypes"
          >
            <div className="w-full">
              <table className="w-full">
                <thead className="bg-customColor_2 h-[46px]">
                  <tr>
                    {columns.map((column, index) => (
                      <th
                        key={index}
                        className={classnames(
                          column.className,
                          'text-sm font-semibold pl-6 pr-4 leading-normal overflow-x-auto text-customColor_20'
                        )}
                      >
                        {column.title}
                      </th>
                    ))}
                  </tr>
                </thead>
              </table>
            </div>
            <div
              className={classnames(
                'overflow-x-hidden overflow-y-auto !h-auto',
                bigScrollbar
              )}
            >
              <table className="w-full border">
                <tbody>
                  {savedData?.map((item, index) => (
                    <DndItem
                      key={item.id}
                      id={item.id}
                      index={index}
                      moveItem={moveItem}
                      onDropItem={onDropItem}
                    >
                      <>
                        {columns.map((column, columnIndex) => (
                          <td
                            key={columnIndex}
                            className={classnames(
                              column.className,
                              'pl-6 pr-4'
                            )}
                          >
                            {column.render(item)}
                          </td>
                        ))}
                      </>
                    </DndItem>
                  ))}
                </tbody>
              </table>
            </div>

            <Stack
              className="min-h-14 w-full border-x border-b"
              alignItems="center"
              direction="row"
            >
              <Stack
                direction="row"
                alignItems="center"
                className={'pl-5 w-full h-full'}
                dataTestid="createPremiumType"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  className={classnames(hoverClassname, 'p-2 rounded-xl')}
                  onClick={() => {
                    setIsFormVisible(true);
                  }}
                >
                  <IconButton customSize="!w-4 !h-4" className="mr-1">
                    <PlusIcon color="#1C64F2" />
                  </IconButton>
                  <span className="text-base font-medium leading-3 text-[#1C64F2]">
                    {t('premiumTypes.createButton')}
                  </span>
                </Stack>
              </Stack>
            </Stack>
          </SmartSpinnerLocal>
        </div>
      </div>
      <CreatePremiumTypeModal
        {...{
          isVisible: isFormVisible,
          selectedPremiumType,
          close: () => {
            setIsFormVisible(false);
            setSelectedPremiumType(undefined);
          },
        }}
      />
      <DeleteModal
        visible={showDeleteModal}
        onClose={() => {
          setSelectedPremiumType(undefined);
          setShowDeleteModal(false);
        }}
        displayName={selectedPremiumType?.displayName}
        isLoading={isDeleting}
        deleteConfirmationText={t(
          'premiumTypes.modals.delete.confirmationText'
        )}
        onAccept={() => {
          selectedPremiumType?.id && mutate([selectedPremiumType?.id]);
        }}
        acceptBtnText={t('common.delete')}
      />
    </>
  );
};

export default PremiumTypes;
