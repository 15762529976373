import {
  Button,
  IconButton,
  CopyItemIcon,
  LoadingTransitionPage,
  Stack,
  TrashIcon,
  ContactStatus,
  modalStyles,
  useNotificationSystem,
  useStateProvider,
  PencilIcon,
  TextInput,
} from '@trueconnect/tp-components';
import { useCallback, useEffect } from 'react';
import { Label, Tooltip } from 'flowbite-react';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';
import { DialogProps } from '../StatusBadge/states';
import { useApiInvalidateQueries, useApiMutation, useApiQuery } from 'src/api';
import classnames from 'classnames';
import PermissionModal from './PermissionModal';

export const PanelInputElement = ({
  value,
  label,
  tooltipContent,
}: {
  value: string;
  label: string;
  tooltipContent: string;
}) => {
  const [, copyToClipboard] = useCopyToClipboard();
  return (
    <>
      <Label className="text-base font-medium">{label}</Label>
      <Stack alignItems="center" direction="row">
        <TextInput className="w-80" type="text" readOnly={true} value={value} />
        <Tooltip content={tooltipContent} style="light" className="!fixed">
          <IconButton onClick={() => copyToClipboard(value)}>
            <CopyItemIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </>
  );
};

export const InvitationDetail: React.FC<DialogProps> = ({
  close,
  contactId,
  email = '',
  visible,
  className,
}) => {
  const { t } = useTranslation();
  const invalidateQueries = useApiInvalidateQueries();
  const { addNotification } = useNotificationSystem();
  const { customerData } = useStateProvider().state.customer;
  const { setCustomerData, setTogglePermissionModal } =
    useStateProvider().actions;

  const { refetch: inviteContacts, data: invitationUrl } = useApiQuery(
    'inviteContacts',
    [{ email }],
    { enabled: customerData.status === ContactStatus.Invited }
  );

  const { mutate: changeContactStatus } = useApiMutation('changeContactStatus');

  const deleteInvitation = useCallback(async () => {
    await changeContactStatus([contactId, ContactStatus.NotInvited], {
      onSuccess: () => {
        invalidateQueries('searchContacts');
        invalidateQueries('getConversationById');
      },
    });
    addNotification({
      color: 'success',
      title: t('customer.invite.delete.alert.label'),
    });
    setCustomerData({ ...customerData, status: ContactStatus.NotInvited });
  }, [
    addNotification,
    changeContactStatus,
    contactId,
    customerData,
    invalidateQueries,
    setCustomerData,
    t,
  ]);

  useEffect(() => {
    visible && inviteContacts();
  }, [email, inviteContacts, visible]);

  const openInvitationMailto = () => {
    const mailtoLink = `mailto:${email}?subject=${t(
      'customer.invite.mailto.subject'
    )}&body=${t('customer.invite.mailto.title')}%0D%0A%0D%0A${t(
      'customer.invite.mailto.description'
    )}%0D%0A%0D%0A${t(
      'customer.invite.mailto.linkDescription'
    )}%0A${invitationUrl}%0D%0A%0D%0A${t(
      'customer.invite.mailto.regards'
    )}%0A${t('customer.invite.mailto.team')}`;
    window.location.href = mailtoLink;
  };

  return (
    <Modal
      show={!!visible}
      title={t('customer.invite.modal.title')}
      className={classnames(className, modalStyles)}
      footer={
        <>
          <Button className="w-1/2" color="info" onClick={openInvitationMailto}>
            {t('customer.invite.modal.buttonOnAccept')}
          </Button>
          <Button className="w-1/2" color="gray" onClick={close}>
            {t('customer.invite.modal.buttonOnClose')}
          </Button>
        </>
      }
      onClose={() => {
        close();
      }}
    >
      <span className="font-light">
        {t('customer.invite.modal.description')}
      </span>
      <Stack className="relative">
        {!invitationUrl && (
          <div className="w-full h-full absolute flex flex-col justify-center">
            <LoadingTransitionPage />
          </div>
        )}
        <div
          className={`flex flex-col gap-4 pt-4 pb-2 ${
            !invitationUrl && 'invisible'
          }`}
        >
          <PanelInputElement
            label={t('customer.invite.modal.email.lable')}
            tooltipContent={t('customer.invite.modal.tooltipContent.email')}
            value={email}
          />
          <PanelInputElement
            label={t('customer.invite.modal.link.lable')}
            tooltipContent={t('customer.invite.modal.tooltipContent.link')}
            value={invitationUrl || ''}
          />
        </div>
        <Stack
          className="pt-6 cursor-pointer"
          direction="row"
          alignItems="center"
        >
          <PencilIcon className="w-4 w-4 text-blue-500" />
          <a
            onClick={() => {
              setTogglePermissionModal(true);
            }}
            className="text-blue-500 text-sm hover:underline pl-1"
          >
            {t('permissions.button')}
          </a>
        </Stack>

        <Stack
          className="pt-6 cursor-pointer"
          direction="row"
          alignItems="center"
        >
          <TrashIcon className="w-4 w-4 text-blue-500" />
          <a
            onClick={deleteInvitation}
            className="text-blue-500 text-sm hover:underline pl-1"
          >
            {t('customer.invite.delete.label')}
          </a>
        </Stack>
      </Stack>
      <PermissionModal />
    </Modal>
  );
};

export default InvitationDetail;
