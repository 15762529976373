import {
  BranchesList,
  DropDownItemType,
  Dropdown,
  EditableTitle,
  EllipsisHorizontalIcon,
  IconButton,
  PencilIcon,
  PuzzlePieceIcon,
  Stack,
  TrashIcon,
  getBranches,
  hiddenScrollbar,
  useIsSelectedRange,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import { TopicType } from '@trueconnect/tp-components/src/providers/WorkManagement/typing';
import {
  UpdateTopicDto,
  useApiInvalidateQueries,
  useApiMutation,
} from './../../../../api';
import { t } from 'i18next';
import {
  ChangeBranchesModal,
  ChangeTitleModal,
} from 'src/components/conversation';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import DeleteModal from 'src/components/DeleteModal';

export const TopicHeader = ({
  item,
  conversationsFound,
  isMobileView,
}: {
  item: TopicType;
  conversationsFound?: number;
  isMobileView?: boolean;
}) => {
  const invalidateQueries = useApiInvalidateQueries();
  const navigate = useNavigate();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { addNotification } = useNotificationSystem();
  const { state, actions } = useStateProvider();
  const {
    selectedTopic,
    availableBranches = [],
    showChangeBranches,
  } = state.workManagement;
  const { showChangeTitleDialog } = state.conversation;
  const { branchIds = [], childrenBranchIds } = selectedTopic;
  const {
    setSelectedBranches,
    setShowChangeBranchesDialog,
    setNotEditableBranches,
    setShowChangeTitleDialog,
  } = actions;
  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('getTopicById');
    await invalidateQueries('searchTopics');
  }, [invalidateQueries]);

  const { mutate: updateTopic, isLoading } = useApiMutation('updateTopic', {
    onSuccess: () => {
      invalidateQuery();
    },
    onError: () => {},
  });

  const close = () => {
    setShowChangeTitleDialog(false);
  };

  const saveTopic = (title: string, branchIds: string[]) => {
    item.id &&
      updateTopic(
        [
          {
            id: item.id,
            branchIds: branchIds,
            displayName: title,
          } as UpdateTopicDto,
        ],
        {
          onSuccess: () => {
            setShowChangeBranchesDialog(false);
            close();
            setSelectedBranches([]);
            setNotEditableBranches([]);
          },
          onError: () => {
            addNotification({
              color: 'failure',
              title: t('Topics.modals.updateTopic.error'),
            });
          },
        }
      );
  };

  const isTabletView = useIsSelectedRange(['sm', 'md', 'lg']);

  const invalidateDeleteQuery = useCallback(async () => {
    await invalidateQueries('searchTopics');
  }, [invalidateQueries]);

  const { mutate: mutateDeleteTopic } = useApiMutation('deleteTopic', {
    onSuccess: () => {
      navigate('/topics');
      invalidateDeleteQuery();
    },
    onError: () => {
      addNotification({
        color: 'failure',
        title: t('Topics.modals.deleteTopic.error'),
      });
    },
  });

  const editBranches = {
    icon: <PuzzlePieceIcon data-testid="editBranches" />,
    text: t('conversationPage.branches'),
    onClick: () => {
      setSelectedBranches(getBranches(availableBranches, branchIds));
      setNotEditableBranches(getBranches(availableBranches, childrenBranchIds));
      setShowChangeBranchesDialog(true);
    },
  };

  const editTitle = {
    icon: <PencilIcon data-testid="editTitle" />,
    text: t('common.modals.changeTitle'),
    onClick: () => {
      setShowChangeTitleDialog(true);
    },
  };

  const deleteTopic = {
    icon: <TrashIcon data-testid="deleteTopic" />,
    color: '#E53935',
    text: t('Topics.modals.deleteTopic.option'),
    onClick: () => {
      setShowDeleteDialog(true);
    },
  };

  return (
    <Stack
      className={classnames(
        'border-b w-full h-[160px]',
        isMobileView ? 'pt-3 px-2' : 'py-6 px-8'
      )}
    >
      <div className={classnames('w-full overflow-x-auto', hiddenScrollbar)}>
        <BranchesList
          {...{
            ...item,
            canEditBranches: true,
            nameTp: isTabletView ? 'shortName' : 'convenientName',
          }}
        />
      </div>
      <Stack
        className="w-full"
        direction="row"
        alignItems="center"
        justifyContent="between"
      >
        <EditableTitle
          title={item?.displayName || ''}
          onSave={(title) => saveTopic(title, item.branchIds || [])}
          isSaving={isLoading && !showChangeBranches}
          isEditable={!isMobileView}
          notEmptyWarning={t('Topics.topicTitle.warning.isEmpty')}
          inputClassName="!pb-0"
          className="!h-12 [&>div>.pencil]:pt-[15px]"
        />
        <Dropdown
          defaultItemClassnames="text-customColor_9"
          mobileView={isMobileView}
          label={
            <Stack direction="row" className="mr-1">
              <IconButton
                hoverable={false}
                dataTestId="TopicActions"
                customSize={'w-6 h-6'}
              >
                <EllipsisHorizontalIcon className="w-6 h-6 text-black" />
              </IconButton>
            </Stack>
          }
          items={
            [
              editBranches,
              isMobileView && editTitle,
              conversationsFound === 0 && deleteTopic,
            ].filter((item) => !!item) as DropDownItemType[]
          }
        />
      </Stack>
      {!!conversationsFound && (
        <span
          className={
            'text-customColor_4 w-full pt-1 font-normal text-xs leading-normal'
          }
        >
          {t('Topics.Details.conversationNumber', {
            conversationNumber: conversationsFound,
          })}
        </span>
      )}

      <ChangeBranchesModal
        type="topic"
        isLoading={isLoading}
        onSave={(branches) => {
          saveTopic(
            item.displayName || '',
            branches.map((item) => item.id || '')
          );
        }}
      />
      <ChangeTitleModal
        {...{
          updateTitle: (value) => saveTopic(value, item.branchIds || []),
          isLoading,
          title: selectedTopic.displayName,
          isVisible: showChangeTitleDialog,
          close,
        }}
      />
      <DeleteModal
        visible={showDeleteDialog}
        displayName={selectedTopic.displayName}
        header={t('Topics.modals.deleteTopic.option')}
        deleteConfirmationText={t(
          'Topics.modals.deleteTopic.deleteConfirmationText'
        )}
        onClose={() => {
          setShowDeleteDialog(false);
        }}
        onAccept={() => {
          selectedTopic.id && mutateDeleteTopic([selectedTopic.id]);
        }}
      />
    </Stack>
  );
};

export default TopicHeader;
