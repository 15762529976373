import React, { useRef, useState } from 'react';
import { bigScrollbar, hoverClassname } from '../../styles';
import classnames from 'classnames';
import { PolicyTableProps, PolicyType } from './typing';
import styles from './ResizableTable.module.scss';
import { SmartSpinnerLocal } from './../../providers';

export const ResizableTable = ({
  columns,
  onRowClick,
  headerClassName,
  nothingFound,
  isSearching,
  queryPolicies,
  policies,
}: PolicyTableProps & {
  policies: PolicyType[];
  nothingFound?: React.ReactNode;
}) => {
  const totalWidthRatio = columns.reduce(
    (sum, col) => sum + (col.widthRatio || 1),
    0
  );
  const tableRef = useRef<HTMLTableElement>(null);
  const [resizing, setResizing] = useState(false);
  const [onMouseMove, setOnMouseMove] = useState<
    ((event: MouseEvent) => void) | null
  >(null);

  const handleResizeStart = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    colIndex: number
  ) => {
    setResizing(true);

    const table = tableRef.current;
    if (!table) return;

    const { clientX } = event;
    const col = table.rows[0].cells[colIndex];
    const nextCol = col.nextElementSibling;

    const colWidth = col.offsetWidth;
    const nextColWidth = (nextCol as HTMLDivElement)?.offsetWidth ?? 0;

    const onMouseMove = (moveEvent: MouseEvent) => {
      const diffX = moveEvent.clientX - clientX;

      if (nextCol) {
        (nextCol as HTMLDivElement).style.width = `${nextColWidth - diffX}px`;
      }
      col.style.width = `${colWidth + diffX}px`;
    };

    setOnMouseMove(() => onMouseMove);

    const onMouseUp = () => {
      setResizing(false);
      table.removeEventListener('mousemove', onMouseMove);
      setOnMouseMove(null);
    };

    table.addEventListener('mousemove', onMouseMove);
    table.addEventListener('mouseup', onMouseUp);
  };

  const handleSpanClick = () => {
    setResizing(true);
  };

  const handleHeadLeave = () => {
    if (resizing && onMouseMove) {
      setResizing(false);
      const table = tableRef.current;
      if (!table) return;
      table.removeEventListener('mousemove', onMouseMove);
      setOnMouseMove(null);
    }
  };

  const isTableResizable = true;

  return (
    <SmartSpinnerLocal condition={queryPolicies.isLoading && !isSearching}>
      <div
        className={classnames(
          'w-full h-full overflow-y-auto overflow-x-hidden relative',
          bigScrollbar
        )}
      >
        <table
          ref={tableRef}
          className={classnames('border border-collapse w-full table-fixed')}
          onMouseLeave={handleHeadLeave}
        >
          <thead
            className={classnames(
              headerClassName,
              'text-gray-600 bg-customColor_2 h-[46px] font-inter text-sm font-semibold sticky -top-[2px]'
            )}
          >
            <tr>
              {columns.map((col, idx) => {
                const widthRatio = (col.widthRatio || 1) / totalWidthRatio;

                return (
                  <th
                    key={col.dataPropName}
                    style={{
                      width: `${widthRatio * 100}%`,
                      minWidth: `${widthRatio * 100}%`,
                    }}
                    className={classnames(
                      col.className,
                      '!min-w-[150px] pl-2 pr-4 leading-normal select-none whitespace-nowrap text-start'
                    )}
                  >
                    {col.title}
                    {idx < columns.length - 1 && isTableResizable && (
                      <span
                        className={classnames(
                          styles.resize_handle,
                          'bg-customColor_2'
                        )}
                        onMouseDown={(e) =>
                          handleResizeStart(e, columns.indexOf(col))
                        }
                        onClick={handleSpanClick}
                      />
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          {!!policies.length && (
            <tbody>
              {policies.map((row) => (
                <>
                  <tr
                    key={row.id}
                    className={classnames(
                      'border-b h-[60px] cursor-pointer',
                      '[&:hover>td>div>.arrowIcon]:block',
                      hoverClassname
                    )}
                    onClick={() => onRowClick && onRowClick(row)}
                  >
                    {columns.map((col) => (
                      <td
                        className="px-2"
                        key={`${row.id}_${col.dataPropName}`}
                      >
                        {col.render(row)}
                      </td>
                    ))}
                  </tr>
                </>
              ))}
              <tr />
            </tbody>
          )}
        </table>
        {!policies.length && isSearching && (
          <SmartSpinnerLocal condition={queryPolicies.isLoading} name="spinner">
            <div className="w-full h-[calc(100%-80px)]">
              <>{nothingFound}</>
            </div>
          </SmartSpinnerLocal>
        )}
      </div>
    </SmartSpinnerLocal>
  );
};

export default ResizableTable;
