import { PathHeader, useIsSelectedView } from '@trueconnect/tp-components';
import { ComponentProps } from 'react';
import classnames from 'classnames';
import { HomeIcon } from '@heroicons/react/24/solid';

export type breadCrumbType = {
  name: string;
  path: string;
  icon?: React.FC<ComponentProps<'svg'>>;
};

export const encode = (data: breadCrumbType[]) => btoa(JSON.stringify(data));
export const decode = (data: string) => JSON.parse(atob(data));

export const initialBreadcrumb = {
  name: 'Documents',
  path: '',
  icon: HomeIcon,
} as breadCrumbType;

export const DocumentBreadCrumb: React.FC<{
  pathname: string;
  items: breadCrumbType[];
  classNames?: string;
}> = ({ pathname, items, classNames }) => {
  const params = items.map((item) => ({
    name: item.name,
    icon: item.icon,
    path: ['/', undefined].includes(item.path)
      ? ''
      : `${pathname}#${item.path}`,
  }));

  const isMobileView = useIsSelectedView('sm');

  return (
    <div
      className={classnames(
        'pt-2 h-15 w-full overflow-y-hidden',
        isMobileView && '!h-12',
        classNames
      )}
    >
      <PathHeader
        params={params}
        showHomeIcon={false}
        classNames={classnames('px-1', isMobileView && '!mb-1')}
      />
    </div>
  );
};

export default DocumentBreadCrumb;
