import {
  relativeFull,
  TaskType,
  VirtualizedGridCellRendererType,
  VirtualizedList,
} from '@trueconnect/tp-components';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import classnames from 'classnames';
import { SearchOrderBy } from 'src/api';

export const BacklogTable = ({
  tasksQuery,
  columns,
  orderBy,
  onRowClick = () => {},
}: {
  tasksQuery: UseInfiniteQueryResult<TaskType[], Error>;
  columns: VirtualizedGridCellRendererType<TaskType>[];
  orderBy?: SearchOrderBy | undefined;
  onRowClick?: (item: TaskType) => void;
}) => {
  return (
    <>
      <div className={classnames(relativeFull)}>
        <VirtualizedList
          columns={columns}
          headerHeight={40}
          isSearching={!!orderBy}
          infinityQuery={tasksQuery}
          classNames={{
            column: 'first:ml-0',
            row: 'cursor-pointer',
          }}
          rowHeight={50}
          onRowClick={onRowClick}
        />
      </div>
    </>
  );
};
