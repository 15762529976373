/* eslint-disable react-hooks/exhaustive-deps */
import {
  setUnsentMessagesAttachments,
  useApiUpload,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import { useCallback, useEffect } from 'react';
import { Query, useQueryClient } from '@tanstack/react-query';
import { useApiInvalidateQueries, useApiMutation, useApiQuery } from 'src/api';

type UploadManagerProps = {
  children: React.ReactNode;
  id: string;
};

export const UploadManagerConversation: React.FC<UploadManagerProps> = ({
  children,
  id,
}) => {
  const { actions, state } = useStateProvider();
  const { addNotification } = useNotificationSystem();
  const invalidateQueries = useApiInvalidateQueries();
  const { conversationId } = state.conversation.conversationData;
  const { currentUploadFile, filesToUpload, isUploading } = state.uploads;
  const { selectedFiles } = useStateProvider().state.uploads;

  const queryClient = useQueryClient();

  const {
    setCurrentUploadFileData,
    setFilesToUpload,
    setIsUploading,
    setSelectedFiles,
  } = actions;

  const createSessionQuery = useApiQuery(
    'creates_Upload_Session_For_Conversation',
    [
      id,
      {
        uploadFileName: currentUploadFile.file?.name,
      },
    ],
    { enabled: false }
  );

  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('search_Conversation_Documents');
  }, [invalidateQueries]);

  const removeSession = useCallback(() => {
    queryClient.removeQueries({
      predicate: (query: Query) =>
        query.queryKey[0] === 'creates_Upload_Session_For_Conversation',
    });
  }, [queryClient]);

  const { mutate: closeUploadSession } = useApiMutation(
    `close_Upload_Session_For_Conversation`,
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (_, [_customerId, documentId]) => {
        removeSession();
        const fileName = currentUploadFile.file?.name;
        if (fileName) {
          const data = [...selectedFiles, { title: fileName, id: documentId }];
          setSelectedFiles(data);
          setUnsentMessagesAttachments(conversationId, data);
        }
        setCurrentUploadFileData({ progress: 0, cancel: () => ({}) });
        fileName &&
          addNotification({
            title: t('fileToolbar.upload.success', {
              filename: currentUploadFile?.file?.name,
            }),
            color: 'success',
          });
        setIsUploading(false);
        invalidateQuery();
      },
    }
  );

  const { progress, cancel } = useApiUpload<string>(
    id,
    createSessionQuery,
    (id: string, documentId: string) => {
      closeUploadSession([id, documentId]);
    },
    setIsUploading,
    (error) => {
      setFilesToUpload([]);
      addNotification({
        title: error,
        color: 'failure',
      });
    },
    currentUploadFile.file,
    isUploading
  );

  useEffect(() => {
    // setIsUploadPanelVisible(
    //   isUploading || filesToUpload.length > 0 || !!currentUploadFile.file
    // );
    if (
      filesToUpload.length > 0 &&
      !isUploading &&
      !currentUploadFile.file &&
      !!id
    ) {
      setCurrentUploadFileData({
        file: filesToUpload[0],
        cancel: () => ({}),
        progress: 0,
      });
      setFilesToUpload(filesToUpload.slice(1));
    }
  }, [filesToUpload.length, currentUploadFile.file, id, isUploading]);

  useEffect(() => {
    id &&
      isUploading &&
      setCurrentUploadFileData({
        ...currentUploadFile,
        cancel: () => {
          setFilesToUpload([]);
          setIsUploading(false);
          cancel();
          setCurrentUploadFileData({ progress: 0, cancel: () => ({}) });
        },
        progress,
      });
  }, [progress]);

  return <>{children}</>;
};

export default UploadManagerConversation;
