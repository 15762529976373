import React, { useEffect } from 'react';
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';

type CustomAuthenticatedTemplateType = {
  children: React.ReactNode;
};

export const redirectUrlParameter = 'reurl';

export const isLoading = (inProgress: InteractionStatus) =>
  inProgress === InteractionStatus.Startup ||
  inProgress === InteractionStatus.HandleRedirect ||
  inProgress === InteractionStatus.Logout;

export const CustomAuthenticatedTemplate: React.FC<
  CustomAuthenticatedTemplateType
> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { inProgress } = useMsal();

  useEffect(() => {
    if (isLoading(inProgress)) {
      return;
    }

    if (!isAuthenticated) {
      const redirectUrl = `/login?${redirectUrlParameter}=${encodeURIComponent(
        window.location.href
      )}`;
      navigate(redirectUrl);
    }
  }, [navigate, isAuthenticated, inProgress]);

  return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
};

export default CustomAuthenticatedTemplate;
