import { Button, IconButton } from '../../../components';
import Modal from '../../Modal';
import { ProgressDialog, Stack } from '../..';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStateProvider } from './../../../providers';
import { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import TextArea from '../../../components/TextArea';
import SelectedFilesComponent from './SelectedFilesComponent';
import {
  deleteUnsentMessage,
  setUnsentMessagesText,
  useIsSelectedRange,
  useIsSelectedView,
} from './../../../utils';
import classnames from 'classnames';
import UploadButton from './UploadButton';
import UploadModalTabs, { documentsTabProps } from './Tabs/UploadTabs';
import ExistingFilesButton from './ExistingFiles/ExistingFilesButton';
import { ArrowDownTrayIcon } from './../../../images';
import { mobileModalStyles } from './../../../styles';

type chatProps = {
  sendMessage: (
    message: string,
    attachments?: { id?: string | undefined; title?: string | undefined }[]
  ) => void;
};

export const UploadModal: React.FC<chatProps & documentsTabProps> = (props) => {
  const { sendMessage, FilesTableProps } = props;
  const { t } = useTranslation();
  const { state, actions } = useStateProvider();

  const { currentUploadFile, isUploading } = state.uploads;
  const { message } = state.chat;
  const { conversationId } = state.conversation.conversationData;
  const {
    isUploadPanelVisible = false,
    selectedFiles,
    filesToUpload,
  } = state.uploads;

  const {
    setIsUploadPanelVisible,
    setSelectedFiles,
    setIsUploading,
    setMessage,
  } = actions;

  const handleSend = () => {
    sendMessage(message, selectedFiles);
    close();
  };

  const close = useCallback(() => {
    currentUploadFile.cancel();
    setMessage('');
    setSelectedFiles([]);
    setIsUploadPanelVisible(false);
    deleteUnsentMessage(conversationId);
  }, [
    conversationId,
    currentUploadFile,
    setIsUploadPanelVisible,
    setMessage,
    setSelectedFiles,
  ]);

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey && !!selectedFiles.length) {
        handleSend();
        e.preventDefault();
      }
    }
  };

  const uploadModalTabsProps = useMemo(() => {
    return {
      uploading: isUploading,
      progress: currentUploadFile.progress,
      cancel: currentUploadFile.cancel,
      FilesTableProps,
    };
  }, [FilesTableProps, currentUploadFile, isUploading]);

  const isMobileView = useIsSelectedView('sm');
  const isTabletView = useIsSelectedRange(['sm', 'md']);

  const [isProgressVisible, setIsProgressVisible] = useState(true);

  useEffect(() => {
    setIsProgressVisible(!!filesToUpload.length || !!currentUploadFile.file);
  }, [currentUploadFile.file, filesToUpload.length, isUploading]);

  return (
    <>
      <Modal
        show={!!isUploadPanelVisible}
        size="5xl"
        className={classnames(
          isMobileView && '[&>div>div>div:nth-child(2)]:p-0',
          mobileModalStyles(isMobileView)
        )}
        title={t('uploadPanel.title')}
        footer={
          <Stack direction="row" justifyContent="end" className="w-full">
            <Button
              className="w-60"
              color="gray"
              onClick={handleSend}
              disabled={!!(!selectedFiles.length || !filesToUpload)}
            >
              {t('uploadPanel.send')}
            </Button>
          </Stack>
        }
        onClose={() => {
          if (selectedFiles.length) {
            sendMessage(
              message ? message : t('uploadPanel.defaultMessage'),
              selectedFiles
            );
          }
          close();
        }}
      >
        <Stack
          className="w-full h-full gap-2"
          direction={isTabletView ? 'col' : 'row'}
        >
          {!isTabletView && (
            <div className={`w-full h-[400px]`}>
              <UploadModalTabs {...uploadModalTabsProps} />
            </div>
          )}
          {isTabletView && (
            <Stack
              direction="col"
              className="w-full gap-3 px-2 pb-3"
              justifyContent="evenly"
            >
              <UploadButton>
                <IconButton size="none">
                  <ArrowDownTrayIcon />
                </IconButton>
              </UploadButton>
              <ExistingFilesButton {...uploadModalTabsProps} />
            </Stack>
          )}
          <Stack
            direction="col"
            className={classnames('w-full h-full', isTabletView && 'px-2 pt-2')}
          >
            <Stack
              className={classnames(
                'w-full',
                isMobileView ? 'h-full' : `h-[400px]`
              )}
            >
              <SelectedFilesComponent />
              <TextArea
                placeholder={t('uploadPanel.placeholder') as string}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  setUnsentMessagesText(conversationId, e.target.value);
                }}
                onKeyDown={onKeyDown}
              />
            </Stack>
          </Stack>
        </Stack>
      </Modal>
      {isUploadPanelVisible && isTabletView && (
        <ProgressDialog
          type="secondary"
          uploading={isProgressVisible}
          onClose={() => {
            setIsUploading(false);
            setIsProgressVisible(false);
          }}
        />
      )}
    </>
  );
};

export default UploadModal;
