import { useEffect, useRef } from 'react';
import { useLocation } from 'react-use';

type FitContentTextAreaType = {
  limit: number;
  value: string;
  className: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  focusTrigger?: boolean;
  placeholder?: string;
};

export const FitContentTextArea = (props: FitContentTextAreaType) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (textareaRef.current && !props.disabled) {
      textareaRef.current.style.height = '42px';
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        props.limit
      )}px`;
    }
  });

  useEffect(() => {
    textareaRef.current?.focus();
  }, [textareaRef, location.pathname]);

  return <textarea autoFocus ref={textareaRef} {...props} />;
};

export default FitContentTextArea;
