import {
  IconButton,
  ExclamationTriangleIcon,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { Tooltip } from 'flowbite-react';
import { t } from 'i18next';
import classnames from 'classnames';

export const WarningIcon = ({
  content,
  hoverable = false,
  className,
  tooltipClassName,
  dataTestId,
}: {
  content: string;
  hoverable?: boolean;
  className?: string;
  tooltipClassName?: string;
  dataTestId?: string;
}) => {
  const isMobileView = useIsSelectedView('sm');
  return (
    <Tooltip
      content={content}
      style="light"
      placement="auto"
      arrow={false}
      className={classnames(
        '!fixed',
        isMobileView && 'whitespace-normal w-fit',
        tooltipClassName
      )}
    >
      <IconButton hoverable={hoverable} className={className}>
        <ExclamationTriangleIcon data-testid={dataTestId} />
      </IconButton>
    </Tooltip>
  );
};

export const ConversationAccessWarning = () => {
  return (
    <WarningIcon
      content={t('permissions.ConversationAccessWarning')}
      dataTestId="ConversationAccessWarning"
    />
  );
};

export const DocumentAccessWarning = () => {
  return (
    <WarningIcon
      content={t('permissions.DocumentAccessWarning')}
      dataTestId="DocumentAccessWarning"
    />
  );
};

export const NotActiveInTrueDataWarning = () => {
  return (
    <WarningIcon
      content={t('contactNotActiveInTrueData')}
      className="text-red-500"
      tooltipClassName="pr-5"
      dataTestId="contactNotActiveInTrueData"
    />
  );
};

export const NoEmailWarning = () => {
  return (
    <WarningIcon
      content={t('contactHasNoEmailInTrueData')}
      className="text-red-500"
      dataTestId="contactHasNoEmailInTrueData"
    />
  );
};

export default WarningIcon;
