import {
  ContextHelpIcon,
  CreateConversationButton,
  MaintenanceInfo,
  NavbarLayout,
  NotificationIcon,
  Stack,
  UserAvatarDropdown,
  true_connect,
  useIsSelectedRange,
  useIsSelectedView,
  useStateProvider,
} from '@trueconnect/tp-components';

import { useNavigate } from 'react-router-dom';
import CreateConversationPanelBroker from './CreateConversationPanelBroker';
import docs from './../pages/App/Help/help.json';
import { HelpType } from '@trueconnect/tp-components/src/pages/Help';
import { blobStorageAccountName, maintenanseFolder } from 'src/authConfig';

export const UserNavbar = () => {
  const navigator = useNavigate();
  const { showCreateConversation } = useStateProvider().state.conversation;

  const goToDashboard = (e: React.FormEvent) => {
    e.preventDefault();
    navigator('/');
  };

  const isMobile = useIsSelectedView('sm');
  const isTabletView = useIsSelectedRange(['sm', 'md', 'lg']);

  return (
    <>
      {isTabletView && (
        <MaintenanceInfo
          mode="banner"
          blobStorageAccountName={blobStorageAccountName}
          maintenanseFolder={maintenanseFolder}
        />
      )}
      <NavbarLayout
        logos={[
          <img
            key="true_connect"
            src={true_connect}
            alt="True Partners"
            width={isMobile ? '90' : '120'}
            height={isMobile ? '24' : '32'}
            className={'cursor-pointer select-none ml-2'}
            onClick={goToDashboard}
          />,
        ]}
      >
        {!isTabletView && (
          <MaintenanceInfo
            mode="info"
            blobStorageAccountName={blobStorageAccountName}
            maintenanseFolder={maintenanseFolder}
          />
        )}
        <Stack direction="row" alignItems="center">
          {!isMobile && <CreateConversationButton />}
          <ContextHelpIcon {...{ docs: docs as HelpType[] }} />
          <NotificationIcon />
          <UserAvatarDropdown />
          {showCreateConversation && <CreateConversationPanelBroker />}
        </Stack>
      </NavbarLayout>
    </>
  );
};

export default UserNavbar;
