import dayjs from 'dayjs';

export const dateFormat = (str: Date | undefined, primaryView?: boolean) => {
  if (!str) {
    return;
  }
  return dayjs(str).format(primaryView ? 'DD.MM.YYYY' : 'DD.MM.YYYY, HH:mm');
};

export default dateFormat;
