import {
  Radio,
  SearchInput,
  Stack,
  VirtualizedList,
  commonText,
} from '@trueconnect/tp-components';
import FilterCell from '@trueconnect/tp-components/src/components/ExtendedSearch/FilterCell';
import { useState } from 'react';
import { useApiInfiniteQuery } from 'src/api';
import { customerFilterType } from './typing';
import { t } from 'i18next';
import classnames from 'classnames';

export const CustomerSearch = ({
  selectedCustomer,
  setSelectedCustomer,
}: {
  selectedCustomer?: number;
  setSelectedCustomer?: (value: number) => void;
}) => {
  const [searchParam, setSearchParam] = useState('');
  const [enabled, setEnabled] = useState(false);

  const queryCustomers = useApiInfiniteQuery(
    'searchCustomers',
    [searchParam, undefined, 0],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map(
            (subitem) =>
              ({
                ...subitem,
                conversations: 3,
              } as customerFilterType)
          )
        ),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled,
    }
  );

  return (
    <FilterCell
      title={t('sidebar.customers')}
      setEnabled={setEnabled}
      active={true}
      dataTestId="extendedSearchCustomerFilter"
    >
      <div className="px-4 w-full">
        <SearchInput
          isMobileView={true}
          classNames={{
            wrapper: 'bg-white',
            input: 'bg-white text-sm',
            icon: 'mx-0 px-0 [&>div]:m-0 !w-7',
          }}
          placeholder={t(
            'conversation.extendedSearch.customerSearch.inputPlaceholder'
          )}
          setSearchParam={setSearchParam}
        />
      </div>
      <Stack
        className="w-full h-full pt-3 px-3"
        isResizable={true}
        resizeDirection="ver"
        resizeProps={{
          width: -1,
          height: 180,
          resizeHandles: ['s'],
          minConstraints: [-1, 140],
          maxConstraints: [-1, 380],
        }}
      >
        <VirtualizedList
          infinityQuery={queryCustomers}
          headerHeight={0}
          rowHeight={30}
          classNames={{
            row: 'border-none',
          }}
          columns={[
            {
              dataPropName: 'item',
              render: (item) => (
                <Stack
                  key={item.id}
                  direction="row"
                  className="cursor-pointer overflow-hidden customerSearchItem"
                  alignItems="center"
                  onClick={() => {
                    setSelectedCustomer?.(item.id);
                  }}
                >
                  <Radio
                    className="mr-2 ml-1 my-1"
                    checked={item.id == selectedCustomer}
                    onChange={() => ({})}
                  />
                  <p
                    className={classnames(
                      commonText,
                      'text-sm !text-[#4B5563] leading-normal pt-[2px]'
                    )}
                  >
                    {item.name}
                  </p>
                </Stack>
              ),
            },
          ]}
        />
      </Stack>
    </FilterCell>
  );
};

export default CustomerSearch;
