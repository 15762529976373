import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import IconButton from '../../IconButton';
import { Stack } from '../../../controls';
import { useStateProvider } from '../../../providers';
import { InvoiceConfirmedType } from '../../../providers/HubListener/typing';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import { useQueryClient } from '@tanstack/react-query';

export const InvoiceConfirmed = ({
  notification,
  id,
  date,
}: {
  notification: InvoiceConfirmedType;
  id: string;
  date: Date | string;
}) => {
  const { RemoveNotification } = useStateProvider().actions;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const client = useQueryClient();

  const { invoiceId, policyId } = notification;

  return (
    <>
      <Stack
        direction="row"
        alignItems="start"
        justifyContent="between"
        className="w-full"
      >
        <Stack direction="col">
          {date && <h1 className="font-semibold">{date.toString()}</h1>}
          <h1 className="text-left">
            {t('Notifications.InvoiceConfirmed.text')}
          </h1>
          <Stack direction="row" className="gap-2 mt-2 w-full">
            <Button
              size="xs"
              className="w-[120px] cursor-pointer"
              onClick={() => {
                if (location.pathname.includes(`/policies/${policyId}`))
                  client.invalidateQueries({
                    queryKey: ['getInvoicesByPolicyId'],
                  });
                else navigate(`/policies/${policyId}`);
                RemoveNotification(id);
              }}
            >
              {t('Notifications.InvoiceConfirmed.openButton')}
            </Button>

            <Button
              size="xs"
              className="w-[120px] cursor-pointer"
              onClick={() => {
                navigate(`/policies/${policyId}?download=${invoiceId}`);
                RemoveNotification(id);
              }}
            >
              {t('Notifications.InvoiceConfirmed.loadButton')}
            </Button>
          </Stack>
        </Stack>
        <IconButton className="pr-0 mr-0" hoverable={false}>
          <ArrowRightIcon />
        </IconButton>
      </Stack>
    </>
  );
};
