import { AppPage, BottomBar, Sidebar } from '@trueconnect/tp-components';
import Routes from 'src/pages/App/AppRoutes';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useSidebarConfig } from 'src/components/SidebarConfig';
import UserNavbar from './../../components/UserNavbar';
import { Suspense } from 'react';
import AppProviders from './AppProviders';

const runReactQueryDevTools = false;

export const App: React.FC = () => {
  const sidebarConfig = useSidebarConfig();
  const bottombarConfig = sidebarConfig.flat(1);

  return (
    <Suspense>
      <AppProviders>
        <AppPage>
          <AppPage.Header>
            <UserNavbar />
          </AppPage.Header>
          <AppPage.Sidebar>
            <Sidebar groups={sidebarConfig} />
          </AppPage.Sidebar>
          <AppPage.Content>
            <Routes />
          </AppPage.Content>
          <AppPage.BottomBar>
            <BottomBar list={bottombarConfig} />
          </AppPage.BottomBar>
        </AppPage>
        {runReactQueryDevTools && <ReactQueryDevtools initialIsOpen={false} />}
      </AppProviders>
    </Suspense>
  );
};

export default App;
