import { invoiceType } from 'src/components';
import {
  CustomerData,
  brokerType,
  companyType,
  documentType,
  policySearchParams,
} from './types';

type CustomerState = {
  customerData: CustomerData;
  brokerData: brokerType;
  modalVisible: boolean;
  invitedModalVisible: boolean;
  permissionModal: boolean;
  toggleShareModal: boolean;
  documentToShare?: documentType;
  companyData?: companyType;
  selectedInvoice: invoiceType;
  invoiceModalType?: string;
  searchPolicyParams: policySearchParams;
  searchPolicyBranchFilter?: string;
};

type CustomerAction =
  | { type: 'CUSTOMER_DATA'; payload: CustomerData }
  | { type: 'BROKER_DATA'; payload: brokerType }
  | { type: 'TOGGLE_MODAL'; payload: boolean }
  | { type: 'CLEAR_CUSTOMER_DATA' }
  | { type: 'TOGGLE_INVITED_MODAL'; payload: boolean }
  | { type: 'TOGGLE_PERMISSION_MODAL'; payload: boolean }
  | { type: 'TOGGLE_SHARE_MODAL'; payload: boolean }
  | { type: 'SET_DOCUMENT_TO_SHARE'; payload: documentType }
  | { type: 'SET_COMPANY_DATA'; payload: companyType }
  | { type: 'SET_SELECTED_INVOICE'; payload: invoiceType }
  | { type: 'SET_INVOICE_MODAL_TYPE'; payload: string }
  | { type: 'SET_POLICY_SEARCH_PARAMS'; payload: policySearchParams }
  | { type: 'SET_POLICY_BRANCH_FILTER'; payload: string };

export const initialCustomerState: CustomerState = {
  customerData: {
    contactId: 0,
    customerId: 0,
    displayName: '',
  },
  brokerData: {},
  modalVisible: false,
  invitedModalVisible: false,
  permissionModal: false,
  toggleShareModal: false,
  selectedInvoice: {},
  searchPolicyParams: {},
  searchPolicyBranchFilter: undefined,
};

const customerReducer = (
  state: CustomerState = initialCustomerState,
  action: CustomerAction
): CustomerState => {
  switch (action.type) {
    case 'CUSTOMER_DATA':
      return { ...state, customerData: action.payload };
    case 'BROKER_DATA':
      return { ...state, brokerData: action.payload };
    case 'TOGGLE_MODAL':
      return { ...state, modalVisible: action.payload };
    case 'TOGGLE_SHARE_MODAL':
      return { ...state, toggleShareModal: action.payload };
    case 'SET_COMPANY_DATA':
      return { ...state, companyData: action.payload };
    case 'SET_DOCUMENT_TO_SHARE':
      return { ...state, documentToShare: action.payload };
    case 'CLEAR_CUSTOMER_DATA':
      return {
        ...state,
        customerData: initialCustomerState.customerData,
      };
    case 'TOGGLE_INVITED_MODAL':
      return { ...state, invitedModalVisible: action.payload };
    case 'TOGGLE_PERMISSION_MODAL':
      return { ...state, permissionModal: action.payload };
    case 'SET_SELECTED_INVOICE':
      return { ...state, selectedInvoice: action.payload };
    case 'SET_INVOICE_MODAL_TYPE':
      return { ...state, invoiceModalType: action.payload };
    case 'SET_POLICY_SEARCH_PARAMS':
      return { ...state, searchPolicyParams: action.payload };
    case 'SET_POLICY_BRANCH_FILTER':
      return { ...state, searchPolicyBranchFilter: action.payload };
    default:
      return state;
  }
};

export { customerReducer };
export type { CustomerAction };
export type { CustomerState };
