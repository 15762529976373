/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RowClickHandlerType,
  useNotificationSystem,
  Stack,
  DocumentToolbar,
  CreateNewFolderDialog,
  IDocumentType,
  FilesTable,
  relativeFull,
  dropZoneStyles,
  useStateProvider,
  dateFormat,
  breadCrumbType,
  formatHierarchy,
  DocumentBreadCrumb,
  initialBreadcrumb,
  useIsSelectedView,
  useUserProfile,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce, useLocation } from 'react-use';
import { FileWithPath, useDropzone } from 'react-dropzone';
import {
  BrokerAppLogic,
  UserRole,
  useApiInfiniteQueryWithPageToken,
  useApiInvalidateQueries,
  useApiMutation,
  useApiQuery,
} from 'src/api';
import UploadManagerCustomer from 'src/providers/UploadManagerCustomer';
import useGetColumns from './useGetColumns';
import { useQuery } from '@tanstack/react-query';
import ManageDocumentAccessModal from 'src/components/conversation/documents/ManageDocumentAccessModal';
import { DeleteModal } from 'src/components/DeleteModal';

type DocumentTabType = {
  id: number;
  searchParam?: string;
};

const DocumentTab: React.FC<DocumentTabType> = ({ id, searchParam }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { roles } = useUserProfile();
  const { pathname: location, hash } = useLocation();
  const { addNotification } = useNotificationSystem();
  const invalidateQueries = useApiInvalidateQueries();
  const { actions, state } = useStateProvider();
  const {
    setIsUploadPanelVisible,
    setFilesToUpload,
    setAddToAction,
    toggleDeleteDocumentModal,
    setSelectedDocument,
  } = actions;

  const { selectedDocument, showDeleteModal } = state.documents;
  const { toggleShareModal: isVisible } = state.customer;
  const [parentId, setParentId] = useState<string>();
  const [breadcrumb, setBreadcrumbs] = useState<breadCrumbType[]>([]);
  const [showCreateNewFolderDialog, setShowCreateNewFolderDialog] = useState<{
    visible: boolean;
    folder: string;
  }>({ visible: false, folder: '' });

  useEffect(() => {
    if (hash) {
      const value = hash.substring(1);
      setParentId(value);
      const index = breadcrumb.findIndex((obj) => obj.path === value);
      setBreadcrumbs(breadcrumb.filter((_, idx) => idx <= index));
    } else {
      if (breadcrumb.length) {
        setBreadcrumbs([]);
        setParentId('');
      }
    }
  }, [hash]);

  const {
    data: result,
    refetch,
    isSuccess,
  } = useQuery({
    queryKey: ['get_Document_Customer_Hierarchy_slice'],
    queryFn: async () => {
      if (!hash) return {};
      return await BrokerAppLogic.get_Document_Customer_Hierarchy_slice(
        hash.substring(1)
      );
    },
    enabled: false,
  });

  useEffect(() => {
    if (isSuccess) {
      const formatted = formatHierarchy(result);
      setBreadcrumbs(
        formatted
          .map((item) => {
            return { name: item.name, path: item.id } as breadCrumbType;
          })
          .slice(1)
      );
    }
  }, [isSuccess]);

  useEffectOnce(() => {
    refetch();
  });

  const queryDocuments = useApiInfiniteQueryWithPageToken(
    'search_Customer_Documents',
    [id, searchParam, parentId, undefined],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.documents
            ? item.documents.map(
                (subitem) =>
                  ({
                    ...subitem,
                    fileName: subitem.name,
                    modifiedAt: dateFormat(subitem.createdDateTime),
                    shared: subitem.isSharedWithCustomer,
                    type: subitem.documentType === 1 ? 'folder' : 'file',
                  } as IDocumentType)
              )
            : []
        ),
      refetchOnWindowFocus: false,
    }
  );

  const { refetch: createFolder } = useApiQuery(
    'createFolder',
    [id, { parentFolderId: parentId, name: showCreateNewFolderDialog.folder }],
    {
      enabled: false,
      onSuccess: () => {
        setShowCreateNewFolderDialog({ visible: false, folder: '' });
        addNotification({
          title: t('file.action.create.success'),
          color: 'success',
        });
        invalidateQuery();
      },
    }
  );

  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('search_Customer_Documents');
  }, [invalidateQueries]);

  const { getRootProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: FileWithPath[]) => {
      setIsUploadPanelVisible(true);
      setFilesToUpload(acceptedFiles);
    },
    noClick: true,
    noKeyboard: true,
  });

  const columns = useGetColumns();

  const openFile: RowClickHandlerType<IDocumentType> = useCallback(
    (item: IDocumentType) => {
      if (item.type === 'folder') {
        navigate(`${location}#${item.id}`);
        setParentId(item.id);
        setBreadcrumbs([...breadcrumb, { name: item.fileName, path: item.id }]);
      } else {
        window.open(`${item.webUrl}?web=1&action=edit`);
      }
    },
    [location, navigate, breadcrumb]
  );

  const isMobileView = useIsSelectedView('sm');

  const FilesTableProps = useMemo(
    () => ({
      isDragActive,
      columns,
      queryDocuments,
      openFile,
      isMobileView,
    }),
    [columns, isMobileView, isDragActive, openFile, queryDocuments]
  );

  const { mutate: deleteDocument } = useApiMutation('deleteDocument');

  const onDelete = useCallback(
    (documentId: string) => {
      setAddToAction(documentId);
      deleteDocument([id, documentId], {
        onSuccess: () => {
          invalidateQuery();
          addNotification({
            title: t('file.action.deleted.success'),
            color: 'success',
          });
        },
      });
    },
    [deleteDocument, id]
  );

  return (
    <>
      <Stack direction="col" className={relativeFull}>
        <DocumentToolbar
          showCreateNewFolderDialog={
            roles?.includes(UserRole.SuperUsers)
              ? () =>
                  setShowCreateNewFolderDialog({ visible: true, folder: '' })
              : undefined
          }
        />
        <DocumentBreadCrumb
          pathname={`${location}`}
          items={[initialBreadcrumb, ...breadcrumb]}
          classNames={isMobileView ? '' : 'pl-8'}
        />
        <div
          {...getRootProps()}
          className={classnames(
            dropZoneStyles(isDragActive),
            'relative',
            !isMobileView && 'pl-8 pr-5'
          )}
        >
          <FilesTable {...FilesTableProps} />
        </div>
        <UploadManagerCustomer id={id} targetFolderId={parentId} />
        <CreateNewFolderDialog
          create={createFolder}
          name={showCreateNewFolderDialog.folder}
          setName={(name) =>
            setShowCreateNewFolderDialog({
              ...showCreateNewFolderDialog,
              folder: name,
            })
          }
          visible={showCreateNewFolderDialog.visible}
          close={() =>
            setShowCreateNewFolderDialog({ visible: false, folder: '' })
          }
        />
      </Stack>
      {isVisible && <ManageDocumentAccessModal />}
      <DeleteModal
        displayName={selectedDocument.name || ''}
        header={t('file.action.deleteDialogTitle')}
        deleteConfirmationText={t('file.action.deleteConfirmationText')}
        onAccept={() => selectedDocument.id && onDelete(selectedDocument.id)}
        visible={showDeleteModal}
        onClose={() => {
          toggleDeleteDocumentModal(false);
          setSelectedDocument({});
        }}
      />
    </>
  );
};

export { DocumentTab };
