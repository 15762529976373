import {
  ApiMutationFunctions,
  ApiQueryFunctions,
  BrokerAppLogicClient,
} from './BrokerAppLogic';
import { ApiOptions } from '@trueconnect/tp-components';

/** Defines all default options for useApiQuery and useApiMutation
 */
export const defaultApiOptions: ApiOptions<
  BrokerAppLogicClient,
  ApiMutationFunctions,
  ApiQueryFunctions
> = {
  getConversationById: (
    options,
    [id],
    invalidateQueries,
    addNotification,
    t
  ) => ({
    ...options,
    enabled: options.enabled || !!id,
    errorMessage: t('apiCalls.error.openConversation'),
  }),
  getCustomerContactByUserId: (
    options,
    [contactUserId],
    invalidate,
    addNotification,
    t
  ) => ({
    ...options,
    enabled: !!contactUserId,
    errorMessage: t('apiCalls.error.participantError'),
  }),
  update_Conversation: (options, invalidateQueries, addNotification, t) => ({
    ...options,
    onSuccess: (data, variables, context) => {
      const [convDto] = variables as [{ id?: string }];
      const result =
        options &&
        options.onSuccess &&
        options.onSuccess(data, variables, context);
      convDto.id && invalidateQueries('getConversationById', [convDto.id]);
      invalidateQueries('searchConversations');
      return result;
    },
    onError: (error: unknown) =>
      addNotification({
        title:
          options.errorMessage || t('conversation.panel.changeTitle.error'),
        color: 'failure',
        subTitle: (error as Error).message,
      }),
  }),

  /** This is used for all useApiMutation */
  defaultMutation: (
    methodName,
    options,
    invalidateQueries,
    addNotification,
    t
  ) => ({
    onError: (error: unknown) =>
      addNotification({
        title: options.errorMessage || t('apiMutationOptions.error.default'),
        color: 'failure',
        subTitle: `${(error as Error)?.message || error} (${methodName})`,
        lifeTimeMs: 10000,
      }),
    ...options,
  }),

  /** This is used for all useApiQuery */
  defaultQuery: (
    methodName,
    args,
    options,
    invalidateQueries,
    addNotification,
    t
  ) => ({
    ...options,
    onError: (error: unknown) =>
      addNotification({
        title: options.errorMessage || t('apiQueryOptions.error.default'),
        color: 'failure',
        subTitle: `${(error as Error)?.message || error} in ${String(
          methodName
        )}`,
        lifeTimeMs: 10000,
      }),
  }),
};

export default defaultApiOptions;
