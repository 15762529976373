import { Button } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { DialogProps } from '../StatusBadge/states';
import { useApiInvalidateQueries, useApiMutation } from 'src/api';
import { useCallback } from 'react';
import {
  ContactStatus,
  modalStyles,
  useStateProvider,
} from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';

export const Reactivate: React.FC<DialogProps> = ({
  contactId,
  close,
  visible,
}) => {
  const { t } = useTranslation();
  const invalidateQueries = useApiInvalidateQueries();
  const { customerData } = useStateProvider().state.customer;
  const { setCustomerData } = useStateProvider().actions;

  const { mutate: changeContactStatus } = useApiMutation('changeContactStatus');

  const reactivateUser = useCallback(async () => {
    await changeContactStatus([contactId, ContactStatus.Active], {
      onSuccess: () => {
        invalidateQueries('searchContacts');
        invalidateQueries('getConversationById');
        invalidateQueries('getCustomerContactByUserId');
      },
    });
    close();
    setCustomerData({ ...customerData, status: ContactStatus.Active });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeContactStatus, contactId, invalidateQueries]);

  return (
    <Modal
      size="md"
      className={modalStyles}
      onClose={close}
      show={!!visible}
      showFooter={false}
      showTitle={false}
    >
      <h3 className="mb-5 text-xl font-semibold pt-4">
        {t('customer.reactivate.modal.title')}
      </h3>
      <span className="font-light">
        {t('customer.reactivate.modal.description')}
      </span>
      <div className="flex flex-col justify-center items-center gap-4 pt-10">
        <Button className="w-80" color="success" onClick={reactivateUser}>
          {t('customer.reactivate.modal.buttonOnAccept')}
        </Button>
        <Button className="w-80" color="gray" onClick={close}>
          {t('customer.reactivate.modal.buttonOnClose')}
        </Button>
      </div>
    </Modal>
  );
};

export default Reactivate;
