import {
  BranchBadge,
  Button,
  ColorPicker,
  Label,
  Stack,
  TextInput,
  branchType,
  hiddenScrollbar,
  useNotificationSystem,
} from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { t } from 'i18next';
import { useCallback, useState } from 'react';
import classnames from 'classnames';
import {
  CreateBranchDto,
  useApiInvalidateQueries,
  useApiMutation,
} from 'src/api';
import { useWindowSize } from 'react-use';

export const BranchForm = ({
  onClose,
  selectedBranch,
}: {
  onClose: () => void;
  selectedBranch?: branchType;
}) => {
  const [shortName, setShortName] = useState(selectedBranch?.shortName || '');
  const [name, setName] = useState(selectedBranch?.name || '');
  const [convenientName, setConvenientName] = useState(
    selectedBranch?.convenientName || ''
  );
  const [background, setBackground] = useState(
    selectedBranch?.backgroundColor || ''
  );
  const [textColor, setTextColor] = useState(selectedBranch?.color || '');

  const OnCancel = () => {
    onClose();
  };

  const invalidateQueries = useApiInvalidateQueries();
  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('getAvailableBranches');
  }, [invalidateQueries]);

  const { mutate: mutateCreate, isLoading: isCreating } =
    useApiMutation('createBranch');
  const { mutate: mutateUpdate, isLoading: isUpdating } =
    useApiMutation('updateBranch');
  const isLoading = isCreating || isUpdating;

  const formType = selectedBranch?.id ? 'updateForm' : 'createForm';
  const { addNotification } = useNotificationSystem();
  const { width } = useWindowSize();
  const isMobileView = width < 400;

  const onAccept = () => {
    const mutate = selectedBranch?.id ? mutateUpdate : mutateCreate;
    mutate(
      [
        {
          id: selectedBranch?.id,
          backgroundColor: background,
          color: textColor,
          name,
          shortName,
          convenientName,
        } as CreateBranchDto,
      ],
      {
        onSuccess: () => {
          invalidateQuery();
          onClose();
        },
        onError: (error) => {
          const response = error as { status?: number };
          if (response.status == 409)
            addNotification({
              color: 'failure',
              title: t(`Branches.AlreadyExistsError.title`, { name }),
              subTitle: t(`Branches.AlreadyExistsError.subTitle`),
            });
          else
            addNotification({
              color: 'failure',
              title: t(`Branches.${formType}.error`),
            });
        },
      }
    );
  };

  const isDisabled =
    !shortName ||
    !name ||
    !convenientName ||
    !/^#[0-9A-F]{6}$/i.test(background) ||
    !/^#[0-9A-F]{6}$/i.test(textColor);

  const colorPickerClassname = classnames(
    'w-[115px] rounded-lg',
    isMobileView && 'w-full'
  );

  return (
    <Modal
      title={t(`Branches.${formType}.title`)}
      onClose={onClose}
      footer={
        <>
          <Button className="w-1/2" variant="secondary" onClick={OnCancel}>
            {t('common.cancel')}
          </Button>
          <Button
            className="w-1/2"
            disabled={isDisabled}
            isLoading={isLoading}
            dataTestid="acceptBranchForm"
            variant="primary"
            onClick={onAccept}
          >
            {t('common.accept')}
          </Button>
        </>
      }
    >
      <Stack direction="col" className="gap-2 !w-full [&>div]:w-full">
        <Label
          htmlFor="convenientName"
          value={t('Branches.createForm.convenientPlaceholder')}
        />
        <TextInput
          type="text"
          id="convenientName"
          value={convenientName}
          placeholder={t('Branches.createForm.convenientPlaceholder')}
          onChange={(e) => setConvenientName(e.target.value)}
          data-testid="branchConvenientNameInput"
        />
        <Label
          htmlFor="name"
          value={t('Branches.createForm.namePlaceholder')}
        />
        <TextInput
          type="text"
          id="name"
          value={name}
          placeholder={t('Branches.createForm.namePlaceholder')}
          onChange={(e) => setName(e.target.value)}
          data-testid="branchNameInput"
        />
        <Label
          htmlFor="shortName"
          value={t('Branches.createForm.shortPlaceholder')}
        />
        <TextInput
          type="text"
          id="shortName"
          value={shortName}
          placeholder={t('Branches.createForm.shortPlaceholder')}
          onChange={(e) => setShortName(e.target.value)}
          data-testid="branchShortNameInput"
          title="text"
        />
        <Stack direction={'col'} className="pt-2 w-full gap-3">
          <Stack
            direction="row"
            justifyContent="between"
            className="gap-3 w-full"
          >
            <Stack className="gap-2">
              <Label
                htmlFor="background"
                value={t('Branches.createForm.background')}
              />
              <ColorPicker
                id="background"
                value={background}
                dataTestId="background"
                setValue={setBackground}
                placeholder={t('Branches.createForm.background')}
                classNames={colorPickerClassname}
                pickerClassname="left-0"
              />
            </Stack>
            <Stack className="gap-2">
              <Label
                htmlFor="textColor"
                value={t('Branches.createForm.textColor')}
              />
              <ColorPicker
                id="textColor"
                value={textColor}
                dataTestId="textColor"
                setValue={setTextColor}
                placeholder={t('Branches.createForm.textColor')}
                classNames={colorPickerClassname}
                pickerClassname="right-0"
              />
            </Stack>
          </Stack>
          {background && textColor && (
            <Stack
              className={classnames(
                'h-full w-full m-auto overflow-x-auto overflow-y-hidden [&>div>span]:text-nowrap',
                hiddenScrollbar
              )}
              alignItems="center"
              justifyContent="center"
            >
              <Stack direction="row" className="w-full m-auto">
                <div className="m-auto">
                  <BranchBadge
                    {...{
                      item: {
                        backgroundColor: background,
                        color: textColor,
                        convenientName: name
                          ? name
                          : t('Branches.createForm.example'),
                      },
                    }}
                  />
                </div>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default BranchForm;
