import {
  ArrowRightIcon,
  Dropdown,
  EllipsisHorizontalIcon,
  IconButton,
  PencilIcon,
  Stack,
  headerStyle,
  useIsSelectedRange,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import TopicsList from '@trueconnect/tp-components/src/pages/Topics/TopicsList';
import TopicContainer from './TopicsDetails/TopicContainer';
import { useCallback, useEffect, useState } from 'react';
import {
  UpdateTopicDto,
  useApiInfiniteQuery,
  useApiInvalidateQueries,
  useApiMutation,
} from 'src/api';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { TopicType } from '@trueconnect/tp-components/src/providers/WorkManagement/typing';
import { t } from 'i18next';
import TopicListHeader from './TopicsList/TopicListHeader';
import TopicsSidebar from '@trueconnect/tp-components/src/pages/Topics/TopicsSidebar';
import { useNavigate, useParams } from 'react-router-dom';
import AddConversationToTopicDialog from 'src/components/topics/AddConversationToTopicDialog';
import classnames from 'classnames';
import TopicExtendedSearch from 'src/components/topics/TopicExtendedSearch/TopicExtendedSearch';
import { ChangeTitleModal } from 'src/components/conversation';
import AddConversationToTopicModal from 'src/components/topics/AddConversationToTopicModal';

export const TopicsPage = () => {
  const [searchParam, setSearchParam] = useState('');
  const { addNotification } = useNotificationSystem();
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const isMobileView = useIsSelectedRange(['sm', 'md']);
  const { state, actions } = useStateProvider();
  const { extendedTopicSearchParams, showExtendedTopicSearch, selectedTopic } =
    state.workManagement;
  const { setShowExtendedTopicSearch } = actions;
  const [topicToUpdate, setTopicToUpdate] = useState<TopicType>({});
  const [updatePanelVisible, setUpdatePanelVisible] = useState(false);
  const [showAddConversationModal, setShowAddConversationModal] =
    useState(false);

  const queryTopics = useApiInfiniteQuery(
    'searchTopics',
    [
      {
        search: searchParam,
        page: 0,
        branchIds: extendedTopicSearchParams.branchIds,
        customerIds: extendedTopicSearchParams.customerIds,
        dateCreatedFrom: extendedTopicSearchParams.dateCreatedFrom,
        dateCreatedTo: extendedTopicSearchParams.dateCreatedTo,
      },
    ],
    {
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('Topics.Sidebar.error'),
        });
      },
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map((subitem) => subitem as TopicType)
        ),
    }
  ) as UseInfiniteQueryResult<TopicType[], Error>;

  const invalidateQueries = useApiInvalidateQueries();
  const invalidateQuery = useCallback(async () => {
    selectedTopic?.id == topicToUpdate?.id &&
      (await invalidateQueries('getTopicById'));
    await invalidateQueries('searchTopics');
  }, [invalidateQueries, selectedTopic, topicToUpdate]);

  const { mutate: updateTopic, isLoading } = useApiMutation('updateTopic', {
    onSuccess: () => {
      invalidateQuery();
    },
    onError: () => {},
  });

  const close = () => {
    setUpdatePanelVisible(false);
    setShowAddConversationModal(false);
    setTopicToUpdate({});
  };

  const saveTopic = (title: string) => {
    topicToUpdate.id &&
      updateTopic(
        [
          {
            id: topicToUpdate.id,
            branchIds: topicToUpdate.branchIds,
            displayName: title,
          } as UpdateTopicDto,
        ],
        {
          onSuccess: () => {
            close();
          },
          onError: () => {
            addNotification({
              color: 'failure',
              title: t('Topics.modals.updateTopic.error'),
            });
          },
        }
      );
  };

  useEffect(() => {
    return () => {
      setShowExtendedTopicSearch(false);
    };
  }, [setShowExtendedTopicSearch]);

  useEffect(() => {
    id && isMobileView && setShowExtendedTopicSearch(false);
  }, [id, isMobileView, setShowExtendedTopicSearch]);

  return (
    <Stack className="w-full h-full" direction="row">
      {!(
        (isMobileView && !!id) ||
        (isMobileView && showExtendedTopicSearch)
      ) && (
        <TopicsSidebar isMobileView={isMobileView}>
          {isMobileView && (
            <h1 className={classnames(headerStyle, 'mx-2')}>
              {t('sidebar.topics')}
            </h1>
          )}
          <TopicListHeader {...{ setSearchParam }} />
          <TopicsList
            {...{
              queryTopics,
              onClick: (item) => {
                item.id && navigate(item.id);
              },
              extraItems: (item) => (
                <div
                  className="!absolute !right-1"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Dropdown
                    defaultItemClassnames="text-customColor_9"
                    mobileView={isMobileView}
                    label={
                      <Stack direction="row" className="mr-2">
                        <IconButton dataTestId="TopicListItemActions" size="xs">
                          <EllipsisHorizontalIcon className="text-black" />
                        </IconButton>
                      </Stack>
                    }
                    items={[
                      {
                        icon: (
                          <PencilIcon
                            data-testid={`listItemEditTitle_${item.displayName}`}
                          />
                        ),
                        text: t('common.modals.changeTitle'),
                        onClick: () => {
                          setTopicToUpdate(item);
                          setUpdatePanelVisible(true);
                        },
                      },
                      {
                        icon: (
                          <ArrowRightIcon
                            data-testid={`listItemAddConversation_${item.displayName}`}
                          />
                        ),
                        text: t('Topics.Details.addConversation'),
                        onClick: () => {
                          setTopicToUpdate(item);
                          setShowAddConversationModal(true);
                        },
                      },
                    ]}
                  />
                </div>
              ),
            }}
          />
        </TopicsSidebar>
      )}
      <TopicExtendedSearch />
      <TopicContainer {...{ queryTopics }} />
      <AddConversationToTopicDialog />
      <ChangeTitleModal
        {...{
          updateTitle: (value) => saveTopic(value),
          isLoading,
          title: topicToUpdate?.displayName,
          isVisible: updatePanelVisible,
          close,
        }}
      />
      <AddConversationToTopicModal
        {...{
          visible: showAddConversationModal,
          selectedTopic: topicToUpdate,
          onClose: close,
        }}
      />
    </Stack>
  );
};

export default TopicsPage;
