import { CommonStyles } from './commonStyles';
import classnames from 'classnames';

export const NavbarStyles = {
  wrapper: (isMobileView: boolean, showNavbar: boolean) =>
    classnames(
      'sm:px-5 content-box transition-all w-full',
      !isMobileView && 'border-b-2',
      showNavbar ? 'h-16 py-[15px]' : 'h-0 py-0 absolute -top-20'
    ),
  notificationIcon: classnames(
    CommonStyles.iconSize,
    'mx-4 cursor-pointer text-customColor_23'
  ),
  avatarDropdown: {
    dropdown: '!top-2 !w-56 !right-10 !left-auto',
    displayName: 'block text-sm text-lg font-semibold',
    mail: 'block truncate text-sm font-medium',
    icon: classnames(CommonStyles.iconSize, 'pr-2 fill-gray-500'),
    logoutIcon: classnames(
      CommonStyles.iconSize,
      'pr-2',
      `fill-customColor_25`
    ),
    logoutText: `text-red-600`,
  },
};

export const SidebarStyles = {
  default: (isFocused: boolean) =>
    classnames(
      'block flex flex-row align-start h-[40px] cursor-pointer',
      isFocused ? 'bg-customColor_2' : ''
    ),
  wrapper: (showFullSidebar: boolean) => {
    const initial =
      'relative flex flex-col [&>div]:bg-white items-start overflow-y-auto overflow-x-hidden [&>div]:w-full';
    const border = 'border-solid border-customColor_21 border-t-1 border-r-2';
    const width = showFullSidebar
      ? 'min-w-[240px] [&>div]:px-2'
      : 'min-w-[60px] max-w-[60px] [&>div]:rounded-none [&>div]:py-4 [&>div]:px-0';

    return classnames(initial, border, width);
  },
};

export const BottomBarStyles = {
  wrapper: (showBottombar: boolean) =>
    classnames(
      'w-full justify-evenly transition-all',
      showBottombar ? 'h-16 pb-2 border-t' : 'h-0 py-0 absolute -bottom-20'
    ),
};
