/* eslint-disable prettier/prettier */
import {
  ScaleIcon,
  ShoppingBagIcon,
  BanknotesIcon,
  HeartIcon,
  BuildingOffice2Icon,
  UserIcon,
  UserPlusIcon,
  FolderIcon,
  WrenchScrewdriverIcon,
  TruckIcon,
} from './../../images';

/* prettier-ignore */
export const branchesArray = {
  Haft: <ScaleIcon />,
  'D&O': <BanknotesIcon />,
  Sach: <BuildingOffice2Icon />,
  Transport: <ShoppingBagIcon />,
  KTG: <HeartIcon />,
  UVGO: <UserIcon />,
  UVGZ: <UserPlusIcon />,
  Übrige: <FolderIcon />,
  Bau: <WrenchScrewdriverIcon />,
  MFZ: <TruckIcon />,
};
