import { Tabs as FlobiteTabs, TabsRef } from 'flowbite-react';
import { IconButton, Stack, useGetTabIndex } from '@trueconnect/tp-components';
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styles from './Tabs.module.scss';
import classnames from 'classnames';

export type TabProps = {
  title: string | React.ReactNode;
  component?: React.ReactNode; //either give path or component
  path?: string;
  icon?: JSX.Element;
  testId?: string;
};

type TabsProps = {
  tabs: TabProps[];
  tabCallback?: Dispatch<SetStateAction<number>>;
  className?: string;
  tabClassname?: string;
  type?: 'fullText' | 'onlyActive' | 'noText';
};

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  tabCallback,
  className = '',
  tabClassname = 'overflow-x-hidden overflow-auto',
}) => {
  const navigator = useNavigate();
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabsRef = useRef<TabsRef>(null);
  const index = useGetTabIndex({ tabs });
  const [display, setDisplay] = useState(tabCallback ? 'none' : 'block');

  useEffect(() => {
    tabsRef.current?.setActiveTab(index);
    setDisplay('block');
  }, [index, tabsRef, tabCallback]);

  useEffect(() => {
    tabCallback?.(activeTab);
  }, [activeTab, tabCallback]);

  return (
    <>
      <div className={styles.tabs} style={{ display }}>
        <FlobiteTabs
          style="underline"
          ref={tabsRef}
          className={classnames(
            'z-[5] !flex-nowrap',
            styles.hideScrollbar,
            '[&>div>button]:!ring-0',
            className
          )}
          onActiveTabChange={(tab) => setActiveTab(tab)}
        >
          {tabs.map((item, index) => {
            const isActive = index === activeTab;
            return (
              <FlobiteTabs.Item
                key={index}
                active={isActive}
                className="border-blue-700"
                title={
                  <Stack
                    className={classnames(
                      'cursor-pointer w-max overflow-hidden',
                      isActive && 'text-blue-700'
                    )}
                    direction="row"
                    alignItems="center"
                    onClick={() => {
                      if (item.path) {
                        navigator(item.path);
                      }
                    }}
                  >
                    {item.icon && (
                      <IconButton
                        hoverable={false}
                        customSize="w-7"
                        canBeDisabled={false}
                        imgClassName="mx-0 p-0"
                      >
                        <>{item.icon}</>
                      </IconButton>
                    )}
                    <span data-testid={item.testId} className="truncate">
                      {item.title}
                    </span>
                  </Stack>
                }
              >
                {activeTab === index && (
                  <div className="relative w-full h-full">
                    <div
                      className={classnames(
                        styles.container,
                        'w-full h-full absolute top-0 bottom-0',
                        tabClassname
                      )}
                    >
                      {item.component}
                    </div>
                  </div>
                )}
              </FlobiteTabs.Item>
            );
          })}
        </FlobiteTabs>
      </div>
    </>
  );
};

export default Tabs;
