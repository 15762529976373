import {
  Button,
  ContactAllowedResourcesDto,
  ConversationListItemProps,
  ResourceType,
  SmartSpinnerLocal,
  Stack,
  useIsSelectedView,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PermissionLogic from './PermissionLogic';
import { useEffect, useState } from 'react';
import {
  ContactPermissionDto,
  useApiMutation,
  useApiInfiniteQuery,
  useApiInvalidateQueries,
} from 'src/api';
import ExistingConversationsModal from './ExistingConversationsModal';

export const initialPermissions = {
  allowConversations: false,
  allowSharedDocuments: false,
  allowPolicyDirectory: false,
  allowedRecources: [],
} as ContactPermissionDto;

export type PermissionKeys = keyof ContactPermissionDto;

export const PermissionModal = () => {
  const { t } = useTranslation();
  const { state, actions } = useStateProvider();
  const { permissionModal: visible, customerData } = state.customer;
  const { contactId, customerId } = customerData;
  const { setTogglePermissionModal } = actions;
  const isMobileView = useIsSelectedView('sm');
  const { addNotification } = useNotificationSystem();
  const [showContactConversations, setShowContactConversations] =
    useState(false);

  const [permissionState, setPermissionState] = useState<
    ContactPermissionDto | undefined
  >(undefined);
  const [policyResoursesState, setPolicyResourcesState] =
    useState<ContactAllowedResourcesDto>({
      resourceType: ResourceType.PolicyDirectoryBranch,
      allowedAccessToAll: false,
      resourceIds: [],
    });

  const { data, mutate: getContactPermissions } = useApiMutation(
    'get_Contact_Perissions',
    {
      onSuccess: (data) => {
        data && setPermissionState(data as ContactPermissionDto);
        data &&
          setPolicyResourcesState(
            (data.allowedResources?.find(
              (resource) =>
                resource.resourceType === ResourceType.PolicyDirectoryBranch
            ) as ContactAllowedResourcesDto) || {
              resourceType: ResourceType.PolicyDirectoryBranch,
            }
          );
      },
    }
  );

  useEffect(() => {
    contactId && visible && getContactPermissions([contactId]);
  }, [contactId, getContactPermissions, visible]);

  useEffect(() => {
    contactId && visible && setPermissionState(initialPermissions);
  }, [contactId, visible]);

  const { conversationData } = state.conversation;
  const invalidateQueries = useApiInvalidateQueries();

  const onClose = (invalidate?: boolean) => {
    if (invalidate) {
      if (conversationData.conversationId) {
        invalidateQueries('getConversationById');
      }
      invalidateQueries('searchContacts');
    }
    setTogglePermissionModal(false);
    setShowContactConversations(false);
  };

  const { mutate, isLoading } = useApiMutation('change_Contact_Perissions');

  const userConversations = useApiInfiniteQuery(
    'searchContactConversations',
    [customerId, contactId, '', undefined, 0],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map(
            (subitem) =>
              ({
                lastActive: subitem.lastActivityUtcTimestamp,
                ...subitem,
              } as ConversationListItemProps)
          )
        ),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: visible && !!contactId && !!customerId,
    }
  );

  const setPermissions = () => {
    if (permissionState) {
      const existingIndex = permissionState.allowedResources?.findIndex(
        (resource) =>
          resource.resourceType === ResourceType.PolicyDirectoryBranch
      );

      const updatedAllowedResources =
        permissionState.allowedResources?.slice() || [];

      if (existingIndex !== -1 && existingIndex !== undefined) {
        updatedAllowedResources[existingIndex] = policyResoursesState;
      } else {
        updatedAllowedResources.push(policyResoursesState);
      }

      const mergedState: ContactPermissionDto = {
        ...permissionState,
        allowedResources: updatedAllowedResources,
      };

      mutate([contactId, mergedState], {
        onSuccess: () => {
          onClose(true);
        },
        onError: () => {
          addNotification({
            color: 'failure',
            title: t('permissions.error'),
          });
        },
      });
    }
  };

  const handleClick = () => {
    if (
      userConversations.data?.flat(1).length &&
      !permissionState?.allowConversations &&
      (data as ContactPermissionDto)?.allowConversations
    ) {
      setShowContactConversations(true);
    } else {
      setPermissions();
    }
  };

  useEffect(() => {
    setPermissionState(initialPermissions);
  }, [contactId, visible]);

  return (
    <>
      <Modal
        show={!!visible}
        title={t('permissions.header')}
        className={classnames('!z-50')}
        onClose={() => {
          onClose();
        }}
        size={isMobileView ? 'md' : 'sm'}
        showFooter={false}
      >
        <p>{t('permissions.body')}</p>
        <Stack direction="col" className="w-full min-h-[175px] h-auto">
          <SmartSpinnerLocal
            condition={
              !permissionState || !contactId || !customerId || !visible
            }
          >
            {permissionState && (
              <PermissionLogic
                currentPermissions={permissionState}
                setPermissionState={(permissions) => {
                  setPermissionState(permissions);
                }}
                policyResoursesState={policyResoursesState}
                setPolicyResourcesState={setPolicyResourcesState}
                className="mt-3"
              />
            )}
          </SmartSpinnerLocal>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          className="w-full gap-5 mt-10"
        >
          <Button
            className="w-1/2"
            dataTestid="cancelSavePermissions"
            onClick={() => {
              onClose();
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            className="w-1/2"
            color="blue"
            dataTestid="savePermissions"
            isLoading={isLoading}
            onClick={handleClick}
          >
            {t('common.save')}
          </Button>
        </Stack>
      </Modal>
      {visible && (
        <ExistingConversationsModal
          {...{
            setPermissions,
            onClose: () => {
              onClose(true);
            },
            userConversations,
            showContactConversations,
            setShowContactConversations,
          }}
        />
      )}
    </>
  );
};

export default PermissionModal;
