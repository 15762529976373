import i18n from 'i18next';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/de';
import 'dayjs/locale/en';

/** This module handles locales and plugins for dayjs. */

dayjs.extend(relativeTime);

function configureDayJsLocale() {
  dayjs.locale(i18n.language);
}

i18n.on('initialized', configureDayJsLocale);
i18n.on('languageChanged', configureDayJsLocale);
