import { useNotificationSystem } from './../../../providers';
import FilterCell from '../FilterCell';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { useState } from 'react';
import { Stack } from './../../../controls';
import { mapDays } from './../../../utils';
import Button from './../../../components/Button';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import locales from './../../../locales';

export const DateSearch = ({
  onAccept,
  clear,
}: {
  onAccept: (start?: Date, end?: Date) => void;
  clear: () => void;
}) => {
  const [startDate, setStartDate] = useState<DateObject | undefined>();
  const [endDate, setEndDate] = useState<DateObject | undefined>();

  const { addNotification } = useNotificationSystem();
  const onOk = () => {
    if (startDate && endDate && startDate > endDate) {
      addNotification({
        color: 'failure',
        title: t('settings.Warnings.startDateMustBeEarlierThanEndDate'),
      });
      return;
    }

    onAccept(
      startDate ? dayjs(startDate.toDate()).startOf('day').toDate() : undefined,
      endDate ? dayjs(endDate.toDate()).endOf('day').toDate() : undefined
    );
  };
  const onCancel = () => {
    clear();
    setStartDate(undefined);
    setEndDate(undefined);
  };

  const today = new DateObject();
  const { i18n } = useTranslation();
  const selectedLanguage = locales[i18n.language] ? i18n.language : 'de';

  return (
    <FilterCell
      title={t('conversation.extendedSearch.dateSearch.title')}
      dataTestId="extendedSearchDateFilter"
    >
      <Stack className="w-full gap-2 px-4">
        <DatePicker
          value={startDate}
          locale={locales[selectedLanguage]}
          onChange={(date: DateObject) => {
            setStartDate(date);
          }}
          format="DD.MM.YYYY"
          weekStartDayIndex={1}
          onOpenPickNewDate={false}
          inputClass="w-full text-sm !border !border-gray-300 !text-[#4B5563]"
          containerClassName="w-full"
          maxDate={today}
          id="extendedSearchStartDate"
          placeholder={
            t('conversation.extendedSearch.dateSearch.start') as string
          }
          mapDays={(object) => {
            return {
              className: mapDays(startDate, endDate, object),
            };
          }}
        />
        <DatePicker
          value={endDate}
          locale={locales[selectedLanguage]}
          onChange={(date: DateObject) => {
            setEndDate(date);
          }}
          format="DD.MM.YYYY"
          weekStartDayIndex={1}
          onOpenPickNewDate={false}
          inputClass="w-full text-sm !border !border-gray-300 !text-[#4B5563]"
          containerClassName="w-full"
          maxDate={today}
          id="extendedSearchEndDate"
          placeholder={
            t('conversation.extendedSearch.dateSearch.end') as string
          }
          mapDays={(object) => {
            return {
              className: mapDays(startDate, endDate, object),
            };
          }}
        />
      </Stack>
      <Stack direction="row" className="w-full gap-2 py-2 px-4">
        <Button
          size="xs"
          className="w-full"
          onClick={onCancel}
          dataTestid="extendedDateClear"
        >
          {t('common.cancel')}
        </Button>
        <Button
          variant="primary"
          size="xs"
          className="w-full"
          onClick={onOk}
          dataTestid="extendedDateAccept"
        >
          {t('common.accept')}
        </Button>
      </Stack>
    </FilterCell>
  );
};

export default DateSearch;
