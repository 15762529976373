import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useNotificationSystem } from '@trueconnect/tp-components';
import { useApiQuery } from 'src/api';
import { useEffect } from 'react';

export const useGetCustomerData = (id: number, disabled?: boolean) => {
  const { addNotification } = useNotificationSystem();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: companyData,
    refetch,
    isFetching,
  } = useApiQuery('getCustomerById', [id], {
    onError: () => {
      addNotification({
        title: t('customer.notFound'),
        color: 'failure',
      });
      navigate('/customers');
    },
    refetchOnWindowFocus: false,
    enabled: !disabled,
  });

  useEffect(() => {
    if (id) {
      !isFetching && refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return companyData;
};

export default useGetCustomerData;
