import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
  UseInfiniteQueryResult,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { OnlyMethods, useNotificationSystem } from '@trueconnect/tp-components';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApiOptions,
  UseApiMutationOptions,
  UseApiQueryOptions,
  InvalidateQueries,
  ApiQueryOptionOverrides,
  ApiMutationOptionOverrides,
  ApiInfiniteQueryPageMapping,
  UseApiInfiniteQueryOptions,
} from './UseApiOptions';

type ApiType = object;

const defaultOptions = {
  refetchOnWindowFocus: false,
  useErrorBoundary: true,
};

type extendedParams<TData> = {
  onSuccess?: (data: TData) => void;
  onError?: () => void;
};

export function getApiQueryMethods<
  TApiClient extends ApiType,
  TApiMutationFunctions extends keyof OnlyMethods<TApiClient>,
  TApiQueryFunctions extends keyof OnlyMethods<TApiClient>
>(
  apiClient: TApiClient,
  apiOptions: ApiOptions<TApiClient, TApiMutationFunctions, TApiQueryFunctions>,
  queryPageMapping: ApiInfiniteQueryPageMapping<TApiClient, TApiQueryFunctions>
) {
  function useApiQuery<
    TFuncKey extends TApiQueryFunctions,
    TData extends Awaited<ReturnType<OnlyMethods<TApiClient>[TFuncKey]>>
  >(
    method: TFuncKey,
    args: Parameters<OnlyMethods<TApiClient>[TFuncKey]>,
    options: UseApiQueryOptions<TData> & extendedParams<TData> = {}
  ): UseQueryResult<TData> {
    const originalCall = apiClient[method] as OnlyMethods<TApiClient>[TFuncKey];
    const invalidateQueries = useApiInvalidateQueries();
    const { addNotification } = useNotificationSystem();
    const { t } = useTranslation();
    const overrideOptionsFunc = (
      apiOptions as ApiQueryOptionOverrides<TApiClient, TApiQueryFunctions>
    )[method];
    const overrideOptions = overrideOptionsFunc
      ? overrideOptionsFunc(
          options as UseApiQueryOptions<TData>,
          args,
          invalidateQueries,
          addNotification,
          t
        )
      : options;
    const applyOptions = apiOptions.defaultQuery(
      method,
      args,
      {
        ...defaultOptions, // can use for others
        ...overrideOptions,
      } as UseApiQueryOptions,
      invalidateQueries,
      addNotification,
      t
    );
    const result = useQuery({
      queryKey: [method, ...args],
      queryFn: () => originalCall.apply(apiClient, args),
      ...(applyOptions as Omit<
        UseQueryOptions<TData>,
        'queryKey' | 'queryFn' | 'initialData'
      >),
    });

    useEffect(() => {
      result.isError && options.onError?.();
    }, [result.isError]);

    useEffect(() => {
      result.isSuccess && options.onSuccess?.(result.data);
    }, [result.isSuccess]);

    return result;
  }

  function useApiInfiniteQuery<
    TFuncKey extends keyof typeof queryPageMapping,
    TData extends Awaited<ReturnType<OnlyMethods<TApiClient>[TFuncKey]>>,
    TSelectData
  >(
    method: TFuncKey,
    args: Parameters<OnlyMethods<TApiClient>[TFuncKey]>,
    options: Omit<
      UseApiInfiniteQueryOptions<TData, TSelectData> &
        extendedParams<TSelectData> & { keepPreviousData?: boolean },
      'getNextPageParam' | 'initialPageParam'
    >
  ): UseInfiniteQueryResult<TSelectData, Error> {
    const originalCall = apiClient[method] as OnlyMethods<TApiClient>[TFuncKey];
    const invalidateQueries = useApiInvalidateQueries();
    const { addNotification } = useNotificationSystem();
    const { t } = useTranslation();
    /*const overrideOptionsFunc = (
      apiOptions as ApiQueryOptionOverrides<TApiClient, TApiQueryFunctions>
    )[method];
    const overrideOptions = overrideOptionsFunc
      ? overrideOptionsFunc(
          options,
          args,
          invalidateQueries,
          addNotification,
          t
        )
      : options;
    const applyOptions = apiOptions.defaultQuery(
      method,
      args,
      overrideOptions as UseApiQueryOptions,
      invalidateQueries,
      addNotification,
      t
    );
    const infiniteOptions: Omit<
      UseInfiniteQueryOptions<TData>,
      'queryFn' | 'queryKey'
    > = {
      ...applyOptions,
      refetchInterval:
        typeof applyOptions.refetchInterval === 'function'
          ? undefined
          : applyOptions.refetchInterval,
      refetchOnWindowFocus:
        typeof applyOptions.refetchOnWindowFocus === 'function'
          ? undefined
          : applyOptions.refetchOnWindowFocus,
      refetchOnReconnect:
        typeof applyOptions.refetchOnReconnect === 'function'
          ? undefined
          : applyOptions.refetchOnReconnect,
      refetchOnMount:
        typeof applyOptions.refetchOnMount === 'function'
          ? undefined
          : applyOptions.refetchOnMount,
      useErrorBoundary:
        typeof applyOptions.useErrorBoundary === 'function'
          ? undefined
          : applyOptions.useErrorBoundary,
      select: undefined,
      placeholderData: undefined,
      initialData: undefined,
      structuralSharing: undefined,
    };*/
    /* TODO: Take over more of the options in defaultQuery, only actually reusing error */
    const defaultQueryOptions = apiOptions.defaultQuery(
      method,
      args,
      // { onError: options.onError, errorMessage: options.errorMessage },
      {},
      invalidateQueries,
      addNotification,
      t
    );
    let applyOptions: Omit<
      UseInfiniteQueryOptions<TData, unknown, TSelectData>,
      'queryKey' | 'queryFn'
    > = defaultQueryOptions as UseInfiniteQueryOptions<
      TData,
      unknown,
      TSelectData
    >;

    applyOptions = {
      ...applyOptions,
      getNextPageParam: (lastPage, allPages) => {
        if (!Array.isArray(lastPage) || lastPage.length === 0) {
          return undefined;
        }
        return allPages.length;
      },
      ...(options as Omit<
        UseApiInfiniteQueryOptions<TData, TSelectData>,
        'getNextPageParam'
      >),
    };

    const mapArgs =
      queryPageMapping[method] ||
      (<TArgs>(page: number, ...args: TArgs[]) =>
        [...args.slice(0, -1), page] as Parameters<
          OnlyMethods<TApiClient>[TFuncKey]
        >); //TODO: find better way how to pass page param

    const result = useInfiniteQuery<TData, Error, TSelectData, never>({
      queryKey: [method, ...args],
      queryFn: ({ pageParam = 0 }) =>
        originalCall.apply(apiClient, mapArgs(pageParam as number, ...args)),
      ...applyOptions,
    });

    useEffect(() => {
      result.isError && options.onError?.();
    }, [result.isError]);

    useEffect(() => {
      result.isSuccess && options.onSuccess?.(result.data);
    }, [result.isSuccess]);

    return result;
  }

  // TODO: refactor and merge with useApiInfiniteQuery()
  function useApiInfiniteQueryWithPageToken<
    TFuncKey extends keyof typeof queryPageMapping,
    TData extends Awaited<ReturnType<OnlyMethods<TApiClient>[TFuncKey]>>,
    TSelectData
  >(
    method: TFuncKey,
    args: Parameters<OnlyMethods<TApiClient>[TFuncKey]>,
    options: Omit<
      UseApiInfiniteQueryOptions<TData, TSelectData> &
        extendedParams<TSelectData>,
      'getNextPageParam' | 'initialPageParam'
    >
  ): UseInfiniteQueryResult<TSelectData, Error> {
    const originalCall = apiClient[method] as OnlyMethods<TApiClient>[TFuncKey];
    const invalidateQueries = useApiInvalidateQueries();
    const { addNotification } = useNotificationSystem();
    const { t } = useTranslation();
    const defaultQueryOptions = apiOptions.defaultQuery(
      method,
      args,
      // { onError: options.onError, errorMessage: options.errorMessage },
      {},
      invalidateQueries,
      addNotification,
      t
    );
    let applyOptions: Omit<
      UseInfiniteQueryOptions<TData, unknown, TSelectData>,
      'queryKey' | 'queryFn'
    > = defaultQueryOptions as UseInfiniteQueryOptions<
      TData,
      unknown,
      TSelectData
    >;

    applyOptions = {
      ...applyOptions,
      getNextPageParam: (lastPage) => {
        return lastPage.nextPageToken;
      },
      ...(options as Omit<
        UseApiInfiniteQueryOptions<TData, TSelectData>,
        'getNextPageParam'
      >),
    };

    const mapArgs = <TArgs>(page: string, ...args: TArgs[]) => [
      ...args.slice(0, -1),
      page,
    ]; //TODO: find better way how to pass page param

    const result = useInfiniteQuery<TData, Error, TSelectData, never>({
      queryKey: [method, ...args],
      queryFn: ({ pageParam }: { pageParam: string }) => {
        return originalCall.apply(apiClient, mapArgs(pageParam || '', ...args));
      },
      ...applyOptions,
    });

    useEffect(() => {
      if (result.isError) {
        options.onError?.();
      }
    }, [result.isError]);

    useEffect(() => {
      if (result.isSuccess) {
        options.onSuccess?.(result.data);
      }
    }, [result.isSuccess]);

    return result;
  }

  function useApiMutation<
    TFuncKey extends TApiMutationFunctions,
    TFunc extends OnlyMethods<TApiClient>[TFuncKey]
  >(
    method: TFuncKey,
    options?: UseApiMutationOptions<TFunc> & {
      onSuccess?: (
        data: Awaited<ReturnType<TFunc>>,
        variables: Parameters<TFunc>
      ) => void;
      onError?: () => void;
    }
  ) {
    const originalCall = apiClient[method] as TFunc;
    const invalidateQueries = useApiInvalidateQueries();
    const { addNotification } = useNotificationSystem();
    const { t } = useTranslation();
    const overrideMethod = (
      apiOptions as ApiMutationOptionOverrides<
        TApiClient,
        TApiMutationFunctions,
        TApiQueryFunctions
      >
    )[method];
    const overrideOptions = overrideMethod
      ? overrideMethod(
          options as UseApiMutationOptions<TFunc>,
          invalidateQueries,
          addNotification,
          t
        )
      : options;
    const applyOptions = apiOptions.defaultMutation(
      method,
      overrideOptions as UseApiMutationOptions,
      invalidateQueries,
      addNotification,
      t
    );
    const result = useMutation({
      mutationKey: [method],
      mutationFn: (param) => {
        return originalCall.apply(apiClient, param);
      },
      ...applyOptions,
    });

    useEffect(() => {
      if (result.isError) {
        options?.onError?.();
      }
    }, [result.isError]);

    return { ...result, isLoading: result.isPending };
  }

  function useApiInvalidateQueries(): InvalidateQueries<
    TApiClient,
    TApiQueryFunctions
  > {
    const client = useQueryClient();
    const invalidator = useCallback(
      (
        method: TApiQueryFunctions,
        args?: Parameters<OnlyMethods<TApiClient>[TApiQueryFunctions]>
      ): Promise<void> => {
        return client.invalidateQueries({
          queryKey: args ? [method, ...args] : [method],
        });
      },
      [client]
    );
    return invalidator as InvalidateQueries<TApiClient, TApiQueryFunctions>;
  }

  return {
    useApiQuery,
    useApiInfiniteQuery,
    useApiInfiniteQueryWithPageToken,
    useApiMutation,
    useApiInvalidateQueries,
  };
}
