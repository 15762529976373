import { DocumentCellStyle } from '../../../styles';
import { IDocumentType } from '../DocumentType';
import { IconButton } from '../../../components';
import { DocumentIcon, FolderIcon } from '../../../images';

export const FileName: React.FC<IDocumentType> = (item: IDocumentType) => {
  return (
    <div className={DocumentCellStyle} data-testid={item.fileName}>
      <IconButton hoverable={false} size="none" className="mr-3 [&>div]:ml-0">
        {item.type === 'folder' ? (
          <FolderIcon className="w-6 h-7" />
        ) : (
          <DocumentIcon className="w-6 h-7 !-ml-[2px]" />
        )}
      </IconButton>
      <span className="font-medium">{item.fileName}</span>
    </div>
  );
};

export default FileName;
