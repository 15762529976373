import { DropDownItemType, Stack } from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { cloneElement, useRef, useState } from 'react';
import classnames from 'classnames';

export const DropdownModal = ({
  items,
  label,
  classNames,
  modalClassnames,
  modalTitle,
}: {
  items: DropDownItemType[];
  label: React.ReactNode;
  classNames?: string;
  modalClassnames?: string;
  modalTitle?: React.ReactNode;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const onClose = () => {
    setShow(false);
  };

  return (
    <>
      <div
        onClick={() => {
          setShow(!show);
        }}
      >
        {label}
      </div>
      <Modal
        onClose={onClose}
        show={show}
        className={classnames(
          '[&>div>div]:!mt-auto [&>div>div]:rounded-t-3xl [&>div>div]:!max-h-full overflow-y-auto [&>div>div>div:nth-child(2)]:h-full',
          ' [&>div>div>div:nth-child(2)]:overflow-y-auto !bottom-0 mt-auto',
          '[&>div>div>div:nth-child(1)]:items-center',
          modalClassnames
        )}
        title={modalTitle}
        showFooter={false}
      >
        <div ref={ref}>
          <Stack direction="col" className="gap-2">
            {items.map((item, idx) => {
              if (item.component) {
                return (
                  <Stack
                    key={idx}
                    className={classnames(
                      classNames,
                      item.classNames,
                      'cursor-pointer',
                      'px-3'
                    )}
                    onClick={() => {
                      setShow(!show);
                    }}
                  >
                    {item.component}
                  </Stack>
                );
              }

              const textColor = item.color?.startsWith('#')
                ? `[${item.color}]`
                : item.color;

              return (
                <Stack
                  key={idx}
                  direction="row"
                  alignItems="center"
                  className={classnames(
                    'py-2 mb-2 pl-2 w-full h-fit',
                    item.type === 'button' &&
                      'border-2 rounded-xl justify-center',
                    item.classNames
                  )}
                  onClick={() => {
                    item.onClick?.();
                    onClose();
                  }}
                >
                  {item.icon &&
                    cloneElement(item.icon, {
                      color: item.color,
                      size: 'none',
                      className: 'h-7 w-7 pr-2',
                    })}
                  <span
                    className={classnames(
                      'font-medium text-lg',
                      item.type !== 'button' && 'pl-5',
                      `text-${textColor}`
                    )}
                  >
                    {item.text}
                  </span>
                </Stack>
              );
            })}
          </Stack>
        </div>
      </Modal>
    </>
  );
};

export default DropdownModal;
