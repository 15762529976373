import { payloadType } from '../StateProvider/actions';
import { SelectedFileType, currentUploadFileType } from './typing';

type actionType =
  | 'SET_IS_UPLOAD_PANEL_VISIBLE'
  | 'SET_FILES_TO_UPLOAD'
  | 'SET_SELECTED_FILES'
  | 'SELECT_FILE'
  | 'SET_CURRENT_UPLOAD_FILE_DATA'
  | 'SET_IS_UPLOADING';

interface action {
  type: actionType;
  payload?: payloadType;
}

interface actions {
  [key: string]: (payload?: payloadType) => action;
}

export const stateActionsUpload: actions = {
  setIsUploadPanelVisible: (value: boolean) => ({
    type: 'SET_IS_UPLOAD_PANEL_VISIBLE',
    payload: value,
  }),
  setFilesToUpload: (acceptedFiles: File[]) => ({
    type: 'SET_FILES_TO_UPLOAD',
    payload: acceptedFiles,
  }),
  setSelectedFiles: (value: SelectedFileType[]) => ({
    type: 'SET_SELECTED_FILES',
    payload: value,
  }),
  selectFile: (value: SelectedFileType) => ({
    type: 'SELECT_FILE',
    payload: value,
  }),
  setCurrentUploadFileData: (value: currentUploadFileType) => ({
    type: 'SET_CURRENT_UPLOAD_FILE_DATA',
    payload: value,
  }),
  setIsUploading: (value: boolean) => ({
    type: 'SET_IS_UPLOADING',
    payload: value,
  }),
};

export type actionsUpload = {
  setIsUploadPanelVisible: (value: boolean) => void;
  setFilesToUpload: (acceptedFiles: File[]) => void;
  setSelectedFiles: (value: SelectedFileType[]) => void;
  selectFile: (value: SelectedFileType) => void;
  setCurrentUploadFileData: (value: currentUploadFileType) => void;
  setIsUploading: (value: boolean) => void;
};

export default stateActionsUpload;
