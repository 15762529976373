import React, { createContext, useContext, useReducer, useMemo } from 'react';
import { rootReducer, defaultState, RootState } from './reducers';
import { actionsType, stateActions } from './actions';

type StateProviderValue = {
  state: RootState;
  actions: actionsType;
};

const StateContext = createContext<StateProviderValue>({
  state: defaultState,
  actions: stateActions as actionsType,
});

export const useStateProvider = () => useContext(StateContext);

export function StateProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(rootReducer, defaultState);

  const actions = useMemo(() => {
    return Object.entries(stateActions).reduce(
      (acc, [key, actionFn]) => ({
        ...acc,
        [key]: (payload?: any) => {
          dispatch(actionFn(payload));
        },
      }),
      {}
    ) as actionsType;
  }, []);

  return (
    <StateContext.Provider value={{ state, actions }}>
      {children}
    </StateContext.Provider>
  );
}
