import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export const useDateFormat = (dt: Date) => {
  const { t } = useTranslation();

  if (!dt) return '';

  const now = dayjs();
  const date = dayjs(dt);

  const isToday = dayjs(date).isSame(now, 'day');
  const isYesterday = dayjs(date).isSame(now.subtract(1, 'day'), 'day');

  if (isToday) {
    return t('common.today', { time: date.format('HH:mm') });
  } else if (isYesterday) {
    return t('common.yesterday', { time: date.format('HH:mm') });
  } else {
    return date.format('DD.MM.YYYY HH:mm');
  }
};
