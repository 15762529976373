import { useTranslation } from 'react-i18next';
import {
  Button,
  CloseIcon,
  ConversationParticipantType,
  CustomerStatusNotificationStyles,
  EnvelopeIcon,
  IconButton,
  LoadingTransitionPage,
  Stack,
  useIsSelectedView,
  useStateProvider,
  useUserProfile,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { useEffect } from 'react';

export const CustomerStatusNotification = ({
  participant,
  onClose,
  classNames = '',
  showNotification,
  members,
}: {
  participant?: ConversationParticipantType;
  onClose: () => void;
  classNames?: string;
  showNotification: boolean;
  members: ConversationParticipantType[];
}) => {
  const { t } = useTranslation();
  const { status, email, contactId, customerId, displayName } = {
    ...participant,
  };
  const { readOnly } = useStateProvider().state.conversation.conversationData;
  const isMobileView = useIsSelectedView('sm');

  const { setCustomerData, setToggleCustomerModal } =
    useStateProvider().actions;

  const profileData = useUserProfile();
  const filteredParticipants = members.filter(
    (item) => item.userId !== profileData.userId
  );

  useEffect(() => {
    status &&
      contactId &&
      customerId &&
      displayName &&
      setCustomerData({ contactId, email, status, customerId, displayName });
  }, [contactId, customerId, displayName, email, setCustomerData, status]);

  if (
    !(
      showNotification &&
      participant?.status &&
      participant?.status !== 'Active'
    ) ||
    filteredParticipants.length > 1 ||
    readOnly
  )
    return <></>;

  if (!status || !contactId) return <LoadingTransitionPage />;

  const handleClick = () => {
    displayName &&
      customerId &&
      setCustomerData({ contactId, email, status, customerId, displayName });
    setToggleCustomerModal(true);
  };

  const handleCloseNotification = () => {
    onClose();
  };

  return (
    <div className={classnames('w-full', !isMobileView && 'pt-14', classNames)}>
      <div
        className={classnames(
          'mx-auto max-w-[400px] mt-[20px] flex flex-col flex-wrap text-center',
          isMobileView && 'rounded-none mt-14'
        )}
      >
        <Stack
          direction="row"
          className={CustomerStatusNotificationStyles.header}
          alignItems="start"
        >
          <p className={classnames(isMobileView && 'text-lg font-semibold')}>
            {t(`statusReport.${status}.header`)}
          </p>
          <IconButton
            onClick={handleCloseNotification}
            hoverable={false}
            className={classnames(isMobileView ? 'mb-6' : 'absolute right-7')}
          >
            <CloseIcon color={'gray-400'} />
          </IconButton>
        </Stack>
        <h2
          className={classnames(
            CustomerStatusNotificationStyles.body,
            isMobileView && 'text-sm'
          )}
        >
          {t(`statusReport.${status}.body`)}
        </h2>
        <Button
          className={classnames(
            CustomerStatusNotificationStyles.button,
            'w-fit mx-auto',
            '!border-[#0170df]'
          )}
          variant={'secondary'}
          onClick={handleClick}
        >
          <IconButton
            hoverable={false}
            customSize="ml-0 pl-0 pr-2 text-blue-500"
          >
            <EnvelopeIcon color={'#0170df'} />
          </IconButton>
          {t(`statusReport.${status}.action`)}
        </Button>
      </div>
    </div>
  );
};

export default CustomerStatusNotification;
