import { Stack } from '@trueconnect/tp-components';
import classnames from 'classnames';

export const TopicsSidebar = ({
  children,
  isFull = true,
  isMobileView,
  className,
}: {
  children: React.ReactNode;
  isFull?: boolean;
  isMobileView?: boolean;
  className?: string;
}) => {
  return (
    <Stack
      isResizable={!isMobileView && !!isFull}
      className={classnames(
        'h-full relative',
        isFull ? 'w-full' : '!w-[45px]',
        'border-r',
        isFull && !isMobileView && '!min-w-[280px] w-[280px]',
        className
      )}
      resizeProps={{
        width: isFull ? 280 : 45,
        height: 0,
        resizeHandles: ['e'],
        minConstraints: [280, -1],
        maxConstraints: [400, -1],
        className: classnames(isMobileView && '!w-full !h-full'),
      }}
    >
      {children}
    </Stack>
  );
};

export default TopicsSidebar;
