import { useLocation } from 'react-router-dom';
import { TabProps } from './../components';

type useGetTabIndexType = {
  tabs: TabProps[];
};

export const useGetTabIndex = ({ tabs }: useGetTabIndexType) => {
  const location = useLocation();

  const foundIndex = tabs
    .slice()
    .reverse()
    .findIndex(
      (obj: TabProps) =>
        obj.path && (location.pathname || '').startsWith(obj.path)
    );

  if (foundIndex == -1) return 0;

  return tabs.length - 1 - foundIndex;
};

export default useGetTabIndex;
