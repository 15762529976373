import {
  ItemTypes,
  Stack,
  branchType,
  formatBranches,
  hoverClassname,
  orderBranches,
  useStateProvider,
} from '@trueconnect/tp-components';
import { TopicType } from '@trueconnect/tp-components/src/providers/WorkManagement/typing';
import classnames from 'classnames';
import { useDrop } from 'react-dnd';

export const TopicsItem = ({
  item,
  branches,
  onClick,
  extraItems,
  secondaryText = 'customerName',
}: {
  item: TopicType;
  branches: branchType[];
  onClick?: (item: TopicType) => void;
  secondaryText?: 'creatorUserName' | 'customerName';
  extraItems?: (item: TopicType) => React.ReactNode;
}) => {
  const {
    selectedTopic = {},
    draggedConversation,
    availableBranches,
  } = useStateProvider().state.workManagement;
  const isSelected = item.id == selectedTopic?.id;

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.conversationListItem,
    drop: () => item,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const condition =
    draggedConversation &&
    !(draggedConversation?.customerId === item.customerId || !item.customerId);

  const opacity = condition ? 0.4 : 1;

  return (
    <div
      ref={drop}
      className="w-full h-full overflow-hidden"
      style={{ opacity }}
    >
      <Stack
        direction="row"
        dataTestid={`test_topicItem_${item.displayName}`}
        className={classnames(
          'w-full h-full pl-[25px] cursor-pointer',
          isSelected ? 'bg-gray-100' : hoverClassname,
          isOver && !condition && 'border-l border-customColor_5',
          !!extraItems && '!pr-[60px]'
        )}
        alignItems="center"
        onClick={() => {
          onClick?.(item);
        }}
      >
        <Stack alignItems="start" className="w-full pr-2" direction="col">
          <Stack direction="row" className="w-full h-full">
            <p
              className={classnames(
                'text-sm font-semibold truncate leading-normal text-black'
              )}
            >
              <span>{item.displayName}</span>
            </p>
          </Stack>
          <span className="pt-[2px] text-xs font-medium leading-normal">
            {secondaryText == 'creatorUserName'
              ? item.creatorUserName
              : item.customerName}
          </span>
          <span className="text-customColor_4 pt-[2px] w-full font-normal text-xs leading-normal">
            {formatBranches(orderBranches(availableBranches, branches))}
          </span>
        </Stack>
        {extraItems?.(item)}
      </Stack>
    </div>
  );
};

export default TopicsItem;
