import {
  ConversationListItemProps,
  Dashboard as DashboardCommon,
  getBranches,
  useStateProvider,
  useUserProfile,
} from '@trueconnect/tp-components';
import { ConversationState, useApiInfiniteQuery } from 'src/api';

export const Dashboard = () => {
  const { displayName } = useUserProfile();
  const { availableBranches } = useStateProvider().state.workManagement;

  const queryConversations = useApiInfiniteQuery(
    'searchConversations',
    [
      {
        state: ConversationState.Open,
        search: '',
      },
    ],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map(
            (subitem) =>
              ({
                title: subitem.title,
                id: subitem.id,
                participants: subitem.participants,
                lastActive: subitem.lastActivityUtcTimestamp,
                branches: getBranches(availableBranches, subitem.branchIds),
                ...subitem,
              } as ConversationListItemProps)
          )
        ),
    }
  );

  return (
    <div className="w-full h-full [&>div>h1]:my-8">
      <DashboardCommon
        displayName={displayName}
        permissions={{ allowConversations: true }}
        queryConversations={queryConversations}
      />
    </div>
  );
};
