import {
  Stack,
  hoverClassname,
  IconButton,
  PlusIcon,
  DocumentIcon,
  ArrowsRightLeftIcon,
  ArrowUpTrayIcon,
  useNotificationSystem,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import classnames from 'classnames';
import { Dispatch, SetStateAction } from 'react';
import { useDropzone } from 'react-dropzone';

export const InvoiceDocument = ({
  selectedDocument,
  setSelectedDocument,
  documentId,
}: {
  selectedDocument: File | undefined;
  setSelectedDocument: Dispatch<SetStateAction<File | undefined>>;
  documentId?: string;
}) => {
  const { addNotification } = useNotificationSystem();
  const { getRootProps, isDragActive } = useDropzone({
    maxFiles: 1,
    accept: { 'application/pdf': ['.pdf'] },
    onDrop: (acceptedFiles) => {
      setSelectedDocument(acceptedFiles[0]);
    },
    noClick: true,
    noKeyboard: true,
  });

  if (!selectedDocument && !documentId)
    return (
      <div className={classnames('w-full h-[56px]')}>
        <div
          {...getRootProps()}
          className={`${classnames(
            'w-full cursor-pointer h-full bg-customColor_7 border-b',
            hoverClassname,
            isDragActive ? 'bg-gray-100 bg-opacity-90 ' : ''
          )}`}
        >
          <label
            htmlFor={'invoice-input'}
            className="block flex-1 flex align-center w-full h-full cursor-pointer"
          >
            {!isDragActive && (
              <Stack
                alignItems="center"
                direction="row"
                className="text-base font-medium text-[#1C64F2] px-4"
              >
                <IconButton hoverable={false} className="!m-0 !p-0 !w-5 !mr-2">
                  <PlusIcon color="#1C64F2" />
                </IconButton>
                <p>{t('policyDetails.editInvoice.uploadFile')}</p>
              </Stack>
            )}
            {isDragActive && (
              <Stack
                direction="row"
                className="w-full border-2 border-gray-300 border-dashed"
                alignItems="center"
                justifyContent="center"
              >
                <IconButton>
                  <ArrowUpTrayIcon />
                </IconButton>
              </Stack>
            )}
          </label>
          <input
            id="invoice-input"
            type="file"
            style={{ display: 'none' }}
            accept="application/pdf"
            onChange={(e) => {
              if (e.target.files && e.target.files.length) {
                const file = e.target.files[0];
                if (file.name.split('.').reverse()[0] !== 'pdf') {
                  addNotification({
                    title: t('policyDetails.uploadPolicyDocument.errorTitle'),
                    subTitle: t(
                      'policyDetails.uploadPolicyDocument.errorSubTitle'
                    ),
                    color: 'info',
                  });
                  return;
                }

                setSelectedDocument(e.target.files[0]);
              }
            }}
          />
        </div>
      </div>
    );

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="between"
      className={classnames('w-full h-[56px] px-4 bg-customColor_7 border-b')}
    >
      <Stack
        alignItems="center"
        direction="row"
        className="text-base font-medium"
      >
        <IconButton hoverable={false} className="!m-0 !p-0 !w-5 !mr-2">
          <DocumentIcon />
        </IconButton>
        <p>{t('policyDetails.editInvoice.premiumInvoice')}</p>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        className={classnames('p-1 rounded-lg')}
      >
        <label htmlFor="invoice-input">
          <Stack
            direction="row"
            alignItems="center"
            className={classnames(hoverClassname, 'p-1 rounded-lg')}
          >
            <input
              id="invoice-input"
              type="file"
              style={{ display: 'none' }}
              accept="application/pdf"
              onChange={(e) => {
                if (e.target.files && e.target.files.length) {
                  const file = e.target.files[0];
                  if (file.name.split('.').reverse()[0] !== 'pdf') {
                    addNotification({
                      title: t('policyDetails.uploadPolicyDocument.errorTitle'),
                      subTitle: t(
                        'policyDetails.uploadPolicyDocument.errorSubTitle'
                      ),
                      color: 'info',
                    });
                    return;
                  }

                  setSelectedDocument(e.target.files[0]);
                }
              }}
            />
            <IconButton hoverable={false} className="!m-0 !p-0">
              <ArrowsRightLeftIcon color="#0170DF" />
            </IconButton>
          </Stack>
        </label>
      </Stack>
    </Stack>
  );
};

export default InvoiceDocument;
