import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Alert } from 'flowbite-react';
import { ComponentProps, useCallback, useEffect } from 'react';
import { Stack } from './../../controls';
import { IconButton } from './../../components';
import classnames from 'classnames';

export type NotificationType = {
  id?: string;
  color?: 'failure' | 'gray' | 'info' | 'success' | 'warning';
  rounded?: boolean;
  withBorderAccent?: boolean;
  children?: React.ReactNode;
  title: string;
  subTitle?: string | unknown;
  onDismiss?: (id: string) => void;
  onClick?: () => void;
  icon?: React.FC<ComponentProps<'svg'>>;
  lifeTimeMs?: number | 'indefinite';
};

const notificationLifeTime = 5000; //miliseconds

const icons = {
  failure: <ExclamationCircleIcon className="text-red-500" />,
  gray: <ExclamationCircleIcon className="text-gray-500" />,
  info: <ExclamationCircleIcon className="text-blue-500" />,
  success: <ExclamationCircleIcon className="text-green-500" />,
  warning: <ExclamationCircleIcon className="text-yellow-500" />,
};

export const Notification: React.FC<NotificationType> = (props) => {
  const { onDismiss, id, onClick, lifeTimeMs = notificationLifeTime } = props;
  const deleteNotification = useCallback(() => {
    id && onDismiss?.(id);
  }, [onDismiss, id]);

  useEffect(() => {
    if (lifeTimeMs !== 'indefinite') {
      setTimeout(() => {
        deleteNotification();
      }, lifeTimeMs);
    }
  }, [deleteNotification, lifeTimeMs]);

  return (
    <Alert
      {...props}
      onClick={() => {}}
      onDismiss={deleteNotification}
      className={classnames(
        `m-[10px] max-w-[500px] [&>div]:!items-start`,
        props.color === 'info' &&
          'bg-blue-100 text-blue-700 [&>div>button]:bg-blue-100 [&>div>button]:text-blue-500',
        props.color === 'info' &&
          '[&>div>button:hover]:bg-blue-200 [&>div>button:focus]:ring-blue-400'
      )}
    >
      <Stack
        direction="col"
        alignItems="start"
        className={classnames(onClick && 'cursor-pointer')}
        onClick={() => {
          if (onClick) {
            onClick();
            deleteNotification();
          }
        }}
      >
        <Stack direction="row" alignItems="start">
          {/* <span className="font-medium">{props.color || 'info'}</span> */}
          <IconButton
            size="xs"
            hoverable={false}
            className="block !m-0 !p-0 !mr-1"
            imgClassName="w-10"
          >
            <>{icons[props.color || 'info']}</>
          </IconButton>
          <h2 className="block">{props.title}</h2>
        </Stack>
        <h2 className="text-ellipsis pr-1">{props.subTitle as string}</h2>
      </Stack>
    </Alert>
  );
};
