import { ColumnType, useIsSelectedView } from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FetchCustomerInfoDto } from 'src/api';
import SyncStatus from '../../../SyncStatus';
import {
  CompanyName,
  Address,
  Metadata,
  AccountManager,
  FavoriteButton,
} from './HoldingInfo';

export const useGetHoldingColumns = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns: ColumnType<FetchCustomerInfoDto>[] = [
    {
      dataPropName: 'name',
      title: <p className="pl-9">{t('customers.company')}</p>,
      render: CompanyName,
      widthRatio: 6,
    },
    {
      dataPropName: 'address',
      title: <p>{t('customers.address')}</p>,
      render: Address,
      widthRatio: 4,
    },
    {
      dataPropName: 'metadata',
      title: <p>{t('customers.metadata')}</p>,
      render: Metadata,
      widthRatio: 2,
    },
    {
      dataPropName: 'accountManager',
      title: <p>{t('customers.accountManager')}</p>,
      render: AccountManager,
      widthRatio: 3,
    },
    {
      dataPropName: 'more',
      title: <SyncStatus showText={false} />,
      render: (item) => FavoriteButton(item, (s) => navigate(s || '')),
      widthRatio: 1,
    },
  ];

  const responsiveColumns: ColumnType<FetchCustomerInfoDto>[] = [
    {
      dataPropName: 'name',
      title: <p className="pl-9">{t('customers.company')}</p>,
      render: CompanyName,
      widthRatio: 6,
    },
    {
      dataPropName: 'metadata',
      title: t('customers.metadata'),
      render: Metadata,
      widthRatio: 3,
    },
  ];

  const isMobile = useIsSelectedView('sm');
  const isTablet = useIsSelectedView('md');

  if (isTablet) {
    return [
      ...responsiveColumns,
      {
        dataPropName: 'accountManager',
        title: t('customers.accountManager'),
        render: AccountManager,
        widthRatio: 3,
      },
    ];
  }

  return isMobile ? responsiveColumns : columns;
};

export default useGetHoldingColumns;
