import {
  PolicyType,
  PolicyTable,
  NotificationImage,
  useStateProvider,
  PolicyToolkit,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import { useApiInfiniteQuery } from 'src/api';
import { useGetColumns } from './useGetColumns';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { useEffect } from 'react';

export const PolicyTableBroker = ({ ids }: { ids: number[] | undefined }) => {
  const navigate = useNavigate();
  const { searchPolicyParams, searchPolicyBranchFilter } =
    useStateProvider().state.customer;
  const { setPolicySearchParams, setPolicyBranchFilter } =
    useStateProvider().actions;

  useEffect(() => {
    if (searchPolicyBranchFilter) {
      setPolicySearchParams({
        branchIds: [searchPolicyBranchFilter],
      });
      setPolicyBranchFilter(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPolicyBranchFilter]);

  const searchBranches = searchPolicyBranchFilter
    ? [searchPolicyBranchFilter, ...(searchPolicyParams.branchIds || [])]
    : searchPolicyParams.branchIds;

  const queryPolicies = useApiInfiniteQuery(
    'searchPolicies',
    [
      {
        customerIds: ids,
        activeYear: searchPolicyParams.year,
        branchIds: searchBranches,
        page: 0,
      },
    ],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map((subitem) => subitem as PolicyType)
        ),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const columns = useGetColumns();
  const isMobileView = useIsSelectedView('sm');

  return (
    <div className="w-full h-full flex flex-col">
      <div className={classnames('w-full', !isMobileView && 'p-3')}>
        <PolicyToolkit showBranches={true} />
      </div>
      <PolicyTable
        columns={columns}
        isSearching={
          !!(searchPolicyParams.year || searchPolicyParams.branchIds?.length)
        }
        queryPolicies={queryPolicies}
        onRowClick={(data) => {
          navigate(`policy/${data.id}`);
        }}
        nothingFound={
          <NotificationImage
            text={
              searchPolicyParams.year || searchPolicyParams.branchIds?.length
                ? t('policies.nothingFiltered')
                : t('policies.nothingFound')
            }
          />
        }
      />
    </div>
  );
};

export default PolicyTableBroker;
