import {
  Button,
  ContactStatus,
  SearchInput,
  Stack,
  VirtualizedList,
  arraysEqual,
  fullScreenModal,
  mobileModalStyles,
  relativeFull,
  useIsSelectedView,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import {
  useApiInfiniteQuery,
  useApiInvalidateQueries,
  useApiMutation,
} from 'src/api';
import classnames from 'classnames';
import useGetColumns from './useGetColumns';
import PermissionDialog, {
  selectedUserType,
} from 'src/components/permissions/PermissionDialog';
import ShareDocumentModal from './ShareDocumentModal';

export const ManageDocumentAccessModal = () => {
  const invalidateQueries = useApiInvalidateQueries();
  const { addNotification } = useNotificationSystem();
  const {
    toggleShareModal: isVisible,
    customerData,
    companyData,
    documentToShare,
  } = useStateProvider().state.customer;
  const {
    setToggleShareModal,
    setClearCustomerData,
    setToggleCustomerModal,
    setDocumentToShare,
    setAddToAction,
    removeItemAction,
  } = useStateProvider().actions;

  const sharedWithUsers = documentToShare?.sharedWithUsers?.length
    ? documentToShare?.sharedWithUsers
    : [];
  const isMobileView = useIsSelectedView('sm');
  const [selectedUsers, setSelectedUsers] = useState(sharedWithUsers);
  const [searchParam, setSearchParam] = useState('');

  const onClose = () => {
    setToggleShareModal(false);
    setClearCustomerData();
    setDocumentToShare({ fileName: '', id: '' });
    setSelectedUsers([]);
  };

  useEffect(() => {
    //hide customer if broker changes status
    if (customerData.status == ContactStatus.NotInvited) {
      setClearCustomerData();
      setToggleCustomerModal(false);
    }
  }, [customerData, setClearCustomerData, setToggleCustomerModal]);

  const queryContacts = useApiInfiniteQuery(
    'searchContacts',
    [
      searchParam,
      companyData?.id,
      [ContactStatus.Invited, ContactStatus.Active],
      0,
    ],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map((subitem) => ({
            ...subitem,
            name: `${subitem.firstName} ${subitem.lastName}`,
            company: subitem.customerName,
          }))
        ),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const handleToggleCheckBox = (userId: string) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((item) => item !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const [showAllowUsersModal, setShowAllowUsersModal] = useState(false);
  const [showFinalAccept, setShowFinalAccept] = useState(false);

  const getUsersWithNoAccess = () => {
    const existingUsers = (queryContacts.data?.flat(1) || []).filter(
      (item) => item.userId && selectedUsers.includes(item.userId)
    );
    return existingUsers
      .filter((item) => !item.permissions?.allowSharedDocuments)
      .map((item) => {
        return {
          ...item,
          contactId: item.id,
          displayName: classnames(item.firstName, item.lastName),
        } as selectedUserType;
      });
  };

  const usersWithoutDocumentAccess = getUsersWithNoAccess();

  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('search_Customer_Documents');
  }, [invalidateQueries]);

  const { mutate: shareDocument } = useApiMutation(
    'share_Document_With_Customer',
    {
      onSuccess: () => {
        invalidateQuery();
        addNotification({
          color: 'success',
          title: t('permissions.allowSharedDocuments.documentShared.success', {
            documentName: documentToShare?.fileName,
          }),
        });
        documentToShare?.id && removeItemAction(documentToShare?.id);
      },
    }
  );

  const ManageAccess = useCallback(async () => {
    onClose();
    documentToShare?.id && setAddToAction(documentToShare?.id);
    companyData?.id &&
      documentToShare?.id &&
      shareDocument([
        companyData?.id,
        documentToShare?.id,
        {
          sharedWithUsers: selectedUsers,
        },
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentToShare?.id, selectedUsers, sharedWithUsers]);

  const columns = useGetColumns({ selectedUsers, handleToggleCheckBox });

  return (
    <>
      <Modal
        show={isVisible}
        onClose={onClose}
        size="7xl"
        className={classnames(
          mobileModalStyles(isMobileView, true),
          isMobileView && fullScreenModal(),
          '[&>div>div>div:nth-child(3)]:border-none [&>div>div>div:nth-child(3)]:py-4',
          '[&>div>div>div:nth-child(1)>h3]:leading-normal [&>div>div>div:nth-child(1)>h3]:font-semibold',
          '[&>div>div>div:nth-child(1)>h3]:text-base'
        )}
        title={t('permissions.allowSharedDocuments.selectedUsersModal')}
        footer={
          <Stack direction="row" className="w-fit ml-auto gap-2">
            <Button onClick={onClose} dataTestid="CancelManageDocumentAccess">
              {t('common.cancel')}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (arraysEqual(sharedWithUsers, selectedUsers)) {
                  onClose();
                }

                if (usersWithoutDocumentAccess.length) {
                  setShowAllowUsersModal(true);
                } else {
                  setShowFinalAccept(true);
                }
              }}
              dataTestid="ConfirmManageDocumentAccess"
            >
              {t('permissions.allowSharedDocuments.acceptBtn')}
            </Button>
          </Stack>
        }
      >
        {!isMobileView && (
          <div className={classnames('w-full', isMobileView && 'px-2 pt-2')}>
            <SearchInput
              dataTestid="DocumentShareCustomerSearch"
              classNames={{
                wrapper: 'h-10 w-full max-w-[300px] mb-3',
              }}
              inputName="DocumentShareCustomerSearch"
              setSearchParam={setSearchParam}
              placeholder={t('tab.contacts.Searchplaceholder')}
            />
          </div>
        )}
        <Stack
          direction="col"
          alignItems="stretch"
          className={classnames(relativeFull, !isMobileView && '!h-[400px]')}
        >
          <Stack className="w-full h-full absolute">
            <div className="w-full h-full">
              <VirtualizedList
                columns={columns}
                infinityQuery={queryContacts}
                classNames={{
                  column: 'first:ml-0',
                  row: 'cursor-pointer',
                }}
                rowHeight={70}
              />
            </div>
          </Stack>
        </Stack>
      </Modal>
      <PermissionDialog
        {...{
          isVisible: showAllowUsersModal,
          isLoading: false,
          onOk: () => {
            setShowFinalAccept(true);
          },
          permission: 'allowSharedDocuments',
          onClose: () => {
            setShowAllowUsersModal(false);
          },
          usersToAllow: getUsersWithNoAccess(),
        }}
      />
      <ShareDocumentModal
        {...{
          visible: showFinalAccept,
          name: documentToShare?.fileName || '',
          onClose: () => {
            setShowFinalAccept(false);
          },
          onAccept: ManageAccess,
        }}
      />
    </>
  );
};

export default ManageDocumentAccessModal;
