import {
  Stack,
  IconButton,
  XMarkIcon,
  defaultScrollbar,
  Button,
  useIsSelectedRange,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { useEffect } from 'react';
import classnames from 'classnames';
import { t } from 'i18next';
import { ResizableBoxProps } from 'react-resizable';

export const initialResizeBoxProps: ResizableBoxProps = {
  width: 300,
  height: 0,
  resizeHandles: ['e'],
  minConstraints: [280, -1],
  maxConstraints: [500, -1],
};

export const ExtendedConversationSearch: React.FC<{
  children: React.ReactNode;
  resizeProps?: ResizableBoxProps;
  classNames?: string;
  showExtendedSearch?: boolean;
  setShowExtendedSearch?: (value: boolean) => void;
  clearExtendedSearchParams?: () => void;
}> = ({
  children,
  resizeProps = initialResizeBoxProps,
  classNames,
  showExtendedSearch,
  setShowExtendedSearch,
  clearExtendedSearchParams,
}) => {
  const isMobileView = useIsSelectedView('sm');
  const isSelectedView = useIsSelectedRange(['sm', 'md', 'lg', 'xl']);

  useEffect(() => {
    !isMobileView && clearExtendedSearchParams?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showExtendedSearch]);

  const minWidth = resizeProps.minConstraints?.[0] || 280;

  return (
    <>
      {showExtendedSearch && (
        <Stack
          direction="col"
          className={classnames(
            'h-full border-r w-full',
            `min-w-[${minWidth}px]`,
            classNames
          )}
          isResizable={!isSelectedView}
          resizeProps={resizeProps}
        >
          <Stack
            className="!h-[55px] w-full"
            alignItems="center"
            justifyContent="between"
            direction="row"
          >
            <p className="pl-4 font-medium text-[#6B7280]">
              {t('conversation.extendedSearch.filter')}
            </p>
            <IconButton
              onClick={() => {
                setShowExtendedSearch?.(!showExtendedSearch);
              }}
            >
              <XMarkIcon data-testid="closeExtendedConversationSearch" />
            </IconButton>
          </Stack>
          <Stack
            className={classnames(
              'w-full overflow-y-auto !overflow-x-hidden relative h-full border-t flex-1',
              defaultScrollbar
            )}
          >
            <div className="w-full h-full absolute">{children}</div>
          </Stack>
          <Stack className="w-full p-2 mb-2 gap-2" direction="row">
            <Button
              className="w-full"
              variant="primary"
              onClick={() => {
                clearExtendedSearchParams?.();
              }}
            >
              {t('conversation.extendedSearch.clearFilters')}
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default ExtendedConversationSearch;
