import {
  Background,
  CommonStyles,
  createConversationStyles,
  SmartSpinnerLocal,
  Stack,
  textStyle_1,
  ContactPermissionDto,
  IconButton,
} from '@trueconnect/tp-components';
import { CloseIcon } from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

export interface CreateConversationPanelProps {
  isCreatingConversation: boolean;
  close: () => void;
  children: React.ReactNode;
  header: React.ReactNode;
}

export enum ContactStatus {
  NotInvited = 'NotInvited',
  Invited = 'Invited',
  Active = 'Active',
  Deactivated = 'Deactivated',
}

export type ContactType = {
  id?: number | undefined;
  name?: string;
  customerId?: number | undefined;
  company?: string;
  email?: string;
  status?: ContactStatus;
  firstName?: string;
  lastName?: string;
  img?: string;
  activeInTrueData?: boolean;
  permissions?: ContactPermissionDto;
};

export function CreateConversationPanel(props: CreateConversationPanelProps) {
  const { isCreatingConversation, children, close, header } = props;
  const { t } = useTranslation();

  return (
    <Background>
      <SmartSpinnerLocal
        name="CreateConversationPanel"
        condition={isCreatingConversation}
      >
        <div className={createConversationStyles.Wrapper}>
          <Stack className={CommonStyles.full}>
            <Stack className="w-full">
              <Stack
                className={createConversationStyles.PanelTop}
                alignItems="center"
                direction="row"
                justifyContent="between"
              >
                <h1
                  className={classnames(
                    textStyle_1,
                    'font-medium leading-normal'
                  )}
                  data-testid="createConversation_header"
                >
                  {t('navbar.createConversationPanel.title')}
                </h1>
                <IconButton
                  className="h-7 cursor-pointer	text-customColor_4"
                  onClick={close}
                  dataTestId="createConversation_close"
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              {header}
            </Stack>

            {children}
          </Stack>
        </div>
      </SmartSpinnerLocal>
    </Background>
  );
}

export default CreateConversationPanel;
