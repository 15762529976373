import { BrokerAppLogic } from 'src/api';
import { Mapping, Folder } from './typing';
import { Dispatch, SetStateAction } from 'react';

export const generateFolders = async (
  path: string,
  parentFolderId: string,
  mapping: Mapping,
  customerId: number,
  setFolderInCreationPath: Dispatch<SetStateAction<string>>
): Promise<string> => {
  const parts = path
    .replace(/\/?[^/]+(\.[^./]+)?$/, '')
    .split('/')
    .filter(Boolean);

  let currentParentId = parentFolderId;
  let currentParentPath = '';

  for (const part of parts) {
    const currentPath = currentParentPath
      ? `${currentParentPath}/${part}`
      : part;
    if (!mapping[currentPath]) {
      setFolderInCreationPath(currentPath);
      const newFolderId = (
        await BrokerAppLogic.createFolder(customerId, {
          name: part,
          parentFolderId: currentParentId,
        })
      ).id;
      if (newFolderId) {
        const newFolder: Folder = {
          name: part,
          parentId: currentParentId,
          id: newFolderId,
        };
        mapping[currentPath] = newFolder;
      }
    }
    currentParentId = mapping[currentPath].id;
    currentParentPath = currentPath;
  }

  return currentParentId;
};
