import { useRef, useState } from 'react';
import { branchType } from './../../providers';
import { CheckBox, IconButton, Radio } from './../../components';
import { useClickAway } from 'react-use';
import classnames from 'classnames';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  FolderIcon,
} from '@heroicons/react/24/solid';
import { CloseIcon } from './../../images';
import { commonText, defaultScrollbar } from './../../styles';
import { Stack } from './../../controls';
import { t } from 'i18next';

export const BranchFilter = ({
  setBranches,
  branchIds,
  availableBranches,
  isMobileView,
  mode = 'primary',
  placeholder = t('policies.selectBranch'),
  closeAfterSelect,
}: {
  availableBranches: branchType[];
  branchIds: (string | undefined)[];
  setBranches: (branchId: string) => void;
  isMobileView?: boolean;
  mode?: 'primary' | 'secondary';
  placeholder?: string;
  closeAfterSelect?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => {
    setIsOpen(false);
  });

  const selectedBranches = availableBranches.filter((item) =>
    branchIds.includes(item.id)
  );

  return (
    <div ref={ref} className="relative" data-testid="branchFilter">
      <div
        className={classnames(
          'flex flex-row items-center h-[44px] w-[200px] overflow-hidden cursor-pointer border border-2',
          mode == 'primary' && ' px-4',
          mode == 'secondary' && 'pl-4 pr-2',
          !isMobileView && 'rounded-lg'
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        {mode == 'primary' && (
          <>
            <IconButton
              customSize="!w-4 !h-4"
              hoverable={false}
              className="mr-1 pt-[1px]"
            >
              <FolderIcon />
            </IconButton>
            <p
              className={classnames(
                'w-full truncate text-sm font-medium leading-normal',
                selectedBranches.length
                  ? 'text-customColor_5'
                  : 'text-customColor_4'
              )}
            >
              {selectedBranches.length
                ? selectedBranches.map((item) => item.convenientName).join(', ')
                : t('policies.selectBranches')}
            </p>
            {!!selectedBranches.length && (
              <>
                <IconButton
                  customSize="!w-4 !h-4"
                  hoverable={false}
                  className="mr-1 pt-[1px]"
                  onClick={() => {
                    setBranches('');
                  }}
                >
                  <CloseIcon color="#0170DF" />
                </IconButton>
                <p className="text-sm font-normal leading-normal text-customColor_4 pt-[1px]">
                  ({selectedBranches.length})
                </p>
              </>
            )}
          </>
        )}
        {mode == 'secondary' && (
          <Stack
            direction="row"
            className="overflow-hidden w-full"
            alignItems="center"
            justifyContent="between"
          >
            <p
              className={classnames(
                'w-full py-[2px] truncate text-sm font-medium leading-normal text-customColor_4'
              )}
            >
              {selectedBranches.length
                ? isMobileView
                  ? selectedBranches[0].shortName
                  : selectedBranches[0].name
                : placeholder}
            </p>
            <IconButton className="!mx-0 !px-0">
              {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </IconButton>
          </Stack>
        )}
      </div>
      {isOpen && (
        <div
          className={classnames(
            'absolute bg-white border border-gray-300 z-10 w-full max-h-[150px] overflow-y-auto mt-1 px-3 rounded-lg',
            isMobileView ? 'w-[200px]' : '!w-[300px]',
            defaultScrollbar
          )}
        >
          {availableBranches.map((item, index) => (
            <Stack
              key={item.id}
              direction="row"
              className="cursor-pointer h-7 gap-1"
              alignItems="center"
              onClick={() => {
                setBranches(item.id || '');
                closeAfterSelect && setIsOpen(false);
              }}
            >
              {mode == 'primary' && (
                <CheckBox
                  className="BranchSearchFilter"
                  dataTestId={item.id}
                  containerClassname="!w-7"
                  checked={selectedBranches
                    .map((branch) => branch.id)
                    .includes(item.id)}
                  onChange={() => ({})}
                />
              )}
              {mode == 'secondary' && (
                <Radio
                  className="BranchSearchFilter cursor-pointer"
                  onChange={() => ({})}
                  checked={selectedBranches
                    .map((branch) => branch.id)
                    .includes(item.id)}
                />
              )}
              <p
                data-testid={`branchOption_${index}`}
                className={classnames(commonText, 'text-sm !text-[#4B5563]')}
              >
                {isMobileView ? item.shortName : item.name}
              </p>
            </Stack>
          ))}
        </div>
      )}
    </div>
  );
};

export default BranchFilter;
