import {
  Stack,
  Avatar,
  customerContacts,
  CustomerStyles,
} from '@trueconnect/tp-components';
import { SearchBrokerResponseDto } from 'src/api';
import classnames from 'classnames';
import { getContactImage } from 'src/utils';
import { useState, useEffect } from 'react';

export const BrokerData: React.FC<{
  item: SearchBrokerResponseDto;
  disabled: boolean;
}> = ({ item, disabled }) => {
  const { imageId } = item;
  const [image, setImage] = useState<string>('');

  useEffect(() => {
    (async () => {
      setImage(await getContactImage(imageId));
    })();
  }, [imageId]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      className={CustomerStyles.greyedOut(disabled)}
    >
      <Avatar
        imgSrc={image}
        initials={item.displayName}
        size="xs"
        classNames="mr-2 ml-0 [&>div>img]:w-8 [&>div>img]:h-8 [&>div]:w-8 [&>div]:h-8"
      />
      <p className={customerContacts}>{item.displayName}</p>
    </Stack>
  );
};

export const BrokerEmail: React.FC<{
  item: SearchBrokerResponseDto;
  disabled: boolean;
}> = ({ item, disabled }) => {
  return (
    <a
      href={`mailto: ${item.email}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={classnames(
        customerContacts,
        CustomerStyles.greyedOut(disabled)
      )}
    >
      {item.email}
    </a>
  );
};
