import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import IconButton from './../../../components/IconButton';
import { Stack } from './../../../controls';
import { useStateProvider } from './../../../providers';
import { ReceiveMessageType } from './../../../providers/HubListener/typing';
import { useTranslation } from 'react-i18next';

export const ReceiveMessage = ({
  notification,
  id,
  date,
}: {
  notification: ReceiveMessageType;
  id: string;
  date: Date | string;
}) => {
  const { RemoveNotification } = useStateProvider().actions;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Stack
        direction="row"
        alignItems="start"
        justifyContent="between"
        className="w-full"
        onClick={() => {
          navigate(`/conversations/${notification.conversationId}`);
          RemoveNotification(id);
        }}
      >
        <Stack direction="col">
          {date && <h1 className="font-semibold">{date.toString()}</h1>}
          <h1>
            {t('Notifications.ReceiveMessage.text', {
              conversationName: notification.conversationName,
            })}
          </h1>
        </Stack>
        <IconButton className="pr-0 mr-0" hoverable={false}>
          <ArrowRightIcon />
        </IconButton>
      </Stack>
    </>
  );
};
