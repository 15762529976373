import { Button, Stack } from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

export const ChangeTitleModal = ({
  updateTitle,
  title,
  isLoading,
  isVisible,
  close,
}: {
  updateTitle: (value: string) => void;
  title?: string;
  isLoading?: boolean;
  isVisible?: boolean;
  close: () => void;
}) => {
  const [value, setValue] = useState(title);

  useEffect(() => {
    setValue(title);
  }, [title]);

  return (
    <Modal
      show={!!isVisible}
      title={t('common.modals.changeTitle')}
      onClose={close}
      footer={
        <Stack direction="row" className="w-full">
          <Button className="w-1/2 mx-1" onClick={close}>
            {t('common.cancel')}
          </Button>
          <Button
            isLoading={isLoading && value !== title}
            disabled={!value || value == title}
            className="w-1/2 mx-1"
            variant="primary"
            onClick={() => {
              !!value && updateTitle(value);
            }}
          >
            {t('common.save')}
          </Button>
        </Stack>
      }
    >
      <input
        value={value}
        className={'p-2 border w-full'}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
    </Modal>
  );
};

export default ChangeTitleModal;
