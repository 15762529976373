import { FileWithPath } from 'react-dropzone';
import { SelectedFileType, currentUploadFileType } from './typing';

type UploadState = {
  isUploadPanelVisible: boolean;
  filesToUpload: FileWithPath[];
  selectedFiles: SelectedFileType[];
  currentUploadFile: currentUploadFileType;
  isUploading: boolean;
};

type UploadAction =
  | { type: 'SET_IS_UPLOAD_PANEL_VISIBLE'; payload: boolean }
  | { type: 'SET_FILES_TO_UPLOAD'; payload: FileWithPath[] }
  | { type: 'SET_SELECTED_FILES'; payload: SelectedFileType[] }
  | { type: 'SET_CURRENT_UPLOAD_FILE_DATA'; payload: currentUploadFileType }
  | { type: 'SET_IS_UPLOADING'; payload: boolean }
  | { type: 'SELECT_FILE'; payload: SelectedFileType };

export const initialUploadState: UploadState = {
  isUploadPanelVisible: false,
  filesToUpload: [],
  selectedFiles: [],
  currentUploadFile: { cancel: () => ({}), progress: 0 },
  isUploading: false,
};

const uploadReducer = (
  state: UploadState = initialUploadState,
  action: UploadAction
): UploadState => {
  switch (action.type) {
    case 'SET_IS_UPLOAD_PANEL_VISIBLE':
      return { ...state, isUploadPanelVisible: action.payload };
    case 'SET_FILES_TO_UPLOAD':
      return { ...state, filesToUpload: Array.from(action.payload) };
    case 'SET_SELECTED_FILES':
      return { ...state, selectedFiles: action.payload };
    case 'SELECT_FILE':
      if (state.selectedFiles.some((file) => file.id === action.payload.id)) {
        return {
          ...state,
          selectedFiles: state.selectedFiles.filter(
            (file) => file.id != action.payload.id
          ),
        };
      }
      return {
        ...state,
        selectedFiles: [...state.selectedFiles, action.payload],
      };
    case 'SET_CURRENT_UPLOAD_FILE_DATA':
      return { ...state, currentUploadFile: action.payload };
    case 'SET_IS_UPLOADING':
      return { ...state, isUploading: action.payload };
    default:
      return state;
  }
};

export { uploadReducer };
export type { UploadAction };
export type { UploadState };
