import {
  ArrowDownTrayIcon,
  CloseIcon,
  DocumentIcon,
  IconButton,
  PencilIcon,
  Spinner,
  Stack,
  VirtualizedGridCellRendererType,
  currencyFormat,
  invoiceType,
  useGetColumns,
  useIsSelectedRange,
  useStateProvider,
} from '@trueconnect/tp-components';
import {
  Column,
  PriceColumn,
  RewardTypeColumn,
  formatDate,
} from '@trueconnect/tp-components/src/components/policy/InvoiceTable/columns';
import { t } from 'i18next';
import { CustomerDocumentDto, useApiMutation } from 'src/api';
import { NotShowToCustomerIcon } from 'src/components/NotShowToCustomerIcon';

export const Netto = (item: invoiceType) => {
  return (
    <Stack direction="row" className="w-full">
      <p className="ml-auto">{currencyFormat(item.nettoPremium)}</p>
    </Stack>
  );
};

export const Courtage = (item: invoiceType) => {
  return (
    <Stack direction="row" className="w-full">
      <p className="ml-auto">{item.courtage}</p>
    </Stack>
  );
};

export const Status = ({
  item,
  setInvoiceModalType,
  setSelectedInvoice,
}: {
  item: invoiceType;
  setInvoiceModalType: (value: string) => void;
  setSelectedInvoice: (value: invoiceType) => void;
}) => {
  if (!item || !item?.isConfirmed) return <></>;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      dataTestid={`invoiceStatus_${item.id}`}
    >
      <Stack
        direction="row"
        alignItems="center"
        className="text-xs w-fit cursor-pointer font-medium bg-[#DEF7EC] text-[#03543F] rounded-lg p-1 px-2"
        onClick={() => {
          setSelectedInvoice(item);
          setInvoiceModalType('reset');
        }}
      >
        <p className="mr-1 select-none">
          {t('policyDetails.invoiceTable.active')}
        </p>
        <IconButton hoverable={false} className="!p-0 !m-0 !h-4 !w-4">
          <CloseIcon color="#03543F" />
        </IconButton>
      </Stack>
    </Stack>
  );
};

const FileIcon = (item: invoiceType) => {
  return (
    <div className="relative">
      {!item.firstTimeDownloadedUtc && !!item.documentId && (
        <div
          data-testid={`indicator_${item.id}`}
          className="absolute top-0 right-2 w-3 h-3 bg-blue-500 rounded-full"
        />
      )}
      <IconButton hoverable={false}>
        {!item.documentId ? <DocumentIcon color="#E5E7EB" /> : <DocumentIcon />}
      </IconButton>
    </div>
  );
};

const FromColumn = (item: invoiceType) => {
  return (
    <Stack direction="row" alignItems="center">
      {FileIcon(item)}
      {Column(formatDate(item.dateFrom))}
    </Stack>
  );
};

export const Actions = (item: invoiceType) => {
  const { setSelectedInvoice, setInvoiceModalType } =
    useStateProvider().actions;

  const { mutate, isLoading } = useApiMutation('getDocumentById');

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="end"
      className="gap-1"
    >
      <IconButton
        dataTestId={`editInvoice_${item.id}`}
        className="!m-0"
        onClick={() => {
          setSelectedInvoice(item);
          setInvoiceModalType('edit');
        }}
      >
        <PencilIcon color="#1C64F2" />
      </IconButton>

      {item.documentId && (
        <>
          {isLoading ? (
            <Spinner className="mr-2 ml-1" />
          ) : (
            <IconButton
              dataTestId={`downloadInvoice_${item.id}`}
              className="!m-0 cursor-pointer"
              onClick={() => {
                mutate([item.documentId], {
                  onSuccess: (data) => {
                    const result = data as CustomerDocumentDto;
                    result.preViewUrl &&
                      window.open(result.preViewUrl, '_blank');
                  },
                });
              }}
            >
              <ArrowDownTrayIcon color="#1C64F2" />
            </IconButton>
          )}
        </>
      )}
    </Stack>
  );
};

export const PricesColumn = (item: invoiceType) => {
  return (
    <Stack direction="col" className="w-full gap-1">
      {item.amount && (
        <Stack
          className="w-full overflow-hidden [&>div]:w-auto leading-[20px]"
          direction="row"
          alignItems="center"
        >
          <p className="w-full text-nowrap truncate">
            {t('policyDetails.invoiceTable.price')}
          </p>
          {PriceColumn(item)}
        </Stack>
      )}

      <Stack className="w-full" direction="row" alignItems="center">
        <Stack direction="row" alignItems="center" justifyContent="end">
          <p className="w-full text-nowrap truncate">
            {t('policyDetails.invoiceTable.netto')}
          </p>
          <NotShowToCustomerIcon classNames="!h-4 pl-1" />
        </Stack>
        {Netto(item)}
      </Stack>

      <Stack
        className="w-full leading-[20px]"
        direction="row"
        alignItems="center"
      >
        <Stack direction="row" alignItems="center" justifyContent="end">
          <p className="w-full text-nowrap truncate">
            {t('policyDetails.invoiceTable.brokerage')}
          </p>
          <NotShowToCustomerIcon classNames="!h-4 pl-1" />
        </Stack>
        {Courtage(item)}
      </Stack>
    </Stack>
  );
};

export const DatePeriod = (item: invoiceType) => {
  return (
    <Stack className="w-full" direction="row" alignItems="center">
      {Column(formatDate(item.dateFrom))}
      <div className="px-2">-</div>
      {Column(formatDate(item.dateTo))}
    </Stack>
  );
};

export const MobileListItem = ({
  item,
  setInvoiceModalType,
  setSelectedInvoice,
}: {
  item: invoiceType;
  setInvoiceModalType: (value: string) => void;
  setSelectedInvoice: (value: invoiceType) => void;
}) => {
  return (
    <Stack className="w-full py-2" direction="col">
      <Stack className="w-full pb-2" direction="row">
        <Stack className="w-full" direction="row" alignItems="center">
          <div className="relative pr-3">
            {!item.firstTimeDownloadedUtc && !!item.documentId && (
              <div className="absolute -top-1 left-3 w-3 h-3 bg-blue-500 rounded-full" />
            )}
            <IconButton hoverable={false} className="!m-0 !p-0 !w-5">
              {!item.documentId ? (
                <DocumentIcon color="#E5E7EB" />
              ) : (
                <DocumentIcon />
              )}
            </IconButton>
          </div>
          {Status({ item, setInvoiceModalType, setSelectedInvoice })}
        </Stack>
        {Actions(item)}
      </Stack>

      <Stack direction="col" className="w-full p-1 gap-1">
        {item.policyRewardTypeName && RewardTypeColumn(item)}
        {DatePeriod(item)}
        {PricesColumn(item)}
      </Stack>
    </Stack>
  );
};

export const useGetInvoiceColumns = () => {
  const columns = useGetColumns();
  const { actions } = useStateProvider();
  const { setInvoiceModalType, setSelectedInvoice } = actions;
  const isMobileView = useIsSelectedRange(['sm', 'md']);
  const isTabletView = useIsSelectedRange(['lg', 'xl']);

  if (isMobileView) {
    return [
      {
        dataPropName: 'mobileItem',
        render: (item: invoiceType) => {
          return (
            <MobileListItem
              {...{ item, setInvoiceModalType, setSelectedInvoice }}
            />
          );
        },
      },
    ];
  }

  if (isTabletView) {
    return [
      {
        dataPropName: 'data',
        render: (item: invoiceType) => {
          return (
            <Stack direction="col" className="h-full">
              <Stack direction="row" alignItems="center" className="h-full">
                {FileIcon(item)}
                {DatePeriod(item)}
              </Stack>
              <div className="pl-[44px]">{RewardTypeColumn(item)}</div>
            </Stack>
          );
        },
      },
      {
        dataPropName: 'prices',
        render: (item: invoiceType) => {
          return (
            <Stack direction="col" className="py-2">
              {PricesColumn(item)}
            </Stack>
          );
        },
      },
      {
        dataPropName: 'actions',
        render: (item: invoiceType) => {
          return (
            <Stack direction="col" className="w-full gap-2" alignItems="end">
              {Actions(item)}
              {Status({ item, setInvoiceModalType, setSelectedInvoice })}
            </Stack>
          );
        },
        widthRatio: 0.5,
      },
    ];
  }

  return [
    {
      title: t('policyDetails.invoiceTable.from'),
      dataPropName: 'from',
      render: FromColumn,
      className: 'pl-[45px]',
    },
    ...columns,
    ...([
      {
        dataPropName: 'netto',
        render: Netto,
        title: (
          <Stack direction="row" alignItems="center" justifyContent="end">
            <p>{t('policyDetails.invoiceTable.netto')}</p>
            <NotShowToCustomerIcon classNames="!h-4 pl-1" />
          </Stack>
        ),
      },
      {
        dataPropName: 'percentage',
        render: Courtage,
        title: (
          <Stack direction="row" alignItems="center" justifyContent="end">
            <p>{t('policyDetails.invoiceTable.brokerage')}</p>
            <NotShowToCustomerIcon classNames="!h-4 pl-1" />
          </Stack>
        ),
      },
      {
        dataPropName: 'isShared',
        render: (item) => (
          <>{Status({ item, setInvoiceModalType, setSelectedInvoice })}</>
        ),
        title: (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <p>{t('policyDetails.invoiceTable.status')}</p>
            <NotShowToCustomerIcon classNames="!h-4 pl-1 mr-2" />
          </Stack>
        ),
      },
      {
        dataPropName: 'actions',
        render: Actions,
        title: (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            className="pr-2"
          >
            <p>{t('policyDetails.invoiceTable.actions')}</p>
          </Stack>
        ),
        widthRatio: 0.5,
      },
    ] as VirtualizedGridCellRendererType<invoiceType>[]),
  ];
};
