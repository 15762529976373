import { Label as LabelFlowbite, LabelProps } from 'flowbite-react';
import classnames from 'classnames';

export const Label = (props: LabelProps) => {
  return (
    <LabelFlowbite
      {...props}
      className={classnames(props.className, 'text-sm font-medium')}
    />
  );
};

export default Label;
