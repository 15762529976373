import { MessageType } from './Message';

type NotificationMessageProps = {
  message: MessageType;
};

export const NotificationMessage: React.FC<NotificationMessageProps> = ({
  message,
}) => {
  return (
    <div className="w-full h-fit my-[5px]">
      <div className="bg-customColor_2 flex flex-row w-fit p-[10px] m-auto rounded-lg">
        <img src={message.icon} alt="" />
        {message.messageText}
      </div>
    </div>
  );
};

export default NotificationMessage;
