import {
  ConversationListItemProps,
  ConversationsPage,
  getBranches,
  useNotificationSystem,
  useStateProvider,
  useUserProfile,
} from '@trueconnect/tp-components';
import { ConversationState, useApiInfiniteQuery } from 'src/api';
import StatusDialog from 'src/components/StatusBadge/StatusDialog';
import { ConversationContainer } from './ConversationContainer';
import { useParams } from 'react-router-dom';
import ExtendedConversationSearchBroker from 'src/components/ExtendedConversationSearch';
import ConversationTopicsSidebar from '../../../components/conversation/ConversationTopicsSidebar';
import { useState } from 'react';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { TopicType } from '@trueconnect/tp-components/src/providers/WorkManagement/typing';
import { t } from 'i18next';
import AddConversationToTopicDialog from 'src/components/topics/AddConversationToTopicDialog';
import RowRenderer from './RowRenderer';

export const InternalConversationsPage = () => {
  const { state } = useStateProvider();
  const { addNotification } = useNotificationSystem();
  const { searchValue, conversationFilter, extendedSearchParams } =
    state.conversation;

  const { availableBranches, selectedTopic } = state.workManagement;
  const { id: conversationId } = useParams();

  const {
    selectedCustomer,
    selectedContacts,
    dateStart,
    dateEnd,
    selectedBranches,
    selectedTopics,
    withoutTopic,
  } = extendedSearchParams;

  const queryConversations = useApiInfiniteQuery(
    'searchConversations',
    [
      {
        page: 0,
        state:
          conversationFilter === 'All'
            ? undefined
            : (conversationFilter as ConversationState),
        search: searchValue,
        customerIds: selectedCustomer ? [selectedCustomer] : [],
        userIds: selectedContacts,
        dateCreatedFrom: dateStart,
        dateCreatedTo: dateEnd,
        branchIds: selectedBranches,
        topicIds: [
          ...(selectedTopic.id ? [selectedTopic.id] : []),
          ...(selectedTopics || []),
        ],
        hasTopic: withoutTopic,
      },
    ],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map(
            (subitem) =>
              ({
                id: subitem.id,
                title: subitem.title,
                participants: subitem.participants,
                lastActive: subitem.lastActivityUtcTimestamp,
                branches: getBranches(availableBranches, subitem.branchIds),
                ...subitem,
              } as ConversationListItemProps)
          )
        ),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const { userId } = useUserProfile();
  const [searchParam, setSearchParam] = useState('');
  const queryTopics = useApiInfiniteQuery(
    'searchTopics',
    [
      {
        search: searchParam,
        page: 0,
        andAnyOf: {
          createdByUserIds: userId ? [userId] : [],
          conversationParticipantsUserIds: userId ? [userId] : [],
        },
      },
    ],
    {
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('Topics.Sidebar.error'),
        });
      },
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map((subitem) => subitem as TopicType)
        ),
    }
  ) as UseInfiniteQueryResult<TopicType[], Error>;

  const hasConversations =
    queryConversations.data && queryConversations.data.length > 0;

  return (
    <ConversationsPage
      isSearching={!!searchValue}
      queryConversations={queryConversations}
      rowRenderer={(item) => {
        return <RowRenderer {...{ item, selectedId: conversationId }} />;
      }}
      ExtendedConversationSearch={<ExtendedConversationSearchBroker />}
      TopicsSearch={
        <ConversationTopicsSidebar
          {...{ queryTopics, setSearchParam, searchParam }}
        />
      }
    >
      <ConversationContainer
        isSearching={!!searchValue}
        isLoading={!!queryConversations.isLoading}
        hasConversations={hasConversations}
        conversationId={conversationId}
      />
      <StatusDialog />
      <AddConversationToTopicDialog />
    </ConversationsPage>
  );
};

export default InternalConversationsPage;
