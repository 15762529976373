import { useGetInvoiceColumns } from './columns';
import {
  InvoiceTable,
  useIsSelectedRange,
  useStateProvider,
} from '@trueconnect/tp-components';
import { GetPolicyByIdDto, useApiQuery } from 'src/api';
import { InvoiceEdit } from './Modals/InvoiceEdit';
import ResetInvoice from './Modals/ResetInvoice';

export const PolicyInvoiceTable = ({
  policyData,
}: {
  policyData: GetPolicyByIdDto;
}) => {
  const { state, actions } = useStateProvider();
  const { invoiceModalType } = state.customer;
  const { id: policyId = '' } = policyData;

  const { setInvoiceModalType, setSelectedInvoice } = actions;
  const isMobileView = useIsSelectedRange(['sm']);
  const isTabletView = useIsSelectedRange(['sm', 'md', 'lg', 'xl']);
  const columns = useGetInvoiceColumns();

  const { data = [], isLoading } = useApiQuery(
    'getInvoicesByPolicyId',
    [policyId],
    {
      enabled: !!policyId,
    }
  );

  if (!policyId) {
    return <></>;
  }

  return (
    <>
      <InvoiceTable
        {...{
          data,
          isLoading,
          columns,
          isMobileView,
          isTabletView,
        }}
      />
      {invoiceModalType == 'edit' && (
        <InvoiceEdit
          {...{
            policyData,
            show: invoiceModalType == 'edit',
            onClose: () => {
              setSelectedInvoice({});
              setInvoiceModalType('');
            },
          }}
        />
      )}
      {invoiceModalType == 'reset' && <ResetInvoice />}
    </>
  );
};

export default PolicyInvoiceTable;
