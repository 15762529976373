import { Button, Stack } from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { t } from 'i18next';

export const ConfirmActionModal = ({
  type,
  onClose,
  onOk,
  visible,
}: {
  type: 'create' | 'delete' | '';
  onClose: () => void;
  onOk: () => void;
  visible: boolean;
}) => {
  return (
    <Modal
      show={visible}
      onClose={onClose}
      showFooter={false}
      title={type && t(`settings.deputyConfirm.${type}.title`)}
    >
      <p>{type && t(`settings.deputyConfirm.${type}.body`)}</p>
      <Stack direction="row" className="w-full gap-4 border-t pt-6 mt-3">
        <Button className="w-full" onClick={onClose}>
          {type && t(`settings.deputyConfirm.${type}.back`)}
        </Button>
        <Button
          variant="primary"
          className="w-full"
          onClick={() => {
            onOk();
            onClose();
          }}
        >
          {type && t(`settings.deputyConfirm.${type}.ok`)}
        </Button>
      </Stack>
    </Modal>
  );
};

export default ConfirmActionModal;
