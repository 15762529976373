import React, { Component, ErrorInfo } from 'react';
import Error from '../../pages/Error/Error';
import axios, { AxiosError } from 'axios';
import {
  withTranslation,
  WithTranslation as WithTranslationProps,
} from 'react-i18next';
import { HttpStatusCode, translatedErrors } from './typing';
import LoadingTransitionPage from '../LoadingTransitionPage';
import { Stack } from './../../controls';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  refreshToken: () => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: AxiosError | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundaryComponent extends Component<
  ErrorBoundaryProps & WithTranslationProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps & WithTranslationProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error | AxiosError) {
    return { hasError: true, error };
  }

  componentDidCatch(error: AxiosError, errorInfo: ErrorInfo) {
    this.setState({ errorInfo });
  }

  componentDidMount() {
    axios.interceptors.response.use(undefined, (error: AxiosError) => {
      this.setState({ hasError: true, error });
      return Promise.reject(error);
    });
  }

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      const status = (this.state.error?.response?.status ||
        this.state.error?.status ||
        this.state.error?.code) as HttpStatusCode;
      const statusCode: HttpStatusCode = translatedErrors.includes(status)
        ? status
        : HttpStatusCode.else;

      const responseData = this.state.error?.response?.data
        ? (this.state.error?.response?.data as {
            message?: string;
            traceId?: string;
            spanId?: string;
          })
        : {};

      if (statusCode == 401) {
        this.props.refreshToken();
        return <LoadingTransitionPage />;
      }

      //exclude status codes that are processed using local Error Boundary
      if (([400, 404, 409] as HttpStatusCode[]).includes(statusCode))
        return this.props.children;

      return (
        <Error
          height="full"
          statusCode={statusCode}
          title={t(`error.${statusCode}.title`)}
          subTitle={t(`error.${statusCode}.subtitle`)}
          buttonType={
            statusCode == HttpStatusCode.Forbidden ? 'dashboard' : 'refresh'
          }
        >
          <Stack className="pt-5" direction="col">
            {responseData.traceId && (
              <span>Trace ID: {responseData?.traceId}</span>
            )}
            {responseData.spanId && (
              <span>Span ID: {responseData?.spanId}</span>
            )}
          </Stack>
        </Error>
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withTranslation()(ErrorBoundaryComponent);

export default ErrorBoundary;
