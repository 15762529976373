import {
  ChevronDownIcon,
  ChevronUpIcon,
  IconButton,
  SmartSpinnerLocal,
  Stack,
  defaultScrollbar,
  useConfig,
  useIsSelectedRange,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import {
  ContactPersonListItem,
  ContactPersonType,
} from './ContactPersonListItem';
import ContactPersonsModal from './ContactPersonsModal';
import { useWindowSize } from 'react-use';

export const ContactPersons = ({
  contacts = [],
  isLoading,
}: {
  contacts: ContactPersonType[];
  isLoading?: boolean;
}) => {
  const [showAll, setShowAll] = useState(false);
  const isMobileView = useIsSelectedRange(['sm', 'md']);
  const ref = useRef<HTMLDivElement>(null);
  const toggleShowAll = () => {
    const container = ref.current;
    if (container) {
      setShowAll(!showAll);
      container.scrollTop = 0;
    }
  };

  const contactRowHeight = 90;
  const [showButton, setShowButton] = useState(false);
  const { width } = useWindowSize();
  const { configState } = useConfig();
  useEffect(() => {
    const container = ref.current;
    if (container) {
      setShowButton(container.scrollHeight > contactRowHeight);
    }
  }, [
    contacts.length,
    width,
    isMobileView,
    configState.FullSidebar,
    contactRowHeight,
  ]);

  if (!isLoading && !contacts.length) {
    return <></>;
  }

  return (
    <>
      <div
        className={classnames(
          'w-full',
          !showAll && 'h-[180px]',
          showButton && !showAll && 'mb-10'
        )}
      >
        <SmartSpinnerLocal condition={isLoading} name="ContactPersons">
          <div
            className={classnames('w-full h-full p-8', isMobileView && 'px-4')}
          >
            <p className="font-semibold text-2xl leading-normal">
              {t('customer.informationPage.contactPersons')}
            </p>
            <div
              className={classnames(
                'w-full h-full',
                isMobileView ? 'pt-4' : 'pt-6',
                isMobileView && '!max-h-[90px] overflow-hidden',
                defaultScrollbar,
                showAll ? 'overflow-y-auto' : 'overflow-y-hidden'
              )}
              style={{
                maxHeight: showAll ? 240 : contactRowHeight,
              }}
              ref={ref}
            >
              <Stack
                direction="row"
                className={classnames(
                  'w-full h-full flex-wrap gap-y-4 gap-x-10',
                  isMobileView && '[&>div]:!w-full [&>div]:mr-2'
                )}
              >
                {contacts.map((item) => (
                  <ContactPersonListItem
                    key={item.displayName}
                    {...{
                      item,
                    }}
                  />
                ))}
              </Stack>
            </div>
            {showButton && (
              <Stack
                direction="row"
                alignItems="center"
                className={classnames(
                  'text-[#0170df] text-base font-medium cursor-pointer',
                  !isMobileView && 'pt-4'
                )}
                onClick={toggleShowAll}
              >
                <IconButton className="!m-0 !p-0 !w-5 !mr-1" hoverable={false}>
                  {showAll ? (
                    <ChevronUpIcon color="#0170df" />
                  ) : (
                    <ChevronDownIcon color="#0170df" />
                  )}
                </IconButton>
                <p>
                  {t(
                    `customer.informationPage.${
                      showAll ? 'hideAll' : 'showAll'
                    }`
                  )}
                </p>
              </Stack>
            )}
          </div>
        </SmartSpinnerLocal>
      </div>
      <ContactPersonsModal
        {...{
          visible: showAll && isMobileView,
          onClose: () => {
            setShowAll(false);
          },
          contacts,
        }}
      />
    </>
  );
};

export default ContactPersons;
