import { Button } from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { t } from 'i18next';

export const ModifyInvoice = ({
  show,
  isLoading,
  onClose,
  onAccept,
}: {
  show?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  onAccept?: () => void;
}) => {
  return (
    <Modal
      {...{
        show,
        className: '[&>div>div>div:nth-child(3)]:border-none',
        title: t('policyDetails.modifyReadInvoice.header'),
        onClose,
        footer: (
          <>
            <Button className="w-1/2" variant="secondary" onClick={onClose}>
              {t('common.cancel')}
            </Button>
            <Button
              variant="primary"
              className="w-1/2"
              isLoading={isLoading}
              onClick={onAccept}
              dataTestid={'confirmResetInvoice'}
            >
              {t('policyDetails.modifyReadInvoice.acceptBtn')}
            </Button>
          </>
        ),
      }}
    >
      {t('policyDetails.modifyReadInvoice.content')}
    </Modal>
  );
};

export default ModifyInvoice;
