import {
  Stack,
  hoverClassname,
  inputStyle,
  taskTypes,
  taskTypesList,
  useStateProvider,
} from '@trueconnect/tp-components';
import { rowStyle } from './TaskForm';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { t } from 'i18next';
import classnames from 'classnames';
import { useClickAway } from 'react-use';

export const TaskType = ({
  stateId,
  setStateId,
}: {
  stateId: number;
  setStateId: Dispatch<SetStateAction<number>>;
}) => {
  const [show, setShow] = useState(false);
  const { availableTaskTypes = [] } = useStateProvider().state.workManagement;
  const ref = useRef(null);

  useClickAway(ref, () => {
    setShow(false);
  });

  const systemName =
    (availableTaskTypes.find((item) => item.id == stateId)
      ?.systemName as taskTypes) || '';

  return (
    <div
      ref={ref}
      className={classnames(rowStyle, 'relative text-gray-500 text-sm')}
      onClick={() => {
        setShow(!show);
      }}
    >
      <input
        value={
          taskTypesList.includes(systemName)
            ? t(`Tasks.types.${systemName}`)
            : systemName
        }
        className={classnames(
          'w-full focus:outline-none text-sm indent-[10px] cursor-pointer',
          inputStyle
        )}
      />
      {show && (
        <Stack className="absolute top-[40px] w-full border bg-white rounded-lg">
          {availableTaskTypes.map((item) => {
            return (
              <Stack
                key={item.id}
                direction="row"
                alignItems="center"
                className={classnames('h-8 w-full pl-[18px]', hoverClassname)}
                onClick={() => {
                  item.id && setStateId(item.id);
                }}
              >
                {item.systemName && taskTypesList.includes(item.systemName)
                  ? t(`Tasks.types.${item.systemName as taskTypes}`)
                  : item.systemName}
              </Stack>
            );
          })}
        </Stack>
      )}
    </div>
  );
};

export default TaskType;
