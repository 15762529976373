import {
  Avatar,
  SmartSpinnerLocal,
  Stack,
  ToggleSwitch,
  VirtualizedGridCellRendererType,
  customerContacts,
  defaultScrollbar,
  hoverClassname,
  useIsSelectedRange,
  useNotificationSystem,
} from '@trueconnect/tp-components';
import {
  FetchBrokerDto,
  UpdateBrokerDto,
  useApiInvalidateQueries,
  useApiMutation,
  useApiQuery,
} from 'src/api';
import classnames from 'classnames';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import ConfirmationModal from '@trueconnect/tp-components/src/controls/ConfirmationModal';
import { getContactImage } from 'src/utils';

type brokerUserType = FetchBrokerDto & { image?: string };

export const BrokerUser = () => {
  const { data = [] } = useApiQuery('getAllBrokers', [], {
    onError: () => {
      addNotification({
        color: 'failure',
        title: t('BrokerUser.cantLoad'),
      });
    },
  });
  const [contacts, setContacts] = useState<brokerUserType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBroker, setSelectedBroker] = useState<FetchBrokerDto>({});
  const { addNotification } = useNotificationSystem();
  const isMobileView = useIsSelectedRange(['sm', 'md']);

  useEffect(() => {
    if (data.length) {
      const fetchImages = async () => {
        const contactsWithImages = await Promise.all(
          data.map(
            async (item) =>
              ({
                ...item,
                image: await getContactImage(item.imageId),
              } as brokerUserType)
          )
        );
        setContacts(contactsWithImages);
        setIsLoading(false);
      };
      fetchImages();
    }
  }, [data]);

  const invalidateQueries = useApiInvalidateQueries();
  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('getAllBrokers');
  }, [invalidateQueries]);
  const { mutate } = useApiMutation('updateBroker', {
    onSuccess: () => {
      invalidateQuery();
      setSelectedBroker({});
    },
    onError: () => {
      addNotification({
        color: 'failure',
        title: t('BrokerUser.editAvailabilityConfirm.error'),
      });
    },
  });

  const columns: VirtualizedGridCellRendererType<brokerUserType>[] = [
    {
      dataPropName: 'name',
      title: <span className="pl-12">{t('BrokerUser.columns.name')}</span>,
      className: classnames(
        'text-start overflow-hidden',
        isMobileView ? 'max-w-[180px]' : 'w-[280px] max-w-[280px]'
      ),
      render: (item) => (
        <Stack
          direction="row"
          alignItems="center"
          className={classnames(
            '[&>div>div>div]:!text-black h-full overflow-hidden w-full',
            isMobileView && '[&>div]:w-full'
          )}
        >
          <Avatar
            {...{
              initials: item.displayName,
              imgSrc: item.image,
              subtitle: isMobileView ? item.email : '',
              title: isMobileView ? item.displayName : '',
              classNames:
                'm-0 p-0 mr-2 ml-1 !w-8 !h-8 [&>div>img]:w-8 [&>div>img]:h-8 [&>div]:w-8 [&>div]:h-8',
            }}
          />
          {!isMobileView && (
            <p className={classnames(customerContacts, 'truncate')}>
              {item.displayName}
            </p>
          )}
        </Stack>
      ),
    },
    ...(isMobileView
      ? []
      : [
          {
            dataPropName: 'email',
            title: <span>{t('BrokerUser.columns.email')}</span>,
            render: (item: FetchBrokerDto) => (
              <span className={classnames(customerContacts, '!font-medium')}>
                {item.email}
              </span>
            ),
            className: classnames('w-auto text-start'),
          },
        ]),
    {
      dataPropName: 'available',
      title: (
        <span
          className={classnames('text-nowrap', isMobileView ? 'pr-2' : 'pr-10')}
        >
          {t('BrokerUser.columns.availableToCustomers')}
        </span>
      ),
      className: 'justify-items-end text-end',
      render: (item) => (
        <ToggleSwitch
          {...{
            className: classnames('ml-auto', isMobileView ? 'pr-2' : 'pr-5'),
            checked: !!item.isVisibleOnUI,
            onChange: () => {},
            onClick: () => {
              setSelectedBroker(item);
            },
          }}
        />
      ),
    },
  ];

  return (
    <div className="w-full h-full overflow-hidden p-3">
      <SmartSpinnerLocal name="getAllBrokers" condition={isLoading}>
        <Stack
          className="w-full h-full overflow-hidden border-customColor_2 border-2 rounded-lg"
          dataTestid="BrokerUserTable"
        >
          {!isMobileView && (
            <div className="w-full">
              <table className="w-full">
                <thead className="bg-customColor_2 h-[46px] px-2">
                  <tr>
                    {columns.map((column, index) => (
                      <th
                        key={index}
                        className={classnames(
                          column.className,
                          'text-sm font-semibold leading-normal overflow-x-auto text-customColor_20'
                        )}
                      >
                        {column.title}
                      </th>
                    ))}
                  </tr>
                </thead>
              </table>
            </div>
          )}
          <div
            className={classnames(
              'flex-1 overflow-x-hidden w-full overflow-y-auto',
              defaultScrollbar
            )}
          >
            <table className="w-full">
              <tbody>
                {contacts?.map((item, index) => (
                  <tr
                    key={index}
                    className={classnames(
                      'brokerUserRowItem border-b',
                      hoverClassname,
                      isMobileView ? 'h-[80px]' : 'h-[56px]'
                    )}
                  >
                    {columns.map((column, columnIndex) => (
                      <td
                        key={columnIndex}
                        className={classnames(column.className)}
                      >
                        {column.render(item)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Stack>
      </SmartSpinnerLocal>
      <ConfirmationModal
        {...{
          show: !!selectedBroker.id,
          title: t('BrokerUser.editAvailabilityConfirm.title'),
          confirmationText: t(
            'BrokerUser.editAvailabilityConfirm.confirmationText',
            {
              displayName: selectedBroker.displayName,
            }
          ),
          onClose: () => {
            setSelectedBroker({});
          },
          onAccept: () => {
            mutate([
              {
                ...selectedBroker,
                isVisibleOnUI: !selectedBroker.isVisibleOnUI,
              } as UpdateBrokerDto,
            ]);
          },
        }}
      />
    </div>
  );
};

export default BrokerUser;
