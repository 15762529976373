import axios from 'axios';
import { useEffect } from 'react';
import { SmartSpinnerLocal } from '.';
import { useGetToken } from '../authentication';

interface RequestInterceptorProps {
  loginRequest: {
    scopes: string[];
  };
  children: JSX.Element;
}

export const RequestInterceptor = ({
  loginRequest,
  children,
}: RequestInterceptorProps) => {
  const token = useGetToken({
    ...loginRequest,
  });

  useEffect(() => {
    if (token) {
      axios.interceptors.request.use(async (config) => {
        if (config.headers) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      });
    }
  }, [token]);

  return (
    <SmartSpinnerLocal
      name="RequestInterceptor"
      condition={!token}
      renderAsGlobal={true}
    >
      {children}
    </SmartSpinnerLocal>
  );
};

export default RequestInterceptor;
