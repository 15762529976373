import {
  IconLabel,
  PlusIcon,
  useIsSelectedView,
  useStateProvider,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import classnames from 'classnames';

export const CreateTaskButton = () => {
  const isMobile = useIsSelectedView('sm');
  const { setShowTaskForm } = useStateProvider().actions;

  return (
    <IconLabel
      dataTestid="create_task_button"
      title={t('Tasks.modals.Create.button')}
      icon={!isMobile ? <PlusIcon /> : undefined}
      onClick={() => {
        setShowTaskForm(true);
      }}
      className={classnames(
        !isMobile && 'border-x border-y border-[#0170df] rounded-lg pr-4 h-10'
      )}
      textClassName={classnames(isMobile && '!text-lg font-medium')}
    />
  );
};
