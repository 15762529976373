import {
  FunnelIcon,
  IconButton,
  MagnifyingGlassIcon,
  SearchInput,
  Stack,
  useIsSelectedView,
  useStateProvider,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import CreateTopicButton from 'src/components/topics/CreateTopicButton';

export const TopicListHeader = ({
  setSearchParam,
}: {
  setSearchParam: Dispatch<SetStateAction<string>>;
}) => {
  const { state, actions } = useStateProvider();
  const { showExtendedTopicSearch, extendedTopicSearchParams } =
    state.workManagement;
  const { setShowExtendedTopicSearch } = actions;
  const isMobileView = useIsSelectedView('sm');

  return (
    <Stack
      direction="row"
      alignItems="center"
      className="px-0 py-2 h-14 w-full border-b bg-customColor_7"
    >
      <SearchInput
        classNames={{
          wrapper: '!border-none',
        }}
        setSearchParam={setSearchParam}
        isMobileView={true}
        iconHoverable={false}
        searchIcon={<MagnifyingGlassIcon color="#1C64F2" />}
        dataTestid="searchInput"
        placeholder={t('Topics.Sidebar.searchPlaceholder')}
      />
      <CreateTopicButton
        btnType="secondary"
        redirectOnCreateTopic={true}
        classNames="!min-w-5 !h-5 mx-[2px]"
        dataTestId="Header"
      />
      <IconButton
        className="relative mr-2"
        onClick={() => {
          setShowExtendedTopicSearch(!showExtendedTopicSearch);
        }}
      >
        {isMobileView &&
          (!!extendedTopicSearchParams.customerIds?.length ||
            !!extendedTopicSearchParams.branchIds ||
            !!extendedTopicSearchParams.dateCreatedFrom ||
            !!extendedTopicSearchParams.dateCreatedTo) && (
            <div className="absolute top-0 left-4 w-3 h-3 bg-blue-500 rounded-full" />
          )}
        <FunnelIcon data-testid="startExtendedTopicSearch" />
      </IconButton>
    </Stack>
  );
};

export default TopicListHeader;
