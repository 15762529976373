import { useState } from 'react';
import {
  useUserProfile,
  Stack,
  useStateProvider,
  MessageType,
  CommonStyles,
  relativeFull,
  ConversationParticipantType,
  useOneParticipant,
  useIsSelectedView,
  useIsSelectedRange,
  deleteUnsentMessage,
  ConversationTopicHeader,
  useNotificationSystem,
} from '@trueconnect/tp-components';
import ConversationTabs from './ConversationTabs';
import { AttachmentDto, useApiMutation } from 'src/api';
import { UserActions } from './Actions/UserActions';
import UploadManagerConversation from 'src/providers/UploadManagerConversation';
import classnames from 'classnames';
import {
  AddConversationMembersModal,
  ChangeBranchesModal,
  ChangeTitleModal,
  ConversationStatusModal,
} from 'src/components/conversation';
import ConversationHeader from './Actions/ConversationHeader';
import { t } from 'i18next';
import AttachToTopicModal from 'src/components/topics/AttachToTopicModal';
import { useNavigate } from 'react-router-dom';

export const ConversationDetails = () => {
  const { userId } = useUserProfile();
  const navigate = useNavigate();
  const { addNotification } = useNotificationSystem();
  const { state, actions } = useStateProvider();
  const { conversationData, showAddMembersDialog } = state.conversation;
  const { selectedTopic } = state.workManagement;
  const { conversationId, title, readOnly, participants, customerId } =
    conversationData;

  const {
    setShowAddMembersDialog,
    setShowChangeBranchesDialog,
    setSelectedBranches,
    setNotEditableBranches,
    setShowChangeTitleDialog,
    setSelectedTopic,
    setShowAttachToTopicDialog,
    setPolicyBranchFilter,
  } = actions;

  const [messages, setMessages] = useState<MessageType[]>([]);

  const { mutate: saveConversation, isPending: isSavingConversation } =
    useApiMutation('update_Conversation');

  const { mutate } = useApiMutation('send_Message');

  const sendMessage = (message: string, attachments?: AttachmentDto[]) => {
    const preLoadMessage = {
      messageText: message,
      senderUserId: userId,
      loading: true,
      attachments,
    } as MessageType;
    setMessages([...messages, preLoadMessage]);
    mutate([conversationId, { message, attachments }], {
      onSuccess: () => {
        deleteUnsentMessage(conversationId);
      },
    });
  };

  const participant = useOneParticipant(
    participants as ConversationParticipantType[],
    true
  );

  const isMobileView = useIsSelectedView('sm');
  const isSelectedView = useIsSelectedRange(['sm', 'md', 'lg']);

  const close = () => {
    setShowChangeTitleDialog(false);
  };

  const updateTitle = (value: string) => {
    saveConversation(
      [
        {
          id: conversationId,
          notes: conversationData.notes,
          branchIds: conversationData.branches,
          title: value,
          topicId: value,
        },
      ],
      {
        onSuccess: () => {
          close();
        },
      }
    );
  };

  const attactToTopic = (value?: string) => {
    saveConversation(
      [
        {
          id: conversationId,
          title: conversationData.title,
          notes: conversationData.notes,
          branchIds: conversationData.branches,
          topicId: value,
        },
      ],
      {
        onError: () => {
          if (value) {
            addNotification({
              color: 'failure',
              title: t('conversationPage.attactToTopicError'),
            });
          } else {
            addNotification({
              color: 'failure',
              title: t('conversationPage.unattactToTopicError'),
            });
          }
        },
        onSuccess: () => {
          setShowAttachToTopicDialog(false);
          if (selectedTopic.id == conversationData.topicId) {
            setSelectedTopic({});
          }
        },
      }
    );
  };

  return (
    <Stack
      alignItems="center"
      direction="col"
      className={classnames(
        CommonStyles.full,
        isMobileView && '!top-0 left-0 right-0 !bottom-0'
      )}
    >
      <ConversationTopicHeader
        {...{
          data: {
            id: conversationData.topicId,
            displayName: conversationData.topicName,
          },
          canEditBranches: !readOnly,
          classNames: classnames('pb-3', isMobileView ? 'px-1' : 'pl-8 pr-5'),
          branchOnclick: (item) => {
            if (item.id) {
              setPolicyBranchFilter(item.id);
              navigate(`/customers/${conversationData.customerId}/policies`);
            }
          },
        }}
      />
      <Stack
        direction="col"
        className={classnames('w-full', !isMobileView && 'pl-8 pr-5')}
      >
        <Stack
          direction="row"
          className="w-full"
          alignItems="center"
          justifyContent="between"
        >
          <ConversationHeader
            saveConversation={(title) => {
              saveConversation([
                {
                  id: conversationId,
                  title,
                  notes: conversationData.notes,
                  branchIds: conversationData.branches
                    .filter((item) => !!item.id)
                    .map((item) => item.id as string),
                  topicId: conversationData.topicId,
                },
              ]);
            }}
            {...{
              isSavingConversation,
              readOnly,
              title: title || '',
              participant,
              messages,
            }}
          />
        </Stack>
        {!isSelectedView && <UserActions participant={participant} />}
      </Stack>
      <div className={relativeFull}>
        {conversationId && (
          <UploadManagerConversation id={conversationId}>
            <ConversationTabs
              {...{
                sendMessage,
                userId,
                messages,
                setMessages,
                participant,
              }}
            />
          </UploadManagerConversation>
        )}
      </div>
      <ConversationStatusModal />
      <ChangeTitleModal
        {...{
          updateTitle,
          isLoading: isSavingConversation,
          title: conversationData.title,
          close,
        }}
      />
      <AttachToTopicModal
        {...{
          attactToTopic,
          isLoading: isSavingConversation,
          initialValue: conversationData.topicId,
          customerId: conversationData.customerId,
        }}
      />
      <ChangeBranchesModal
        onSave={(branches) => {
          saveConversation(
            [
              {
                id: conversationId,
                title,
                notes: conversationData.notes,
                branchIds: branches
                  .filter((item) => !!item.id)
                  .map((item) => item.id as string),
                topicId: conversationData.topicId,
              },
            ],
            {
              onSuccess: () => {
                setShowChangeBranchesDialog(false);
                setSelectedBranches([]);
                setNotEditableBranches([]);
              },
            }
          );
        }}
        isLoading={isSavingConversation}
      />
      {showAddMembersDialog && customerId && (
        <AddConversationMembersModal
          customerId={customerId}
          participants={participants}
          isVisible={showAddMembersDialog}
          onClose={() => {
            setShowAddMembersDialog(false);
          }}
        />
      )}
    </Stack>
  );
};

export default { ConversationDetails };
