import React, { useCallback, useEffect, useState } from 'react';
import { useApiInvalidateQueries, useApiMutation, useApiQuery } from 'src/api';
import {
  BranchBadge,
  IconButton,
  PlusIcon,
  Stack,
  TrashIcon,
  VirtualizedGridCellRendererType,
  branchType,
  defaultBranchIcon,
  defaultScrollbar,
  hoverClassname,
  useIsSelectedView,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { t } from 'i18next';
import BranchForm from './BranchForm';
import DeleteModal from 'src/components/DeleteModal';
import { useWindowSize } from 'react-use';
import { branchesArray } from '@trueconnect/tp-components/src/components/BranchesIconList/branches';

export const ColorBadge = ({ clr }: { clr: string | undefined }) => {
  return (
    <div
      className="w-8 h-8 rounded-full ml-8"
      style={{
        background: clr,
      }}
    />
  );
};

const BranchesSettings = () => {
  const { actions } = useStateProvider();
  const { setAvailableBranches } = actions;
  const { data } = useApiQuery('getAvailableBranches', []);

  useEffect(() => {
    data?.length && setAvailableBranches(data);
  }, [data, setAvailableBranches]);

  const [showBranchModal, setShowBranchModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState<branchType>();
  const { addNotification } = useNotificationSystem();
  const isMobileView = useIsSelectedView('sm');
  const { width } = useWindowSize();

  const invalidateQueries = useApiInvalidateQueries();
  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('getAvailableBranches');
  }, [invalidateQueries]);
  const { mutate, isLoading } = useApiMutation('deleteBranch');

  const handleDeleteAction = (e: React.MouseEvent, data: branchType) => {
    e.stopPropagation();
    setSelectedBranch(data);
    setShowDeleteModal(true);
  };

  const columns: (VirtualizedGridCellRendererType<branchType> & {
    headerClassname?: string;
  })[] = [
    ...(isMobileView
      ? []
      : [
          {
            dataPropName: 'color',
            render: (data) => <ColorBadge {...{ clr: data.backgroundColor }} />,
            title: <p className="pl-6">{t('Branches.Table.color')}</p>,
            className: 'w-20',
          } as VirtualizedGridCellRendererType<branchType>,
        ]),
    ...(isMobileView
      ? []
      : [
          {
            dataPropName: 'icon',
            render: (data) => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                className="w-full pr-4"
              >
                <IconButton hoverable={false} className="[&>div]:text-black">
                  {data.shortName &&
                  Object.keys(branchesArray).includes(data.shortName)
                    ? branchesArray[
                        data.shortName as keyof typeof branchesArray
                      ]
                    : defaultBranchIcon}
                </IconButton>
              </Stack>
            ),
            title: t('Branches.Table.icon'),
            className: 'w-20',
          } as VirtualizedGridCellRendererType<branchType>,
        ]),
    {
      dataPropName: 'convenientName',
      render: (item: branchType) => (
        <div className="h-full flex flex-col items-start justify-center overflow-hidden">
          <BranchBadge
            classNames="text-nowrap max-w-[270px]"
            key={item.id}
            item={item}
            selectedName="convenientName"
          />
        </div>
      ),
      title: t('Branches.createForm.convenientPlaceholder'),
      className: 'px-5 w-[300px] max-w-[300px]',
      headerClassname: 'text-start',
    },
    ...(width < 800
      ? []
      : [
          {
            dataPropName: 'name',
            render: (item: branchType) => (
              <div className="h-full flex flex-col items-start justify-center overflow-hidden">
                <BranchBadge
                  classNames="text-nowrap max-w-[270px]"
                  key={item.id}
                  item={item}
                  selectedName="name"
                />
              </div>
            ),
            title: t('Branches.createForm.namePlaceholder'),
            headerClassname: 'text-start',
            className: 'px-5 w-[300px] max-w-[300px]',
          },
        ]),
    ...(width < 1200
      ? []
      : [
          {
            dataPropName: 'short',
            render: (item: branchType) => (
              <div className="w-full h-full overflow-hidden">
                <p className="truncate">{item.shortName}</p>
              </div>
            ),
            title: t('Branches.createForm.shortPlaceholder'),
            headerClassname: 'text-start',
            className: 'px-5 w-[300px] max-w-[300px]',
          },
        ]),
    {
      dataPropName: 'space',
      render: () => <div className="w-auto" />,
      title: <></>,
    },
    {
      dataPropName: 'actions',
      render: (data) => (
        <>
          {data.canBeDeleted && (
            <div
              className="flex flex-row justify-center"
              data-testid={`${data.convenientName}_deleteAction`}
              onClick={(e) => handleDeleteAction(e, data)}
            >
              <IconButton>
                <TrashIcon />
              </IconButton>
            </div>
          )}
        </>
      ),
      title: t('Branches.Table.actions'),
      className: 'w-20',
    },
  ];

  return (
    <div className="w-full h-full p-3">
      <Stack
        direction="col"
        className="w-full h-full border-customColor_2 border-2 rounded-lg"
      >
        <div
          className={classnames('w-full h-full overflow-hidden flex flex-col')}
        >
          <div className="w-full">
            <table className="w-full">
              <thead className="bg-customColor_2 h-[46px]">
                <tr>
                  {columns.map((column, index) => (
                    <th
                      key={index}
                      className={classnames(
                        column.headerClassname,
                        column.className,
                        'text-sm font-semibold leading-normal overflow-x-auto text-customColor_20'
                      )}
                    >
                      {column.title}
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
          </div>
          <div
            className={classnames(
              'flex-1 overflow-x-hidden overflow-y-auto',
              defaultScrollbar
            )}
          >
            <table className="w-full">
              <tbody>
                {data?.map((item, index) => (
                  <tr
                    key={index}
                    data-testid={`${item.convenientName}_listItem`}
                    className={classnames('h-[56px] border-t', hoverClassname)}
                  >
                    {columns.map((column, columnIndex) => (
                      <td
                        key={columnIndex}
                        className={classnames(column.className)}
                        onClick={() => {
                          if (item.canBeDeleted) {
                            setSelectedBranch(item);
                            setShowBranchModal(true);
                          }
                        }}
                      >
                        {column.render(item)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Stack className="h-14 w-full border-t" direction="row">
          <Stack
            className="w-20 h-full [&>div]:ml-4"
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <ColorBadge {...{ clr: '#F3F4F6' }} />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            className={'pl-5 w-full h-full'}
            onClick={() => setShowBranchModal(true)}
            dataTestid="createBranch"
          >
            <Stack
              direction="row"
              alignItems="center"
              className={classnames(hoverClassname, 'p-2 rounded-xl')}
            >
              <IconButton customSize="!w-4 !h-4" className="mr-1">
                <PlusIcon color="black" />
              </IconButton>
              <span className="text-xs font-medium leading-3">
                {t('Branches.createForm.button')}
              </span>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {showBranchModal && (
        <BranchForm
          onClose={() => {
            invalidateQuery();
            setShowBranchModal(false);
            setSelectedBranch({});
          }}
          selectedBranch={selectedBranch}
        />
      )}
      <DeleteModal
        visible={showDeleteModal}
        onClose={() => {
          invalidateQuery();
          setShowDeleteModal(false);
        }}
        displayName={selectedBranch?.convenientName}
        isLoading={isLoading}
        deleteConfirmationText={t('Branches.deleteModal.confirmationText')}
        onAccept={() => {
          selectedBranch?.id &&
            mutate([selectedBranch.id], {
              onSuccess: () => {
                invalidateQuery();
                setShowDeleteModal(false);
                setSelectedBranch({});
              },
              onError: () => {
                addNotification({
                  color: 'failure',
                  title: t(`Branches.deleteModal.error`),
                });
              },
            });
        }}
      />
    </div>
  );
};

export default BranchesSettings;
