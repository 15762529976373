import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import classnames from 'classnames';
import { bigScrollbar } from './../../styles';
import { SmartSpinnerLocal } from './../../providers';
import { t } from 'i18next';
import { useCallback, useRef } from 'react';
import VirtualizedColumnType from '../VirtualizedList/VirtualizedColumnType';
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import { RowClickHandlerType, classNamesType } from '../VirtualizedList';

type VirtualizedListType<TData, TError> = {
  columns: VirtualizedColumnType<TData>[];
  infinityQuery: {
    data?: TData[];
    isLoading?: boolean;
    isFetching?: boolean;
    hasNextPage?: boolean;
    fetchNextPage?: (
      options?: FetchNextPageOptions
    ) => Promise<InfiniteQueryObserverResult<TData | TData[], TError>>;
  };
  onRowClick?: RowClickHandlerType<TData>;
  rowHeight?: number;
  headerHeight?: number;
  nothingFound?: string | JSX.Element;
  classNames?: classNamesType;
  isSearching?: boolean;
  overscan?: number;
};

export function VirtualizedList<TData, TError>({
  columns,
  onRowClick,
  rowHeight = 50,
  headerHeight = 0,
  infinityQuery,
  nothingFound,
  classNames,
  overscan = 200,
  isSearching = false,
}: VirtualizedListType<TData, TError>) {
  const data = infinityQuery.data?.flat(1) || [];
  const { fetchNextPage, isLoading, isFetching, hasNextPage } = infinityQuery;
  const virtuoso = useRef<VirtuosoHandle>(null);
  const ref = useRef<HTMLDivElement>(null);

  const endReached = async () => {
    if (!isFetching && hasNextPage) {
      await fetchNextPage?.();
    }
  };

  const atBottomStateChange = useCallback(
    async (atBottom: boolean) => {
      if (!isFetching && hasNextPage && atBottom) {
        await fetchNextPage?.();
      }
    },
    [fetchNextPage, isFetching]
  );

  const totalWidthRatio = columns
    .map((item) => item.widthRatio || 1)
    .reduce((sum, widthRatio) => sum + widthRatio, 0);

  const virtuosoTable =
    ref.current?.getElementsByClassName('virtuoso_table')[0];
  const scrollBarWidth = virtuosoTable
    ? (virtuosoTable as any)?.offsetWidth - virtuosoTable.clientWidth
    : 0;

  return (
    <SmartSpinnerLocal
      name={'VirtualizedList'}
      background={false}
      condition={isLoading && !isSearching}
    >
      <div
        ref={ref}
        className={classnames('w-full h-full', classNames?.content)}
        style={{
          paddingBottom: headerHeight,
        }}
      >
        {!!headerHeight && ref.current && (
          <div
            className={classnames(
              'bg-customColor_2 py-2 border-b-2 flex normal-case text-customColor_20 font-medium !indent-0 first:indent-3',
              classNames?.header
            )}
            style={{
              height: headerHeight,
              paddingRight:
                data.length * rowHeight + headerHeight >
                ref.current?.offsetHeight
                  ? scrollBarWidth
                  : 0,
            }}
          >
            {columns.map((column, index) => (
              <div
                key={index}
                className={classnames(
                  'flex flex-row items-center h-full',
                  column.className,
                  classNames?.column
                )}
                style={{
                  width:
                    columns.length > 1
                      ? `${((column.widthRatio || 1) / totalWidthRatio) * 100}%`
                      : '100%',
                }}
              >
                {column.title}
              </div>
            ))}
          </div>
        )}
        <SmartSpinnerLocal
          name={'VirtualizedListLocal'}
          background={false}
          condition={isLoading && isSearching}
        >
          {!!data.length && (
            <Virtuoso
              {...{
                data,
                ref: virtuoso,
                start: 0,
                endReached,
                overscan,
                atBottomStateChange,
                totalCount: data.length,
                fixedItemHeight: rowHeight,
                className: classnames(
                  'virtuoso_table w-full h-full absolute overflow-x-hidden',
                  bigScrollbar
                ),
                itemContent: (index, item) => {
                  return (
                    <div
                      key={index}
                      style={{
                        ...(rowHeight
                          ? {
                              height: rowHeight,
                            }
                          : {}),
                      }}
                      className={classnames(
                        'bg-customColor_17 flex flex-row items-center',
                        (data.length - 1 !== index || !scrollBarWidth) &&
                          'border-b-2',
                        classNames?.row,
                        data.length - 1 === index && classNames?.lastRow
                      )}
                      onClick={() => {
                        onRowClick?.(item);
                      }}
                    >
                      {columns.map((column, colIndex) => (
                        <div
                          key={colIndex}
                          className={classnames(
                            'h-full w-full flex items-center overflow-hidden truncate',
                            classNames?.column,
                            column.className
                          )}
                          style={{
                            width:
                              columns.length > 1
                                ? `${
                                    ((column.widthRatio || 1) /
                                      totalWidthRatio) *
                                    100
                                  }%`
                                : '100%',
                          }}
                        >
                          {column.render(item)}
                        </div>
                      ))}
                    </div>
                  );
                },
              }}
            />
          )}
          {!isLoading && !data.length && (
            <div className="w-full px-2 py-2">
              {nothingFound || t('apiCalls.warnings.tryAnotherFilter')}
            </div>
          )}
        </SmartSpinnerLocal>
      </div>
    </SmartSpinnerLocal>
  );
}

export default VirtualizedList;
