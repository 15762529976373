import {
  Stack,
  customerContacts,
  CustomerStyles,
} from '@trueconnect/tp-components';
import { ContactForRenderType } from 'src/pages/App/Customers/DataView/Tabs';
import classnames from 'classnames';

export const ContactEmail = (item: ContactForRenderType) => {
  const { email, disabled } = item;
  return (
    <Stack
      alignItems="start"
      justifyContent="center"
      className="w-full h-full overflow-hidden"
    >
      <a
        href={`mailto: ${email}`}
        className={classnames(
          customerContacts,
          CustomerStyles.greyedOut(disabled),
          'truncate w-full !font-medium font-[inter]'
        )}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {email}
      </a>
    </Stack>
  );
};
