import { Button, Stack, useIsSelectedView } from '@trueconnect/tp-components';
import { t } from 'i18next';
import { BrokersMembersTab } from './BrokerMembers/BrokersMembersTab';
import { CustomerMembersTab } from './CustomerMembers/CustomerMembersTab';
import { addMemberType } from './typing';
import { Dispatch, SetStateAction } from 'react';
import { Button as FlowbiteButton } from 'flowbite-react';
import classnames from 'classnames';

export const AddMemberTabs = (
  props: Omit<addMemberType, 'isQueryEnabled'> & {
    activeTab: number;
    setActiveTab: Dispatch<SetStateAction<number>>;
  }
) => {
  const isMobileView = useIsSelectedView('sm');
  const { setActiveTab, activeTab } = props;

  const tabs = [
    {
      title: t('conversation.manageMembers.modals.add.tabs.customers'),
      component: (
        <CustomerMembersTab {...props} isQueryEnabled={activeTab === 0} />
      ),
    },
    {
      title: t('conversation.manageMembers.modals.add.tabs.brokers'),
      component: (
        <BrokersMembersTab {...props} isQueryEnabled={activeTab === 1} />
      ),
    },
  ];

  return (
    <Stack direction="col" className="w-full h-full">
      <FlowbiteButton.Group
        className={classnames('my-3', isMobileView && 'ml-2')}
      >
        {tabs.map((tab, index) => (
          <Button
            variant="secondary"
            key={index}
            onClick={() => setActiveTab(index)}
            className={classnames(index !== activeTab && '!text-gray-400')}
          >
            {tab.title}
          </Button>
        ))}
      </FlowbiteButton.Group>

      <div className="relative w-full h-full">
        <div className={classnames('w-full h-full absolute top-0 bottom-0')}>
          {tabs[activeTab].component}
        </div>
      </div>
    </Stack>
  );
};

export default AddMemberTabs;
