import { DateObject } from 'react-multi-date-picker';

export const mapDays = (
  startDate: DateObject | undefined,
  endDate: DateObject | undefined,
  object: {
    date: DateObject;
    selectedDate: DateObject | DateObject[];
  }
) => {
  const { date } = object;

  if (startDate && endDate && startDate.format() == endDate.format()) {
    return '!rounded-lg';
  }

  if (
    startDate &&
    date.format('DD.MM.YYYY') == startDate.format('DD.MM.YYYY')
  ) {
    return '!rounded-l-lg !rounded-r-none !bg-blue-500 !text-white';
  }
  if (endDate && date.format('DD.MM.YYYY') == endDate.format('DD.MM.YYYY')) {
    return '!rounded-r-lg !rounded-l-none !bg-blue-500 !text-white';
  }
  if (startDate && endDate && date > startDate && date < endDate) {
    return 'bg-gray-200 !rounded-none';
  }
};

export default mapDays;
