/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import classnames from 'classnames';
import { t } from 'i18next';
import { PermissionKeys, initialPermissions } from './PermissionModal';
import {
  ContactAllowedResourcesDto,
  ContactPermissionDto,
  Stack,
  branchType,
  useStateProvider,
  ToggleSwitch,
} from '@trueconnect/tp-components';
import PolicyBranchSelection from '../permissions/PolicyBranchSelection';

interface PermissionLogicProps {
  setPermissionState: (permissions: typeof initialPermissions) => void;
  className?: string;
  currentPermissions: typeof initialPermissions;
  policyResoursesState: ContactAllowedResourcesDto;
  setPolicyResourcesState: Dispatch<SetStateAction<ContactAllowedResourcesDto>>;
}

const Permission = ({
  permission,
  isActive = false,
  handlePermissionChange,
}: {
  permission: keyof ContactPermissionDto;
  isActive?: boolean;
  handlePermissionChange: (permission: string, isChecked: boolean) => void;
}) => {
  return (
    <div
      key={permission}
      className="flex items-center cursor-pointer"
      onClick={() => {
        handlePermissionChange(permission, !isActive);
      }}
    >
      <Stack
        className="w-full border-b py-3"
        direction="row"
        alignItems="center"
        justifyContent="between"
      >
        <p className="ml-2 font-bold">{t(`permissions.${permission}.title`)}</p>
        <ToggleSwitch
          data-testid={`${permission}_switch`}
          checked={isActive}
          label={''}
          onChange={() => ({})}
        />
      </Stack>
    </div>
  );
};

const PermissionLogic: React.FC<PermissionLogicProps> = ({
  setPermissionState,
  className,
  currentPermissions,
  policyResoursesState,
  setPolicyResourcesState,
}) => {
  const permissions: PermissionKeys[] = Object.keys(
    currentPermissions
  ) as PermissionKeys[];

  const handlePermissionChange = (
    permission: string,
    isChecked: boolean
  ): void => {
    const updatedPermissions = {
      ...currentPermissions,
      [permission]: isChecked,
    };
    setPermissionState(updatedPermissions);
  };

  const handleAllowAllChange = (value: boolean) => {
    setPolicyResourcesState({
      ...policyResoursesState,
      allowedAccessToAll: value,
    });
  };

  const handleResourceChange = (item: branchType) => {
    if (!item.id) {
      return;
    }
    const currentResourceIds = policyResoursesState.resourceIds || [];

    if (currentResourceIds.includes(item.id)) {
      setPolicyResourcesState({
        ...policyResoursesState,
        resourceIds: currentResourceIds.filter((id) => id !== item.id),
      });
    } else {
      setPolicyResourcesState({
        ...policyResoursesState,
        resourceIds: [...currentResourceIds, item.id],
      });
    }
  };

  const { state } = useStateProvider();
  const { permissionModal } = state.customer;

  useEffect(() => {
    if (!currentPermissions['allowPolicyDirectory'] || !permissionModal) {
      setPolicyResourcesState({ ...policyResoursesState, resourceIds: [] });
      handleAllowAllChange(false);
    }
  }, [currentPermissions['allowPolicyDirectory'], permissionModal]);

  useEffect(() => {
    policyResoursesState.allowedAccessToAll &&
      setPolicyResourcesState({ ...policyResoursesState, resourceIds: [] });
  }, [policyResoursesState.allowedAccessToAll]);

  return (
    <div className={classnames('space-y-2 w-full h-full', className)}>
      {permissions.map((permission) => {
        const permissionType = typeof currentPermissions[permission];
        if (permissionType === 'boolean') {
          return (
            <Permission
              key={permission}
              {...{
                permission,
                isActive: !!currentPermissions[permission],
                handlePermissionChange,
              }}
            />
          );
        }
      })}
      {currentPermissions['allowPolicyDirectory'] && (
        <Stack direction="col" className="w-full max-h-[250px] pt-3">
          <PolicyBranchSelection
            selected={policyResoursesState.resourceIds || []}
            selectAll={!!policyResoursesState.allowedAccessToAll}
            setSelectAll={handleAllowAllChange}
            addToSelected={handleResourceChange}
          />
        </Stack>
      )}
    </div>
  );
};

export default PermissionLogic;
