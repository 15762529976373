import { useState } from 'react';
import { Button, IconButton } from './../../../../components';
import { useTranslation } from 'react-i18next';
import { DocumentIcon } from './../../../../images';
import Modal from './../../../../controls/Modal';
import { Stack } from './../../../../controls';
import SelectExistingTab from '../Tabs/SelectExistingTab';
import { documentsTabProps } from '../Tabs/UploadTabs';
import classnames from 'classnames';
import { mobileModalStyles } from './../../../../styles';

export const ExistingFilesButton: React.FC<documentsTabProps> = (props) => {
  const { t } = useTranslation();
  const [showExistingFilesModal, setShowExistingFilesModal] = useState(false);

  const toggleState = () => {
    setShowExistingFilesModal(!showExistingFilesModal);
  };

  return (
    <>
      <Button className="w-full !h-12" onClick={toggleState}>
        <IconButton size="none">
          <DocumentIcon />
        </IconButton>
        {t('uploadPanel.upload.selectFrom.conversationShort')}
      </Button>

      <Modal
        title={t('uploadPanel.upload.selectFrom.existingFilesModal.title')}
        show={showExistingFilesModal}
        onClose={toggleState}
        footer={
          <Stack direction="row" justifyContent="end" className="w-full">
            <Button className="w-60" color="gray" onClick={toggleState}>
              {t('uploadPanel.upload.selectFrom.existingFilesModal.select')}
            </Button>
          </Stack>
        }
        className={classnames(
          '[&>div>div]:rounded-t-3xl [&>div>div>div:nth-child(2)]:pl-3',
          mobileModalStyles(true)
        )}
      >
        <SelectExistingTab {...props} />
      </Modal>
    </>
  );
};

export default ExistingFilesButton;
