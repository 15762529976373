import { InformationCircleIcon } from '../images';
import { InformationRow } from '../controls';
import IconButton from './IconButton';
import { InformationNoteStyles } from './../styles';
import classnames from 'classnames';

export const InformationNote: React.FC<{
  title: string;
  wrapperClassnames?: string;
}> = ({ title, wrapperClassnames }) => {
  return (
    <InformationRow
      icon={
        <IconButton size="none" hoverable={false} imgClassName="mx-0">
          <InformationCircleIcon />
        </IconButton>
      }
      classNames={{
        wrapper: classnames(InformationNoteStyles.wrapper, wrapperClassnames),
        title: InformationNoteStyles.title,
      }}
      title={title}
    />
  );
};
export default InformationNote;
