import {
  BranchBadge,
  Stack,
  PlusIcon,
  getBranches,
  useStateProvider,
  uniqueStrings,
  nameType,
  branchType,
} from '@trueconnect/tp-components';
import { Badge } from 'flowbite-react';
import { t } from 'i18next';
import classnames from 'classnames';
import { useWindowSize } from 'react-use';

export const BranchesList = ({
  branchIds = [],
  childrenBranchIds = [],
  onClick,
  canEditBranches = false,
  nameTp = 'convenientName',
}: {
  branchIds?: string[];
  childrenBranchIds?: string[];
  onClick?: (item: branchType) => void;
  canEditBranches?: boolean;
  nameTp?: nameType;
}) => {
  const { state, actions } = useStateProvider();
  const { availableBranches = [] } = state.workManagement;
  const {
    setSelectedBranches,
    setShowChangeBranchesDialog,
    setNotEditableBranches,
  } = actions;
  const { width } = useWindowSize();
  const isMobileView = width < 500;

  const totalBranches = uniqueStrings(branchIds.concat(childrenBranchIds));

  const branches = getBranches(availableBranches, totalBranches) || [];
  const displayBranches = branches.slice(0, 3);
  const remainingBranchesCount = branches.length - displayBranches.length;

  return (
    <Stack
      direction="row"
      className={classnames(
        'gap-[9px] w-full'
        // hiddenScrollbar
      )}
      alignItems="center"
    >
      {displayBranches.map((item) =>
        item && item.id ? (
          <BranchBadge
            classNames="text-nowrap"
            key={item.id}
            {...{
              item,
              onClick,
              selectedName: nameTp,
            }}
          />
        ) : (
          <></>
        )
      )}
      {remainingBranchesCount > 0 && (
        <p className="text-xs font-normal text-nowrap leading-normal text-[#6B7280]">
          +{' '}
          {t('Topics.Details.moreBranches', {
            amount: remainingBranchesCount,
          })}
        </p>
      )}
      {canEditBranches && !isMobileView && (
        <Badge
          className="bg-blue-100 text-blue-800 h-[22px] font-medium cursor-pointer"
          onClick={() => {
            setSelectedBranches(getBranches(availableBranches, branchIds));
            setNotEditableBranches(
              getBranches(availableBranches, childrenBranchIds)
            );
            setShowChangeBranchesDialog(true);
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="center">
            <PlusIcon className="h-3 w-3 mr-1" />
            {t('Topics.Details.addBranch')}
          </Stack>
        </Badge>
      )}
    </Stack>
  );
};

export default BranchesList;
