import {
  IDocumentType,
  RowClickHandlerType,
  VirtualizedGridCellRendererType,
  dateFormat,
  useStateProvider,
} from '@trueconnect/tp-components';
import { useCallback, useMemo } from 'react';
import { useApiInfiniteQueryWithPageToken } from 'src/api';

export type PropsType = {
  columns: VirtualizedGridCellRendererType<IDocumentType>[];
  searchParam?: string;
  conversationId: string;
  isDragActive: boolean;
  enabled?: boolean;
};

export function useGetDocumentsTableProps(props: PropsType) {
  const {
    columns,
    searchParam,
    isDragActive,
    conversationId,
    enabled = true,
  } = props;

  const { removeItemAction } = useStateProvider().actions;

  const { inAction } = useStateProvider().state.documents;

  const queryDocuments = useApiInfiniteQueryWithPageToken(
    'search_Conversation_Documents',
    [conversationId, searchParam, undefined],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.documents
            ? item.documents.map((subitem) => {
                if (subitem.id && inAction.includes(subitem.id)) {
                  removeItemAction(subitem.id);
                }

                return {
                  ...subitem,
                  fileName: subitem.name,
                  modifiedAt: dateFormat(subitem.createdDateTime),
                  type: subitem.documentType === 1 ? 'folder' : 'file',
                } as IDocumentType;
              })
            : []
        ),
      refetchOnWindowFocus: false,
      enabled: enabled,
    }
  );
  const openFile: RowClickHandlerType<IDocumentType> = useCallback(
    (item: IDocumentType) => {
      const selection = window.getSelection();
      if (selection?.toString() === '')
        window.open(`${item.webUrl}?web=1&action=edit`);
    },
    []
  );

  const FilesTableProps = useMemo(
    () => ({
      isDragActive,
      columns,
      queryDocuments,
      openFile,
    }),
    [columns, isDragActive, openFile, queryDocuments]
  );

  return FilesTableProps;
}

export default useGetDocumentsTableProps;
