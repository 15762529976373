import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from './../../../components/IconButton';
import { Stack } from './../../../controls';
import { useStateProvider } from './../../../providers';
import { ShareDocumentType } from './../../../providers/HubListener/typing';
import { useTranslation } from 'react-i18next';
import Button from './../../../components/Button';
import { useQueryClient } from '@tanstack/react-query';

export const ShareDocument = ({
  notification,
  id,
  date,
}: {
  notification: ShareDocumentType;
  id: string;
  date: Date | string;
}) => {
  const { RemoveNotification } = useStateProvider().actions;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const client = useQueryClient();

  const parentPath = notification.parentFolderId
    ? `#${notification.parentFolderId}`
    : notification.parentFolderId;

  return (
    <>
      <Stack
        direction="row"
        alignItems="start"
        justifyContent="between"
        className="w-full"
      >
        <Stack direction="col">
          {date && <h1 className="font-semibold">{date.toString()}</h1>}
          <h1>
            {t('Notifications.ShareDocument.text', {
              documentName: notification.documentName,
            })}
          </h1>
          <Stack direction="row" className="gap-2 mt-2 w-full">
            <Button
              size="xs"
              className="w-[120px] cursor-pointer"
              onClick={() => {
                if (
                  location.pathname + location.hash ==
                  `/documents${parentPath}`
                )
                  client.invalidateQueries({
                    queryKey: ['search_My_Documents'],
                  });
                else navigate(`/documents${parentPath}`);
                RemoveNotification(id);
              }}
            >
              {t('Notifications.ShareDocument.openButton')}
            </Button>
            {notification.documentType !== 1 && (
              <Button
                size="xs"
                className="w-[120px] cursor-pointer"
                onClick={() => {
                  if (
                    location.pathname + location.hash ==
                    `/documents${parentPath}`
                  )
                    client.invalidateQueries({
                      queryKey: ['search_My_Documents'],
                    });
                  navigate(
                    `/documents${parentPath}?download=${notification.documentId}`
                  );
                  RemoveNotification(id);
                }}
              >
                {t('Notifications.ShareDocument.loadButton')}
              </Button>
            )}
          </Stack>
        </Stack>
        <IconButton className="pr-0 mr-0" hoverable={false}>
          <ArrowRightIcon />
        </IconButton>
      </Stack>
    </>
  );
};
