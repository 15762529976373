import { BookmarkIcon } from './../../images';
import { Stack } from './../../controls';
import { useStateProvider } from './../../providers';
import IconButton from '../IconButton';
import { branchesArray } from './branches';
import { Tooltip } from 'flowbite-react';

export const defaultBranchIcon = <BookmarkIcon />;

export const BranchIconList = ({
  branchIds = [],
}: {
  branchIds?: (string | undefined)[];
}) => {
  const { availableBranches } = useStateProvider().state.workManagement;
  const foundBranches = availableBranches.filter((branch) =>
    branchIds.includes(branch.id)
  );

  const branchesToDisplay = foundBranches.map((branch) => {
    const icon =
      branch.shortName && Object.keys(branchesArray).includes(branch.shortName)
        ? branchesArray[branch.shortName as keyof typeof branchesArray]
        : defaultBranchIcon;
    return { ...branch, icon };
  });

  if (!branchesToDisplay.length) {
    return <></>;
  }

  return (
    <Stack direction="row" alignItems="center" className="pl-2 py-1">
      {branchesToDisplay.map((branch) => (
        <Tooltip
          content={branch.convenientName}
          key={branch.id}
          style="light"
          trigger="hover"
        >
          <IconButton
            hoverable={false}
            className="bg-customColor_2 !px-0 !m-0 !w-10 !h-10 !-ml-2 !rounded-full border border-white"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {branch.icon}
          </IconButton>
        </Tooltip>
      ))}
    </Stack>
  );
};

export default BranchIconList;
