/* eslint-disable react-hooks/exhaustive-deps */
import {
  ProgressDialog,
  useApiUpload,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import { currentUploadFileType } from '@trueconnect/tp-components/src/providers/Uploads/typing';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { useApiInvalidateQueries, useApiMutation, useApiQuery } from 'src/api';
import { UploadManagerProps, Mapping } from './typing';
import { generateFolders } from './generateFolders';
import { Query, useQueryClient } from '@tanstack/react-query';

export const UploadManagerCustomer: React.FC<UploadManagerProps> = ({
  id,
  targetFolderId = '',
}) => {
  const { actions, state } = useStateProvider();
  const { addNotification } = useNotificationSystem();
  const invalidateQueries = useApiInvalidateQueries();
  const {
    currentUploadFile,
    selectedFiles = [],
    filesToUpload,
    isUploading,
  } = state.uploads;

  const {
    setCurrentUploadFileData,
    setFilesToUpload,
    setIsUploading,
    setSelectedFiles,
  } = actions;

  const queryClient = useQueryClient();

  const createSessionQuery = useApiQuery(
    'creates_Upload_Session_For_Customer_Documents',
    [
      id,
      {
        uploadFileName: currentUploadFile.file?.name,
        targetFolderId: currentUploadFile.parentId,
      },
    ],
    { enabled: false }
  );

  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('search_Customer_Documents');
  }, [invalidateQueries]);

  const removeSession = useCallback(() => {
    queryClient.removeQueries({
      predicate: (query: Query) =>
        query.queryKey[0] === 'creates_Upload_Session_For_Customer_Documents',
    });
  }, [queryClient]);

  const { mutate: closeUploadSession } = useApiMutation(
    'close_Upload_Session_For_Customer',
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (_, [_customerId, documentId]) => {
        removeSession();
        const fileName = currentUploadFile.file?.name;
        fileName &&
          setSelectedFiles([
            ...selectedFiles,
            { title: fileName, id: documentId as string },
          ]);
        setCurrentUploadFileData({ progress: 0, cancel: () => ({}) });
        addNotification({
          title: t('fileToolbar.upload.success', {
            filename: currentUploadFile?.file?.name,
          }),
          color: 'success',
        });
        setIsUploading(false);
        invalidateQuery();
      },
    }
  );

  const { progress, cancel } = useApiUpload<number>(
    id,
    createSessionQuery,
    (id: number, documentId: string) => {
      closeUploadSession([id, documentId]);
    },
    setIsUploading,
    (error) => {
      setFilesToUpload([]);
      addNotification({
        title: error,
        color: 'failure',
      });
    },
    currentUploadFile.file,
    isUploading
  );

  const [mapping, setMapping] = useState<Mapping>({});
  const [folderInCreationPath, setFolderInCreationPath] = useState('');
  const { isUploadPanelVisible: uploading } = useStateProvider().state.uploads;

  const handleFileUpload = async (file: FileWithPath) => {
    const filePath = file.path?.split('/').filter(Boolean).join('/') || '';

    const folderId = await generateFolders(
      filePath,
      targetFolderId,
      mapping,
      id,
      setFolderInCreationPath
    );

    setCurrentUploadFileData({
      file: filesToUpload[0],
      cancel: () => ({}),
      progress: 0,
      parentId: folderId,
    } as currentUploadFileType);

    invalidateQuery();
    setFilesToUpload(filesToUpload.slice(1));
  };

  useEffect(() => {
    if (
      filesToUpload.length > 0 &&
      !isUploading &&
      !currentUploadFile.file &&
      !!id
    ) {
      handleFileUpload(filesToUpload[0]);
    }
  }, [filesToUpload, currentUploadFile.file, id, isUploading]);

  useEffect(() => {
    if (!filesToUpload.length) {
      setMapping({});
      setFolderInCreationPath('');
    }
  }, [filesToUpload]);

  useEffect(() => {
    id &&
      isUploading &&
      setCurrentUploadFileData({
        ...currentUploadFile,
        cancel: () => {
          setFilesToUpload([]);
          setIsUploading(false);
          cancel();
          setCurrentUploadFileData({ progress: 0, cancel: () => ({}) });
        },
        progress,
      });
  }, [progress]);

  return (
    <>
      <ProgressDialog
        uploading={uploading}
        primaryView={false}
        folderInCreationPath={folderInCreationPath}
      />
    </>
  );
};

export default UploadManagerCustomer;
