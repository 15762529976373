export const compareDates = (
  date1: Date | undefined,
  date2: Date | undefined
): boolean => {
  if (!date1 || !date2) {
    return false;
  }

  const date1String = date1.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

  const date2String = date2.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

  return date1String === date2String;
};

export const compareDatesWithTime = (
  date1: Date | undefined,
  date2: Date | undefined
): boolean => {
  if (!date1 || !date2) {
    return false;
  }

  const date1String = date1.toLocaleString(undefined, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  const date2String = date2.toLocaleString(undefined, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  return date1String === date2String;
};
