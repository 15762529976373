import {
  CheckBox,
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
  IconButton,
  PlusIcon,
  SearchInput,
  Stack,
  VirtualizedList,
  defaultScrollbar,
  hoverClassname,
  useStateProvider,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import { useRef, useState } from 'react';
import { InvoiceNotificationReceiverDto, useApiInfiniteQuery } from 'src/api';
import classnames from 'classnames';
import { useClickAway } from 'react-use';
import { titleClassname, subTitle } from './InvoiceEdit';

export const RecipientSearch = ({
  selectedRecipients,
  selectRecipients,
  branchId = '',
  enabled = true,
}: {
  selectedRecipients: InvoiceNotificationReceiverDto[];
  selectRecipients: (item: InvoiceNotificationReceiverDto) => void;
  branchId?: string;
  enabled?: boolean;
}) => {
  const { companyData } = useStateProvider().state.customer;
  const [searchParam, setSearchParam] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const queryContacts = useApiInfiniteQuery(
    'searchContacts',
    [searchParam, companyData?.id, undefined, 0],
    {
      select: (data) =>
        data.pages.flatMap((arr) =>
          arr
            .filter((item) => {
              const policyPermissions =
                item.permissions?.allowedResources?.find(
                  (itm) => itm.resourceType == 'PolicyDirectoryBranch'
                );

              return (
                !!policyPermissions?.allowedAccessToAll ||
                !!policyPermissions?.resourceIds?.includes(branchId)
              );
            })
            .map(
              (subitem) =>
                ({
                  userId: subitem.userId,
                  displayName: `${subitem.firstName} ${subitem.lastName}`,
                  email: subitem.email,
                } as InvoiceNotificationReceiverDto)
            )
        ),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: showDropdown,
    }
  );

  const ref = useRef<HTMLDivElement>(null);

  useClickAway(ref, () => {
    setShowDropdown(false);
    setSearchParam('');
  });

  const isEmailEntered =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(searchParam);

  return (
    <>
      <p className={classnames(titleClassname, '!font-medium mb-2 text-black')}>
        {t('policyDetails.editInvoice.recipients')}
      </p>
      <div ref={ref} className="w-full">
        <Stack
          className="w-full border bg-customColor_7 rounded-lg overflow-hidden min-h-[52px] max-h-[62px]"
          direction="row"
          alignItems="center"
          justifyContent="between"
          onClick={() => {
            enabled && setShowDropdown(!showDropdown);
          }}
        >
          <div className="w-full gap-2 overflow-hidden">
            <div
              className={classnames(
                'w-full overflow-y-auto p-1 flex flex-wrap gap-1',
                defaultScrollbar
              )}
              style={{ maxHeight: '62px' }}
            >
              {selectedRecipients.length ? (
                selectedRecipients.map((item) => (
                  <Stack
                    key={item.email}
                    direction="row"
                    alignItems="center"
                    justifyContent="between"
                    className="px-2 h-[42px] border bg-white rounded-lg gap-2"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Stack direction="col" className="text-sm">
                      <p>{item.displayName}</p>
                      <p className={subTitle}>{item.email}</p>
                    </Stack>
                    {enabled && (
                      <IconButton
                        className="!m-0 !px-0 cursor-pointer"
                        onClick={(e) => {
                          selectRecipients(item);
                          e.stopPropagation();
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Stack>
                ))
              ) : (
                <p className={classnames(subTitle, 'select-none pl-3')}>
                  {t('policyDetails.editInvoice.recipientsPlaceholder')}
                </p>
              )}
            </div>
          </div>
          {enabled && (
            <IconButton
              className="cursor-pointer"
              dataTestId="RecipientSearchToggle"
            >
              {showDropdown ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </IconButton>
          )}
        </Stack>
        <div className="relative">
          {showDropdown && (
            <div
              className={classnames(
                '!absolute flex flex-col min-h-[180px] h-[180px] z-10 !left-0 w-full h-full',
                '!top-0'
              )}
            >
              <div
                className={classnames(
                  'w-full h-[180px] bg-white border rounded-lg'
                )}
              >
                <SearchInput
                  {...{
                    setSearchParam,
                    dataTestid: 'RecipientSearchSearchInput',
                    autoFocus: true,
                    isMobileView: true,
                    clearOnClickAway: true,
                    clearValue: !!searchParam,
                    onEnterClicked: () => {
                      isEmailEntered &&
                        selectRecipients({
                          displayName: '',
                          email: searchParam,
                          userId: undefined,
                        });
                      setSearchParam('');
                    },
                    classNames: {
                      wrapper: 'min-h-[40px] max-h-[40px] rounded-t-lg',
                    },
                  }}
                />
                <div className="w-full h-full min-h-[140px] h-[140px] pb-1 rounded-lg overflow-hidden">
                  {!queryContacts.isLoading &&
                  (queryContacts.data?.flat(1).length || !isEmailEntered) ? (
                    <VirtualizedList
                      columns={[
                        {
                          dataPropName: 'name',
                          render: (item) => (
                            <Stack
                              className="w-full cursor-pointer pl-4"
                              dataTestid={`recipientOption_${item.email}`}
                              justifyContent="between"
                              direction="row"
                              alignItems="center"
                              onClick={() => {
                                selectRecipients(item);
                                setSearchParam('');
                              }}
                            >
                              <Stack direction="col" className="text-sm">
                                <p>{item.displayName}</p>
                                <p className={subTitle}>{item.email}</p>
                              </Stack>
                              <CheckBox
                                {...{
                                  className: 'removeRecipientCheckbox',
                                  checked: selectedRecipients
                                    .map((itm) => itm.userId)
                                    .includes(item.userId),
                                }}
                              />
                            </Stack>
                          ),
                        },
                      ]}
                      headerHeight={0}
                      infinityQuery={queryContacts}
                      rowHeight={50}
                      classNames={{
                        row: '!last:border-none',
                      }}
                      onRowClick={(item) => {
                        selectRecipients(item);
                      }}
                    />
                  ) : (
                    <>
                      {isEmailEntered && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          className={classnames(
                            hoverClassname,
                            'w-full h-[40px]'
                          )}
                          onClick={() => {
                            selectRecipients({
                              displayName: '',
                              email: searchParam,
                              userId: undefined,
                            });
                            setSearchParam('');
                          }}
                        >
                          <IconButton>
                            <PlusIcon />
                          </IconButton>
                          <p>{t('policyDetails.editInvoice.addRecipient')}</p>
                        </Stack>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RecipientSearch;
