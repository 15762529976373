import { generateId } from './../../utils';

type Notification = {
  id: string;
  title: string;
  subTitle: string;
};

type NotificationsState = {
  notifications: Notification[];
};

type NotificationsAction =
  | { type: 'ADD_NOTIFICATION'; payload: Notification }
  | { type: 'REMOVE_NOTIFICATION'; payload: string };

export const initialNotificationsState: NotificationsState = {
  notifications: [],
};

const notificationsReducer = (
  state: NotificationsState = initialNotificationsState,
  action: NotificationsAction
): NotificationsState => {
  switch (action.type) {
    case 'ADD_NOTIFICATION':
      if (
        state.notifications.some(
          (notification) =>
            notification.title === action.payload.title &&
            notification.subTitle === action.payload.subTitle
        )
      ) {
        return state;
      }

      return {
        ...state,
        notifications: [
          ...state.notifications,
          { ...action.payload, id: generateId() },
        ],
      };
    case 'REMOVE_NOTIFICATION':
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export { notificationsReducer };
export type { NotificationsAction };
export type { NotificationsState };
