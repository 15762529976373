import { Stack } from '@trueconnect/tp-components';
import { Dispatch, SetStateAction } from 'react';

/* eslint-disable @typescript-eslint/no-unused-vars */
export const TitleEditor = ({
  value,
  setValue,
  isEditable,
}: {
  value?: string;
  setValue?: Dispatch<SetStateAction<string | undefined>>;
  isEditable?: boolean;
}) => {
  return (
    <Stack direction="col" className="w-full mb-6 pr-1">
      {isEditable ? (
        <input
          className="w-full !h-6 font-semibold border-b leading-normal py-0 text-sm text-customColor_20 outline-none focus:border-blue-700"
          value={value}
          data-testid="TitleEditor"
          onChange={(e) => {
            setValue?.(e.target.value);
          }}
        />
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          className="w-full !h-6 border-b border-t border-white"
        >
          <p className="truncate my-auto font-semibold leading-normal py-0 text-sm text-customColor_20">
            {value}
          </p>
        </Stack>
      )}
    </Stack>
  );
};

export default TitleEditor;
