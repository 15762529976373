import {
  ChatAction,
  ChatState,
  chatReducer,
  initialChatState,
} from '../Chat/reducers';
import {
  ConversationAction,
  ConversationState,
  conversationReducer,
  initialConversationState,
} from '../Conversation/reducers';
import {
  CustomerAction,
  CustomerState,
  customerReducer,
  initialCustomerState,
} from '../Customers/reducers';
import {
  DocumentAction,
  DocumentState,
  documentReducer,
  initialDocumentState,
} from '../Documents/reducers';
import {
  ExtraAction,
  ExtraState,
  extraReducer,
  initialExtraState,
} from '../Extra/reducers';
import {
  HubListenerAction,
  HubListenerState,
  hubListenerReducer,
  initialHubListenerState,
} from '../HubListener/reducers';
import {
  NotificationsAction,
  NotificationsState,
  initialNotificationsState,
  notificationsReducer,
} from '../NotificationSystem/reducers';
import {
  SpinnerAction,
  SpinnerState,
  initialSpinnerState,
  spinnerReducer,
} from '../Spinners/reducers';
import {
  UploadAction,
  UploadState,
  initialUploadState,
  uploadReducer,
} from '../Uploads/reducers';
import {
  WorkManagementAction,
  WorkManagementReducer,
  WorkManagementState,
  initialWorkManagementState,
} from '../WorkManagement/reducers';

type RootState = {
  notifications: NotificationsState;
  spinners: SpinnerState;
  uploads: UploadState;
  chat: ChatState;
  conversation: ConversationState;
  customer: CustomerState;
  documents: DocumentState;
  hubListener: HubListenerState;
  extra: ExtraState;
  workManagement: WorkManagementState;
};

type RootAction =
  | NotificationsAction
  | SpinnerAction
  | UploadAction
  | ChatAction
  | ConversationAction
  | CustomerAction
  | DocumentAction
  | HubListenerAction
  | ExtraAction
  | WorkManagementAction;

const rootReducer = (
  state: RootState | undefined,
  action: RootAction
): RootState => {
  return {
    notifications: notificationsReducer(
      state?.notifications,
      action as NotificationsAction
    ),
    spinners: spinnerReducer(state?.spinners, action as SpinnerAction),
    uploads: uploadReducer(state?.uploads, action as UploadAction),
    chat: chatReducer(state?.chat, action as ChatAction),
    conversation: conversationReducer(
      state?.conversation,
      action as ConversationAction
    ),
    customer: customerReducer(state?.customer, action as CustomerAction),
    documents: documentReducer(state?.documents, action as DocumentAction),
    hubListener: hubListenerReducer(
      state?.hubListener,
      action as HubListenerAction
    ),
    extra: extraReducer(state?.extra, action as ExtraAction),
    workManagement: WorkManagementReducer(
      state?.workManagement,
      action as WorkManagementAction
    ),
  };
};

const defaultState: RootState = {
  notifications: initialNotificationsState,
  spinners: initialSpinnerState,
  uploads: initialUploadState,
  chat: initialChatState,
  conversation: initialConversationState,
  customer: initialCustomerState,
  documents: initialDocumentState,
  hubListener: initialHubListenerState,
  extra: initialExtraState,
  workManagement: initialWorkManagementState,
};

export { rootReducer, defaultState };
export type { RootState, RootAction };
