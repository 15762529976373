import {
  Avatar,
  ContactStatus,
  createConversationStyles,
  Highlighted,
  Stack,
  hoverClassname,
  textStyle_medium,
  textStyle_xs,
  useIsSelectedView,
  ContactType,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import {
  ConversationAccessWarning,
  NoEmailWarning,
  NotActiveInTrueDataWarning,
} from '../WarningIcon';

interface ContactProps {
  data: ContactType;
  searchParam: string;
  hoverable?: boolean;
}

export const Contact: React.FC<ContactProps> = ({
  data,
  searchParam,
  hoverable = true,
}) => {
  const isMobileView = useIsSelectedView('sm');

  const { name, activeInTrueData, company, email, status } = data;
  const hasNoContactEmail = !email;

  const stopFromCreatingConversation =
    !activeInTrueData ||
    status == ContactStatus.Deactivated ||
    hasNoContactEmail;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="between"
      className={classnames(
        createConversationStyles.contact,
        stopFromCreatingConversation &&
          createConversationStyles.disabledContact,
        !stopFromCreatingConversation && hoverable && hoverClassname,
        isMobileView && '!p-0'
      )}
      dataTestid={`contact_${data.email?.replace('@', '_')}`}
    >
      <Stack direction="row" alignItems="center" className="contact truncate">
        <Avatar
          imgSrc={data.img}
          initials={`${name}`}
          size="xs"
          classNames="ml-0 pl-0 bg-none [&>div>div]:bg-transparent"
        />
        <Stack className="pl-2 pb-1">
          <h1 className={textStyle_medium}>{Highlighted(name, searchParam)}</h1>
          <h2 className={classnames(textStyle_xs, 'leading-normal')}>
            <Stack direction="row">
              {Highlighted(company, searchParam)}
              {email && (
                <>
                  <span className="px-1">/</span>
                  {Highlighted(email, searchParam)}
                </>
              )}
            </Stack>
          </h2>
        </Stack>
      </Stack>
      <Stack direction="row" className="px-2 nowrap">
        {!data.permissions?.allowConversations && <ConversationAccessWarning />}
        {!activeInTrueData && <NotActiveInTrueDataWarning />}
        {hasNoContactEmail && <NoEmailWarning />}
      </Stack>
    </Stack>
  );
};

export default Contact;
