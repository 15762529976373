import classnames from 'classnames';

const textColor = (
  regex: RegExp,
  part: string,
  classNames = {
    highlight: 'text-customColor_6',
    common: 'text-customColor_4',
  }
) => {
  return regex.test(part) ? classNames.highlight : classNames.common;
};

export const Highlighted = (
  text = '',
  highlight = '',
  classNames = {
    highlight: 'text-customColor_6',
    common: 'text-customColor_4',
  }
) => {
  if (!highlight) return <span>{text}</span>;

  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);

  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) => (
          <span
            className={classnames(textColor(regex, part, classNames))}
            key={i}
          >
            {part}
          </span>
        ))}
    </span>
  );
};

export default Highlighted;
