import {
  Stack,
  IconButton,
  EllipsisHorizontalIcon,
  Dropdown,
  useIsSelectedView,
  DropDownItemType,
  TrashIcon,
  useNotificationSystem,
  PencilIcon,
  Button,
  ConditionalClickAway,
  Spinner,
  RichTextEditor,
  DocumentDuplicateIcon,
  truncateString,
} from '@trueconnect/tp-components';
import { noteType } from './typing';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classnames from 'classnames';
import { t } from 'i18next';
import {
  BrokerAppLogic,
  useApiInvalidateQueries,
  useApiMutation,
} from 'src/api';
import TitleEditor from './TitleEditor';

export const NoteListItem = ({
  item,
  customerId,
  isEditMode,
  setNoteIdToDelete,
  setNoteToCopy,
}: {
  item: noteType;
  customerId: number | undefined;
  isEditMode?: boolean;
  setNoteIdToDelete: Dispatch<SetStateAction<string>>;
  setNoteToCopy: Dispatch<SetStateAction<noteType | undefined>>;
}) => {
  const isMobileView = useIsSelectedView('sm');
  const title = item.title;
  const text = item.text;
  const [isEditable, setIsEditable] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [editedText, setEditedText] = useState(text);

  const { addNotification } = useNotificationSystem();
  const invalidateQueries = useApiInvalidateQueries();
  const { mutate: updateNote, isLoading } = useApiMutation('update_Note', {
    onSuccess: () => {
      invalidateQueries('search_Notes');
    },
    onError: () => {
      addNotification({
        color: 'failure',
        title: t('customer.notes.editNote.cantSaveError'),
      });
    },
  });

  const items: DropDownItemType[] = [
    {
      icon: <DocumentDuplicateIcon data-testid="copyNote" />,
      text: t('customer.notes.copyModal.title'),
      onClick: () => {
        setNoteToCopy(item);
      },
    },
    {
      icon: <TrashIcon data-testid="showDeleteNoteModal" />,
      color: '#F05252',
      className: 'text-[#F05252]',
      text: t('customer.notes.deleteNoteModal.title'),
      onClick: () => {
        setNoteIdToDelete(item.id);
      },
    },
  ];

  const editNote = (newTitle?: string, newText?: string) => {
    if (item.title == newTitle && item.text == newText) {
      return;
    }
    setIsEditable(false);
    updateNote([
      customerId,
      {
        id: item.id,
        title: newTitle,
        text: newText,
      },
    ]);
  };

  // useEffect(() => {
  //   setEditedTitle(editedTitle);
  //   setEditedText(editedText);
  // }, [editedTitle, editedText]);

  useEffect(() => {
    setIsEditable(false);
  }, [isEditMode]);

  const isEdited = (editedTitle !== title || text !== editedText) && isEditable;

  const getContacts = async (query: string) => {
    const result = await BrokerAppLogic.searchContacts(
      query,
      customerId,
      [],
      0
    ).then((data) => {
      return data.map((item) => {
        const name = truncateString({
          str: `${item.firstName} ${item.lastName}`,
        });
        return `@${name}`;
      });
    });
    return result;
  };

  const getBrokers = async (query: string) => {
    const result = await BrokerAppLogic.searchBrokers(query, 0).then((data) => {
      return data.map((item) => {
        const name = truncateString({
          str: `${item.displayName}`,
        });
        return `@${name}`;
      });
    });
    return result;
  };

  return (
    <ConditionalClickAway
      onClickAway={(event) => {
        const targetElement = event.target as HTMLElement;

        const hasParentClass = (
          element: HTMLElement,
          className: string
        ): boolean => {
          while (element) {
            if (element.classList && element.classList.contains(className)) {
              return true;
            }
            element = element.parentElement as HTMLElement;
          }
          return false;
        };

        if (targetElement && targetElement.classList.contains('ck')) {
          return;
        } else if (hasParentClass(targetElement, 'ck')) {
          return;
        }

        if (isEdited) {
          editNote(editedTitle, editedText);
        } else {
          setIsEditable(false);
        }
      }}
      enableClickAway={isEditable}
      data-testid={item.title}
      className={classnames(
        'min-w-[254px] px-2 max-w-[254px] h-full border rounded-lg shadow-sm',
        isEditable && 'border-slate-400'
      )}
    >
      <div className="w-full h-full flex flex-col align-end relative">
        <Stack
          direction="row"
          dataTestid=""
          alignItems="center"
          justifyContent="end"
          className="w-full min-h-5 max-h-5 [&>div]:!m-0 pt-2 mb-1 pr-1"
        >
          {isEditMode && (
            <>
              {isEdited ? (
                <Stack direction="row" alignItems="center" className="gap-1">
                  <Button
                    size="xs"
                    className="h-[20px] text-xs"
                    onClick={() => {
                      setEditedText(text);
                      setEditedTitle(title);
                      setIsEditable(false);
                    }}
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    size="xs"
                    variant="primary"
                    className="h-[20px] text-xs [&>span>span>svg]:h-[12px]"
                    dataTestid="saveChanges"
                    onClick={() => {
                      editNote(editedTitle, editedText);
                    }}
                  >
                    {t('common.save')}
                  </Button>
                </Stack>
              ) : (
                <>
                  {isLoading && <Spinner className="w-4 h-4" />}
                  {!isLoading && (
                    <IconButton
                      className={classnames(
                        '!w-5 !h-5',
                        isEdited && 'border-[1px]'
                      )}
                      dataTestId="editNoteButton"
                      onClick={() => {
                        setIsEditable(!isEditable);
                      }}
                    >
                      <PencilIcon />
                    </IconButton>
                  )}
                </>
              )}
            </>
          )}
        </Stack>
        <TitleEditor
          {...{
            value: editedTitle,
            setValue: setEditedTitle,
            isEditable,
          }}
        />
        <div className={classnames('w-full h-full pr-1')}>
          <RichTextEditor
            {...{
              value: editedText,
              setValue: setEditedText,
              config: {
                mention: {
                  feeds: [
                    {
                      marker: '@',
                      feed: async (query) => {
                        const contacts = await getContacts(query);
                        const brokers = await getBrokers(query);

                        return [...contacts, ...brokers];
                      },
                      minimumCharacters: 3,
                      dropdownLimit: 5,
                    },
                  ],
                },
              },
              isEditable,
            }}
          />
        </div>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          className="w-full min-h-7 max-h-7 pb-2 pr-1"
        >
          {isEditMode && !isEditable && (
            <Dropdown
              defaultItemClassnames="text-customColor_9"
              placement="right-start"
              mobileView={isMobileView}
              label={
                <Stack direction="row">
                  <IconButton
                    hoverable={false}
                    dataTestId="noteActions"
                    customSize={classnames('w-5 h-5')}
                  >
                    <EllipsisHorizontalIcon className="w-5 h-5 text-black" />
                  </IconButton>
                </Stack>
              }
              items={items}
            />
          )}
        </Stack>
      </div>
    </ConditionalClickAway>
  );
};

export default NoteListItem;
