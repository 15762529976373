import { Help as HelpCommon } from '@trueconnect/tp-components';
import { HelpType } from '@trueconnect/tp-components/src/pages/Help';
import { HelpHeader } from 'src/components/HelpHeader';
import docs from './help.json';

export const Help = () => {
  return (
    <HelpCommon data={docs as HelpType[]} headerComponent={<HelpHeader />} />
  );
};

export default Help;
