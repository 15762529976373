import { hoverClassname } from './../../../styles';
import { IconButton, VirtualizedList } from '../../../components';
import { useStateProvider } from './../../../providers';
import { XMarkIcon, DocumentIcon } from './../../../images';
import classnames from 'classnames';
import { Stack } from './../../../controls';
import { setUnsentMessagesAttachments } from './../../../utils';
import { toggleSelectedFiles } from './../../../utils/toggleSelectedFiles';
import { SelectedFileType } from 'src/providers/Uploads/typing';

export const SelectedFilesComponent = () => {
  const { state } = useStateProvider();
  const { selectedFiles } = state.uploads;
  const { conversationId } = state.conversation.conversationData;
  const { setSelectedFiles } = useStateProvider().actions;

  const rowRenderer = (file: SelectedFileType) => {
    return (
      <div
        key={file.id}
        className={classnames(hoverClassname, 'w-full h-full pt-2')}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="between"
          onClick={() => {
            const data = toggleSelectedFiles(selectedFiles, {
              id: file.id,
              title: file.title,
            });
            setSelectedFiles(data);
            setUnsentMessagesAttachments(conversationId, data);
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            className="overflow-hidden"
          >
            <IconButton hoverable={false}>
              <DocumentIcon />
            </IconButton>
            <div className="truncate">{file.title}</div>
          </Stack>
          <IconButton hoverable={false} className="mr-5">
            <XMarkIcon />
          </IconButton>
        </Stack>
      </div>
    );
  };

  return (
    <>
      {!!selectedFiles.length && (
        <>
          <div className="w-full h-[250px] border-b border-silver-200">
            <VirtualizedList
              {...{
                rowHeight: 42,
                headerHeight: 0,
                infinityQuery: {
                  data: selectedFiles,
                  isLoading: false,
                },
                classNames: {
                  row: '!border-b border-silver-200',
                },
                columns: [
                  {
                    render: rowRenderer,
                    dataPropName: 'item',
                  },
                ],
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default SelectedFilesComponent;
