import { payloadType } from '../StateProvider/actions';

type actionType =
  | 'SET_CAN_SEND'
  | 'TOGGLE_SHOULD_SCROLL_TO_BOTTOM'
  | 'SET_MESSAGE';

interface action {
  type: actionType;
  payload?: payloadType;
}

interface actions {
  [key: string]: (payload?: payloadType) => action;
}

export const stateActionsChat: actions = {
  setCanSendMessage: (value: boolean) => ({
    type: 'SET_CAN_SEND',
    payload: value,
  }),
  setToggleShouldScrollToBottom: (value: boolean) => ({
    type: 'TOGGLE_SHOULD_SCROLL_TO_BOTTOM',
    payload: value,
  }),
  setMessage: (value: string) => ({
    type: 'SET_MESSAGE',
    payload: value,
  }),
};

export type actionsChat = {
  setCanSendMessage: (value: boolean) => void;
  setToggleShouldScrollToBottom: (value: boolean) => void;
  setMessage: (value: string) => void;
};

export default stateActionsChat;
