import {
  VirtualizedGridCellRendererType,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import { CustomerProps } from './CustomerProps';
import SyncStatus from './SyncStatus';
import {
  CompanyName,
  Address,
  Metadata,
  AccountManager,
  FavoriteButton,
  MobileViewListItem,
} from './columns';
import { useNavigate } from 'react-router-dom';

const useGetCustomersColumns = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns: VirtualizedGridCellRendererType<CustomerProps>[] = [
    {
      dataPropName: 'companyName',
      title: <p className="pl-9">{t('customers.company')}</p>,
      render: CompanyName,
      widthRatio: 6,
    },
    {
      dataPropName: 'address',
      title: <p>{t('customers.address')}</p>,
      render: Address,
      widthRatio: 4,
    },
    {
      dataPropName: 'metadata',
      title: <p className="mr-auto">{t('customers.metadata')}</p>,
      render: Metadata,
      widthRatio: 2,
    },
    {
      dataPropName: 'accountManager',
      title: <p>{t('customers.accountManager')}</p>,
      render: AccountManager,
      widthRatio: 3,
    },
    {
      dataPropName: 'more',
      title: <SyncStatus showText={false} />,
      render: (item) => FavoriteButton(item, (s) => navigate(s || '')),
      widthRatio: 1,
      className: '!pr-0',
    },
  ];

  const isMobile = useIsSelectedView('sm');
  const isTablet = useIsSelectedView('md');

  if (isTablet) {
    return [
      {
        dataPropName: 'companyName',
        title: <p className="pl-9">{t('customers.company')}</p>,
        render: CompanyName,
        widthRatio: 6,
      },
      {
        dataPropName: 'metadata',
        title: <p className="mr-auto">{t('customers.metadata')}</p>,
        render: Metadata,
        widthRatio: 2,
      },
      {
        dataPropName: 'accountManager',
        title: <p>{t('customers.accountManager')}</p>,
        render: AccountManager,
        widthRatio: 3,
      },
    ];
  }

  const mobileView: VirtualizedGridCellRendererType<CustomerProps> = {
    dataPropName: 'ListItem',
    render: (item) => MobileViewListItem(item, isMobile),
  };

  return isMobile ? [mobileView] : columns;
};

export default useGetCustomersColumns;
