import { PolicyType } from './typing';
import { BranchBadge, Stack } from './../../controls';
import { InsurancesIcon, ArrowUpIcon } from './../../images';
import { FC, SVGProps } from 'react';
import classnames from 'classnames';
import { Badge as BadgeFlowbite, Tooltip } from 'flowbite-react';
import { dateFormat } from './../../utils';
import { currencyFormat } from './../../utils';
import { branchType } from './../../providers';

const renderBadge = (
  icon: FC<SVGProps<SVGSVGElement>>,
  color?: string,
  className?: string
) => {
  return (
    <BadgeFlowbite
      className={classnames('w-6 h-6', className)}
      color={color}
      icon={icon}
    />
  );
};

export const OrgFrom = (
  data: PolicyType & { isBroker?: boolean },
  isTabletView: boolean
) => {
  return (
    <Stack className={classnames(isTabletView ? 'pl-2' : 'pl-3')}>
      {data.customerIsAHolding &&
        renderBadge(InsurancesIcon, 'info', 'bg-blue-200 text-blue-800')}

      {!data.customerIsAHolding &&
        (data.isBroker ? (
          <Tooltip
            content={data.customerName}
            style="light"
            trigger="hover"
            className="!fixed"
          >
            {renderBadge(ArrowUpIcon, 'info', 'bg-indigo-100 text-blue-900')}
          </Tooltip>
        ) : (
          <>{renderBadge(ArrowUpIcon, 'info', 'bg-indigo-100 text-blue-900')}</>
        ))}
    </Stack>
  );
};

const columnsClassname = 'text-base font-medium leading-normal truncate';

const Cell = (props: { value: string | number | undefined }) => {
  const { value } = props;

  return (
    <Stack direction="row" className={classnames('overflow-hidden pr-2')}>
      <p className={columnsClassname}>{value}</p>
    </Stack>
  );
};

const DateCell = (props: { value: Date | undefined }) => {
  const { value } = props;

  return <Cell value={dateFormat(value, true)} />;
};

export const Branch = (
  data: PolicyType,
  item: branchType | undefined,
  isTabletView?: boolean
) => {
  if (isTabletView) {
    return <Cell value={item?.shortName} />;
  }
  return <Cell value={data.branchName} />;
};
export const Insurer = (data: PolicyType) => (
  <Cell value={data.insuranceName} />
);
export const ContractType = (data: PolicyType) => (
  <Cell value={data.contractTypeName} />
);
export const Number = (data: PolicyType) => <Cell value={data.policyNumber} />;
export const ExpireDate = (data: PolicyType) => (
  <DateCell value={data.expirationDate} />
);
export const Price = (price: number | undefined, isMobileView?: boolean) => {
  return (
    <div
      className={classnames(
        'w-full overflow-hidden text-end',
        !isMobileView && 'pr-5'
      )}
    >
      <p className={columnsClassname}>{currencyFormat(price)}</p>
    </div>
  );
};
export const Description = (data: PolicyType) => {
  return <p className="!truncate text-sm font-medium">{data.notes}</p>;
};

export const MobileViewItem = (
  data: PolicyType,
  item: branchType | undefined,
  actions?: React.ReactNode
) => {
  return (
    <Stack direction="row" alignItems="center" className={'w-full h-full'}>
      <Stack direction="col" className="w-full h-full p-2">
        <Stack
          direction="row"
          className="w-full gap-2"
          justifyContent="between"
        >
          <Stack direction="row" alignItems="center" className="w-full gap-2">
            <>
              {data.customerIsAHolding && renderBadge(InsurancesIcon)}
              {!data.customerIsAHolding && renderBadge(ArrowUpIcon, 'info')}
            </>
            <>
              {item && (
                <BranchBadge
                  key={data.branchId}
                  {...{
                    item,
                    selectedName: 'shortName',
                  }}
                />
              )}
            </>
            <p className={columnsClassname}>{data.policyNumber}</p>
          </Stack>
          <p className="whitespace-nowrap">
            {dateFormat(data.expirationDate, true)}
          </p>
        </Stack>
        {Insurer(data)}
        <Stack
          direction="row"
          className="w-full mt-auto"
          justifyContent="between"
          alignItems="end"
        >
          <div className="[&>div>p]:text-sm text-gray-500">
            {ContractType(data)}
          </div>

          <div>{Price(data.bruttoPremium, true)}</div>
        </Stack>
      </Stack>
      {actions}
    </Stack>
  );
};
