import { DocumentCellStyle } from '../../../styles';
import { IDocumentType } from '../DocumentType';

export const CreatedBy: React.FC<IDocumentType> = (item: IDocumentType) => {
  return (
    <div className={DocumentCellStyle}>
      <p className="text-customColor_6 font-medium">
        {item.createdBy === 'SharePoint App' ? 'TrueConnect' : item.createdBy}
      </p>
    </div>
  );
};

export default CreatedBy;
