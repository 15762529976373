import { Button } from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { t } from 'i18next';

type DeleteModalType = {
  onAccept: () => void;
  visible: boolean;
  onClose: () => void;
  displayName?: string;
  header?: string;
  deleteConfirmationText?: string | React.ReactNode;
  isLoading?: boolean;
  showFooter?: boolean;
  acceptBtnText?: string;
  cancelBtnText?: string;
};

export const DeleteModal: React.FC<DeleteModalType> = ({
  onAccept,
  visible,
  onClose,
  displayName,
  header = t('common.delete'),
  deleteConfirmationText,
  isLoading,
  showFooter = true,
  acceptBtnText = t('common.accept'),
  cancelBtnText = t('common.cancel'),
}) => {
  return (
    <Modal
      show={visible}
      title={header}
      showBody={!!deleteConfirmationText}
      showFooter={showFooter}
      footer={
        <>
          <Button
            className="w-1/2"
            variant="secondary"
            onClick={onClose}
            dataTestid={`deleteCancel_${displayName}`}
          >
            {cancelBtnText}
          </Button>
          <Button
            variant="primary"
            className="w-1/2"
            isLoading={isLoading}
            onClick={() => {
              onAccept();
              onClose();
            }}
            dataTestid={`deleteAccept_${displayName}`}
          >
            {acceptBtnText}
          </Button>
        </>
      }
      onClose={onClose}
    >
      {deleteConfirmationText}
    </Modal>
  );
};

export default DeleteModal;
