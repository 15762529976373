import {
  Stack,
  CommonStyles,
  VirtualizedList,
  ConversationListItem,
  ConversationListItemProps,
  Button,
  SearchInput,
  getBranches,
  useStateProvider,
  useNotificationSystem,
  hoverClassname,
  CircleCheckBox,
  fullScreenModal,
  mobileModalStyles,
  modalStyles,
  useIsSelectedView,
  relativeFull,
  ConversationType,
  TopicType,
  IconButton,
  InfoIcon,
} from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useApiInfiniteQuery,
  useApiInvalidateQueries,
  useApiMutation,
} from 'src/api';
import classnames from 'classnames';
import ConversationStatusFilter from '../conversation/ConversationStatusFilter';

export const AddConversationToTopicModal = ({
  onClose,
  visible,
  selectedTopic,
}: {
  onClose: () => void;
  visible: boolean;
  selectedTopic: TopicType;
}) => {
  const { t } = useTranslation();
  const { state } = useStateProvider();
  const { availableBranches } = state.workManagement;
  const [searchParam, setSearchParam] = useState('');
  const { addNotification } = useNotificationSystem();
  const [selectedType, setSelectedType] = useState<ConversationType>('Mine');

  const queryConversations = useApiInfiniteQuery(
    selectedType == 'All'
      ? 'searchCustomerConversations'
      : 'searchConversations',
    selectedType == 'All'
      ? [
          selectedTopic.customerId || 0,
          {
            state: undefined,
            search: searchParam,
            hasTopic: false,
          },
        ]
      : [
          {
            state: undefined,
            search: searchParam,
            hasTopic: false,
            customerIds: selectedTopic.customerId
              ? [selectedTopic.customerId]
              : [],
          },
        ],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item
            .map(
              (subitem) =>
                ({
                  title: subitem.title,
                  id: subitem.id,
                  participants: subitem.participants,
                  lastActive: subitem.lastActivityUtcTimestamp,
                  branches: getBranches(availableBranches, subitem.branchIds),
                  ...subitem,
                } as ConversationListItemProps)
            )
            .filter(
              (itm) =>
                itm.customerId == selectedTopic.customerId ||
                !selectedTopic.customerId
            )
        ),
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('Topics.Details.cantLoadConversations'),
        });
      },
      enabled: visible,
    }
  );
  const initialAdded =
    queryConversations.data
      ?.flatMap((item) => item)
      .filter((item) => item.topicId == selectedTopic.id)
      .map((item) => item.id) || [];

  const [selectedConversations, setSelectedConversations] =
    useState(initialAdded);

  const close = () => {
    setSelectedConversations([]);
    onClose();
  };

  const invalidateQueries = useApiInvalidateQueries();
  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('getTopicById');
    !selectedTopic.customerId && (await invalidateQueries('searchTopics'));
    await invalidateQueries('searchCustomerConversations');
    await invalidateQueries('searchConversations');
  }, [invalidateQueries, selectedTopic.customerId]);

  const { mutate } = useApiMutation('update_Conversations_in_Batch', {
    onSuccess: () => {
      setSelectedConversations([]);
      invalidateQuery();
      close();
    },
    onError: () => {
      addNotification({
        color: 'failure',
        title: t('Topics.modals.addConversation.cantAdd'),
      });
    },
  });

  const formatData = () => {
    const toAdd = selectedConversations
      .filter((item) => !initialAdded.includes(item))
      .map((item) => {
        return {
          id: item,
          topicId: selectedTopic.id,
        };
      });

    // const toRemove = initialAdded
    //   .filter((item) => !selectedConversations.includes(item))
    //   .map((item) => {
    //     return {
    //       id: item,
    //       topicId: undefined,
    //     };
    //   });

    return [...toAdd];
  };

  const rowRenderer: React.FC<ConversationListItemProps> = (
    item: ConversationListItemProps
  ) => {
    return (
      <Stack
        className={classnames(
          'w-full h-full [&>.radioBtn]:hover:!border-[#6B7280]',
          hoverClassname
        )}
        alignItems="center"
        direction="row"
        onClick={() => {
          if (!selectedConversations.includes(item.id))
            setSelectedConversations([...selectedConversations, item.id]);
          else
            setSelectedConversations([
              ...selectedConversations.filter((id) => id !== item.id),
            ]);
        }}
      >
        <ConversationListItem
          {...{ ...item, showLastActive: false, redirect: false }}
        />
        <CircleCheckBox
          className="mx-5 my-1"
          checked={selectedConversations.includes(item.id)}
        />
      </Stack>
    );
  };

  const isMobileView = useIsSelectedView('sm');

  const InfoComponent = () => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        className={classnames('px-2 pb-8', isMobileView && 'pt-2 !pb-2')}
      >
        <IconButton hoverable={false} className="!mx-0 !px-0 !w-7 [&>div]:m-0">
          <InfoIcon color="#0170DF" />
        </IconButton>
        <p className="leading-none text-sm font-normal">
          {t('Topics.modals.addConversation.info')}
        </p>
      </Stack>
    );
  };

  return (
    <Modal
      size="7xl"
      title={t('Topics.modals.addConversation.title', {
        topicDisplayName: selectedTopic.displayName,
      })}
      show={visible}
      onClose={close}
      className={classnames(
        modalStyles,
        '!z-50',
        mobileModalStyles(isMobileView, true),
        isMobileView && fullScreenModal()
      )}
      footer={
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          className="w-full gap-5"
        >
          <Button onClick={close}>{t('common.cancel')}</Button>
          <Button
            color="blue"
            onClick={() => {
              mutate([formatData()]);
            }}
            dataTestid="AddConversationToTopicModalAccept"
          >
            {t('common.save')}
          </Button>
        </Stack>
      }
    >
      <Stack
        className={classnames(
          relativeFull,
          !isMobileView && '!h-[500px]',
          'overflow-hidden'
        )}
      >
        {!isMobileView && <InfoComponent />}
        <Stack
          direction={isMobileView ? 'col' : 'row'}
          alignItems={isMobileView ? 'end' : 'center'}
          justifyContent="between"
          className={classnames(
            'w-full',
            !isMobileView && 'mb-2',
            isMobileView && 'border-b'
          )}
        >
          <SearchInput
            {...{
              setSearchParam,
              classNames: { wrapper: 'w-[255px] h-[48px]' },
              dataTestid: 'AddConversationToTopicModalSearchInput',
              placeholder: t('Topics.modals.addConversation.Searchplaceholder'),
              isMobileView,
            }}
          />
          {selectedTopic.customerId && (
            <div>
              <ConversationStatusFilter
                {...{
                  selectedType,
                  setSelectedType,
                  isMobileView: true,
                  types: ['Mine', 'All'],
                }}
              />
            </div>
          )}
          {isMobileView && <InfoComponent />}
        </Stack>
        <Stack className={CommonStyles.full}>
          <VirtualizedList
            columns={[
              {
                dataPropName: 'itemConversation',
                render: rowRenderer,
              },
            ]}
            infinityQuery={queryConversations}
            headerHeight={0}
            rowHeight={120}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};

export default AddConversationToTopicModal;
