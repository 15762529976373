import {
  CustomerStyles,
  DetailsHeader,
  SmartSpinnerLocal,
  Stack,
  defaultScrollbar,
  useIsSelectedRange,
  useIsSelectedView,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import { useNavigate, useParams } from 'react-router-dom';
import useGetCustomerData from '../useGetCustomerData';
import { useEffect } from 'react';
import classnames from 'classnames';
import PolicyPageBreadCrumb from './PolicyPageBreadCrumb';
import { useApiQuery } from 'src/api';
import PolicyDocument from './PolicyDocument';
import PolicyDetails from './PolicyDetails';
import { t } from 'i18next';
import PolicyInvoiceTable from './PolicyInvoiceTable';

export const PolicyPage = () => {
  const { id = '', policyId = '' } = useParams();
  const navigate = useNavigate();
  const parsedId = parseInt(id || '');
  const data = useGetCustomerData(parsedId);
  const { actions } = useStateProvider();
  const isMobileView = useIsSelectedView('sm');
  const isTabletView = useIsSelectedRange(['md', 'lg', 'xl']);
  const { setShowNavbar, setShowBottombar, setCompanyData, setSelectedPolicy } =
    actions;
  const { addNotification } = useNotificationSystem();

  useEffect(() => {
    isMobileView && setShowNavbar(!id);
    isMobileView && setShowBottombar(!id);
    return () => {
      setShowNavbar(true);
      setShowBottombar(true);
    };
  }, [id, isMobileView, setShowBottombar, setShowNavbar]);

  useEffect(() => {
    data && setCompanyData(data);
    return () => {
      setCompanyData({});
    };
  }, [id, data, setCompanyData]);

  const { data: policyData, isLoading } = useApiQuery(
    'getPolicyById',
    [policyId],
    {
      onSuccess: (data) => {
        setSelectedPolicy(data);
      },
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('policyDetails.cantLoadError'),
        });
        navigate(`/customers/${parsedId}/policies`);
      },
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );

  if (!policyData?.id) {
    return <></>;
  }

  return (
    <SmartSpinnerLocal
      name="DataViewIsDataLoaded"
      condition={!data?.id || isLoading}
    >
      <Stack direction="row" className={classnames('w-full h-full relative')}>
        <div
          className={classnames(
            CustomerStyles.wrapper,
            'absolute top-0 left-0'
          )}
        >
          <Stack
            direction="col"
            className={classnames(
              'w-full',
              isMobileView ? '' : 'pl-8 pr-5 pt-5 [&>div>div]:mb-0'
            )}
          >
            {!isMobileView && (
              <Stack>
                <PolicyPageBreadCrumb
                  id={parsedId}
                  customerData={data}
                  contractTypeName={policyData?.contractTypeName}
                  policyNumber={policyData?.policyNumber}
                />
              </Stack>
            )}
          </Stack>

          <div
            className={classnames(
              'w-full h-full overflow-y-auto overflow-x-hidden',
              defaultScrollbar,
              !isMobileView && 'pl-5 pr-8 pb-3',
              isTabletView && '!px-2'
            )}
          >
            <DetailsHeader
              {...{
                policyData: { ...policyData, isBroker: true },
                returnUrl: `/customers/${parsedId}/policies`,
                classNames: 'pt-2',
              }}
            />
            <PolicyDocument {...{ policyData, isDataLoading: isLoading }} />
            <PolicyDetails {...{ policyData }} />
            <PolicyInvoiceTable {...{ policyData }} />
          </div>
        </div>
      </Stack>
    </SmartSpinnerLocal>
  );
};

export default PolicyPage;
