import {
  useStateProvider,
  Button,
  PlusIcon,
  IconButton,
} from '@trueconnect/tp-components';
import classnames from 'classnames';

export const CreateConversationIcon = ({
  className,
}: {
  className: string;
}) => {
  const { setShowCreateConversation } = useStateProvider().actions;

  return (
    <Button
      variant="primary"
      dataTestid="CreateConversationIcon"
      className={classnames('h-14 w-14 text-[#0170df] rounded-full', className)}
      onClick={() => {
        setShowCreateConversation(true);
      }}
    >
      <IconButton hoverable={false}>
        <PlusIcon className="text-white" />
      </IconButton>
    </Button>
  );
};

export default CreateConversationIcon;
