import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useStateProvider } from '../StateProvider';
import { generateId } from './../../utils';
import {
  HubListenerNotificationType,
  InvoiceConfirmedType,
  ReceiveMessageType,
  ShareDocumentType,
} from './typing';
import { ContactPermissionDto } from '../UserProfileContext';

export type methodType = {
  name: string;
  call: (args: any) => void;
};

export const useGetMethods = (permissions?: ContactPermissionDto) => {
  const { actions, state } = useStateProvider();

  const {
    ReceiveNotification,
    setCanSendMessage,
    setToggleShouldScrollToBottom,
  } = actions;
  const client = useQueryClient();

  const receiveMessage = (args: any) => {
    const conversationId = window.location.pathname.split('/')[2];
    if (conversationId && conversationId === args.conversationId) {
      client.invalidateQueries({ queryKey: ['getMessages'] });
      setCanSendMessage(true);
      setToggleShouldScrollToBottom(true);
    } else {
      ReceiveNotification({
        notification: {
          ...args,
        } as ReceiveMessageType,
        target: 'ReceiveMessage',
        id: generateId(),
        date: dayjs().format('YYYY-MM-DD HH:mm'),
      } as HubListenerNotificationType);
    }
  };

  const shareDocument = (args: any) => {
    ReceiveNotification({
      notification: {
        ...args,
      } as ShareDocumentType,
      target: 'ShareDocument',
      id: generateId(),
      date: dayjs().format('YYYY-MM-DD HH:mm'),
    } as HubListenerNotificationType);
  };

  const confirmInvoice = (args: any) => {
    ReceiveNotification({
      notification: {
        ...args,
      } as InvoiceConfirmedType,
      target: 'InvoiceConfirmed',
      id: generateId(),
      date: dayjs().format('YYYY-MM-DD HH:mm'),
    } as HubListenerNotificationType);
  };

  const invoiceDocumentDownloaded = (args: {
    documentId?: string;
    invoiceId?: string;
    policyId?: string;
    notificationType?: number;
  }) => {
    const selectedPolicy = state.workManagement.selectedPolicy;

    if (selectedPolicy?.id == args.policyId) {
      client.invalidateQueries({ queryKey: ['getPolicyById'] });
      client.invalidateQueries({ queryKey: ['getInvoicesByPolicyId'] });
    }
  };

  const methods: methodType[] = [];

  if (!permissions || permissions?.allowConversations) {
    methods.push({
      name: 'ReceiveMessage',
      call: receiveMessage,
    });
  }

  if (!permissions || permissions?.allowSharedDocuments) {
    methods.push({
      name: 'ShareDocument',
      call: shareDocument,
    });
  }

  if (!permissions || permissions?.allowPolicyDirectory) {
    methods.push({
      name: 'InvoiceConfirmed',
      call: confirmInvoice,
    });
  }

  if (!permissions) {
    methods.push({
      name: 'InvoiceDocumentDownloaded',
      call: invoiceDocumentDownloaded,
    });
  }

  return methods;
};
