export type SpinnerSubscriber = {
  name: string;
  value: boolean;
  background?: boolean;
  renderAsGlobal?: boolean;
};

type SpinnerState = {
  spinnerParentLoaded: boolean;
  spinners: SpinnerSubscriber[];
  globalSpinnerActive: boolean;
};

type SpinnerAction =
  | { type: 'SUBSCRIBE_TO_SPINNER'; payload: SpinnerSubscriber }
  | { type: 'UNSUBSCRIBE_TO_SPINNER'; payload: string }
  | { type: 'SET_PARENT_LOADED'; payload: boolean }
  | { type: 'SET_PARENT_ACTIVE'; payload: boolean };

export const initialSpinnerState: SpinnerState = {
  spinnerParentLoaded: false,
  spinners: [],
  globalSpinnerActive: false,
};

const spinnerReducer = (
  state: SpinnerState = initialSpinnerState,
  action: SpinnerAction
): SpinnerState => {
  switch (action.type) {
    case 'SUBSCRIBE_TO_SPINNER':
      if (
        state.spinners.some((spinner) => spinner.name === action.payload.name)
      ) {
        const updatedSpinners = state.spinners.map((spinner) => {
          if (spinner.name === action.payload.name) {
            return {
              ...spinner,
              value: action.payload.value,
            };
          }
          return spinner;
        });

        return {
          ...state,
          spinners: updatedSpinners,
        };
      }

      return {
        ...state,
        spinners: [...state.spinners, action.payload],
      };

    case 'UNSUBSCRIBE_TO_SPINNER':
      return {
        ...state,
        spinners: state.spinners.filter((item) => item.name !== action.payload),
      };
    case 'SET_PARENT_LOADED':
      return { ...state, spinnerParentLoaded: action.payload };
    case 'SET_PARENT_ACTIVE':
      return { ...state, globalSpinnerActive: action.payload };
    default:
      return state;
  }
};

export { spinnerReducer };
export type { SpinnerAction };
export type { SpinnerState };
