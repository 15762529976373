import {
  NotificationImage,
  SmartSpinnerLocal,
  Stack,
  branchType,
  customScrollbar,
  defaultScrollbar,
  useIsSelectedView,
  useNotificationSystem,
} from '@trueconnect/tp-components';
import NotesCarousels, { NotesCarouselsType } from './NotesCarousels';
import classnames from 'classnames';
import {
  SearchNotesDto,
  useApiInvalidateQueries,
  useApiMutation,
  useApiQuery,
} from 'src/api';
import { t } from 'i18next';
import { useState } from 'react';
import DeleteNoteModal from './DeleteNoteModal';
import CopyNoteModal from './CopyNoteModal';
import { noteType } from './typing';

export const getGeneralNote = () => {
  return {
    id: '',
    name: t('customer.notes.generalNote.name'),
    shortName: t('customer.notes.generalNote.shortName'),
    convenientName: t('customer.notes.generalNote.convenientName'),
    backgroundColor: '#F3F4F6',
    color: '#1F2A37',
    canBeDeleted: false,
  };
};

export const NotesList = ({
  selectedBranches = [],
  availableBranches,
  onAdd,
  customerId,
  isEditMode,
}: {
  selectedBranches?: string[];
  availableBranches: branchType[];
  onAdd: (id: string) => void;
  customerId?: number;
  isEditMode: boolean;
}) => {
  const isMobileView = useIsSelectedView('sm');
  const generalNotesBranch = getGeneralNote();

  const displayedBranches = selectedBranches.length
    ? availableBranches.filter((item) =>
        item.id
          ? selectedBranches.includes(item.id)
          : selectedBranches.includes(generalNotesBranch.id)
      )
    : availableBranches;

  const { data: notes, isLoading } = useApiQuery(
    'search_Notes',
    [
      {
        branchIds: displayedBranches.map((item) => (item.id ? item.id : null)),
        customerId,
      } as SearchNotesDto,
    ],
    {}
  );

  const [noteIdToDelete, setNoteIdToDelete] = useState('');
  const [noteToCopy, setNoteToCopy] = useState<noteType | undefined>();
  const allBranches = notes?.map((item) => item.branchId) || [];
  const data = displayedBranches
    .filter((item) =>
      item.id ? allBranches?.includes(item.id) : item.id == ''
    )
    .map(
      (item) =>
        ({
          branch: item,
          notes: notes?.filter(
            (note) =>
              note.branchId === item.id ||
              (note.branchId == null && item.id == '')
          ),
        } as NotesCarouselsType)
    );

  const { addNotification } = useNotificationSystem();
  const invalidateQueries = useApiInvalidateQueries();
  const { mutate: deleteNote, isLoading: isDeleting } = useApiMutation(
    'delete_Note',
    {
      onSuccess: () => {
        invalidateQueries('search_Notes');
      },
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('customer.notes.deleteNoteModal.error'),
        });
      },
    }
  );

  if (!data.length && !isLoading) {
    return (
      <NotificationImage
        text={t(`customer.notes.nothingFound.text`)}
        tooltip={t(`customer.notes.nothingFound.tooltip`) as string}
      />
    );
  }

  return (
    <SmartSpinnerLocal condition={isLoading} name="search_Notes">
      <Stack
        className={classnames(
          'w-full h-full pb-2 overflow-y-auto',
          isMobileView ? defaultScrollbar : customScrollbar
        )}
      >
        {data.map((item) => (
          <NotesCarousels
            key={item.branch.id}
            {...{
              customerId,
              setNoteIdToDelete,
              setNoteToCopy,
              data: item,
              isEditMode,
              onAdd,
            }}
          />
        ))}
      </Stack>
      <DeleteNoteModal
        {...{
          visible: !!noteIdToDelete,
          isDeleting,
          onAccept: () => {
            deleteNote([customerId, noteIdToDelete]);
          },
          onClose: () => {
            setNoteIdToDelete('');
          },
        }}
      />
      <CopyNoteModal
        {...{
          visible: !!noteToCopy?.id,
          customerId,
          noteToCopy,
          availableBranches,
          onAccept: () => {},
          onClose: () => {
            setNoteToCopy(undefined);
          },
        }}
      />
    </SmartSpinnerLocal>
  );
};

export default NotesList;
