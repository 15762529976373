import {
  TabProps,
  Tabs,
  TaskType,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { UseInfiniteQueryResult, InfiniteData } from '@tanstack/react-query';
import Backlog from './Backlog/Backlog';
import { useState } from 'react';
import { SearchOrderBy } from 'src/api';
import { useGetColumns } from './Backlog/columns';

export type taskQueryType = UseInfiniteQueryResult<
  InfiniteData<TaskType[], unknown>,
  Error
>;

export type tabProps = {
  tasksQuery: taskQueryType;
};

export const TaskTabs = () => {
  const { t } = useTranslation();
  const isMobileView = useIsSelectedView('sm');
  const [orderBy, setOrderBy] = useState<SearchOrderBy | undefined>(undefined);
  const columns = useGetColumns({ setOrderBy, orderBy });

  const tabs: TabProps[] = [
    {
      path: `/tasks`,
      title: t('Tasks.tabs.backlog.title'),
      component: <Backlog {...{ orderBy, columns }} />,
    },
    // {
    //   path: `/tasks/taskboard`,
    //   title: t('Tasks.tabs.taskboard.title'),
    //   component: <TaskBoard {...props} />,
    // },
  ];

  return (
    <Tabs
      tabs={tabs}
      className={classnames(
        '!mx-0 [&>div]:gap-8 [&>div>button]:px-0',
        isMobileView ? 'flex-nowrap overflow-auto' : '!overflow-hidden',
        isMobileView ? '[&>*:first-child]:!pl-4' : '[&>*:first-child]:!pl-8',
        "[&>div[role='tablist']>button]:!px-0"
      )}
    />
  );
};

export default TaskTabs;
