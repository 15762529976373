import { ConversationListItemProps } from 'src/pages/Conversations';
import { branchType } from '../WorkManagement/branches';
import {
  FetchTaskStateDto,
  TaskType,
  TopicType,
  addConversationToTopicType,
  extendedTopicParams,
  ContactBrokerType,
} from './typing';
import { PolicyType } from 'src/components';

type WorkManagementState = {
  availableBranches: branchType[];
  availableBrokers: ContactBrokerType[];
  selectedTopic: TopicType;
  showChangeBranches: boolean;
  selectedBranches: branchType[];
  notEditableBranches: branchType[];
  showAttachToTopic: boolean;
  addConversationToTopic: addConversationToTopicType;
  showTopicsSidebar: boolean;
  showTaskForm: boolean;
  selectedTask?: TaskType;
  openTask?: string;
  showDeleteTask?: boolean;
  draggedConversation?: ConversationListItemProps;
  showAddTask?: boolean;
  availableTaskTypes?: FetchTaskStateDto[];
  showExtendedTopicSearch?: boolean;
  extendedTopicSearchParams: extendedTopicParams;
  selectedPolicy?: PolicyType;
};

export const initialAddConversationToTopic = {
  conversation: {},
  topic: {},
} as addConversationToTopicType;

type WorkManagementAction =
  | {
      type: 'SET_AVAILABLE_BRANCHES';
      payload: branchType[];
    }
  | {
      type: 'SET_AVAILABLE_BROKERS';
      payload: ContactBrokerType[];
    }
  | {
      type: 'SET_SELECTED_TOPIC';
      payload: TopicType;
    }
  | { type: 'SHOW_CHANGE_BRANCHES'; payload: boolean }
  | { type: 'SET_SELECTED_BRANCHES'; payload: branchType[] }
  | { type: 'SET_NOT_EDITABLE_BRANCHES'; payload: branchType[] }
  | { type: 'SHOW_ATTACH_TO_TOPIC'; payload: boolean }
  | {
      type: 'SET_ADD_CONVERSATION_TO_TOPIC';
      payload: addConversationToTopicType;
    }
  | { type: 'SET_SHOW_TOPICS_SIDEBAR'; payload: boolean }
  | { type: 'SET_FORM_TYPE'; payload: boolean }
  | { type: 'SET_SELECTED_TASK'; payload: TaskType }
  | { type: 'SET_OPEN_TASK'; payload: string }
  | { type: 'SET_SHOW_DELETE_TASK'; payload: boolean }
  | { type: 'SET_DRAGGED_CONVERSATION'; payload: ConversationListItemProps }
  | { type: 'SET_SHOW_ADD_TASK'; payload: boolean }
  | { type: 'SET_AVAILABLE_TASKTYPES'; payload: FetchTaskStateDto[] }
  | { type: 'SET_SHOW_EXTENDED_TOPIC_SEARCH'; payload: boolean }
  | { type: 'SET_EXTENDED_TOPIC_SEARCH_PARAMS'; payload: extendedTopicParams }
  | { type: 'SET_SELECTED_POLICY'; payload: PolicyType };

export const initialTopicState = {
  id: '',
  displayName: '',
};

export const initialWorkManagementState: WorkManagementState = {
  availableBranches: [],
  availableBrokers: [],
  selectedTopic: initialTopicState,
  showChangeBranches: false,
  selectedBranches: [],
  notEditableBranches: [],
  showAttachToTopic: false,
  addConversationToTopic: initialAddConversationToTopic,
  showTopicsSidebar: false,
  showTaskForm: false,
  openTask: '',
  showDeleteTask: false,
  showAddTask: false,
  showExtendedTopicSearch: false,
  extendedTopicSearchParams: {},
};

const WorkManagementReducer = (
  state: WorkManagementState = initialWorkManagementState,
  action: WorkManagementAction
): WorkManagementState => {
  switch (action.type) {
    case 'SET_AVAILABLE_BRANCHES':
      return { ...state, availableBranches: action.payload };
    case 'SET_AVAILABLE_BROKERS':
      return { ...state, availableBrokers: action.payload };
    case 'SET_SELECTED_TOPIC':
      return { ...state, selectedTopic: action.payload };
    case 'SHOW_CHANGE_BRANCHES':
      return { ...state, showChangeBranches: action.payload };
    case 'SET_SELECTED_BRANCHES':
      return { ...state, selectedBranches: action.payload };
    case 'SET_NOT_EDITABLE_BRANCHES':
      return { ...state, notEditableBranches: action.payload };
    case 'SHOW_ATTACH_TO_TOPIC':
      return { ...state, showAttachToTopic: action.payload };
    case 'SET_ADD_CONVERSATION_TO_TOPIC':
      return { ...state, addConversationToTopic: action.payload };
    case 'SET_SHOW_TOPICS_SIDEBAR':
      return { ...state, showTopicsSidebar: action.payload };
    case 'SET_FORM_TYPE':
      return { ...state, showTaskForm: action.payload };
    case 'SET_SELECTED_TASK':
      return { ...state, selectedTask: action.payload };
    case 'SET_OPEN_TASK':
      return { ...state, openTask: action.payload };
    case 'SET_SHOW_DELETE_TASK':
      return { ...state, showDeleteTask: action.payload };
    case 'SET_DRAGGED_CONVERSATION':
      return { ...state, draggedConversation: action.payload };
    case 'SET_SHOW_ADD_TASK':
      return { ...state, showAddTask: action.payload };
    case 'SET_AVAILABLE_TASKTYPES':
      return { ...state, availableTaskTypes: action.payload };
    case 'SET_SHOW_EXTENDED_TOPIC_SEARCH':
      return { ...state, showExtendedTopicSearch: action.payload };
    case 'SET_EXTENDED_TOPIC_SEARCH_PARAMS':
      return { ...state, extendedTopicSearchParams: action.payload };
    case 'SET_SELECTED_POLICY':
      return { ...state, selectedPolicy: action.payload };
    default:
      return state;
  }
};

export { WorkManagementReducer };
export type { WorkManagementAction };
export type { WorkManagementState };
