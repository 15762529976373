import { Progress, Spinner } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import { useStateProvider } from './../../providers';
import classnames from 'classnames';
import FilesToUploadTable, {
  FilesToUploadType,
} from '../conversation/UploadModal/FilesToUploadTable';
import Stack from '../Stack';

export const ProgressDialog = ({
  primaryView = true,
  folderInCreationPath = '',
  uploading = false,
  type = 'primary',
  onClose = () => ({}),
}: FilesToUploadType & {
  folderInCreationPath?: string;
  uploading: boolean;
  type?: 'primary' | 'secondary';
  onClose?: () => void;
}) => {
  const { t } = useTranslation();
  const { uploads } = useStateProvider().state;
  const { progress, cancel, file } = uploads.currentUploadFile;
  const { filesToUpload } = uploads;
  const { setIsUploadPanelVisible, setFilesToUpload, setSelectedFiles } =
    useStateProvider().actions;

  return (
    <Modal
      show={uploading}
      title="Uploading"
      onClose={() => {
        cancel();
        setFilesToUpload([]);
        if (type === 'primary') {
          setSelectedFiles([]);
          setIsUploadPanelVisible(false);
        } else {
          onClose();
        }
      }}
    >
      <div className="w-full h-full" data-testid="ProgressDialog">
        {!!filesToUpload.length && (
          <div className={classnames('h-40')}>
            <FilesToUploadTable primaryView={primaryView} />
          </div>
        )}
        {file && (
          <>
            <div className="h-6 overflow-hidden">
              <div className="truncate">
                {t('uploadPanel.uploading', { fileName: file.name })}
              </div>
            </div>
            <Progress progress={progress} size="lg" color="blue" />
          </>
        )}
        {folderInCreationPath && !file && (
          <Stack direction="row" className="mt-5">
            <>
              {t('uploadPanel.creatingFolder', {
                folderName: folderInCreationPath,
              })}
            </>
            <Spinner className="ml-5" />
          </Stack>
        )}
      </div>
    </Modal>
  );
};

export default ProgressDialog;
