import { InformationRowWrapper } from './../../styles';
import { IconButton } from './../../components';
import classnames from 'classnames';
import Stack from '../Stack';
import { CustomerStyles } from './../../styles';

export type RowProps = {
  icon?: JSX.Element;
  title: React.ReactNode;
  onClick?: () => void;
  display?: boolean;
  classNames?: {
    wrapper?: string;
    img?: string;
    title?: string;
  };
};

export const InformationRow = ({
  icon,
  title,
  onClick,
  display = true,
  classNames = {},
}: RowProps) => {
  if (!title || !display) return <></>;
  return (
    <Stack
      className={InformationRowWrapper(classNames.wrapper)}
      alignItems="center"
      direction="row"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {icon && (
        <IconButton
          size="none"
          hoverable={false}
          className={classnames('mr-[5px]', classNames.img)}
        >
          {icon}
        </IconButton>
      )}
      <Stack
        direction="row"
        alignItems="center"
        className={CustomerStyles.informationTab.title(classNames.title)}
      >
        {title}
      </Stack>
    </Stack>
  );
};

export default InformationRow;
