import { commonText, hoverClassname } from './commonStyles';
import classnames from 'classnames';

export const ConversationTypeClassname = (type: string) => {
  return `px-1 text-xs font-medium font-normal leading-6 rounded-lg ${type}`;
};

const ConversationListItemClassname = (
  selected: boolean,
  classNames: string
) => {
  return classnames(
    'flex flex-col overflow-hidden p-4 w-full h-full cursor-pointer',
    hoverClassname,
    selected && 'bg-customColor_10',
    classNames
  );
};

export const ConversationReasonClassname = commonText;

export const userNameClassname =
  'truncate indent-1 text-customColor_4 w-full font-normal text-xs leading-normal';

export const sidebarStyles = {
  wrapper: (isMobileView: boolean, isSelectedView: boolean, hasId: boolean) =>
    classnames(
      'h-full overflow-hidden',
      isMobileView ? 'w-full' : '',
      isSelectedView && 'border-r-[1px]',
      isMobileView && hasId && 'hidden'
    ),
  searchInput:
    'w-full bg-customColor_7 p-[5px] indent-[5px] text-customColor_4 border-none focus:outline-none',
  filter: {
    tab: 'pb-1 font-medium text-base leading-tighter',
    selected: 'border-b-2 border-customColor_5 text-customColor_5',
    default: 'border-b-2 border-transparent text-customColor_4',
  },
};

export const conversationPageStyles = {
  sidebar: sidebarStyles,
  noConversationReplacement: {
    wrapper: 'grid text-center place-items-center',
    text: 'font-semibold mt-5',
  },
  selectConversation: 'w-full h-full grid place-items-center overflow-hidden',
  listItem: ConversationListItemClassname,
  lastActive:
    'truncate indent-2 text-customColor_4 font-normal text-xs leading-1.5',
  editableTitle: {
    wrapper: 'w-full space-x-1 mb-[10px] absolute',
    input: 'py-4 max-w-full text-2xl font-[600] focus:outline-none',
  },
  userActions: {
    header: (isMobileView: boolean) =>
      classnames(
        !isMobileView && 'border-l-2 border-customColor_1',
        'mx-[10px]'
      ),
  },
};

export const dropZoneStyles = (isDragActive: boolean) =>
  classnames(
    'flex flex-col w-full h-full flex-0 absolute top-0 bottom-0 relative',
    isDragActive
      ? 'bg-gray-100 bg-opacity-90 border-2 border-gray-300 border-dashed'
      : ''
  );

export const CustomerStatusNotificationStyles = {
  header: 'text-2xl font-semibold leading-normal',
  body: 'mt-[15px] font-normal px-5',
  button: 'mt-4 text-customColor_17 border border-blue-500 text-blue-500',
};

export const createConversationStyles = {
  Wrapper:
    'sm:w-[500px] p-4 w-full h-full bg-customColor_17 float-right overflow-hidden',
  PanelTop: 'w-full',
  contact: 'py-4 h-full text-base box-border w-full cursor-pointer',
  disabledContact: 'text-customColor_23 cursor-default bg-white',
  button: 'font-bold text-xs leading-1.5 h-4 truncate',
};

export const chatStyles = {
  wrapper: 'w-full flex h-full flex-col overflow-hidden mt-5',
  sender: 'text-xs text-gray-500',
  message: {
    container: 'w-full h-full flex-1 overflow-y-auto',
    default: (isMobileView: boolean) =>
      classnames(
        'px-4 py-3 mb-4 w-fit break-words rounded-lg',
        !isMobileView && '!max-w-[60%]'
      ),
    my: 'float-right bg-customColor_9 text-customColor_17 rounded-br-none',
    notMy: 'float-left bg-customColor_1 text-customColor_8 rounded-bl-none',
  },
  bottom: {
    style: 'w-full h-[66px] pb-2',
    wrapper: 'w-full h-[96%] bg-customColor_7 rounded-lg flex flex-row',
    scrollToBottomButton:
      'absolute -top-5 right-5 z-[5] border-solid border-2 bg-white cursor-pointer',
  },
};
export const defaultConversation = 'p-[3px] leading-none mr-[15px] rounded-md';

export const openConversation = classnames(
  'text-customColor_14 bg-customColor_15',
  defaultConversation
);
export const closedConversation = classnames(
  'bg-customColor_2 text-customColor_8',
  defaultConversation
);
