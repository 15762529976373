import React, { HTMLAttributes, useRef } from 'react';
import { useClickAway } from 'react-use';

interface ConditionalClickAwayProps extends HTMLAttributes<HTMLDivElement> {
  enableClickAway?: boolean;
  onClickAway: (event: Event) => void;
}

const ClickAwayWrapper: React.FC<ConditionalClickAwayProps> = ({
  onClickAway,
  children,
  ...divProps
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useClickAway(ref, onClickAway);

  return (
    <div ref={ref} {...divProps}>
      {children}
    </div>
  );
};

const PlainWrapper: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...divProps
}) => {
  return <div {...divProps}>{children}</div>;
};

export const ConditionalClickAway: React.FC<ConditionalClickAwayProps> = ({
  enableClickAway,
  onClickAway,
  children,
  ...divProps
}) => {
  if (enableClickAway) {
    return (
      <ClickAwayWrapper onClickAway={onClickAway} {...divProps}>
        {children}
      </ClickAwayWrapper>
    );
  }

  return <PlainWrapper {...divProps}>{children}</PlainWrapper>;
};

export default ConditionalClickAway;
