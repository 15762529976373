import {
  VirtualizedGridCellRendererType,
  IDocumentType,
  ModifiedBy,
  useIsSelectedView,
  useGetDocumentsColumns,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import { useMemo } from 'react';
import { ShareStatus, More, ListItem } from './columns';

export const useGetColumns = () => {
  const defaultColumns = useGetDocumentsColumns();

  const columns: VirtualizedGridCellRendererType<IDocumentType>[] = useMemo(
    () => [
      ...defaultColumns,
      {
        dataPropName: 'modifiedBy',
        title: t('file.modifiedBy'),
        render: ModifiedBy,
        widthRatio: 5,
      },
      {
        dataPropName: 'share',
        render: (item) => <ShareStatus item={item} />,
        widthRatio: 3,
      },
      {
        dataPropName: 'more',
        render: (item) => <More item={item} />,
        widthRatio: 2,
      },
    ],
    [defaultColumns]
  );

  const isMobileView = useIsSelectedView('sm');
  const isTabletView = useIsSelectedView('md');
  const isLargeView = useIsSelectedView('lg');

  const mobileViewItem: VirtualizedGridCellRendererType<IDocumentType> = {
    render: ListItem,
    dataPropName: 'item',
    widthRatio: 8,
  };

  if (isMobileView) {
    return [
      mobileViewItem,
      ...columns.filter(
        (item) =>
          !['fileName', 'modifiedAt', 'modifiedBy', 'createdBy'].includes(
            item.dataPropName
          )
      ),
    ];
  }

  if (isTabletView) {
    return columns.filter(
      (item) => !['modifiedAt', 'modifiedBy'].includes(item.dataPropName)
    );
  }

  if (isLargeView) {
    return columns.filter(
      (item) => !['modifiedBy'].includes(item.dataPropName)
    );
  }

  return columns;
};

export default useGetColumns;
