import {
  IconButton,
  PolicyType,
  ArrowRightIcon,
  Stack,
} from '@trueconnect/tp-components';
import { useNavigate } from 'react-router-dom';

export const Badge = ({ data }: { data: PolicyType }) => {
  const navigate = useNavigate();

  return (
    <Stack
      className="w-full h-full pr-[26px]"
      direction="row"
      alignItems="center"
      justifyContent="end"
    >
      <IconButton
        className="my-auto arrowIcon hidden pr-[6px]"
        onClick={() => {
          navigate(`policies/${data.id}`);
        }}
      >
        <ArrowRightIcon color="#0170DF" />
      </IconButton>
    </Stack>
  );
};
