import { useEffect, useState } from 'react';
import {
  ContactStatus,
  SearchInput,
  Stack,
  fullScreenModal,
  mobileModalStyles,
  relativeFull,
  useIsSelectedView,
  useStateProvider,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { t } from 'i18next';
import { ContactsTab } from './DataView/Tabs';

export const InvitedCustomersList = () => {
  const { invitedModalVisible: isVisible, customerData } =
    useStateProvider().state.customer;
  const {
    setToggleInvitedListModal,
    setClearCustomerData,
    setToggleCustomerModal,
  } = useStateProvider().actions;
  const isMobileView = useIsSelectedView('sm');
  const [searchParam, setSearchParam] = useState('');

  const onClose = () => {
    setToggleInvitedListModal(false);
    setClearCustomerData();
  };

  useEffect(() => {
    if (customerData.status == ContactStatus.NotInvited) {
      setClearCustomerData();
      setToggleCustomerModal(false);
    }
  }, [customerData, setClearCustomerData, setToggleCustomerModal]);

  return (
    <Modal
      show={isVisible}
      onClose={onClose}
      size="7xl"
      className={classnames(
        mobileModalStyles(isMobileView, true),
        isMobileView && fullScreenModal()
      )}
      title={t('customers.invitedContacts')}
      showFooter={false}
    >
      {!isMobileView && (
        <div className={classnames('w-full', isMobileView && 'px-2 pt-2')}>
          <SearchInput
            dataTestid="invitedCustomerSearch"
            classNames={{
              wrapper: 'h-12 w-[40%] mb-2',
            }}
            placeholder={t('tab.contacts.Searchplaceholder')}
            inputName="invitedCustomerSearch"
            setSearchParam={setSearchParam}
          />
        </div>
      )}
      <Stack
        direction="col"
        alignItems="stretch"
        className={classnames(relativeFull, !isMobileView && '!h-[500px]')}
      >
        {isVisible && (
          <ContactsTab
            id={undefined}
            statuses={[ContactStatus.Invited, ContactStatus.Active]}
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            isPanel={true}
          />
        )}
      </Stack>
    </Modal>
  );
};

export default InvitedCustomersList;
