import {
  ConversationListItemProps,
  ConversationListItem,
  ItemTypes,
  useStateProvider,
  useNotificationSystem,
} from '@trueconnect/tp-components';
import { TopicType } from '@trueconnect/tp-components/src/providers/WorkManagement/typing';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useDrag } from 'react-dnd';

const RowRenderer = ({
  item,
  selectedId,
  onClick,
}: {
  item: ConversationListItemProps;
  selectedId?: string | undefined;
  onClick?: (item: ConversationListItemProps) => void;
}) => {
  const { setAddConversationToTopic, setDraggedConversation } =
    useStateProvider().actions;
  const { addNotification } = useNotificationSystem();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.conversationListItem,
    item: item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (conversation, monitor) => {
      const topic = monitor.getDropResult<TopicType>();
      if (topic) {
        if (!topic.customerId || topic.customerId == conversation.customerId)
          setAddConversationToTopic({
            conversation,
            topic,
          });
        else {
          addNotification({
            color: 'info',
            title: t('Topics.modals.addConversation.customerError.title'),
          });
        }
      }
    },
  }));
  const opacity = isDragging ? 0.4 : 1;

  useEffect(() => {
    setDraggedConversation(isDragging ? item : undefined);
    return () => {
      setDraggedConversation(undefined);
    };
  }, [isDragging, item, setDraggedConversation]);

  return (
    <div
      ref={drag}
      onClick={() => {
        onClick?.(item);
      }}
      className="w-full h-full"
      style={{ opacity }}
    >
      <ConversationListItem {...item} selected={selectedId === item.id} />
    </div>
  );
};

export default RowRenderer;
