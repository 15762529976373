import {
  DocumentCellStyle,
  DocumentIcon,
  DropDownItemType,
  Dropdown,
  EllipsisHorizontalIcon,
  FolderIcon,
  IDocumentType,
  ShareIcon,
  Spinner,
  Stack,
  TrashIcon,
  textStyle_xs,
  useIsSelectedView,
  useStateProvider,
} from '@trueconnect/tp-components';
import { IconButton } from '@trueconnect/tp-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'flowbite-react';

export const ShareStatus: React.FC<{
  item: IDocumentType;
}> = ({ item }) => {
  const { t } = useTranslation();
  return (
    <Stack
      className={DocumentCellStyle}
      alignItems="center"
      justifyContent="center"
    >
      {item.shared && (
        <Badge color="success" data-testid={`shareStatus_${item.fileName}`}>
          {t('file.status.shared')}
        </Badge>
      )}
    </Stack>
  );
};

export const More: React.FC<{
  item: IDocumentType;
}> = ({ item }) => {
  const { t } = useTranslation();
  const { setToggleShareModal, setDocumentToShare } =
    useStateProvider().actions;
  const isMobileView = useIsSelectedView('sm');

  const toggleShare = () => {
    setToggleShareModal(true);
    setDocumentToShare(item);
  };

  const { toggleDeleteDocumentModal, setSelectedDocument } =
    useStateProvider().actions;

  const items: DropDownItemType[] = [
    {
      icon: <ShareIcon data-testid={`shareDocument_${item.fileName}`} />,
      classNames: 'text-[#0170DF] border-[#0170DF]',
      type: 'button',
      color: '#0170DF',
      text: t('file.action.manageAccess'),
      onClick: toggleShare,
    },
    {
      icon: <TrashIcon data-testid={`deleteDocument_${item.fileName}`} />,
      classNames: 'text-[#E53935] border-[#E53935]',
      type: 'button',
      color: '#E53935',
      text: t('file.action.delete'),
      onClick: () => {
        toggleDeleteDocumentModal(true);
        setSelectedDocument({ ...item });
      },
    },
  ];

  const { inAction } = useStateProvider().state.documents;

  return (
    <Stack
      className={DocumentCellStyle}
      direction="row"
      justifyContent="end"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {inAction.includes(item.id) ? (
        <Stack direction="row" alignItems="center" className="pl-2">
          <Spinner data-testid="actionProgress" />
        </Stack>
      ) : (
        <Dropdown
          mobileView={isMobileView}
          label={
            <IconButton>
              <EllipsisHorizontalIcon
                data-testid={`documentActionsDropdown_${item.fileName
                  .split(' ')
                  .join('_')}`}
              />
            </IconButton>
          }
          items={items}
        />
      )}
    </Stack>
  );
};

export const ListItem = (item: IDocumentType) => {
  return (
    <Stack
      direction="row"
      className={DocumentCellStyle}
      alignItems="center"
      data-testid={item.fileName}
    >
      <IconButton hoverable={false} size="none" className="mr-3 pl-4">
        {item.type === 'folder' ? (
          <FolderIcon className="w-6 h-7" />
        ) : (
          <DocumentIcon className="w-6 h-7" />
        )}
      </IconButton>
      <Stack direction="col">
        <span>{item.fileName}</span>
        <span className={textStyle_xs}>{item.modifiedAt}</span>
      </Stack>
    </Stack>
  );
};
