import { Textarea } from 'flowbite-react';
import { useRef } from 'react';
import classnames from 'classnames';
import { defaultScrollbar } from './../styles';

export type TextareaProps = {
  id?: string;
  placeholder?: string;
  rows?: number;
  value: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string;
};

export const TextArea: React.FC<TextareaProps> = (props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  return (
    <Textarea
      {...props}
      ref={textareaRef}
      className={classnames(
        'resize-none h-full my-2 focus:border-blue-500 focus:ring-none',
        defaultScrollbar,
        props.className
      )}
    />
  );
};

export default TextArea;
