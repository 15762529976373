import {
  Stack,
  ConversationParticipantType,
  useStateProvider,
  useIsSelectedRange,
} from '@trueconnect/tp-components';
import { ConversationStatusButton } from 'src/components/conversation';
import ParticipantDisplay from './ParticipantDisplay';

type UserActionsProps = {
  participant?: ConversationParticipantType;
};

export const UserActions: React.FC<UserActionsProps> = ({ participant }) => {
  const { conversationData } = useStateProvider().state.conversation;
  const { isConversationMember, participants } = conversationData;

  const isSelectedView = !!useIsSelectedRange(['sm', 'md', 'lg']);

  return (
    <Stack
      className="w-full my-[10px] h-10"
      direction="row"
      alignItems="center"
      justifyContent="between"
    >
      <Stack direction="row" className="relative">
        <ParticipantDisplay {...{ participants, participant }} />
      </Stack>
      {isConversationMember && (
        <ConversationStatusButton isSelectedView={isSelectedView} />
      )}
    </Stack>
  );
};
