import { Route, Routes as Router } from 'react-router-dom';
import { InternalConversationsPage } from './Conversations';
import { Dashboard } from './Dashboard/Dashboard';
import { DataTableCustomer, DataView as Customer } from './Customers';
import { Help } from './Help';
import { TopicsPage } from './Topics';
import { TaskPage } from './Tasks';
import { SettingsPage } from './Settings/SettingsPage';
import UserPage from './Users/UserPage';
import PolicyPage from './Customers/PolicyPage/PolicyPage';
import NotesPage from './Customers/NotesPage/NotesPage';
import { NotFoundPage } from '@trueconnect/tp-components';

const Routes = () => {
  return (
    <Router>
      <Route path="/" element={<Dashboard />} />
      <Route path="/me" element={<UserPage />} />
      <Route path="/topics" element={<TopicsPage />}>
        <Route path=":id" element={<TopicsPage />} />
      </Route>
      <Route path="/conversations" element={<InternalConversationsPage />}>
        <Route path=":id" element={<InternalConversationsPage />}>
          <Route path=":tab" element={<InternalConversationsPage />} />
        </Route>
      </Route>
      <Route path="/customers" element={<DataTableCustomer />} />
      <Route path="/customers/:id" element={<Customer />}>
        <Route path=":tab/:branchId" element={<>tester</>} />
        <Route path=":tab" element={<Customer />} />
      </Route>
      <Route path="/customers/:id/policy/:policyId" element={<PolicyPage />} />
      <Route path="/customers/:id/notes" element={<NotesPage />} />
      <Route path="/tasks" element={<TaskPage />}>
        <Route path=":id" element={<TaskPage />} />
      </Route>
      <Route path="/settings" element={<SettingsPage />}>
        <Route path=":tab/*" element={<SettingsPage />} />
      </Route>
      <Route path="/help" element={<Help />}>
        <Route path=":value" element={<Help />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Router>
  );
};

export default Routes;
