import { MouseEventHandler } from 'react';
import classnames from 'classnames';
// import { useTheme } from './../providers';
import {
  getHoverAndFocusClassName,
  getDisabledClassName,
  basicButtonClassName,
  getImageClasses,
} from './../styles';

interface IconButtonProps {
  children?: React.ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'none';
  className?: string;
  disabled?: boolean;
  hoverable?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  canBeDisabled?: boolean;
  imgClassName?: string;
  customSize?: string;
  dataTestId?: string;
}

const sizeClassnames = {
  xs: 'w-7 p-1',
  sm: 'w-9 p-1 m-1',
  md: 'w-9 p-3',
  lg: 'w-9 m-4',
  none: 'w-9',
};

export const IconButton: React.FC<IconButtonProps> = ({
  children,
  size = 'sm',
  className,
  disabled = false,
  onClick,
  hoverable = true,
  canBeDisabled = true,
  imgClassName,
  customSize,
  dataTestId,
}) => {
  // const { mode } = useTheme();

  const sizeClassName = customSize ? customSize : sizeClassnames[size];

  const hoverAndFocusClassName = getHoverAndFocusClassName(disabled, hoverable);

  const disabledClassName = getDisabledClassName(disabled, canBeDisabled);

  const buttonClasses = classnames(
    sizeClassName,
    basicButtonClassName,
    hoverAndFocusClassName,
    disabledClassName,
    onClick && 'cursor-pointer',
    className
  );

  const imageClasses = getImageClasses(imgClassName);

  return (
    <div
      tabIndex={disabled ? undefined : 0}
      className={buttonClasses}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <div className={imageClasses}>
        <>{children}</>
      </div>
    </div>
  );
};

export default IconButton;
