import {
  ExtendedConversationSearch as ExtendedConversationSearchCommon,
  BranchSearch,
  branchType,
  useStateProvider,
  DateSearch,
  useIsSelectedView,
  useIsSelectedRange,
  initialResizeBoxProps,
} from '@trueconnect/tp-components';
import classnames from 'classnames';

export const TopicExtendedSearch = () => {
  const { state, actions } = useStateProvider();
  const { extendedTopicSearchParams, showExtendedTopicSearch } =
    state.workManagement;
  const { branchIds } = extendedTopicSearchParams;
  const { setExtendedTopicSearchParams, setShowExtendedTopicSearch } = actions;
  const isMobileView = useIsSelectedView('sm');

  const setSelectedBranches = (branch: branchType) => {
    if (!branch.id) return;
    if (branchIds?.includes(branch.id))
      setExtendedTopicSearchParams({
        ...extendedTopicSearchParams,
        branchIds: branchIds.filter((item) => item !== branch.id),
      });
    else {
      setExtendedTopicSearchParams({
        ...extendedTopicSearchParams,
        branchIds: [...(branchIds || []), branch.id],
      });
    }
  };

  const isSelectedView = useIsSelectedRange(['sm', 'md', 'lg', 'xl']);

  return (
    <>
      {showExtendedTopicSearch && (
        <div
          className={classnames('h-full', isSelectedView ? 'w-full' : 'w-fit')}
        >
          <ExtendedConversationSearchCommon
            {...{
              resizeProps: {
                ...initialResizeBoxProps,
                resizeHandles: ['w'],
                width: 360,
                minConstraints: [360, -1],
              },
              classNames: 'border-l',
              showExtendedSearch: showExtendedTopicSearch,
              setShowExtendedSearch: setShowExtendedTopicSearch,
              clearExtendedSearchParams: () => {
                setExtendedTopicSearchParams({});
                isMobileView && setShowExtendedTopicSearch(false);
              },
            }}
          >
            <>
              <DateSearch
                {...{
                  onAccept: (dateCreatedFrom, dateCreatedTo) => {
                    setExtendedTopicSearchParams({
                      ...extendedTopicSearchParams,
                      dateCreatedFrom,
                      dateCreatedTo,
                    });
                  },
                  clear: () => {
                    setExtendedTopicSearchParams({
                      ...extendedTopicSearchParams,
                      dateCreatedFrom: undefined,
                      dateCreatedTo: undefined,
                    });
                  },
                }}
              />
              <BranchSearch
                {...{
                  selectedBranches: branchIds,
                  setSelectedBranches,
                }}
              />
            </>
          </ExtendedConversationSearchCommon>
        </div>
      )}
    </>
  );
};

export default TopicExtendedSearch;
