import {
  Stack,
  TextInput,
  Button,
  useNotificationSystem,
  Label,
} from '@trueconnect/tp-components';
import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { premiumType } from './typing';
import { useApiInvalidateQueries, useApiMutation } from 'src/api';

export const ManagePremiumType = ({
  isVisible,
  selectedPremiumType,
  close,
}: {
  isVisible: boolean;
  selectedPremiumType?: premiumType;
  close?: () => void;
}) => {
  const [name, setName] = useState('');

  const modalType = selectedPremiumType ? 'update' : 'create';
  const invalidateQueries = useApiInvalidateQueries();
  const { addNotification } = useNotificationSystem();

  const { mutate: mutateCreate } = useApiMutation('createPolicyRewardType', {
    onSuccess: () => {
      invalidateQueries('getAvailablePolicyRewardTypes');
      onClose();
    },
    onError: () => {
      addNotification({
        color: 'failure',
        title: t('premiumTypes.modals.create.error'),
      });
      onClose();
    },
  });

  const { mutate: mutateUpdate } = useApiMutation('updatePolicyRewardType', {
    onSuccess: () => {
      invalidateQueries('getAvailablePolicyRewardTypes');
      onClose();
    },
    onError: () => {
      addNotification({
        color: 'failure',
        title: t('premiumTypes.modals.update.error'),
      });
      onClose();
    },
  });

  const onAccept = () => {
    if (modalType == 'create') {
      mutateCreate([{ displayName: name }]);
    }
    if (modalType == 'update') {
      mutateUpdate([{ displayName: name, id: selectedPremiumType?.id }]);
    }
  };
  const onClose = () => {
    setName('');
    close?.();
  };

  useEffect(() => {
    isVisible && setName(selectedPremiumType?.displayName || '');
  }, [isVisible, selectedPremiumType?.displayName]);

  return (
    <Modal
      show={isVisible}
      size="md"
      title={t(`premiumTypes.modals.${modalType}.title`)}
      footer={
        <Stack direction={'row'} className="w-full gap-2">
          <Button
            className={'w-1/2'}
            variant="secondary"
            onClick={onClose}
            dataTestid="managePermiumTypeCancel"
          >
            <p className="truncate">{t('common.cancel')}</p>
          </Button>
          <Button
            className={'w-1/2'}
            variant="primary"
            onClick={onAccept}
            disabled={!name}
            dataTestid="managePermiumTypeAccept"
          >
            <p className="truncate">{t('common.save')}</p>
          </Button>
        </Stack>
      }
      onClose={onClose}
    >
      <Label
        htmlFor="premiumTypeInput"
        value={t('premiumTypes.modals.inputLabel')}
      />
      <TextInput
        type="text"
        id="premiumTypeInput"
        value={name}
        onChange={(e) => setName(e.target.value)}
        data-testid="premiumTypeInput"
      />
    </Modal>
  );
};

export default ManagePremiumType;
