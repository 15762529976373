import { useEffect, useRef, useState } from 'react';
import { PolicyTableProps, PolicyType } from './typing';
import { useTranslation } from 'react-i18next';
import { currencyFormat, useIsSelectedRange } from './../../utils';
import ResizableTable from './ResizableTable';
import { VirtualizedList } from '../Virtualized/VirtualizedList';
import classnames from 'classnames';
import { Stack } from './../../controls';

export const PolicyTable = ({
  columns,
  queryPolicies,
  onRowClick,
  headerClassName,
  isSearching,
  nothingFound = <></>,
  totalType = 'brutto',
}: PolicyTableProps) => {
  const { t } = useTranslation();

  const [policies, setPolicies] = useState<PolicyType[]>([]);
  const [total, setTotal] = useState<number>();

  useEffect(() => {
    setPolicies(queryPolicies.data || []);
  }, [queryPolicies]);

  useEffect(() => {
    setTotal(
      policies.reduce(
        (acc, item) =>
          acc +
          ((totalType == 'netto' ? item.nettoPremium : item.bruttoPremium) ||
            0),
        0
      )
    );
  }, [policies]);

  const ref = useRef<HTMLDivElement>(null);
  const isMobileView = useIsSelectedRange(['sm', 'md']);

  const TotalCostHeader = () => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="end"
        dataTestid="TotalCostHeader"
        className={classnames(
          'w-full bg-gray-50 border-t border-gray-200 sticky bottom-0',
          isMobileView ? '!min-h-[50px]' : '!min-h-[70px]'
        )}
      >
        <p
          className={classnames(
            'text-base font-semibold text-blue-600 leading-6',
            isMobileView ? 'mr-2' : 'mr-5'
          )}
        >
          {`${t('policies.total')}: ${currencyFormat(total)}`}
        </p>
      </Stack>
    );
  };

  return (
    <div ref={ref} className="w-full h-full flex flex-col overflow-hidden">
      {isMobileView && <TotalCostHeader />}
      {isMobileView ? (
        <Stack className="w-full h-full">
          <VirtualizedList
            {...{
              columns,
              isSearching,
              infinityQuery: queryPolicies,
              rowHeight: 90,
              headerHeight: 0,
              nothingFoundWrapper: 'h-full',
              shouldHaveNextPage: false,
              onRowClick,
              nothingFound: <>{nothingFound}</>,
            }}
          />
        </Stack>
      ) : (
        <ResizableTable
          {...{
            columns,
            onRowClick,
            headerClassName,
            queryPolicies,
            policies,
            isSearching,
            width: ref.current?.offsetWidth,
            nothingFound,
          }}
        />
      )}
      {!isMobileView && <TotalCostHeader />}
    </div>
  );
};

export default PolicyTable;
