import {
  NotificationImage,
  SmartSpinnerLocal,
  Stack,
  useIsSelectedRange,
  useNotificationSystem,
  useStateProvider,
} from '@trueconnect/tp-components';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { TopicType } from '@trueconnect/tp-components/src/providers/WorkManagement/typing';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import TopicsDetails from './TopicsDetails';
import { useEffect } from 'react';
import { useApiQuery } from 'src/api';
import CreateTopicButton from 'src/components/topics/CreateTopicButton';

export const TopicContainer = ({
  queryTopics,
}: {
  queryTopics: UseInfiniteQueryResult<TopicType[], Error>;
}) => {
  const { id = '' } = useParams();
  const { isLoading } = queryTopics;
  const topics = queryTopics.data?.flatMap((item) => item) || [];
  const { actions, state } = useStateProvider();
  const { setSelectedTopic } = actions;
  const { selectedTopic } = state.workManagement;
  const { addNotification } = useNotificationSystem();
  const navigate = useNavigate();

  const {
    data,
    isLoading: getByIdIsLoading,
    fetchStatus,
  } = useApiQuery('getTopicById', [id], {
    enabled: !!id,
    onError: () => {
      addNotification({
        color: 'failure',
        title: t('Topics.Details.cantGetById'),
      });
      navigate('/topics');
    },
  });

  useEffect(() => {
    id && setSelectedTopic(data as TopicType);
  }, [data, fetchStatus, id, setSelectedTopic]);

  const isMobileView = useIsSelectedRange(['sm', 'md']);

  if (isMobileView && !id) {
    return <></>;
  }

  if (!topics.length && !isLoading && !id) {
    return (
      <NotificationImage text={t('Topics.Details.nothingFound')}>
        <CreateTopicButton />
      </NotificationImage>
    );
  }

  if (!id) {
    return (
      <NotificationImage text={t('Topics.Details.selectTopic')}>
        <CreateTopicButton />
      </NotificationImage>
    );
  }
  return (
    <Stack className="w-full h-full">
      <SmartSpinnerLocal
        condition={
          getByIdIsLoading || !selectedTopic?.id || !selectedTopic?.displayName
        }
        name="GetTopicById"
      >
        <TopicsDetails />
      </SmartSpinnerLocal>
    </Stack>
  );
};

export default TopicContainer;
