import { VirtualizedList } from '@trueconnect/tp-components';
import { SearchBrokerResponseDto, useApiInfiniteQuery } from 'src/api';
import useGetColumns from './useGetColumns';
import { useCallback, useMemo } from 'react';
import { addMemberType } from '../typing';

export const BrokersMembersTab = ({
  searchParam,
  isQueryEnabled,
  participants,
  selectedUsers,
  manageSelectedParticipants,
}: Omit<addMemberType, 'allowFullHistory' | 'customerId'>) => {
  const queryBrokers = useApiInfiniteQuery('searchBrokers', [searchParam, 0], {
    select: (data) =>
      data.pages.flatMap((item) => item.map((subitem) => subitem)),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: isQueryEnabled,
  });

  const participantIds = useMemo(() => {
    return participants?.map((participant) => participant.userId) || [];
  }, [participants]);

  const shouldDisable = useCallback(
    (item: SearchBrokerResponseDto) => {
      return item.id ? participantIds.includes(item.id) : false;
    },
    [participantIds]
  );

  const toggleAddCheckbox = (item: SearchBrokerResponseDto) => {
    if (item.id && !shouldDisable(item)) {
      manageSelectedParticipants({
        brokerUserId: item.id,
        displayName: `${item.displayName}`,
      });
    }
  };

  const columns = useGetColumns({
    selectedUsers,
    shouldDisable,
  });

  return (
    <div className="w-full h-full">
      <VirtualizedList
        columns={columns}
        infinityQuery={queryBrokers}
        rowHeight={70}
        onRowClick={toggleAddCheckbox}
      />
    </div>
  );
};
