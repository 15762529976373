import { useCallback, useEffect, useRef, useState } from 'react';
import { useClickAway, useDebounce, useEffectOnce } from 'react-use';
import { IconButton } from '../../components';
import { MagnifyingGlassIcon, XCircleIcon } from './../../images';
import styles from './SearchInput.module.scss';
import classnames from 'classnames';

type SearchInputProps = {
  setSearchParam: (value: string) => void;
  classNames?: {
    wrapper?: string;
    input?: string;
    icon?: string;
  };
  inputName?: string;
  saveHistory?: boolean;
  historyMaxSize?: number;
  placeholder?: string;
  dataTestid?: string;
  isMobileView?: boolean;
  showIcon?: boolean;
  clearIcon?: React.ReactNode;
  searchIcon?: React.ReactNode;
  iconHoverable?: boolean;
  showClearIcon?: boolean;
  autoFocus?: boolean;
  clearOnClickAway?: boolean;
  onEnterClicked?: () => void;
};

const mobileSearchInputStyles =
  'w-full rounded-none border-b-2 !my-0 border-t-0 border-x-0 hover:border-x-0 hover:border-b-2 hover:border-t-0';

export const SearchInput: React.FC<SearchInputProps> = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [history, setHistory] = useState([] as string[]);
  const [showHistoryList, setShowHistoryList] = useState(false);
  const name = props.inputName || '';
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    showIcon = true,
    clearIcon = <XCircleIcon />,
    searchIcon = <MagnifyingGlassIcon />,
    showClearIcon = true,
    iconHoverable = true,
    autoFocus = false,
    clearOnClickAway = false,
    onEnterClicked,
  } = props;

  const wrapperRef = useRef(null);
  useClickAway(wrapperRef, () => {
    setShowHistoryList(false);
    clearOnClickAway && setInputValue('');
  });

  useEffect(() => {
    autoFocus && inputRef.current?.focus();
  }, [autoFocus]);

  useEffect(() => {
    setInputValue('');
  }, [props.placeholder]);

  useEffectOnce(() => {
    if (sessionStorage.getItem(name))
      setHistory(JSON.parse(sessionStorage.getItem(name) || ''));
  });

  const saveToHistory = useCallback(() => {
    sessionStorage.setItem(
      name,
      JSON.stringify(
        Array.from(new Set([inputValue, ...history])).slice(
          0,
          props.historyMaxSize || 3
        )
      )
    );
    if (sessionStorage.getItem(name))
      setHistory(JSON.parse(sessionStorage.getItem(name) || ''));
  }, [history, inputValue, name, props.historyMaxSize]);

  const handleSearch = useCallback(() => {
    props.setSearchParam(inputValue.length < 3 ? '' : inputValue);
    setShowHistoryList(false);
  }, [inputValue, props]);

  const clearSearchInput = () => {
    setInputValue('');
    props.setSearchParam('');
    setShowHistoryList(false);
  };

  const selectFromHistory = (str: string) => {
    setInputValue(str);
    props.setSearchParam(str);
    setShowHistoryList(false);
  };

  useDebounce(
    () => {
      handleSearch();
    },
    500,
    [inputValue, props.setSearchParam]
  );

  return (
    <div
      className={classnames(
        styles.wrapper,
        inputValue ? styles.activated : '',
        props.isMobileView && mobileSearchInputStyles,
        props.classNames?.wrapper
      )}
      ref={wrapperRef}
    >
      <div className="flex w-full m-0">
        {showIcon && (
          <IconButton
            className={classnames(
              'm-[10px]',
              props.classNames?.icon,
              inputValue && 'text-[#0170df]'
            )}
            hoverable={iconHoverable}
            onClick={handleSearch}
          >
            {searchIcon}
          </IconButton>
        )}

        <div className="relative w-full">
          <input
            ref={inputRef}
            data-testid={props.dataTestid}
            className={classnames(styles.input, props.classNames?.input)}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={props.placeholder}
            onMouseDown={() => {
              setShowHistoryList(true);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (props.saveHistory && inputValue) saveToHistory();
                if (onEnterClicked) {
                  onEnterClicked();
                  setInputValue('');
                }
              }
            }}
          />
          {!!history.length && showHistoryList && (
            <ul className={styles.history_list}>
              {history.map((result, key) => (
                <li
                  key={key}
                  className={styles.history_list_item}
                  onClick={() => {
                    selectFromHistory(result);
                  }}
                >
                  {result}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {inputValue && showClearIcon && (
        <IconButton
          className="text-gray-200 closeIcon"
          onClick={() => {
            clearSearchInput();
          }}
        >
          {clearIcon}
        </IconButton>
      )}
    </div>
  );
};

export default SearchInput;
